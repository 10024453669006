import React, { useEffect } from 'react';
import style from "../components/Plagiarism/plagiarism.module.css";
import TextareaAutosize from "react-textarea-autosize";
import $ from "jquery";
import './Sample.css'
let highlight = `every year there are about one million suicides worldwide`;
const SampleSocket = () => {
    const [value, setvalue] = React.useState('');
    const [highlights, setHighlights] = React.useState();
    useEffect(() => {
        if (value) {
            applyHighlights(value);
        }else{
            $('.highlights').html('');
        }
    }, [value]);
    const applyHighlights = (text) => {
        let highlight = text
            .replace(/[A-Z].*?\b/g, '<mark>$&</mark>');
            console.log(highlight);
        $('.highlights').html(highlight);
    }
    const handleScroll = () => {
        var scrollTop = $(".textarea").scrollTop();
        $(".backdrop").scrollTop(scrollTop);
    }
    return (
        <React.Fragment>
            <div className="text-container">
                <div className="backdrop">
                    <div className="highlights" style={{ width: "100%" }}>
                       
                    </div>
                </div>
                {/* <TextareaAutosize
                    onChange={(e) => setvalue(e.target.value)}
                    className={`${style.editable} textarea`}
                    placeholder="Write your content..."
                    spellCheck={false}
                    value={value}
                    onScroll={handleScroll}
                /> */}
                <textarea onScroll={handleScroll} placeholder="Write your content..."  className='textarea' onChange={(e) => setvalue(e.target.value)} >
                    {value}
                </textarea>
            </div>
        </React.Fragment>
    );
}

export default SampleSocket;
import {
  apiUrl,
  herokuUrl
} from "./config.json";


import httpService from "./httpService.js";

let apiEndpoint = "";

if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/detectors`;

} else {
  apiEndpoint = `${apiUrl}/detectors`;

}

export async function getCheckSummariser(input) {
  const data = await httpService.post(`${apiEndpoint}/checkSummarizer`, {
    input
  });
  return data;
}

export async function summarizerKeywords(text) {
  const data = await httpService.post(`${apiEndpoint}/summarizerKeywords`, {
    text
  });
  return data;
}



export async function Summarizer_Perc(text) {
  const data = await httpService.post(`${apiEndpoint}/Summarizer_Length_Perc`, {
    text
  });
  return data;
}


export default {
  getCheckSummariser,
  summarizerKeywords,
  Summarizer_Perc

}
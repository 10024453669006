import { sidenavbarActionTypes } from "./sidenavbar.types"


export const sideBaropen = (stateValue)=> ({
    type : sidenavbarActionTypes.SET_SIDE_BAR,
    payload:stateValue
  })
  
  export const sideBarClose = (stateValue)=> ({
    type : sidenavbarActionTypes.SET_SIDE_BAR_CLOSE,
    payload:stateValue
  })
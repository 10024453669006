import axios from "axios";
import { toast } from "react-toastify";
axios.interceptors.response.use(null, error => {
  console.log(error);
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log(error);
    toast.error("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-access-token"] = jwt;
}

async function getFetch(apiEndpoint, isBinary = false, doc = null) {
  let contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/octet-stream" || "application/pdf"
  let response = await fetch(`${apiEndpoint}`, {
    method: "GET",
    headers: {
      // "Content-Type": isBinary ? "application/pdf" : "application/json",
      // "Content-Type": isBinary ? "application/octet-stream" : "application/json",
      "Content-Type": isBinary ? contentType : "application/json",
      "x-access-token": sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"),
      "x-org-token": sessionStorage.getItem("orgToken") ? sessionStorage.getItem("orgToken") : localStorage.getItem("orgToken")
    }
  });
  console.log("getFetchresponse", response)
  let data = {}
  if (isBinary) {
    let convertedResponse = await response.blob();
     data = {
      rawResponse: response,
      blobResponse: convertedResponse,
      blobURL: URL.createObjectURL(convertedResponse)
    }
    // return URL.createObjectURL(response);
    return data
  }
  else if (doc == "doc") {
     let textResponse = await response.text()
     data = {
      rawResponse: response,
      textResponse: textResponse
     }
     return data
  }
  else {
    response = await response.json();
    if (response.status === 401) {
      toast.error(response.message);
      localStorage.clear()
      localStorage.setItem('sessiontimeout', true);
      localStorage.removeItem('token');
      window.location.href = "/";
    } else {
      return response;
    }
  }
}

async function deleteFetch(apiEndpoint) {
  let response = await fetch(`${apiEndpoint}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"),
      "x-org-token": sessionStorage.getItem("orgToken") ? sessionStorage.getItem("orgToken") : localStorage.getItem("orgToken")
    }
  });
  response = await response.json();
  if (response.status === 401) {
    toast.error(response.message);
    localStorage.clear()
    localStorage.setItem('sessiontimeout', true);
    sessionStorage.removeItem('token');
    window.location.href = "/";
  } else {
    return response;
  }
}

async function postFetch(apiEndpoint, data) {

  let response = await fetch(`${apiEndpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"),
      "x-org-token": sessionStorage.getItem("orgToken") ? sessionStorage.getItem("orgToken") : localStorage.getItem("orgToken")
    },
    body: JSON.stringify(data)
  });

  response = await response.json();
  if (response.status === 401) {
    toast.error(response.message);
    localStorage.clear()
    localStorage.setItem('sessiontimeout', true);
    sessionStorage.removeItem('token');
    window.location.href = "/";
  } else {
    return response;
  }
}

async function putFetch(apiEndpoint, data) {
  let response = await fetch(`${apiEndpoint}`, {
    method: 'PUT', // Method itself
    headers: {
      'Content-type': 'application/json', // Indicates the content 
      // 'Access-Control-Allow-Origin': '*',
      "x-access-token": sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"),
      "x-org-token": sessionStorage.getItem("orgToken") ? sessionStorage.getItem("orgToken") : localStorage.getItem("orgToken")
    },
    body: JSON.stringify(data) // We send data in JSON format
  });
  response = await response.json();
  if (response.status === 401) {
    toast.error(response.message);
    localStorage.clear()
    localStorage.setItem('sessiontimeout', true);
    sessionStorage.removeItem('token');
    window.location.href = "/";
  } else {
    return response;
  }
}

export default {
  get: getFetch,
  post: postFetch,
  put: putFetch,
  delete: deleteFetch,
  setJwt
};
/* 
function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error("promise timeout"))
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  })
}
*/
import React from "react";
import star from "../../../static/noun.png";

export default function HeadOne() {
  return (
    <div>
      <button
        // type="button"
        className="border ft_sm mr-2 p-2"
        style={{
          backgroundColor: "#f0f0f7",
          color: "black",
          border: "none",
          marginLeft: "150px"
        }}
      >
        <img src={star} className="p-1 mr-2"/>
        Add in any information you have below. The starred boxes are mandatory.
      </button>
    </div>
  );
}

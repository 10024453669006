import { featuresActionTypes } from "./features.types";

const INITIAL_STATE = {
    features: {},
    planName: "PET Basic Plan",
}

const featureReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case featuresActionTypes.SET_USER_FEATURES : 
        return {
            ...state,
            features : action.payload,
        }
        default :
            return state;
    }
}

export default featureReducer;

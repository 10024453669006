import React from 'react';
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SubAdminSideBar from '../SubAdminSideBar';
import DocumentMgmt from '../DocumentMgmt';

const DocumentMgmtSubAdminPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <DocumentMgmt />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(DocumentMgmtSubAdminPage);

import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/seo";
} else {
  apiEndpoint = herokuUrl + "/seo";
}

export async function addSEOBlogs(data) {
  let response = await httpService.post(`${apiEndpoint}/saveBlog`, data);
  return response;
}
export async function getAllSEOBlogs(pageItems, currentPage) {
  const data = await httpService.get(
    `${apiEndpoint}/getAllSEOBlogs/${pageItems}/${currentPage}`
  );
  return data;
}
export async function updateSEOBlog(data) {
  let response = await httpService.post(`${apiEndpoint}/updateSEOBlog`, data);
  console.log("services===>", response);
  return response;
}

export async function getSEOBlogsByCategoryWise(
  pageItems,
  currentPage,
  category
) {
  let response = await httpService.get(
    `${apiEndpoint}/getSEOBlogs/${pageItems}/${currentPage}/${category}`
  );
  return response;
}

export async function getSEOBlogByUrlValue(urlValue) {
  let response = await httpService.post(`${apiEndpoint}/getBlogByUrlValue`, {
    urlValue,
  });
  return response;
}
export async function getProductSEOBlog(data) {
  let response = await httpService.post(
    `${apiEndpoint}/getProductSEOBlog`,
    data
  );
  return response;
}

export default {
  addSEOBlogs,
  getAllSEOBlogs,
  getSEOBlogsByCategoryWise,
  updateSEOBlog,
  getSEOBlogByUrlValue,
  getProductSEOBlog,
};

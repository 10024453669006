import { TemplateActionTypes } from "./template.type";

const INITIAL_STATE = {
    templatData: {},
    
   
}

const templateReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case TemplateActionTypes.SET_TEMPLATE_DATA: 
        return {
            ...state,
            templatData : action.payload,
        }
        default :
            return state;
    }
}

export default templateReducer;

import http from "./httpService";
import {
  apiUrl,
  herokuUrl
} from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/blog`;
} else {
  apiEndpoint = `${herokuUrl}/blog`;
}


export async function saveBlogs(saveblog) {
  const {
    id
  } = await http.post(
    `${apiEndpoint}/postBlogs`,
    saveblog
  );
  console.log(id)
  return id;
}

// API - Get Blogs
export async function getAllBlogs() {
  const data = await http.get(`${apiEndpoint}/getBlogs`);
  console.log("service", data);
  return data;
}

export async function getAllUsersBlogs(pageSize, currentPage, value) {
  const data = await http.get(
    `${apiEndpoint}/getAllUsersBlog/${pageSize}/${currentPage}/${value}`
  );
  console.log(data);
  return data;
}

// API - Blog publish
export async function publishMyBlogs(blogId) {
  const data = await http.post(`${apiEndpoint}/publishBlog`, {
    id: blogId
  });
  return data;
}

// API - Blog unpublish
export async function unpublishMyBlogs(blogId) {
  const data = await http.post(`${apiEndpoint}/unpublishBlog`, {
    id: blogId
  });
  return data;
}

// API - Get blog by ID
export async function getBlogById(blogId) {
  const data = await http.post(`${apiEndpoint}/getblogbyId`, {
    id: blogId
  });
  return data;
}

// API - Get Public shareable blog
export async function getPublicBlog(blogId) {
  const data = await http.post(`${apiEndpoint}/getPublicBlog`, {
    id: blogId
  });
  return data;
}

// API - Get All Tags
export async function getBlogTags() {
  const data = await http.get(`${apiEndpoint}/getBlogTagsByUserId`);
  console.log("service", data);
  return data;
}

export async function searchBlogByName(value, blogValue) {
  const {
    blog
  } = await http.get(`${apiEndpoint}/searchBlogs/${value}/${blogValue}`);
  console.log(blog);
  return blog;
}

export async function autoSaveBlog(blog) {
  const data = await http.post(`${apiEndpoint}/autoSaveBlogs`, blog);
  return data;
}

export async function getBlogByTags(tag) {
  const data = await http.post(`${apiEndpoint}/getBlogByTags`, {
    tag
  });
  return data;
}

export  function ShareableLink(id, name) {
  let url = window.location.href;
  let arr = url.split("/");
  let result = arr[0] + "//" + arr[2];
  let param = `${name}-${id}`;
  // let link = `https://www.professionaleditingtool.com/public/blogpreview/${param}`;
  let link = `https://master.d3j6rh745b68id.amplifyapp.com/public/blogpreview/${param}`;
  // let link = `${result}/${param}`;
  return link;
}

export async function deletePermanentlyBlog(data) {
  const response = await http.post(`${apiEndpoint}/deletePermanentlyBlog`, data);
  return response;
}


export default {
  autoSaveBlog,
  saveBlogs,
  getAllBlogs,
  getBlogTags,
  getAllUsersBlogs,
  searchBlogByName,
  publishMyBlogs,
  unpublishMyBlogs,
  getBlogById,
  getBlogByTags,
  ShareableLink,
  getPublicBlog,
  deletePermanentlyBlog
}
import React from "react";
import SearchOpportunity from "../SearchOpportunity";
// import SideNavBar from "../SideNavBar";
import SubAdminSideBar from "../SubAdminSideBar";
import Farclause from "../Farclause";

const FARpage = () => {
  return (
    <div>
      <SubAdminSideBar>
        <Farclause />
      </SubAdminSideBar>
    </div>
  );
};

export default FARpage;
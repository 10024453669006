import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import ContactCard from "../ContactCard/ContactCard";
import GroupCard from "../ContactCard/GroupCard";
import "./Contacts.css";
import { Box, TextField, InputBase, Grid, Accordion, AccordionSummary, AccordionDetails, Avatar } from "@mui/material";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from "@material-ui/core";
import CreateGroup from "../Group/CreateGroup";
import ChatServices from "../../../services/ChatServices";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.actions";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@material-ui/core/styles';
import HelloWorldDialog from "../Group/dummyModal";


const Contacts = ({currentUser}) => {

  const [expandedPanels, setExpandedPanels] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { contacts, groups,  fetchUsersChat } = useContext(ChatContext);
  const [searchInput, setSearchInput] = useState("");
  const topContact = useRef();
  const dummy = useRef();
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    }
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


  const togglePanel = () => {
    setExpandedPanels(!expandedPanels);
  };

   const handleOpenDialog = () =>{
    setOpenDialog(true);
   }

   const handleCloseDialog = () => {
    setOpenDialog(false)
   }

  // Filter non-archived contacts
  const nonArchivedContacts = contacts.filter(contact => !contact.isArchive);
  // Filter archived contacts
  const archivedContacts = contacts.filter(contact => contact.isArchive);

  const archivedGroups = groups.filter(group => group.isArchive);

  const nonArchivedGroups = groups.filter(group => !group.isArchive);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredContacts = nonArchivedContacts.filter(contact =>
    contact.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredGroups = nonArchivedGroups.filter(group =>
    group.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    if (expandedPanels){
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" });
    }
    else{
      if (topContact?.current) topContact.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [expandedPanels]);

  return (

    <div className="contacts">
      
      { openDialog ? (
      <CreateGroup openDialog={openDialog}
       contacts={contacts} 
       fetchUsersChat={fetchUsersChat} 
       setOpenDialog={setOpenDialog} 
       /> 
      ): ''}

       {/* <HelloWorldDialog open={openDialog} handleClose={handleCloseDialog} /> */}


    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems="center" p={1}>
    <Box display='flex' flexDirection='row' gap={1} alignItems="center">
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar
              style={{
                backgroundColor: `#616e80`,
                color: "black"
              }}
            >
              <h5
                style={{ marginTop: "4px" }}
                data-toggle="tooltip"
                title={currentUser.username}
              >
                {currentUser.username.charAt(0).toUpperCase()}
              </h5>
            </Avatar>
          </StyledBadge>

          <Typography variant="h6">{currentUser.username}</Typography>
    </Box>
    <Box >
    <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ cursor: 'pointer', verticalAlign: 'middle', fontSize: '25px' }} onClick={handleOpenDialog}></i>
    </Box>
    </Box>


      <Box p={2}>
        <Paper
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '30ch' }}
        >
        <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        value={searchInput}
        onChange={handleSearchInputChange}
        />
        <IconButton disabled type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
        </IconButton>
        </Paper>
      </Box>

      <div className="contacts__list">
        <div ref={topContact}></div>
        {filteredContacts.map((contact , index) => (
          <ContactCard archive={''} key={contact._id} contact={contact} index={index} />
        ))}


        {filteredGroups.map((contact, index) => (
          <GroupCard key={contact._id} contact={contact} index={index} />
        ))}

      <Grid item xs={12}>
        <Box textAlign='center' sx={{cursor:"pointer"}} onClick={togglePanel} py={2}>
            <Typography variant='subtitle1' fontWeight={600}>Archive Chats ({parseInt(archivedContacts.length + archivedGroups.length)})</Typography>
        </Box>
        {
          expandedPanels  ?
          <Box>
                {archivedContacts.map((contact, index) => (
                  <ContactCard archive={'1'} key={contact._id} contact={contact} index={index} />
                ))}
                {archivedGroups.map((contact, index) => (
                  <GroupCard key={contact._id} contact={contact} index={index} />
                ))}
          </Box>
          :
          null
        }
          <div ref={dummy}></div>
        </Grid>

      </div>

    </div>
  );
};


const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Contacts);

import React from "react";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import Paraphrasetwo from "../Paraphrasetwo/Paraphrasetwo";

const ParaTwoPage = () => {

    return (
      <React.Fragment>
        <SideNavBar>
          <Paraphrasetwo />
        </SideNavBar>
      </React.Fragment>
    );
};

export default NetworkDetector(ParaTwoPage);

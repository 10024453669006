import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "#1890ff"
  }
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$selected": {
      color: "#1890ff",
      border: "none",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#2e1534"
  }
}));

export default function CustomizedTabs({ handleChangeTab, docLength }) {
  // const {  switchTab } = this.props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue);
    setValue(newValue);
    handleChangeTab(newValue);
  };
// const search = 
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          variant="scrollable"
          styles={{ color: "blue" }}
          //  onClick={()=>handleChangeTab(value)}
        >
          <AntTab className="reportour-5" label="Document Report"></AntTab>
          {docLength > 0 ? <AntTab className="reportour-6" label="Grammar Report"></AntTab> : ""}
         <AntTab className="reportour-7" label="Document Analyzer"></AntTab> 
        </AntTabs>
      </div>
    </div>
  );
}

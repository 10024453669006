import React from "react";
import Newsletter from "../Newsletter";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";


const NewsletterPage = (props) => {
  const user = authService.getCurrentUser();
  console.log(props);
  let data = {};
  if(props.location.state){
    data = props.location.state;
  }


  if (!user.isAdmin) {
    return (
      <React.Fragment>
        <Newsletter/>
      </React.Fragment>
    );
  } else {
    return (
      <AdminNavBar>
      <Newsletter user={data} />
    </AdminNavBar>
    );
  }
  
};

export default NetworkDetector(NewsletterPage);

import React from 'react';
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SubAdminSideBar from '../SubAdminSideBar';
import FarCauses from './FarCauses';


const FarCausePage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
     <FarCauses />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(FarCausePage);

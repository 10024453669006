import React from 'react';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search'; 

export const CoAuthor_Aireviewcomponent = () => {

    return (<>
      <div style={{ width: "80%", margin: "auto", marginTop: "10px" }}>
        Alright! Let's get started with a writing review. what type of pointer would you like
      </div>
  
      <div className='Service_bottom'>
        <div className='Ai_Review_div'>
          <p style={{ fontWeight: "600", fontFamily: "poppins", fontSize: "14px" }}>Get genral advice</p>
          <div style={{ display: 'flex' }}>
            <p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon />
          </div>
        </div>
        <div className='Ai_Review_div'>
          <p style={{ fontWeight: "600", fontFamily: "poppins", fontSize: "14px" }}>Increase clarity & readability</p>
          <div style={{ display: 'flex' }}>
            <p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon />
          </div>
        </div>
        <div className='Ai_Review_div'> <p style={{ fontWeight: "600", fontFamily: "poppins", fontSize: "14px" }}>Ensure content is complete</p>
          <div style={{ display: 'flex' }}>
            <p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon />
          </div>
        </div>
      </div>
  
      <div style={{ width: "434px", margin: "auto", marginTop: "10px" }}>
        <p style={{ fontWeight: "600", fontFamily: "poppins", fontSize: "14px", marginLeft: "15px" }}>Or ask for custom feedback</p>
        <div style={{ width: "434", margin: "auto", marginLeft: "10px" }}><textarea style={{ borderRadius: "12px" }} name="" placeholder='E.g., How can I make my writing sound more academic?' id="" cols="56" rows="5"></textarea></div>
      </div>
    </>)
  }
  
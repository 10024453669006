import React, {useState} from 'react';
import { Container, TextField, Button, IconButton,Modal, Card, CardContent,CardMedia, Typography, Grid, Menu, MenuItem } from '@material-ui/core';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import WindowIcon from '@mui/icons-material/Window';
import DownloadIcon from '@mui/icons-material/Download';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TemplatesIcon from '@mui/icons-material/CropOriginalOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import { Link } from 'react-router-dom';






const folderData = [
    {
      "type": "folder",
      "folderName": "Folder 1",
      "numDocuments": 3,
      "ownership": "my projects"
    },
    {
      "type": "folder",
      "folderName": "Folder 2",
      "numDocuments": 2,
      "ownership": "shared with me"
    },
    {
      "type": "folder",
      "folderName": "Folder 3",
      "numDocuments": 1,
      "ownership": "my projects"
    },
    {
      "type": "folder",
      "folderName": "Folder 4",
      "numDocuments": 0,
      "ownership": "shared with me"
    },
    {
      "type": "document",
      "docName": "Document 1",
      "description": "This is the first document",
      "numUsers": 5,
      "ownership": "my projects"
    },
    {
      "type": "document",
      "docName": "Document 2",
      "description": "A detailed document",
      "numUsers": 8,
      "ownership": "shared with me"
    },
    {
      "type": "document",
      "docName": "Document 3",
      "description": "Important information",
      "numUsers": 3,
      "ownership": "my projects"
    }
  ];


  const templateData = [
    {
      "name": "Appendix",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Academic"
    },
    {
      "name": "Blog",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Professional"
    },
    {
      "name": "Class Notes",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Personal"
    },
    {
      "name": "Business Letter",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Email & Letters"
    },
    {
      "name": "Article",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Academic"
    },
    {
      "name": "Resume",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Professional"
    },
    {
      "name": "Meeting-Notes",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Personal"
    },
    {
      "name": "Cold-Email",
      "imageUrl": "https://static.jobscan.co/blog/uploads/Software-Engineer-Cover-Letter-Example.png",
      "type": "Email & Letters"
    },
    
  ]

  

const CoAuthor = () => {


    
  return (
    <>
          <CoAuthorNav />
          <CoAuthorIcons/>
  
          <MyProjects data={folderData}/>
          <SharedWithMe data = {folderData}/>
       
        
      
    </>
  );
};


const CoAuthorIcons = ()=>{
    return (
        <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
          {/* Icons */}
          <IconButton>
            <CreateNewFolderIcon />
          </IconButton>

          <IconButton style={{ marginLeft: '5px' }}>
            <WindowIcon />
          </IconButton>

          <IconButton style={{ marginLeft: '5px' }}>
            <DownloadIcon />
          </IconButton>

          <IconButton style={{ marginLeft: '5px' }}>
            <SortByAlphaIcon />
          </IconButton>
        </div>
      </Container>
    )
}

const CoAuthorNav = () => {

    const [isTemplatesModalOpen, setTemplatesModalOpen] = useState(false);

    const handleTemplatesButtonClick = () => {
      setTemplatesModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setTemplatesModalOpen(false);
    };
  return (
    <Container>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '30px,10px' }}>
    {/* Search Bar */}
    <TextField label="Search" variant="outlined" size="small" style={{ width: '50%', marginRight: '20px' }} />

    {/* Buttons */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link to="/coauthor-newproject">
        <Button variant="contained" color="primary" startIcon={<AddIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
          New Project
        </Button>
      </Link>

      <Button
        variant="outlined"
        color="primary"
        startIcon={<UploadIcon />}
        style={{ marginRight: '7px', whiteSpace: 'nowrap' }}
      >
        Upload Doc
      </Button>

      <Button
        variant="outlined"
        color="primary"
        startIcon={<TemplatesIcon />}
        onClick={handleTemplatesButtonClick}
        style={{ whiteSpace: 'nowrap' }}
      >
        Use Templates
      </Button>
    </div>

    {/* Templates Modal */}
    <TemplatesModal isOpen={isTemplatesModalOpen} onClose={handleCloseModal} />
  </div>
</Container>

  );
};


const MyProjects = ({ data }) => {
    // Filter data for "my projects"
    const myProjects = data.filter(item => item.ownership === 'my projects');
  
    return (
      <Container style={{ marginTop: '10px' }}>
        <Typography variant="h5" style={{ marginBottom: '10px' }}>
          My Projects
        </Typography>
        <Grid container spacing={2}>
          {myProjects.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <MyProjectCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };
  
  const MyProjectCard = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleFolderMenuClick = () => {
        // Handle folder-specific menu action
        handleClose();
      };
    
      const handleDocumentMenuClick = () => {
        // Handle document-specific menu action
        handleClose();
      };
  
    return (
      <Card style={{ height: '100%', borderRadius: '10px' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
          {item.type === 'folder' && (
            <>
              <CreateNewFolderIcon style={{ fontSize: 30, marginBottom: '5px' }} />
              <Typography variant="h6">{item.folderName}</Typography>
              <hr style={{ width: '80%', margin: '10px 0'  }} />
              <div style={{display:'flex'}}>
              <Typography variant="body2" style={{ textAlign: 'center', marginTop: '5px', marginLeft:'45px' }}>
                {item.numDocuments} Documents
              </Typography>
              <IconButton
                style={{ marginLeft: '20px', width:'5px', height:'5px' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton></div>
              
            </>
          )}
          {item.type === 'document' && (
            <>
              <Typography variant="h6" style={{marginTop:'15px'}}>{item.docName}</Typography>
              <Typography variant="body2">{item.description}</Typography>
              <hr style={{ width: '80%', margin: '13px 0' }} />
              <div style={{display:'flex'}}>
              <Typography variant="body2" style={{marginLeft:'45px'}}>
                <PersonIcon style={{ marginRight: '5px' }} />
                {item.numUsers} Users
              </Typography>
              <IconButton
                style={{ marginLeft: '20px', width:'5px', height:'5px' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton></div>
            </>
          )}
  
        
          
  
          {/* Menu */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {item.type === 'folder' && (
              <>
                <MenuItem onClick={handleFolderMenuClick}>Share</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Duplicate</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Download</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Delete</MenuItem>
              </>
            )}
            {item.type === 'document' && (
              <>
                <MenuItem onClick={handleDocumentMenuClick}>Share</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Duplicate</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Add as a Template</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Download</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Delete</MenuItem>
              </>
            )}</Menu>
        </CardContent>
      </Card>
    );
  };


  const SharedWithMe = ({ data }) => {
    // Filter data for "shared with me"
    const sharedWithMeData = data.filter(item => item.ownership === 'shared with me');
  
    return (
      <Container style={{ marginTop: '10px' }}>
        <Typography variant="h5" style={{ marginBottom: '5px' }}>
          Shared with Me
        </Typography>
        <Grid container spacing={2}>
          {sharedWithMeData.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <SharedWithMeCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };
  
  const SharedWithMeCard = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleFolderMenuClick = () => {
        // Handle folder-specific menu action
        handleClose();
      };
      
      const handleDocumentMenuClick = () => {
        // Handle document-specific menu action
        handleClose();
      };
  
    return (
      <Card style={{ height: '100%', borderRadius: '10px' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
          {item.type === 'folder' && (
            <>
              <CreateNewFolderIcon style={{ fontSize: 30, marginBottom: '5px' }} />
              <Typography variant="h6">{item.folderName}</Typography>
              <hr style={{ width: '80%', margin: '10px 0'  }} />
              <div style={{display:'flex'}}>
              <Typography variant="body2" style={{ textAlign: 'center', marginTop: '5px', marginLeft:'45px' }}>
                {item.numDocuments} Documents
              </Typography>
              <IconButton
                style={{ marginLeft: '20px', width:'5px', height:'5px' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton></div>
              
            </>
          )}
          {item.type === 'document' && (
            <>
              <Typography variant="h6" style={{marginTop:'15px'}}>{item.docName}</Typography>
              <Typography variant="body2">{item.description}</Typography>
              <hr style={{ width: '80%', margin: '13px 0' }} />
              <div style={{display:'flex'}}>
              <Typography variant="body2" style={{marginLeft:'45px'}}>
                <PersonIcon style={{ marginRight: '5px' }} />
                {item.numUsers} Users
              </Typography>
              <IconButton
                style={{ marginLeft: '20px', width:'5px', height:'5px' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton></div>
            </>
          )}
  
  
          {/* Menu */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {item.type === 'folder' && (
              <>
                <MenuItem onClick={handleFolderMenuClick}>Share</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Duplicate</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Download</MenuItem>
                <MenuItem onClick={handleFolderMenuClick}>Delete</MenuItem>
              </>
            )}
            {item.type === 'document' && (
              <>
                <MenuItem onClick={handleDocumentMenuClick}>Share</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Duplicate</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Add as a Template</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Download</MenuItem>
                <MenuItem onClick={handleDocumentMenuClick}>Delete</MenuItem>
              </>
            )}
          </Menu>
        </CardContent>
      </Card>
    );
  };

  const TemplatesModal = ({ isOpen, onClose }) => {
    const [selectedCategory, setSelectedCategory] = useState('All Templates'); // 'all' is the default category
  
    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
    };
    const getCategoryIcon = () => {
      switch (selectedCategory) {
        case 'All Templates':
          return <SchoolIcon />;
        case 'Academic':
          return <SchoolIcon />;
        case 'Professional':
          return <WorkIcon />;
        case 'Personal':
          return <ComputerIcon />;
        case 'Email & Letters':
          return <MailOutlineIcon />;
        default:
          return <SchoolIcon />;
      }
    };

    
  
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <div style={{ backgroundColor: 'white', padding: '40px', borderRadius: '8px', maxWidth: '850px',maxHeight:'580px', position: 'relative' }}>
          <IconButton style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          
          {/* Category Buttons */}
          <Container>
          <Button
            variant={selectedCategory === 'All Templates' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => handleCategoryClick('All Templates')}
          >
            All Templates
          </Button>
  
          <Button
            variant={selectedCategory === 'Academic' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => handleCategoryClick('Academic')}
          >
            Academic
          </Button>
  
          <Button
            variant={selectedCategory === 'Professional' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => handleCategoryClick('Professional')}
          >
            Professional
          </Button>
  
          <Button
            variant={selectedCategory === 'Personal' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => handleCategoryClick('Personal')}
          >
            Personal
          </Button>
  
          <Button
            variant={selectedCategory === 'Email & Letters' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => handleCategoryClick('Email & Letters')}
          >
            Email & Letters
          </Button>
          </Container>

          <Container style={{marginTop:'15px'}}><h4>{getCategoryIcon()} {selectedCategory}</h4></Container>

          <Container><TemplateGallery templateData={templateData} selectedCategory={selectedCategory}/></Container>
        </div>
      </Modal>
    );
  };


  const TemplateGallery = ({ templateData, selectedCategory }) => {
    const filteredData =
      (selectedCategory === 'All Templates'
        ? templateData
        : templateData.filter((template) => template.type === selectedCategory));
  
    return (
      <Container>
        <Grid container spacing={2}>
          {filteredData.map((template, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <div style={{ margin: '0 10px', marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card style={{ height: 170, width: 120, display: 'flex', flexDirection: 'column' }}>
                  <CardMedia component="img" alt={template.name} height="140" image={template.imageUrl} />
                </Card>
                <Typography variant="body2" component="p" style={{ marginTop: '10px' }}>
                  {template.name}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };
  
  
  
  
 

export default CoAuthor;

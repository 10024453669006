import React, { useState } from "react";
import style from "./para.module.css";
import { TiArrowForward, TiArrowBack, TiLockClosed } from "react-icons/ti";
import { FaRegFileAlt } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import petLogo from "../../static/pet_logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
  },
}));

export function RightBottomBtn({ onHandleNavigation, colChange, sentanceNav }) {
  const [isCopy, setIsCopy] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onHandleCopy = () => {
    const editorRight = document.getElementById("editorRight").innerText;
    if (!editorRight) {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(editorRight).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (editorRight) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <div className={`text-e d-flex justify-content-between ${style.moveup}`}>
      <div className="d-flex" style={{marginBottom:"10px"}}>
        <button
          onClick={() => onHandleNavigation("down")}
          className={style.btn_trn}
        >
          <IoIosArrowUp
            size={"20px"}
            className="point"
            style={{ color: "#777274" }}
          />
        </button>
        <button
          onClick={() => onHandleNavigation("up")}
          className={style.btn_trn}
        >
          <IoIosArrowDown
            size={"20px"}
            className="point"
            style={{ color: "#777274" }}
          />
        </button>
        <p className="ft_md ml-2 mt-1">
          <text style={{ color: "#4cb2ff" }}> {sentanceNav.current}</text> /
          <text style={{ color: "red" }}> {sentanceNav.total}</text> Sentences
        </p>
      </div>

      <div className="d-flex align-items-right">
        <button
          className={style.btn_trns}
          aria-describedby={id}
          onClick={handleClick}
        >
          <span className="dotone"></span>
          <span className="mx-1">
          <svg height="40" width="20">
            <g fill="none">
              <path stroke="yellow" d="M5 20 l215 0" />
            </g>
          </svg>
          </span> 
          &nbsp;&nbsp;
          <span className="dottwo"></span>
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 350, left: 1050 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="container">
          <div className="row mt-2 ml-2"><b>Text Features</b></div>
          <div className="mt-3 ml-2">
          <div className="row">
            <div className="col-md-1">
            <span className="dotone"></span>
            </div>
            <div className="col-md-11">
              <b>Changed Words</b>
              <br />
              Original words are swapped with synonyms to maintain the underlying meaning and concepts.
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1">
            <svg height="40" width="20">
            <g fill="none">
              <path stroke="yellow" d="M5 20 l215 0" />
            </g>
          </svg>
            </div>
            <div className="col-md-11">
            <b>Structural Changes</b>
              <br />
              Going a step further, the sentence structure is modified by rearranging and altering clauses.
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1">
            <span className="dotthree"></span>
            </div>
            <div className="col-md-11">
            <b>Longest Unchanged Words</b>
              <br />
              This is the longest set of words that remain unchanged between the original and quilled text.
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1">
            <span className="dottwo"></span>
            </div>
            <div className="col-md-11">
              <b>Thesaurus</b>
              <br />
              Select a word or phrase with the same meaning (synonym) to enhance the sentence.
            </div>
          </div>
          </div>
          </div>
        </Popover>

        <button className={style.btn_trns} onClick={onHandleCopy}>
          <FiCopy
            size={"22px"}
            data-toggle="tooltip"
            data-placement="top"
            title={isCopy ? "Copied" : "Copy to clipboard"}
            style={{ color: "#777274" }}
          />
        </button>
      </div>
    </div>
  );
}

export function PetAssistanceBtn({
  characters,
  onPetAssistant,
  onHandleClear,
  isPara,
  limitChara,
}) {
  console.log(limitChara);
  return (
    <div className={`text-e d-flex justify-content-between ${style.moveup}`}>
      <div>
        <p className="ft_sm">
          <text style={{ color: "#4cb2ff" }}>{characters.now}</text>/
          <text style={{ color: "red" }}>{characters.max}</text> Characters
        </p>
      </div>
      <div className="d-flex align-items-center">
        <div
          className={
            isPara && !limitChara
              ? style.pet_clicked
              : limitChara
              ? style.charaLimitCSS
              : style.pet_out
          }
          onClick={onPetAssistant}
        >
          {/* <FiPenTool size={"28px"}/> */}
          <img className={style.logo} src={petLogo} alt="pet logo" />
          {isPara ? (
            <span className="mr-2 ft_md" style={{ color: "white" }}>
              Re-Phrase
            </span>
          ) : (
            <span className="mr-2 ft_md" style={{ color: "white" }}>
              Paraphrase
            </span>
          )}
        </div>
        {/* <p
          onClick={onHandleClear}
          data-toggle="tooltip"
          data-placement="top"
          title="Clear editor"
        >
          {" "}
          <i className={`fa fa-trash ml-3 ${style.trash}`}></i>{" "}
        </p> */}
      </div>
    </div>
  );
}

export function SideBtn(props) {
  const [selectedText, setselectedText] = useState("");

  const getSelectionText = () => {
    let text = "";
    if (window.getSelection) text = window.getSelection().toString();
    else if (document.selection && document.selection.type != "Control")
      text = document.selection.createRange().text;
    // console.log(text);
    setselectedText(text);
  };

  return (
    <div className={style.sidebtn}>
      <ul>
        <li>
          <button
            className={style.btn_side}
            onClick={props.onSelectedTextCopy}
            data-toggle="tooltip"
            data-placement="top"
            title="Copy selected text"
          >
            <FaRegFileAlt size={"20px"} />{" "}
          </button>
        </li>

        <li>
          <button
            className={style.btn_side}
            onClick={() => props.showHistory("inc")}
            data-toggle="tooltip"
            data-placement="top"
            title="ForwardResult"
          >
            <TiArrowForward size={"23px"} />{" "}
          </button>
        </li>

        <li>
          <button
            className={style.btn_side}
            onClick={() => props.showHistory("dec")}
            data-toggle="tooltip"
            data-placement="top"
            title="PreviousResult"
          >
            <TiArrowBack size={"23px"} />{" "}
          </button>
        </li>

        <li className={props.isLocked && style.enableIcon}>
          <button
            className={style.btn_side}
            onMouseEnter={getSelectionText}
            onClick={() => props.onHandleLock(selectedText)}
            data-toggle="tooltip"
            data-placement="top"
            title="Lock selected text"
          >
            <TiLockClosed size={"25px"} />
          </button>
        </li>

        {/* <li
          className={props.isLike && style.enableIcon}
          onClick={props.onHandleLike}
          data-toggle="tooltip"
          data-placement="top"
          title={props.isLike ? "Dis Like" : "Like"}
        >
          <TiThumbsUp size={"25px"} />
        </li> */}
      </ul>
    </div>
  );
}

export function Statistics({ colChange, statistics }) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton
              onClick={() => colChange("statistics")}
              aria-label="settings"
            >
              <CloseIcon />
            </IconButton>
          }
          title="Statistics"
        />
        <hr />

        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            color="primary"
            className="ml-1"
            style={{fontSize:"16px"}}
          >
            Fluency
          </Typography>
          <div
            className="ml-2"
            style={{  lineHeight: "13px"}}
          >
            <div className="row mb-3 mt-3">
              <div className="col-md-8 pt-2" style={{ color: "#707070", fontWeight:"bold",fontSize: "11.2px", }}>
                AVG. WORDS IN A SENTENCE
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                <text style={{fontSize:"20.16px"}}>0</text>&nbsp;&nbsp;
                <BsArrowRight size={"20px"} style={{ color: "#707070" }} />
                &nbsp;&nbsp;<text style={{fontSize:"20.16px"}}>0</text>
                <br />
                <small style={{ color: "black",fontSize:"12px" }}>from</small>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <small style={{ color: "black",fontSize:"12px" }}>to</small>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-8 pt-2" style={{ color: "#707070", fontWeight:"bold" ,fontSize: "11.2px", }}>
                AVG. SYLLLABLES IN A WORD
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                <text style={{fontSize:"20.16px"}}>0</text>&nbsp;&nbsp;
                <BsArrowRight size={"20px"} style={{ color: "#707070" }} />
                &nbsp;&nbsp;<text style={{fontSize:"20.16px"}}>0</text>
                <br />
                <small style={{ color: "black" ,fontSize:"12px"}}>from</small>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <small style={{ color: "black",fontSize:"12px" }}>to</small>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 pt-2" style={{ color: "#707070" , fontWeight:"bold",fontSize: "11.2px", }}>
                READABILITY
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                <text style={{fontSize:"20.16px"}}>0</text>%&nbsp;&nbsp;
                <BsArrowRight size={"20px"} style={{ color: "#707070" }} />
                &nbsp;&nbsp;<text style={{fontSize:"20.16px"}}>0</text>%
                <br />
                <small style={{ color: "black" ,fontSize:"12px"}}>from</small>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <small style={{ color: "black",fontSize:"12px" }}>to</small>
              </div>
            </div>
          </div>

          <hr />

          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            color="primary"
            className="ml-1"
            style={{fontSize:"16px"}}
          >
            Difference
          </Typography>

          <div
            className="ml-2"
            style={{  lineHeight: "13px" }}
          >
            <div className="row mb-3 mt-3">
              <div className="col-md-8 pb-1" style={{ color: "#707070" , fontWeight:"bold",fontSize: "11.2px", }}>
                SENTENCE COUNT
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                {" "}
                <text style={{fontSize:"20.16px"}}>{statistics.sentance}{" "}</text>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-8 pt-2" style={{ color: "#707070", fontWeight:"bold" ,fontSize: "11.2px", }}>
                WORD COUNT
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                <text style={{fontSize:"20.16px"}}>0</text>&nbsp;&nbsp;
                <BsArrowRight size={"20px"} style={{ color: "#707070" }} />
                &nbsp;&nbsp;<text style={{fontSize:"20.16px"}}>0</text>
                <br />
                <small style={{ color: "black" ,fontSize:"12px"}}>from</small>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <small style={{ color: "black",fontSize:"12px" }}>to</small>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8" style={{ color: "#707070" , fontWeight:"bold",fontSize: "11.2px", }}>
                PERCENT CHANGE
              </div>
              <div
                className="col-md-4"
                style={{ float: "right", color: "#1976d2" }}
              >
                <text style={{fontSize:"20.16px"}}>{statistics.percentChange}</text>
              </div>
            </div>
          </div>
        </CardContent>
        <br />
        <br />
        {/* <hr /> */}

        {/* <div className="text-center">
          <Button href="#text-buttons" color="primary" size="small" style={{fontSize: "10px", lineHeight: "13px"}}>
            LONGEST UNCHANGED WORDS
          </Button>
          <br />
          <Button variant="outlined" className="mt-2 mb-4">
            PREMIUM
          </Button>
        </div> */}
      </Card>
    </div>
  );
}

export function Feedback({ colChange }) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton
              onClick={() => colChange("feedback")}
              aria-label="settings"
            >
              <CloseIcon />
            </IconButton>
          }
          title="Feedback"
        />
        <hr />

        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            How am I doing?
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Have a suggestion, found some bug, enjoy the product? Let me know.
          </Typography>
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            placeholder="Start here"
            rows={4}
            //   defaultValue="Default Value"
            variant="outlined"
          />
        </CardContent>

        <div className="text-center">
          <Button href="#text-buttons" color="primary">
            Use Discord
          </Button>
          <br />
          <Button variant="outlined" className="mt-2 mb-4">
            SUBMIT
          </Button>
        </div>
      </Card>
    </div>
  );
}

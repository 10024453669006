import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/scanHistory`;
   
  } else {
    apiEndpoint = `${apiUrl}/scanHistory`;
   
  }
   
  // export async function getCheckSummariser(input) {
  //   const data = await httpService.post(`${apiEndpoint}/checkSummarizer`, {
  //     input
  //   });
  //   return data;
  // }


  export async function getScanHistory(data){
    const response=await httpService.post(`${apiEndpoint}/getScanHistory`,{data});
    return response;
  }

  export async function addScanHistory(data){
    const response=await httpService.post(`${apiEndpoint}/addScanHistory`,{data});
    return response;
  }

  export async function deleteHistory(params){
    const response=await httpService.post(`${apiEndpoint}/deleteScanHistory`,{params});
    return response;
  }

  export async function getScanData(params){
    const response=await httpService.post(`${apiEndpoint}/getScanData`,{params});
    return response;
  }
   
 
   
  export default {
    getScanHistory,
    addScanHistory,
    deleteHistory,
    getScanData
   
  }
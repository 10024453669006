import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/orgUserRole`;
} else {
  apiEndpoint = `${herokuUrl}/orgUserRole`;
}
 
export async function getOrgUserRole(data){
  const response = await http.post(`${apiEndpoint}/getorguserrole`, {data});

  console.log(response)
  return response;
  
}
 
// export async function getSearchApi(text){
//   const response = await http.post(`${apiEndpoint}/coAuthorPrompts  `, text );
//   return response;
// }
 
export default {
    getOrgUserRole,
    // checkTranslatorLang
}
import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import {
  getAllUsers2,
  unFreezeUserBySuperAdmin,
  freezeUserBySuperAdmin,
  deleteUserAccountBySuperAdmin,
} from "../services/adminServices";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png";
import DataGridMui from "./ReusableComponents/DataGridMui";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import Activate from ".././static/admin_dashboard/ActivateUser.png";
import Deactivate from ".././static/admin_dashboard/DeactivateUser.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import organizationService from "../services/organizationService";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import "./ManageOrganizationStyle.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { parse } from "json2csv";
import { showToast } from "../messages";




const ManageUser = ({ history }) => {
  let [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState("");
  const [superadminid, setsuperadminid] = useState(null);

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);
  const handleDeactivateModelClose = () => setShowDeactivateModal(false);

  useEffect(() => {
    if (!showDeactivateModal) {
      setDeactivateReason("");
    }
  }, [showDeactivateModal]);

  let email = sessionStorage.getItem("currentUserEmail");
  const getcurrentuserdata = async () => {
    try {
      const data = email;
      const response = await organizationService.getcurrentuserdata(data);
      console.log(response, "responsee");
      setsuperadminid(response?.data?._id);
    } catch (error) {}
  };
  useEffect(() => {
    getcurrentuserdata();
    getAllUsers();
  }, []);

  const handleActivate = async (userId) => {
    try {
      const superAdminId = superadminid;
      const response = await unFreezeUserBySuperAdmin({ userId, superAdminId });
      if (response.status === 200) {
        showToast('Organization.accountActivate')
        getAllUsers();
      } else {
       showToast('Organization.accountActivateFailed')
      }
    } catch (error) {
      console.error("Error while activating account:", error);
     showToast('Organization.accountActivateFailed')
    }
  };

  const handleDeactivate = async () => {
    try {
      const superAdminId = superadminid;
      const response = await freezeUserBySuperAdmin({
        userId: selectedUserId,
        superAdminId,
        freezeReason: deactivateReason,
      });
      if (response.status === 200) {
        showToast('Organization.accountDeactivate')
        getAllUsers();
      } else {
        showToast('Organization.accountDeactivateFailed')
      }
    } catch (error) {
      console.error("Error while deactivating account:", error);
      showToast('Organization.accountDeactivateFailed')
    }
    handleDeactivateModelClose();
  };

  const handleDelete = async () => {
    try {
      const superAdminId = superadminid;
      const response = await deleteUserAccountBySuperAdmin({
        userId: selectedUserId,
        superAdminId,
      });
      console.log(superAdminId, "superAdminId");
      console.log(selectedUserId, "selectedUserId");
      if (response.status === 200) {
        showToast('Organization.accountDelete')
        setUsers(users.filter((user) => user._id !== selectedUserId));
      } else {
        showToast('Organization.accountDeleteFailed')
      }
    } catch (error) {
      console.error("Error while deleting user account:", error);
      showToast('Organization.accountDeleteFailed')
    }
    handleClose();
  };

  const getAllUsers = async () => {
    setLoading(true);
    const res = await getAllUsers2();
    console.log("getAllUsers", res);
    if (res.status === 200) {
      setUsers(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      console.log("Oops something went worng");
    }
  };

  const handleToggleChange = (userId, isChecked) => {
    if (isChecked) {
      handleActivate(userId);
    } else {
      setSelectedUserId(userId);
      setShowDeactivateModal(true);
    }
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const filteredUsers = users.map((user) => ({
      username: user.username,
      email: user.email,
      phone: user.phone,
      Role: user.Role,
      organizationName: user.organizationName,
      country: user.country,
      Status: user.freeze ? "Inactive" : "Active",
    }));

    const ws = XLSX.utils.json_to_sheet(filteredUsers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    saveAs(excelData, "users" + fileExtension);
  };

  const exportToCSV = () => {
    const filteredUsers = users.map((user) => ({
      username: user.username,
      email: user.email,
      phone: user.phone,
      Role: user.Role,
      organizationName: user.organizationName,
      country: user.country,
      Status: user.freeze ? "Inactive" : "Active",
    }));

    const csvData = parse(filteredUsers);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(csvBlob, "users.csv");
  };

  const handleExport = (format) => {
    if (format === "excel") {
      exportToExcel();
    } else if (format === "csv") {
      exportToCSV();
    }
  };

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      flex: 0.3,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      type: "number",
      flex: 0.2,
    },
    {
      field: "Role",
      headerName: "User Role",
      flex: 0.2,
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      flex: 0.3,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.2,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 0.2,
      renderCell: (params) => (
        <>
          <span style={{ color: params.row.freeze ? "#ed4f4a" : "#28a745" }}>
            {params.row.freeze ? "Inactive" : "Active"}
          </span>
          <Tooltip title={params.row.freeze ? "Activate" : "Deactivate"}>
            <Switch
              checked={!params.row.freeze}
              onChange={(event) =>
                handleToggleChange(params.row._id, event.target.checked)
              }
              style={{ color: params.row.freeze ? "#ed4f4a" : "#28a745" }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      field: "Delete",
      headerName: "Delete",
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title="Delete Account">
          <DeleteOutlineIcon
            style={{ cursor: "pointer", color: "#ed4f4a" }}
            onClick={() => {
              setSelectedUserId(params.row._id);
              handleShow();
            }}
            title="Delete Account"
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Loader loading={loading} />
      <div className="container-fluid" style={{ background: "#f0f0f7" }}>
        <div className="export-dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleExport("excel")}
                className="dropdown-item custom-dropdown-item"
              >
                Export to Excel
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleExport("csv")}
                className="dropdown-item custom-dropdown-item"
              >
                Export to CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Modal
          show={showDeleteModal}
          onHide={handleClose}
          centered
          className="user-delete-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you really want to delete this user? The user will be deleted
              permanently.{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeactivateModal}
          onHide={() => setShowDeactivateModal(false)}
          centered
          className="user-deactivate-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Deactivate Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Provide a reason for deactivating this account:</p>
            <Form.Control
              as="textarea"
              rows={3}
              value={deactivateReason}
              onChange={(e) => setDeactivateReason(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeactivateModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeactivate}
              disabled={!deactivateReason.trim()}
            >
              Deactivate
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row mt-2 w-100">
          {users.length === 0 ? (
            <div className="col-md-12 text-center">
              <img src={team} alt="No Teams" />
              <h3>No teams found!</h3>
            </div>
          ) : (
            <>
              <div className="col-md-12">
                <DataGridMui
                  height="75vh"
                  getRowId={(data) => data._id}
                  columns={columns}
                  rows={users.filter(
                    (user) =>
                      user.username !== "Unknown" ||
                      !!user.email ||
                      !!user.phone ||
                      !!user.Role ||
                      !!user.organizationName ||
                      !!user.country ||
                      user.freeze !== true
                  )}
                  bgWhite={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ManageUser);

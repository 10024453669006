import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/detectors`;
} else {
  apiEndpoint = `${herokuUrl}/detectors`;
}

export async function checkTranslator(text){
  const response = await http.post(`${apiEndpoint}/checkTranslator`, text );
  return response;
}

export async function checkTranslatorLang(text){
    const response = await http.post(`${apiEndpoint}/translatorLang`,{text});
    return response;
  }

export default {
    checkTranslator,
    checkTranslatorLang
}
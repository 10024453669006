import React from "react";
// import { DatePicker } from "antd";
import Box from '@mui/material/Box';
// import "antd/dist/antd.css"; // Import Ant Design styles
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DateTimePicker } from "react-widgets";
import { DatePicker } from "antd";
import "../manualOpportunity.css";

const theme = createTheme({
  components: {
      MuiTextField: {
          styleOverrides: {
              root: {
                  '& .MuiInputBase-input': {
                      color: 'black',
                      border: 'none',
                      // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                      // transition: 'box-shadow 0.3s',
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: '700',
                      // fontSize: '14px',
                      fontSize: '0.9rem',
                      height:{screen:"ManualOpportunity"}&&"40px",
                  
                      '&:focus': {
                          color: 'black',
                          outline: 'none',
                          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
                      },
                  },
                  '& .MuiInputLabel-root': {
                      color: 'black',
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: "700",
                      
                      // fontSize: '14px',
                      fontSize: '0.9rem',
                      "&.Mui-focused": {
                          color: "black",
                      },
                      // '&:focus': {
                      //     color: '#646464',
                      // },
                  },
                  '& .MuiInputBase-multiline': {
                      // boxShadow: "none"
                      // minHeight: '10px', // Set a minimum height for multiline
                  },

              },
          },
      },
      MuiOutlinedInput: {
          styleOverrides: {
              notchedOutline: {
                  // borderColor: '#646464', // Change the outline border color
                  // borderWidth: '1px', // Add a border width
                  '&:focus': {
                      // borderColor: '#646464',
                  },
              },
              input: {
                  '&:focus': {
                      boxShadow: "none !important"
                  }
              },
              inputSingle: {
                  // border: 'none', // Remove the inner border for single-line input
                  // borderRadius: '8px', // Add border radius
                  // padding: '10px', // Add padding
              },
              inputMultiline: {
                  border: 'none', // Remove the inner border for multiline input
                  // borderRadius: '8px', // Add border radius
                  // padding: '10px', // Add padding
                  // padding: "none"
                  boxShadow: "none"
              },
          },
      },
      MuiInputBase: {
          styleOverrides: {
              root: {
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.3s',
              }
          }
      }
  },
});

const CustomDatePicker = ({ label, value, onChange, placeholder }) => {
  return (
    <ThemeProvider theme={theme}>
    <FormControl className="custom-date-picker" fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <DatePicker
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
      />
    </FormControl>
  </ThemeProvider>
  );
};

export default CustomDatePicker;

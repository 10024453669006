import React from "react";
import SearchOpportunity from "../SearchOpportunity";
// import SideNavBar from "../SideNavBar";
import SubAdminSideBar from "../SubAdminSideBar";

const SearchPage = () => {
  return (
    <div>
      <SubAdminSideBar>
        <SearchOpportunity />
      </SubAdminSideBar>
    </div>
  );
};

export default SearchPage;

import {
    apiUrl,
    herokuUrl
} from "./config.json";


import httpService from "./httpService";

let apiEndpoint = "";

if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/templateGallery`;

} else {
    apiEndpoint = `${apiUrl}/templateGallery`;

}


// export async function UploadTemplateInGallery(data) {

//     const response = await httpService.post(`${apiEndpoint}/UploadCategories/${data}`);

//     return response;
// }


// export async function UploadTemplateInGallery(category, file) {

//     console.log("Upload dtaaa",category, file)
//   try {
//     const formData = new FormData();
//     formData.append('file', file); // Append the file to the FormData object

//     // Make a POST request to the API endpoint with the FormData containing the file
//     const response = await httpService.post(`${apiEndpoint}/UploadCategories/${category}`, formData);

//     return response.data; // Assuming response contains the uploaded file information
//   } catch (error) {
//     console.error('Error uploading file:', error);
//     throw error;
//   }
// }


export async function UploadTemplateInGallery(category, file) {
    try {
      const formData = new FormData();
      formData.append('file', file); // Append the file to the FormData object
  console.log("upload tempppppp  ",category, file)
     // const apiEndpoint = 'http://your-backend-domain/api'; // Update with your backend API endpoint
      const response = await httpService.post(`${apiEndpoint}/UploadCategories/${category}`, formData);
  
      return response.data; // Assuming response contains the uploaded file information
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

export async function GetGalleryList(data) {



    const response = await httpService.post(`${apiEndpoint}/getTemplatesgallery`,({data}));

    return response;
}
// export async function GetPreviewContent(category, filename) {
//     console.log("preview Called   ",)


//     let isBinary = false
//     let doc = "doc"
//     if (filename.includes(".doc") || filename.includes(".docx")) {
//         isBinary = false
//         doc = "doc"
//     }
//     const response = await httpService.get(`${apiEndpoint}/getSectionDocument/${category}/${filename}`, isBinary,doc);
//      console.log("Responce  ", response)
//     return response;
// }

export async function GetPreviewContent(category, filename) {
    
    let isBinary = true
    let doc = null
    if (filename.includes(".doc") || filename.includes(".docx")) {
      isBinary = false
      doc = "doc"
    }
    const response = await httpService.get(`${apiEndpoint}/getByName24/${category}/${filename}`,isBinary,doc);
    return response;
}

export async function deleteGalleryTemplate(category, fileName,id) {

    const response = await httpService.post(`${apiEndpoint}/deleteTemplates`, { category, fileName,id });

    return response;
}

export async function UpdateFile(data, category, filename) {
console.log("state in service",  data, category, filename)


    const response = await httpService.post(`${apiEndpoint}/updateFile/${category}/${filename}`,{data});
    return response;
  }
  


export default {
    UploadTemplateInGallery,
    GetGalleryList,
    GetPreviewContent,
    deleteGalleryTemplate,
    UpdateFile

}








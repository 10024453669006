import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, IconButton, Modal, Card, CardContent, CardMedia, Typography, Grid, Menu, MenuItem, FormControl,FormControlLabel,Checkbox } from '@material-ui/core';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import WindowIcon from '@mui/icons-material/Window';
import DownloadIcon from '@mui/icons-material/Download';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Link } from 'react-router-dom';
import { DocumentGridCard, DocumentListCard } from './MyDocumentsCards';
import documentService from '../services/document';
import { ListItemText } from "@material-ui/core";
import { toast } from 'react-toastify';
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";




const MyDocumentsNew = () => {

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [listView, setListView] = useState(true)
    const [documents, setDocuments] = useState([])
    const [sortOrder, setSortOrder] = useState('asc');
    const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);

    
    const [myDocs, setMyDocs] = useState([]);
    const [deletedDocs, setDeletedDocs] = useState([]);
    const [sharedDocs, setSharedDocs] = useState([]);

    const [initialMyDocs, setInitialMyDocs] = useState([])
    const [initialDeletedDocs, setInitialDeletedDocs] = useState([])
    const [initialSharedDocs, setInitialSharedDocs] = useState([])


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Select all cards
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllChecked(isChecked);
        setMyDocs((prevDocuments) => {
            return prevDocuments.map((doc) => ({
                ...doc,
                isChecked: isChecked
            }));
        });

        // setDeletedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });

        // setSharedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });
    };

    // Download pdf and word doc
    const handleDownloadDropdownClick = (event) => {
        setDownloadAnchorElDropdown(event.currentTarget);
      };
    
    const handleDownloadDropdownClose = () => {
        setDownloadAnchorElDropdown(null);
      };


    const handleWordDownload=()=>{
        const selectedDocuments = documents.filter((docs) =>
            docs.isChecked
        );
  
        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }
        
        const wordDocContent = selectedDocuments
        .map(
            (doc) =>{
            const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
            let description = parsedJson.blocks[0].text;
            return `${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/>`
            }
        )
        .join('');

        
        asBlob(wordDocContent).then(docx => {
            saveAs(docx, `Documents.docx`);
            });
    }


    const handlePDFDownload = () => {
        const selectedDocuments = documents.filter((docs) => docs.isChecked);
    
        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }
    
        const pdfContent = selectedDocuments
            .map((doc) => {
                const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = parsedJson.blocks[0].text;
    
                // Customize the HTML structure as needed
                return `<div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div>`;
            })
            .join('');
    
        const element = document.createElement('div');
        element.innerHTML = pdfContent;
    
        html2pdf(element, {
            margin: 10,
            filename: 'Documents.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, 'Documents.pdf');
        });
    };
      
      
    

    const handleDropdownSelectforDownload = (selectedOption) => {
        console.log(selectedOption, "selected option");
        switch (selectedOption) {
          case "MS-Word":
            handleWordDownload()
            break;
          case "PDF":
            console.log("PDF")
            handlePDFDownload();
            break;
        }
        handleDownloadDropdownClose();
      };



    // Sorting documents
    const handleSortClick = () => {
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newSortOrder);
        const sortMyDocs = [...myDocs].sort((a, b) => {
        const titleA = a.documentName.toUpperCase();
        const titleB = b.documentName.toUpperCase();

        return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();
    
            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
            });
        const sortSharedDocs = [...sharedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();
    
            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
            });

        setMyDocs(sortMyDocs);
        setDeletedDocs(sortDeletedDocs);
        setSharedDocs(sortSharedDocs);
        
    };


    // Search Documents
    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase();
    
        const filteredMyDocs = initialMyDocs.filter((doc) =>
          doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
          doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredSharedDocs = initialSharedDocs.filter((doc) =>
          doc.documentName.toLowerCase().includes(newSearchTerm)
        );
    
        setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
        setDeletedDocs(() => (newSearchTerm ? filteredDeletedDocs : initialDeletedDocs));
        setSharedDocs(() => (newSearchTerm ? filteredSharedDocs : initialSharedDocs));
      };


    // Individual Card Selection
    const handleMyDocsCardChange = (index) => {
        setMyDocs((prevDocuments) => {
          const newDocuments = [...prevDocuments];
          newDocuments[index].isChecked = !newDocuments[index].isChecked;
          return newDocuments;
        });
      };

    // const handleDeletedDocsCardChange = (index) => {
    //     setDeletedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;
      
    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };

    // const handleSharedDocsCardChange = (index) => {
    //     setSharedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;
      
    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };



    // API Calls for getting docs, deleted docs and shared docs
    const getDocs = async () => {
    try {
        const response = await documentService.getAllDocuments();
        if (response.status === 200) {
            setMyDocs(response.data);
            setInitialMyDocs(response.data);
        } else {
        console.error("Error fetching data:", response.message);
        }
    } catch (error) {
        console.error("Error calling backend API:", error.message);
    }
    };

    const getDeletedDocs = async () => {
        try {
            const response = await documentService.getDeletedDocuments();
            if (response.status === 200) {
                setDeletedDocs(response.data);
                setInitialDeletedDocs(response.data);
            } else {
            console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getSharedDocs = async () => {
        try {
            const response = await documentService.getSharedDocuments();
            if (response.status === 200) {
                setSharedDocs(response.data);
                setInitialSharedDocs(response.data)
            } else {
            console.error("Error fetching data:", response.message);
            setSharedDocs([])
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
        };


    const getAllDocuments = () =>{
        getDocs(); 
        getDeletedDocs();
        getSharedDocs();
    }

    useEffect(() => {
        getAllDocuments()
      }, []);


      useEffect(() => {
        setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
    }, [myDocs, deletedDocs, sharedDocs]);
    
    
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField label="Search" variant="outlined" size="small" style={{ width: '100%' }} onChange={handleSearchChange}/>
                    </div>
                    <div className='col-md-3'>
                        <Link to="/mynewdocuments">
                            <Button variant="contained" color="primary"
                                startIcon={<AddIcon />} 
                                style={{ width: '100%' , whiteSpace:'nowrap' }}
                                >
                                New Document 
                            </Button>
                        </Link>
                    </div>
                    <div className='col-md-3'>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<UploadIcon />}
                            style={{ width: '100%' , whiteSpace:'nowrap' }}
                        >
                            Upload Document 
                        </Button>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4'>
                        <h3>Documents</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-7 mt-2'>
                        <FormControl>
                            <FormControlLabel
                                style={{ marginLeft: '10px' }}
                                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                                label={<Typography>Select All</Typography>}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-5 d-flex justify-content-end'>
                        <IconButton>
                            <CreateNewFolderIcon />
                        </IconButton>

                        <IconButton>
                            {listView ? <div title='Grid View'><WindowIcon onClick={() => setListView(false)} /></div> : <div title='List View'><TableRowsIcon onClick={() => setListView(true)} /></div>}
                        </IconButton>

                        <IconButton>
                            <div title='Download' 
                            onClick={handleDownloadDropdownClick}
                            ><DownloadIcon /></div>
                        </IconButton>

                        <IconButton>
                            <div title='Sort'><SortByAlphaIcon onClick={()=>handleSortClick()}/></div>
                        </IconButton>

                        <Menu
                            anchorEl={downloadAnchorElDropdown}
                            open={Boolean(downloadAnchorElDropdown)}
                            onClose={handleDownloadDropdownClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            >

                            <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
                                <ListItemText primary="MS-Word" />
                            </MenuItem>
                            
                            <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
                                <ListItemText primary="PDF" />
                            </MenuItem>


                        </Menu>

                    </div>
                </div>

                {(myDocs.length>0) && <div className='row'>
                    <div className='col-md-12 mt-1 mb-3'><hr/><h3>My Documents</h3></div>

                {myDocs.map((document, index) => {
                    const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                    let description = parsedJson.blocks[0].text;
                    
                    return (
                        <div className={listView ? 'col-md-12' : 'col-md-3 mt-3'} key={index}>
                            {listView ? (
                                <DocumentListCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                    getAllDocuments={getAllDocuments}
                                    type = {'MyDocuments'}
                                />
                            ) : (
                                <DocumentGridCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                    getAllDocuments={getAllDocuments}
                                    type = {'MyDocuments'}
                                />
                            )}
                        </div>
                    )
                            
                })}

                </div>}

                {(sharedDocs.length>0) && 
                <div className='row'>
                    <div className='col-md-12 mt-1 mb-3'><hr/><h3>Shared With Me</h3></div>

                { sharedDocs.map((document, index) => {
                    const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                    let description = parsedJson.blocks[0].text;
                    
                    return (
                        <div className={listView ? 'col-md-12' : 'col-md-3 mt-3'} key={index}>
                            {listView ? (
                                <DocumentListCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    //onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                    getAllDocuments={getAllDocuments}
                                    type = {'SharedWithMe'}
                                />
                            ) : (
                                <DocumentGridCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    //onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                    getAllDocuments={getAllDocuments}
                                    type = {'SharedWithMe'}
                                />
                            )}
                        </div>
                    )
                            
                })}

                </div>}

                {(deletedDocs.length>0) && 
                <div className='row'>
                    <div className='col-md-12 mt-1 mb-3'><hr/><h3>Deleted Documents</h3></div>

                 {deletedDocs.map((document, index) => {
                    const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                    let description = parsedJson.blocks[0].text;
                    
                    return (
                        <div className={listView ? 'col-md-12' : 'col-md-3 mt-3'} key={index}>
                            {listView ? (
                                <DocumentListCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    getAllDocuments={getAllDocuments}
                                    type = {'DeletedDocuments'}
                                />
                            ) : (
                                <DocumentGridCard
                                    title={document.documentName}
                                    description={description}
                                    cardChecked={document.isChecked || false}
                                    documentId = {document._id}
                                    getAllDocuments={getAllDocuments}
                                    type = {'DeletedDocuments'}
                                />
                            )}
                        </div>
                    )
                            
                })}

                </div>}
            </div>
        </>
    )
}



export default MyDocumentsNew
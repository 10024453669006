import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./LoginForm.css";
import { getHomeCMS } from "../services/adminServices";
import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import Login1 from "../static/login-3.png";
import {imgUrl} from "../services/config.json";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
}));

function SignUp({ history }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [values, setValues] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isTermsAccepted, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [disable, setdisable] = useState(true);
  const [showPassword] = useState(false);
  const [cms, setCms] = useState({});

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    getCms();
  }, []);

  async function handleRegisterSubmit() {
    setLoading(true);
    if (!username) {
      setLoading(false);
      toast.warn("Please enter the username");
      return;
    }
    if (!email) {
      setLoading(false);
      toast.warn("Please enter the email");
      return;
    }
    if (!password) {
      setLoading(false);
      toast.warn("Please enter the password");
      return;
    }
    if (!isTermsAccepted) {
      setLoading(false);
      toast.warn("Please accept the Terms & Conditions");
      return;
    }
    if (password.length < 6) {
      setLoading(false);
      toast.warn("Password must contain at least 6 characters");
    } else {
      let user = {
        username: username,
        email: email,
        password: password,
        referedEmail:
          localStorage.getItem("referedEmail") !== "undefined" || null
            ? localStorage.getItem("referedEmail")
            : "",
        accountType:
          localStorage.getItem("accountType") !== "undefined" || null
            ? localStorage.getItem("accountType")
            : "",
      };
      console.log(user);
      const result = await userService.register(user);
      console.log(result);
      if (result.status !== 200) {
        setLoading(false);
        toast.warn(result.message);
      } else {
        setLoading(false);
        localStorage.removeItem("referedEmail");
        localStorage.removeItem("accountType");
        toast.info(result.message);
        history.push({
          pathname: `/afterRegistration`,
          state: { detail: user },
        });
        // toast.info("Please check your email to verify");
      }
    }
  }
  function handleAcceptTerms(e) {
    console.log("value", e.target.checked);
    const isChecked = e.target.checked;
    setAcceptTerms(isChecked);
  }

  function handlePassword(e) {
    let pass = e.target.value;

    if (pass.length > 0) {
      if (pass.length <= 7) {
        setdisable(true);
        setErrors("Password contains minimum 8 Characters");
      } else {
        setErrors("");

        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (!pass.match(upperCaseLetters)) {
          setdisable(true);
          setErrors("Password contains at least 1 Capital letter");
        } else {
          setErrors("");
          if (!pass.match(numbers)) {
            setdisable(true);
            setErrors("Password contains at least 1 number");
          } else {
            setErrors("");
            setPassword(pass);
            setdisable(false);
          }
        }
      }
    } else {
      setErrors("");
    }
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="container-fluid" style={{ height: "100%" }}>
      <Loader loading={loading} />
      <div className="row" style={{ height: "100vh" }}>
        {/* Flex One */}
        <ToastContainer />

        <div
          className="col-md-5 p-4"
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Flex Two Content */}

          <div className="text-center mt-2">
            <Link to="/">
              <img
                // src={image1}
                // alt="image1"
                className="petLogo"
                alt="Group570"
                src={
                  cms && cms.Navbar_logo
                    ? cms.Navbar_logo.Image
                    : `{imgUrl}/pet-website-static-images/Group+571.png`
                }
                style={{
                  width: "40%",
                  height: "10%",
                  // objectFit: "contain",
                }}
              ></img>
            </Link>
          </div>

          <div
            style={{
              paddingLeft: "15%",
              paddingRight: "15%",
              paddingTop: "8%",
              paddingBottom: "2%",
              marginTop: "-1%",
            }}
          >
            <Card
              className="text-center"
              style={{ border: "none", borderRadius: "8px" }}
            >
              <div className="text-center pt-5">
                <h3 className="mainHeading">Welcome to PET</h3>
              </div>

              <div className="loginForm">
                <Form
                  // className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <InputGroup size="lg" className="mb-3 loginInput">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      label="User Name"
                      // value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>

                  <InputGroup size="lg" className="mb-3 loginInput">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="email"
                      label="Email ID"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email ID"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>

                  <InputGroup size="lg" className="mb-3 loginInput">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-key" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(e) => handlePassword(e)}
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <p className="p-2" style={{ color: "red", fontSize: "10px" }}>
                    {errors}
                  </p>
                </Form>
              </div>

              <div className="text-center" style={{ marginTop: "2%" }}>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    size="xl-lg"
                    type="checkbox"
                    inline
                    label="I agree with terms and conditions"
                    checked={isTermsAccepted}
                    onChange={(e) => handleAcceptTerms(e)}
                  />
                </Form.Group>
              </div>

              <div className="text-center" style={{ marginTop: "2%" }}>
                <Button
                  variant="primary"
                  className="loginBtnRegister"
                  disabled={disable}
                  onClick={handleRegisterSubmit}
                >
                  Sign up
                </Button>
              </div>

              <div
                className="row"
                style={{ marginTop: "8%", marginBottom: "8%" }}
              >
                <div className="col-md-12 text-center">
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "1.67",
                      textAlign: "center",
                      color: "#43425d",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="signinLink"
                      style={{ cursor: "pointer" }}
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </Card>

            <div className="text-center mt-4">
              <Link to="/privacypolicy" className="privacyLink">
                Term of use. Privacy policy
              </Link>
            </div>
          </div>
        </div>

        {/* Flex Two */}

        <div
          className="col-md-7"
          style={{
            backgroundImage: `url(${Login1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Flex One Content */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
export default withRouter(SignUp);

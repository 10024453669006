import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CoAuthor_Aireviewcomponent } from './Forms/FormComponents/CoAuthor_Aireview';

const AIReview_Modal = ({ handleClose }) => {
    return (
        <>
            <Modal show={true} onHide={handleClose} centered style={{marginTop:'3%', marginLeft:'30%'}} >
                <Modal.Header closeButton>
                    <Modal.Title>AI Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CoAuthor_Aireviewcomponent />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AIReview_Modal;

import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/performanceMetrics`;
} else {
  apiEndpoint = `${herokuUrl}/performanceMetrics`;
}

export async function savePerfomanceMatrics(data) {
  const response = await http.post(`${apiEndpoint}/savePerfomanceMatrics`, {
    data,
  });
  return response;
}

export async function getPerfomanceMatrics(data) {
  const response = await http.post(`${apiEndpoint}/getPerfomanceMatrics`, {
    data,
  });
  return response;
}

export async function getPerfomanceMatricsByAssetid(data) {
  const response = await http.post(
    `${apiEndpoint}/getPerfomanceMatricsbyAssetid`,
    { data }
  );
  return response;
}

export async function deletePerfomanceMatrics(data) {
  const response = await http.post(`${apiEndpoint}/deletePerfomanceMatrics`, {
    data,
  });
  return response;
}

export async function updatePerformanceMetrics(data) {
  const response = await http.post(`${apiEndpoint}/updatePerformanceMetrics`, {
    data,
  });
  return response;
}

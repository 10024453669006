import React, { useState, useEffect } from "react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useField,
} from "formik";
import * as Yup from "yup";
import deletes from "../../static/delete.png";
import plus from "../../static/plus.png";
import InputBoxTwo from "../Forms/FormComponents/InputBoxTwo";
import InputFeedback from "../Forms/FormComponents/InputFeedBack";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";
import { toast } from "react-toastify";
import { postTermsOfServiceCMS } from "../../services/adminServices"
import { Button } from 'react-bootstrap';
import HeadingB from "../Cards/HeadingB";

// const InputField = ({ placeholder, className, maxLength, ...props }) => {
//   const [field] = useField(props);
//   return (
//     <input
//       type="text"
//       {...field}
//       className={className}
//       placeholder={placeholder}
//       maxLength={maxLength || 1000}
//     />
//   );
// };

const TextArea = (props) => {
  return <textarea {...props} className="form-control" rows="4" />;
};

const schema = Yup.object({
  tos: Yup.array().of(
    Yup.object().shape({
        tos_title : Yup.string().required("Mandatory"),
        tos_subtext: Yup.string().required("Mandatory"),
    })
  ),
});

function TermsOfServiceCMS({ cmsData }) {
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState(cmsData.data);

  let value = {
    // tos: [
    //   {
    //     tos_title: "",
    //     tos_subtext: ""
    //   },
    // ],
    tos: cmsData.data
  };

  useEffect(() => {
    console.log("Values");
  }, []);

  return (
    <div>
      <div className="container bg-white rounded shadow mt-5 mb-5 pt-4">
        <div className="text-center" style={{ fontSize: "22px" }}>
        <HeadingB className="text-center alertHead pt-5" text="TERMS OF SERVICE" />
        </div>
        {/* <ToastContainer /> */}
        <Formik
         initialValues={value}
         validationSchema={schema}
         onSubmit={async (values, { resetForm }) => {
             setLoading(true)
           console.log(values.tos);
    
               let post = await postTermsOfServiceCMS(values);
               if(post.status === 200){
                   toast.success("Changes Updated");
                   
               }else{
                   toast.error("Failed To Save");
               }
           resetForm({});
           setLoading(false)
          }}
          render={({ values }) => (
            <Form>
              <Loader loading={loading} />

              <FieldArray
                name="tos"
                render={(arrayHelpers) => (
                  <div>
                    {values.tos && values.tos.length > 0
                      ? values.tos.map((tos, index) => (
                          <div
                            key={index}
                            style={{ marginLeft: "150px", marginTop: "10px" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "55px", marginLeft: "-10px" }}
                            >
                              <div className="col-md-8">
                             
                                  {/* About Title */}
                                  <label>Terms of Service Title</label>
                                  <hr/>
          
                                 <Field
                                    name={`tos[${index}].tos_title`}
                                    as={InputBoxTwo}
                                  />
                                  <ErrorMessage
                                  as={InputFeedback}
                                  name="tos[0].tos_title"
                                />
                                  
                              </div>
                                </div>
                                <div
                              className="row"
                              style={{ marginTop: "30px", marginLeft: "-10px" }}
                            >
                              
                              
                              <div className="col-md-8">
                              <label >Terms Of Service Sub-Bullet-Text</label>
                                <hr/>
                              </div>
                             
                              </div>
                                                    
                        
                              <div className="row">
                              <div className="col-md-8">
                            
                            <Field
                              name={`tos[${index}].tos_subtext`}
                              as={TextArea}
                            />
                            <ErrorMessage
                              as={InputFeedback}
                              name="tos[0].tos_subtext"

                            />
                            <label style={{color:"#ffc107"}}>Note: For every new bullet point please press enter</label>
                          
                          </div>
                          
                              
                           </div>
                             
                              
                              {values.tos && values.tos.length == 1 ? (
                                ""
                              ) : (
                               
                                  <div className="row" style={{ marginTop: "30px"}}>
                                    <div className="col-md-7">
                                    <Button variant="danger" className="w-50" onClick={() => arrayHelpers.remove(index)}>
                                                <i class="fa fa-trash" aria-hidden="true"></i>{" "}Delete Terms of Service
                                            </Button>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-3">
                                      </div>
                                   
                                   
                                   
                                </div>
                              )}
                           
                          </div>
                        ))
                      : ""}
                    <div
                      className="row"
                      style={{ marginTop: "20px", marginLeft: "150px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outlined-primary"
                        style={{ color: "#1976d2" }}
                        onClick={() =>
                          arrayHelpers.push({
                            tos_title: "",
                            tos_subtext: "",
                          })
                        }
                      >
                        <img src={plus} className="ft_sm mr-2" alt="plus" />
                        Add another Terms Of Service
                      </button>
                    </div>
                  </div>
                )}
              />

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}

export default TermsOfServiceCMS;


import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
 
const useStyles = makeStyles((theme) => ({
 
    timeline: {
        float: 'left',
        // Align the timeline to the left
        marginRight: theme.spacing(4), // Adjust spacing as needed
      },
  timelineItem: {
    float: "left",
    minHeight: '80px', // Adjust the height as needed
    display: 'flex',
    justifyContent: 'flex-end', // Align content to the right
  },
  oppositeContent: {
    marginRight: theme.spacing(2), // Adjust spacing as needed
  },
}));
 
const HistoryBar = ({ historyData, redirectingData }) => {
  console.log(redirectingData,"___redirectingData")
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory(); 
 
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when location changes
  }, [location]);
 
  const currentItemId = location.pathname.split('/').pop();

  const handleClick = (id) => {
    // Use history.push to navigate and pass state
    history.push({
      pathname: '/singleNoticePage',
      state: { documentId: id },
    });
  };
 
  return (
   
    <Timeline align="left">
      {redirectingData?.reverse()?.map((item, index) => {
        const date = new Date(item.postedDate);
 
        // Format date
        const formattedDate = date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          timeZoneName: 'short'
        });
 
        const isCurrentItem = currentItemId === item._id;
 
        return (
          <TimelineItem key={index} className={classes.timelineItem}>
            <TimelineSeparator>
              {isCurrentItem ? (
                <TimelineDot />
              ) : (
                // <Link to={`/singleNoticePage/${item._id}`}>
                  <TimelineDot onClick={() => handleClick(item._id)}/>
                // </Link>
              )}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {isCurrentItem ? (
                <Typography variant="body1">{item.type}</Typography>
              ) : (
                // <Link to={`/singleNoticePage/${item._id}`}>
                  <Typography variant="body1" onClick={() => handleClick(item._id)}>{item.type}</Typography>
                // </Link>
              )}
              <Typography variant="body2" color="textSecondary" className={classes.oppositeContent}>
                {formattedDate}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
 
  );
};
 
export default HistoryBar;
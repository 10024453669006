import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./MyDocuments.css";
import $ from "jquery";
import { connect } from "react-redux";
import {
    setUserDocument,
    setUserDeletedDocument,
    removeDeletedDocument,
} from "../redux/document/document.actions";
import { setCurrentUser } from "../redux/user/user.actions";
import ReactTour from "./ReactTour";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import SuggestionComp from "./ReusableComponents/SuggestionComp";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CopyToClipboard from "react-copy-to-clipboard";
import { checkCapitalization, checkCompression, checkEfficiency, checkExpansion, checkGrammar, checkPunctuation, checkRepetitiveWords, checkWordChoice, checkWordConfusion } from "../services/englishCheckers";
import _ from 'lodash'
import { Card } from "@mui/material";
import { Button } from "@material-ui/core";
import FeatureButton from "./ReusableComponents/Button";
// import { Modal } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import GrammarCheckAssistant from "./ReusableComponents/GrammarCheckAssistant";
import TagButtonComp from "./ReusableComponents/TagButtonComp";
import CheckboxMui from "./ReusableComponents/CheckBoxMui";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";

const steps = [
    {
        selector: ".first-step",
        content: "This helps you to submit your content for review.",
        position: "left",
    },
    {
        selector: ".second-step",
        content: "You can edit the document.",
        position: "bottom",
    },
    {
        selector: ".third-step",
        content: "You can compare the content of original document and changes made by you.",
        position: "bottom",
    },
];

const GrammarCheck = (data) => {
    const [state, setState] = useState({
        loading: false,
        documents: [],
        deletedDocuments: [],
        sharedDocuments: [],
        currentDocumentPage: 1,
        currentDeletedDocumentPage: 1,
        currentSharedDocumentPage: 1,
        pageSize: 6,
        searchQuery: "",
        totalDocumentCount: "",
        totalDeletedDocumentCount: "",
        totalSharedDocumentCount: "",
        isAdmin: false,
        modalIsOpen: false,
        downloadDocModal: false,
        downloadDocName: "",
        downloadDoCPDF_: "",
        shareDocumentId: "",
        NoDocs: false,
        dashLink: "",
        date: "",
        intervalId: 0,
        userFeatures: {},
        docLink: "",
        reactTour: false,
        paraText: ""
    });
    const [isTyping, setIsTyping] = useState(false);
    const [isSuggestion, setIsSuggestion] = useState(false)

    const [isFeatureButton, setIsFeatureButton] = useState(false)
    const [copyText, setCopyText] = useState("")
    const [sentenceResult, setSentenceResult] = useState({})
    const [selectedText, setSelectedText] = useState("")
    const [isGoalsOpen, setIsGoalsOpen] = useState(false)
    const [isAIOpen, setIsAIOpen] = useState(false)
    const [audience, setAudience] = useState({
        "general": false,
        "knowledgeable": false,
        "expert": false,
    })
    const [domain, setDomain] = useState({
        "academic": false,
        "business": false,
        "generalDomain": false,
        "email": false,
        "casual": false,
        "creative": false,
    })
    const [showGoalsCheck, setShowGoalsCheck] = useState(true)

    const [formality, setFormality] = useState({
        "neutral": false,
        "casual": false,
        "formal": false,
    })

    const [tone, setTone] = useState({
        "personable": false,
        "confident": false,
        "empathetic": false,
        "engaging": false,
        "witty": false,
        "direct": false,
    })

    const [professionList, setProfessionList] = useState([
        {
            name: "Content Writer",
            value: "Content Writer"
        },
        {
            name: "Copywriter",
            value: "Copywriter"
        },
        {
            name: "Technical writer",
            value: "Technical writer"
        },
        {
            name: "Research Analyst",
            value: "Research Analyst"
        },
    ])
    const [profession, setProfession] = useState("")

    const handleFormality = (e) => {
        console.log("handleFormality", e.target.id, formality)
        let objID = e.target.id
        setFormality({ ...formality, [objID]: !formality[objID] })
    }

    const handleTone = (e) => {
        console.log("handleTone", e.target.id, tone)
        let objID = e.target.id
        setTone({ ...tone, [objID]: !tone[objID] })
    }

    const handleProfession = (e) => {
        console.log("handleProfession", e)
        let value = e.target.value
        setProfession(value)
    }

    const updateAIAssistant = () => {
        console.log("updateAIAssistant",formality,tone,profession)
        closeAIAssistant()
    }
    useEffect(() => {
        console.log("data", data)
        const { userDocuments, deletedDocuments, features, manageUser } = data;
        setState((prevState) => ({
            ...prevState,
            userFeatures: features,
        }));
    }, []);

    useEffect(() => {
        $("#open").click(function () {
            $("#fileup").trigger("click");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCopyText = () => {
        toast("Copied")
    }

    const handleTextArea = (e) => {
        console.log("params_", e.target.value);
        let newText = e.target.value;
        // newText = newText.replace("\n","")
        console.log("newText_", newText)

        setState({ paraText: newText.replace("\n", ""), loading: false })
        setCopyText(e.target.value)
        setIsTyping(true);
    }
    const handleTagButton = (e) => {
        console.log("handleTagButton", e.target.id)
        let compId = e.target.id
    }

    const handleAudience = (e) => {
        console.log("handleAudience", e.target.id, audience)
        let audID = e.target.id
        // setAudience((preValue)=>({...preValue,id: !preValue[id]}))
        setAudience({ ...audience, [audID]: !audience[audID] })
    }

    const handleDomain = (e) => {
        console.log("handleDomain", e.target.id, audience)
        let domainID = e.target.id
        setDomain({ ...domain, [domainID]: !domain[domainID] })
    }

    const handleGoals = () => {
        setIsGoalsOpen(true)
    }

    const closeGoalsModal = () => {
        setIsGoalsOpen(false)
    }

    const handleShowGoalsCheck = () => {
        console.log("handleShowGoalsCheck")
        setShowGoalsCheck(!showGoalsCheck)
    }

    const handleSetGoals = () => {
        console.log("handleSetGoals", audience, domain, showGoalsCheck)
        closeGoalsModal()
    }

    const handleAIassistant = () => {
        setIsAIOpen(true)
    }

    const closeAIAssistant = ()=>{
        setIsAIOpen(false)
    }

    const checkRepetitiveWord = async (e) => {
        let data = {
            text: state.paraText
        }
        let repetitiveResponse = await checkRepetitiveWords(data)
        console.log("changedWords_", repetitiveResponse);

        if (repetitiveResponse.data !== "") {
            let repetitiveData = repetitiveResponse.data.output
            console.log("changedWords_", repetitiveData);

            let resResult = {
                changedWords: repetitiveData,
                heading: "Repetitive"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
        // setGrammerFeature({ repetition: repetitiveData })
    }

    const checkCapitalizationWords = async (e) => {
        console.log("capitalizeResponse_", selectedText)
        let data = {
            text: state.paraText
        }
        let capitalizeResponse = await checkCapitalization(data)
        console.log("repetitiveResponse_", capitalizeResponse.data.output)
        if (capitalizeResponse.data !== "") {
            let responseOutput = capitalizeResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Capitalize"
            }
            console.log("changedWords_", responseOutput);
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkPunctuationWords = async (e) => {
        let data = {
            text: state.paraText
        }
        let punctuationResponse = await checkPunctuation(data)
        if (punctuationResponse.data !== "") {
            let responseOutput = punctuationResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Punctuation"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }

        // let finalPunctuationResponse = {
        //     heading: "Punctuation",
        //     text: punctuationResponse.data.output,
        // }

        console.log("repetitiveResponse_", punctuationResponse.data.output)
    }

    const checkGrammarWords = async (e) => {
        let data = {
            text: state.paraText
        }
        let grammarResponse = await checkGrammar(data)
        if (grammarResponse.data !== "") {
            let responseOutput = grammarResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Grammar"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkWordsConfusion = async (e) => {
        let data = {
            text: state.paraText
        }
        let wordConfusionResponse = await checkWordConfusion(data)
        if (wordConfusionResponse.data !== "") {
            let responseOutput = wordConfusionResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Word Confusion"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkWordsCompression = async (e) => {
        let data = {
            text: state.paraText
        }
        let wordCompressionResponse = await checkCompression(data)
        if (wordCompressionResponse.data !== "") {
            let responseOutput = wordCompressionResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Word Compression"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkWordsEfficiency = async (e) => {
        let data = {
            text: state.paraText
        }
        let wordEfficiencyResponse = await checkEfficiency(data)
        if (wordEfficiencyResponse.data !== "") {
            let responseOutput = wordEfficiencyResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Word Efficiency"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkWordsExpansion = async (e) => {
        let array = []
        let data = {
            text: state.paraText || selectedText
        }
        let wordExpansionResponse = await checkExpansion(data)
        if (wordExpansionResponse.data !== "") {
            let responseOutput = wordExpansionResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Word Expansion"
            }

            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }

    const checkWordsChoice = async (e) => {
        let data = {
            text: state.paraText || selectedText
        }
        let wordExpansionResponse = await checkWordChoice(data)
        if (wordExpansionResponse.data !== "") {
            let responseOutput = wordExpansionResponse?.data?.output
            let resResult = {
                changedWords: responseOutput,
                heading: "Word Choice"
            }
            setSentenceResult(resResult)
            setIsFeatureButton(true)
        }
    }


    // useEffect(() => {
    //     let typingTimer;
    //     // Function to execute after user stops typing
    //     console.log("Parachanges_", state.paraText);
    //     const handleStoppedTyping = () => {
    //         setIsTyping(false);
    //         // Perform your action here when the user stops typing
    //         console.log('User has stopped typing:', state.paraText);
    //         if (state.paraText == "" || isSuggestion) {
    //             return
    //         }
    //         checkRepetitiveWord()
    //         checkCapitalizationWords()
    //         checkPunctuationWords()
    //     };
    //     // Clear the previous typing timer
    //     clearTimeout(typingTimer);

    //     // Set a new timer to determine when the user has stopped typing
    //     typingTimer = setTimeout(handleStoppedTyping, 2000); // Adjust the duration as needed (e.g., 1000ms = 1 second)

    //     // Cleanup function
    //     return () => {
    //         clearTimeout(typingTimer); // Clear the timer on component unmount or re-render
    //     };
    // }, [state.paraText]);

    const handleGrammarCheck = () => {
        console.log("grammerCheck_");
        checkGrammarWords()
    }

    const handleCaptalization = () => {
        console.log("captalization");
        checkCapitalizationWords()
    }

    const handlePunctuation = () => {
        console.log("Punctuation");
        checkPunctuationWords();
    }

    const handleRepetition = () => {
        // setIsFeatureButton(true)
        console.log("repetition")
        checkRepetitiveWord()
    }

    const handleWordConfusion = () => {
        console.log("WordConfusion")
        checkWordsConfusion()
    }

    const handleCompression = () => {
        console.log("WordCompression")
        checkWordsCompression()
    }

    const handleEfficiency = () => {
        console.log("WordEfficiency")
        checkWordsEfficiency()
    }

    const handleExpansion = () => {
        console.log("WordExpansion")
        checkWordsExpansion()
    }

    const handleWordChoice = () => {
        console.log("WordChoice")
        checkWordsChoice()
    }

    const handleSelect = () => {
        const selection = window.getSelection();
        console.log("selected_", selection)

        if (selection) {
            const selected = selection.toString();
            console.log("selected_", selected)
            // setState({ paraText: selected, loading: false })
            setSelectedText(selected);
        }
        // } else {
        //     setState({paraText:"",loading:false})
        // }
    };

    return (
        <div className="docCont">
            <Loader loading={state.loading} />
            <ToastContainer />
            <div className="row">
                <div className="col-md-4">
                    {/* text editor tools */}
                    <div>
                        <Card style={{ height: "50px", borderRadius: "10px", fontSize: "14px", fontWeight: "bolder", paddingTop: "13px", paddingLeft: "10px" }} >
                            Text Editor
                        </Card>
                    </div>
                    <div className="py-3">
                        <InputBoxMui
                            fullWidth
                            multiline={true}
                            rows={25}
                            placeholder={"Please enter the text"}
                            value={state.paraText}
                            onSelect={handleSelect}
                            onChange={e => handleTextArea(e)}
                        />
                    </div>
                    <div>
                        <Card style={{ height: "50px", borderRadius: "10px" }} />
                    </div>
                </div>

                <div className="col-md-2">

                    <div style={{ marginTop: "60px" }}>
                        {/* <button className="btn btn-primary"
                            onClick={handleAccept}
                        >Accept</button> */}
                        <FeatureButton text={"Grammar"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleGrammarCheck} />
                        <FeatureButton text={"Punctuation"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handlePunctuation} />
                        <FeatureButton text={"Word Confusion"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleWordConfusion} />
                        <FeatureButton text={"Captalization"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleCaptalization} />
                        <FeatureButton text={"Repetition"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleRepetition} />
                        <FeatureButton text={"Compression"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleCompression} />
                        {/* <FeatureButton text={"Efficiency"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleEfficiency} /> */}
                        <FeatureButton text={"Expansion"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleExpansion} />
                        <FeatureButton text={"Word Choice"} buttonClassName={"btn btn-primary featurebutton"} divClassName={"py-2"} onChange={handleWordChoice} />

                    </div>
                </div>
                <div className="col-md-4 mt-4">
                    {/* {isFeatureButton &&  */}
                    {isFeatureButton && <>
                        <div
                            className=" pt-3"
                            style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 600,
                                color: "#1A1A1A",
                                fontSize: "18px",
                            }}
                        >
                            Suggestions
                        </div>
                        <SuggestionComp changedSentence={sentenceResult} copiedText={copyText} setIsFeatureButton={setIsFeatureButton} setIsSuggestion={setIsSuggestion} setState={setState} />
                    </>
                    }
                </div>
                <div className="col-md-2 mt-2">
                    <GrammarCheckAssistant
                        handleGoals={handleGoals}
                        handleAIassistant={handleAIassistant}
                    />
                </div>
            </div>

            {/* End */}

            <ReactTour
                steps={steps}
                isOpen={state.reactTour}
                closeTour={() => setState({ reactTour: false })}
            />

            {/* Goals Modal start */}
            <Modal
                isOpen={isGoalsOpen}
                toggle={() => setIsGoalsOpen(!isGoalsOpen)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setIsGoalsOpen(!isGoalsOpen)}
                // className="text-center"
                >
                    <p
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 600,
                            color: "#303030",
                            fontSize: "1rem",
                        }}
                    >Set Goals</p>
                    <p
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                            color: "#6C6C6C",
                            fontSize: "0.9rem",
                        }}
                    >Convey the content using Set Goals customization</p>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Audience</p>
                        {/* <button
                            id="general"
                            style={{
                                boxShadow: "2px 2px 5px -2px #000000",
                            }}
                            className={`${audience.general ? "btn btn-primary" : "btn btn-outline-primary"}`}
                            onClick={handleAudience}
                        >
                            General
                        </button> */}
                        <TagButtonComp
                            id="general"
                            name="General"
                            className="ml-2"
                            onClickTagButton={handleAudience}
                        />
                        {/* <button
                            id="knowledgeable"
                            className="btn btn-primary ml-2"
                            onClick={handleAudience}
                        >
                            Knowledgeable
                        </button> */}
                        <TagButtonComp
                            id="knowledgeable"
                            name="Knowledgeable"
                            className="ml-2"
                            onClickTagButton={handleAudience}
                        />

                        <TagButtonComp
                            id="expert"
                            name="Expert"
                            className="ml-2"
                            onClickTagButton={handleAudience}
                        />

                    </div>
                    <div className="mt-3">
                        <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Domain</p>
                        <div>
                            {/* <button
                                id="academic"
                                style={{
                                    boxShadow: "2px 2px 5px -2px #000000",
                                }}
                                className={`${audience.academic ? "btn btn-primary" : "btn btn-outline-primary"}`}
                                onClick={handleAudience}
                            >
                                Academic
                            </button> */}
                            <TagButtonComp
                                id="academic"
                                name="Academic"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />
                            {/* <button
                                id="business"
                                className="btn btn-primary ml-2"
                                onClick={handleAudience}
                            >
                                Business
                            </button> */}

                            <TagButtonComp
                                id="business"
                                name="Business"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />
                            <TagButtonComp
                                id="generalDomain"
                                name="General"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />
                        </div>
                        <div className="mt-2">
                            <TagButtonComp
                                id="email"
                                name="Email"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />

                            <TagButtonComp
                                id="casual"
                                name="Casual"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />

                            <TagButtonComp
                                id="creative"
                                name="Creative"
                                className="ml-2"
                                onClickTagButton={handleDomain}
                            />

                        </div>
                    </div>
                    <div className="mt-4">
                        <CheckboxMui
                            label="Show goals when I start a new Document"
                            isChecked={showGoalsCheck}
                            onChange={(e) => handleShowGoalsCheck(e)}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-primary"
                            onClick={handleSetGoals}
                        >
                            Done
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* Goals Modal end */}

            {/* AI Assistant Modal start */}
            <Modal
                isOpen={isAIOpen}
                toggle={() => setIsAIOpen(!isAIOpen)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "33%",
                }}
            >
                <ModalHeader
                    toggle={() => setIsAIOpen(!isAIOpen)}
                // className="text-center"
                >
                    <p
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 600,
                            color: "#303030",
                            fontSize: "1rem",
                        }}
                    >AI Assistant</p>

                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Formality</p>

                        <TagButtonComp
                            id="neutral"
                            name="Neutral"
                            className="ml-2"
                            onClickTagButton={handleFormality}
                        />

                        <TagButtonComp
                            id="casual"
                            name="Casual"
                            className="ml-2"
                            onClickTagButton={handleFormality}
                        />

                        <TagButtonComp
                            id="formal"
                            name="Formal"
                            className="ml-2"
                            onClickTagButton={handleFormality}
                        />

                    </div>
                    <div className="mt-3">
                        <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Tone</p>
                        <div>
                            <TagButtonComp
                                id="personable"
                                name="Personable"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />

                            <TagButtonComp
                                id="confident"
                                name="Confident"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />

                            <TagButtonComp
                                id="empathetic"
                                name="Empathetic"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />
                        </div>
                        <div className="mt-2">
                            <TagButtonComp
                                id="engaging"
                                name="Engaging"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />

                            <TagButtonComp
                                id="witty"
                                name="Witty"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />

                            <TagButtonComp
                                id="direct"
                                name="Direct"
                                className="ml-2"
                                onClickTagButton={handleTone}
                            />

                        </div>
                    </div>
                    <div className="mt-4">
                        {/* <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Your Profession</p> */}

                        <SelectDropdownMui
                            optionsList={professionList}
                            selectLabel={"Your Profession"}
                            onChange={handleProfession}
                            value={profession}
                        />

                    </div>
                    <div className="mt-3 d-flex justify-content-end">
                        <button
                            className="btn btn-primary"
                            onClick={updateAIAssistant}
                        >
                            Update
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* AI Assistant Modal end */}
        </div>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    userDocuments: state.document.userDocuments,
    deletedDocuments: state.document.deletedDocuments,
    features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
    setUserDeletedDocument: (document) =>
        dispatch(setUserDeletedDocument(document)),
    setUserDocument: (document) => dispatch(setUserDocument(document)),
    removeDeletedDocument: (document) =>
        dispatch(removeDeletedDocument(document)),
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GrammarCheck));

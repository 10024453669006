import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GrammarReport from "./GrammarReport";
import ReactTour from "./ReactTour";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      border: "none",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

export default function CustomizedTabs({ handleChangeTab, openEditor }) {
  // const {  switchTab } = this.props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [reactTour, setReactTour] = useState(false);

  const steps = [
    {
      selector: ".reactour-1",
      content: "Click to view your blogs.",
      position: "bottom",
    }
  ];

  const stepsone = [
    {
      selector: ".reactour-1",
      content: "Click to view your blogs.",
      position: "bottom",
    },
    {
      selector: ".reactour-2",
      content: "Click to write a blog.",
      position: "bottom",
    },
  ];

  const handleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue);
    setValue(newValue);
    handleChangeTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          variant="scrollable"
        >
          <AntTab label="Latest Posts"></AntTab>
          <AntTab label="Your Posts" className="reactour-1"></AntTab>
          {value === 1 ? (
            <AntTab
              label="Write Blog"
              onClick={openEditor}
              className="reactour-2"
            ></AntTab>
          ) : (
            ""
          )}

          <i
            className="fa fa-question-circle mt-3"
            aria-hidden="true"
            data-toggle="tooltip"
            title="Need Help ?"
            style={{
              cursor: "pointer",
              color: "dodgerblue",
              fontSize: "15px",
              float: "right",
            }}
            onClick={() => setReactTour(true)}
          ></i>
        </AntTabs>
      </div>
      <ReactTour
        steps={value === 1 ? stepsone : steps}
        isOpen={reactTour}
        closeTour={() => setReactTour(false)}
      />
    </div>
  );
}

import React from "react";

const ImageCard = ({divclassName,bgColor,altImg, imgSrc, cardName, number, height, width, padding,subAdmin}) => {
    console.log(width,"subAdmin");
    return (
        <div
            className={divclassName}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: bgColor,
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                height: height,
                width: width || "100%",
                justifyContent: "space-between",
            }}
        >
            <div style={{width: "38%"}}>
            <img className="p-2 m-2"
                style={{ display: "block", margin: "0 auto" }}
                alt={altImg}
                src={imgSrc}
            />
            </div>
            <div style={{ marginLeft: "10px",textAlign: "center",paddingRight: "4px",width: "62%"}}>
            <p
                style={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: subAdmin && window.innerWidth>768 ? "1.3rem" : "1rem" ,
                    display: "block",
                    marginTop:"20px"
                }}
            >{cardName}</p>
            <p
                style={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: "1rem",
                    alignContent: "center",
                    marginBottom:"20px"
                }}
            >{number}</p>
            </div>
        </div>
    )
}

export default ImageCard
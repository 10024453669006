
import React, { Component } from "react";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Summeriser from "../Paraphrase/Summeriser/Summeriser";
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";
 
const SummeriserPage = (props) => {
  console.log(props.location.state);
  return (
    <SideNavBar>
   <Summeriser/>
    </SideNavBar>
  );
};
 
export default withRouter(NetworkDetector(SummeriserPage));
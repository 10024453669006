import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, List, ListItem, ListItemText, Typography, Box, Divider, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

const TerminateProjectModal = ({ open, handleClose, tasks, handleComplete, handleTerminateReason }) => {
  const [taskStatus, setTaskStatus] = useState([]);
  const [anyTaskSelected, setAnyTaskSelected] = useState(false);
  const [selectedReason, setSelectedReason] = useState(''); // State for dropdown

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      setTaskStatus(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    // Check if any task is selected whenever taskStatus changes
    setAnyTaskSelected(taskStatus.some(task => task.Status));
  }, [taskStatus]);

  const handleToggle = (index) => {
    const updatedTasks = taskStatus.map((task, i) =>
      i === index ? { ...task, Status: !task.Status } : task
    );
    setTaskStatus(updatedTasks);
  };

  const handleCompleteClick = () => {
    handleComplete(taskStatus);
  };

  const handleSubmit = () => {
    handleTerminateReason(selectedReason); // Pass the selected reason to parent component
    handleComplete(selectedReason);
    handleClose(); // Close the modal after submission
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '20%',
          right: '20%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxWidth: 400,
          margin: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Terminate Project</Typography>
          <Button variant="outlined" onClick={handleClose}>X</Button>
        </Box>
        <Divider />

        {/* Task list (optional, in case you want to keep it) */}
       
        <Divider sx={{ my: 2 }} />

        {/* Select reason to terminate the project */}
        <Typography variant="body1" gutterBottom>
          Select any one reason to terminate the project:
        </Typography>

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="terminate-reason-label">Reason</InputLabel>
          <Select
            labelId="terminate-reason-label"
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
            label="Reason"
          >
            <MenuItem value="Not Required">Not Required</MenuItem>
            <MenuItem value="Deadline Constraints">Deadline Constraints</MenuItem>
            <MenuItem value="Awardee canceled the request">Awardee canceled the request</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedReason} // Disable if no reason selected
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default TerminateProjectModal;

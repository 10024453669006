import React, { useEffect, useState } from "react";
import { createContext  } from "react";
import { connect } from "react-redux";
import ChatServices from "../services/ChatServices";
import { setCurrentUser } from "../redux/user/user.actions";
import { getUserInfo } from "../services/opportunityService";


export const ChatContext = createContext();


const ChatContextProvider = ({ children }) => {

  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedChat, setSelectedContactChat] = useState(null);
  const [selectedInboxId, setSelectedInboxId] = useState(null);
  
  const [colorIndex , setColorIndex] = useState('');
  const [audioURL, setAudioURL] = useState("");
  const [showFilePage , setShowFilePage] = useState(false);
  const [unreadMessagesCount , setUnreadMessagesCount] = useState(0);
  const [DeleteGroupId , setDeleteGroupId] = useState(null);
  const [track, setTrack] = useState('');
  const [audios, setAudios] = useState([]);
  const [userInfo, setUserInfo] = useState([])

  const Username = sessionStorage.getItem("currentUserEmail")


  const getCuurentUser = async (name) => {
    try {
      const response = await getUserInfo(name)
      setUserInfo(response.data)
      fetchUsersChat(response.data._id)
    } catch (error) {
      console.log(error)
    }
  }

  const userId  = userInfo?._id

  const fetchUsersChat = async (id) => {
    try {
      const response = await ChatServices.fetchMessages(id || userInfo?._id);
      if (response.status === 200) {
        console.log(response,"pipipoo")
        setContacts(response?.chatData?.contacts)
        setGroups(response?.chatData?.groups);
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }

  useEffect(() => {
    getCuurentUser(Username)
    // fetchUsersChat();
  }, []);


  return (
    <ChatContext.Provider
      value={{ groups, audios, setAudios, track, setTrack, DeleteGroupId, setDeleteGroupId,  contacts, colorIndex, unreadMessagesCount, setUnreadMessagesCount , showFilePage, setShowFilePage, audioURL, setAudioURL, selectedGroupId, setSelectedGroupId,  setColorIndex,  fetchUsersChat, userId , selectedInboxId, setSelectedInboxId, selectedContactId, setSelectedContactId, selectedChat, setSelectedContactChat }}
    >
      {children}
    </ChatContext.Provider>
  ); 
};

export default ChatContextProvider;
import React from 'react'
import AdminSideNavBar from "../AdminSideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import AddSubscription from '../AddSubscription';
function Addsubcription() {
    return (

        <AdminSideNavBar>
            <AddSubscription />
        </AdminSideNavBar>

    )
}

export default NetworkDetector(Addsubcription)
import React from "react";
import { withRouter } from "react-router-dom";
import { data } from "./PricePlans/data";
import AddPlan from "./PricePlans/AddPlan";

function AddSubscription() {
  return (
        <AddPlan />
  );
}

export default withRouter(AddSubscription);

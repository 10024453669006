import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
// import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/appfeature`;
} else {
  apiEndpoint = `${herokuUrl}/appfeature`;
}
export async function getfeaturelist() {
  let response = await http.get(`${apiEndpoint}/getappfeature`,);
  return response;  
 
}
export async function getEditDatalist(id) {
  let response = await http.post(`${apiEndpoint}/getappfeaturebyid`,id);
  return response;
 
}
export async function postfeaturelist(data) {
  let response = await http.post(`${apiEndpoint}/addappfeature`, data);
  return response;
}
 
export async function editfeaturelist(_id, updatedData) {
  let response = await http.put(`${apiEndpoint}/updateappfeature/${_id}`, updatedData);
  return response;
}
 
export async function deletefeaturelist(_id ) {
  let response = await http.delete(`${apiEndpoint}/deleteappfeature/${_id}`, (_id) );
  return response;
 
}
 
// console.log(response)
export default {
  getfeaturelist,
  postfeaturelist,
  editfeaturelist,
  deletefeaturelist,
  getEditDatalist
}

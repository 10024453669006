
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

const MultiSelectDropdownWithSearch = ({ optionList, onChange, labelKey, label, value, onDelete }) => {
  const [selectedItems, setSelectedItems] = useState([]);
 console.log(selectedItems,"items_")
  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);
 
  const handleOnChange = (event, newValue) => {
    setSelectedItems(newValue);
    onChange(newValue);
  };

  const handleDelete = (deletedItem) => {
    const updatedItems = selectedItems.filter(item => item[labelKey] !== deletedItem[labelKey]);
    setSelectedItems(updatedItems);
    onChange(updatedItems);
    onDelete(deletedItem); // Call onDelete function with the deleted item
  };

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        multiple
        id="multiple-select"
        options={optionList}
        getOptionLabel={(option) => option[labelKey]}
        value={selectedItems}
        onChange={handleOnChange}
        isOptionDisabled={(option) => selectedItems.some(item => item[labelKey] === option[labelKey])}
        style={{ width: '100%', background: "white" }}
        renderInput={(params) => (
          <TextField {...params} label={label || 'Select Items'} variant="outlined" />
        )}
        renderTags={(value, getTagProps) => (
          <div style={{ maxHeight: '60px', overflowY: 'auto', display: 'flex', flexWrap: 'wrap' }}>
            {value.map((option, index) => (
              <Chip
                key={index}
                label={option[labelKey]}
                {...getTagProps({ index })}
                onDelete={() => handleDelete(option)}
              />
            ))}
          </div>
        )}

      />
    </div>
  );
};

export default MultiSelectDropdownWithSearch;





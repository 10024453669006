import React, { useState, useEffect } from 'react';
import ShareFile from './shareFileModal';
import { Modal, Button } from 'react-bootstrap';
import document from '../services/document';
import { toast } from 'react-toastify';
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import adminServices from '../services/adminServices';


////         Share Functionality   ////////////////////////////

export const ShareOptionModal = ({ show, onHide, id }) => {
    const [documentData, setDocumentData] = useState(null);
    const [ allDocumentData, setAllDocumentData] = useState([])
    // const [shareFileKey, setShareFileKey] = useState(0);


    const getDocsById = async () => {
        try {
            const response = await document.getDocumentbyId(id);
            if (response.status === 200) {
                // Store the data in the state variable
                console.log(response.data)
                setDocumentData(response.data);

            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getDocs = async () => {
        try {
            const response = await document.getAllDocuments();
            if (response.status === 200) {
                setAllDocumentData(response.data);
               //setDocuments(response.data);
            } else {
            console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };
    

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
            getDocs()
        }
    }, [id]);

    return (
        <>
            <Modal show={show} onHide={onHide} centered id="shareOptionModal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Options
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        {/* Access ShareFile component here */}
                        <ShareFile
                            // key={shareFileKey}
                            shareDocumentId={documentData}
                            getDocuments={getDocs}
                            closeModal={onHide}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

///////////////   Delete Functionality    /////////////////////////////////////////

export const DeleteOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await document.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handleDelete = async () => {
        try {
            const data = await document.getDocumentbyId(documentData._id);

            if (data.status === 200) {
                if (data.data.activeDoc === true) {
                    if (documentData.status === "draft") {
                        if (documentData.reviewStatus === true) {
                            const og = await document.getDocumentbyId(documentData.orginalDocumentID);
                            if (og.status === 200) {
                                if (og.data.pendingDocuments.includes(documentData._id)) {
                                    toast.warn("Document is in Merging process");
                                    return;
                                }
                            }
                        }
                    }

                    const res = await document.deleteDocuments(documentData._id);

                    if (res.status === 200) {
                        toast.success("Document deleted!");
                        onHide(); // Close the modal after deletion
                        getAllDocuments()
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    alert("Document has already been deleted");
                    onHide(); // Close the modal
                }
            } else {
                alert("Error fetching document information");
            }
        } catch (error) {
            console.error("Error deleting document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="deleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


//////////////     Restore  Functionality           /////////////////////////////////////

export const RestoreOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await document.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handleRestore = async () => {
        try {
            const data = await document.getDocumentbyId(documentData._id);

            if (data.status === 200) {
                if (data.data.activeDoc === false) {
                    const res = await document.restoreDocuments(documentData._id);

                    if (res.status === 200) {
                        toast.success("Document restored!");
                        onHide(); // Close the modal after restoration
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    alert("Document is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                alert("Error fetching document information");
            }
        } catch (error) {
            console.error("Error restoring document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="restoreOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Restore Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to restore the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleRestore}>
                    Restore
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


/////////////////////   Delete Permanently  Functionality         ///////////////////////////////////

export const PermanentDeleteOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await document.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handlePermanentDelete = async () => {
        try {
            const data = await document.getDocumentbyId(documentData._id);

            if (data.status === 200) {
                if (data.data.activeDoc === false) {
                    const res = await document.permanentDelete({
                        _id: documentData._id,
                        drafts: documentData.drafts,
                        status: documentData.status,
                        orginalDocumentID: documentData.orginalDocumentID,
                        editor: documentData.editor,
                        pendingDocuments: documentData.pendingDocuments,
                        mergeDocuments: documentData.mergeDocuments,
                    });

                    if (res.status === 200) {
                        toast.success("Document deleted permanently!");
                        onHide(); // Close the modal after permanent deletion
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    alert("Document is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                alert("Error fetching document information");
            }
        } catch (error) {
            console.error("Error permanently deleting document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };


    return (
        <Modal show={show} onHide={onHide} centered id="permanentDeleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Permanent Deletion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to permanently delete the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handlePermanentDelete}>
                    Delete Permanently
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

/////////////////      Download Functionality         /////////////////////////////////////

export const DownloadOptionModal = ({ show, onHide, id }) => {
    const [documentData, setDocumentData] = useState(null);
  
    const getDocsById = async () => {
        try {
                const response = await document.getDocumentbyId(id);
                if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
        };
  
    useEffect(() => {
      // Fetch data when the component mounts or 'id' changes
      if (id) {
        getDocsById();
      }
    }, [id]);

    const handleDocxDownload = async () => {
        const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
        const contentHTML = stateToHTML(contentState);
    
        const htmlString = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>${documentData.documentName}</title>
        </head>
        <body>
            <h3>${documentData.documentName}</h3>
            <hr/>
            ${contentHTML}
        </body>
        </html>`;
    
        asBlob(htmlString).then(docx => {
        saveAs(docx, `${documentData.documentName}.docx`);
        onHide(); // Close the modal after the download
        });
    };

    const downloadDocPDF = async () => {
        try {
            const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
            const contentHTML = stateToHTML(contentState);
    
            // Include HTML content in the request payload
            const requestData = { ...documentData, content: contentHTML };
    
            // Assuming downloadDocPDF is an async function
            let res = await adminServices.downloadDocPDF(requestData);
    
            if (res.status === 200) {
                adminServices.downDocumentPdf(documentData._id, documentData.documentName);
                toast.success("Document downloaded!");
            } else {
                toast.error("Oops something went wrong");
            }
        } catch (error) {
            console.error("Error downloading document:", error.message);
            toast.error("Oops something went wrong");
        }
    };
    
    
  
    return (
      <Modal show={show} onHide={onHide} centered id="downloadOptionModal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Download Options
          </Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <div className="text-center">
            <span>Download as...</span>
            <br /> <br />
            <Button
              variant="primary"
              className="inviteMember mr-3 pl-5 pr-5"
              onClick={downloadDocPDF}
            >
              PDF
            </Button>
            <Button
              variant="primary"
              className="inviteMember pl-5 pr-5"
              onClick={handleDocxDownload}
            >
              Word
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  
  
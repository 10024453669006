import React, { useState, useEffect, useRef } from 'react';
import { getUserInfo } from '../../services/opportunityService';
import { Gettransitions, updateRolesToTransition } from '../../services/transitionServices';
import './TransitionWorkFlowModal.css';
import xCircle from '../Images/xCircle.png';
import { toast } from "react-toastify";
import Select from 'react-select';
import Modal from '@mui/material/Modal';
import { showToast } from '../../messages';


const TransitionWorkFlowModal = ({ isOpen, onClose, data, workflowid }) => {
  const [dropdownValues, setDropdownValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [orgid, setOrgId] = useState('');
  const [initialValues, setInitialValues] = useState([]);



  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const Username = sessionStorage.getItem("currentUserEmail");
        const response = await getUserInfo(Username);
        setOrgId(response.data.organizationId);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const fetchTransitionData = async () => {
    try {
      let data = {
        orgid,
        workflowid
      }
      const response = await Gettransitions(data);
      console.log("Dropdown data transition", response);
      if (response && response.Data) {
        setDropdownValues(response.Data);
            // Map the response data to include roles and comment
            const mappedValues = response.Data.map(item => ({
              transitionId: item.transitionId,
              roles: item.roles ? item.roles.map(role => ({ value: role, label: role })) : [],
              comment: item.comment || ""
            }));
    
            // Set the selectedValues state with the mapped data
            setSelectedValues(mappedValues);
            setInitialValues(mappedValues);

      
      } else {
        console.error("Failed to fetch dropdown values:", response ? response.message : "No response");
      }
    } catch (error) {
      console.log("Error fetching dropdown values:", error.message);
    }
  };

  useEffect(() => {
    if (isOpen && orgid && workflowid) {
      fetchTransitionData();
    } else {
      // Clear selected values when modal is closed
      setSelectedValues([]);
      setInitialValues([]);

    }
  }, [isOpen, orgid, workflowid]);

  const handleRoleChange = (transitionId, selectedOptions) => {
    setSelectedValues(prevState =>
      prevState.map(item =>
        item.transitionId === transitionId
          ? { ...item, roles: selectedOptions.map(option => option.value) }
          : item
      )
    );
  };

  const handleCommentChange = (transitionId, comment) => {
    setSelectedValues(prevState => {
      const existingIndex = prevState.findIndex(item => item.transitionId === transitionId);
      if (existingIndex !== -1) {
        prevState[existingIndex].comment = comment;
        return [...prevState];
      } else {
        return [...prevState, { transitionId, comment }];
      }
    });
  };



  const hasDataChanged = () => {

console.log("onee ", JSON.stringify(selectedValues) )
console.log("twoo ", JSON.stringify(initialValues) )


    return JSON.stringify(selectedValues) !== JSON.stringify(initialValues);
  };




  const RolesUpdateFunc = async () => {

    if (!hasDataChanged()) {
      toast.warn("No change detected.");
      return;
    }


    try {
      console.log("selectedValues",selectedValues)
      const data = selectedValues.map((data) => ({
        transitionId:data.transitionId,
        roles:[...data.roles],
        comment:data.comment
      }));
      console.log("Updated Data:", data);
      const response = await updateRolesToTransition(data);
      console.log("updateRolesToTransition==>", response);
     await fetchTransitionData();

      showToast('Success.dataUpdated')
      
    } catch (error) {
      toast.error("Error updating transitions: " + error);
      console.error("Error updating transitions:", error);
    }
  };

  const handleCloseModal = () => {
    // Clear selected values when modal is closed
    setSelectedValues([]);
    setInitialValues([]);

    onClose();
  };

  return (

    <Modal
    style={{position:"absolute", top:windowWidth < 500 ? "15%" : "15%", left:windowWidth < 500 ? "0" : "15%",right:windowWidth < 500 ? "15%" : ""}}
  open={isOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
      <div className="trans-modal-content">
      <div className='headingContainer'>
                    <div> <h2 className="headerTranst">Transition Table</h2></div>
                    <div className="closeshr-btn"><img src={xCircle} onClick={onClose} /></div>
      </div>
       
        <div className="trans-table-container">
          <table className="trans-table">
            <thead>
              <tr>
                <th>Transition Name</th>
                <th>From Stage</th>
                <th>From Status</th>
                <th>To Stage</th>
                <th>To Status</th>
                <th>Roles</th>
                <th>Conditions</th>
              </tr>
            </thead>
            <tbody>
              {dropdownValues && dropdownValues.map((row, index) => (
                <tr key={index}>
                  <td>{row.transitionName}</td>
                  <td>{row.fromStageName}</td>
                  <td>{row.fromStatusName}</td>
                  <td>{row.toStageName}</td>
                  <td>{row.toStatusName}</td>
                  <td>
                  <Select
                    options={data.map(item => ({ value: item.rolename, label: item.rolename }))}
                    onChange={(selectedOptions) => handleRoleChange(row.transitionId, selectedOptions)}
                    isMulti
                    // Ensure roles are formatted as { value, label }
                  />
                </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <input
                      type="text" placeholder="Enter the Conditions"
                      value={selectedValues.find(item => item.transitionId === row.transitionId)?.comment || ''}
                      onChange={(e) => handleCommentChange(row.transitionId, e.target.value)}
                      style={{ border: 'none', outline: 'none', width: '100%', backgroundColor: 'transparent' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="trans-button-container">
          <button className="trans-update-button" onClick={RolesUpdateFunc}>Update</button>
        </div>
      </div>
      </Modal>

  );
};

export default TransitionWorkFlowModal;

import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// CustomDropdown Component
const CustomDropdown = ({ options, onSelectChange, disabled, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onSelectChange(option.value);
    setIsOpen(false);
  };

  return (
    <div className={`custom-dropdown ${disabled ? "disabled" : ""}`}>
      <div
        className="custom-dropdown-selected"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {selectedOption ? options.find(option => option.value === selectedOption).label : "Select an option"}
        <span className="dropdown-arrow"><KeyboardArrowDownIcon/></span> {/* Down arrow icon */}
      </div>
      {isOpen && (
        <ul className="custom-dropdown-list">
          {options.map((option, index) => (
            <li
              key={index}
              className="custom-dropdown-option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const RevenueChart = ({ data, boxWidth, boxHeight, selectedChartType, setSelectedChartType }) => {
  const currentYear = new Date().getFullYear();
  const next20Years = Array.from({ length: 20 }, (_, index) => currentYear + index);

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleChartTypeChange = (value) => {
    setSelectedChartType(value);
  };

  const monthlyChartData = data.map(entry => ({
    ...entry,
    year: new Date(entry.month + ' 1, ' + entry.year).getFullYear(),
  }));

  const yearlyChartData = data.map(entry => ({
    year: entry.year,
    basicPlan: entry.basicPlan,
    premiumPlan: entry.premiumPlan,
  }));

  const chartData = selectedChartType === 'monthly' ? monthlyChartData : yearlyChartData;

  const ChartComponent = selectedChartType === 'monthly' ? LineChart : BarChart;

  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 5px -2px #000000',
        padding: '2px',
        width: boxWidth || '100%',
        height: boxHeight || 'auto',
      }}
    >
      <div style={{ marginTop: '10px', display: 'flex',justifyContent:'space-between', padding:"20px" }}>
        <div>
          <CustomDropdown
            options={[
              { value: 'monthly', label: 'Monthly Revenue' },
              { value: 'yearly', label: 'Yearly Revenue' }
            ]}
            onSelectChange={handleChartTypeChange}
            selectedOption={selectedChartType}
          />
        </div>
        <div>
          {selectedChartType === 'monthly' && (
            <CustomDropdown
              options={next20Years.map(year => ({ value: year, label: year.toString() }))}
              onSelectChange={handleYearChange}
              selectedOption={selectedYear}
            />
          )}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <ChartComponent data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={selectedChartType === 'monthly' ? 'month' : 'year'} />
          <YAxis />
          <Tooltip />
          <Legend />
          {selectedChartType === 'monthly' ? (
            <>
              <Line type="monotone" dataKey="basicPlan" stroke="#DA4141" strokeWidth={2} activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="premiumPlan" stroke="#2E75B6" strokeWidth={2} activeDot={{ r: 8 }} />
            </>
          ) : (
            <>
              <Bar dataKey="basicPlan" fill="#DA4141" />
              <Bar dataKey="premiumPlan" fill="#2E75B6" />
            </>
          )}
        </ChartComponent>
      </ResponsiveContainer>
    </div>
  );
};

// Custom CSS styling
const styles = `
.custom-dropdown {
  position: relative;
  width: 100%;
  margin-left: 0;
}

.custom-dropdown.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.custom-dropdown-selected {
  border: 1px solid #1976D2;
  border-radius: 10px;
  box-shadow: 1px 1px 4px -2px #000000;
  padding: 5px;
  background-color: #ffffff;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.custom-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 1px 1px 4px -2px #000000;
  z-index: 1000;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 150px;
  overflow-y: auto;
}

.custom-dropdown-option {
  padding: 5px;
  cursor: pointer;
}

.custom-dropdown-option:hover {
  background-color: #1976D2;
  color: #ffffff;
}
`;

// Inject custom CSS styling
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default RevenueChart;

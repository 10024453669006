import React, { Component } from "react";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
//import Citation from "../Forms/Citation";
import Citation1 from '../Forms/Citation-1'
import CoAuthor from '../Forms/CoAuthor'
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";

const CitationPage = (props) => {
  console.log(props.location.state);
  return (
    <SideNavBar>
      {/* <Citation value={props.location.state} /> */}
      <CoAuthor/>
    </SideNavBar>
  );
};

export default withRouter(NetworkDetector(CitationPage));

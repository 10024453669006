import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import { Modal as B_Modal, Form } from "react-bootstrap";

import { Tabs, Tab, Box, AppBar, InputAdornment, FormControlLabel, Select, Modal, FormControl, InputLabel, NativeSelect, TextField, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Link as MuiLink, MenuItem, Menu, Button, IconButton, Card, CardContent } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FileCopy as CopyIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import CloseIcon from '@material-ui/icons/Close';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import citationService from '../../services/citationService';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './FormComponents/Citation-1.css'
import { CitationCard} from './CitationCard';

import 'react-toastify/dist/ReactToastify.css';

import AddIcon from '@mui/icons-material/Add';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import CitationModal from './CitationModal';






const Citation1 = () => {
  const [initialCitations, setInitialCitations] = useState([])
  const [citations, setCitations] = useState([]);



  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [checkedCards, setCheckedCards] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);


  const [anchorEl, setAnchorEl] = useState(null);
  const [citationStyle, setCitationStyle] = useState("");
  const [searchCitationStyle, setSearchCitationStyle] = useState("");


  const [searchInput, setSearchInput] = useState("");
  const [websiteData, setWebsiteData] = useState(null);
  const [show, setShow] = useState(false);
  const [sourceChecked, setSourceChecked] = useState(false);
  const [totalSource,setTotalSource]=useState(0)
  const [isCopied, setIsCopied] = useState(false);


  const [contributors, setContributors] = useState([
    { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
  ]);

  const [anchorElDropdown, setAnchorElDropdown] = useState(null);
  const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
  const [professionList, setProfessionList] = useState([
    
    {
      "value": "AMA 10th edition",
      "name": "AMA 10th edition"
    },
    {
      "value": "American Chemical Society",
      "name": "American Chemical Society"
    },
    {
      "value": "APA 11th edition",
      "name": "APA 11th edition"
    },
    {
      "value": "APA 6th edition",
      "name": "APA 6th edition"
    },
    {
      "value": "APA 7th edition",
      "name": "APA 7th edition"
    },
    {
      "value": "Chicago author",
      "name": "Chicago author"
    }
  ]);

  const [publishSort, setPublishSort] = useState([])
  const [displayByDate,setDisplayByDate]=useState(false)
  const [totalSort,setTotalSort]=useState();
  const [sourceTypeSort, setSourceTypeSort] = useState([])

  const [formData, setFormData] = useState({
    heading: "",
    title: "",
    description: "",
    websiteName: "",
    contributors: [],
    url: "",
    publicationDate: {
      year: "",
      month: "",
      day: "",
    },
    accessDate: {
      year: "",
      month: "",
      day: "",
    },
    placeOfPublication:{
      country: "",
      state : "",
      city : ""
    }
    // sourceType: ''
  });



  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAccordion = () => {
    setWebsiteData(null);
  };

  const handleModalClose = () => {
    setShow(false);
  }
  const handleModalShow = () => setShow(true);

  const handleCopyClick = () => {
    setIsCopied(true);
    toast.success("Text copied")
  };

  const showConfirmationToast = (message, onConfirm, onCancel) => {
    const toastId = toast.info(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{marginBottom:'10px'}}><h4>Delete Citation</h4></div>
        <p style={{ marginBottom: '10px' }}>{message}</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Delete Citation
          </button>
          <button
            onClick={() => {
              onCancel();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Do not auto-close the toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  
    return toastId;
  };
  

  const handleSourceCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSourceChecked(isChecked);

    // Update the state of all checkboxes in the cards based on the "Source" checkbox
    const updatedCitations = citations.map((citation) => ({
      ...citation,
      isChecked: isChecked,
    }));

    setCitations(updatedCitations);
  };

  const handleCardCheckboxChange = (citationId) => {
    // Update the state of the clicked checkbox only
    const updatedCitations = citations.map((citation) => {
      if (citation._id === citationId) {
        return {
          ...citation,
          isChecked: !citation.isChecked,
        };
      }
      return citation;
    });

    // Update the state of the checked cards
    const updatedCheckedCards = updatedCitations
      .filter((citation) => citation.isChecked)
      .map((citation) => citation._id);

    setCheckedCards(updatedCheckedCards);
    setCitations(updatedCitations);
  };

  const handleCopySelectedClick = () => {
    // Filter out the checked cards
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );

    if (selectedCitations == "") {
      toast.warn(`Select citations to copy`);
      return;
    }


    // Concatenate the data of checked cards for copying
    const copiedText = selectedCitations
      .map((citation) => `Title: ${citation.title}\nFull Citation: ${citation.fullCitation}\nIn-Text Citation: ${citation.publicationDate.year}\n\n`)
      .join("");

    // Use the copiedText as needed (e.g., copy to clipboard)
    navigator.clipboard.writeText(copiedText)

    toast.success("Citations copied to clipboard!");
  };

  
  const createBlobAndDownload = (content, fileType, fileName) => {
    const blob = new Blob([content], { type: fileType });
  
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
  };
  
  const handleBibtexDownload = () => {
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );
  
    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to download`);
      return;
    }
  
    const bibtexContent = selectedCitations
      .map(
        (citation) =>
          `Title: ${citation.title}\nFull Citation: ${citation.fullCitation}\nIn-Text Citation: ${citation.publicationDate.year}\n\n`
      )
      .join('');
  
    createBlobAndDownload(
      bibtexContent,
      'application/x-bibtex',
      'citations.bib'
    );
  
  };
  
  const createPdfDocument = async (citations) => {
    // Assuming citations is an array of objects with properties: title, fullCitation, and publicationDate
    const pdfContent = citations
        .map((citation) => {
            return `<div>
                        <h5>Title: ${citation.title}</h5>
                        <p>Full Citation: ${citation.fullCitation}</p>
                        <p>In-Text Citation: ${citation.publicationDate.year}</p><br/>
                    </div>`;
        })
        .join('');

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    return html2pdf(element, {
        margin: 10,
        filename: 'citations.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });
};

const handlePDFDownload = async () => {
    const selectedCitations = citations.filter((citation) => citation.isChecked);

    if (selectedCitations.length === 0) {
        toast.warn(`Select citations to download`);
        return;
    }

    try {
        const pdf = await createPdfDocument(selectedCitations);
        saveAs(pdf, 'citations.pdf');
    } catch (error) {
        console.error("Error generating PDF:", error);
        toast.error("Oops, something went wrong");
    }
};
  
  const handleWordDownload = () => {
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );
  
    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to download`);
      return;
    }
  
    const wordDocContent = selectedCitations
      .map(
        (citation) =>
          `Title: ${citation.title}\nFull Citation: ${citation.fullCitation}\nIn-Text Citation: ${citation.publicationDate.year}\n---\n`
      )
      .join('\n');

    const blob = new Blob([wordDocContent], { type: 'application/msword' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'citations.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };
  




  const handleDeleteSelectedClick = async () => {
    // Filter out the checked cards
    const selectedCitations = citations.filter((citation) => citation.isChecked);
  
    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to delete`);
      return;
    }
  
    // Extract the IDs of selectedCitations
    const selectedIds = selectedCitations.map((citation) => citation._id);
  
    // Show a confirmation toast
    const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?", 
      () => handleConfirmDeleteSelected(selectedIds, toastId), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );
  
    // ... (existing code)
  };
  
  const handleConfirmDeleteSelected = async (selectedIds, toastId) => {
    try {
      // Call the API to delete the selected citations
      const response = await citationService.deleteSelectedCitations(selectedIds);
  
      console.log("Backend Response:", response);
      if (response.status === 200) {
        toast.success("Deleted successfully");
        fetchCitationDetails();
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  
    // Dismiss the confirmation toast
    toast.dismiss(toastId);
  };



  const fetchCitationDetails = async () => {
    try {
      const email = localStorage.getItem("currentUserEmail");
      let data={email}
      const response = await citationService.getCitationDetails(data);
      if (response.status === 200) {
        setInitialCitations(response.citationDetails);
        setTotalSource(response.citationDetails.length);
        setCitations(response.citationDetails);
        setTotalSort('');
        setDisplayByDate(false)
      } else {
        console.error("Error fetching citation details:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  useEffect(() => {
    fetchCitationDetails(); // Fetch data when the component mounts
  }, []);

  const yearsArray = [...new Set(initialCitations.map(citation => citation.publicationDate.year))];

  console.log(yearsArray,'array');
  const citationsByYear = yearsArray.map(year => ({
    year,
    citations: initialCitations.filter(citation => citation.publicationDate.year === year),
  }));
console.log(citationsByYear,"byYear")

  //////////////////////////////////////////////     SORTING       &&&&     SEARCHING                   ///////////////////////////////////////////////////////


  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    const filteredCitations = initialCitations.filter((citation) =>
      citation.title.toLowerCase().includes(newSearchTerm)
    );

    setCitations((prevCitations) => (newSearchTerm ? filteredCitations : initialCitations));
  };



  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortedCitations = [...initialCitations].sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });

    setInitialCitations(sortedCitations);
    setCitations(sortedCitations);
  };

  //////////////////////////////////////////////     SORTING       &&&&     SEARCHING                   ///////////////////////////////////////////////////////



  let suggestionArray = [];


  const isValidURL = (url) => {
    // Check if the URL ends with '.com' or any other specific pattern
    const validUrlPattern = new RegExp('\\.(com|io|org|net|gov|edu|co)$', 'i');
  
    // If the user entered 'https://' or 'www.', strip it
    const cleanedUrl = url.replace(/^(https?:\/\/|www\.)/i, '');
  
    return validUrlPattern.test(cleanedUrl);
  };
  
  const validateInput = (selectedValue, searchInput) => {
    if (selectedValue === 0 && !isValidURL(searchInput)) {
      toast.warning("Please enter a valid URL");
      return false;
    }
  
    // Add more validation checks for other tabs if needed
  
    return true;
  };
  
  const handleCiteButtonClick = async () => {
    if (!searchInput.trim()) {
      toast.warn("Search bar is empty");
      return;
    }


    if (validateInput(selectedValue, searchInput)) {
      try {
        const response = await citationService.getCheckCitation(searchInput, citationStyle);
        if (response.status === 400) {
          toast.error("Error fetching data");
          return;
        }
  
        console.log("Backend Response:", response);
        let responseOutput = response?.data?.output;
        const parsedData = JSON.parse(responseOutput);
        suggestionArray = parsedData.replacements?.corrections;
        console.log("changedJSon_", suggestionArray, parsedData);
      } catch (error) {
        toast.warn("Error");
        console.error("Error calling backend API:", error.message);
      }
  
      setWebsiteData(suggestionArray);
    }
  };



  // Handle save logic here


  // const handleSaveCitations = async () => {
  //   // Include contributors data in the formData state
  //   const updatedFormData = {
  //     ...formData,
  //     contributors: contributors,
  //   };
  //   try{
  //     const response = await citationService.getCheckCitation(updatedFormData.title, citationStyle);
  //   if (response.status===400){
  //     toast.error("Error fetching data")
  //     return
  //   }

  //   console.log("Backend Response:", response);
  //   let responseOutput = response?.data?.output
  //   const parsedData = JSON.parse(responseOutput);
  //   suggestionArray = parsedData.replacements?.corrections
  //   console.log("changedJSon_", suggestionArray, parsedData)
  // } catch (error) {
  //   console.error("Error calling backend API:", error.message);
  // }

  //   // Handle save logic here using updatedFormData
  //   try {
  //     const response = await citationService.getAddCitation2(suggestionArray[0]);
  //     console.log("Backend Response:", response);
  //     if(response.status == 200){
  //       toast.success("Citation saved successfully")
  //       fetchCitationDetails()
  //     }
  //   } catch (error) {
  //     console.error("Error calling backend API:", error.message);
  //   }

  //   handleModalClose();
  // };



  const handleSaveCitations = async () => {
    const email = localStorage.getItem("currentUserEmail")
    // Include contributors data in the formData state
    const updatedFormData = {
      ...formData,
      email:email,
      contributors: contributors,
    };

    console.log(updatedFormData,"TESTIIIIIIIIIIINNNNNNNNNNNGGGGGGGGGGGGGGGGGGG")


    // Handle save logic here using updatedFormData
    try {
      const response = await citationService.getAddCitation(updatedFormData, citationStyle);
      console.log("Backend Response:", response);

      if (response.status == 200) {
        toast.success("Citation saved successfully")
        fetchCitationDetails()
        setFormData({
          heading: "",
          title: "",
          description: "",
          websiteName: "",
          contributors: [],
          url: "",
          publicationDate: {
            year: "",
            month: "",
            day: "",
          },
          accessDate: {
            year: "",
            month: "",
            day: "",
          },
        })
        setContributors([
          { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
        ])
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

    setSelectedValue("")
    handleModalClose();
  };


  const handleSaveCitations2 = async () => {
    // Handle save logic here
    const email = localStorage.getItem("currentUserEmail")
    try {
      const response = await citationService.getAddCitation2(websiteData[0], email);
      console.log("Backend Response:", response);
      if (response.status == 200) {
        toast.success("Citation saved successfully")
        fetchCitationDetails()
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

    handleClose();

  };

  const handleDeleteCitation = async (id) => {
    // Show a confirmation toast
    const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?", 
      () => handleConfirmDeleteCitation(id, toastId), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );
  
  };
  
  const handleConfirmDeleteCitation = async (id, toastId) => {
    try {
      // Call the API to delete the citation
      const response = await citationService.deleteCitation(id);
  
      console.log('Backend Response:', response);
      if (response.status === 200) {
        toast.success('Deleted successfully');
        fetchCitationDetails();
      }
    } catch (error) {
      console.error('Error calling backend API:', error.message);
    }
  };

  // const handleCitationStyleChange = async (event) => {
  //   const email = localStorage.getItem("currentUserEmail");
  //   const selectedCitationStyle = event.target.value;
  //   if(selectedCitationStyle=="All Citations"){
  //     setSearchCitationStyle(selectedCitationStyle)
  //     fetchCitationDetails()
  //     return
  //   }
  //   setSearchCitationStyle(selectedCitationStyle);
    
  
  //   try {
      
  //     const response = await citationService.getCitationDetailsByStyle(selectedCitationStyle,email);
  //     if(response.status === 404){
  //       setCitations([])
  //       toast.warn("Citations not found")
  //     }
  //     if (response.status === 200) {
  //       setCitations(response.citationData);
  //     } else {
  //       console.error("Error fetching citation details:", response.message);
  //     }
  //   } catch (error) {
  //     console.error("Error calling backend API:", error.message);
  //   }
  // };


  //  for grid functionality

  const handleDropdownClick = (event) => {
    setAnchorElDropdown(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorElDropdown(null);
  };

  const handleDownloadDropdownClick = (event) => {
    setDownloadAnchorElDropdown(event.currentTarget);
  };

  const handleDownloadDropdownClose = () => {
    setDownloadAnchorElDropdown(null);
  };

  const handleNoGroup = () => {
    // Reset to the initial order
    setCitations(initialCitations);
    setTotalSort('')
    alert(totalSort)
  };
  // const handleDropdownSelect = (option) => {
  //   // Implement logic based on the selected option
  //   console.log(`Selected option: ${option}`);
  //   handleDropdownClose();
  // };

  const handleSortBySourceType = () => {
    const groupedCitations = {};
    console.log(initialCitations, "init cia");
    // Group citations by source type
    initialCitations.forEach(citation => {
      const sourceType = citation.sourceType || "Unknown";

      if (!groupedCitations[sourceType]) {
        groupedCitations[sourceType] = [];
      }

      groupedCitations[sourceType].push(citation);
    });

    // Create a flat array by concatenating groups
    const sortedCitations = Object.values(groupedCitations).flatMap(group => group);
    console.log(sortedCitations, "srted ci");
    setSourceTypeSort(sortedCitations);
    setTotalSort(sortedCitations.length)
    setCitations(sortedCitations);
    setDisplayByDate(false)
  };



  const handleSortByPublishedYear = () => {
    console.log("Before sorting:", initialCitations);

    const sortedCitations = [...initialCitations].sort((a, b) => {
      const yearA = a.publicationDate.year;
      const yearB = b.publicationDate.year;
      return sortOrder === "asc" ? yearA - yearB : yearB - yearA;
    });

    console.log("After sorting:", sortedCitations);

    setPublishSort(sortedCitations);
    setTotalSort(sortedCitations.length)
    setCitations(sortedCitations);
    setDisplayByDate(true)
  };


  const handleDropdownSelectforGrid = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "No Group":
        console.log("no group selected");
        fetchCitationDetails()
        console.log("Citations after resetting:", citations);
        break;
      case "Source Type":
        handleSortBySourceType();
        break;
      case "Published Year":
        console.log("published year selected");
        handleSortByPublishedYear();
        break;
      default:
        handleNoGroup();
        break;
    }
    handleDropdownClose();
  };

  const handleDropdownSelectforDownload = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "MS-Word":
        handleWordDownload()
        break;
      case "BibTeX":
        handleBibtexDownload()
        break;
      case "PDF":
        handlePDFDownload();
        break;
    }
    handleDropdownClose();
  };

  
  const Navtabs = () => {
  
    const handleTabChange = (event, newValue) => {
      setSelectedValue(newValue);
      
    };
  
    const handleSelectChange = (event) => {
      setSelectedValue(-1); // Set an invalid index for the Tabs to avoid highlighting any tab
      setSelectedValue(event.target.value);
    };
  
    useEffect(() => {
      // Update the document title using the browser API
      console.log(selectedValue);
    }, [selectedValue]);
  
    return (
      <>
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex',alignItems: 'center' }}>
        <Tabs value={selectedValue} onChange={handleTabChange} aria-label="basic tabs example" textColor="primary" indicatorColor="primary">
          <Tab label="Webpage" />
          <Tab label="Journal Article" />
          <Tab label="Book" />
        </Tabs>
        <Select
          value={selectedValue}
          onChange={handleSelectChange}
          style={{ minWidth: '150px',backgroundColor:'white', height:'48px' }}
          label="More types"
        >
          <MenuItem value="Book Chapter">BOOK CHAPTER</MenuItem>
          <MenuItem value="Video">VIDEO</MenuItem>
          <MenuItem value="Online Dictionary Entry">ONLINE DICTIONARY ENTRY</MenuItem>
          <MenuItem value="Online News Article">ONLINE NEWS ARTICLE</MenuItem>
          <MenuItem value="Image">IMAGE</MenuItem>
          <MenuItem value="Report">REPORT</MenuItem>
          <MenuItem value="Website">WEBSITE</MenuItem>
        </Select>
      </Box>
    </Box>

    </>

    );
  };


  ///////////////////////////////////////////////////////////////////////////////////////////////



  return (
    <>
      <div style={{ width: '100%', maxWidth: '1200px' }}>
        <Container fluid>
          <Row className="text-center py-3">
            <Col>
               <Navtabs/> 
            </Col>
          </Row>

          <Row
            style={{
              width: '100%',
              zIndex: 1000,
              height: '72.4px',
              top: '173px',
              left: '306px',
              borderRadius: '15px',
              border: '1px solid #9F9F9F',
              backgroundColor: '#FFFFFF',
              paddingTop: '9px',
              paddingLeft: '6px',
              paddingRight: '6px',
              boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            className="citation-accordion"
          >
            {/* <Col xs={3}>
             
            <div className='mt-3'>
                <SelectDropdownMui
                  optionsList={professionList}
                  selectLabel={"Citation Style"}
                  onChange={(e) => setCitationStyle(e.target.value)}
                  value={citationStyle}
                />
              </div>
           </Col> */}
            <Col xs={11}>
              <FormControl fullWidth>
                <TextField
                  id="standard-search"
                  label={selectedValue === 0 ? "Enter or Paste the URL" : selectedValue === 1 ? "Search or Paste Article Title" : "Search or Paste Book Title"}
                  
                  type="search"
                  variant="standard"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                    if (!e.target.value.trim()) {
                      handleCloseAccordion();
                    }
                  }}
                />
              </FormControl>
            </Col>

            <Col xs={1}>
              <B_Button style={{ marginTop: '10px', marginRight: '10px' }} variant="primary" onClick={handleCiteButtonClick}>
                Cite
              </B_Button>

            </Col>

            {websiteData && (
              <Accordion style={{ width: '97%', zIndex: 1001 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography><b>{websiteData[0].title} - {websiteData[0].description}</b></Typography>

                </AccordionSummary>
                <AccordionDetails style={{ position: 'relative' }}>

                  <Typography><p>{websiteData[0].sourceType} | {websiteData[0].publicationDate.year}</p></Typography>


                  {/* Save Icon */}
                  <div style={{ display: 'flex' }}>
                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '90px' }}
                      onClick={handleSaveCitations2}
                      title={"Save Citation"}
                    >
                      <AssignmentTurnedInIcon data-toggle="tooltip" />
                    </IconButton>

                    <CopyToClipboard
                      text={`${websiteData[0].title} - ${websiteData[0].description}\n${websiteData[0].pageType} | ${websiteData[0].publicationDate.year}`}
                      onCopy={handleCopyClick}
                    >

                      <IconButton
                        style={{ position: 'absolute', bottom: '10px', right: '50px' }}
                        title={"Copy"}
                      >
                        <CopyIcon data-toggle="tooltip" />

                      </IconButton>
                    </CopyToClipboard>

                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '10px' }}
                      onClick={handleCloseAccordion}
                      title={"Close"}
                    >
                      <CloseIcon data-toggle="tooltip" />
                    </IconButton>
                  </div>

                </AccordionDetails>
              </Accordion>
            )}
          </Row>
        </Container>

        <Container fluid style={{ marginTop: '10px', width: '97%' }}>
          <Row xs={12} className="justify-content-between">
            <Col>

            </Col>


            <Col xs="auto">
              <CitationModal
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleSaveCitations={handleSaveCitations}
                formData={formData}
                setFormData={setFormData}
                show={show}
                setShow={setShow}
                handleModalClose={handleModalClose}
                handleModalShow={handleModalShow}
                contributors={contributors}
                setContributors={setContributors}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{ marginTop: "10px", marginLeft: '24px', width: '100%' }}>
          <h3>Citations</h3>
        </Container>

        <Container style={{ marginTop: "10px" }}>
          <Row xs={12} className="justify-content-between">
            <FormControl>
              {totalSort ? <FormControlLabel
                style={{ marginLeft: '25px' }}
                control={<Checkbox checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
                label={<Typography variant="body1" style={{ fontSize: '15px' }}>Source ({totalSource} out of {totalSource})</Typography>}
              /> : <FormControlLabel
              style={{ marginLeft: '25px' }}
              control={<Checkbox checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
              label={<Typography variant="body1" style={{ fontSize: '15px' }}>Source ({totalSource})</Typography>}
            />
            }
            </FormControl>

            <div className="d-flex col-md-8 justify-content-end align-items-center ml-auto">
              {" "}
              {/* This div pushes its contents to the right */}
              <TextField
                placeholder="Search"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{
                  // width: "171.19px",
                  // height: "42.02px",
                  backgroundColor: 'white',
                  // marginLeft: "10px",
                  // marginRight: '10px'
                }}
                value={searchTerm}
                onChange={handleSearchChange}
                className='col-md-3 mx-2'
              />
              
              <div className='col-md-3'>
                <SelectDropdownMui
                  optionsList={professionList}
                  selectLabel={"Select"}
                  //  onChange={handleCitationStyleChange}
                  value={searchCitationStyle}
                />
              </div>
              <div title={'Delete'} id="hoverChange" >
                <DeleteIcon 
                  onClick={handleDeleteSelectedClick}
                  data-toggle="tooltip"
                 
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: 'pointer',
                    color: '7E7E7E',
                    margin:'6px'
                  }}
                /></div>
              <div title={'Copy'} id="hoverChange">
                <CopyIcon
                  onClick={handleCopySelectedClick}
                  data-toggle="tooltip"
                  style={{
                    width: "22px",
                    height: "22px",
                    margin:'8px',
                    cursor: 'pointer',
                    color: '7E7E7E'
                  }}
                /></div>
              <div title={'Download'} id="hoverChange">
                <DownloadIcon
                  onClick={handleDownloadDropdownClick}
                  data-toggle="tooltip"
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: 'pointer',
                    color: '7E7E7E',
                    margin:'7px',
                  }}
                /></div>
              <div title={'Sort'} id="hoverChange">
                <SortByAlphaIcon
                  data-toggle="tooltip"
                  style={{
                    width: "24px",
                    height: "24px",
                    color: '7E7E7E',
                    margin:'7px',
                    cursor: 'pointer',
                  }}
                  onClick={handleSortClick}
                />
              </div>
              <div title={'Group By'} id="hoverChange">
                <ViewListIcon
                  style={{
                    width: "24px",
                    height: "24px",
                    color: '7E7E7E',
                    margin:'8px',
                    cursor: 'pointer',
                  }}
                  onClick={handleDropdownClick}
                />

              </div>
            </div>
          </Row>
        </Container>

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorElDropdown}
          open={Boolean(anchorElDropdown)}
          onClose={handleDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <MenuItem onClick={() => handleDropdownSelectforGrid("No Group")}>
            <ListItemText primary="No Group" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforGrid("Source Type")}>
            <ListItemText primary="Source Type" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforGrid("Published Year")}>
            <ListItemText primary="Published Year" />
          </MenuItem>


        </Menu>

        <Menu
          anchorEl={downloadAnchorElDropdown}
          open={Boolean(downloadAnchorElDropdown)}
          onClose={handleDownloadDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
            <ListItemText primary="MS-Word" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforDownload("BibTeX")}>
            <ListItemText primary="BibTeX" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
            <ListItemText primary="PDF" />
          </MenuItem>


        </Menu>

        <Container style={{ marginTop: "15px" }}>
        {displayByDate ? <div>
            <CitationCard 
              handleCardCheckboxChange={handleCardCheckboxChange}
              handleDeleteCitation={handleDeleteCitation}
              fetchCitationDetails={fetchCitationDetails}
              displayByDate={displayByDate}
              yearsArray={yearsArray}
              initialCitations={initialCitations}
              handleModalShow={handleModalShow}
            />
          </div> :
            citations?.map((citation, index) => (
              <CitationCard key={index} citation={citation}
                handleCardCheckboxChange={handleCardCheckboxChange}
                handleDeleteCitation={handleDeleteCitation}
                fetchCitationDetails={fetchCitationDetails}
                displayByDate={displayByDate}
                yearsArray={yearsArray}
                initialCitations={initialCitations}
                handleModalShow={handleModalShow}
              />

            ))}
        </Container>
      </div>
    </>
  );
};


export default Citation1;
import React, { useEffect, useRef, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import TableViewIcon from '@mui/icons-material/TableView';
import GetAppIcon from '@mui/icons-material/GetApp';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import EditIcon from '@mui/icons-material/Edit';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import ImageIcon from '@mui/icons-material/Image';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SegmentIcon from '@mui/icons-material/Segment';
import RemoveIcon from '@mui/icons-material/Remove';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, IconButton, TextField } from '@material-ui/core';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { Menu, MenuItem } from '@mui/material';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import Tooltip from '@material-ui/core/Tooltip';
import { AtomicBlockUtils, BlockMapBuilder, ContentState, EditorState, Entity, Modifier, RichUtils, SelectionState } from 'draft-js';
import { FONT_TYPES,FONT_COLOR,Heading,Text_Align, Special_Characters, Format_Style, Table_List, Add_List } from '../Editor/utilize';
import { AddfunctionControl, InlineClearFormating, InlineFontSize, InlineSelect, InlineSymbols, InlineTextAlign, TableMaker } from '../Editor/SelectItem';
import { InlineColor } from '../Editor/SelectItem';
import { InlineSize } from '../Editor/SelectItem';
import { InlineParagraph } from '../Editor/SelectItem';
import { FONT_SIZE } from '../Editor/utilize';
import { InlineStyleControls } from '../Editor/StyleButton';
import ImageUpload from '../Editor/ImageUpload';
import { BlockStyleControls } from '../Editor/StyleButton';
import { toast, ToastContainer } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from 'react-bootstrap';
// import FormatClearIcon from '@mui/icons-material/FormatClear';
 
 
const CoAutherToolbar = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedParaOption, setSelectedParaOption] = React.useState('Paragraph');
    const [fontStyle, setFontStyle] = React.useState(null);
    const [selectFontStyle, setSelectFontStyle] = React.useState('Arial');
    const [fontSize, setFontSize] = React.useState(16);
    const [textAlign, setTextAlign] = React.useState(null);
    const [selectTextAlign, setSelectTextAlign] = React.useState(<FormatAlignLeftIcon />);
    const [showUrlpopup,setShowUrlpopup]=useState(false)
    const [urlValue, setUrlValue] = useState('');
    const[showReplace,setShowReplace]=useState(false);
    const findReplaceDivRef = useRef();
    const [findValue, setFindValue] = useState('');
    const [replaceValue, setReplaceValue] = useState('');
    const [currentBlockKey, setCurrentBlockKey] = useState(null);
    const [matchArray, setMatchArray] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [columnCount, setColumnCount] = useState(0);
    const [currentReplaceIndex, setCurrentReplaceIndex] = useState(0);
 
 
    //table grid
    const renderTextFieldGrid = () => {
      const grid = [];
   
      for (let i = 0; i < rowCount; i++) {
        const row = [];
        for (let j = 0; j < columnCount; j++) {
          row.push(
            <TextField
              key={`textfield-${i}-${j}`}
              hiddenLabel
              id={`outlined-hidden-label-${i}-${j}`}
              variant="outlined"
              style={{
                border: '1px solid grey',
                backgroundColor: 'white',
                margin: '0px', // Adjust the margin as needed
              }}
            />
          );
        }
        grid.push(
          <Form.Group
            key={`row-${i}`}
            style={{
              display: 'flex',
              margin: '0px',
              maxWidth: '900px',
              minWidth: '400px',
              backgroundColor: 'white',
            }}
          >
            {row}
          </Form.Group>
        );
      }
   
      return grid;
    };
 
 
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (findReplaceDivRef.current && !findReplaceDivRef.current.contains(event.target)) {
          // Clicked outside the div, close it
          setShowReplace(false);
        }
      };
 
      // Add event listener to detect clicks outside the div
      document.addEventListener('mousedown', handleClickOutside);
 
      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showReplace]);
   
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (option) => {
        setAnchorEl(null);
        if (option) {
            setSelectedParaOption(option);
        }
    };
    const handleFontStyleClick = (event) => {
        setFontStyle(event.currentTarget);
    };
    const handleFontStyleClose = (option) => {
        setFontStyle(null);
        if (option) {
            setSelectFontStyle(option);
        }
    };
    const handleIncreaseFontSize = () => {
        setFontSize(fontSize + 2);
    };
 
    const handleDecreaseFontSize = () => {
        setFontSize(Math.max(fontSize - 2, 1));
    };
 
    const handleTextAlign = (event) => {
        setTextAlign(event.currentTarget);
    };
    const handleTextAlignclose = (option) => {
        setTextAlign(null);
        if (option) {
            setSelectTextAlign(option);
        }
    };
    const handleFontStyleToggle = (style) => {
        console.log(style,"font type is ")
        props.onChange(RichUtils.toggleInlineStyle(props.editorState, style));
    };
 
    const handleParagraphToggle = (style) => {
        console.log(props.editorState,style,"font type is ")
        props.onChange(RichUtils.toggleInlineStyle(props.editorState, style));
        console.log(props,"-------")
    };
   
    const handleSymbolToggle=(label)=>{
        console.log(label, "selected label is ");
        const newContentState = Modifier.insertText(
            props.editorState.getCurrentContent(),
            props.editorState.getSelection(),
            label
          );
          const newEditorState = EditorState.push(
            props.editorState,
            newContentState,
            "insert-characters"
          );
          props.onChange(newEditorState);
    }
 
    const handleLinkToggle = (linkUrl) => {
      const { editorState } = props;
   
      // Get the current selection
      const selection = editorState.getSelection();
   
      // Ensure the selection is collapsed
      const collapsedSelection = selection.isCollapsed()
        ? selection
        : selection.merge({
            anchorOffset: selection.getStartOffset(),
            focusOffset: selection.getStartOffset(),
          });
   
      // Apply the link entity to the selection
      const contentStateWithEntity = editorState.getCurrentContent().createEntity(
        'LINK',
        'MUTABLE',
        { url: linkUrl }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
   
      // Apply the entity to the selection using RichUtils.toggleLink
      const editorStateWithLink = RichUtils.toggleLink(
        editorState,
        collapsedSelection,
        entityKey
      );
   
      // Update the editor state
      props.onChange(editorStateWithLink);
    };
   
    const handleFontColorToggle = (style) => {
        console.log(style,"font color is ")
        props.onChange(RichUtils.toggleInlineStyle(props.editorState, style));
    };
 
   
    const toggleBlockType = (blockType) => {
        props.onChange(RichUtils.toggleBlockType(props.editorState, blockType));
      };
 
    const handleDownload = () => {
        // const contentState = outputSentence;
        // const rawText = outputSentence
        // const textToDownload = rawText.blocks.map(block => block.text).join('\n');
        if (!props.editorState.getCurrentContent().getPlainText()) {
          toast.warn(`You don't have text, to download`);
          return;
        }
        const blob = new Blob([props.editorState.getCurrentContent().getPlainText()], { type: 'text/plain' });
   
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = 'text_content.doc';
        anchor.click();
        URL.revokeObjectURL(anchor.href);
      };
 
      const handleClearToggle=(style)=>{
        props.onChange(RichUtils.toggleInlineStyle(props.editorState, style));
      }

      const handleFontSizeToggle = (style) => {
        props.onChange(RichUtils.toggleInlineStyle(props.editorState, style));
      };
 
      const handleCheckClick = () => {
        alert(urlValue)
        if (urlValue) {
            handleLinkToggle(urlValue)
        }
        setShowUrlpopup(false);
      };
 
       const handleCloseClick = () => {
        setShowUrlpopup(false)
      };
 
      const handleUrlChange = (event) => {
        setUrlValue(event.target.value);
      };
      const handleLinkClick=()=>{
        setShowUrlpopup(true)
      }
 
      const showReplaceForm=()=>{
        setShowReplace(true);
 
      }
 
      const hideReplaceForm=()=>{
        setShowReplace(false);
      }
 
const handleFind = () => {
  const content = props.editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const findValueLowerCase = findValue.toLowerCase(); // Ignore case
  let newEditorState = props.editorState;
  let foundMatch = false;
  const matches = [];
 
  blockMap.forEach((currentBlock) => {
    const blockText = currentBlock.getText();
    const regex = new RegExp(findValueLowerCase, 'gi');
 
    let match;
    while ((match = regex.exec(blockText)) !== null) {
      foundMatch = true;
      matches.push({
        blockKey: currentBlock.getKey(),
        matchText: match[0], // The matched text
      });
    }
  });
 
  if (foundMatch) {
    highlightMatches(matches);
  } else {
    toast.info(`No matches found for "${findValue}"`);
  }
};
 
const highlightMatches = (matches) => {
  console.log(matches,"matches")
  let newEditorState = props.editorState;
  const contentState = newEditorState.getCurrentContent();
 
  const selectionRanges = [];
 
  matches.forEach((matchRange) => {
    const { blockKey, matchText } = matchRange;
    const block = contentState.getBlockForKey(blockKey);
    const blockText = block.getText();
    let matchIndex = blockText.indexOf(matchText);
 
    while (matchIndex !== -1) {
      const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: matchIndex,
        focusOffset: matchIndex + matchText.length,
      });
 
      selectionRanges.push(selection);
 
      // Move to the next occurrence
      matchIndex = blockText.indexOf(matchText, matchIndex + 1);
    }
  });
 
  // Apply all selections
  selectionRanges.forEach((selection) => {
    newEditorState = EditorState.forceSelection(newEditorState, selection);
  });
 
  props.onChange(newEditorState);
  setMatchArray(matches); // Update matchArray state
};
 


const handleReplaceAll = () => {
  if (matchArray.length === 0) {
    toast.info('No matches to replace');
    return;
  }
 
  let newEditorState = props.editorState;
 
  matchArray.forEach((matchRange) => {
    const { blockKey, matchText } = matchRange;
    const contentState = newEditorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);
    const blockText = block.getText();
    const matchIndex = blockText.indexOf(matchText);
 
    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: matchIndex,
      focusOffset: matchIndex + matchText.length,
    });
 
    const newContent = Modifier.replaceText(
      contentState,
      selection,
      replaceValue
    );
 
    newEditorState = EditorState.push(newEditorState, newContent, 'replace-text');
  });
 
  props.onChange(newEditorState);
 
  // Clear the matchArray after replacing
  setMatchArray([]);
};

const handleReplace = () => {
  if (matchArray.length === 0) {
    toast.info('No matches to replace');
    return;
  }

  // Use state or useRef to keep track of the current index
  

  // Get the current match to replace
  const currentMatch = matchArray[currentReplaceIndex];

  // Check if there are still matches to replace
  if (currentMatch) {
    let newEditorState = props.editorState;
    const { blockKey, matchText } = currentMatch;

    const contentState = newEditorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);
    const blockText = block.getText();
    const matchIndex = blockText.indexOf(matchText);

    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: matchIndex,
      focusOffset: matchIndex + matchText.length,
    });

    const newContent = Modifier.replaceText(
      contentState,
      selection,
      replaceValue
    );

    newEditorState = EditorState.push(newEditorState, newContent, 'replace-text');

    // Move to the next match
    setCurrentReplaceIndex(currentReplaceIndex + 1);

    props.onChange(newEditorState);
  } 
};

 
const handleInsertImage = ( url) => {
  console.log(url,"ali")
  const contentState = props.editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: url },)
      console.log(contentStateWithEntity,"entity")
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
const newEditorState = EditorState.set( props.editorState, { currentContent: contentStateWithEntity });
console.log(newEditorState,"state")
return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, '');
// props.onChange(newEditorState,entityKey,"")
};
 
const handletableToggle = (rows, columns) => {
  setRowCount(rows)
  setColumnCount(columns)
 
    // const gridContent = renderTextFieldGrid();
 
    // // Get the current selection in the editor state
    // const selection = props.editorState.getSelection();
 
    // // Create a new ContentState with the grid content inserted at the current selection
    // const newContentState = Modifier.replaceText(
    //   props.editorState.getCurrentContent(),
    //   selection,
    //   gridContent
    // );
 
    // // Create a new EditorState with the updated ContentState
    // const newEditorState = EditorState.push(
    //   props.editorState,
    //   newContentState,
    //   'insert-characters'
    // );
 
    // Call the onChange function in your parent component with the updated editor state
    // props.onChange(newEditorState);
};
 
const handlePlusClick = (style) => {
  const { editorState, onChange, dividerplugin } = props;
 
  if (style === 'CODE') {
    // Toggle the "CODE" inline style
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  } else if (style === 'HORIZONTALLINE' && dividerplugin) {
    // Insert a horizontal line block using the passed divider plugin
    const newEditorState = dividerplugin.props.addHorizontalLine(editorState);
    onChange(newEditorState);
  } else {
    // For other styles, toggle the inline style
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  }
};
 //   Working undo Redo code
 const handleUndo = () => {
  props.onChange(EditorState.undo(props.editorState));
 };
 
 const handleRedo = () => {
   props.onChange(EditorState.redo(props.editorState));
 };
 
    return (
        <div style={{ display: "flex",height:"40px", alignItems: "center", width: '800px', marginBottom: '5px', marginTop: '3%', boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)', color: "#1976D2", padding: '5px', borderRadius: '8px', background: 'white' }}>
           <IconButton title="Undo" disableTouchRipple style={{ margin: "1px",width:"20px", padding: "2px", color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }} onClick={()=>{handleUndo()}}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
           ><UndoIcon /></IconButton>
          <IconButton title="Redo" disableTouchRipple style={{ margin: "1px",width:'20px',padding: "2px", color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }} onClick={()=>{handleRedo()}}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "white")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
          ><RedoIcon /></IconButton>|
 
            {/* --- paragraph --- */}
            {/* <IconButton style={{ fontSize: "14px", marginLeft: "2px", padding: "2px", color: "#1976D2", outline: "none" }} onClick={handleClick}> {selectedParaOption}<KeyboardArrowDownIcon /></IconButton>| */}
           
         
            <IconButton title="Heading " disableTouchRipple style={{ marginLeft: "8px",marginRight:"8px",width:"60px", color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
           <InlineSize
                 
                  placeholder="Paragraph"
                  list={Heading}
                  currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(style) =>handleFontColorToggle(style)}
                /></IconButton>|
            {/* ---- paragraph end ---
 
            {/* ---- font style --- */}
          <IconButton title="Font Style" disableTouchRipple style={{ marginLeft: "8px",marginRight:"8px",width:"60px", color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
          >
            <InlineSelect
                placeholder="sans_serif"
                list={FONT_TYPES}
                currentStyle={props.editorState.getCurrentInlineStyle()}
                onToggle={(style) => handleFontStyleToggle(style)}
            />
            </IconButton>|
   
            {/* --- font style ends --- */}
 
            {/* Font Color Start  */}
            <IconButton title="Color" disableTouchRipple style={{ marginLeft: "8px",marginRight:"8px",width:"30px", color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
            <InlineColor
                  placeholder="Black"
                  list={FONT_COLOR}
                  currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(style) =>handleFontColorToggle(style)}
                />
             </IconButton>|
            {/* {Font color end } */}
 
 
 
            {/* --- font size ---- */}
            <IconButton title="Font-Size" disableTouchRipple style={{ marginLeft: "13px",marginRight:"15px",width:"20px", color: "#1976D2", outline: "none" ,backgroundColor: "transparent",transition: "background-color 0.3s",}}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
           <InlineFontSize
      placeholder="14"
      list={FONT_SIZE}
      currentStyle={props.editorState.getCurrentInlineStyle()}
      onToggle={(style) => handleFontSizeToggle(style)}
    /></IconButton>|

            {/* <div style={{width:"60px",marginLeft:"20px",display:'flex',alignItems:'center',marginLeft:"-1px",}}>
                <div style={{width:"19px",fontSize:"25px",display:'flex',justifyContent:'center'}}>-</div>
                <div class=" border border-primary" style={{width:"19px",borderRadius:'3px',fontSize:'10px',padding:'2px'}}>14</div>
                <div style={{width:"19px",fontSize:"20px",display:'flex',justifyContent:'center'}}>+</div>
            </div>| */}
 
 
            {/* ---- font size ---  */}
         <IconButton disableTouchRipple style={{width:"160px", marginLeft: "-10px", marginRight:"-20px",color: "#1976D2", outline: "none",backgroundColor: "transparent",transition: "background-color 0.3s", }}
         onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
         onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
         >
            <InlineStyleControls
                  editorState={props.editorState}
                  onToggle={(style) =>handleFontColorToggle(style)}
                /></IconButton>|
         {/* Insert image */}
        <IconButton title="Image" disableTouchRipple style={{width:"20px", marginLeft: "8px",marginTop:'5px', marginRight:"-8px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s",  }}
         onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
         onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
        >
        <ImageUpload insertImage={(url) => handleInsertImage(url)} />
        </IconButton>|
 
            {/* text segment icon ends */}
            <IconButton disableTouchRipple style={{ width:"80px", marginLeft: "-6px",marginRight:"-20px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s", }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
            <BlockStyleControls
                  editorState={props.editorState}
                  onToggle={(blockType)=>{toggleBlockType(blockType)}}
                />
            </IconButton>|
         
            {/* text Alignment */}
            <IconButton title="Text Align" disableTouchRipple style={{ width:"25px",marginLeft: "1px",marginRight:"2px", padding: "2px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s" }}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
                 <InlineSize
                 
                  placeholder={<FormatAlignLeftIcon  style={{fontSize:"14px"}}/>}
                  list={Text_Align}
                  currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(style) =>handleFontColorToggle(style)}
                /></IconButton>|
 
           {/* attach link */}
            <IconButton title="Link" disableTouchRipple onClick={handleLinkClick} style={{width:"25px", marginLeft: "1px",marginRight:'2px', padding: "2px", color: "#1976D2" ,backgroundColor: "transparent",transition: "background-color 0.3s"}}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            ><AttachFileIcon style={{fontSize:"14px"}} /></IconButton>|
 
            {/* table */}
            <IconButton title="Table" disableTouchRipple style={{ width:"25px", marginLeft: "11px",marginRight:'2px', padding: "2px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s" }}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
            <TableMaker
            placeholder={<TableViewIcon style={{fontSize:"14px"}}/>}
                  list={Table_List}
                  currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(rows,columns) =>handletableToggle(rows,columns)}
                />
            </IconButton>|
            {/* <IconButton style={{ marginLeft: "1px", padding: "2px", color: "#1976D2" }}>Ω </IconButton>| */}
 
            {/* special symbols */}
            <IconButton title="Special Symbols" disableTouchRipple  style={{ width:"25px", marginLeft: "2px",marginRight:'2px', padding: "2px", color: "#1976D2" ,backgroundColor: "transparent",transition: "background-color 0.3s"}}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
                <InlineSymbols
                    placeholder="Ω"
                    list={Special_Characters}
                    currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(label) =>handleSymbolToggle(label)}
                />
            </IconButton>|
 
            {/* '+' function */}
            <IconButton title="Insert" disableTouchRipple style={{width:"25px", marginLeft: "1px", padding: "2px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s" }}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
            <AddfunctionControl
                placeholder="+"
                list={Add_List}
                currentStyle={props.editorState.getCurrentInlineStyle()}
                onToggle={(style) =>handlePlusClick(style)}
            />
            </IconButton>|
           
           {/* find and  replace */}
            <IconButton disableTouchRipple title="Find and Replace" onClick={showReplaceForm} style={{marginLeft: "2px", padding: "2px", color: "#1976D2",backgroundColor: "transparent",transition: "background-color 0.3s" }}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            ><FindReplaceIcon style={{color:"#1976D2",fontSize:"15px"}}/></IconButton>|
         
 
{showReplace && (
        <div ref={findReplaceDivRef} style={{ position: 'absolute',padding:'10px', width: '400px', height: '260px', backgroundColor: 'white', top: '250px', left: '950px', zIndex: '1000',borderRadius:'5px',boxShadow: '0 0 10px rgba(169, 169, 169)'}}>
          <div style={{ height: '60px', borderBottom: '1px solid gray',display:'flex',justifyContent:"space-between",alignItems:'center' }}>
            <div style={{width:"90%"}}><h3 style={{ color: 'black' }}>Find and Replace</h3></div>
           <div style={{width:"5%",cursor:"pointer",fontSize:"15px"}}> <p onClick={hideReplaceForm}><CloseIcon /></p></div>
          </div>
          <div style={{width:'100%', display: 'flex',justifyContent:'space-between',marginTop:'10px',}}>
            <div style={{width:"70%"}}>
              <TextField
                style={{width:'100%'}}
                id="find-input"
                label="Find"
                variant="outlined"
                value={findValue}
                onChange={(e) => setFindValue(e.target.value)}
              />
            </div>
            <div style={{width:'28%'}}>
              <Button style={{width:'100%', backgroundColor:'#1976D2',color:'white', height:'50px'}} variant="contained" onClick={handleFind}>Find</Button>
            </div>
            </div>
            <div style={{ width:'100%', display: 'flex',justifyContent:'space-between',marginTop:'10px'}}>
            <div style={{width:"100%"}}>
              <TextField
               style={{width:'100%'}}
                id="replace-input"
                label="Replace"
                variant="outlined"
                value={replaceValue}
                onChange={(e) => setReplaceValue(e.target.value)}
              />
            </div>
            </div>
            <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
              <div style={{width:"50%",display:"flex",marginTop:"10px"}}>
                
              <div style={{width:'50%',backgroundColor:'transparent',color:'#1976D2', height:'40px',fontSize:'14px',cursor:"pointer",display:'flex',justifyContent:'center',alignItems:'center'}} variant="contained" onClick={handleReplace}
               onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "rgb(232, 230, 230)")}
               onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>Replace</div>
              <div style={{width:'50%',backgroundColor:'transparent',color:'#1976D2', height:'40px',fontSize:'14px',cursor:"pointer",display:'flex',justifyContent:'center',alignItems:"center"}} variant="contained" onClick={handleReplaceAll}
               onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "rgb(232, 230, 230)")}
               onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>Replace all</div>
              </div>
            </div>
         
        </div>
      )}
      {/* ... (existing code) */}
   
 
 
            {/* clear formatting */}
            {/* <IconButton style={{ marginLeft: "", padding: "2px", color: "#1976D2" }}><FormatClearIcon /></IconButton> */}
            <IconButton title="Clear Formatting" disableTouchRipple style={{width:"25px",marginLeft: "-8px",marginRight:"3px",padding:"2px",backgroundColor: "transparent",transition: "background-color 0.3s"}} className='iconButton'
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
                 <InlineClearFormating
                  list={Format_Style}
                  currentStyle={props.editorState.getCurrentInlineStyle()}
                  onToggle={(style) =>handleClearToggle(style)}
                /></IconButton>|
 
            <IconButton title="Download" style={{width:"25px",margin:"0px",outline:"none",marginLeft: "2px",padding:"2px",backgroundColor: "transparent",transition: "background-color 0.3s"}} className='iconButton' onClick={handleDownload}
             onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
            <DownloadIcon style={{color:"#1976D2",fontSize:"15px",outline:"none"}}/>
            </IconButton>
 
        {/* link input */}
        {showUrlpopup &&
          <>
       
          <div  style={{position:'absolute',top:"210px",left:"580px",zIndex:"1000"}}>
          <div style={{display:'flex',alignItems:"center", width:"310px", backgroundColor:'white',border:"1px solid black", justifyContent:"space-evenly"}}>
          <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
             <TextField id="outlined-basic" label="Url" variant="outlined" value={urlValue} onChange={handleUrlChange} />
             </Box>
             <IconButton disableTouchRipple style={{ border: '1px solid green', color: 'green', padding: '8px' ,backgroundColor: "transparent",transition: "background-color 0.3s"}} onClick={handleCheckClick}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             ><CheckIcon /></IconButton>
             <IconButton disableTouchRipple style={{ border: '1px solid red', color: 'red', padding: '8px',backgroundColor: "transparent",transition: "background-color 0.3s" }} onClick={handleCloseClick}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
             ><CloseIcon /></IconButton>  
          </div>
        </div>
       
          </>
        }
 
      <div style={{position:"absolute",backgroundColor:"white",top:'300px',left:"340px",zIndex:"1000",}}>
      {renderTextFieldGrid()}
   
     </div>
        </div>
    )
}
 
export default CoAutherToolbar
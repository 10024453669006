import React, { useEffect, useState } from "react";
import "./ContactCard.css";
import { Avatar } from "@material-ui/core";
import { useContext } from "react";
import Checkbox from '@mui/material/Checkbox';
import LetterAvatarsecond from "../../AvatarSecond";
import { Box } from "@mui/material";

const CreateGroupCard = ({ contact, onSelect , index }) => {

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(false);
  }, [contact]);

  const handleCheckboxChange = () => {
    setSelected(!selected);
    onSelect(contact, !selected); 
  };

  
  return (
        <div className="contactcard">
        <Box display='flex' flexDirection='row' alignItems='center'> 
        <Checkbox checked={selected} onChange={handleCheckboxChange} />
         <LetterAvatarsecond name={contact.name} />
        <div className="contactcard__username">
        <h3>{contact.name}</h3>
        </div>
        </Box>
        </div>
  );
};

export default CreateGroupCard;

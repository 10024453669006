
import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const SearchInputField = ({ placeholder, onChange, value, width, height }) => {
  return (
    <TextField
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        style: {
          padding: '8px',
          borderRadius: '4px',
          backgroundColor: 'white',
          height: height,
          boxShadow: '2px 2px 5px -2px #000000',
          borderRadius: '12px',
          border:"1px solid #1976D2"
        },
      }}
      style={{
        width: width ? width : '100%',
      }}
    />
  );
};

export default SearchInputField;

import React, { useState } from "react";
import {
  Formik,
  Field,
  Form,
  FieldArray,
  useField,
  ErrorMessage,
} from "formik";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { postHomeCMS } from "../../services/adminServices";
import imageCompression from "browser-image-compression";
import uploadImageServices from "../../services/uploadImageServices";
import { Button } from "react-bootstrap";
import HeadingB from "../Cards/HeadingB";
import LogoImg from "../../static/Group570.png";
import * as Yup from "yup";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import PhoneInput from "react-phone-input-2";
import Icon from "@material-ui/core/Icon";

const InputField = ({ placeholder, className, type, ...props }) => {
  const [field] = useField(props);
  return (
    <input
      type={type}
      {...field}
      className={className}
      placeholder={placeholder}
    />
  );
};

// const PhoneField = ({ placeholder, className, type, ...props }) => {
//   const [field] = useField(props);
//   return (
//     <PhoneInput
//       className={className}
//       // value={this.state.data.phone}
//       // name="phone"
//       onChange={(e)=>onChangePhone(e)}
//       required
//       // onChange={this.onChangePhone}
//       // readOnly="true"
//       // style={{ width: "50%", height: "100%" }}
//     />
//   );
// };

const TextArea = ({ placeholder, className, maxLength, ...props }) => {
  const [field] = useField(props);
  return (
    <textarea
      {...field}
      className={className}
      placeholder={placeholder}
      rows="2"
      maxLength={maxLength}
    />
  );
};



const SelectField = ({ className, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControl variant="outlined" className={className}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        {...field}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};
const validationSchema = Yup.object().shape({
  Footer_Details:
    Yup.object().shape({
      Address: Yup.string().required("*Required Field"),
      Phone_no: Yup.string().required("*Required Field"),
      Email: Yup.string().email("Enter valid email.").required("*Required Field"),
    }),

  Footer_Links:
    Yup.object().shape({
      Facebook: Yup.string()
        .url("Enter valid URL")
        .required("Please enter website"),
      Linkedin: Yup.string()
        .url("Enter valid URL")
        .required("Please enter website"),
      Twitter: Yup.string()
        .url("Enter valid URL")
        .required("Please enter website"),
    })
  ,
});

function FooterCMS({ cmsData }) {
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState(cmsData);
  const [phoneNo, setPhoneno] = useState()


  let value = {
    Footer_Details: cmsData.Footer_Details,
    Footer_Links: cmsData.Footer_Links,
  };

  const onChangePhone = (e) => {
    let phone = e
    let data = mainData
    data.Footer_Details.Phone_no = phone
    console.log(data)
    setMainData(data)
  };
  const _resetChanges = async () => {
    setLoading(true);
    let mainData = cmsData;
    mainData.Footer_Details.Address=`22738, Maple Road Suite 120, Lexington Park, Maryland, 20653`
    mainData.Footer_Details.Phone_no="+3019975694"
    mainData.Footer_Details.Email="admin@professionaleditingtools.com"
    mainData.Footer_Links.Facebook="https://www.facebook.com/"
    mainData.Footer_Links.Linkedin="https://in.linkedin.com/"
    mainData.Footer_Links.Twitter="https://twitter.com/?lang=en"
    let post = await postHomeCMS(mainData);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }
    setLoading(false);
  }
  return (
    <div className="container-fluid h-100" id="DocCard">
      <Loader loading={loading} />
      <Formik
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log(values);
          setLoading(true);
          let finalData = mainData;
          finalData.Footer_Details.Address = values.Footer_Details.Address;
          finalData.Footer_Details.Email = values.Footer_Details.Email;
          // finalData.Footer_Details.Phone_no = values.Footer_Details.Phone_no;
          finalData.Footer_Links.Facebook = values.Footer_Links.Facebook;
          finalData.Footer_Links.Linkedin = values.Footer_Links.Linkedin;
          finalData.Footer_Links.Twitter = values.Footer_Links.Twitter;
          console.log(finalData);
          let post = await postHomeCMS(finalData);
          if (post.status === 200) {
            toast.success("Changes Updated");
          } else {
            toast.error("Failed To Save");
          }
          setLoading(false);
        }}
        render={({ values, setFieldValue }) => (
          <Form className="container">
            <HeadingB className="text-center alertHead pt-5" text="FOOTER" />

            <div class="form-group mt-5">
              <label>Address</label>
              <hr />
              <TextArea
                type="text"
                name="Footer_Details.Address"
                className="form-control w-50"
                placeholder="Address"
                maxLength="70"
              />
              <small>Length: {values.Footer_Details.Address.length}/70</small>
              <ErrorMessage name="Footer_Details.Address" className="">
                {(error) => <small style={{ color: "red", marginLeft: "35%" }}>{error}</small>}
              </ErrorMessage>
            </div>

            <div class="form-group mt-5">
              <label>Phone Number</label>
              <hr />
              <PhoneInput
                name="Footer_Details.Phone_no"
                className="phone"
                placeholder="Phone No"
                value={mainData.Footer_Details.Phone_no}
                onChange={(e) => onChangePhone(e)}
                required
              />
              <ErrorMessage name="Footer_Details.Phone_no">
                {(error) => <small style={{ color: "red" }}>{error}</small>}
              </ErrorMessage>
            </div>

            <div class="form-group mt-5">
              <label>Email</label>
              <hr />
              <InputField
                type="email"
                name="Footer_Details.Email"
                className="form-control w-50"
                placeholder="Email"
              />
              <ErrorMessage name="Footer_Details.Email">
                {(error) => <small style={{ color: "red" }}>{error}</small>}
              </ErrorMessage>
            </div>

            <div class="form-group mt-5">
              <label>Links</label>
              <hr />
              <div className="row">
                <div className="col-md-2 text-center">
                  <Icon
                    className="fa fa-facebook-square point"
                    style={{ fontSize: 30, color: "#007bff" }}
                  />
                </div>

                <div className="col-md-10">
                  <InputField
                    type="text"
                    name="Footer_Links.Facebook"
                    className="form-control w-50"
                    placeholder="Facebook Link"
                  />
                  <ErrorMessage name="Footer_Links.Facebook">
                    {(error) => <small style={{ color: "red" }}>{error}</small>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-2 text-center">
                  <Icon
                    className="fa fa-linkedin-square point"
                    style={{ fontSize: 30, color: "#007bff" }}
                  />
                </div>

                <div className="col-md-10">
                  <InputField
                    type="text"
                    name="Footer_Links.Linkedin"
                    className="form-control w-50"
                    placeholder="Linkedin Link"
                  />
                  <ErrorMessage name="Footer_Links.Linkedin">
                    {(error) => <small style={{ color: "red" }}>{error}</small>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-2 text-center">
                  <Icon
                    className="fa fa-twitter-square point"
                    style={{ fontSize: 30, color: "#007bff" }}
                  />
                </div>

                <div className="col-md-10">
                  <InputField
                    type="text"
                    name="Footer_Links.Twitter"
                    className="form-control w-50"
                    placeholder="Twitter Link"
                  />
                  <ErrorMessage name="Footer_Links.Twitter">
                    {(error) => <small style={{ color: "red" }}>{error}</small>}
                  </ErrorMessage>
                </div>
              </div>{" "}
            </div>

            {/* Save Button */}
            <div className="text-center">
              <button
                className="btn btn-danger mr-2 "
                type="submit"
                id="backBtn"
                onClick={_resetChanges}
                style={{
                  width: "20%",
                }}
                // disabled={buttonDisable}
              >
                Reset Changes
          </button>
              <Button type="submit" className="mt-5 mb-5 w-25">
                Save
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

export default FooterCMS;

import React, {useState, useEffect} from 'react'
import {MenuItem, Select} from '@material-ui/core';
import { Button, Dropdown, FormControl } from 'react-bootstrap';
 
import FormatClearIcon from '@mui/icons-material/FormatClear';
 
import { indexOf } from 'lodash';
 
function SelectItem({list, onBlockChange, currentBlockType, placeholder}) {
 
    const [selected, setSelected] = useState("");
 
    const onChangeFn = (style) =>{
        setSelected(style);
        onBlockChange(style);
    }
 
    useEffect(() => {
        // console.log("currentBlockType", currentBlockType)
        if(currentBlockType === "unstyled")
            setSelected(placeholder)
        else
            setSelected(currentBlockType)
    }, [currentBlockType])
 
    return (
        <Select value={ selected } className="mr-3" onChange={ (e)=> onChangeFn(e.target.value) } >
            {
                list.map((item) => <MenuItem value={item.style}>{item.label}</MenuItem> )
            }
      </Select>
    )
}
 
 
function InlineSelect({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
    const [label, setLabel] = useState("");
 
    const onChangeFn = (e, style) =>{
        e.preventDefault();
        // console.log("style", style);
        setSelected(style);
        onToggle(style);
    }
 
    useEffect(() =>{
        let getInlineStyle = null;
       
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.style
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        // console.log("getInlineStyle", getInlineStyle)
    },[currentStyle])
 
    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle className="mydrop" variant="white" id="dropdown-basic" style={{color:"#1976D2",fontSize:"12px"}}>
           {selected}
        </Dropdown.Toggle>
 
        <Dropdown.Menu className="setShadow">
            {
                list.map((item) =>  <Dropdown.Item onMouseDown={ (e) => onChangeFn(e, item.style) }  >{item.label}</Dropdown.Item>)
            }
        </Dropdown.Menu>
        </Dropdown>
    )
}
 
 
function InlineSize({list, placeholder, currentStyle, onToggle}){
  const [selected, setSelected] = useState("");
 
  const onChangeFn = (e, style, label) =>{
      e.preventDefault();
      console.log("style", style);
      setSelected(label);
      onToggle(style);
  }
 
  useEffect(() =>{
    // console.log("working")
    console.log(list,"headingList")
    console.log(currentStyle,"current style size")
      let getInlineStyle = null
      list.forEach( (obj) => {
          if(currentStyle.has(obj.style))
              getInlineStyle = obj.label
      });
      if(!getInlineStyle)
          setSelected(placeholder)
      else
          setSelected(getInlineStyle)
    //   console.log("getInlineStyle", getInlineStyle)
  },[currentStyle])
 
  return (
      <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
      <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic" style={{color:"#1976D2",fontSize:"10px"}}> {selected} </Dropdown.Toggle>

      <Dropdown.Menu className="setShadow">
          {
              list.map((item) =>  <Dropdown.Item
               onMouseDown={ (e) => onChangeFn(e, item.style, item.label) }  >{item.label}</Dropdown.Item>)
          }
      </Dropdown.Menu>
      </Dropdown>
  )
}



function InlineFontSize({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState(14);

  const handleToggle = (action) => {
    setSelected((prevSize) => {
      let newSize = parseInt(prevSize);

      if (action === "increase" && newSize < 99) {
        newSize = newSize + 1;
      } else if (action === "decrease" && newSize > 1) {
        newSize = newSize - 1;
      }

      const matchingItem = list.find((item) => item.label === newSize.toString());
      if (matchingItem) {
        const style = matchingItem.style;
        onToggle(style);
      }

      return newSize.toString();
    });
  };
  return (
    <div style={{ width: "60px", marginLeft: "20px", display: 'flex', alignItems: 'center', marginLeft: "10px", marginRight: '15px' }}>
      <div onClick={() => handleToggle("decrease")} style={{ cursor: 'pointer', width: "19px", fontSize: "20px", display: 'flex', justifyContent: 'center' }}>-</div>
      <div className="border border-primary" style={{ width: "19px", borderRadius: '3px', fontSize: '10px', padding: '2px' }}>{selected}</div>
      <div onClick={() => handleToggle("increase")} style={{ cursor: 'pointer', width: "19px", fontSize: "20px", display: 'flex', justifyContent: 'center' }}>+</div>
    </div>
  );
}

// function InlineFontSize({ list, placeholder, currentStyle, onToggle }) {
//   const [inputValue, setInputValue] = useState(14);
//   const [selected, setSelected] = useState(14);
//   const handleToggle = () => {
//     const newSize = parseInt(inputValue);

//     if (newSize >= 1 && newSize <= 99) {
//       const matchingItem = list.find((item) => item.label === newSize.toString());
//       if (matchingItem) {
//         const style = matchingItem.style;
//         onToggle(style);
//       }
//     }
//   };
//   const handleToggleButton = (action) => {
//         setSelected((prevSize) => {
//           let newSize = parseInt(prevSize);
    
//           if (action === "increase" && newSize < 99) {
//             newSize = newSize + 1;
//           } else if (action === "decrease" && newSize > 1) {
//             newSize = newSize - 1;
//           }
    
//           const matchingItem = list.find((item) => item.label === newSize.toString());
//           if (matchingItem) {
//             const style = matchingItem.style;
//             onToggle(style);
//           }
    
//           return newSize.toString();
//         });
//       };
//   useEffect(() => {
//     let getInlineStyle = null;
//     list.forEach((obj) => {
//       if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
//     });
//     if (!getInlineStyle) setInputValue(placeholder);
//     else setInputValue(getInlineStyle);
//   }, [currentStyle, placeholder, list]);

//   return (
//     <div style={{ width: "120px", marginLeft: "20px", display: 'flex', alignItems: 'center', marginLeft: "10px", marginRight: '15px' }}>
//       <div onClick={() => handleToggle("decrease")} style={{ fontSize:'14px',cursor: 'pointer', width: "19px", fontSize: "20px", display: 'flex', justifyContent: 'center' }}>-</div>
//       <input
//         // type="number"
//         value={inputValue}
//         onChange={(e) => setInputValue(e.target.value)}
//         onBlur={handleToggle}
//         style={{ width: "19px",padding:"2px", textAlign: 'center',appearance: 'none',outline:"none",color:"#1976D2",backgroundColor: 'none',fontSize:'12px',border:'1px solid #1976D2',borderRadius:"2px"}}
//       />
//       <div  onClick={() => handleToggle("increase")} style={{ cursor: 'pointer', width: "19px", fontSize: "20px", display: 'flex', justifyContent: 'center' }}>+</div>
//     </div>
//   );
// }

function InlineParagraph({ list, placeholder, currentStyle, onToggle }) {
    const [selected, setSelected] = useState("");
    const onChangeFn = (e, style, label) => {
        // console.log(style,label,"----")
      e.preventDefault();
      setSelected(label);
      onToggle(style);
    //   console.log(selected,"style")
    };
 
    useEffect(() =>{
            //   console.log(list,"headingList")
            //   console.log(currentStyle,"current style")
                let getInlineStyle = null
                list.forEach( (obj) => {
                    if(currentStyle.has(obj.style))
                   
                        getInlineStyle = obj.label
                       
                });
                if(!getInlineStyle){
                    setSelected(placeholder)
                // console.log(selected,"not")
                }else{
                    setSelected(getInlineStyle)
                    console.log(getInlineStyle,".....")
                    console.log(selected,"yes")
                }
            },[currentStyle])
       
   
    return (
      <Dropdown onMouseDown={(e) => e.preventDefault()}>
        <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic">
          {selected}
        </Dropdown.Toggle>
 
        <Dropdown.Menu className="setShadow">
          {list.map((item,index) => (
            <Dropdown.Item
              key={index}
              onMouseDown={(e) => onChangeFn(e, item?.style, item?.label)}
            >
              {item?.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
}
 
function InlineSymbols({placeholder,list,currentStyle, onToggle}){
  const [selected,setSelected]=useState("");
 
  const onChangeFn = (e, label) =>{
    e.preventDefault();
    console.log("style", label);
    setSelected(label);
    onToggle(label);
}
 
useEffect(() =>{
  // console.log("working")
  console.log(list,"symbolList")
  console.log(currentStyle,"current style size")
    let getInlineStyle = null
    list.forEach( (obj) => {
        if(currentStyle.has(obj.style))
            getInlineStyle = obj.label
    });
    if(!getInlineStyle)
        setSelected(placeholder)
    else
        setSelected(getInlineStyle)
  //   console.log("getInlineStyle", getInlineStyle)
},[currentStyle])
 
return (
  <Dropdown onMouseDown={(e) => { e.preventDefault() }}>
  <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic"  style={{color:"#1976D2",fontSize:"12px"}}>Ω</Dropdown.Toggle>
  <Dropdown.Menu className="setShadow" style={{ height: "400px", overflowY: 'auto', display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '10px', padding: '5px' }}>
    {list.map((item) => (
      <Dropdown.Item
        key={item.label}
        onMouseDown={(e) => onChangeFn(e, item.label)}
        style={{
          cursor: 'pointer',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid transparent', // Initial border
          transition: 'border 0.3s ease', // Add transition for a smooth effect
        }}
        onMouseOver={(e) => e.target.style.border = '1px solid #000'} // Change border on hover
        onMouseOut={(e) => e.target.style.border = '1px solid transparent'} // Reset border on mouse out
      >
        {item.label}
      </Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>
 
 
 
   
)
}
 
function TableMaker({list, placeholder, currentStyle, onToggle}){
 
  // console.log(list,"tablelist")
 
  const [Rows, setRows] = useState(0);
  const [Cols, setCols] = useState(0);
  const [selected, setSelected] = useState("");
 
 
 
 
  const onChangeFn = (e, rows,columns) =>{
 
      e.preventDefault();
      // console.log(rows,columns,"table");
      // setSelected(style);
      onToggle(rows,columns);
  }
 
  useEffect(() =>{
      let getInlineStyle = null
      list.forEach( (obj) => {
          if(currentStyle.has(obj.style))
              getInlineStyle = obj.style
      });
      if(!getInlineStyle)
          setSelected(placeholder)
      else
          setSelected(getInlineStyle)
      // console.log("getInlineStyle", getInlineStyle)
  },[currentStyle])
 
  return (
     <>
    <Dropdown
onMouseDown={(e) => {
  e.preventDefault();
}}
>
<Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic" style={{color:"#1976D2"}}>
  {selected}
</Dropdown.Toggle>
 
<Dropdown.Menu
  className="setShadow"
  style={{
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: '2000',
  }}
>
  {list.map((item) => (
    <Dropdown.Item
      key={`${item.rows}-${item.columns}`}
      onMouseDown={(e) => onChangeFn(e, item.rows, item.columns)}
    >
      {item.rows} x {item.columns}
    </Dropdown.Item>
  ))}
</Dropdown.Menu>
</Dropdown>
  </>
  )
}
 
function InlineClearFormating({ placeholder, list, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");
 
  const onChangeFn = (e) => {
    e.preventDefault();
    setSelected("clear");
    onToggle("clearStyle");
  }
 
  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
  }, [currentStyle]);
 
  return (
    <button style={{backgroundColor:"transparent",width:"25px"}} onClick={(e) => onChangeFn(e)}>
      <FormatClearIcon style={{color:"#1976D2",fontSize:"15px",backgroundColor: "transparent"}} />
    </button>
  );
}
 
function InlineColor({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
 
    const onChangeFn = (e, style) =>{
        e.preventDefault();
        // console.log("style", style);
        setSelected(style);
        onToggle(style);
    }
 
    useEffect(() =>{
        let getInlineStyle = null
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.style
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        // console.log("getInlineStyle", getInlineStyle)
    },[currentStyle])
 
    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle variant="green" id="dropdown-basic" className="mydrop"  style={{color:"#1976D2",fontSize:"12px"}}>
           {selected}
        </Dropdown.Toggle>
 
        <Dropdown.Menu className="setShadow">
            {
                list.map((item) =>  <Dropdown.Item className="drop_set" style={{backgroundColor :item.style}}
                 onMouseDown={ (e) => onChangeFn(e, item.style) }  >{item.label}</Dropdown.Item>)
            }
        </Dropdown.Menu>
        </Dropdown>
    )
  }
 
 
  function InlineTextAlign({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
 
    const onChangeFn = (e, style, label) =>{
        e.preventDefault();
        console.log("style", style);
        setSelected(label);
        onToggle(style);
    }
 
    useEffect(() =>{
      // console.log("working")
      console.log(list,"headingList")
      console.log(currentStyle,"current style")
      console.log(onToggle,"gdfguygxyg")
        let getInlineStyle = null
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.label
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        console.log("gettextalignStyle", getInlineStyle)
    },[currentStyle])
 
    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic"> {selected} </Dropdown.Toggle>
 
        <Dropdown.Menu className="setShadow">
            {
                list.map((item) =>  <Dropdown.Item
                 onMouseDown={ (e) => onChangeFn(e, item.style, item.label) }  >{item.label}</Dropdown.Item>)
            }
        </Dropdown.Menu>
        </Dropdown>
    )
  }
 
  function AddfunctionControl({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
 
    const onChangeFn = (e, style) =>{
        e.preventDefault();
        // console.log("style", style);
        setSelected(style);
        onToggle(style);
    }
 
    useEffect(() =>{
        let getInlineStyle = null
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.style
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        // console.log("getInlineStyle", getInlineStyle)
    },[currentStyle])
 
    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle variant="green" id="dropdown-basic" className="mydrop" style={{color:"#1976D2",fontSize:"15px"}}>
           +
        </Dropdown.Toggle>
 
            <Dropdown.Menu className="setShadow" style={{ display: "flex", alignItems: "center" }}>
            {
            list.map((item) => (
                <Dropdown.Item
                className="drop_set"
                style={{ backgroundColor:"white",color:"black"}}
                onMouseDown={(e) => onChangeFn(e, item.style)}
                >
                {item.font}
                </Dropdown.Item>
            ))
            }
        </Dropdown.Menu>
        </Dropdown>
    )
  };
 
 
export  {SelectItem, InlineSelect, InlineColor, InlineFontSize,InlineSize,InlineParagraph,InlineTextAlign,InlineSymbols,InlineClearFormating,TableMaker,AddfunctionControl};
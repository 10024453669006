import React, { useState, useEffect } from 'react';

import alertCircle from '../Images/alertCircle.png';
import xCircle from '../Images/xCircle.png'
import { getorderedsection } from '../../services/assetSectionServices';
import ReorderPopup from './ReorderPopup';

const ReorderModal = ({ isOpen, onClose, items, setItems }) => {
  const [selectedItemIndexes, setSelectedItemIndexes] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };


  useEffect(() => {
    console.log("useEffectReorder", items)
  }, [isOpen])


  const handleSelectItem = (index) => {
    if (selectedItemIndexes.length < 2) {
      setSelectedItemIndexes([...selectedItemIndexes, index]);
    }
  };

  const handleSaveChanges = async () => {
    const [index1, index2] = selectedItemIndexes;
    const newItems = [...items];
    const temp = newItems[index1];
    newItems[index1] = newItems[index2];
    newItems[index2] = temp;
    setItems(newItems.map((item, index) => ({ ...item, id: index + 1 })));
    let id = []
    let payload = {
      "order":[],
      "assetType":"",
      "assetId":""
    }
     newItems.map((item, index) => {
      console.log("item__", item)
      id.push({_id:item._id})
      console.log("length_", id.length, index, newItems.length)
      if (id.length == newItems.length) {
        console.log("inside if")
        payload = {
          "order": id,
          "assetType": item.assetType,
          "assetId": item.assetId
        }
        // return payload;
      }
    }
    );
    console.log("contractPro",payload )

    setSelectedItemIndexes([]);
   
    try {
      const response = await getorderedsection(payload)
      console.log(response);
      if (response) {
        console.log('Sequence updated successfully');
      } else {
        console.error('Failed to update sequence');
      }
  
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCancelChanges = () => {
    setSelectedItemIndexes([]);
    //onClose();
  };

  const cancelModal = () => {
    setSelectedItemIndexes([]);
    onClose();

  }

  return (
    <div>
    <div style={{ display: isOpen ? 'block' : 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
      <div style={{ backgroundColor: 'white', width: '50%', margin: '100px auto', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', maxHeight: '500px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div><h2 style={{ color: 'rgb(61, 131, 250)',marginBottom:'15px' }}>Swap Sections</h2></div>
          <div>
            <span><img src={alertCircle} style={{ height: '20px', marginRight: '15px' }} alt="Description of the image" onClick={handleOpenPopup}/>
            
            </span>
            <span><img src={xCircle} style={{ height: '20px' }} alt="Description of the image" onClick={cancelModal} /></span>
          </div>
        </div>
        <ul style={{ listStyle: 'none', padding: 0, maxHeight: '300px', overflowY: 'auto', border: '1px solid lightgray' }}>
          {items.map((item, index) => (
            <li key={item.assetId} onClick={() => handleSelectItem(index)} style={{ padding: '10px', border: '1px solid lightgray', backgroundColor: selectedItemIndexes.includes(index) ? 'skyblue' : '#f0f0f0', cursor: 'pointer' }}>
              {item.title}
            </li>
          ))}
        </ul>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button style={{ padding: '10px', backgroundColor: 'white', color: 'Red', borderRadius: '10px', border: '1px solid red' }} onClick={handleCancelChanges}>Cancel</button>
          
          <button style={{ marginLeft: '10px', padding: '10px', backgroundColor: 'blue', color: 'white', borderRadius: '10px', border: 'none' }}
            onClick={handleSaveChanges}>Save New Order  </button>
        </div>
      {isPopupOpen && <ReorderPopup message="Select two sections which is to be swapped and click on save new order." onClose={handleClosePopup} />}

      </div>


    </div>

</div>
  );
};

export default ReorderModal;

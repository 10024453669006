import React, { Component } from "react";
import Loader from "../utils/loader";
import DocumentCard from "./Cards/DocumentCard";
import { Link } from "react-router-dom";
import documentService, { getAllDocumentsByUserId } from "../services/document";
import { downloadDocPDF, downDocumentPdf,downloadDocDocx } from "../services/adminServices";
import { paginate } from "../utils/paginate";
import PaginatioN from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ShareFile from "./shareFileModal";
import { ToastContainer, toast } from "react-toastify";
import authService from "../services/authService";
import swal from "sweetalert";
import "./MyDocuments.css";
import NoDocuments from "../static/No-documents-found.gif";
import SharedDocCard from "./Cards/sharedDocCard";
import $ from "jquery";
import Swal from "sweetalert2";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { Document, Packer } from "docx";
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import SearchBox from "./SearchBox";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TeamCollaborationServices from "../services/teamCollbarationServices";
import { connect } from "react-redux";
import {
  setUserDocument,
  setUserDeletedDocument,
  removeDeletedDocument,
} from "../redux/document/document.actions";
import { setCurrentUser } from "../redux/user/user.actions";
import ReactTour from "./ReactTour";

const steps = [
  {
    selector: ".first-step",
    content: "This helps you to submit your content for review.",
    position: "left",
  },
  {
    selector: ".second-step",
    content: "You can edit the document.",
    position: "bottom",
  },
  {
    selector: ".third-step",
    content: "You can compare the content of original document and changes made by you.",
    position: "bottom",
  },
];

export class MyDocuments extends Component {
  state = {
    loading: true,
    documents: [],
    deletedDocuments: [],
    sharedDocuments: [],
    currentDocumentPage: 1,
    currentDeletedDocumentPage: 1,
    currentSharedDocumentPage: 1,
    pageSize: 6,
    searchQuery: "",
    totalDocumentCount: "",
    totalDeletedDocumentCount: "",
    totalSharedDocumentCount: "",
    isAdmin: false,
    modalIsOpen: false,
    downloadDocModal: false,
    downloadDocName: "",
    downloadDoCPDF_: "",
    shareDocumentId: "",
    NoDocs: false,
    dashLink: "",
    date: "",
    intervalId: 0,
    userFeatures: {},
    docLink: "",
    reactTour: false,
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, downloadDocModal: false });
  };

  ///My Document
  async componentDidMount() {
    const {
      userDocuments,
      deletedDocuments,
      features,
      manageUser,
    } = this.props;
    // console.log("userDocuments",userDocuments)
    this.setState({ userFeatures: features }, () => this.initalCall());

    // if(userDocuments.length > 0){
    //   console.log("calling from redux");
    //   this.setState({
    //     documents: userDocuments,
    //     totalDocumentCount: userDocuments.length,
    //   }, () => this.getDeletedDocuments());
    // }else{
    //   console.log("calling from server");
    //   this.initalCall();
    // }
  }

  async componentWillMount() {
    $("#open").click(function () {
      $("#fileup").trigger("click");
    });
  }

  initalCall = async () => {
    const { data: userData } = this.props;
    sessionStorage.setItem("userData", JSON.stringify(userData));
    const user = authService.getCurrentUser();
    if (userData && user.isAdmin) {
      // console.log(userData._id);
      this.getDocumentsForAdmin(userData._id);
      this.setState({
        dashLink: "/admindashboard",
        docLink: "/manageuser",
        isAdmin: true,
      });
    } else {
      this.getDocuments("");
      this.setState({
        dashLink: "/dashboard",
      });
    }
  };

  // getCurrentUser_ = () => {
  //    const res = getCurrentUser();
  //    if(res.isAdmin) this.setState({ isAdmin: true })
  // }

  getDocumentsForAdmin = async (id) => {
    const document = await getAllDocumentsByUserId(id);
    //console.log(document);
    if (document.status === 200) {
      if (document.data.length < 1) {
        this.setState({ loading: false, NoDocs: true });
      } else {
        console.log("document", document);
        await document.data.reverse();
        this.setState({
          documents: document.data,
          totalDocumentCount: document.data.length,
          loading: false,
          isAdmin: true,
        });
      }
    }
  };

  ///get all my Document
  getDocuments = async () => {
    const { setUserDocument } = this.props;
    let document = await documentService.getAllDocuments();
    console.log("document", document);
    if (document.status === 200) {
      setUserDocument(document.data);
      this.setState(
        {
          documents: document.data,
          totalDocumentCount: document.data.length,
        },
        () => this.getDeletedDocuments()
      );
    } else {
      this.setState({ loading: false });
    }
  };

  //get Deleted Documents
  getDeletedDocuments = async () => {
    const { setUserDeletedDocument, deletedDocuments } = this.props;
    let deletedDoc = await documentService.getDeletedDocuments();
    // console.log(deletedDoc);
    if (deletedDoc.status === 200) {
      setUserDeletedDocument(deletedDoc.data);
      this.setState(
        {
          deletedDocuments: deletedDoc.data,
          totalDeletedDocumentCount: deletedDoc.data.length,
        },
        () => this.getSharedWithMe()
      );
    } else {
      this.setState({ loading: false }, () => this.getSharedWithMe());
    }
  };
  //get deleted Documents
  getSharedWithMe = async () => {
    let NoDocs = false;
    let sharedDocs = await documentService.getSharedDocuments();
    console.log("sharedDocs===>", sharedDocs);
    if (sharedDocs.status === 200) {
      this.setState({
        sharedDocuments: sharedDocs.data,
        totalSharedDocumentCount: sharedDocs.data.length,
        loading: false,
      });
    } else if (
      this.state.documents.length < 1 &&
      sharedDocs.data.length < 1 &&
      this.state.deletedDocuments.length < 1
    ) {
      NoDocs = true;
      this.setState({ loading: false, NoDocs });
    } else {
      this.setState({
        sharedDocuments: sharedDocs.data,
        totalSharedDocumentCount: sharedDocs.data.length,
        loading: false,
      });
    }
  };

  // Delete Document
  handleDelete = async (docs) => {
    console.log("DOCS====>", docs);
    let data = await documentService.getDocumentbyId(docs._id);
    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        if (docs.status === "draft") {
          if (docs.reviewStatus === true) {
            let og = await documentService.getDocumentbyId(
              docs.orginalDocumentID
            );
            if (og.status === 200) {
              if (og.data.pendingDocuments.includes(docs._id)) {
                toast.warn("Document is in Merging process");
                return;
              }
            }
          }
        }

        const { removeDeletedDocument } = this.props;
        swal({
          title: "Delete Document",
          text: "Are you sure ? You want to delete this document",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.setState({ loading: true });
            console.log(docs._id);
            let res = await documentService.deleteDocuments(docs._id);
            this.setState({ loading: false }, () => {
              if (res.status !== 200) {
                toast.error("Oops..Something went wrong!!");
              } else {
                toast.success("Document deleted!");
                this.initalCall();
              }
            });
          } else {
            // swal("Your imaginary file is safe!");
          }
        });
      } else {
        alert("document was already been deleted");
        this.initalCall();
      }
    } else {
      alert("document was already been deleted");
      this.initalCall();
    }
  };

  // restore document
  handleRestore = async (docs) => {
    console.log(docs);
    swal({
      title: "Restore Document",
      text: "Do you want to restore this document ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        this.setState({ loading: true });
        console.log(docs._id);
        let res = await documentService.restoreDocuments(docs._id);
        if (res.status !== 200) {
          this.setState({ loading: false });
          toast.error("Oops..Something went wrong!!");
        }
        this.initalCall();
        toast.success("Document restored!");
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  permanentDeleteDoc = async (doc) => {
    swal({
      title: "Permanent Deletion",
      text: "Do you want to permanently delete this document ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (permanentDelete) => {
      if (permanentDelete) {
        this.setState({ loading: true });
        let data = {
          _id: doc._id,
          drafts: doc.drafts,
          status: doc.status,
          orginalDocumentID: doc.orginalDocumentID,
          editor: doc.editor,
          pendingDocuments: doc.pendingDocuments,
          mergeDocuments: doc.mergeDocuments,
        };
        let res = await documentService.permanentDelete(data);
        if (res.status !== 200) {
          this.setState({ loading: false });
          toast.error("Document Deletion Failed !");
        }
        this.initalCall();
        toast.success("Document deleted permanently!");
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  ImportFromFileBodyContent = () => {
    let fileReader;

    const handleFileRead = () => {
      const content = fileReader.result;
      console.log(content);
    };

    const handleFileChosen = (file) => {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    return (
      <div>
        {/* File Upload */}

        <input
          type="file"
          id="file"
          className="input-file"
          accept=".docx"
          onChange={(e) => handleFileChosen(e.target.files[0])}
          style={{ display: "none" }}
        />
        <Card
          className="mt-2 text-center DocumentCardHover point"
          id="DocCard"
          onClick={this.handleFileUpload}
        >
          <CardContent>
            <InsertDriveFileIcon
              className="text-primary"
              style={{ fontSize: 48 }}
            />
            <Typography className="mt-2" color="textSecondary">
              New
            </Typography>
          </CardContent>
          <CardActions>
            <Typography className="mt-2" color="textSecondary">
              <i
                className="fa fa-upload text-primary m-1"
                aria-hidden="true"
              ></i>{" "}
              Upload
            </Typography>
          </CardActions>
        </Card>
      </div>
    );
  };

  handleDownload = async (docs) => {
    let data = await documentService.getDocumentbyId(docs._id);
    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        this.setState({
          downloadDocModal: true,
          downloadDocName: docs.documentName,
          downloadDoCPDF_: docs,
        });
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    } else {
      alert("document has been deleted");
      this.getDocuments();
    }
  };

  // download Docx
  handleDocxDownload = async (data) => {
    // this.setState({ loading: true });
    // const contentState = convertFromRaw(JSON.parse(data.onlyDocumentContent));
    // data["content"] = contentState.getPlainText();
    // data["content"] = stateToHTML(data["content"]);
    // const doc = new Document();
    
    // const paragraph = new Paragraph(data["content"]);
    // doc
    //   .createParagraph(`${data.documentName}`)
    //   .center()
    //   .title()
    //   .thematicBreak();
    // doc.createParagraph(data["content"]).justified();
    
    // doc.addParagraph(paragraph);
    
    // const packer = new Packer();
    
    // packer.toBlob(doc).then((blob) => {
      //   console.log(blob);
      //   saveAs(blob, `${data.documentName}.docx`);
      //   console.log("Document created successfully");
      // });

      data["content"] = convertFromRaw(JSON.parse(data.onlyDocumentContent));
      data["content"] = stateToHTML(data["content"]);

      const htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Document</title>
      </head>
      <body>
        <h3>${data.documentName}</h3>
        <hr/>
        ${data["content"]}
      </body>
      </html>`
      
      asBlob(htmlString).then(docx => {
        saveAs(docx, `${data.documentName}.docx`);
      })
      // console.log("content", data["content"]);
      // let res = await downloadDocDocx(data);
      // if (res.status === 200) {
      //  toast.success(res.message)
      //  saveAs(res.data, `${data.documentName}.docx`);
      // } else {
      //   toast.error("Oops something went wrong");
      // }

      this.setState({ downloadDocModal: false, loading: false });
      
  };

  downloadDocPDF = async (data) => {
    this.setState({ loading: true });
    data["content"] = convertFromRaw(JSON.parse(data.onlyDocumentContent));
    data["content"] = stateToHTML(data["content"]);
    let res = await downloadDocPDF(data);
    console.log(data);
    if (res.status === 200) {
      downDocumentPdf(data._id, data.documentName);
      toast.success("Document downloaded!");
    } else {
      toast.error("Oops something went wrong");
    }
    this.setState({ downloadDocModal: false, loading: false });
  };

  handleEdit = (docs) => {
    Swal.fire({
      title: "Edit as a new document or update the existing one?",
      type: "warning",

      showCancelButton: true,
      showCloseButton: true,

      confirmButtonText: "Edit",
      cancelButtonText: "Update",
    }).then((result) => {
      if (result.value === true) {
        this.handleNewUpdate(docs, true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.handleNewUpdate(docs, false);
      }
    });
  };

  handleShareDocEdit = (docs) => {
    console.log(docs);

    this.props.history.push({
      pathname: `/texteditor`,
      data: { document: docs },
    });
  };

  handleNewUpdate = async (docs, isNew) => {
    let data = await documentService.getDocumentbyId(docs._id);
    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        const { history } = this.props;
        history.push({
          pathname: `/texteditor`,
          data: { document: docs, isNew },
        });
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    } else {
      alert("document has been deleted");
      this.getDocuments();
    }
  };

  shareFile = async (document) => {
    let data = await documentService.getDocumentbyId(document._id);
    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        if (this.state.userFeatures.shareDocument === true) {
          this.setState({
            shareDocumentId: document,
          });
          console.log(document);
          this.setState({ modalIsOpen: true });
        } else {
          toast("Please Upgrade your plan to enable this feature");
        }
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    } else {
      alert("document has been deleted");
      this.getDocuments();
    }
  };

  viewDocument = async (document, isReviewing) => {
    const { history, userData } = this.props;
    let data = await documentService.getDocumentbyId(document._id);
    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        history.push({
          pathname: `/documentpreview/${document._id}`,
          state: { detail: document, isReviewing, userData },
        });
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    } else {
      alert("document has been deleted");
      this.getDocuments();
    }
  };

  compareDocument = async (data) => {
    const { history } = this.props;
    let originalDocument = await documentService.getDocumentbyId(
      data.orginalDocumentID
    );
    if (originalDocument.status === 200) {
      history.push({
        pathname: `/compareDocument`,
        state: { draftData: data, originalData: originalDocument },
      });
    } else {
      toast("Original document has been deleted");
    }
  };

  submitForReview = async (document) => {
    swal({
      title: "Review Document",
      text: "Submit your Document for Review ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (reviewDoc) => {
      if (reviewDoc) {
        const response = await TeamCollaborationServices.reviewDocument(
          document._id
        );
        if (response.status !== 200) {
          toast.error(response.message);
        } else {
          toast.info(response.message);
        }
        this.initalCall();
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  handleTeamCollab = async (data) => {
    if (this.state.userFeatures.AcceptDecline === false) {
      toast("Please upgrade your plan to enable Team Collaboration feature");
    } else {
      let check = await documentService.getDocumentbyId(data._id);
      if (check.status === 200) {
        if (check.data.activeDoc === true) {
          this.props.history.push({
            pathname: "/collaboration",
            state: { document: data },
          });
        } else {
          alert("document has been deleted");
          this.getDocuments();
        }
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    }
  };

  handleDeltedDocument = () => {};

  handleSearch = (e) => {
    this.setState({ searchQuery: e.target.value, currentPage: 1 });
  };

  handleDocumentPageChange = (page) => {
    this.setState({ currentDocumentPage: page });
  };

  handleDeletedDocumentPageChange = (page) => {
    this.setState({ currentDeletedDocumentPage: page });
  };

  handleSharedDocumentPageChange = (page) => {
    this.setState({ currentSharedDocumentPage: page });
  };

  getPagedData = () => {
    const {
      documents,
      sharedDocuments,
      deletedDocuments,
      currentDocumentPage,
      currentDeletedDocumentPage,
      currentSharedDocumentPage,
      pageSize,
      searchQuery,
    } = this.state;

    let filteredDocument = documents;
    let filteredDeletedDocuments = deletedDocuments;
    let filteredSharedDocuments = sharedDocuments;

    if (searchQuery) {
      filteredDocument = documents.filter((doc) =>
        doc.documentName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

      filteredDeletedDocuments = deletedDocuments.filter((deldoc) =>
        deldoc.documentName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

      filteredSharedDocuments = sharedDocuments.filter((sharedoc) =>
        sharedoc.documentName
          .toLowerCase()
          .startsWith(searchQuery.toLowerCase())
      );
    }

    const Docs = paginate(filteredDocument, currentDocumentPage, pageSize);

    const DelDocs = paginate(
      filteredDeletedDocuments,
      currentDeletedDocumentPage,
      pageSize
    );
    const ShareDocs = paginate(
      filteredSharedDocuments,
      currentSharedDocumentPage,
      pageSize
    );

    return {
      totalDocumentCount: filteredDocument.length,
      totalDeletedDocumentCount: filteredDeletedDocuments.length,
      totalSharedDocumentCount: filteredSharedDocuments.length,
      documents: Docs,
      deletedDocuments: DelDocs,
      sharedDocuments: ShareDocs,
    };
  };

  getUserDocs = () => {
    const { userDocuments, setUserDocument } = this.props;
    if (userDocuments.length > 0) {
      this.setState(
        {
          documents: userDocuments,
          totalDocumentCount: userDocuments.length,
        },
        () => this.getDeletedDocuments()
      );
    } else {
      this.initalCall();
    }
  };

  render() {
    const {
      currentDocumentPage,
      currentDeletedDocumentPage,
      currentSharedDocumentPage,
      isAdmin,
      NoDocs,
    } = this.state;

    console.log(NoDocs);

    const {
      totalDocumentCount,
      totalDeletedDocumentCount,
      totalSharedDocumentCount,
      documents,
      deletedDocuments,
      sharedDocuments,
    } = this.getPagedData();

    return (
      <div className="docCont">
        <Loader loading={this.state.loading} />
        <ToastContainer />
        <div className="">
          {isAdmin ? (
            <BreadCrumb
              l1="Home"
              l2="Manage Users"
              l3="My Documents"
              dashboardlink={this.state.dashLink}
              documentLink={this.state.docLink}
            />
          ) : (
            <BreadCrumb
              l1="Home"
              l2="My Documents"
              dashboardlink={this.state.dashLink}
            />
          )}
          {/* 404 Condition */}
          {NoDocs ? (
            <div className="text-center mb-5">
              <Link to={{ pathname: this.state.isAdmin ? "" : "/texteditor" }}>
                <img
                  className="img-fluid setImgMargin"
                  src={NoDocuments}
                  alt="404"
                ></img>
              </Link>
            </div>
          ) : (
            <div>
              <div className="row pl-1">
                {/* Title */}
                <div className="row docHeadRow" style={{ paddingLeft: "3%" }}>
                  <HeadingB text="My Documents" style={{ color: "#3e80f1" }} />
                </div>

                <div className="col-md-4 pl-5">
                  <SearchBox
                    value={this.state.searchQuery}
                    onSearch={this.handleSearch}
                    placeholder="&#xF002; Search document"
                  />
                </div>

                {sharedDocuments.length > 0 ? (
                  <i
                    className="fa fa-question-circle mt-2"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="Need Help ?"
                    style={{
                      cursor: "pointer",
                      color: "dodgerblue",
                      fontSize: "15px",
                    }}
                    onClick={() => this.setState({ reactTour: true })}
                  ></i>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-4" style={{ paddingLeft: "1%" }}>
                {/* File Upload */}
                {/* { !isAdmin ? (<DocUpload />) : "" } */}

                {documents.length > 0 &&
                  documents.map((document) => (
                    <div className="col-sm-6 col-md-4 col-xl-4 p-3">
                      <DocumentCard
                        key={document._id}
                        data={document}
                        UpdateNewDoc={() => this.handleEdit(document, true)}
                        editDocs={() => this.handleEdit(document, false)}
                        shareDocs={() => this.shareFile(document)}
                        deleteDocs={() => this.handleDelete(document)}
                        showDocs={() => this.viewDocument(document, false)}
                        download={() => this.handleDownload(document)}
                        isEdit={true}
                        isAdmin={this.state.isAdmin}
                        handleTeamCollab={this.handleTeamCollab}
                      />
                    </div>
                  ))}
              </div>

              {/* Documents Pagination */}
              {totalDocumentCount > 6 ? (
                <>
                  <div
                    className="ml-auto"
                    style={{ float: "right", margin: "2% 2% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={currentDocumentPage}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={totalDocumentCount}
                      pageRangeDisplayed={5}
                      onChange={this.handleDocumentPageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}

              {/* shared Documents  */}
              {sharedDocuments.length > 0 && (
                <div>
                  <hr />
                  {/* <h5 className="mt-5 pl-1" style={{ fontFamily: "roboto" }}>
                    Shared With Me Documents
                  </h5> */}
                  <HeadingB text=" Shared With Me Documents" />
                </div>
              )}
              <div className="row pl-3" style={{ marginTop: "2%" }}>
                {sharedDocuments.length > 0 &&
                  sharedDocuments.map((document) => (
                    <SharedDocCard
                      key={document._id}
                      data={document}
                      showDocs={() => this.viewDocument(document, false)}
                      submitForReview={this.submitForReview}
                      editDocs={() => this.handleShareDocEdit(document, false)}
                      deleteDocs={this.handleDelete}
                      compareDocument={this.compareDocument}
                    />
                  ))}

                {/* Shared Document Pagination */}
                {totalSharedDocumentCount > 6 ? (
                  <div
                    className="row ml-auto"
                    style={{ margin: "2% 2% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={currentSharedDocumentPage}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={totalSharedDocumentCount}
                      pageRangeDisplayed={5}
                      onChange={this.handleSharedDocumentPageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Deleted Documents  */}
              {totalDeletedDocumentCount < 1 ||
                (isAdmin ? (
                  ""
                ) : (
                  <div>
                    <hr />

                    <div className="row">
                      <p
                        className="mt-4 ft_lg text-e"
                        style={{ marginLeft: "2%", color: "#3e80f1" }}
                      >
                        Deleted Documents
                      </p>
                    </div>

                    <div className="row mt-2" style={{ paddingLeft: "1%" }}>
                      {deletedDocuments.length > 0 &&
                        deletedDocuments.map((document) => (
                          <div className="col-sm-6 col-md-4 col-xl-4 p-3">
                            <DocumentCard
                              key={document._id}
                              data={document}
                              showDocs={() =>
                                this.handleDeltedDocument(document, false)
                              }
                              restoreDocs={() => this.handleRestore(document)}
                              permanentDelete={this.permanentDeleteDoc}
                              isEdit={false}
                            />
                          </div>
                        ))}
                    </div>

                    {/* Delted Documents Pagination */}
                    {totalDeletedDocumentCount > 6 ? (
                      <div
                        className="d-flex justify-content-end mt-3"
                        style={
                          {
                            // margin: "2% 2% 0% 0%",
                            // float: "right !important"
                          }
                        }
                      >
                        <PaginatioN
                          activePage={currentDeletedDocumentPage}
                          itemsCountPerPage={this.state.pageSize}
                          totalItemsCount={totalDeletedDocumentCount}
                          pageRangeDisplayed={5}
                          onChange={this.handleDeletedDocumentPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}

              <hr />
            </div>
          )}

          {/* ------------- */}
        </div>
        {/* End */}

        {/* Modal */}
        <Modal
          show={this.state.modalIsOpen}
          onHide={this.closeModal}
          centered
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Share File
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fluid">
              <ShareFile
                shareDocumentId={this.state.shareDocumentId}
                getDocuments={this.getDocuments}
                closeModal={this.closeModal}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal */}

        {/* Download Doc Modal */}

        <Modal
          size="sm"
          show={this.state.downloadDocModal}
          onHide={this.closeModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.downloadDocName}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="text-center">
              <span>Download as...</span>
              <br /> <br />
              <button
                className="btn btn-primary inviteMember mr-3 pl-5 pr-5"
                onClick={() => this.downloadDocPDF(this.state.downloadDoCPDF_)}
              >
                PDF
              </button>
              <button
                className="btn btn-primary inviteMember pl-5 pr-5"
                onClick={() =>
                  this.handleDocxDownload(this.state.downloadDoCPDF_)
                }
              >
                Word
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Download Doc Modal */}

        <ReactTour
          steps={steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  userDocuments: state.document.userDocuments,
  deletedDocuments: state.document.deletedDocuments,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setUserDeletedDocument: (document) =>
    dispatch(setUserDeletedDocument(document)),
  setUserDocument: (document) => dispatch(setUserDocument(document)),
  removeDeletedDocument: (document) =>
    dispatch(removeDeletedDocument(document)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyDocuments));

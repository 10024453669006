
import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
apiEndpoint = apiUrl + "/newbucket";
} else {
apiEndpoint = herokuUrl + "/newbucket";
}



export async function uploadFile(noticeID,file) {
  let data = await httpService.post(`${apiEndpoint}/newupload`,{"noticeID":noticeID,filename:file});
  console.log(data);
  return data;
}



export async function getList() {
  const response = await httpService.get(`${apiEndpoint}/list`);
  return response;
}


export async function deleteFile(orgId, opportunityid, assetid,assetsectionId,filename,userId) {
  let data = await httpService.delete(`${apiEndpoint}/deleteDocument/${orgId}/${opportunityid}/${assetid}/${assetsectionId}/${filename}/${userId}`);
  console.log(data);
  return data;
}

export async function deleteSubsectionFile(orgId, opportunityid, assetid,subsectionId,filename,userId) {
  let data = await httpService.delete(`${apiEndpoint}/deleteSubsection/${orgId}/${opportunityid}/${assetid}/${subsectionId}/${filename}/${userId}`);
  console.log(data);
  return data;
}

export async function extractPdfData(resourceLinks) {
  try {
    const response = await httpService.post(`${apiEndpoint}/getContentFromPdf`, { resourceLinks });
    return response.data;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}


export async function improveInput(text,aidata,organizationName,category) {
  try {
    const response = await httpService.post(`${apiEndpoint}/improveInputText2`, { text , aidata ,organizationName,category});
    return response;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}
export async function improveInputSection(text ,departMentSector) {
  try {
    const response = await httpService.post(`${apiEndpoint}/generateSectionPrompt`, { text,"sector":departMentSector });
    return response;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}

export async function improveSectionContent(text ,sections) {
  try {
    const response = await httpService.post(`${apiEndpoint}/generateSectionContent`, { text,sections});
    return response;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}




export async function aiGeneratedRequirnments(resourceLinks,clausesData) {
  try {
    const response = await httpService.post(`${apiEndpoint}/generateAIRequirments`, { resourceLinks,clausesData });
    return response;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}



export async function requirementMapping(requirements, fileContent) {


  console.log("432   ",  requirements, fileContent )
  try {
    const response = await httpService.post(`${apiEndpoint}/requirmentMapping`, { requirements, fileContent });
    return response;
  } catch (error) {
    console.error('Error extracting PDF data:', error);
    throw error;
  }
}

export async function keywordForSamrtSuggestion(data) {
  const response = await httpService.post(`${apiEndpoint}/keywordForSamrtSuggestion`, {data});
  return response;
}

export async function smartSuggestion(data) {
  const response = await httpService.post(`${apiEndpoint}/smartSuggestion`, {data});
  return response;
}


export async function deleteExisting(data) {
  const response = await httpService.post(`${apiEndpoint}/deleteSectionDocument`, {data});
  return response;
}


export async function getS3Data(data) {
  const response = await httpService.post(`${apiEndpoint}/getS3Data`, {data});
  return response;
}




export async function getCoverPages(data) {
  const response = await fetch(`${apiEndpoint}/getCoverPages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  console.log(response,"serviceresponse")
  if (!response.ok) {
    throw new Error('Error fetching template preview');
  }

  const blob = await response.blob();
  return blob;
}

export async function addCoverImage(data){
  const response = await httpService.post(`${apiEndpoint}/addCoverImage`, {data});
  return response;
}

export async function fetchImagePreview(data) {
  const response = await fetch(`${apiEndpoint}/fetchImagePreview`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Error fetching image preview');
  }

  const blob = await response.blob();
  return blob;
}

export async function getEstimateTemplatePreview(data) {
  const response = await fetch(`${apiEndpoint}/getEstimateTemplatePreview`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Error fetching template preview');
  }

  const blob = await response.blob();
  return blob;
}

export async function convertPDFToHTML(data) {
    const response = await httpService.post(`${apiEndpoint}/convertPDFToHTML`, {data});
    return response;

}


export async function getFileContent(data,orgName,userid) {
const datas = {data,orgName,userid}
  console.log("Data Called", data)
  let isBinary = false
  let doc = "doc"
  if (data.includes(".doc") || data.includes(".docx")) {
    isBinary = false
    doc = "doc"
  }
  const response = await httpService.post(`${apiEndpoint}/fetchTemplatePreview`,{datas}, isBinary,doc);
  console.log("Responce  ", response)
  return response;
}

export async function referenceAttachmentText(data) {
  const response = await httpService.post(`${apiEndpoint}/extractFileContentFromLink`, data);
  return response;
}


export default  {
  addCoverImage,
  convertPDFToHTML,
  getFileContent,
  getEstimateTemplatePreview,
  fetchImagePreview,
  getS3Data,

}
import React, { useEffect, useState } from "react";
import "./ContactCard.css";
import { Avatar } from "@material-ui/core";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import { Badge, Box, Typography } from "@mui/material";
// import LetterAvatars from "../../Avatar";
import LetterAvatars from "../Contacts/Avatar";
import { socket } from "../Socket/Socket";
import { capitalize } from 'lodash';
import ChatServices from "../../../services/ChatServices";
import VideoContext from "../../../contexts/VideoContext";

const ContactCard = ({ contact, archive , index}) => {

  const { setSelectedContactId, setShowFilePage, setSelectedGroupId, setDeleteGroupId, userId , setSelectedContactChat, setSelectedInboxId,  setColorIndex } = useContext(ChatContext);

  const { setIdToCall } = useContext(VideoContext);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(contact.unreadCount);

  const openChat = (id) => {
    setSelectedContactId(id);
    setSelectedContactChat(contact);
    setSelectedInboxId(contact._id);
    setColorIndex(index);
    setShowFilePage(false);
    setSelectedGroupId(null);
    socket.emit('join_personal_room', { senderUserId: userId, receiverUserId: id });
    fetchunReadMsg(id);
    setDeleteGroupId(null);
  };

  const fetchunReadMsg = async (id) => {
    try {
      const response = await ChatServices.markReadMsg(userId , id);
      if (response.status === 200) {
         setUnreadMessagesCount(0);
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }

  return (
    <div className={`contactcard${archive !== '' ? archive : ''}`} style={{ backgroundColor: unreadMessagesCount === 0  ? '#fff' : '#B4D4FF' }} onClick={() => openChat(contact.userId)}>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <LetterAvatars name={contact.name} status={contact.status} />
        <div className="contactcard__username">
          <h3>{capitalize(contact.name)}</h3>
        </div>
      </Box>
      <Box display='flex' justifyContent='end' alignContent='end'>
        <Badge color="primary" badgeContent={unreadMessagesCount} max={999}>
        </Badge>
      </Box>
    </div>
  );
};

export default ContactCard;

import React, { Component } from "react";
import Loader from "../utils/loader";
import DocumentServices from "../services/document";
import DocumentCard from "./Cards/DocumentCard";
import BreadCrumb from "./Cards/BreadCrumbs";
import { withRouter } from "react-router-dom";
import HeadingB from "./Cards/HeadingB";
import notags from "../static/notags.png";
import TagsCard from "./Cards/TagsCard";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import ReactTour from "./ReactTour";

const steps= [
  {
    selector: '.reactour-1',
    content: 'Left side displays all the document related tags to select upon',
    position: 'right',
  },
  {
    selector: '.reactour-2',
    content: 'Over here documents will be displayed as per selected tags',
    position: 'left',
  },
];

export class DocTags extends Component {
  state = {
    loading: true,
    characters: [],
    alltags: [],
    tagList: [],
    documents: [],
    filteredDoc: [],
    intervalId: 0,
    filter: [],
    reactTour: false,
  };

  componentDidMount() {
    this.getalltags();
    // this.getuserDocs();
  }
  starting = (init, tags) => {
    return tags.filter((tag) => tag.toLowerCase().startsWith(init));
  };
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(this.scrollStep(), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  searchDoc = (tag) => {
    this.setState({
      filteredDoc: [],
    });
    let doc = this.state.documents;
    let filterDoc = doc.filter((ok) => {
      for (let i = 0; i < ok.tags.length; i++) {
        if (ok.tags[i] === tag) {
          return ok;
        }
      }
    });
    this.setState({
      filteredDoc: filterDoc,
    });
    console.log(filterDoc);
    this.scrollToTop();
  };

  // filterOnChange = e => {
  //   if (e.target.value === "") {
  //     this.setState({ filter: this.state.alltags });
  //   } else {
  //     let value = e.target.value;
  //     let tags = this.state.alltags;
  //     let result = [];
  //     result = tags.filter(tag => {
  //       return tag.title.toLowerCase().search(value.toLowerCase()) !== -1;
  //     });
  //     this.setState({ filter: result });
  //   }
  // };

  getuserDocs = async () => {
    let document = await DocumentServices.getAllDocuments();
    if (document.status === 200) {
      this.setState({
        documents: document.data,
        loading: false,
      });
    }
    // console.log(this.state.documents);
  };
  getalltags = async () => {
    const characters = [];
    const result = [];
    let tags = await DocumentServices.getAllTags();
    this.setState({ alltags: tags.data });
    tags = tags.data;
    for (let i = 65; i <= 90; i++) {
      characters.push(String.fromCharCode(i));
    }

    characters.map((char) => {
      let letter = char;
      let Tags = this.starting(char.toLowerCase(), tags);
      if (Tags.length > 0) {
        const words = {
          characters: letter,
          Tags: Tags,
        };
        result.push(words);
      }
    });
    this.setState({ tagList: result }, () => this.getuserDocs());
    console.log(this.state.alltags);
  };

  viewDocument = (document, isReviewing) => {
    const { history } = this.props;
    history.push({
      pathname: `/documentpreview/${document._id}`,
      state: { detail: document, isReviewing, isReviewing },
    });
  };

  handleTeamCollab = async (data) => {
    if (this.props.features.AcceptDecline === false) {
      toast("Please upgrade your plan to enable Team Collaboration feature");
    } else {
      let check = await DocumentServices.getDocumentbyId(data._id);
      if (check.status === 200) {
        if (check.data.activeDoc === true) {
          this.props.history.push({
            pathname: "/collaboration",
            state: { document: data },
          });
        } else {
          alert("document has been deleted");
          this.getDocuments();
        }
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    }
  };

  render() {
    const { tagList, filteredDoc, alltags } = this.state;
    // console.log(tagList);
    return (
      <div className="">
        <ReactTour 
          steps={steps} 
          isOpen={this.state.reactTour} 
          closeTour={()=>this.setState({ reactTour: false })}
        />
        <Loader loading={this.state.loading} />

        <BreadCrumb l1="Home" l2="Tags" dashboardlink="/dashboard" />

        {alltags.length === 0 ? (
          <div className="text-center">
            <img src={notags} />
          </div>
        ) : (
          <>
            <div className="row mb-2">
              <div className="col-11">
                <HeadingB text="Tags" />
              </div>
              <div className="col-1">
                <i 
                    className="fa fa-question-circle mt-2" 
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="Need Help ?"
                    style={{
                      cursor: "pointer",
                      color: "dodgerblue",
                      fontSize: "15px"
                    }}
                    onClick={()=>this.setState({ reactTour: true })}
                  ></i>
              </div>
            </div>
            
            <div className="row border-top">
              <div className="col-sm-4 reactour-1 border-right pt-3" style={{
                  height: "570px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}>
                {tagList.length > 0 &&
                  tagList.map((tag, index) => (
                    <div className="">
                      <TagsCard data={tag} abc={this.searchDoc} />
                    </div>
                  ))}
              </div>
              <div
                className="col-sm-8 pt-3 reactour-2"
                // style={{ borderLeft: "2px solid rgba(0,0,0,.1)" }}
              >
                <div className="row pl-3">
                  {filteredDoc.map((document) => (
                    <div className="col-sm-6 p-2">
                      <DocumentCard
                        key={document._id}
                        data={document}
                        showDocs={() => this.viewDocument(document, false)}
                        hide={true}
                        handleTeamCollab={this.handleTeamCollab}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(
  mapStateToProps
)(withRouter(DocTags));

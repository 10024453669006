import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";

  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/opportunities`;
   
  } else {
    apiEndpoint = `${apiUrl}/opportunities`;
   
  }
   
  export async function getUserInfo(data){
    const response=await httpService.post(`${apiEndpoint}/getUserInfo`,{email:data});
    return response;
  }
   
  export async function searchOpportunity(data, showPastOpportunities, status){
    if(showPastOpportunities){

      console.log("ppp show past opportunities ", showPastOpportunities)
      const reducedData = data.data.reduce((acc, curr) => {
        acc[curr.type] = curr; // Overwrite existing entries with the same type
        return acc;
      }, {});
      
      // Step 2: Convert the reduced object back into an array
      let finalData = Object.values(reducedData);
      
      // Step 3: Filter out objects where fedOrg is an empty list
      finalData = finalData.filter(obj => obj.fedOrg.length > 0);
      
      // Step 4: If finalData is empty, ensure data.data is set to an empty array
      data.data = finalData.length > 0 ? finalData : [];
      console.log(data,"kkklux")
      const response=await httpService.post(`${apiEndpoint}/searchPastOpportunity`,{data,status});
      return response;


    }else {
      console.log("ppp show future opportunities ", showPastOpportunities)
      const reducedData = data.data.reduce((acc, curr) => {
        acc[curr.type] = curr; // Overwrite existing entries with the same type
        return acc;
      }, {});
      
      // Step 2: Convert the reduced object back into an array
      let finalData = Object.values(reducedData);
      
      // Step 3: Filter out objects where fedOrg is an empty list
      finalData = finalData.filter(obj => obj.fedOrg.length > 0);
      
      // Step 4: If finalData is empty, ensure data.data is set to an empty array
      data.data = finalData.length > 0 ? finalData : [];

      console.log(data,"kkklux22")
      const response=await httpService.post(`${apiEndpoint}/searchOpportunity`,{data,status});
      return response;

    }
  }
   
  export async function searchOpportunityrefOrg(data){
    const response=await httpService.post(`${apiEndpoint}/searchOpportunityrefOrg`,{data});
    return response;
  }


  export async function getopportunities(data,showPastOpportunities){
    // alert("jjjj")
    const response=await httpService.post(`${apiEndpoint}/getopportunities`,{data,showPastOpportunities});
    return response;
  }

  export async function getDescription(data){
    const response=await httpService.post(`${apiEndpoint}/getDescription`,{data});
    return response;
  }
  
  export async function matchingOppPercentage(data){
    const response=await httpService.post(`${apiEndpoint}/matchingOppPercentage`,{data});
    return response;
  }
  export async function matchingOppPercentageAdditional(data){
    const response=await httpService.post(`${apiEndpoint}/matchingOppPercentageAdditional`,{data});
    return response;
  }
  export async function fetchHistory(data){
    const response=await httpService.post(`${apiEndpoint}/fetchHistory`,{data});
    return response;
  }

  export async function getDashboardProjects(){
    const response=await httpService.get(`${apiEndpoint}/getDashboardProjects`);
    return response;
  }

  export async function isSummarizedDescription(id){
    const response=await httpService.post(`${apiEndpoint}/isSummarizedDescription`,{id});
    return response;
  }

  export async function updateSummarizedDescription(id , summarizedDescription ){
    const response=await httpService.post(`${apiEndpoint}/updateSummarizedDescription`,{id,summarizedDescription});
    return response;
  }

  export async function fetchViewChanges(data){
    const response=await httpService.post(`${apiEndpoint}/fetchViewChanges`,{data})
      return response;
  }

  export async function checkOpportunityOrder(data){
    const response=await httpService.post(`${apiEndpoint}/checkOpportunityOrder`,{data})
      return response;
  }
  export async function addManualOpportunities(data){
    const response=await httpService.post(`${apiEndpoint}/addManualOpportunities`,{data})
      return response;
  }

  export async function updateOpportunity(data){
    const response=await httpService.post(`${apiEndpoint}/updateOpportunity`,{data})
      return response;
  }

  export async function getFile(data){
    const response=await httpService.get(`${apiEndpoint}/getFile/${data.id}/${data.fileName}`)
      return response;
  }

  export default {
    searchOpportunity,
    searchOpportunityrefOrg,
    getopportunities,
    matchingOppPercentage,
    matchingOppPercentageAdditional,
    fetchHistory,
    getDashboardProjects,
    getDescription,
    fetchViewChanges,
    checkOpportunityOrder,
    addManualOpportunities,
    updateOpportunity,
    getFile
  }
import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { TextField } from "@mui/material";
import SelectDropdownMui from "./SelectDropdownMui";
import { Tooltip } from "recharts";

const PieChartSubAdmin = ({
  chartHeading,
  pieChartData,
  isSelectDropdownShown,
  pieChartSelectOptions,
  selectDropdownDisabled,
  boxWidth,
  boxHeight,
  headingTooltip
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };




  const tooltipStyles = {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
  };

  const tooltipTextStyles = {
    visibility: "hidden",
    // width: "140px",
    backgroundColor: "grey",
    color: "#fff",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 0",
    position: "absolute",
    zIndex: 1,
    bottom: "100%", // Position above the element
    left: "50%",
    marginLeft: "-70px",
    opacity: 0,
    transition: "opacity 0.3s",
     fontSize: "10px"
  };


  const tooltipTextAfterStyles = {
    content: "''",
    position: "absolute",
    top: "100%", // Arrow at the bottom
    left: "50%",
    marginLeft: "-5px",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "grey transparent transparent transparent",
  };

  const tooltipHoverStyles = {
    visibility: "visible",
    opacity: 1,
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          border: "1px solid #ccc",
          boxShadow: "2px 2px 5px -2px #000000",
          padding: "2px",
          width: boxWidth || "100%",
          height: boxHeight || "auto",
        }}
      >
        <div className="row p-2">
          
          <div className="col-md-7">
          <div  style={tooltipStyles}
              onMouseEnter={(e) => {
                const tooltipText = e.currentTarget.querySelector(".tooltip-text");
                Object.assign(tooltipText.style, tooltipHoverStyles);
              }}
              onMouseLeave={(e) => {
                const tooltipText = e.currentTarget.querySelector(".tooltip-text");
                Object.assign(tooltipText.style, { visibility: "hidden", opacity: 0 });
              }}
              >
            <h6
              className="p-2"
              style={{
                color: "#1976d2",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {chartHeading}
            </h6>
            <span className="tooltip-text" style={tooltipTextStyles}>
                {headingTooltip}
                <span style={tooltipTextAfterStyles}></span>
              </span>
              </div>
          </div>
         
          <div className="col-md-5">
            <div style={{ display: "flex", alignItems: "center" }}>
              {isSelectDropdownShown && (
                <SelectDropdownMui
                  optionsList={pieChartSelectOptions}
                  disabled={selectDropdownDisabled}
                />
              )}
              <TextField
                id="date-picker"
                type="date"
                value={selectedDate}
                onChange={(e) => handleDateChange(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                    style: {
                      height: '30px', 
                    },
                  }}
          
              />
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "2px solid #ccc", margin: "0 8px" }}></div>
        <div className="row" style={{marginTop:'20px'}}>
          <div className="col-md-7 px-4">
            <div>
              <PieChart
                data={pieChartData}
                label={(props) => `${props.dataEntry["value"]}`}
                labelStyle={{
                  fontSize: "0.5rem",
                  fontFamily: "sans-serif",
                  fill: "#ffffff",
                }}
                labelPosition={70}
                animate
              />
            </div>
          </div>
          <div className="col-md-5">
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {pieChartData.map((entry, index) => (
                <li
                  key={`item-${index}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <svg width="20" height="20">
                    <circle cx="10" cy="10" r="8" fill={entry.color} />
                  </svg>
                  <span style={{ marginLeft: "8px" }}>
                    {`${entry.title} (${entry.value})`}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PieChartSubAdmin;

// // import React, { useState, useEffect } from 'react';
// // import TextField from '@mui/material/TextField';
// // import Autocomplete from '@mui/material/Autocomplete';

// // const SingleSelectDropDown = ({ optionList, onChange, labelKey, label, value }) => {
// //   const [selectedItem, setSelectedItem] = useState(null);
// // console.log(optionList,"optionListoptionList")
// //   useEffect(() => {
// //     if (value) {
// //       setSelectedItem(value);
// //     }
// //   }, [value]);
 
// //   const handleOnChange = (event, newValue) => {
// //     setSelectedItem(newValue);
// //     onChange(newValue);
// //   };

// //   return (
// //     <div style={{ width: '100%',fontWeight:"900",color:"black" }}>
// //       <Autocomplete
// //         id="single-select"
// //         options={optionList}
// //         getOptionLabel={(option) => option[labelKey]}
// //         value={selectedItem}
// //         onChange={handleOnChange}
// //         style={{ width: '100%', background: "white",fontWeight:"900" }}
// //         renderInput={(params) => (
// //           <TextField {...params} label={label || 'Select Item'} variant="outlined" style={{fontWeight:"900",color:"black"}}/>
// //         )}
// //       />
// //     </div>
// //   );
// // };

// // export default SingleSelectDropDown;

// import React, { useState, useEffect } from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';

// const SingleSelectDropDown = ({ optionList, onChange, labelKey, label, value }) => {
//   const [selectedItem, setSelectedItem] = useState(null);
//   console.log(value,"dropvalue")
//   useEffect(() => {
//     // if (value) {
//       setSelectedItem({value});
//     // }
//   }, [value]);
 
//   const handleOnChange = (event, newValue) => {
//     setSelectedItem(newValue);
//     onChange(newValue);
//   };
//   console.log(typeof(selectedItem),"listt")

//   return (
//     <div style={{ width: '100%' }}>
//       <Autocomplete
//         id="single-select"
//         options={optionList}
//         getOptionLabel={(option) => option[labelKey]}
//         value={selectedItem}
//         onChange={handleOnChange}
//         style={{ width: '100%' }}
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             label={label || 'Select Item'}
//             variant="outlined"
//             InputLabelProps={{ style: {color:"black",fontWeight: 400 } }}
            
//           />
//         )}
//       />
//     </div>
//   );
// };

// export default SingleSelectDropDown;


import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SingleSelectDropDown = ({ optionList, onChange, labelKey, label, value, width, height }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);
 
  const handleOnChange = (event, newValue) => {
    setSelectedItem(newValue);
    onChange(newValue);
  };

  return (
    <div style={{ width: width }}>
      <Autocomplete
        id="single-select"
        options={optionList}
        getOptionLabel={(option) => option[labelKey] || ""}
        value={selectedItem}
        onChange={handleOnChange}
        style={{ width: width, height: height }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || 'Select Item'}
            variant="outlined"
            InputLabelProps={{ style: { color: "black", fontWeight: 400 } }}
            style={{ width: '100%', height: '100%', fontWeight: 900, color: 'black' }}
          />
        )}
      />
    </div>
  );
};

export default SingleSelectDropDown;


import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ShareIcon from '@mui/icons-material/Share';

const RecentActivityCard = ({ username, projectname, time, msg, assetType, assetId, redirectToRfp }) => {
  let icon, text, borderColor, indicationText, indicationTextColor;

  switch (msg) {
    case 'added a comment':
    case 'added a note':
    case 'added a section':
    case 'added a clause':
    case 'sent a reply':
      // case "accepted a task":
      icon = <AddCommentOutlinedIcon style={{ color: 'green', fontSize: 15 }} />;
      indicationTextColor = 'green';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Added'
      break;
    case "approved a changes":
      icon = <AddCommentOutlinedIcon style={{ color: 'green', fontSize: 15 }} />;
      indicationTextColor = 'green';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Approved'
      break;
    case "accepted a task":
      icon = <AddCommentOutlinedIcon style={{ color: 'green', fontSize: 15 }} />;
      indicationTextColor = 'green';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Accepted'
      break;
    case "rejected a task":
    case "rejected a changes":
      icon = <AddCommentOutlinedIcon style={{ color: 'red', fontSize: 15 }} />;
      indicationTextColor = 'red';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Rejected'
      break;
    case "submitted a task for review":
      icon = <AddCommentOutlinedIcon style={{ color: 'green', fontSize: 15 }} />;
      indicationTextColor = 'green';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Submit'
      break;
    case 'deleted a comment':
    case 'deleted the document':
    case 'deleted a section':
    case 'deleted a note':
    case 'deleted a reply':
      icon = <DeleteOutlineOutlinedIcon style={{ color: 'red', fontSize: 15 }} />;
      indicationTextColor = 'red';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Deleted'
      break;
    case 'uploaded the document':
    case 'uploaded the section':
      // case "submitted a task for review":
      icon = <FileUploadOutlinedIcon style={{ color: '#1976D2', fontSize: 15 }} />;
      indicationTextColor = '#1976D2';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Uploaded'
      break;
    case 'updated a comment':
    case 'updated a note':
    case 'updated a section':
    case 'updated a reply':
      icon = <UpdateIcon style={{ color: '#1976D2', fontSize: 15 }} />;
      indicationTextColor = '#1976D2';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Updated'
      break;
    case 'shared a section':

      icon = <ShareIcon style={{ color: '#1976D2', fontSize: 13 }} />;
      indicationTextColor = '#1976D2';
      text = (
        <>
          <span>{username} {msg} in {assetType}</span>
        </>
      );
      indicationText = 'Shared'
      break;
    default:
      break;
  }
  return (
    <div style={{ border: '1px solid #1976D2', borderRadius: '10px', boxShadow: '2px 2px 5px -2px #000000', marginBottom: '14px', height: 110, marginTop: '8px', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ flex: '1', overflow: 'hidden' }} onClick={() => redirectToRfp(assetId, assetType)}>
        <CardContent>
          <div style={{ minHeight: '20px', maxHeight: '40px', overflowX: 'auto' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '13px' }}>
              {projectname}
            </Typography>
          </div>
          <div style={{}}>
            <Typography variant="subtitle1" style={{ fontSize: '13px', marginTop: '5px', marginBottom: "5px" }}>
              {text}
            </Typography>
          </div>
        </CardContent>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px', borderTop: '1px solid #ccc', background: '#f9f9f9', borderRadius: '5px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
          {icon}
          <Typography variant="caption" style={{ color: indicationTextColor, marginLeft: '5px' }}>
            {indicationText}
          </Typography>
        </div>
        <Typography variant="caption" style={{ color: '#1976d2', marginRight: '15px' }}>
          {time}
        </Typography>
      </div>
    </div>

  );
};

RecentActivityCard.propTypes = {
  username: PropTypes.string.isRequired,
  projectname: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired
};

export default RecentActivityCard;

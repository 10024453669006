import React, { useEffect, useState } from "react";
import ChatArea from "../ChatArea/ChatArea";
import ChatNav from "../ChatNav/ChatNav";
import "./Chat.css";

const ChatBox = () => {
  
  return (
    <div className="chat">
      <ChatNav />
      <ChatArea />
    </div>
  );
};

export default ChatBox;

import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputBoxMui from "./InputBoxMui";
import { StageComp } from "./StageComp";
import SelectDropdownMui from "./SelectDropdownMui";

export const TransitionComp = ({ isOpen, toggle }) => {

    const dmmuyList = [
        {
            "value": "In-progress",
            "name": "In-progress"
        },
        {
            "value": "Complete",
            "name": "Complete"
        }]


    const addStage = () => {

    }

    const handleSubmitWorkFlow = () => {

    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                style={{
                    position: "absolute",
                    left: "37%",
                    top: "30%",
                    width: "70%"
                }}
            >
                <ModalHeader
                    toggle={toggle}
                    className="text-center"
                >
                    Workflow Transition
                </ModalHeader>
                <ModalBody>
                    <div
                        className="row p-2"
                    // style={{ textAlign: "center" }}
                    >

                        <div className="col-md-6">
                            <SelectDropdownMui
                                optionsList={dmmuyList}
                                selectLabel={"From Stage"}
                            // onChange={}
                            // value={}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectDropdownMui
                                optionsList={dmmuyList}
                                selectLabel={"From Status"}
                            // onChange={}
                            // value={}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <SelectDropdownMui
                                optionsList={dmmuyList}
                                selectLabel={"To Stage"}
                            // onChange={}
                            // value={}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectDropdownMui
                                optionsList={dmmuyList}
                                selectLabel={"To Status"}
                            // onChange={}
                            // value={}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <SelectDropdownMui
                                optionsList={dmmuyList}
                                selectLabel={"User Roles"}
                            // onChange={}
                            // value={}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-end mt-4">
                        <div className="col-md-3 d-flex justify-content-end">
                            <button className="btn btn-primary"
                                onClick={handleSubmitWorkFlow}
                            >
                                Submit
                            </button>
                        </div>

                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
import React from "react";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Paraphrase from "../Paraphrase/Paraphrase";
import ParaphraseContextProvider from "../../contexts/ParaphraseContext";
import NewParaphrase from "../Paraphrase/NewParaphrase";

const ParaphrasePage = props => {
  return (
    <React.Fragment>
      <SideNavBar>
        <ParaphraseContextProvider>
          <NewParaphrase/>
        </ParaphraseContextProvider>
      </SideNavBar>
    </React.Fragment>
  );
};

export default ParaphrasePage;

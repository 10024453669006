import React, { useState, useEffect } from "react"
import "./ProposalCreation.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@material-ui/core";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PrintIcon from '@mui/icons-material/Print';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DownloadIcon from "@mui/icons-material/Download";
import JoditEditor from "jodit-react";
import UploadIcon from '@mui/icons-material/Upload';
import ReplyIcon from '@mui/icons-material/Reply';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function ProposalCreation() {
    const [isWindowOpen, setIsWindowOpen] = useState(true);
    const [content, setContent] = useState(null);
    const history = useHistory();


    const handleExpandClick = () => {
        setIsWindowOpen(!isWindowOpen);
    };


    return (

        <div className="container-proposal">

            {isWindowOpen ? (
                <div className="LeftSec">
                    <div className="proposal-topBar">


                        <div className="proposal-title">
                            <h2>Proposal Title</h2>
                        </div>


                        <div className="proposal-button">
                            <Button variant="outlined">Add <AddIcon /></Button>
                            <Button variant="outlined">Reorder <ImportExportIcon /></Button>

                            <Button variant="outlined" color="error">Delete <DeleteIcon /></Button>

                        </div>
                    </div>



                    <div className="proposal-section">
                        <div className="proposal-leftHide">
                            <div className="proposal-layers">
                                Layers<ArrowForwardIcon/>
                            </div>

                            <div className="hide-accordion">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography>Section 1</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Section 1 - Description
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="hide-accordion">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography>Section 2</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Section 2 - Description
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>


                            <div className="hide-accordion">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography>Section 3</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Section 3 - Description
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>



                        </div>







                        <div className="proposal-sectionDiv">

                            <div className="accordion-Div">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon onClick={handleExpandClick} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <b>Section-1 Title </b>
                                        <div className="Accordion-Icon">
                                            <div>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><AddIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PlagiarismIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><MailOutlineIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PrintIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><DownloadIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PersonAddIcon /></button>
                                                <button style={{ padding: "6px", color: "red", marginRight: "10px" }}  ><DeleteIcon /></button>

                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Section-1 Description
                                    </AccordionDetails>
                                </Accordion>


                            </div>


                            <div className="accordion-Div">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon onClick={handleExpandClick} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <b>Section-2 Title </b>
                                        <div className="Accordion-Icon">
                                            <div>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><AddIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PlagiarismIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><MailOutlineIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PrintIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><DownloadIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PersonAddIcon /></button>
                                                <button style={{ padding: "6px", color: "red", marginRight: "10px" }}  ><DeleteIcon /></button>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Section-2 Description
                                    </AccordionDetails>
                                </Accordion>


                            </div>


                            <div className="accordion-Div">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon onClick={handleExpandClick} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <b>Section-3 Title </b>
                                        <div className="Accordion-Icon">
                                            <div>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><AddIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PlagiarismIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><MailOutlineIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PrintIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }}  ><DownloadIcon /></button>
                                                <button style={{ padding: "6px", color: "#1976D2", marginRight: "10px" }} ><PersonAddIcon /></button>
                                                <button style={{ padding: "6px", color: "red", marginRight: "10px" }}  ><DeleteIcon /></button>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Section-3 Description
                                    </AccordionDetails>
                                </Accordion>


                            </div>



                        </div>

                    </div>
                    <Button style={{ marginTop: "20px" }} variant="contained" onClick={() => history.goBack()}>Back</Button>


                </div>

            ) : (
                <div className="LeftSec">
                    <div className="proposal-topBar">


                        <div className="proposal-title">
                            <h2>Proposal Title</h2>
                        </div>


                        <div className="editor-button">
                            <Button variant="outlined">Add <AddIcon /></Button>
                            <Button variant="outlined">Add User <ImportExportIcon /></Button>
                            <Button variant="outlined">Upload <UploadIcon /></Button>
                            <Button variant="outlined">Download <DownloadIcon /></Button>
                            <Button variant="outlined">share <ReplyIcon /></Button>

                            <Button variant="outlined" color="error">Delete <DeleteIcon /></Button>

                        </div>
                    </div>
                    <div className="proposal-editorDiv">
                        <JoditEditor
                            value={content}
                            config={{
                                readonly: false, // set to true for read-only mode
                                toolbar: true,
                                branding: false,
                                statusbar: false,

                                height: 570, // set height

                            }}
                            style={{ resize: "none" }}
                        />

                    </div>

                    <Button style={{ marginTop: "0px" }} variant="contained" onClick={() => setIsWindowOpen(true)}>Back</Button>

                </div>
            

            )}

            <div className="RightSec">


                <div style={{ height: "90vh", overflow: "auto", display: "flex", flexDirection: "column", boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.3)", padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}>Activities</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
                    </div>

                    <div style={{ border: '1px solid #1976D2', borderRadius: '5px', marginTop: "10px" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}>Notes</Typography>
                                <Typography>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            fontSize: "12px",
                                            borderRadius: "10px",
                                            color: "#1976D2",
                                            borderColor: "#1976D2",
                                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        Add Note


                                    </Button>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
                    </div>

                    <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}>Version History</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
                    </div>



                    <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}>Comments</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>





        </div>

    )
}
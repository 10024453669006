import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../../contexts/ChatContext";

const useRecorder = () => {
    const { setAudioURL } = useContext(ChatContext);
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [stream, setStream] = useState(null);

    const handleData = (e) => {
        setAudioURL({
            data: e.data,
            type: e,
            name: e.name
        });
    };

    const startRecording = async () => {
        if (!stream) {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setStream(mediaStream);
            const newRecorder = new MediaRecorder(mediaStream, { type: "audio/mp3" });
            newRecorder.addEventListener("dataavailable", handleData);
            setRecorder(newRecorder);
            newRecorder.start();
        }
        setIsRecording(true);
    };

    const stopRecording = () => {
        if (recorder && recorder.state !== "inactive") {
            recorder.stop();
        }
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        setIsRecording(false);
    };

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        };
    }, [stream]);

    return { isRecording, startRecording, stopRecording };
};

export default useRecorder;

import React from 'react';
import './ReorderPopup.css';

const ReorderPopup = ({ message, onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <div className="popup-content">
          <p>{message}</p>
          <button onClick={onClose}>Got it</button>
        </div>
      </div>
    </div>
  );
};
export default ReorderPopup;

import React from 'react';
import TextField from "@material-ui/core/TextField";
// import deletes from "../../../static/delete.png";

export default function InputBoxThree(props) {
    return (
        <div>
            <TextField
              id="outlined-multiline-static"
              style={{ width: "500px" }}
              multiline
              rows={4}
              variant="outlined"
              {...props}
            />
            {/* <img
              src={deletes}
              style={{
                height: "20px",
                width: "15px",
                marginLeft: "8px",
                marginTop: "80px",
              }}
            /> */}
        </div>
    )
}

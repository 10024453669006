import React, { useState, useContext, useEffect } from "react";
import { Input, Button, Tooltip, Modal, message } from "antd";
import Phone from "../assests/phone.gif";
import Teams from "../assests/teams.mp3";
import * as classes from "./Options.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VideoContext from "../../../contexts/VideoContext";
import Hang from "../assests/hang.svg";
import {
    PhoneOutlined,
} from "@ant-design/icons";

const CallModal = () => {

    const {
        call,
        callAccepted,
        name,
        stream,
        setName,
        leaveCall,
        callUser,
        answerCall,
        callEnded,
        me,
        setOtherUser,
        leaveCall1,
        isModalVisible,
        setIsModalVisible,
        audio,
        idToCall,
        setIdToCall,
        callRejected, setCallRejected
    } = useContext(VideoContext);

    useEffect(() => {
        if (isModalVisible) {
            audio.play();
        } else {
            audio.pause();
        }
        // Cleanup function to pause audio when component unmounts
        return () => {
            audio.pause();
        };
    }, [isModalVisible, audio]);

    const showModal = (showVal) => {
        setIsModalVisible(showVal);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        leaveCall1();
    };

    useEffect(() => {
        if (call.isReceivingCall && !callAccepted) {
            setIsModalVisible(true);
            if (call.from) {
                setOtherUser(call.from);
            }
        } else {
            setIsModalVisible(false);
        }
    }, [call.isReceivingCall, callAccepted, call.from, setIsModalVisible, setOtherUser]);



    return (
        <div>
            {call.isReceivingCall && !callAccepted && (
                <>
                    <audio src={Teams} loop autoPlay />
                    <Modal
                        title="Incoming Call"
                        open={isModalVisible}
                        onOk={() => setIsModalVisible(false)}
                        onCancel={handleCancel}
                        footer={null}
                        closable={false}
                    >
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <h1>
                                {call.name} is calling you:{" "}
                                <img
                                    src={Phone}
                                    alt="phone ringing"
                                    className={classes.phone}
                                    style={{ display: "inline-block" }}
                                />
                            </h1>
                        </div>
                        <div className={classes.btnDiv}>
                            <Button
                                variant="contained"
                                className={classes.answer}
                                color="#29bb89"
                                icon={<PhoneOutlined />}
                                onClick={() => {
                                    answerCall();
                                    audio.pause();
                                }}
                                tabIndex="0"
                            >
                                Answer
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.decline}
                                icon={<PhoneOutlined />}
                                onClick={() => {
                                    setIsModalVisible(false);
                                    audio.pause();
                                    setCallRejected(true);
                                    stream.getTracks().forEach((track) => {
                                        track.stop();
                                    });
                                }}
                                tabIndex="0"
                            >
                                Decline
                            </Button>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
};




export default CallModal;

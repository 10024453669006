import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Button, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import bg from "../static/Home-BG.png";
import Group468 from "../static/Group468.png";
import feature1 from "../static/feature1.png";
import feature2 from "../static/feature2.png";
import feature3 from "../static/feature3.png";
import feature4 from "../static/Group 466.png";
import Group463 from "../static/Group463.png";
import Group464 from "../static/Group464.png";
import Group465 from "../static/Group465.png";
import Group409 from "../static/Group 571.png";
import Group570 from "../static/Group570.png";
import facebook from "../static/facebook.png";
import linkedin from "../static/linkedin.png";
import twitter from "../static/twitter.png";
import BaselineBusiness from "../static/BaselineBusiness.png";
import BaselineEmail from "../static/BaselineEmail.png";
import BaselinePhonelink from "../static/BaselinePhonelink.png";
import HomeSubscription from "../components/HomeSubscription";
import "./Home.css";
import ScrollUp from "./ScrollUp";
import subscribedservices from "../services/subscribeusersservices";
import { toast, ToastContainer } from "react-toastify";
import queryString from "query-string";
import isEmptyObject from "../utils/isEmptyObject";
import ScrollAnimation from "react-animate-on-scroll";
import ReadMoreAndLess from "react-read-more-less";
import blueBG from "../static/blue-bg.png";
import { getHomeCMS } from "../services/adminServices";
import { getProducts }  from '../services/productService';
import newfeature1 from "../static/Group 1005.png";
import newfeature2 from "../static/Group 1006.png";
import newfeature3 from "../static/Group 1008.png";
import newfeature4 from "../static/Group 1007.png";
import about1 from "../static/Group 1003.png";
import about2 from "../static/Group 1.png";
import seoServices from "../services/seoServices";
import { paramCase } from "change-case";
import PetLogo from "../static/signup_login/PetLogo.png"
import ContactProLogo from "../static/signup_login/contactPrologo.PNG";
import lappy from "../static/signup_login/lappy.png"
import lappyssss from "../static/mobileresponsiveimg.png"
import lappy_left from "../static/signup_login/blue-bg.png"
import {imgUrl} from "../services/config.json";
import { showToast } from "../messages";


const DefaultSubText = `ContractPro streamlines RFP management with automation, smart content generation, and a robust FAR and DFAR clause library, enhancing collaboration and compliance in a user-friendly platform.
ContractPro is designed to transform how your team manages Requests for Proposals (RFPs) and contract opportunities. With advanced automation, intelligent content generation, and a comprehensive library of FAR and DFAR clauses, ContractPro streamlines your proposal process from start to finish. Empower your team to reduce manual effort, enhance collaboration, and ensure compliance—all within one user-friendly platform.`
class Home extends React.Component {
constructor() {
  super()
  this.state = {
    name: "",
    email: "",
    message: "",
    cms: {},
    products: [],
    windowWidth: window.innerWidth,  
  }
  this.handleResize = this.handleResize.bind(this);
}

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    let cms = await getHomeCMS();
    let products = await getProducts();
    this.setState({ cms: cms.data, 
      // products: products.data.products 
    });
    console.log("cms",cms);
    if (localStorage.getItem("sessiontimeout") === "true") {
      showToast('signup.sessionExpired')
      localStorage.setItem("sessiontimeout", false);
    }
    if (!isEmptyObject(this.props)) {
      const { referedEmail, accountType } = queryString.parse(
        this.props.location.search
      );
      console.log(referedEmail);
      if (
        !localStorage.getItem("referedEmail") ||
        localStorage.getItem("referedEmail") === "undefined"
      ) {
        localStorage.setItem("referedEmail", referedEmail);
      }
      if (
        !localStorage.getItem("accountType") ||
        localStorage.getItem("accountType") === "undefined"
      ) {
        localStorage.setItem("accountType", accountType);
      }
    }
  }

  handleResize = () => {
    // Update state with new window width
    this.setState({ windowWidth: window.innerWidth });
  };

  subscribe = async () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    let response = await subscribedservices.subscribedUsers(data);
    if (response.status !== 200) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }
    this.setState({ name: "", email: "", message: "" }); // clear input field after submission
  };

  onChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(this.state.name);
    // console.log(this.state.email);
    // console.log(this.state.message);
  };

  handleProductBlog = async (category) => {
    const { history } = this.props
    const product = {
      main_category: "products",
      category: category
    }
    const { data, status } = await seoServices.getProductSEOBlog(product);

    if(status === 200){
      history.push({
        pathname: `/${paramCase(data.urlValue)}`,
        state: { detail: data }
      });
    }else {
      console.log('no blog found');
    }
  }

  render() {
  
    let { cms,windowWidth } = this.state;

    const responsive = {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };

    const stagePadding = {
      paddingLeft: 100,
      paddingRight: 100
    };

    const headStyle = {
      fontFamily: "Roboto",
      fontSize: windowWidth < 500 ? "10px" : "20px",
      lineHeight: "1.8",
      paddingTop: "2%",
      marginTop:windowWidth < 500 ? "20px" : ""
    };

    const iconStyle = {
      width: "9%",
      // height: "13%",
      objectFit: "contain",
      marginRight:windowWidth < 500 ? "3%" : "5%",
      marginTop:windowWidth < 500 ? "6%" : "2%",
    };

    const paraStyle = {
      fontFamily: "Roboto",
      fontSize: windowWidth < 500 ? "9px" : "14px",
      lineHeight: "1.47",
      textAlign: "left",
      marginLeft: "15%",
    };

    const ftHeadStyle = {
      fontSize:windowWidth < 500 ? "12px" : "17px",
      color: "#ffffff"
      // marginBottom: "15%"
    };

    const ftListStyle = {
      fontFamily: "Roboto",
      fontSize:windowWidth < 500 ? "10px" : "12px",
      color: "#a1a1a1",
      //  marginTop:"5px"
    };

    const ftIconStyle = {
      width: "2.5rem",
      height: "2.5rem",
      objectFit: "contain",
      marginRight: "3%",
      // marginTop: "55%"
    };

    const fticon = {
      width: "4%",
      height: "5%",
      objectFit: "contain",
    };
    const handleFeatures = (value) => {
      const { history } = this.props
      history.push({
        pathname: "/blog",
        state: { category: value }
      });
    }





 


    return (
      <div style={{ overflowY: "hidden" }}>
      
        {/* Start */}
        <ToastContainer />
        <div>
          {/* Main Background */}
          <div
            className="res-home-bg"
            style={{
              height: "100vh",
              width:windowWidth < 500 ? "100vw" : "100%",
              // border:"5px solid red",
              // border:"3px solid red",
              // overflow:"auto",
              backgroundImage: `url(${lappy_left})`,
              // url(${(cms && cms.Background_left)
                // ? cms.Background_left.Image
                // : `${imgUrl}/pet-website-static-images/blue-bg.png`

                // })`
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
         
           <img
              id="lappy_image"
              src={
                // (cms && cms.Background_right) ? cms.Background_right.Image :
                // `${imgUrl}/pet-website-static-images/lappy.png`
                windowWidth < 500 ? 
                lappyssss : lappy
              }
              alt="lappy"
              className="img-fluid"
              style={{
                position: "absolute",
                // border:"5px solid black",
                zIndex: 1,
                // border:"5px solid red",
                width:windowWidth < 500 ? "50vw" : `${(cms && cms.Background_right) ? `${cms.Background_right.width}vh` : "50%"
                  }`,
                height:windowWidth < 500 ? "70%" : `${(cms && cms.Background_right) ? `${cms.Background_right.height}vh` : "75%"
                  }`,
                marginTop:windowWidth < 500 ? "" : `${(cms && cms.Background_right) ? `${cms.Background_right.margin_top}%` : "1%"
                  }`,
                marginLeft:windowWidth < 500 ? "55%" : `${(cms && cms.Background_right) ? `${cms.Background_right.margin_left}%` : "50%"
                  }`,
                marginRight:windowWidth < 500 ? "" : `${(cms && cms.Background_right) ? `${cms.Background_right.margin_right}%` : "0"
                  }`,
                marginBottom:windowWidth < 500 ? "" : `${(cms && cms.Background_right)
                  ? `${cms.Background_right.margin_bottom}%`
                  : "0"
                  }`,
              }}
            />
         
            {/* NavBar Start */}

            <div className="container p-5 ">
              <Navbar
                expand="lg"
                fixed="top"
                style={{
                  // position: "sticky",
                  // top: "0",
                  backgroundColor: "white",
                  // height:"80px",
                  paddingLeft: "3%",
                  // marginTop: "3%",
                  // backgroundColor:
                  //   window.scrollY > 100 ? "#1976d2" : "transparent"
                }}
              // sticky="top"
              >
                <Navbar.Brand 
                // style={{ color: "blue",height:"100%",width:"30%",display:'flex',alignItems:"center"}} href="#"
                style={{ color: "white" }} href="#"
                >
                  <img
                    className="petLogo"
                    alt="Group570"
                    // style={{ width: "50%", height: "90%" }}
                    style={{ width: "auto", height: "70px" }}
                    // src={
                    //   (cms && cms.Navbar_logo)
                    //     ? cms.Navbar_logo.Image
                    //     : `${imgUrl}/pet-website-static-images/Group+571.png`
                    // }
                    src={ContactProLogo}
                  ></img>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                // className="navbar-toggler navbar-toggler-right"
                />
                {/* justify-content-end */}
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="navLinks" style={{ marginLeft: "32%"}}>
                    <Nav.Link href="#about" style={{color:"#4085f6" }}>
                      About
                    </Nav.Link>
                    <Nav.Link className="ft_md text-#4085f6" href="#features">
                      Features
                    </Nav.Link>
                    <Nav.Link className="ft_md text-#4085f6" href="#pricing">
                      Pricing
                    </Nav.Link>
                    {/* <Nav.Link className="ft_md text-white">
                      <NavLink
                        className="ft_md text-white"
                        to={{ pathname: "/faq" }}
                      >
                        FAQ
                      </NavLink>
                    </Nav.Link> */}
                    <Nav.Link className="ft_md text-#4085f6" href="#contacts">
                      Contact
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white">
                      <NavLink
                        className="ft_md text-#4085f6"
                        to={{ pathname: "/blogpage" }}
                      >
                        Blogs
                      </NavLink>
                    </Nav.Link>
                      <NavDropdown title="Products" id="basic-nav-dropdown">
                        {/* { this.state.products.map(product => (
                          <NavDropdown.Item onClick={() => this.handleProductBlog(product)}>
                            {  (product.charAt(0).toUpperCase() + product.slice(1)).replace(/_/g, ' ') }
                          </NavDropdown.Item>
                        )) } */}
                        <NavDropdown.Item onClick={() => this.handleProductDropdownClick('grammarcheck')}>Grammar Check</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => this.handleProductDropdownClick('citation')}>Citation Generator</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => this.handleProductDropdownClick('paraphrase')}>Paraphraser</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => this.handleProductDropdownClick('plagiarism')}>Plagiarism</NavDropdown.Item>
                      </NavDropdown>
                    <Nav.Link
                      className="loginLink"
                      style={{ color: "white", marginLeft: "10%" }}
                    // href="#"
                    >
                      <NavLink
                        className="text-#4085f6 ft_md"
                        to={{ pathname: "/login" }}
                      >
                        Login
                      </NavLink>
                    </Nav.Link>
                    &nbsp;&nbsp;
                    <Link to={{ pathname: "/signup" }}>
                      <Button
                        className="ft_md"
                        style={{
                          textAlign: "center",
                          color: "#4085f6",
                          borderRadius: "20px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          backgroundColor: "white",
                        }}
                      >
                        SignUp
                      </Button>
                    </Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>

            {/* Navbar Ends */}
            {/* lappy ends */}
            <div className="container ">
              <div className="row mt-0 mt-sm-0 mt-md-5">
                <div className="col-md-6 col-8">
                  <div style={{marginTop:"-30px",marginLeft:"-20px",fontSize:"12px"}}>
                    <p className="res-home-maintitle ft-46 ft-wt-500 text-white mt-20 mb-1 rm" style={{marginTop:"50px"}}>
                  
                      {(cms && cms.Project_title) ? cms.Project_title : "Welcome to ContractPro"}
                      {/* Welcome to Professional Editing Tool */}
                    </p>

                    <div>
                      <input
                        type="checkbox"
                        className="read-more-state"
                        id="post-1"
                      />

                      <p className="read-more-wrap res-home-maintext ft_xl text-white">
                        {(cms && cms.Project_subtext) ? (
                          <>
                            {cms.Project_subtext.slice(0, 363)}&nbsp;
                       <span class="read-more-target">
                              {cms.Project_subtext.slice(363, cms.Project_subtext.length)}
                            </span>
                          </>
                        ) :
                          <>
                            {DefaultSubText.slice(0, 362)}&nbsp;
                        <span class="read-more-target">
                              {DefaultSubText.slice(362, DefaultSubText.length)}.
                        </span>
                          </>
                        }
                      </p>

                      <label for="post-1" class="read-more-trigger"></label>
                    </div>
                    <br />
                    {/* <button className="btn btn-white-outline ft_lg px-4 py-2">
                      Know More
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* lappy ends */}
          </div>
          <div id="about"></div>
          <br />
          <br />
          <br />

          {/* Cards Start */}
          <div className="container">
            <div className="text-center">
              <p className="ft-36 rm">{(cms && cms.About_title) ? cms.About_title : "About ContractPro"}</p>
              <p className="ft_lg text-b">
                {(cms && cms.About_text) ? cms.About_text : `Professional Editing Tool (PET) is our industry-Leading Document
                Editing and Team Collaboration Solution that was built to help
                improve, integrate and govern document editing and team
                collaboration. Our Mission at PET is to provide industry with
                full featured multifunctional tools and services to meet each
                client's specific document editing needs. At PET, we understand
                the importance effective writing and clarity of ideas. For this
                reason, Your Professional Editing Tool (PET) was developed to
                provide professional editing and team collaboration services to
                all business professionals, students, educators, and writers.
                Simply put, we had the writers in mind!`
                }

              </p>
              <br />
              <br />
            </div>

            <div style={{width:"100%"}}>
              <div className="row text-center">
              {(cms && cms.About_Cards) ? (
                  <>
                    <AliceCarousel
                      duration={400}
                      autoPlay={true}
                      startIndex={0}
                      fadeOutAnimation={true}
                      mouseDragEnabled={true}
                      responsive={responsive}
                      autoPlayInterval={2000}
                      autoPlayDirection="ltr"
                      buttonsDisabled={true}
                      // autoPlayActionDisabled={true}
                      // disableAutoPlayOnAction={true}
                      // stagePadding={stagePadding}
                      infinite={true}
                    >
                      {cms.About_Cards.map((card) => (
                        <div
                          className="card text-center mx-sm-4"
                          style={{ height: "65vh" }}
                        >
                          <div className="card-body my-3">
                            <div>
                              <img
                                className="img-fluid"
                                src={card.Card_image == "about1" ? about1 : card.Card_image == "about2" ? about2 : card.Card_image == "feature3" ? feature3 : ""}
                                alt=""
                                style={{ height: "12vh" }}
                              ></img>
                            </div>
                            <br />
                            <p className="ft-22 mb-3 mt-1">{card.Card_title}</p>
                            <p
                              className="card-text ft_md p-2"
                              style={{ height: "35vh", overflowY: "auto" }}
                            >
                              {card.Card_text}
                            </p>
                          </div>
                        </div>
                      ))}
                    </AliceCarousel>
                  </>
                ) : (
                    <>
                      <div className="col-md-4 text-center">
                        <ScrollAnimation animateIn="slideInLeft">
                          <div className="card shadow" style={{ width: "100%" }}>
                            <div className="card-body my-3">
                              <div>
                                <img className="img-fluid" src={about1} alt=""></img>
                              </div>
                              <br />
                              <p className="ft-22 mb-2">24 / 7 Support</p>
                              <p
                                className="card-text ft_md p-2"
                                style={{ height: "35vh", overflowY: "auto" }}
                              >
                                Since we want each individual person and organization
                                to succeed in their Editing Needs, ContractPro offers 24/7
                                technical support day or night. Our 24/7 support
                                seamlessly transitions between getting support by
                                virtual house calls, phone, chat, and/or DIY guides.
                                Our Technical Support Professionals speak in everyday
                                language and have the experience, know-how, and tools
                                to solve your editing issues as quickly as possible.
                          </p>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>

                      <div className="col-md-4 text-center">
                        <ScrollAnimation animateIn="slideInDown">
                          <div className="card shadow" style={{ width: "100%" }}>
                            <div className="card-body my-3">
                              <div>
                                <img className="img-fluid" src={about2} alt=""></img>
                              </div>
                              <br />
                              <p className="ft-22 mb-2">Data Privacy</p>
                              <p
                                className="card-text ft_md p-2"
                                style={{ height: "35vh", overflowY: "auto" }}
                              >
                                At ContractPro, we act to protect data privacy online and
                                improve personal data protection. We understand that
                                data privacy protection concerns are driving new
                                regulations around the world, and so we go beyond just
                                complying with all the new requirements, but we ensure
                                that we build trust with consumers and users and stand
                                out from their competitors.
                          </p>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-4 text-center">
                        <ScrollAnimation animateIn="slideInRight">
                          <div className="card shadow" style={{ width: "100%" }}>
                            <div className="card-body my-3">
                              <div>
                                <img className="img-fluid" src={feature3} alt=""></img>
                              </div>
                              <br />
                              <p className="ft-22 mb-2">Easy Write</p>
                              <p
                                className="card-text ft_md p-2"
                                style={{ height: "35vh", overflowY: "auto" }}
                              >
                                Your ContractPro document editing and team collaboration makes
                                your writing easy. Your ContractPro’s AI Algorithm know the
                                English language inside out and will analyze your
                                written English using over 160 English grammar rules.
                                PET makes your writing easy by not just pointing out
                                mistakes, but by actually fixing it for you, saving
                                you the major headache and confusion of deciphering
                                complex English rules. PET also provide the ability to
                                collaborate live with other writers, which allow
                                others to see what you missed and to ensure you are
                                not alone.
                          </p>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </>
                  )}

              </div>
            </div>
          </div>
          <div id="features"></div>
          <br />
          <br />
          <br />
          {/* Card End */}

          {/* Feature */}
          <div
            id="features"
            className="features text-white"
            style={{ backgroundColor: "#3b81f3" }}
          >
            <br /> <br />
            <div className="text-center text-white mx-4">
              <p className="ft-36 mb-0 ft-wt-500">Features</p>
              <br />
            </div>
            <div
              style={{
                paddingRight: "10%",
                paddingTop: "4%",
                paddingBottom: "6%",
                // border:"3px solid red"
              }}
            >
              <div className="row">
               {windowWidth < 500 ? <>
               
               </> : <>
               <div
                  className="col-md-6"
                  style={{
                    height: `${(cms && cms.Feature_image) ? cms.Feature_image.height : "50"
                      }vh`,
                    width: `${(cms && cms.Feature_image) ? cms.Feature_image.width : "100"
                      }vw`,
                      // border:"3px solid black",
                      backgroundImage: `url(${Group468})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    
                  }}
                ></div>
               </>}
                <div className="col-md-6" id="featureId">
                  {(cms && cms.Features) ? (
                    <>
                      {cms?.Features?.map((feature) => (
                        <div className="row">
                         <img
  alt="Group463"
  src={
    feature?.image === "newfeature1"
      ? newfeature1
      : feature?.image === "newfeature2"
      ? newfeature2
      : feature?.image === "newfeature3"
      ? newfeature3
      : feature?.image === "newfeature4"
      ? newfeature4
      : ""
  }
  style={iconStyle}
/>

                          <h6 style={headStyle}>{feature.title}</h6>
                          <p style={paraStyle}>{feature.text}</p>
                        </div>
                      ))}
                    </>
                  ) : (
                      <>
                        <div className="row">
                          <img alt="Group463" src={newfeature1} style={iconStyle}></img>
                          <h6 style={headStyle}>Intelligent RFP and Proposal Management</h6>
                          <p style={paraStyle}>
                          Effortlessly manage RFPs and proposals with automated workflows that allow you to add opportunities, assign tasks, review content, and make data-driven decisions from sector selection to final submission.
                    </p>
                        </div>

                        <div className="row">
                          <img alt="Group464" src={newfeature2} style={iconStyle}></img>
                          <h6 style={headStyle}>Automated Content Generation</h6>
                          <p style={paraStyle}>
                          Generate accurate, persuasive content for proposals with AI-powered tools that leverage historical data and templates, reducing the time and effort needed while ensuring high-quality results.
                    </p>
                        </div>

                        <div className="row">
                          <img alt="Group465" src={newfeature3} style={iconStyle}></img>
                          <h6 style={headStyle}>Comprehensive Clause Library for Compliance</h6>
                          <p style={paraStyle}>
                          Ensure compliance with government and industry regulations using an extensive library of FAR and DFAR clauses that can be easily searched, selected, and inserted into documents, keeping you up-to-date without manual updates.
                    </p>
                        </div>

                        <div className="row">
                          <img alt="Group465" src={newfeature4} style={iconStyle}></img>
                          <h6 style={headStyle}>Collaborative Workflow and Task Management</h6>
                          <p style={paraStyle}>
                          Enhance teamwork and streamline the proposal process with robust task management features that allow for real-time monitoring, feedback, and a structured multi-tiered review, ensuring quality submissions even under tight deadlines.
                    </p>
                        </div>
                      </>
                    )}

                </div>
              </div>
            </div>
          </div>
          <div id="pricing"></div>
          <br />
          <br />

          {/* Features End */}

          {/* Pricing */}
          <div className="container">
            <h2 className="text-center">Pricing</h2>

            <HomeSubscription hide={true} />
          </div>
          <div className="text-center">
            <br />

            <div id="contacts"></div>
          </div>
          <br />
          <br />
          {/* <Subscriptions /> */}
          {/* Pricing Ends */}

          {/* Newsletter */}
          <div
            className="container-fluid text-center py-5"
            style={{ backgroundColor: "#E5E7E9" }}
          >
            <div className="text-g mx-3">
              <p className="ft-36 mb-2">
                {" "}
                Subcribe to know about offers and features!
              </p>
            </div>
            <div>
              <br />
              <br />
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  <input
                    id="newsInput"
                    className="border-0 w-75 p-3"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeData}
                    required
                  ></input>
                  <br />
                  <br />
                  <input
                    id="newsInput"
                    className="border-0 w-75 p-3"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeData}
                    required
                  ></input>
                  <br />
                  <br />
                  <textarea
                    id="newsInput"
                    rows="4"
                    className="border-0 w-75 p-3"
                    placeholder="Your Message"
                    name="message"
                    value={this.state.message}
                    onChange={this.onChangeData}
                    required
                  ></textarea>
                  {/* <input
                    id="newsInput"
                    className="border-0 w-75"
                    placeholder="Your Message"
                    name="message"
                    value={this.state.message}
                    onChange={this.onChangeData}
                    required
                  ></input> */}
                  <br />
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary px-5"
                    id="msgBtn"
                    onClick={this.subscribe}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Newsletter ends */}

          {/* End */}
        </div>

        {/* Footer */}

        <div
          // className="footer"
          // id="footer"
          style={{
            width:"100%",
            backgroundColor: "#212121",
            overflowX: "hidden",
            // border:"5px solid red"
          }}
        >
          <div
            // className="row ftRow"
            style={{
              width:"100%",
              display:"flex",
              flexDirection:windowWidth < 768 ? "column" : "row",
              justifyContent:"space-between",
              padding:windowWidth < 500 ? "30px" : "2%"
              // border:"5px solid skyblue"
            }}
          >
            <div 
            // className="col-md-5"
             style={{
              // border:"3px solid purple",
             width:windowWidth < 768 ? "100%" : "30%",padding:"1%"}}>
              <div>
                
                <img
                  className="ftLogo mb-4"
                  alt="Group409"
                  src={
                    (cms && cms.Navbar_logo)
                      ? ContactProLogo
                      : ContactProLogo
                  }
                  style={{
                    width: "45%",
                    height: "20%",
                    objectFit: "contain",
                  }}
                ></img>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselineBusiness"
                    src={BaselineBusiness}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize:windowWidth < 500 ? "10px" : "13px" }}
                  >
                    {(cms && cms.Footer_Details)
                      ? cms.Footer_Details.Address
                      : "22738, Maple Road Suite 120, Lexington Park, Maryland, 20634"}
                    {/* 22738, Maple Road Suite 120, Lexington Park, Maryland, 20634 */}
                  </text>
                </p>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselinePhonelink"
                    src={BaselinePhonelink}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize:windowWidth < 500 ? "10px" : "13px" }}
                  >
                    {(cms && cms.Footer_Details)
                      ? cms.Footer_Details.Phone_no
                      : "301-997-5695 "}
                    {/* 301-997-5695 */}
                  </text>
                </p>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselineEmail"
                    src={BaselineEmail}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize:windowWidth < 500 ? "10px" : "13px" }}
                  >
                    {(cms && cms.Footer_Details)
                      ? cms.Footer_Details.Email
                      : "admin@professionaleditingtool.com"}
                    {/* admin@professionaleditingtool.com */}
                  </text>
                </p>
              </div>
            </div>

            <div 
            // className="col-md-4"
             style={{
              // border:"3px solid yellow",
              width:windowWidth < 768 ? "100%" : "38%",padding:"1%",marginTop:windowWidth < 768 ? "20px" : "" }}>
           

              
                <p style={{display:"flex",justifyContent:"space-around", width:"100%"}}>
                  <div style={{display:"flex",flexDirection:"column",width:"31%",height:"90%",
                    //  border:"1px solid red"
                     }}>
                  <text 
                 
                 style={ftHeadStyle}>
                  Company
                </text>
                <a href="#about" style={{
                       fontFamily: "Roboto",
                       fontSize:windowWidth < 500 ? "10px" : "12px",
                       color: "#a1a1a1",
                       textAlign:"left",
                       marginTop:"16px"
                  }}>
                    About Us
                  </a>
                  <Link to="/privacypolicy" style={ftListStyle}>
                    Privacy Policy
                  </Link>
               
                  </div>
                  <div style={{display:"flex",flexDirection:"column",width:"31%",height:"90%",
                    // border:"1px solid red"
                    }}>
                  <text style={{fontSize:windowWidth < 500 ? "12px" : "17px", color: "#ffffff"}}>Legal</text>
                  <Link to="/faq" style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" : "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    marginTop:"16px"
                   
                  }}>
                    FAQ
                  </Link>
                  <Link to="/termsofservice" style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" : "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    cursor: 'pointer'
                  }}>
                    Terms of Service
                  </Link>
                  </div>
                  <div style={{display:"flex",flexDirection:"column",width:"31%",height:"90%",
                    // border:"1px solid red"
                    }}> 

                  <text style={{ fontSize:windowWidth < 500 ? "12px" : "17px", color: "#ffffff" }}>Features</text>
                  <text onClick={() => handleFeatures('grammar_checker')} style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" : "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    cursor: 'pointer',
                    marginTop:"15px"
                  }}>
                    Opportunity Management
                  </text>
                  <text onClick={() => handleFeatures('citation')} style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" : "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    cursor: 'pointer'

                  }}>
                    FAR/DFAR library
                  </text>
                  <text onClick={() => handleFeatures('paraphrase')} style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" :  "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    cursor: 'pointer'
                  }}>
                    AI RFP Creation
                  </text>
                  <text onClick={() => handleFeatures('plagiarism')} style={{
                    fontFamily: "Roboto",
                    fontSize:windowWidth < 500 ? "10px" : "12px",
                    textAlign: "left",
                    color: "#a1a1a1",
                    cursor: 'pointer',
                  }}>
                    AI Driven Proposal Drafting
                  </text>
                  </div>

                </p>

             
              
            </div>

            <div 
            // className="col-md-3 text-center" 
            style={{
              // border:"3px solid pink",
            width:windowWidth < 768 ? "100%" : "20%",padding:"1%",marginTop:windowWidth < 768 ? "20px" : ""}}>
           
              <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",alignContent:"center"}}>
                <ScrollUp/>
                <div
                style={{
                  // border:"3px solid blue",
                  display:"flex",justifyContent:"space-around",marginTop:"30px",width:"60%"}}
                >
                  <div>
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={(cms && cms.Footer_Links) ? cms.Footer_Links.Facebook : ""}
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="facebook"
                        src={facebook}
                        style={ftIconStyle}
                      ></img>
                    </a>
                  </div>
                  <div>
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={(cms && cms.Footer_Links) ? cms.Footer_Links.Linkedin : ""}
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="linkedin"
                        src={linkedin}
                        style={ftIconStyle}
                      ></img>
                    </a>
                  </div>
                  <div style={{
                    // border:"5px solid red",
                    backgroundColor:"white",
                          borderRadius:"22px"
                  }}>
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={
                        (cms && cms.Footer_Links)
                          ? cms.Footer_Links.Twitter
                          : "https://www.facebook.com/"
                      }
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="twitter"
                        src={twitter}
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          objectFit: "contain",
                          marginRight: "3%"
                        }}
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              opacity: "0.35",
              backgroundColor: "#757575",
              paddingTop: "1%",
              paddingBottom: "1%",
            }}
          >
            <div className="col-md-12 text-center">
              <p
                style={{
                  fontFamily: "Roboto",
                  fontSize:windowWidth < 500 ? "10px" : "14px",
                  fontWeight: "300",
                  // lineHeight: "2.99",
                  color: "#ffffff",
                }}
              >
                Copyright © Professional Editing Tool Inc. All Rights Reserved{" "}
                {/* {`${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`} */}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;

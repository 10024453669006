

export const limitList=[
    {
        "value": "25",
        "name": "25"
    },
    {
       "value": "50",
       "name": "50"
    },
    {
       "value": "100",
       "name": "100"
    }
]
import { apiUrl, herokuUrl } from "./config.json";
import axios from "axios";
import { toast } from "react-toastify";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
    apiEndpoint = apiUrl + "/imageUpload";
} else {
    apiEndpoint = herokuUrl + "/imageUpload";
}

axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

axios.interceptors.response.use(null, error => {
    console.log(error);
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        console.log(error);
        toast.error("An unexpected error occurrred.");
    }

    return Promise.reject(error);
});


async function UploadImageToS3(file, key) {
    console.log(file);
    const uploadConfig = await axios.get(`${apiEndpoint}/get-signed-url?file-name=${file.name}&file-type=${file.type}&prev-file=${key}`, {
        headers: {
            "x-access-token": sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token"),
        }
    });
    await axios.put(uploadConfig.data.url, file, {
        headers: {
            'Content-Type': file.type,
        }
    });
    console.log(uploadConfig);
    return uploadConfig;
}

async function DeleteImageFromS3(key) {
    let data = await axios.post(`${apiEndpoint}/deleteS3Images`, { key });
    return data;
}

export default {
    UploadImageToS3,
    DeleteImageFromS3
}
import React from 'react';
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import UserRoles from '../UserRoles';

const UserRolesPage = () => {
  return (
    <div>
        <React.Fragment>
    <SideNavBar>
       <UserRoles />
    </SideNavBar>
  </React.Fragment>
    </div>
  );
}

export default NetworkDetector(UserRolesPage);

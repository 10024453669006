import React from 'react';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search';  
const label = { inputProps: { 'aria-label': 'Switch demo' } };



export const CoAuthor_Searchcomponent = () => {
    const [isAskActive, setIsAskActive] = useState(true);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [activeButton, setActiveButton] = useState("ask");
  
    // const handleCssClick = (button) => {
    //   setActiveButton(button === activeButton ? '' : button);
    // };
  
    const handleCssClick = (button) => {
      if (button === 'ask') {
        if (isAskActive === true) {
          setIsAskActive((prev) => prev);
        } else {
          setIsAskActive((prev) => !prev);
        }
        setIsSearchActive(false); // Reset Search
        setActiveButton(button)
      } else if (button === 'search') {
        if (isSearchActive === true) {
          setIsSearchActive((prev) => prev);
        } else {
          setIsSearchActive((prev) => !prev);
        }
        setIsAskActive(false); // Reset Ask
        setActiveButton(button)
      }
    };
  
  
  
    return (
      <>
  
        <div>
          {/* Content to be displayed when 'Research' is clicked */}
          <div className="button-container">
            <button
              className={activeButton === 'ask' ? 'active' : ''}
              onClick={() => handleCssClick('ask')}
            >
              Ask a question
            </button>
            <button
              className={activeButton === 'search' ? 'active' : ''}
              onClick={() => handleCssClick('search')}
            >
              Search
            </button>
          </div>
  
          {isAskActive &&
            <div>
              <div className='Switch_css'>
                <div>Academic</div><div><Switch {...label} defaultChecked /></div>
              </div>
  
              <div className='Input_div' style={{ position: "relative" }}>
                <input
                  style={{
                    margin: "auto",
                    width: '406px',
                    padding: '10px 25px 10px 5px',
                    paddingRight: '25px',
                    boxSizing: 'border-box',
                  }}
                  placeholder='Ask Your question '
                />
                <div style={{ position: "absolute", top: "11px", right: "15px", width: "18px", height: "18px", fontSize: "10px", backgroundColor: "#1976D2", color: "white", borderRadius: "10px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ArrowForwardIcon /></div>
              </div>
  
              <div className='Suggetion_div'>
                <div style={{ fontWeight: "500" }}>
                  Suggestions
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <CachedIcon />
                </div>
              </div>
  
              <div className='Service_bottom'>
                <div className='Suggestion_tag_div'><p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon /></div>
                <div className='Suggestion_tag_div'><p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon /></div>
                <div className='Suggestion_tag_div'><p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon /></div>
                <div className='Suggestion_tag_div'><p>What factors contribute to employee satisfactions?</p> <ArrowForwardIosIcon /></div>
              </div>
  
        
            </div>
          }
  
  
          {isSearchActive &&
  
            <div>
              <div className='Input_div' style={{ marginTop: "40px", position: 'relative' }}>
                <input
                  style={{
                    margin: "auto",
                    width: '406px',
                    padding: '10px 25px 10px 5px',
                    paddingRight: '25px',
                    boxSizing: 'border-box',
                  }}
                  placeholder='Search anything or paste the url  '
                />
                <div style={{ position: "absolute", top: "10px", left: "6px", fontSize: "8px" }}>
                  <SearchIcon />
                </div>
  
                <div style={{ position: "absolute", top: "10px", right: "15px", fontSize: "8px" }}>
                  <MicIcon />
                </div>
  
  
  
  
              </div>
  
              <div style={{ width: "90%", margin: "auto", marginTop: "10px", display: 'flex', justifyContent: 'center' }}>
                <button style={{ width: "120px", backgroundColor: "white", color: "#1976D2", border: "1px solid #1976D2", borderRadius: "12px" }}>Search</button>
              </div>
            </div>
          }
  
  
          </div>    
        </>
      )
    }
  


import {
  apiUrl,
  herokuUrl
} from "./config.json";


import httpService from "./httpService.js";

let apiEndpoint = "";

if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/TaskAssignmentData`;

} else {
  apiEndpoint = `${apiUrl}/TaskAssignmentData`;

}


export async function addTaskAssignmentData(data) {
  const response = await httpService.post(`${apiEndpoint}/task-assignments-data`, {
    data
  });
  return response;
}

export async function getTaskAssignedDetails(data) {
  const response = await httpService.post(`${apiEndpoint}/getTaskAssignedDetails`, {
    data
  });
  return response;
}
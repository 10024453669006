import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/assetrequest`;
   
  } else {
    apiEndpoint = `${apiUrl}/assetrequest`;
   
  }
 

  export async function sendAssetRequest(data){
    const response=await httpService.post(`${apiEndpoint}/sendRequestForAssetPerformance`,{data});
    return response;
  }

  export async function getAssetRequestStatus(data){
    const response = await httpService.post(`${apiEndpoint}/getAssetRequestStatus`,{data})
    return response;
  }
  

  export async function assetRequestedList(data){
    const response = await httpService.post(`${apiEndpoint}/getAssetRequestedList`,{data});
    return response;
  }
 

  export async function assetRequestedListbyAssetid(data){
    const response = await httpService.post(`${apiEndpoint}/getAssetRequestedListbyAssetid`,{data});
    return response;
  }

  

  export async function approvalForAssetPerformance(data){
    const response = await httpService.post(`${apiEndpoint}/approvalForAssetPerformance`,{data});
    return response;
  }
// import React from 'react';
// import styled from '@emotion/styled';
// import PropTypes from 'prop-types';
// import { Card as MuiCard, CardContent, Typography, Box, IconButton } from '@mui/material';
// import PreviewIcon from '@mui/icons-material/Preview';
// import AddIcon from "@mui/icons-material/Add";
// const CardContainer = styled(MuiCard)`
//   width: 200px;   // Adjust width as needed
//   height: 200px;  // Adjust height as needed to make it square
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
//   background-color: white;
//   margin: 10px;
// `;

// const CardTitle = styled(Typography)`
//   font-size: 1.5em;
//   color: #333;
//   text-align: center;
// `;

// const IconContainer = styled(Box)`
//   display: flex;
//   justify-content: center;
//   padding: 10px;
// `;

// const TemplateCard = ({ title, preview,coverClick }) => (
//   <CardContainer>
//     <CardContent >
//       <CardTitle variant="h2">{title}</CardTitle>
//     </CardContent>
//     <IconContainer>
//       <IconButton onClick={preview}>
//          <PreviewIcon fontSize="large" />
//       </IconButton>
//       <IconButton onClick={coverClick}>
//          <AddIcon fontSize="large" />
//       </IconButton>
//     </IconContainer>
//   </CardContainer>
// );

// TemplateCard.propTypes = {
//   title: PropTypes.string,
// };

// export default TemplateCard;

import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from "@mui/icons-material/Add";

import {
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const CardContainer = styled(MuiCard)`
  width: ${({ dynamicWidth }) => dynamicWidth || '180px'} !important;  // Ensuring width is applied
  // height: 200px !important;  // Ensuring height is applied
  height:${({ view }) => (view ? '50px' : '200px')};
  display:  ${({ view }) => (view ? 'flex' : '')};
  flex-direction:row
  justify-content: space-between;
  background-color: white !important;  // Ensure background color is applied
  margin: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 16px !important, rgba(0, 0, 0, 0.2) 0px 4px 8px !important, rgba(0, 0, 0, 0.15) 0px 3px 6px !important;  // Stronger shadow on hover
  border: 1px solid #ccc !important;  // Border changes on hover
  border-radius: 8px !important;  // Border-radius
  transition: box-shadow 0.3s ease, border 0.3s ease !important;

  // &:hover {
    
  // }
`;

const CardTitle = styled(Typography)`
  font-size: ${({ dynamicFontSize }) => dynamicFontSize || '1.5em'} !important;
  color:rgb(61, 131, 250) !important;
  text-align: center;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  overflow: hidden !important;
  padding-top:${({ view }) => (view ? '' : '20px')};
`;

const CardContent1 = styled(Box)`
  height: ${({ view }) => (view ? '100%' : '80%')};
width: ${({ view }) => (view ? '60%' : '100%')};
display:${({ view }) => (view ? 'flex' : '')};
justify-content:${({ view }) => (view ? 'center' : '')};
align-items:${({ view }) => (view ? 'center' : '')};
`;

const IconContainer = styled(Box)`
  display: flex !important;
  justify-content: center !important;
  padding: 10px !important;
  border-top:${({ view }) => (view ? '' : '1px solid #ccc')};
  height: ${({ view }) => (view ? '100%' : '20%')};
  width: ${({ view }) => (view ? '40%' : '100%')};
`;

const SingleTemplate = ({
  type,
  view,
  title,
  preview,
  AddClick,
  editClick,
  checkBox,
  selectedTemp,
  dynamicWidth,
  dynamicFontSize,
}) => (
  <CardContainer dynamicWidth={dynamicWidth} view={view}>
    <CardContent1 view={view}>
      <CardTitle
      view={view}
        variant="h2"
        dynamicFontSize={dynamicFontSize}
        style={{ color: "rgb(61, 131, 250)" }}
      >
        {title.split('-').slice(-1)[0].replace('.html', '')}
      </CardTitle>
    </CardContent1>
    <IconContainer view={view}>
      <FormControlLabel
        control={
          <Checkbox checked={title === selectedTemp} onChange={checkBox} />
        }
        style={{ margin: "0px" }}
      />
      <IconButton onClick={preview}>
        <VisibilityIcon fontSize="medium" style={{color:"rgb(61, 131, 250)"}} />
      </IconButton>
      {type=="Template" ? <>
        <IconButton onClick={editClick}>
        <EditIcon fontSize="medium" style={{color:"green"}} />
      </IconButton>
      </>:<>
      <IconButton>
        <AddIcon fontSize="medium" onClick={AddClick} style={{color:"green"}} />
      </IconButton>
      </>}
      
     
    </IconContainer>
  </CardContainer>
);

SingleTemplate.propTypes = {
  title: PropTypes.string,
};

export default SingleTemplate;

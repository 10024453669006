import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/extractClauses";
} else {
  apiEndpoint = herokuUrl + "/extractClauses";
}


export async function getResourceLinks(data) {
    let response = await httpService.post(`${apiEndpoint}/extractClause`,{data});
    return response;
  }


export async function getClauses(data) {
  let response = await httpService.post(`${apiEndpoint}/getExtractedClauses`,{data});
  return response;
}
export async function addClauses(data) {
  let response = await httpService.post(`${apiEndpoint}/addClauses`,{data});
  return response;
}

export async function deleteClause(data) {
  let response = await httpService.post(`${apiEndpoint}/deleteClauses`,{data});
  return response;
}


export async function addClauseProject(data) {
  let response = await httpService.post(`${apiEndpoint}/addClauseProject`,{data});
  return response;
}
export async function getClauseProject(data) {
  let response = await httpService.post(`${apiEndpoint}/getClauseProject`,{data});
  return response;
}

export async function updateClauseProject(data) {
  let response = await httpService.post(`${apiEndpoint}/updateClauseProject`,{data});
  return response;
}

export async function approveClause(data){
  let response = await httpService.post(`${apiEndpoint}/approveClause`,{data});
  return response;
}

export async function rejectClause(data){
  let response = await httpService.post(`${apiEndpoint}/rejectClause`,{data});
  return response;
}

export async function getDeletedClause(data){
  let response = await httpService.post(`${apiEndpoint}/getDeletedClause`,{data});
  return response;
}
import React, { Component } from "react";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { ToastContainer } from "react-toastify";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import { getTermsOfServiceCMS } from "../services/adminServices";
import { getHomeCMS } from "../services/adminServices";
import {imgUrl} from "../services/config.json";

export default class TermsofService extends Component {
  state = {
    tosData: [],
    cms: {},
  };

  async componentDidMount() {
    this.getTos();
    let cms = await getHomeCMS();
    this.setState({ cms: cms.data });
  }

  getTos = async () => {
    let tosdata = await getTermsOfServiceCMS();
    this.setState({ tosData: tosdata.data });
    console.log(this.state.tosData);
  };

  render() {
    const { tosData, cms } = this.state;
    return (
      <div>
        <ToastContainer />
        <div className="container" style={{ width: "100%" }}>
          <div
            className="row d-inline-flex p-3 text-white"
            style={{ width: "100%" }}
          >
            {/* Flex One */}
            <div
              className="col-md-6 p-4 border"
              style={{
                // backgroundColor: "#3b81f3",
                width: "100%",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "94vh"
              }}
            >
              {/* Flex One Content */}
              <div
                style={{
                  paddingLeft: "19.8%",
                  paddingRight: "14.6%",
                  paddingTop: "16%",
                  
                }}
              >
                <div className="row">
                  <div>
                    {/* <h3 className="text-center" style={headOneStyle}>
                    PROFESSIONAL
                  </h3>
                  <h3 style={headTwoStyle}>EDITING TOOL</h3> */}

                    <Link to="/">
                      <img
                        className="petLogo"
                        alt="Group570"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          cms && cms.Navbar_logo
                            ? cms.Navbar_logo.Image
                            : `${imgUrl}/pet-website-static-images/Group+571.png`
                        }
                      ></img>
                    </Link>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20%" }}>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "30px",
                      fontWeight: "500",
                      lineHeight: "1.35",
                      textAlign: "left",
                      color: "#ffffff",
                    }}
                  >
                    {cms && cms.Project_title
                      ? cms.Project_title
                      : "Welcome to Professional Editing Tool"}
                  </p>
                </div>

                <div className="row" style={{ marginTop: "7%" }}>
                  <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                    {cms && cms.Project_subtext
                      ? cms.Project_subtext
                      : `PET is the industry-leading document editing and team
                        collaboration software for Businesses, Educators,
                        Students and Researchers. PET, integration with other
                        apps and services, with the power of AI brings all the
                        state-of-the-art, extensive features which are available
                        in multiple writing tools. &nbsp;
                          With PET, you can create and edit new projects from
                          any device. Develop a writing education that meets
                          students’ and professionals’ contemporary writing
                          needs.`}
                  </p>
                </div>
              </div>
            </div>

            {/* Flex Two */}

            <div className="col-md-6 p-4 border" style={{ width: "100%" , height:"94vh" }}>
              {/* Flex Two Content */}

              <div
                style={{
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  paddingTop: "17%",
                  paddingBottom: "4%",
                }}
              >
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3
                      style={{
                        fontSize: "35px",
                        fontWeight: "bold",
                        lineHeight: "1.26",
                        color: "#43425d",
                      }}
                    >
                      Terms of Service
                    </h3>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10%"}}>
                  {(tosData && tosData).map((tos, index) => (
                    <div className="col-md-12">
                      <p
                        style={{
                          // opacity: "0.5",
                          fontSize: "15px",
                          lineHeight: "1.72",
                          textAlign: "justify",
                          color: "black",
                          padding: "5%",
                        }}
                      >
                        {cms && tosData ? (
                          <>
                          <b>{tos.tos_title}:</b>
                        <br />
                        <br />
                        <ul style={{ paddingLeft: "10%" }}>
                          {tos.tos_subtext.split("\n").map((sub) => (
                            <li>{sub}</li>
                          ))}
                        </ul>
                        <br />
                          </>
                        ) :( <>
                          <b>
                            Language Preferences:
                          </b>
                          <br />
                          <br />
                          <ul style={{ paddingLeft: "10%" }}>
                          
                            <li>American English</li>
                            <li>British English</li>
                            <li>Canadian English</li>
                            <li>Australian English</li>
                          
                        </ul>
                        <br />
                        <b>Needed Feature:</b>
                        <br />
                          <br />
                          <ul style={{ paddingLeft: "10%" }}>
                          
                            <li>MS Office Plug-in </li>
                            <li>Chrome Extension</li>
                            
                        </ul>
                        </>
                        )}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="text-center">
                  <Link to="/">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        width: "50%",
                        marginTop: "10%",
                      }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { combineReducers } from 'redux';


import userReducers from './user/user.reducer';
import documentReducer from './document/document.reducer';
import featureReducer from './features/features.redcuer';
import rfpManagmentReducer from './opportunity/opportunity.reducer';
import sidenavbarReducer from './sidenavbar/sidenavbar.reducer';
import templateReducer from './template/template.reducer';
import setOpportunityData from './manageOpportunity/manageOpportunity.reducer';

export default combineReducers({
    user : userReducers,
    document : documentReducer,
    features : featureReducer,
    rfp:rfpManagmentReducer,
    sideNavbar:sidenavbarReducer,
    template:templateReducer,
    opportunity: setOpportunityData,
})
import React from 'react';
import OppourtunityManagement from "../OppourtunityManagement"
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SubAdminSideBar from '../SubAdminSideBar';
import ProposalWriting from '../ProposalWriting';
const ProposalWritingPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <ProposalWriting />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(ProposalWritingPage);



import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer } from 'recharts';
 
const SimpleBarChart = ({
  data,
  xKey,
  barKey,
  xAxisLabel,
  yAxisLabel,
  barColor,
  tooltipFormatter,
  boxWidth,
  boxHeight,
  chartHeading
}) => {
  const angle = data.length > 5 ? -25 : 0;
 
  return (
    <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          border: '1px solid #ccc',
          boxShadow: "2px 2px 5px -2px #000000",
          padding: "2px",
          width: boxWidth || "100%",
          height: boxHeight || "auto",
        //   marginLeft:'50px'
          padding:'5px',
          overflowX: 'auto' // Enable horizontal scrolling
         
        }}
      >
            <div >
            <h6
              className="p-2"
              style={{
                color: "#1976d2",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {chartHeading}
            </h6>
            </div>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
            //  width={boxWidth} height={270}
            data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xKey} label={{ value: xAxisLabel, position: 'insideBottom', dy: 10  }} angle={angle} textAnchor={angle === 0 ? "middle" : "end"} interval={0} height={75}  />
            <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideCenter', dx: -10  }} />
            <Tooltip formatter={tooltipFormatter} />
            <Bar dataKey={barKey} fill={barColor} />
            </BarChart>
            </ResponsiveContainer>
    </div>
  );
};
 
export default SimpleBarChart;
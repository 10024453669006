import React from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Card, CardContent, CardActions, Button, Typography, Box } from "@mui/material";
import "./ReceiverMessage.css";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { capitalize } from 'lodash';

const ReceiverMessage = ({ message, handleDownload}) => {
  const dateTime = new Date(message.createdAt);
  const formattedDateTime = dateTime.toLocaleString();


  const renderMessageContent = () => {
    if (message.contentType === "file") {
      // Render content for file type message
      return (
        <Box display='flex' flexDirection='column' justifyContent='start'>
          <Typography variant='caption'>{capitalize(message.userName)}</Typography>
        <div className="receivermessage1">
          <Card sx={{ bgcolor: '#F2F1EB !important', width: '30ch !important' }} py={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
              <Typography>{message.fileName}</Typography>
              {
                message?.download ?
                  <CheckCircleIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
                  :
                  <DownloadForOfflineIcon sx={{ fontSize: '40px', cursor: 'pointer' }} onClick={handleDownload} />
              }           
               </Box>
            <Box py={1} display='flex' justifyContent='end'>
              <Typography variant="caption" fontSize='10px'>
                {formattedDateTime}
              </Typography>
            </Box>
          </Card>
        </div>
        </Box>
      );
    } else if (message.contentType === "audio") {
      // Render content for audio type message
      return (
        <Box display='flex' flexDirection='column' justifyContent='start'>
          <Typography variant='caption'>{capitalize(message.userName)}</Typography>
        <div className="receivermessage1">
          <Card sx={{ bgcolor: '#F2F1EB !important', width: '30ch !important' }} py={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
              <audio controls>
                <source src={message.content} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </Box>
            <Box py={1} display='flex' justifyContent='end'>
              <Typography variant="caption" fontSize='10px'>
                {formattedDateTime}
              </Typography>
            </Box>
          </Card>
        </div>
        </Box>
      );
    } else {
      // Default rendering for other message types
      return (
        <Box display='flex' flexDirection='column' justifyContent='start'>
          <Typography variant='caption'>{capitalize(message.userName)}</Typography>
        <div className="receivermessage">
          <p>{message.content}</p>
          <Box py={1} display='flex' justifyContent='end'>
            <Typography variant="caption" fontSize='10px'>
              {formattedDateTime}
            </Typography>
          </Box>
        </div>
        </Box>
      );
    }
  };

  return renderMessageContent();
};

export default ReceiverMessage;

import React, { useState } from "react";
// import image1 from "../static/Group5702x.png";
import image1 from "../static/signup_login/contactPrologo.PNG";
import bgImage from "../static/Group244.png";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import Correct from "../static/correct.png";
import userService from "../services/userService";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { showToast } from "../messages";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
}));

const styles = {
  input: {
    color: 'white',
    borderBottom: '1px solid white', // Set the color of the bottom line
  },
  notchedOutline: {
    borderColor: 'white !important', // Set the color of the outlined border
  },
};

function AfterRegistration({ data }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(data.email);

  const handleResendMail = async () => {
    setLoading(true);
    if (!email) {
      setLoading(false);
      showToast('Warning.enterEmail')
      return;
    }
    if (!data.email) {
      setLoading(false);
      showToast('Error.somethingFailed')
      return;
    }
    console.log(email);

    const result = await userService.resendEmail(email, data.email);
    if (result.status !== 200) {
      setLoading(false);
      toast.warn(result.message);
    } else {
      setLoading(false);
      toast.info(result.message);
    }
  };
  return (
    <div>
      <Loader loading={loading} />
      <div className="container mt-3" style={{ width: "100%" }}>
        <div
          className="row d-inline-flex p-3 text-white"
          style={{ width: "100%" }}
        >
          {/* Flex One */}
          <div
            className="col-md-6 p-4 border"
            style={{
              // backgroundColor: "#3b81f3",
              width: "100%",
              color: "black",
              // backgroundImage: `url(${bgImage})`,
              // backgroundSize: "cover",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "center",
            }}
          >
            {/* Flex One Content */}
            <div
              style={{
                paddingLeft: "19.8%",
                paddingRight: "14.6%",
                paddingTop: "30%",
                paddingBottom: "30%",
              }}
            >
              <div className="row">
                <div>
                  {/* <h3 className="text-center" style={headOneStyle}>
                    PROFESSIONAL
                  </h3>
                  <h3 style={headTwoStyle}>EDITING TOOL</h3> */}
                  <Link to="/">
                    <img
                      src={image1}
                      alt="image1"
                      //         srcset="img/group-457@2x.png 2x,
                      //  img/group-457@3x.png 3x"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    ></img>
                  </Link>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20%" }}>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "30px",
                    fontWeight: "500",
                    lineHeight: "1.35",
                    textAlign: "left",
                    color: "black",
                  }}
                >
                  Welcome to ContactPRO
                </p>
              </div>

              <div className="row" style={{ marginTop: "7%" }}>
                <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                  Welcome to Contract-Pro,it is your go-to solution for enhanced
                  productivity and seamless collaboration. Whether you're
                  working solo or with a team, the comprehensive features and
                  user-friendly interface make it an indispensable tool for a
                  wide range of tasks.
                </p>
              </div>
            </div>
          </div>

          {/* Flex Two */}

          <div
            className="col-md-6 p-4 border border-primary"
            style={{
              width: "100%",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "white",
            }}
          >
            {/* Flex Two Content */}
            <div className="text-center mt-4">
              <img
                className=" "
                style={{ height: "25%", width: "25%" }}
                src={Correct}
                alt="404"
              ></img>
              {/* <HeadingB className="alertHead" text="No Payment Reports Found..!" /> */}
            </div>
            <div
              style={{
                paddingLeft: "20%",
                paddingRight: "20%",
                paddingTop: "8%",
                paddingBottom: "4%",
              }}
            >
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "1.26",
                      color: "white",
                    }}
                  >
                    Thank You For Registration !
                  </h3>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-12">
                  <p
                    style={{
                      // opacity: "0.5",
                      fontSize: "15px",
                      lineHeight: "1.72",
                      textAlign: "justify",
                      color: "white",
                    }}
                  >
                    <b>
                      A confirmation mail has been sent to your email. If you
                      cannot see the email from ContractPRO in inbox, make sure to check
                      your SPAM folder.
                    </b>
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "10%", color: "white" }}>
                <div className="col-md-12 ">
                  <form
                    Style={{ color: "white" }}
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <TextField
                    style={{color:"white",border:"3px solid white"}}
                      id="standard-dense"
                      label="Email ID"
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    /> */}
                    <TextField
                      id="standard-dense"
                      label="Email ID"
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // InputProps={{
                      //   style: { color: "white" },
                      //   classes: {
                      //     notchedOutline: classes.textFieldBorder,
                      //   },
                      // }}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      InputProps={{
                        style: styles.input,
                        classes: {
                          notchedOutline: styles.notchedOutline,
                        },
                      }}
                    />
                  </form>
                </div>
              </div>
              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-outline-secondary  w-100 loginBtn"
                    onClick={handleResendMail}
                  >
                    Resend Mail
                  </button>
                </div>

                <div className="col-md-6">
                  <Link to="/signup">
                    <button
                      type="button"
                      className="btn btn-outline-secondary  w-100"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AfterRegistration;

import React from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton,Typography,Modal,Accordion, AccordionSummary, AccordionDetails,} from '@material-ui/core';
import { Modal as B_Modal, Form } from "react-bootstrap";




export const Coauthor_Notescomponent = ({ noteInput, handleTextInput }) => {
    const handleTextChange = (e) => {
      handleTextInput(e.target.value);
    };
  
    return(
      <>
       <Form.Group>
        <Form.Control
          as="textarea"
          placeholder="Please enter your text."
          cols="60"
          rows="50"
          value={noteInput}
          onChange={handleTextChange}
        />
        </Form.Group> 
      </>
    )
  }
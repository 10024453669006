import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/activitytransaction";
} else {
  apiEndpoint = herokuUrl + "/activitytransaction";
}


export async function addActivity(  assetId,assetType, msg, createdBy, sectionTitle ) {
    let data = await httpService.post(`${apiEndpoint}/addactivitytransaction`,
    {
        assetId,
        assetType, 
        msg, 
        createdBy,
        sectionTitle 
    }
    );
    return data;
  }

  

  export async function getActivity( assetId ) {
    let data = await httpService.post(`${apiEndpoint}/getactivityByassetId`,
    {
        assetId, 
    }
    );
    return data;
  }

  export async function markasread(data) {
    const details = await httpService.post(`${apiEndpoint}/markasread`, data);
    return details;
  }

  export async function markallasread(email) {
    const details = await httpService.post(`${apiEndpoint}/markallasread`, {email});
    return details;
  }
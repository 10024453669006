import React, { Component } from "react";
// import Test from "../Test";
import Blogpage from "../Blogpage";

class BlogsonLandingPage extends Component {
  render() {
    return (
      <Blogpage
        category={
          this.props.location.state
            ? this.props.location.state.category
            : "blog"
        }
      />
    );
  }
}

export default BlogsonLandingPage;

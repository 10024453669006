import React, { useEffect, useState } from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import "./PricePlans/plans.css";
import BasicPlan from "./PricePlans/BasicPlan";
import BreadCrumb from "./Cards/BreadCrumbs";
import { getAllPlan } from "../services/plans";
import { Button, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Group570 from "../static/Group570.png";

export default function PriceDetail({ breadCrumb }) {
  const [planList, setplanList] = useState([]);
  useEffect(() => {
    // onLoad();
    initalCall();
  }, []);

  const initalCall = async () => {
    const data = await getAllPlan();
    if (data.status !== 200) return;

    const plans = data.plan;
    let html = plans.map((plan) => {
      const content = unOrderList(plan.content);
      const planName = plan.planName;
      const nameOfTerms = plan.nameOfTerms;
      const planMode = { mode: plan.planMode };
      const termDetails = convertToObj(plan.termDetails);
      return (
        <BasicPlan
          heading={planName}
          ul={content}
          btn_list={nameOfTerms}
          price_detail={termDetails}
          plan_mode={planMode}
          hide={true}
        />
      );
    });
    setplanList(html);
  };

  const convertToObj = (termDetails) => {
    let termObj = termDetails.reduce((newObj, obj) => {
      const { term, price, user_count } = obj;
      if (!newObj[obj.term]) {
        newObj = { ...newObj, [term]: [] };
      }
      let term_arr = newObj[term];
      term_arr.push({ price, user_count });
      return newObj;
    }, {});

    return termObj;
  };

  const unOrderList = (contentObj) => {
    if (contentObj) {
      const ul = Object.keys(contentObj).map((key) => {
        let get_data = contentObj[key];
        if (typeof get_data == "object") {
          const lh = <lh>{key}</lh>;
          const li = get_data.map((text) => <li>{text}</li>);
          return (
            <ul>
              {" "}
              {lh} {li}{" "}
            </ul>
          );
        }
      });
      return ul;
    }
  };

  return (
    <div>
      <div>
        <div className="container p-5">
          <Navbar
            expand="lg"
            fixed="top"
            style={{
              // position: "sticky",
              // top: "0",
              backgroundColor: "#1976d2",
              paddingLeft: "7%",
              // marginTop: "3%",
              // backgroundColor:
              //   window.scrollY > 100 ? "#1976d2" : "transparent"
            }}
            // sticky="top"
          >
            <Navbar.Brand style={{ color: "white" }} href="#">
              <img
                className="petLogo"
                alt="Group570"
                style={{ width: "auto", height: "60px" }}
                src={Group570}
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              // className="navbar-toggler navbar-toggler-right"
            />
            {/* justify-content-end */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navLinks" style={{marginLeft: "62%"}}>
                <Nav.Link className="ft_md text-white">
                  <NavLink className="ft_md text-white" to={{ pathname: "/" }}>
                    Home
                  </NavLink>
                </Nav.Link>
                <Nav.Link
                  className="loginLink"
                  style={{ color: "white", marginLeft: "10%" }}
                  // href="#"
                >
                  <NavLink
                    className="text-white ft_md"
                    to={{ pathname: "/login" }}
                  >
                    Login
                  </NavLink>
                </Nav.Link>
                &nbsp;&nbsp;
                <Link to={{ pathname: "/signup" }}>
                  <Button
                    className="ft_md"
                    style={{
                      textAlign: "center",
                      color: "#4085f6",
                      borderRadius: "20px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    SignUp
                  </Button>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {breadCrumb && (
        <BreadCrumb
          l1="Dashboard"
          l2="Subcriptions"
          l3="pricedetails"
          dashboardlink="dashboard"
          documentLink="pricing"
        />
      )}
      <div className="container plans">
        <ScrollUpButton />
        <div className="d-flex justify-content-center">
          <h2 className="text-center text-dark w-50 my-4">
            Get Instant Access to the Professional Editing Tool Choose Your Plan
            and Get Started
          </h2>
        </div>

        {planList}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import Loader from "../utils/loader";
import InvoiceCard from "./Cards/InvoiceCard";
import InvoiceService from "../services/invoiceService";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import BreadCrumb from "./Cards/BreadCrumbs";
import "./Invoice.css";
import NoInvoices from "../static/noInvoices.png";
import HeadingB from "./Cards/HeadingB";
import { ToastContainer, toast } from "react-toastify";
import { getUserInfo } from "../services/opportunityService";



const Invoice = () => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dashLink, setDashLink] = useState("/dashboard");
  const [noInvoice, setNoInvoice] = useState(false);
  const [userInfo, setUserInfo] = useState('')



  useEffect(() => {
    getCuurentUser();
    
  }, []);

  useEffect(()=>{
    const fetchUserData = async () => {
      const user = await authService.getCurrentUser();
      if (user.isAdmin) {
        getAllPayments();
        setDashLink("/admindashboard");
      } else if (user.isUser) {
        getAllUserInvoices();
      } else {
        setDashLink("/subAdminDashboard");
        getAllUserInvoices();
      }
    };
    fetchUserData();

  },[userInfo])

  const getAllPayments = async () => {
    let details = await InvoiceService.getAllpaymentsForAdmin();
    console.log(details);
    if (details.length > 0) {
      const expandedArray = new Array(details.length).fill(false);
      setInvoices(details.data);
      setExpanded(expandedArray);
      setTotalInvoices(details.length);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getCuurentUser = async () => {
    const Username = sessionStorage.getItem("currentUserEmail")
    try {
      const response = await getUserInfo(Username)
      console.log("userinfo", response)
      setUserInfo(response.data)

    } catch (error) {
      console.log(error)
    }
  }

  const getAllUserInvoices = async () => {

    console.log(userInfo?._id,"kokokok")
    let details = await InvoiceService.getUserInvoices(userInfo?._id);
    console.log(details);
    if (details.status !== 200) {
      toast.error(details.message);
    } else {
      if (details.data.length < 1) {
        setLoading(false);
        setNoInvoice(true);
      } else {
        const expandedArray = new Array(details.data.length).fill(false);
        setInvoices(details.data);
        setExpanded(expandedArray);
        setLoading(false);
        console.log(invoices);
      }
    }
  };

  const handleExpand = (panel, index) => {
    const newExpanded = expanded.map((item, idx) =>
      idx === index ? !item : false
    );
    setExpanded(newExpanded);
  };

  return (
    <div>
      <Loader loading={loading} />
      <ToastContainer />
      <div className="">
        {/* <BreadCrumb
          l1="Home"
          l2="Invoice"
          dashboardlink={dashLink}
        />

        <HeadingB text="Invoice" /> */}

        {noInvoice ? (
          <div className="text-center mt-4">
            <img
              className=" mt-5"
              style={{ height: "150px" }}
              src={NoInvoices}
              alt="404"
            ></img>
            <HeadingB className="alertHead" text="No Invoice Found..!" />
          </div>
        ) : (
          <div className="row mt-2">
            <div className="col-md-12 mt-2 ">
              {invoices.length > 0 &&
                invoices.map((order, index) => (
                  <InvoiceCard
                    key={index}
                    data={order}
                    index={index}
                    expand={handleExpand}
                    expanded={expanded[index] ? `panel${index}` : false}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Invoice;

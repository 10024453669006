import React, { useEffect } from "react";
import {
  Modal,
  Checkbox,
  Typography,
  Box,
  Button
} from "@material-ui/core";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  outline: "none",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  width: "70%",
  height: "fit-content",
  overflowY: "auto"
};

export const ResourceLinksModal = (props) => {
  const { open, close, documentData, handleAttachment, resourceLinks,submit } = props;

  const isPDFAttachment = (attachmentName) => {
    return attachmentName?.toLowerCase().endsWith('.pdf') || attachmentName?.toLowerCase().endsWith('.docx');
  };

  useEffect(() => {
    console.log("props", props);
  }, [props]);

  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        {documentData &&
          documentData.map((attachment, index) => {
            const attachmentName = attachment?.linkname;
            if (isPDFAttachment(attachmentName)) {
              const isSelected = resourceLinks?.includes(attachment.url);
              return (
                <Box
                  className="row"
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    cursor: 'pointer',
                    backgroundColor: isSelected ? 'lightblue' : 'inherit',
                    padding: '5px',
                    borderRadius: '4px',
                    flexWrap: "nowrap"
                  }}
                  onClick={() => handleAttachment(attachment.url)}
                >
                  <Checkbox
                    checked={isSelected}
                    onChange={() => handleAttachment(attachment.url)}
                    color="primary"
                    inputProps={{ 'aria-label': 'select attachment' }}
                  />
                  <InsertDriveFileIcon style={{ marginRight: '5px' }} />
                  <Typography>{attachmentName}</Typography>

                </Box>
              );
            }
            return null;
          })}

          <Button
          onClick={submit}
          variant="contained" color="primary"
          style={{margin: "15px"}}
          >
            Submit
          </Button>
          
      </Box>
    </Modal>
  );
};

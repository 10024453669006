import React, { memo } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';
import {
  IconButton,
} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import AddBoxIcon from '@mui/icons-material/AddBox';

const ResizableGroup = ({ selected, id, data, children, addStatus }) => {

  const handleAddStatus = () => {
    console.log("adddata", data, "id", id, "children", children)
    addStatus(id, data)
  }

  return (
    <div style={{ padding: 10 }}>
      <NodeResizer
        isVisible={selected}
        minWidth={100} minHeight={30} />
      {/* <Handle type="target" position={Position.Left} /> */}
      {/* <div>{data.label}</div> */}
      <div className='d-flex justify-content-between'>
        <div className=''>
          {data.label}
        </div>
        <div>
        <Tooltip title="Add Status" sx={{ fontSize: "25px" }} >
          <button
            className='addStatus'
            onClick={handleAddStatus}
          >
            <AddBoxIcon />
          </button>
          </Tooltip>
        </div>
      </div>
      {/* <Handle type="source" position={Position.Right} /> */}
      {children}
    </div>
  );
};

export default memo(ResizableGroup);

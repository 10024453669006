import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, List, ListItem, ListItemText, Typography, Box, Divider } from '@material-ui/core';

const TaskCompletionModal = ({ open, handleClose, tasks, handleComplete }) => {
  const [taskStatus, setTaskStatus] = useState([]);
  const [anyTaskSelected, setAnyTaskSelected] = useState(false);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      setTaskStatus(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    // Check if any task is selected whenever taskStatus changes
    console.log(taskStatus, "taskStatus");
    setAnyTaskSelected(taskStatus.some(task => task.Status));
  }, [taskStatus]);

  const handleToggle = (index) => {
    const updatedTasks = taskStatus.map((task, i) =>
      i === index ? { ...task, Status: !task.Status } : task
    );
    setTaskStatus(updatedTasks);
  };

  const handleCompleteClick = () => {
    handleComplete(taskStatus);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '20%',
          right: '20%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxWidth: 400,
          margin: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Task Completion</Typography>
          <Button variant="outlined" onClick={handleClose}>X</Button>
        </Box>
        <Divider />

        <List>
          {taskStatus?.map((task, index) => {
            if (task.task === 'Terminate') {
              return null; // Skip this task
            }
            return (
              <ListItem key={index} dense button onClick={() => handleToggle(index)}>
                <Checkbox
                  edge="start"
                  checked={task.Status}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={task.task} />
              </ListItem>
            );
          })}
        </List>

        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} style={{ marginRight: '10px' }}>Cancel</Button>
          {anyTaskSelected && (
            <Button variant="contained" color="primary" onClick={handleCompleteClick}>Complete</Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskCompletionModal;

import React, { useState, useEffect } from 'react'
import { InputText, DropDownComp } from "../Cards/TexboxValidate"
import { FormControlLabel, Checkbox } from '@material-ui/core';
import swal from 'sweetalert';
import { ContentList } from './ContentList';
import BreadCrumb from "../Cards/BreadCrumbs";
import { getAllPlan, deletePlanById, addPlan, updatePlanById } from '../../services/plans'
// import { getAllFeatures } from '../../services/features'
import { toast } from 'react-toastify';
import _ from "lodash";

function AddPlan() {

    const initalState = {
        plan_name_submit: false,
        term_details_submit: false,
        temp_data: {},
        term_details: [],
        selected: "",
        clear: ""
    }
    const [features, setfeatures] = useState({});
    const [inital, setinital] = useState(initalState);
    let [content, setcontent] = useState({});
    const [clear, setclear] = useState(null);
    const [htmlList, sethtmlList] = useState(null);
    const [price, setprice] = useState(null);
    const [user_count, setUser_count] = useState(null);
    let [plan_name, setplan_name] = useState("");
    const [errorSelect, seterrorSelect] = useState('')
    const [planList, setplanList] = useState([]);
    const [planNameClear, setplanNameClear] = useState("")
    const [_id, set_id] = useState(null);
    const [planMode, setplanMode] = useState('');

    let temp_data = {};
    const terms = [
        {
            term: 'Yearly',
            period: 365
        },
        {
            term: 'Annually',
            period: 365
        },
        {
            term: 'Monthly',
            period: 30
        },
        {
            term: 'Quarterly',
            period: 168
        },
        {
            term: 'Semester',
            period: 254
        },
        {
            term: 'Trimester',
            period: 45
        }
    ]

    useEffect(() => {
        initalCall();
        featuresFn();
    }, []);

    const featuresFn = async () => {
        // const response = await getAllFeatures();
        // console.log('getAllFeatures', response);
        // if (response.status !== 200) return;
        // const featuresList = response.features;
        // const features = featuresList.reduce((obj, feature) => ({ ...obj, [feature]: false }), {});
        // setfeatures(features);
    }

    const initalCall = async () => {
        const result = await getAllPlan();
        if (result.status == 200)
            setplanList(result.plan);
        console.log('myresult', result)
    }


    const structureData = async (status) => {
        setinital({ ...inital, plan_name_submit: true });
        console.log("plan_name", plan_name);
        if (!plan_name)
            return;
        const result = {
            'nameOfTerms': inital.term_details.reduce((arr, obj) => {
                if (!arr.includes(obj.term))
                    return [...arr, obj.term]
                return arr

            }, []),
            'termDetails': inital.term_details,
            'planName': plan_name,
            'content': content,
            features
        }

        if (Object.keys(result.content).length < 1) {
            toast.error('Please add any one of the content');
            return
        }

        if (result.termDetails.length < 1) {
            toast.error('Please add term details');
            return
        }

        if (result.termDetails.some(termDetail => termDetail.user_count))
            result.planMode = "Team"
        else
            result.planMode = "Personal";

        console.log('status', status)
        if (status == 'insert')
            AddPlan(result)
        else
            UpdatePlan(result)
    }

    const AddPlan = async (result) => {
        const data = await addPlan(result);
        console.log(data)
        if (data.status == 200) {
            toast.success(data.message);
            initalCall();
            clearAll(data.message);
        }
        else
            toast.error('Oops something went wrong');
    }

    const UpdatePlan = async (result) => {
        result._id = _id;
        console.log("result", result);
        const data = await updatePlanById(result);
        console.log(data)
        if (data.status == 200) {
            toast.success(data.message);
            initalCall();
            clearAll(data.message);
        }
        else
            toast.error('Oops something went wrong');
    }

    const deletePlan = async (_id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete the plan?",
            icon: "warning",
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    const result = await deletePlanById(_id);
                    console.log(result)
                    if (result.status == 200) {
                        toast.success(result.message);
                        initalCall();
                        swal("Deleted!", "Your Plan has been deleted!", "success");
                    }
                    else
                        toast.error('Oops something went wrong');
                }
            });
    }

    const handleFn = (action) => {
        console.log(action);
        switch (action.type) {
            case 'drop_down': {
                const selected = action.value;
                setinital({ ...inital, selected });
                seterrorSelect('');
            }
                break;

            case 'term_detail': {
                const selected = inital.selected;
                console.log("objectterm_detail", user_count, price, selected, temp_data);
                dynamicTextInsertion(selected);
                if (!price) {
                    setinital({ ...inital, term_details_submit: true });
                    return
                }

                if (!selected) {
                    seterrorSelect('error_bor')
                    return;
                }
                const term_details = inital.term_details
                term_details.push({ price, user_count: user_count, term: selected });
                setinital({ ...inital, term_details, clear: Math.random() });
                setUser_count(null);
                setprice(null);
                seterrorSelect('');
            }
                break;

            case 'term_details_remove': {
                let term_details = _.remove(inital.term_details, (obj) => {
                    return ((obj.price !== action.value || obj.term !== action.term))
                })
                setinital({ ...inital, term_details });
                let key , value;
                if(planMode === "Team"){
                    key = `${action.term} Subscription Cost`;
                    value = `${action.user_count} Users - $${action.value}/${action.term.replace('ly','')}`
                }else{
                    console.log(action.term);
                    let period = terms.filter(term => term.term === action.term);
                    let NoOfMonths = Math.round((period[0].period) / 30);
                    let subcriptionCostForMonth = parseFloat(action.value / NoOfMonths).toFixed(2);
                    console.log('NoOfMonths===>', NoOfMonths);
                    console.log('subcriptionCostForMonth===>', subcriptionCostForMonth);
                    key = `${action.term} Subscription Cost`;
                    value = `${action.term} Subscription - $${subcriptionCostForMonth}/Month(billed as one payment of $${action.value})`
                }
                contentDelete(key, value)
                
            }
                break;

            default:
                console.log("went to default", action.type)
                break;
        }
    }

    function contentAdd(obj) {
        console.log("object", obj)
        const value = obj.value.trim();
        const key = obj.key;
        console.log({ key, value })

        if (!content[key]) {
            let create_obj = {
                [key]: [value]
            }
            setcontent({ ...content, ...create_obj });
            content = { ...content, ...create_obj };
            createHTML({ ...content, ...create_obj });
            setclear(value)
        }
        else {
            let arr = content[key];
            arr.push(value)
            setcontent({ ...content, [key]: arr });
            createHTML({ ...content, [key]: arr });
            setclear(value)
        }
    }

    function createHTML(obj) {
        // console.log(obj)
        let keys = Object.keys(obj);
        let result = keys.map((key) => {
            let arr = obj[key];
            console.log("createHTML", arr);
            const li = arr.map((text) => {
                return (
                    <li key={text} className="d-flex align-items-center justify-content-between">
                        <span>{text}</span>
                        <i onClick={() => contentDelete(key, text)}
                            className="fa fa-trash text-primary point" aria-hidden="true"></i>
                    </li>
                )
            })
            return (
                <ul key={key}>
                    <lh className="d-flex align-items-center justify-content-between"  >
                        <span>{key}</span>
                        <i onClick={() => contentDelete(key)}
                            className="fa fa-trash text-danger point" aria-hidden="true"></i>
                    </lh>
                    {li}
                </ul>
            )
        })
        sethtmlList(result)
    }

    function contentDelete(key, value) {
        console.log("key===>", key);
        if (!value) {
            delete content[key];
            createHTML(content)
        }
        else {
            let arr = content[key];
            console.log('arr', arr)
            console.log("value==>",value);
            let filtArr = arr.filter((text) => text != value);
            console.log("array", arr, filtArr)
            setcontent({ ...content, [key]: filtArr });
            content = { ...content, [key]: filtArr };
            createHTML({ ...content, [key]: filtArr })
        }
    }

    const showPlan = (obj) => {
        console.log("object", obj)
        // setplanNameClear(Math.random());
        // featuresFn();
        setinital(initalState);
        content = obj.content;
        setcontent(obj.content);
        createHTML(obj.content);
        set_id(obj._id)
        setinital({ inital, term_details: obj.termDetails });
        setplan_name(obj.planName);
        setFeatureVal(obj.features);
        setplanMode(obj.planMode);
    } 

    useEffect(() => {
            console.log('Test===>',inital.term_details);
    },[inital])

    const setFeatureVal = (getFeature) => {
        if (!getFeature || !features)
            return;
        const featuresKey = Object.keys(features);
        const updatedFeature = featuresKey.reduce((obj, feature) => {
            const status = getFeature[feature];
            return { ...obj, [feature]: status || false }
        }, {})
        console.log('updatedFeature', updatedFeature);
        setfeatures(updatedFeature)
    }

    const textBoxPlan = (obj) => {
        const { planName } = obj;
        console.log("planName", planName)
        if (planName)
            setplan_name(planName)
    }

    const handleChkBox = (feature) => {
        setfeatures({ ...features, [feature]: !features[feature] })
    }

    const clearAll = (data) => {
        showPlan({ planName: "", content: {} })
        setplanNameClear(Math.random());
        featuresFn();
        setinital(initalState);
    }
    const dynamicTextInsertion = (selected) => {
        console.log("objectterm_detail", user_count, price, selected, temp_data);
        let period = terms.filter(term => term.term === selected);
        let appendText = {}
        if (planMode === "Personal") {
            let NoOfMonths = Math.round((period[0].period) / 30);
            let subcriptionCostForMonth = parseFloat(price / NoOfMonths).toFixed(2);
            console.log('NoOfMonths===>', NoOfMonths);
            console.log('subcriptionCostForMonth===>', subcriptionCostForMonth);
            appendText = { value: `${selected} Subscription - $${subcriptionCostForMonth}/Month(billed as one payment of $${price})`, key: "Subscription Cost" };
        } else {
            let key = `${selected} Subscription Cost`;
            let value = `${user_count} Users - $${price}/${selected}`
            appendText = { key, value };
        }
        console.log("appendText===>", appendText);
        contentAdd(appendText);
    } 
    return (
        <div className="container">

            <div className='d-flex justify-content-between align-items-center'>
                <BreadCrumb
                    l1="Dashboard"
                    l2="Subcriptions"
                    l3="addsubscription"
                    dashboardlink="admindashboard"
                    documentLink="pricing"
                />
                <span className="text-primary point" onClick={() => clearAll(Math.random())}>Clear</span>

            </div>

            <div className="sticky">
                {
                    planList.map((obj) => {
                        return (<div key={obj._id} onClick={() => showPlan(obj)}
                            className="d-flex justify-content-between hovp bg-primary p-3 mb-1 rounded" style={{cursor: "pointer"}}>
                            <p className="text-light m-0" >{obj.planName}</p>
                            {obj.planName !== 'PET Basic Plan' && <i onClick={() => deletePlan(obj._id)}
                                className="fa fa-trash text-danger point" aria-hidden="true"></i>}
                        </div>)
                    })
                }
            </div>
            <br />

            <div className="row">
                <div className='col-md-6'>
                    <InputText
                        className="w-75"
                        required={true}
                        label="Plan Name"
                        name="planName"
                        submit={inital.plan_name_submit}
                        getValue={(obj) => textBoxPlan(obj)}
                        min={5}
                        value={plan_name}
                        min_error="Plan Name should be more then 5 charcter"
                        empty_string="Please enter the plan name"
                        clear={planNameClear}
                    />
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        {
                            features && Object.keys(features).map((feature) => {
                                return (
                                    <div className='col-md-4'>
                                        <FormControlLabel onChange={() => handleChkBox(feature)}
                                            control={<Checkbox value={feature}
                                                color="primary"
                                                checked={features[feature]} />} label={feature} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <br /> <br />
            <div>


                <ContentList
                    label="Content"
                    name="content"
                    type="sucription_cost"
                    clear={clear}
                    handleFn={(obj) => contentAdd(obj)}
                />
                <br />
                <div className="plans">
                    {htmlList}
                </div>

            </div>


            <br />

            <div className="d-flex align-items-end">

                <InputText
                    required={true}
                    label="price"
                    name="price"
                    submit={inital.term_details_submit}
                    clear={inital.clear}
                    // numberOnly={true}
                    getValue={(obj) => setprice(parseFloat(obj.price))}
                />

                <InputText
                    className="mx-3"
                    label="User Count"
                    name="user_count"
                    clear={inital.clear}
                    submit={inital.term_details_submit}
                    numberOnly={true}
                    getValue={(obj) => setUser_count(obj.user_count)}
                />

                <DropDownComp errorSelect={errorSelect}
                    placeholder="Select Term* "
                    selected={inital.selected}
                    terms={terms.map(obj => obj.term)}
                    handleFn={(obj) => handleFn(obj)} />


                <button className="btn btn-primary ml-2 px-5"
                    onClick={() => handleFn({ type: 'term_detail' })}
                >Add</button>
            </div>

            <div>
                {
                    inital.term_details && inital.term_details.length > 0 &&
                    <table className="table table-bordered mt-5">
                        <thead>
                            <tr>
                                <th>Price</th>
                                <th>User Count</th>
                                <th>Term</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inital.term_details.map((obj,index) => {
                                    console.log("term_details",obj);
                                    return (
                                        <tr key={index}>
                                            <td>{obj.price}</td>
                                            <td>{obj.user_count}</td>
                                            <td>{obj.term}</td>
                                            <td>
                                                <i onClick={() => handleFn({ type: 'term_details_remove', user_count:obj.user_count, value: obj.price,term : obj.term })}
                                                    className="fa fa-trash text-primary point" aria-hidden="true"></i>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>


                }

            </div>




            <br /><br />

            {_id && <button className="btn btn-primary px-5"
                onClick={() => structureData('update')} >Update Plan</button>}

            {!_id && <button className="btn btn-primary px-5 ml-4"
                onClick={() => structureData('insert')} >Add Plan</button>}

            <br /><br />
        </div>
    )
}

export default AddPlan
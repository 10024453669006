import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdmZip from 'adm-zip';
const xml2js = require('xml-js');

const FileViewer = ({ fileId }) => {
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        // Fetch file data from API endpoint
        const response = await axios.get(`https://api.contractpro360.com/api/zipfile/files/${fileId}`);
        console.log(response,"aaaaaaaaaaaa")
       
        // const binaryData = response.data;
        // const decodedData = atob(binaryData);
        // const dataArray = response.data;
        // const displaydata = dataArray.map(k=>k.content,k.filename)
        // setFileContent(displaydata);

//         const dataArray = response.data;
// const displayData = dataArray.map(item => `${item.content} ${item.filename}`);
// setFileContent(displayData);

const dataArray = response.data;
const displayData = dataArray.map(item => {
    return `${item.filename}\n\n${item.content}\n`;
});
setFileContent(displayData);

      } catch (error) {
        console.error('Error fetching file content:', error);
      }
    };

    fetchFileContent();
  }, [fileId]);

  return (
    <div>
    
      {/* <pre>{fileContent}</pre> */}
      <pre dangerouslySetInnerHTML={{ __html: fileContent }} />
    </div>
  );
};

export default FileViewer;





import {
  apiUrl,
  herokuUrl
} from "./config.json";
 
 
import httpService from "./httpService";
 
let apiEndpoint = "";
 
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/bucket`;
 
} else {
  apiEndpoint = `${apiUrl}/bucket`;
 
}
 
// export async function gettemplates(data) {
//   console.log("service list Called")
//   const response = await httpService.post(`${apiEndpoint}/list`,data);
 
//   return response;
// }
export async function getTemplates(data) {
  const response = await httpService.post(`${apiEndpoint}/getTemplates`,data);
  console.log(response,"service list Called")
 
  return response;
}
 
 
 
 
export async function downloadTemplates(data) {
  console.log("data in service   ", data)
  const response = await httpService.get(`${apiEndpoint}//download/${data}`);
 
  return response;
}
 
export async function getFileContent(data,orgName,userid) {
  
  console.log("Data Called", data)
  let isBinary = false
  let doc = "doc"
  if (data.includes(".doc") || data.includes(".docx")) {
    isBinary = false
    doc = "doc"
  }
  const response = await httpService.get(`${apiEndpoint}/getByName25/${orgName}/${userid}/${data}`, isBinary,doc);
  console.log("Responce  ", response)
  return response;
}

export async function getRFPDocument(data, orgId, opportunityid, assetid ,userId,assetsectionId) {
  console.log("Data Called", data, "==" ,orgId,"==", opportunityid,"==" ,assetid ,"==","==",userId,"==",assetsectionId);
  let isBinary = true
  let doc = null
  if (data.includes(".doc") || data.includes(".docx")) {
    isBinary = false
    doc = "doc"
  }
  const response = await httpService.get(`${apiEndpoint}/getRFPDocument/${data}/${orgId}/${opportunityid}/${assetid}/${userId}/${assetsectionId}`,isBinary,doc);
  // const response = await httpService.get(url);
  console.log("Response ", response);
  return response;
}
 
// export async function getSectionDocument(data, orgId, opportunityid, assetid ,userId,assetsectionId,versionId) {
//   console.log("getSectionDocument Called", data, "==" , opportunityid,"==" ,assetid ,"==","==",userId,"==",assetsectionId);
//   let isBinary = true
//   let doc = null
//   if (data.includes(".doc") || data.includes(".docx")) {
//     isBinary = false
//     doc = "doc"
//   }
//   let url = `${apiEndpoint}/getSectionDocument/${data}/${orgId}/${opportunityid}/${assetid}/${userId}/${assetsectionId}`;
//   if (versionId) {
//       url += `/${versionId}`;
//   }

//   const response = await httpService.get(url, isBinary, doc); 
//   console.log("Response ", response);
//   return response;
// }

export async function getSectionDocument(data, orgId, opportunityid, assetid ,userId,assetsectionId,keydata) { 

  if(keydata == undefined){ 

    keydata = "owner" 

  } 

  console.log(keydata,"servicekey") 

 
 

  console.log(data, orgId, opportunityid, assetid ,userId,assetsectionId,keydata,"kkkkkkkkkkkkkkkkk") 

  console.log(data,"dadaname") 

  console.log("getSectionDocument Called", data, "==" , opportunityid,"==" ,assetid ,"==","==",userId,"==",assetsectionId); 

  let isBinary = true 

  let doc = null 

  if (data.includes(".doc") || data.includes(".docx")) { 

    isBinary = false 

    doc = "doc" 

  } 

  const response = await httpService.get(`${apiEndpoint}/getSectionDocument/${data}/${orgId}/${opportunityid}/${assetid}/${assetsectionId}/${keydata}`,isBinary,doc); 

  // const response = await httpService.get(url); 

  console.log("Response ", response); 

  return response; 

} 

 
export async function DeleteTemplate(data) {
 
 alert("deleted fro template service")
  const response = await httpService.post(`${apiEndpoint}/deleteTemplates/${data}`);
 
  return response;
}
 
export async function UploadTemplate(data) {
  console.log("Upload Template Called    ", data)
 
 
  const response = await httpService.post(`${apiEndpoint}/upload/${data}`);
 
  return response;
}
 
//vru
export async function newuploads(data) {
  console.log("Upload Template VRU Called   ", data)
 
 
  const response = await httpService.post(`${apiEndpoint}/newuploads/${data}`);
 
  return response;
}
 
 
 
export async function UpdateTemplate(data) {
  console.log("Update Template Called   ", data)
 
 
  const response = await httpService.post(`${apiEndpoint}/api/copy-template/${data}`);
 
  return response;
}
 
 
export async function TemplateMerge(data) {
  console.log("Merge Template Called   ", data)
 
 
  const response = await httpService.post(`${apiEndpoint}/getallhtml`,{data});
 
  return response;
}


export async function copyTemplate(data) {
  const response = await httpService.post(`${apiEndpoint}/copyDocument`,{data});
  return response;
}
export async function getFileContentforcopy(data) {
  // alert("data")
  console.log(data,"ddddddddddddd")
  // console.log("Data Called", data)
  // let isBinary = false
  // let doc = "doc"
  // if (data.includes(".doc") || data.includes(".docx")) {
  //   isBinary = false
  //   doc = "doc"
  // }
  const response = await httpService.get(`${apiEndpoint}/getByName25/${data}`);
  console.log("Responce  ", response)
  return response;
}
 
export async function GetDocData(data) {
let text  = data
  const response = await httpService.post(`${apiEndpoint}/processInputText`, {text});
 // console.log("Responce  ", response)
  return response;
}

export async function ImprovedDocData(data) {
  let text  = data
    const response = await httpService.post(`${apiEndpoint}/improveInputText`, {text});
    console.log("Responce  ", response)
    return response;
  }

  export async function getSpecificVersionData(data) {
      const response = await httpService.post(`${apiEndpoint}/getVersionsBySection`, {data});
      return response;
    }
 

 
export default {
  getTemplates,
  downloadTemplates,
  getFileContent,
  DeleteTemplate,
  UploadTemplate,
  newuploads,
  getRFPDocument,
  TemplateMerge,
  GetDocData,
  ImprovedDocData,
  getSpecificVersionData
 
}


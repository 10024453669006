import { sidenavbarActionTypes } from "./sidenavbar.types";

const INITIAL_STATE = {
    isOpen:{ stateValue:false}
}

const sidenavbarReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
            case sidenavbarActionTypes.SET_SIDE_BAR: 
            return{
                ...state,
                isOpen:action.payload,
            }
            case sidenavbarActionTypes.SET_SIDE_BAR_CLOSE: 
            return{
                ...state,
                isOpen:action.payload,
            }
        default:
            return state;
    }
};



export default sidenavbarReducer;
import React, { useState, useEffect } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from '@mui/material/Box';
//  import DropDownMenu from 'material-ui/DropDownMenu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import FormControl from '@mui/material/FormControl';
import team from "../static/noteams.png"
// import DataGridMui from "./ReusableComponents/DataGridMui";
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";
import getOrgUserRole from "../services/subAdminService";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import SaveIcon from '@mui/icons-material/Save';
import ModalMUI from "./ReusableComponents/ModalMUI";
import ModalUpdateMUI from "./ReusableComponents/ModalUpdateMUI";
import Axios from "axios";
// import featurelists, { postfeaturelist, deletefeaturelist } from "../services/featurelists";
import { editfeaturelist, postfeaturelist, getfeaturelist, deletefeaturelist, getEditDatalist } from "../services/features";
import { axisClasses } from "@mui/x-charts/ChartsAxis/axisClasses";
import BootStrapModal from "./ReusableComponents/BootstrapModal";
import AppFeatureRoleLists from "./AppFeatureRoleLists";
 
 
 
const FeatureLists = ({ currentUser }) => {
    const [inviteEmails, setInviteEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [inviteLimit, setInviteLimit] = useState(currentUser?.userCount);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [adminModalShow, setAdminModalShow] = useState(false);
    const [currentRow, setCurrentRow] = useState(null)
    const [filterTeam, setFilterTeam] = useState([]);
    const [adminChanged, setAdminChanged] = useState(false)
    const [age, setAge] = React.useState('');
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const [editableRowId, setEditableRowId] = useState(null); //new edit //
    const [editedData, setEditedData] = useState({}); //new edit //
    const [isUpdatemodalOpen, setIsUpdatemodalOpen] = useState(false);
    const openUpdatemodal = () => {
        setIsUpdatemodalOpen(true);
    };
    const [editValues,seteditValues]=useState([])
    const closeUpdateModal = () => {
        setIsUpdatemodalOpen(false)
    };
    
 
    // const handleEditClick = (rowId) => {
    //     setEditableRowId(rowId);
    //     const rowData = masterRowdata.find(row => row._id === rowId);
    //     // Ensure _id field is present
    //     if (rowData && rowData._id) {
    //         setEditedData(rowData);
    //     } else {
    //         console.error("Row data or _id is missing.");
    //     }
    // };
 
    const handleEditClick = (rowData) => {
        setEditedData(rowData);
        setOpen(true); // Open the modal
    };
   
 
 
    const handleSaveClick = async (rowId) => {
        try {
            await updateAppFeature(editedData);
            setEditableRowId(null);
        } catch (error) {
            console.error("Error updating feature:", error.message);
        }
    };
   
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };
 
    // const renderEditCell = (params) => {
    //     return (
    //         <>
    //         {
    //             editableRowId === params.row._id ? (
    //                 // Render input fields or other editable components
    //                 <input
    //                     type="text"
    //                     value={editedData.name} // Example: Update with appropriate field from editedData state
    //                     name="name"
    //                     onChange={handleInputChange}
    //                 />
    //             ) : (
    //             // Render non-editable cell content
    //             <span>{params.row.name}</span> // Example: Update with appropriate field from rowData
    //         )
    //         }
    //         <button
    //             className="btn btn-md"
    //             onClick={() => handleEditClick(params.row._id)}
    //             disabled={editableRowId === params.row._id}
    //         >
    //             {<BorderColorRoundedIcon fontSize="small" />}
    //         </button>
    //         </>
    //     );
    // };
 
    const renderEditCell = (params) => {
        return (
            <button
                className="btn btn-md"
                onClick={() => handleEditClick(params.row)}
                disabled={editableRowId === params.row._id}
            >
                {<BorderColorRoundedIcon fontSize="small" />}
            </button>
        );
    };
   
 
    const renderSaveCell = (params) => {
        return (
            <button
                className="btn btn-md"
                onClick={() => handleSaveClick(params.row._id)}
                disabled={editableRowId !== params.row._id}
            >
                {<SaveIcon fontSize="small" />}
            </button>
        );
    };
 
 
    const [roleList, setRoleList] = useState([
        {
            "value": "User",
            "name": "User"
        },
        {
            "value": "Admin",
            "name": "Admin"
        }
    ]);
    const [masterFeaturesdata, setFeaturesRowdata] = useState([])
 
    const [modalOpen, setModalOpen] = useState(false);
 
    const openModal = () => {
        setModalOpen(true);
    };
 
    const [userInfo, setuserInfo] = useState({
        username: "",
        email: "",
        Phone: "",
        userRole: "",
        changeAdmin: ""
 
    })
    const [role, setRole] = useState("")
 
    useEffect(() => {
        if (adminChanged) {
            history.push({
                pathname: `/`,
            });
        }
    }, [adminChanged]);
 
    useEffect(() => {
        // getTeam()
        getOrganizationUsers()
        getOrgUserRoles()
    }, []);
 
    const getOrgUserRoles = async () => {
        let payload = {
            id: sessionStorage.getItem("organizationId")
        }
 
        try {
            let orgUserRoleResponse = await getOrgUserRole(payload);
            console.log("orgUserRoleResponse", orgUserRoleResponse)
        } catch {
            console.log("catch_")
        }
    }
 
    const getOrganizationUsers = async () => {
        let currentUserEmail = sessionStorage.getItem("currentUserEmail")
        try {
            const response = await adminServices.getAllOrganizationUsers(currentUserEmail);
            // const response2 = await adminServices.getAllUsers2()
            console.log("organizationUsers", response)
            if (response.status === 200) {
                setTeamMembers(response.data);
                setFilterTeam(response.data);
                setLoading(false);
            } else if (response.status === 404) {
                setLoading(false);
                setTeamMembers(response.data);
                setFilterTeam(response.data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    }
 
    //  Get All Team Members
    const getTeam = async () => {
        try {
            const teamMembers = await userService.getTeam();
            if (teamMembers.status === 200) {
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
                setLoading(false);
            } else if (teamMembers.status === 404) {
                setLoading(false);
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    };
 
    const handleCheckFn = async (data) => {
        // console.dir(data);
    };
 
 
 
    // Delete Member
    // const handleDelete = async (user) => {
    //     setLoading(true);
 
    //     try {
    //         const team = await userService.deleteTeam({ id: user.id });
    //         if (team.status !== 200) {
    //             toast.error(team.message);
    //         } else {
    //             toast.success(team.message);
    //             getTeam();
    //         }
    //     } catch (error) {
    //         console.error("Error deleting user:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
 
    const handleSearch = (e) => {
        if (e.target.value === "") {
            setFilterTeam(teamMembers);
        } else {
            const value = e.target.value.toLowerCase();
 
            const result = teamMembers.filter((member) =>
                member.name.toLowerCase().includes(value)
            );
 
            setFilterTeam(result);
        }
    };
 
 
 
    const rowData = [
        {
            "_id": "123",
            "name": "",
            "description": " ",
            "frontendurl": " ",
            "apiurl": " ",
            "module": " ",
            "subModule": " ",
            "objectType": " ",
            "createdDate": " ",
            "parentObject": " ",
            "parentObject": " ",
            "parentObjectType": " ",
            "childObject": " ",
            "childObjectType": " ",
            "objectType": " ",
            "createdBy": " ",
            "createdDate": " "
        },
    ]
 
    const handleRole = (e) => {
        console.log("eData_", e)
        setRole(e.target.value)
    }
 
    const getAppFeature = async () => {
        try {
 
            const response = await getfeaturelist()
            if (response.status === 200) {
                console.log("newResponse2", response)
                setFeaturesRowdata(response.checkfeature)
            } else {
                console.error("Error fetching details:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };
    useEffect(() => {
        getAppFeature()
    }, [])
 
    const updateAppFeature = async (updatedFeatureData) => {
        try {
            // Make a PUT request to update the feature with updatedFeatureData
            const response = await editfeaturelist(updatedFeatureData.id, updatedFeatureData);
 
            if (response.status === 200) {
                // Update the state or perform any necessary actions upon successful update
                console.log("Feature updated successfully:", response.data);
            } else {
                // Handle error responses
                console.error("Error updating feature:", response.message);
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error("Error calling backend API:", error.message);
        }
    };
 
    // Usage of updateAppFeature function
    // Assuming you have a useEffect hook to trigger the update operation
    useEffect(() => {
        // Example usage: Update feature data with ID 123
        const updatedFeatureData = {
            id: "65b7473b9af5b25d7892a575",
 
        };
 
        // Call the updateAppFeature function with the updated feature data
        updateAppFeature(updatedFeatureData);
    }, []);
 
 
 
 
 
 
 
    const PostAppFeature = async () => {
        try {
 
            const response = await postfeaturelist()
            if (response.status === 200) {
                console.log(response)
                // setMasterRowdata(response.checkfeature)
            } else {
                console.error("Error fetching details:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };
 
    useEffect(() => {
        postfeaturelist()
    }, [])
 
    const columns = [
        { field: 'name', headerName: 'Name', flex: 0.5 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'frontendurl', headerName: 'Frontend URL', flex: 1 },
        { field: 'apiurl', headerName: 'API URL', flex: 1 },
        { field: 'module', headerName: 'Module', flex: 0.5 },
        { field: 'submodule', headerName: 'Submodule', flex: 0.5 },
        { field: 'isEdit', headerName: 'isedit', flex: 0.5 },
        { field: 'isView', headerName: 'isview', flex: 0.5 },
 
 
        // {
        //     field: 'edit',
        //     headerName: 'Edit',
        //     renderCell: renderEditCell
        // },
        // {
        //     field: 'save',
        //     headerName: 'Save',
        //     renderCell: renderSaveCell
        // },
 
       
        // { field: 'parentObject', headerName: 'Parent Object', flex: 1 },
        // { field: 'parentObjectType', headerName: 'Parent Object Type', flex: 1 },
        // { field: 'childObject', headerName: 'Child Object', flex: 1 },
        // { field: 'childObjectType', headerName: 'Child Object Type', flex: 1 },
        // { field: 'objectType', headerName: 'Object Type', flex: 1 },
        // { field: 'createdBy', headerName: 'Created By', flex: 0.5 },
        // { field: 'createdDate', headerName: 'Created Date', flex: 0.5 },
 
 
        // {
        //     field: 'id', headerName: 'ID',
        //     width: 90,
        //     flex: 0.2,
        // },
 
 
        //dropdown removed //
        // {
        //     field: 'userRole',
        //     headerName: 'User Role',
        //     flex: 0.5,
        //     renderCell: (params) => {
        //         return (
        //             <div className="col-md-12">
        //              <SelectDropdownMui
 
        //                         optionsList={roleList}
        //                         selectLabel={"Role"}
        //                         onChange={handleRole}
        //                         value={role}
        //                         />
        //                     {/* /> */}
        //           </div>
        //         )
        //     }
        // },
 
 
 
        {
            field: 'delete',
            headerName: 'Delete',
            renderCell: (params) => {
                const handleDeleteClick = async (_id) => {
 
                    try {
                        const response = await deletefeaturelist(params.row._id);
                        if (response.status === 200) {
                            console.log('Item deleted successfully');
                            // Optionally update UI or show a notification
                            setFeaturesRowdata(masterFeaturesdata.filter(feature => feature._id !== params.row._id));
 
                        } else {
                            console.error('Failed to delete item:', response.error);
                            // Handle deletion failure, e.g., show an error message
                        }
                    } catch (error) {
                        console.error('Error deleting item:', error);
                        // Handle error if the deletion process fails unexpectedly
                    }
 
                    // try {
                    //     const response = await deletefeaturelist({ id: '65b7473b9af5b25d7892a575'});
                    //     if (response.status === 200) {
                    //         console.log('Item deleted successfully');
                    //         // Optionally update UI or show a notification
                    //     } else {
                    //         console.error('Failed to delete item:', response.error);
                    //         // Handle deletion failure, e.g., show an error message
                    //     }
                    // } catch (error) {
                    //     console.error('Error deleting item:', error);
                    //     // Handle error if the deletion process fails unexpectedly
                    // }
                };
 
 
               
                return (
                    <button
                        className="btn btn-md"
                        onClick={handleDeleteClick}
                    >
                        <DeleteRoundedIcon fontSize="small" />
                    </button>
                );
            }
        },
 
 
        // changeAdmin code Removed //
        // {
        //     field: 'changeAdmin',
        //     headerName: 'Change Admin',
        //     // flex: 0.1,
        //     renderCell: (params) => {
        //         // console.log("params", params)
        //         return (
        //             <button
        //                 className="btn btn-md"
        //                 onClick={() => changeAdmin(params)}
        //             // disabled={params.row.isApproved == true ? true : false}
        //             >
        //                 {<SwapHorizRoundedIcon fontSize="small" />}
        //             </button>
        //         )
        //     }
        // },
 
 
        {
            field: 'Save',
            headerName: 'Edit',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                const handleditClick = async (_id) => {
                   
                    try {
                        const response = await getEditDatalist({id:params.row._id});
                        if (response.status === 200) {
                            console.log('Item data fetch successfully',response);
                            seteditValues(response.checkfeature)
                            setIsUpdatemodalOpen(true);
 
                        } else {
                            console.error('Failed to fetch edit data:', response.error);
                            // Handle deletion failure, e.g., show an error message
                        }
                    } catch (error) {
                        console.error('Error deleting item:', error);
                        // Handle error if the deletion process fails unexpectedly
                    }
 
                    // try {
                    //     const response = await deletefeaturelist({ id: '65b7473b9af5b25d7892a575'});
                    //     if (response.status === 200) {
                    //         console.log('Item deleted successfully');
                    //         // Optionally update UI or show a notification
                    //     } else {
                    //         console.error('Failed to delete item:', response.error);
                    //         // Handle deletion failure, e.g., show an error message
                    //     }
                    // } catch (error) {
                    //     console.error('Error deleting item:', error);
                    //     // Handle error if the deletion process fails unexpectedly
                    // }
                };
                return (
                <>
                    <button
                        className="btn btn-md"
                        // onClick={openModal}
                        onClick={handleditClick}
                    >
                        {<SaveIcon fontSize="small" />}
                    </button>
                    {/* <BootStrapModal isOpen={isUpdatemodalOpen} isFeature={true} header={"Edit features"} buttonName={"Update"} handleClose={closeUpdateModal} editValues ={editValues} /> */}
                    <ModalUpdateMUI  open={isUpdatemodalOpen} close={closeUpdateModal}  editValues={editValues}/>
                </>
                )
            }
        },
 
 
 
 
 
 
 
 
 
 
 
        // {
        //     field: 'age',
        //     headerName: 'Subscription Plan',
        //     type: 'number',
        //     // width: 110,
        //     flex: 0.2,
 
        // },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     // width: 160,
        //     flex: 0.6,
        //     valueGetter: (params) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];
 
    // const deleteUser = (params) => {
    //     console.log("params", params)
    //     setCurrentRow(params.row)
    //     setDeleteModalShow(!deleteModalShow)
    // }
 
    // const deleteUserConfirm = async () => {
    //     let sendData = {
    //         email: currentRow.email,
    //         isDelete: true
    //     }
    //     setLoading(true)
    //     try {
    //         let response = await adminServices.subAdminDeleteUserDirectly(sendData)
    //         console.log("response", response)
    //         if (response.status == 200) {
    //             toast.success(response.message)
    //         }
    //         else {
    //             toast.info(response.message)
    //         }
    //     }
    //     catch (e) {
    //         console.log("error", e)
    //     }
    //     getOrganizationUsers()
    //     setDeleteModalShow(false)
    //     setLoading(false)
    // }
 
    const changeAdmin = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        setAdminModalShow(!adminModalShow)
    }
 
    const changeAdminConfirm = async () => {
        let sendData = {
            email: currentRow.email,
            isTransfer: true
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminTransferAdminRole(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
                setAdminChanged(true)
            }
            else {
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        setAdminModalShow(false)
        // getOrganizationUsers()
        setLoading(false)
    }
 
    return (
        <div>
            <Loader loading={loading} />
            <ToastContainer />

            
            <div className="container-fluid" style={{ background: "#f0f0f7" }}>
                {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
                <div className="row justify-content-end w-100">
                    {/* Title */}
 
 
                    {/* Search Bar */}
                    {/* <div className="col-md-8">
                        <input
                            className="form-control searchBar"
                            name="searchQuery"
                            onChange={handleSearch}
                            type="text"
                            placeholder="&#xF002; Search group or Team Member"
                            aria-label="Search"
                            style={{
                                fontFamily: "FontAwesome",
                                border: "none",
                                //   width: "60%",
                                //   float: "left",
                            }}
                        />
                    </div> */}
 
                    {/* Send Invite Button */}
                    {/* <div className="col-md-2">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-outline-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Invite
                            </button>
                        </OverlayTrigger>
                    </div> */}
                    <div className="col-md-2 justify-content-end">
                        {/* <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        > */}
                        {/* <button
                                className="btn btn-primary inviteMember"
                                style={{ width: "90%", border:"2px solid red" }}
                                onClick={openModal}
                                // disabled={true}
                            >
                                Add New Features
                            </button> */}
                        {/* </OverlayTrigger> */}
 
                    </div>
                    <ModalMUI open={modalOpen} onClose={() => setModalOpen(false)} />
                </div>
 
                <div className="row mt-2 w-100">
                    {/* {filterTeam.length === 0 ? ( */}
                    {rowData.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No User Records found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data._id}
                                    columns={columns}
                                    rows={masterFeaturesdata}
                                    checkboxSelection={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
 
            {/* <div className="container">
                <div className="row mt-5">
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No teams found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12 p-2">
                                <DataGridMui
                                    getRowId={(data) => data.id}
                                    columns={columns}
                                    rows={sampleData}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div> */}
 
            {/* Delete User Modal */}
            <Modal
                isOpen={deleteModalShow}
                toggle={() => setDeleteModalShow(!deleteModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                {/* <ModalHeader
                    toggle={() => setDeleteModalShow(!deleteModalShow)}
                >
 
                    Delete User
                </ModalHeader> */}
                {/* <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to delete <strong>{currentRow?.username}</strong>
                        </p>
 
                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary hvr-float-shadow buysub"
                                    onClick={deleteUserConfirm}
                                >
                                    Confirm
                                </button>
                            </div>
 
                        </div>
                    </div>
                </ModalBody> */}
            </Modal>
 
            <Modal
                isOpen={adminModalShow}
                toggle={() => setAdminModalShow(!adminModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setAdminModalShow(!adminModalShow)}
                >
 
                    Change Admin
                </ModalHeader>
                {/* <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to change <strong>{currentRow?.username}</strong> as Admin
                        </p>
 
                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary hvr-float-shadow buysub"
                                    onClick={changeAdminConfirm}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody> */}
            </Modal>
            
            {/* <AppFeatureRoleLists masterFeaturesdata ={masterFeaturesdata} /> */}
        </div>
    );
}
// new code//
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FeatureLists));
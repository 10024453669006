import {
    apiUrl,
    herokuUrl
  } from "./config.json";
  import http from "./httpService";
  let apiEndpoint = "";
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = apiUrl + "/prompt";
  } else {
    apiEndpoint = herokuUrl + "/prompt";
  }

  export async function getSectorKeys(data) {
    const response = await http.post(`${apiEndpoint}/getSectorKeys`, {data});
    return response;
  }
  export async function getPrompts(data) {
    const response = await http.post(`${apiEndpoint}/getPrompts`, {data});
    return response;
  }
  export async function updatePrompt(data) {
    const response = await http.post(`${apiEndpoint}/updatePrompt`, {data});
    return response;
  }

  export default {
    getSectorKeys,
    getPrompts,
    updatePrompt
  };
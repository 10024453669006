import React from "react";
import queryString from "query-string";
import ResetPass2 from "../ResetPass2";
const ResetPassPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("ForgotEmail", values.email);
  }
  return (
    <React.Fragment>
      <ResetPass2 />
    </React.Fragment>
  );
};

export default ResetPassPage;

import React, { useState, useEffect, useRef } from "react";
import VideoContext from "./VideoContext";
import Peer from "simple-peer";
import { message } from "antd";
import { socket } from '../components/ChatBox/Socket/Socket';
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { useLocation } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import ChatServices from "../services/ChatServices";
import Teams from "../components/ChatBox/assests/teams.mp3";

const VideoState = ({ children, currentUser, history }) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [chat, setChat] = useState([]);
  const [name, setName] = useState(currentUser?.username);
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [userName, setUserName] = useState("");
  const [otherUser, setOtherUser] = useState("");
  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState();
  const [msgRcv, setMsgRcv] = useState("");
  const [screenShare, setScreenShare] = useState(false);
  const [openVideoChat, setOpenVideoChat] = useState(false);
  const [callRejected, setCallRejected]= useState(false);
  const currentLocation = history.location;
  const [startStream , setStartStream] = useState(false);

  const [idToCall, setIdToCall] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audio] = useState(new Audio(Teams));

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const screenTrackRef = useRef();

  const openVideoChatTab = () => {
    setOpenVideoChat(true);
  };

  useEffect(() => {
    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name"));
    }
    socket.on("me", (id) =>{
    setMe(id);
      if (currentUser) {
        const response = ChatServices.saveSocketId(currentUser._id, id);
        if (response.status === 200) {
          console.log('Socket Saved');
        } else {
          console.error("Error Opening Chat:", response.message);
        }
      }
  });


    socket.on("endCall", () => {
      window.location.reload();
    });

    socket.on("updateUserMedia", ({ type, currentMediaStatus }) => {
      console.log("updateUserMedia",type,currentMediaStatus)
      if (currentMediaStatus !== null || currentMediaStatus.length !== 0) {
        switch (type) {
          case "video":
            setUserVdoStatus(currentMediaStatus);
            break;
          case "mic":
            setUserMicStatus(currentMediaStatus);
            break;
          default:
              setUserMicStatus(currentMediaStatus[0]);
              setUserVdoStatus(currentMediaStatus[1]);
            break;
        }
      }
    });

    socket.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    socket.on("msgRcv", ({ name, msg: value, sender }) => {
      setMsgRcv({ value, sender });
      setTimeout(() => {
        setMsgRcv({});
      }, 2000);
    });
  }, []);

  const answerCall = () => {
    setCallAccepted(true);
    setOtherUser(call.from);
    setStartStream(true);
    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("answerCall", {
        signal: data,
        to: call.from,
        userName: name,
        type: "both",
        myMediaStatus: [myMicStatus, myVdoStatus],
      });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        // Modern browsers support srcObject directly
        if ('srcObject' in userVideo.current) {
          userVideo.current.srcObject = currentStream;
        } else {
          // Fallback for older browsers
          userVideo.current.src = URL.createObjectURL(currentStream);
        }
        //  userVideo.current.play();
      } else {
        console.error("Video element not found.");
      }
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
    console.log(connectionRef.current);
    openVideoChatTab();
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    setOtherUser(id);
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        // Modern browsers support srcObject directly
        if ('srcObject' in userVideo.current) {
          userVideo.current.srcObject = currentStream;
        } else {
          // Fallback for older browsers
          userVideo.current.src = URL.createObjectURL(currentStream);
        }
        // userVideo.current.play();
      } else {
        console.error("Video element not found.");
      }
    });

    socket.on("callAccepted", ({ signal, userName }) => {
      setCallAccepted(true);
      setStartStream(true, 'Stream on other Side');
      setUserName(userName);
      peer.signal(signal);
      socket.emit("updateMyMedia", {
        type: "both",
        currentMediaStatus: [myMicStatus, myVdoStatus],
      });
    });

    connectionRef.current = peer;
  };


  const updateVideo = () => {
    setMyVdoStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "video",
        currentMediaStatus: !currentStatus,
      });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {  
    setMyMicStatus((currentStatus) => {
      console.log(currentStatus);
      socket.emit("updateMyMedia", {
        type: "mic",
        currentMediaStatus:!currentStatus
      });
      console.log("stream_before",stream,stream.getAudioTracks()[0].enabled)

      stream.getAudioTracks()[0].enabled = !currentStatus;
      // stream.getAudioTracks()[0].muted = !(stream.getAudioTracks()[0].muted);
      // stream.getAudioTracks().forEach(track => track.enabled = !track.enabled);

      console.log("stream_after",stream,!stream.getAudioTracks()[0].enabled)

      // peer.removeTrack(stream.getAudioTracks()[0], stream);
     
    //   stream.getTracks().forEach((track) => {
    //     if (track.kind === "mic") {
    //         track.enabled = !track.enabled;
    //     }
    // });
      return !currentStatus;
    });
  };


  
    //SCREEN SHARING 
    // const handleScreenSharing = () => {

    //   if(!myVdoStatus){
    //     message.error("Turn on your video to share the content", 2);
    //     return;
    //   }
    
    //   if (!screenShare) {
    //     navigator.mediaDevices
    //       .getDisplayMedia({ cursor: true })
    //       .then((currentStream) => {
    //         const screenTrack = currentStream.getTracks()[0];

  
    //           // replaceTrack (oldTrack, newTrack, oldStream);
    //           connectionRef.current.replaceTrack(
    //             connectionRef.current.streams[0]
    //               .getTracks()
    //               .find((track) => track.kind === 'video'),
    //             screenTrack,
    //             stream
    //           );
  
    //         // Listen click end
    //         screenTrack.onended = () => {
    //           connectionRef.current.replaceTrack(
    //               screenTrack,
    //               connectionRef.current.streams[0]
    //                 .getTracks()
    //                 .find((track) => track.kind === 'video'),
    //               stream
    //             );

    //           myVideo.current.srcObject = stream;
    //           setScreenShare(false);
    //         };
  
    //         myVideo.current.srcObject = currentStream;
    //         screenTrackRef.current = screenTrack;
    //         setScreenShare(true);
    //       }).catch((error) => {
    //         console.log("No stream for sharing")
    //       });
    //   } else {
    //     screenTrackRef.current.onended();
    //   }
    // };

     //full screen
     
     
     const fullScreen = (e) => {
      const elem = e.target;
  
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    };
    
  const leaveCall = () => {
    setCallEnded(true);
    const peer = new Peer({ initiator: false, trickle: false, stream });
    peer.removeAllListeners();
    peer._destroy();
    connectionRef.current.destroy();
    socket.emit("endCall", { id: otherUser });
    setOpenVideoChat(false);
  };

  const leaveCall1 = () => {
    socket.emit("endCall", { id: otherUser });
    setOpenVideoChat(false);
  };


  const sendMsg = (value) => {
    socket.emit("msgUser", { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = "sent";
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  return (
    <VideoContext.Provider
      value={{
        call,
        callAccepted,
        userVideo,
        stream,
        name,
        myVideo,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        sendMsg,
        msgRcv,
        chat,
        setChat,
        setMsgRcv,
        setOtherUser,
        leaveCall1,
        userName,
        myVdoStatus,
        setMyVdoStatus,
        userVdoStatus,
        setUserVdoStatus,
        updateVideo,
        myMicStatus,
        userMicStatus,
        updateMic,
        screenShare,
        fullScreen,
        isModalVisible,
        setIsModalVisible,
        audio,
        idToCall, 
        setIdToCall,
        setStream,
        setCallAccepted,
        openVideoChat, 
        setOpenVideoChat,
        callRejected, setCallRejected,
        startStream, setStartStream
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoState));
import React from "react";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import Settings from "../settings";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import authService from "../../services/authService";
import SubAdminNavBar from "../SubAdminSideBar";
import SettingsNew from "../settingsNew";

import SubAdminSideBar from "../SubAdminSideBar";
import AdminSideNavBar from "../AdminSideNavBar";

const AccountSettingsPage = props => {
  const user = authService.getCurrentUser();
  let userData = {};
  let manageUser = false;
  if (props.location.state) {
    userData = props.history.location.state.userData;
    console.log("userData", userData);
    manageUser = true;
  }
  if (!user.isAdmin && !user.isSubAdmin) {
    return (
      <React.Fragment>
        <SubAdminSideBar>
          {/* <Settings /> */}
          <SettingsNew />
        </SubAdminSideBar>
      </React.Fragment>
    );
  } else if(user.isAdmin && !user.isSubAdmin) {
    return (
      <React.Fragment>
        <AdminSideNavBar>
          {/* <Settings data={userData} manageUser={manageUser} /> */}
          <SettingsNew data={userData} manageUser={manageUser} />
        </AdminSideNavBar>
      </React.Fragment>
    );
  } else {
    return (
    <React.Fragment>
      <SubAdminSideBar>
        {/* <Settings  data={userData} manageUser={true}/> */}
        <SettingsNew  data={userData} manageUser={true}/>
      </SubAdminSideBar>
    </React.Fragment>
     );
  }
};

export default NetworkDetector(AccountSettingsPage);

// import React, { useState, useEffect } from "react";
// import {
//   Modal,
//   Typography,
//   Box,
//   Button,
//   IconButton,
// } from "@material-ui/core";
// import Select from "react-select";
// import ReactSelect from "react-select";
// import CloseIcon from "@mui/icons-material/Close";

// export const EditTemplateModal = (props) => {
//   const {
//     open,
//     close,
//     sectorData,
//     selectedSector,
//     handleSectorFieldChange,
//     sectionData,
//     handleSelectFieldChange,
//     selectedSection,
//     mandatorySections,
//     manualSections,
//     createSections
//   } = props;

//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   // Update windowWidth on resize
//   useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);

//     window.addEventListener('resize', handleResize);

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "white",
//     padding: "20px",
//     borderRadius: "10px",
//     outline: "none",
//     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//     width: "55%",
//     overflowY: "auto",
//   };

//   const customStyles1 = {
//     multiValue: (provided) => ({
//       ...provided,
//       backgroundColor: "#1976d2",
//       color: "white",
//     }),
//     multiValueLabel: (provided) => ({
//       ...provided,
//       color: "white",
//     }),
//     multiValueRemove: (provided, state) => ({
//       ...provided,
//       color: state.isDisabled ? "#ccc" : "white",
//       ":hover": {
//         backgroundColor: state.isDisabled ? "transparent" : "#145a86",
//         color: state.isDisabled ? "#ccc" : "white",
//       },
//     }),
//     control: (provided) => ({
//       ...provided,
//       width: "300px",
//       height: "50px",
//       overflowY: "auto",
//     }),
//     menu: (provided) => ({
//       ...provided,
//       zIndex: 9999,
//     }),
//   };

//   // Combine mandatorySections and sectionData for the select dropdown
//   const combinedSections = sectionData?.map((section) => {
//     const isMandatory = mandatorySections?.some(
//       (mandatory) => mandatory.title === section.label
//     );
//     return {
//       ...section,
//       isDisabled: isMandatory, // Disable mandatory options
//     };
//   });

//   // Preselect mandatory sections in the AI section dropdown
//   const preselectedSections = combinedSections?.filter((section) =>
//     mandatorySections?.some((mandatory) => mandatory.title === section.label)
//   );

//   return (
//     <>
//       <Modal open={open} onClose={close}>
//         <div 
//         // sx={style}
//         style={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           backgroundColor: "white",
//           padding: "20px",
//           borderRadius: "10px",
//           outline: "none",
//           boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//           width: "55%",
//           overflowY: "auto",
//         }}
//         >
//           <div>
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 padding: "1%",
//               }}
//             >
//               <Typography
//                 variant="h5"
//                 component="div"
//                 style={{ color: "#1976D2" }}
//               >
//                 Add Template
//               </Typography>
//               <IconButton className="modal_closeIcon" onClick={close}>
//                 <CloseIcon className="modal_closeIcon" />
//               </IconButton>
//             </div>

//             <div style={{ width: "100%", padding: "1%" }}>
//               <div
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <ReactSelect
//                   options={sectorData}
//                   value={selectedSector}
//                   onChange={handleSectorFieldChange}
//                   // styles={customStyles1}
//                   placeholder="Select Sector"
//                 />
//                 <Select
//                   isMulti
//                   options={combinedSections}
//                   value={preselectedSections?.concat(selectedSection)}
//                   onChange={handleSelectFieldChange}
//                   // styles={customStyles1}
//                   placeholder="AI Sections"
//                   isOptionDisabled={(option) => option.isDisabled} // Disable mandatory sections from being deselected
//                 />
//                 <button
//                   style={{ padding: "2%", height: "50px",backgroundColor:"#1976D2",color:"white"}}
//                   onClick={createSections}
//                 >
//                   Create Sections
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div>
//             <div
//               style={{
//                 width: "100%",
//                 padding: "1%",
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 height: "400px",
//               }}
//             >
//               <div
//                 style={{
//                   width: "100%",
//                   padding: "1%",
//                   height: "40%",
//                   border: "1px solid #ccc",
//                   borderRadius: "10px",
//                   maxHeight: "40%", // Set a max height to limit the div's height
//                   overflowY: "auto",
//                 }}
//               >
//                 <h3 style={{fontWeight:"bold"}}>
//                   Mandatory Section
//                 </h3>
//                 {mandatorySections &&
//                   mandatorySections.map((section) => (
//                     <div key={section._id} style={{marginTop:"3px"}}>
//                       <p style={{fontSize:"17px",color:"lightgray",marginBottom:"3px"}}>{section.title}</p>
//                     </div>
//                   ))}
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                   padding: "1%",
//                   height: "58%",
//                   border: "1px solid #ccc",
//                   borderRadius: "10px",
//                   maxHeight: "58%", // Set a max height to limit the div's height
//                   overflowY: "auto",
//                 }}
//               >
//                 <h3 style={{fontWeight:"bold"}}>
//                   Manual Section
//                 </h3>
//                 {manualSections &&
//                   manualSections.map((section) => (
//                     <div key={section._id} style={{marginTop:"3px"}}>
//                       <p style={{fontSize:"17px",color:"#1976D2",marginBottom:"3px"}}>{section.title}</p>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };




import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  IconButton,
} from "@material-ui/core";
import Select from "react-select";
import ReactSelect from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "react-bootstrap";

export const EditTemplateModal = (props) => {
  const {
    open,
    close,
    sectorData,
    selectedSector,
    handleSectorFieldChange,
    sectionData,
    handleSelectFieldChange,
    selectedSection,
    mandatorySections,
    manualSections,
    createSections
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    outline: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width: "55%",
    overflowY: "auto",
  };

  const dropdownWidth = windowWidth < 1200 ? "100%" : "300px";

  const customStyles1 = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#1976d2",
      color: "white",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#ccc" : "white",
      ":hover": {
        backgroundColor: state.isDisabled ? "transparent" : "#145a86",
        color: state.isDisabled ? "#ccc" : "white",
      },
    }),
    control: (provided) => ({
      ...provided,
      width: dropdownWidth,
      height: "50px",
      overflowY: "auto",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  // Combine mandatorySections and sectionData for the select dropdown
  const combinedSections = sectionData?.map((section) => {
    const isMandatory = mandatorySections?.some(
      (mandatory) => mandatory.title === section.label
    );
    return {
      ...section,
      isDisabled: isMandatory, // Disable mandatory options
    };
  });

  // Preselect mandatory sections in the AI section dropdown
  const preselectedSections = combinedSections?.filter((section) =>
    mandatorySections?.some((mandatory) => mandatory.title === section.label)
  );

  return (
    <>
      <Modal open={open} onClose={close}>
        <div 
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "60%",
            height:"600px",
            overflowY: "auto",
          }}
        >
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "1%",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                style={{ color: "#1976D2" }}
              >
                Add Template
              </Typography>
              <IconButton className="modal_closeIcon" onClick={close}>
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <div style={{ width: "100%", padding: "1%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth < 1200 ? "column" : "row",
                }}
              >
                <ReactSelect
                  options={sectorData}
                  value={selectedSector}
                  onChange={handleSectorFieldChange}
                  styles={customStyles1}
                  placeholder="Select Sector"
                />
                <Select
                  isMulti
                  options={combinedSections}
                  value={preselectedSections?.concat(selectedSection)}
                  onChange={handleSelectFieldChange}
                  styles={customStyles1}
                  placeholder="AI Sections"
                  isOptionDisabled={(option) => option.isDisabled} // Disable mandatory sections from being deselected
                />
                <Button
                variant="outlined"
                color="primary"
                  style={{ 
                    padding: "2%", 
                    height: "50px", 
                    backgroundColor: "#1976D2", 
                    color: "white",
                    width: windowWidth < 1200 ? "100%" : "auto",
                    marginTop: windowWidth < 1200 ? "10px" : "0",
                  }}
                  onClick={createSections}
                >
                  Create Sections
                </Button>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                width: "100%",
                padding: "1%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "400px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "1%",
                  height: "40%",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  maxHeight: "40%", // Set a max height to limit the div's height
                  overflowY: "auto",
                }}
              >
                <h3 style={{fontWeight:"bold"}}>
                  Mandatory Section
                </h3>
                {mandatorySections &&
                  mandatorySections.map((section) => (
                    <div key={section._id} style={{marginTop:"3px"}}>
                      <p style={{fontSize:"17px",color:"lightgray",marginBottom:"3px"}}>{section.title}</p>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "1%",
                  height: "58%",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  maxHeight: "58%", // Set a max height to limit the div's height
                  overflowY: "auto",
                }}
              >
                <h3 style={{fontWeight:"bold"}}>
                  Manual Section
                </h3>
                {manualSections &&
                  manualSections.map((section) => (
                    <div key={section._id} style={{marginTop:"3px"}}>
                      <p style={{fontSize:"17px",color:"#1976D2",marginBottom:"3px"}}>{section.title}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Radio from "@material-ui/core/Radio";
import InputBoxOne from "./FormComponents/InputBoxOne";
import InputBoxTwo from "./FormComponents/InputBoxTwo";
import HeadOne from "./FormComponents/HeadOne";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormButtons from "./FormComponents/FormButtons";
import deletes from "../../static/delete.png";
import plus from "../../static/plus.png";
import InputBoxThree from "./FormComponents/InputBoxThree";
import InputFeedback from "./FormComponents/InputFeedBack";
import { citationStructure } from "../../utils/citationWithRandomNumber";
import {
  savecitations,
  getStyle,
  updateCitations,
} from "../../services/citationService";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Mandatory"),

  author: Yup.array().of(
    Yup.object().shape({
      given: Yup.string().required("Mandatory"),
      family: Yup.string().required("Mandatory"),
    })
  ),
  year: Yup.number().required("Mandatory").positive(),

  // issued: Yup.object({
  //   "date-parts": Yup.number().required("Required Field").positive(),
  // }),
});

let value = {};
function SpeechForm({ history, initialValues, projectID }) {
  const [loading, setLoading] = useState(false);

  if (initialValues) {
    value = initialValues;
  } else {
    value = {
      title: "",
      author: [
        {
          title: "Author",
          given: "",
          middlename: "",
          family: "",
          suffix: "",
        },
      ],
      event: "",
      eventplace: "",
      year: "",
      issued: {
        "date-parts": [["", "", ""]],
      },
      note: "",
      type: "speech",
    };
  }
  return (
    <div>
      <div
        className="container bg-white rounded shadow"
        // style={{ height: "1250px" }}
      >
        <div className="row pt-5">
          <HeadOne />
        </div>
        <ToastContainer />
        <Formik
          initialValues={value}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            console.log(values);
            let response = "";
            if (!values._id) {
              response = await savecitations(values, projectID);
            } else {
              response = await updateCitations({
                _id: values._id,
                data: values,
              });
            }
            if (response.status !== 200) {
              toast.error(response.message);
            } else {
              toast.info(response.message);
              // history.push('/citationList')
              history.push({
                pathname: `/citationList`,
                state: { projectId: projectID },
              });
              resetForm({});
            }
            setLoading(false);
          }}
          render={({ values, errors, touched, resetForm }) => (
            <Form>
              <Loader loading={loading} />

              <div>
                <div className="row mt-5" style={{ marginLeft: "142px" }}>
                  <h3>What I'm citing</h3>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: "142px", marginTop: "20px" }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      lineHeight: "19px",
                    }}
                  >
                    Speech title
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "142px" }}>
                  <Field name={`title`} as={InputBoxOne} />
                  {errors.title && touched.title ? (
                    <div>{errors.title}</div>
                  ) : null}
                </div>
              </div>
              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              <FieldArray
                name="author"
                render={(arrayHelpers) => (
                  <div>
                    <div style={{ marginLeft: "140px", marginTop: "10px" }}>
                      <h3>Authors</h3>
                    </div>
                    {values.author && values.author.length > 0
                      ? values.author.map((author, index) => (
                          <div
                            key={index}
                            style={{ marginLeft: "150px", marginTop: "10px" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "20px", marginLeft: "-10px" }}
                            >
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Article title</h6>
                                </div>
                                <div className="row">
                                  <Field
                                    name={`author[${index}].title`}
                                    as="select"
                                    placeholder="title"
                                  >
                                    <option value="Author">Author</option>
                                    <option value="Editor">Editor</option>
                                    <option value="Translator">
                                      Translator
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="row">
                                  <h6>First Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].given`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].given"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>MI / Middle</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].middlename`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Last Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].family`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].family"
                                  />
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="row">
                                  <h6>suffix</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].suffix`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              {values.author && values.author.length == 1 ? (
                                ""
                              ) : (
                                <div className="col-md-1">
                                  <div className="row">
                                    <img
                                      alt="delete"
                                      src={deletes}
                                      style={{
                                        height: "20px",
                                        width: "15px",
                                        marginLeft: "8px",
                                        marginTop: "30%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-md-2"></div>
                            </div>
                          </div>
                        ))
                      : ""}
                    <div
                      className="row"
                      style={{ marginTop: "20px", marginLeft: "125px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outlined-primary"
                        style={{ color: "#1976d2" }}
                        onClick={() =>
                          arrayHelpers.push({
                            title: "Author",
                            given: "",
                            family: "",
                            suffix: "",
                            middle: "",
                          })
                        }
                      >
                        <img src={plus} className="ft_sm mr-2" alt="plus" />
                        Add another Author
                      </button>
                    </div>
                  </div>
                )}
              />

              {/* author */}

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* Speech publication info */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>Speech publication info</h3>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-2%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                      }}
                    >
                      Event title
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <Field name={`event`} as={InputBoxTwo} />
                      {errors.event && touched.event ? (
                        <div>{errors.event}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-3%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                      }}
                    >
                      City
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <Field name={`eventplace`} as={InputBoxTwo} />

                      {errors.eventplace && touched.eventplace ? (
                        <div>{errors.eventplace}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Date viewed
                  </h6>
                </div>

                <FieldArray
                  name="issued"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        {values.issued["date-parts"][0].map((date, index) => (
                          <div className="col-md-2">
                            <div className="row" style={{ marginLeft: "-5px" }}>
                              <h6>
                                {index == 0
                                  ? "Year"
                                  : index == 1
                                  ? "Month"
                                  : "Day"}
                              </h6>
                              <br />
                              <Field
                                name={
                                  index == 0
                                    ? "year"
                                    : `issued["date-parts"][0][${index}]`
                                }
                                as={index === 0 ? InputBoxOne : InputBoxTwo}
                              />
                              {index == 0 ? (
                                <ErrorMessage as={InputFeedback} name="year" />
                              ) : (
                                ""
                              )}
                              {/* <ErrorMessage as={InputFeedback} name="issued" /> */}
                              {/* {errors.year && touched.year ? (
                                <div>
                                  {errors.year}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                />
              </div>

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* More Options */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>More Options</h3>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Annotation
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "-5px" }}>
                  <Field name={`note`} as={InputBoxThree} />
                </div>
              </div>

              {/* More Options */}

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}
export default withRouter(SpeechForm);

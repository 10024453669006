import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
    apiEndpoint = apiUrl + "/transitions";
} else {
    apiEndpoint = herokuUrl + "/transitions";
}


export async function getSpecificWorkFlow(workflowid) {
    let data = await httpService.post(`${apiEndpoint}/getworkflowsbyid`, { "workflowId": workflowid });
    console.log(data);
    return data;
}

export async function addnewtransition(data) {
    try {
        const response = await httpService.post(`${apiEndpoint}/addnewtransition`, {
            data
        });
        return response;
    } catch (error) {
        console.error("Error Adding workflow:", error.message);
        throw error;
    }
}

export async function updateTransitionName(data) {
    console.log("updateTransitionName", data)   
    const response = await httpService.put(`${apiEndpoint}/updateTransitionName`,{data});
    return response;
  }

 
  export async function Gettransitions(data) {
    let response = await httpService.post(`${apiEndpoint}/Gettransitions`,{data});
    return response
   
  }

  
export async function updateRolesToTransition(data) {
    console.log("updateRolesToTransition", data)   
    const response = await httpService.post(`${apiEndpoint}/updateRolesToTransition`,{data});
    return response;
  }





import React, { Component } from "react";
import Loader from "../utils/loader";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import "./Newsletter.css";
import adminServices from "../services/adminServices";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";

export class Newsletter extends Component {
  state = {
    loading: false,
    userData: [],
    mode: this.props.user.type,
    body: "",
    contentOnly: "",
    subject: ""
  };

  async componentDidMount() {
    const {
      user: { sendData: userData }
    } = this.props;
    this.setState({ userData, mode: this.props.user.type });
    console.log(this.state.mode);
  }

  handleSubject = e => {
    this.setState({ subject: e.target.value });
  };

  rteChange = (content, delta, source, editor) => {
    this.setState({
      contentOnly: editor.getText(),
      body: editor.getHTML()
    });
  };

  validateForm = () => {
    if (!this.state.body) {
      toast.warn("No Mail Body is Provided");
      return;
    }
    if (!this.state.subject) {
      toast.warn("Subject is empty");
      return;
    }
  };

  sendNotifications = async () => {
    const { body, userData, mode, subject, contentOnly } = this.state;
    const data = {
      sendTo: userData,
      subject,
      body
    };
    console.log(data);

    if (mode.toLowerCase() === "alert") {
      if (!subject) {
        toast.warn("Subject is empty");
        return;
      }
      if (!body) {
        toast.warn("No Mail Body is Provided");
        return;
      }
      this.setState({ loading: true });

      data["body"] = contentOnly;
      const AlertResponse = await adminServices.sendAlerts(data);
      if (AlertResponse.status !== 200) {
        toast.error(AlertResponse.message);
      } else {
        toast.success(AlertResponse.message);
        setTimeout(()=>{
          this._discard();
        },2000)
      }
    } else {
      if (!subject) {
        toast.warn("Subject is empty");
        return;
      }
      if (!body) {
        toast.warn("No Mail Body is Provided");
        return;
      }
      this.setState({ loading: true });

      const response = await adminServices.sendNewsletter(data);
      if (response.status !== 200) {
        toast.error(response.message);
      } else {
        toast.info(response.message);
        setTimeout(()=>{
          this._discard();
        },2000)
      }
    }
    this.setState({ body:"", userData:"", subject:"", contentOnly:"", loading: false });
  };

  _discard = async ()=> {
    this.props.history.push({
      pathname: `/manageuser`
    });
  }

  render() {
    const {mode}= this.state
    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />
        <div
          className=""
          style={{
            fontFamily: "Roboto",
            background: "#f0f0f7",
            paddingBottom: "1%"
          }}
        >
          {
              mode === "alert" ? (
              <div>
                <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/ManageUser"}>
                     ManageUsers
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Alerts
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <h3> Alerts </h3>
            </div>)  :
            (  <div>
              <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/ManageUser"}>
                   ManageUsers
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Newsletter
                </li>
              </ol>
            </nav>
          </div>
        </div>

          <h3> Newsletter </h3>
          </div>)

          }
          
          
          
          <div
            className="jumbotron pre"
            style={{
              height: "100vh",
              background: "white",
              border: "none"
            }}
          >
            {/* <hr /> */}
            <form name="form1" method="" action="" onsubmit={this.validateForm}>
              <div className="form-group">
                <div className="row">
                  {/* <label>To</label>
                            <input type="email" style={{width:"80%"}}></input> */}

                  <div className="col-md-2">
                    <label for="usr">Add Subject:</label>
                  </div>

                  <div className="col-md-10">
                    <input
                    autoComplete="off"
                      required
                      name="sub"
                      type="text"
                      className="form-control"
                      id="usr"
                      style={{ border: "5" }}
                      value={this.state.subject}
                      onChange={this.handleSubject}
                    />
                  </div>
                </div>

                <hr />
                <ReactQuill
                  required
                  theme="snow"
                  name="content"
                  autoComplete="off"
                  modules={this.modules}
                  formats={this.formats}
                  value={this.state.body}
                  onChange={this.rteChange}
                  ref={el => {
                    this.reactQuillRef = el;
                  }}
                />
              </div>

              <div
                className="row"
                style={{ marginTop: "5%", marginBottom: "5%" }}
              >
                <div className="col-md-4"></div>
                <div className="col-md-2">
                  {/* <button
                    className="btn send"
                    style={{
                      backgroundColor: "#1976d2",
                      border: "solid 1px #43425d",
                      // float: "right",
                      width: "100%"
                    }}
                    onClick={this.sendNotifications}
                    onSubmit={this.validateForm}
                  >
                    Send
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-outline-primary send"
                    style={{
                      width: "100%"
                    }}
                    onClick={this.sendNotifications}
                    onSubmit={this.validateForm}
                  >
                    Send
                  </button>
                </div>

                <div className="col-md-2">
                  {/* <button
                    className="btn discard"
                    style={{
                      backgroundColor: "#f0f0f7",
                      border: "solid 1px #43425d",
                      // float: "left",
                      width: "100%"
                    }}
                  >
                    Discard
                  </button> */}
                  <button
                    type="button"
                    onClick={this._discard}
                    className="btn btn-outline-primary send"
                    style={{
                      width: "100%"
                    }}
                  >
                    Discard
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>

             
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Newsletter);

import { documentActionTypes } from './document.types';
const INITIAL_STATE = {
    userDocuments: [],
    deletedDocuments: [],
    shareWithMeDocument: []
}

const documentReducer = (state = INITIAL_STATE, action) => {
    let returnvalue = {}
    switch (action.type) {
        case documentActionTypes.SET_USER_DOCUMENT:
            return {
                ...state,
                userDocuments: action.payload,
            }
        case documentActionTypes.SET_SHARED_TO_USER_DOCUMENT:
            return {
                ...state,
                shareWithMeDocument: action.payload,
            }
        case documentActionTypes.SET_USER_DELETED_DOCUMENT:
            return {
                ...state,
                deletedDocuments: action.payload,
            }
        case documentActionTypes.ADD_CREATED_DOCUMENT:
            let index;
            let updatedOrNewDocuments = state.userDocuments.filter((docs, pos) => {
                if (docs._id === action.payload._id) {
                    index = pos;
                    return docs;
                }
            });
            console.log("Redux",updatedOrNewDocuments);
            if (updatedOrNewDocuments.length > 0) {
                let documents = [...state.userDocuments];
                documents[index] = action.payload;
                returnvalue = {
                    ...state,
                    userDocuments: documents,
                }
            }
            else {
                returnvalue = {
                    ...state,
                    userDocuments: [action.payload,...state.userDocuments]
                }
            }
            return returnvalue
        case documentActionTypes.REMOVE_DELETED_DOCUMENT:
            let newUserDocumentState = state.userDocuments.filter(docs => docs._id !== action.payload._id);
            console.log(newUserDocumentState)
            if(newUserDocumentState.length > 0){
                returnvalue = {
                    ...state,
                    userDocuments : [...newUserDocumentState]
                }
            return returnvalue;
            }
        default:
            return state;
    }
}

export default documentReducer;
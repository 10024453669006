
import React from 'react';
import NetworkDetector from '../HigherOrderComponents/NetworkDetector';
import OppourtunityManagementTemplate from '../OppourtunityManagementTemplate';
import SubAdminSideBar from '../SubAdminSideBar';
export const OpportunityTemplatePage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <OppourtunityManagementTemplate />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(OpportunityTemplatePage);


import React from "react";
import ContactUs from "../ContactUs";
import SideNavBar from "../SideNavBar";
import SubAdminSideBar from "../SubAdminSideBar";
const ContactUsPages = props => {
 
  return (
    <SubAdminSideBar>
      <ContactUs />
    </SubAdminSideBar>
  );
};
export default ContactUsPages;
import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from 'crypto-js';
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { apiUrl, imgUrl } from "../services/config.json";
import "./LoginForm.css";
import { getHomeCMS } from "../services/adminServices";
import { showToast } from "../messages";
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import PetLogo from "../static/signup_login/PetLogo.png"
import ContactProLogo from "../static/signup_login/contactPrologo.PNG";
import googleSignin from "../static/signup_login/googleSignin.png"
import facebookSignin from "../static/signup_login/facebookSignin.png"
import tick from "../static/signup_login/Check.png"

import Login1 from "../static/login-3.png";
// import backgroudImage from "../static/signup_login/opportunity-background-image.webp"
import backgroudImage from "../static/signup_login/SignUp_background.jpg"
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import CheckboxMui from "./ReusableComponents/CheckBoxMui";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui";
import InputBoxMobile from "./ReusableComponents/InputBoxforMobile";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import OtpInputBox from "./ReusableComponents/OtpInputBox";
import { sendOtp, verifyOtp } from "../services/authService";
import { countries } from "./ReusableComponents/countryList";
import { useLocation } from "react-router-dom/cjs/react-router-dom";



const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    dense: {
        marginTop: 19,
    },
}));

function SignUp({ history }) {
    const location = useLocation(); 
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [values, setValues] = useState("");
    const [username, setUserName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [password, setPassword] = useState("");
    const [isTermsAccepted, setAcceptTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [disable, setdisable] = useState(false);
    const [disableButton, setdisableButton] = useState(true);
    const [showPassword] = useState(false);
    const [cms, setCms] = useState({});
    const [roleList, setRoleList] = useState([
        {
            "value": "User",
            "name": "User"
        },
        {
            "value": "Admin",
            "name": "Admin"
        }
    ]);
    const [role, setRole] = useState("")
    const [userRoleList, setUserRoleList] = useState([
        {
            "value": "Team Member",
            "name": "Team Member"
        },
        {
            "value": "Team Leader",
            "name": "Team Leader"
        },
        {
            "value": "Manager",
            "name": "Manager"
        },
    ])
    const [userRole, setUserRole] = useState("")
    const [region, setRegion] = useState("")
    const [countryList, setCountryList] = useState([])
    const [otpValue, setOtpValue] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [isOtpShown, setIsOtpShown] = useState(false)
    const [isOtpVerify, setIsOtpVerify] = useState(false)
    const [verifyDisable, setVerifyDisable] = useState(true)
    const [timer, setTimer] = useState(0);
    const [isResendCodeDisable, setisResendCodeDisable] = useState(true)
    const [isResendButton, setIsResendButton] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    // const [location, setLocation] = useState(useLocation());
    const [locationState, setLocationState] = useState(location.state); // Capture location state
    const selectedCountry = countries.find(country => country.phone === countryCode.slice(1));
    const countryName = selectedCountry ? selectedCountry.label : "";
    const [selectedCountryForEIN, setSelectedCountryForEIN] = useState(null);
    const [EIN, setEIN] = useState()
    const getCms = async () => {
        let cms = await getHomeCMS();
        setCms(cms.data);
        console.log(cms);
    };



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toastMessage = params.get('toast');
        if (toastMessage) {
            toast.error(toastMessage);
        }
    }, [location.search]);
    useEffect(() => {
        getCms();
    }, []);
  
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const orgId = params.get('organizationId');
        // const orgName = params.get('organizationName')
        if(orgId){
            setOrganizationId(orgId);
            setRole("User");    
        }
        // setOrganizationName(orgName);
    }, [location.search]);

    useEffect(() => {
        if (locationState && locationState.detail) {
            const { organizationId, organizationName } = locationState.detail;
            
            if(organizationId){
                setOrganizationId(organizationId);
                setRole("User");    
            }
            // setOrganizationName(organizationName);
        }
    }, [locationState]);

    
    async function handleRegisterSubmit() {
        if (!username) {
            showToast('signup.username')
            return;
        }
        if (!email) {
            showToast('signup.email')
            return;
        }
        if (role == "Organization") {
            // if (!region) {
            //     setLoading(false);
            //     toast.warn("Please enter Region");
            //     return
            // }
            if (!organizationName) {
                showToast('signup.nameEmpty')
                return;
            }
            if (!organizationId) {
                showToast('signup.idEmpty')
                return;
            }
            if (!userRole) {
                showToast('signup.roleEmpty')
                return;
            }
        }
        if (!password) {
            showToast('signup.password')
            return;
        }
        if (!mobileNo) {
            showToast('signup.mobileNo')
            return;
        }
        if (role === "Admin" && selectedCountryForEIN && selectedCountryForEIN.label === "United States") {
            if (!EIN) {
                showToast('signup.EIN')
                return;
            }
        }    
        if (!isTermsAccepted) {
            showToast('signup.terms')
            return;
        }
        if (password.length < 6) {
            showToast('signup.tooShort')
        } else {
            const hashedPassword = CryptoJS.SHA256(password).toString();
            let user = {
                username: username,
                email: email,
                // password: password,
                password: hashedPassword,
                phone: countryCode + mobileNo,
                country: countryName,
                // organizationId: organizationId,
                // organizationName: organizationName,
                referedEmail:
                    localStorage.getItem("referedEmail") !== "undefined" || null
                        ? localStorage.getItem("referedEmail")
                        : "",
                Role: role,
                organizationName: role == "Admin" ? organizationName : "",
                organizationId: role == "User" ? organizationId : "",
                EIN: role === "Admin" && selectedCountryForEIN && selectedCountryForEIN.label === "United States" ? EIN : ""
                // userRole: role == "Organization" ? userRole : "",
                // region: role == "Organization" && region,
            };
            console.log("user_", user);
            setLoading(true)
            const result = await userService.register(user);
            setLoading(false);
            console.log(result);
            if (result.status !== 200) {
                toast.warn(result.message);
            } else {
                localStorage.removeItem("referedEmail");
                localStorage.removeItem("accountType");
                toast.info(result.message);
                history.push({
                    pathname: `/afterRegistration`,
                    state: { detail: user },
                });
                // toast.info("Please check your email to verify");
            }
        }
    }
    function handleAcceptTerms(e) {
        console.log("value", e.target.checked);
        const isChecked = e.target.checked;
        setAcceptTerms(isChecked);
    }

    function handlePassword(e) {
        let pass = e.target.value;

        if (pass.length > 0) {
            if (pass.length <= 7) {
                setErrors("Password contains minimum 8 Characters");
                setIsPasswordValid(false);
            } else {
                setErrors("");

                var upperCaseLetters = /[A-Z]/g;
                var numbers = /[0-9]/g;

                if (!pass.match(upperCaseLetters)) {
                    setErrors("Password contains at least 1 Capital letter");
                    setIsPasswordValid(false);
                } else {
                    setErrors("");
                    if (!pass.match(numbers)) {
                        setErrors("Password contains at least 1 number");
                        setIsPasswordValid(false);
                    } else {
                        setErrors("");
                        setIsPasswordValid(true);
                        setPassword(pass);
                    }
                }
            }
        } else {
            setErrors("");
            setIsPasswordValid(true);
        }
    }
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRole = (e) => {
        console.log("eData_", e)
        setRole(e.target.value) 
    }

    const handleUserRole = (e) => {
        console.log("eData_", e)
        setUserRole(e.target.value)
    }

    const handleRegion = (e) => {
        console.log("handleRegion", e, region)
        setRegion(e.target.value)
    }

    const handleCountry = (event, value) => {
        console.log("params_", event, value)
        if (value.phone !== "" && mobileNo !== "") {
            setVerifyDisable(false)
            setIsOtpShown(false)
            setIsResendButton(false)
        } else {
            setVerifyDisable(true)
        }
        setCountryCode(`+${value.phone}`)
        if (role === "Admin" && value.label === "United States") {
            setSelectedCountryForEIN(value); 
        } else {
            setSelectedCountryForEIN(null); 
        }
    
    }

    const handleNumber = (e) => {
        console.log("eData_", e,e.length,countryCode)
        if(e.length > 10 && countryCode !=="+86"){
            return
        }
        if(e.length > 11){
            return
        }
        let isChina = e.length === 11 && countryCode == "+86"
        let isOtherCountries = e.length === 10 && countryCode !== "" && countryCode !== "+86"
        if(isChina || isOtherCountries){
            setVerifyDisable(false)
            setIsOtpShown(false)
            setIsResendButton(false)
            
        }
        // if (e !== "" && countryCode !== "") {
        //     setVerifyDisable(false)
        //     setIsOtpShown(false)
        //     setIsResendButton(false)

        // } 
        else {
            setVerifyDisable(true)

        }
        setMobileNo(e)
    }

    const handleOTP = async (e) => {
        console.log("otpdata_", e.length, e)
        setOtpValue(e)

        if (e.length == 6) {
            setLoading(true)
            let payload = {
                "phone": countryCode + mobileNo,
                "otp": e
            }
            let verifyOtpresponse = await verifyOtp(payload)
            console.log("verifyOtp_", verifyOtpresponse)
            if (verifyOtpresponse.status == 200) {
                setLoading(false)
                setIsOtpShown(false)
                setIsOtpVerify(true)
                setdisable(false)
                setdisableButton(false)
                setisResendCodeDisable(false)
                setIsResendButton(false)
            } else {
                setLoading(false)
                setIsOtpVerify(false)
                setIsOtpShown(true)
                setisResendCodeDisable(true)
                setIsResendButton(true)
            }
        }
    }

    // useEffect(()=>{
    //     if(role !=="" && username !== "" && email !== "" && mobileNo !== "" && password !== ""){
    //         setdisable(false)
    //     } else{
    //         setdisable(true)
    //     }
    // },[role,username,email,mobileNo,password])
    
    //OTP handling //
    const handleVerify = async () => {
        // alert("resendOTP")
        setOtpValue("")
        setTimer(60)
        // setVerifyDisable(true)
        setIsOtpVerify(false)
        // setIsOtpShown(false)
        
        if (!isPasswordValid) {
            showToast('signup.invalid')
            return;
        } 

        let payload = {
            // "email": email,
            "phone": countryCode + mobileNo
        }
        
        let sentresponse = await sendOtp(payload);
        console.log("sentresponse_", sentresponse.message)

        if (sentresponse.status == 200) {
            showToast('signup.otp')
            setIsResendButton(true)
            setIsOtpShown(true)
        } else {
            showToast('signup.invalid')
            return
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        if (timer == 0) {
            setisResendCodeDisable(false);
            setIsOtpShown(false)
        } else {
            setisResendCodeDisable(true);

        }
        return () => {
            clearTimeout(timerId);
        };
    }, [timer]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        fetchCountryList()
    }, [])

    const fetchCountryList = () => {
        let finalArr = []
        countries.map((obj) => {
            finalArr.push({
                name: obj.label,
                value: obj.label
            })
        })
        setCountryList(finalArr)
    }

    return (
        <div className="container-fluid"
            style={{
                backgroundImage: ` url(${backgroudImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "auto",
                minHeight: "-webkit-fill-available",
                position: "absolute",
                top: 0,
                left: 0
              
                // position: "relative"
            }}
        >
            <ToastContainer />
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2 col-md-12" style={{height:"3%"}}>
            
                    <Link to="/">
                        <img
                            className="petLogo"
                            alt="Group570"
                           
                            // src={PetLogo}
                            src={ContactProLogo}
                            style={{
                                left:"0",
                                width: "13%",
                                height:"100%",
                                objectFit: "cover",
                                opacity:"1.5"
                            }}
                        // src={
                        //     cms && cms.Navbar_logo
                        //         ? cms.Navbar_logo.Image
                        //         : `${imgUrl}/pet-website-static-images/Group+571.png`
                        // }
                        ></img>
                    </Link>
                </div>

                <div className="row justify-content-end"
                 style={{
                    display: "flex",
                    alignItems: "center",
                    height: "90vh",
                  }}
                >
                    {windowWidth > 450 ? <>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center text-center">
                    <Card
                        className="justify-content-center card-comp px-5 p-3"
                        // style={{
                        //     boxShadow: '0px 0px 10px 2px rgba(128, 128, 128, 0.5)',
                        // }}
                    >
                        <div className="text-center">
                            <h3 className="mainHeading">Signup</h3>
                        </div>
                        <div className="mt-3 py-1">
                            <SelectDropdownMui
                                optionsList={roleList}
                                selectLabel={"Role"}
                                onChange={handleRole}
                                value={role}
                                disabled={location.search.includes('organizationId=')}
                                />
                        </div>

                        {/* <div className="py-2">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Role"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div> */}

                        {role == "Admin" &&
                            <>
                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={countryList}
                                        selectLabel={"Region*"}
                                        onChange={handleRegion}
                                        value={region}
                                    />
                                </div> */}

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization*"
                                        // value={username}
                                        // value={organizationName}
                                        onChange={(e) => setOrganizationName(e.target.value)}
                                    // placeholder="Username"

                                    />
                                </div>
                                </>
                        }
                        {role == "User" &&
                        <>

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization ID*"
                                        // value={username}
                                        value={organizationId}
                                        onChange={(e) => setOrganizationId(e.target.value)}
                                        disabled={location.search.includes('organizationId=')}

                                    // placeholder="Username"
                                    />
                                </div>

                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={userRoleList}
                                        selectLabel={"User Role"}
                                        onChange={handleUserRole}
                                        value={userRole}
                                    />
                                </div> */}
                            </>
                        }

                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Username*"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div>



                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email*"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            // placeholder="Email"
                            />
                            {/* <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {emailerrors}
                            </p> */}
                        </div>


                        <div className="mt-2 py-1">
                            <InputBoxPasswordMui
                                fullWidth
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={(e) => handlePassword(e)}
                            // placeholder="Password"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {errors}
                            </p>
                        </div>
                            {/* OTP CODE */}
                        <div className="mt-2 py-1 row">
                           
                            <div className="col-md-9">
                                <InputBoxMobile
                                    onChange={(e) => handleNumber(e)}
                                    handleCountryCode={handleCountry}
                                    value={mobileNo}
                                />
                            </div>
                            <Button
                                variant="primary"
                                className="loginBtnVerify"
                                // disabled={disable}
                                disabled={verifyDisable}
                                onClick={handleVerify}
                            >
                                Verify
                            </Button>
                            {/* <div className=""> */}
                            {/* <Button
                                variant="primary"
                                className="loginBtnVerify"
                                // disabled={disable}
                                disabled={verifyDisable}
                                onClick={handleVerify}
                            >
                                Verify
                            </Button> */}
                            {/* </div> */}
                        </div>
                        {isOtpShown &&
                            <>
                                <div className="mt-2 d-flex justify-content-center">
                                    <OtpInputBox value={otpValue} onChange={(e) => handleOTP(e)} />
                                </div>
                                {(!isOtpVerify && otpValue?.length == 6) && <div>
                                    <p className="errorMsg mt-1">Please enter the correct OTP </p>
                                </div>
                                }
                            </>}
                                {/* OTP CODE */}
                        <div className="mt-2 d-flex">
                            {/* OTP CODE */}
                            {isResendButton && <Button className="otpresend p-0" onClick={handleVerify} disabled={isResendCodeDisable}>Resend Code</Button>}
                            {/* <a href="handleResend">Resend Code</a> */}
                            {isOtpShown && <p className="ml-5"> {isOtpShown && formatTime(timer)}</p>}
                        </div>

                        {(isOtpVerify) &&
                            <div className="d-flex mt-2">
                                <p className="verifyMsg">Phone Number Verified </p><img className="tickicon ml-2 mt-1" src={tick}></img>
                            </div>
                            
                        }

                        {/* OTP CODE */}
                        {selectedCountryForEIN && role === "Admin" && selectedCountryForEIN.label === "United States" &&
                                    <div className="mt-2 py-1">
                                        <InputBoxMui
                                            fullWidth
                                            type="text"
                                            label="Employee Identity Number (EIN)*"
                                            value={EIN}
                                            onChange={(e) => setEIN(e.target.value)}
                                        />
                                    </div>
                                }
                        <div className="">
                            <CheckboxMui
                                label="I agree with terms and conditions"
                                isChecked={isTermsAccepted}
                                onChange={(e) => handleAcceptTerms(e)}
                            />
                        </div>

                        <div className="text-center"
                        // style={{ marginTop: "2%" }}
                        >
                            <Button
                                variant="primary"
                                className="loginBtnRegister"
                                disabled={disableButton}
                                onClick={handleRegisterSubmit}
                            >
                                Sign up
                            </Button>
                        </div>

                        <div className="text-center mt-1">
                            <p
                                style={{
                                    // fontSize: "10px",
                                    fontSize: "0.7rem",
                                    lineHeight: "1.67",
                                    textAlign: "center",
                                    color: "#43425d",
                                }}
                            >
                                Already have an account?{" "}
                                <Link
                                    to="/login"
                                    className="signinLink"
                                    style={{ cursor: "pointer", fontSize: "0.7rem" }}
                                >
                                    Login
                                </Link>
                            </p>
                        </div>

                        {/* {role !== "Organization" &&
                            <>
                                <p className="hr-lines ">OR</p>
                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        href="https://api-pet.rapidqube.com/api/auth/google"
                                        // href={`${apiUrl}/auth/google`}
                                    >
                                        <img
                                            className="smImg"
                                            src={googleSignin}
                                        />
                                        <a
                                            href="https://api-pet.rapidqube.com/api/auth/google"
                                            // className="googlefblink"
                                        >Sign up with <strong>Google</strong></a>

                                    </Button>
                                </div>

                                <div className="mt-2 text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        // href="https://api-pet.rapidqube.com/api/auth/facebook"
                                        href={`${apiUrl}/auth/facebook`}
                                    >
                                        <img
                                            className="smImg"
                                            src={facebookSignin}
                                        />
                                        <a
                                            // href="https://www.professionaleditingtool.com/api/auth/facebook"
                                            // href="https://api-pet.rapidqube.com/api/auth/facebook"
                                            className="googlefblink"
                                        >Sign up with <strong>Facebook</strong></a>

                                    </Button>
                                </div>
                            </>
                        } */}
                        <div className="text-center mt-2">
                            <Link to="/privacypolicy" target={"_blank"} className="privacyLink">
                                Term of use. Privacy policy
                            </Link>
                        </div>

                    </Card>
                </div>
             </> : <>
             <div 
               style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                flex: "0 0 100%",
                maxWidth: "100%",
                // Styles for larger screen sizes
                "@media (min-width: 576px)": { flex: "0 0 100%", maxWidth: "100%" },
                "@media (min-width: 768px)": { flex: "0 0 50%", maxWidth: "50%" },
                "@media (min-width: 992px)": { flex: "0 0 25%", maxWidth: "25%" },
                "@media (min-width: 1200px)": { flex: "0 0 25%", maxWidth: "25%" },
                // Styles specifically for min-width: 300px
                "@media (min-width: 300px)": { width: "100%"},
              }}
             >
                    <Card
                        className="justify-content-center card-comp px-5 p-3"
                        style={{
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                            marginTop: "-20px",
                          }}
                    >
                        <div className="text-center">
                            <h3 className="mainHeading">Signup</h3>
                        </div>
                        <div className="mt-3 py-1">
                            <SelectDropdownMui
                                optionsList={roleList}
                                selectLabel={"Role"}
                                onChange={handleRole}
                                value={role}
                                disabled={location.search.includes('organizationId=')}
                                />
                        </div>

                        {/* <div className="py-2">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Role"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div> */}

                        {role == "Admin" &&
                            <>
                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={countryList}
                                        selectLabel={"Region*"}
                                        onChange={handleRegion}
                                        value={region}
                                    />
                                </div> */}

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization*"
                                        // value={username}
                                        // value={organizationName}
                                        onChange={(e) => setOrganizationName(e.target.value)}
                                    // placeholder="Username"

                                    />
                                </div>
                                </>
                        }
                        {role == "User" &&
                        <>

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization ID*"
                                        // value={username}
                                        value={organizationId}
                                        onChange={(e) => setOrganizationId(e.target.value)}
                                        disabled={location.search.includes('organizationId=')}

                                    // placeholder="Username"
                                    />
                                </div>

                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={userRoleList}
                                        selectLabel={"User Role"}
                                        onChange={handleUserRole}
                                        value={userRole}
                                    />
                                </div> */}
                            </>
                        }

                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Username*"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div>



                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email*"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            // placeholder="Email"
                            />
                            {/* <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {emailerrors}
                            </p> */}
                        </div>


                        <div className="mt-2 py-1">
                            <InputBoxPasswordMui
                                fullWidth
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={(e) => handlePassword(e)}
                            // placeholder="Password"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {errors}
                            </p>
                        </div>
                            {/* OTP CODE */}
                        <div className="mt-2 py-1 row" >
                           
                            <div className="col-md-12">
                                <InputBoxMobile
                                    onChange={(e) => handleNumber(e)}
                                    handleCountryCode={handleCountry}
                                    value={mobileNo}
                                />
                            </div>

                       
                        </div>
                        {/* {isOtpShown &&
                            <>
                                <div className="mt-2 d-flex justify-content-center">
                                    <OtpInputBox value={otpValue} onChange={(e) => handleOTP(e)} />
                                </div>
                                {(!isOtpVerify && otpValue?.length == 6) && <div>
                                    <p className="errorMsg mt-1">Please enter the correct OTP </p>
                                </div>
                                }
                            </>} */}
                                {/* OTP CODE */}
                  

                        {/* {(isOtpVerify) &&
                            <div className="d-flex mt-2">
                                <p className="verifyMsg">Phone Number Verified </p><img className="tickicon ml-2 mt-1" src={tick}></img>
                            </div>
                            
                        } */}

                        {/* OTP CODE */}
                        {selectedCountryForEIN && role === "Admin" && selectedCountryForEIN.label === "United States" &&
                                    <div className="mt-2 py-1">
                                        <InputBoxMui
                                            fullWidth
                                            type="text"
                                            label="Employee Identity Number (EIN)*"
                                            value={EIN}
                                            onChange={(e) => setEIN(e.target.value)}
                                        />
                                    </div>
                                }
                        <div className="" style={{width:"100%"}}>
                            <CheckboxMui
                                label="I agree with terms and conditions"
                                isChecked={isTermsAccepted}
                                onChange={(e) => handleAcceptTerms(e)}
                               
                            />
                        </div>

                        <div className="text-center"
                        style={{ width:"90%",margin:"auto" }}
                        >
                            <Button
                                variant="primary"
                                className="loginBtnRegister"
                                disabled={disable}
                                onClick={handleRegisterSubmit}
                                style={{width:"90%"}}
                            >
                                Sign up
                            </Button>
                        </div>

                        <div className="text-center mt-1">
                            <p
                                style={{
                                    // fontSize: "10px",
                                    fontSize: "0.7rem",
                                    lineHeight: "1.67",
                                    textAlign: "center",
                                    color: "#43425d",
                                }}
                            >
                                Already have an account?{" "}
                                <Link
                                    to="/login"
                                    className="signinLink"
                                    style={{ cursor: "pointer", fontSize: "0.7rem" }}
                                >
                                    Login
                                </Link>
                            </p>
                        </div>

                        {/* {role !== "Organization" &&
                            <>
                                <p className="hr-lines ">OR</p>
                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        href="https://api-pet.rapidqube.com/api/auth/google"
                                        // href={`${apiUrl}/auth/google`}
                                    >
                                        <img
                                            className="smImg"
                                            src={googleSignin}
                                        />
                                        <a
                                            href="https://api-pet.rapidqube.com/api/auth/google"
                                            // className="googlefblink"
                                        >Sign up with <strong>Google</strong></a>

                                    </Button>
                                </div>

                                <div className="mt-2 text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        // href="https://api-pet.rapidqube.com/api/auth/facebook"
                                        href={`${apiUrl}/auth/facebook`}
                                    >
                                        <img
                                            className="smImg"
                                            src={facebookSignin}
                                        />
                                        <a
                                            // href="https://www.professionaleditingtool.com/api/auth/facebook"
                                            // href="https://api-pet.rapidqube.com/api/auth/facebook"
                                            className="googlefblink"
                                        >Sign up with <strong>Facebook</strong></a>

                                    </Button>
                                </div>
                            </>
                        } */}
                        <div className="text-center mt-2">
                            <Link to="/privacypolicy" target={"_blank"} className="privacyLink">
                                Term of use. Privacy policy
                            </Link>
                        </div>

                    </Card>
                </div>
             </>
}
            </div >
        </div >
    );
}
export default withRouter(SignUp);

import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
  Box,
  FormControl,
  Paper,
  Grid,
} from "@material-ui/core";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  outline: "none",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  width: "70%",
  maxHeight: "90vh", // Ensure modal doesn't exceed viewport height
  overflowY: "auto", // Add scroll for overflow content
};

// Function to format the date to YYYY-MM-DD
const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const MetricsModal = (props) => {
  const {
    open,
    close,
    assetId,
    title,
    existingData,
    allExistingData,
    handleAdd,
    handleDelete,
    enableUpdateMetric,
    canEdit,
    updateMetrics,
  } = props;

  const submissionModeOptions = [
    { value: "Email", label: "By Email" },
    { value: "Electronic", label: "By Electronic" },
    { value: "PaperMail", label: "By Paper Mail" },
  ];

  const [dueDate, setDueDate] = useState("");
  const [formData, setFormData] = useState({
    proposalName: "",
    winningPercentage: "",
    lostPercentage: "",
    submissionDate: "",
    submissionMethod: null,
  });

  useEffect(() => {
    if (existingData) {
      setFormData({
        proposalName: existingData?.proposalName,
        winningPercentage: existingData?.winningPercentage || "",
        lostPercentage: existingData?.lostPercentage || "",
        submissionDate: formatDate(existingData?.submissionDate) || "",
        submissionMethod:
          submissionModeOptions.find(
            (option) => option.label === existingData?.modeOfSubmission
          ) || null,
      });
      setDueDate(new Date(existingData?.submissionDate)); // Set DatePicker value
    } else if (existingData == "") {
      setFormData({
        proposalName: title,
        winningPercentage: "",
        lostPercentage: "",
        submissionDate: "",
        submissionMethod: null,
      });
      setDueDate(null); // Reset DatePicker value
    }
  }, [existingData, title]);
// useEffect(() => {
  //   if (existingData) {
  //     setFormData({
  //       proposalName: existingData?.proposalName,
  //       winningPercentage: existingData?.winningPercentage || "",
  //       lostPercentage: existingData?.lostPercentage || "",
  //       submissionDate: formatDate(existingData?.submissionDate) || "",
  //       submissionMethod: submissionModeOptions.find(option => option.label === existingData?.modeOfSubmission) || null
  //     });
  //   } else if (existingData == "") {
  //     setFormData({
  //       proposalName: title,
  //       winningPercentage: "",
  //       lostPercentage: "",
  //       submissionDate: "",
  //       submissionMethod: null
  //     });
  //   }
  // }, [existingData, title]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, submissionMethod: selectedOption });
  };

  const handleDueDate = (date) => {
    if (date) {
      setDueDate(date);
      setFormData({
        ...formData,
        submissionDate: formatDate(date), // Update formData with formatted date
      });
    } else {
      setDueDate(null);
      setFormData({
        ...formData,
        submissionDate: "", // Clear the date in formData if no date selected
      });
    }
  };
//   const handleDueDate = (date)=>{
//     setDueDate(date)
// }
  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{fontSize:window.innerWidth<768?'12px':'18px', fontWeight:'bold'}}>Project Completion Metrics</p>
          <IconButton onClick={close}>
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Proposal Name"
              name="proposalName"
              value={formData?.proposalName}
              fullWidth
              margin="normal"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Winning Percentage"
              name="winningPercentage"
              type="number"
              value={formData.winningPercentage}
              onChange={handleChange}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: !existingData == "" && canEdit == false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Lost Percentage"
              type="number"
              name="lostPercentage"
              value={formData.lostPercentage}
              onChange={handleChange}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: !existingData == "" && canEdit == false,
              }}
            />
          </Grid>
           {/* <TextField
              id="date-picker"
              type="date"
              name="submissionDate"
              value={formatDate(formData.submissionDate)} // Convert to YYYY-MM-DD format for date input
              onChange={(e) => handleChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: !existingData == "" && canEdit == false,
                style: {
                  height: "30px",
                  boxShadow: "1px 1px 4px -2px #000000",
                  marginRight: "10px"
                },
              }}
            />
  */}


{/* <DatePicker
      selected={dueDate}
      onChange={(e) => handleDueDate(e)}
      dateFormat="yyyy-MM-dd"
      placeholderText="yyyy-mm-dd"
      customInput={
        <TextField
          label="Select Date"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          // style={{ width: 700}}
        /> }
        /> */}
          <Grid item xs={12} sm={6}>
            <DatePicker
              selected={dueDate}
              onChange={(date) => handleDueDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="yyyy-mm-dd"
              customInput={
                <TextField
                  label="Select Date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Select
                options={submissionModeOptions}
                value={formData.submissionMethod}
                onChange={handleSelectChange}
                placeholder="Select Mode Of Submission"
              />
            </FormControl>
          </Grid>
        </Grid>
        {!existingData && (
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAdd(formData);
            }}
            // style={{ display:"flex",justifyContent:"flex-end", marginTop:"10px" }}
          >
            Add
          </Button>
          </Box>
        )}
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no</TableCell>
                <TableCell>Proposal Name</TableCell>
                <TableCell>Winning %</TableCell>
                <TableCell>Lost %</TableCell>
                <TableCell>Submission Date</TableCell>
                <TableCell>Mode Of Submission</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allExistingData?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.proposalName}</TableCell>
                  <TableCell>{row.winningPercentage}</TableCell>
                  <TableCell>{row.lostPercentage}</TableCell>
                  <TableCell>
                    {moment(row.submissionDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell>{row.modeOfSubmission}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(row.assetId)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        enableUpdateMetric(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Button
              variant="contained"
              color="secondary"
              onClick={close}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateMetrics(formData);
              }}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
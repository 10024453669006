import React, { useState } from 'react';
import OtpInput from 'react-otp-input';

export default function OtpInputBox({onChange,value}) {
  const inputStyle = {
    width: "27px",
    height: "27px",
    border: "1px solid black",
    borderRadius: "1px",
    // fontSize: "0.8rem",
    textAlign: "center"
  }


  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={6}
      renderSeparator={<span>{"\u00A0\u00A0\u00A0"}</span>}
      inputStyle={inputStyle}
      renderInput={(props) => <input {...props} />}
    />
  );
}
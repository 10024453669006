import React, { Component } from 'react'
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Subadminsidebar from "../SubAdminSideBar"
import queryString from "query-string";
import FeatureLists from '../FeatureLists';
 
const FeaturelistPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
 
 
  }
    return (
      <Subadminsidebar>
        {/* <SubadminManageUser /> */}
        <FeatureLists/>
      </Subadminsidebar>
    );
  };
  export default NetworkDetector(FeaturelistPage);
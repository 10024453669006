import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import useRecorder from "./useRecorder";
import MicIcon from '@mui/icons-material/Mic';
import { Button } from "@mui/material";
import { ChatContext } from "../../../contexts/ChatContext";

const TimerController = (props) => {
    const [renderedStreamDuration, setRenderedStreamDuration] = useState(
        "00:00:00"
    ),
        streamDuration = useRef(0),
        previousTime = useRef(0),
        requestAnimationFrameId = useRef(null),
        [isStartTimer, setIsStartTimer] = useState(false),
        [isStopTimer, setIsStopTimer] = useState(false),
        [isPauseTimer, setIsPauseTimer] = useState(false),
        [isResumeTimer, setIsResumeTimer] = useState(false),
        isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
        isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer);

    const updateTimer = useCallback(() => {
        let now = performance.now();
        let dt = now - previousTime.current;

        if (dt >= 1000) {
            streamDuration.current = streamDuration.current + Math.round(dt / 1000);
            const formattedStreamDuration = new Date(streamDuration.current * 1000)
                .toISOString()
                .substr(11, 8);
            setRenderedStreamDuration(formattedStreamDuration);
            previousTime.current = now;
        }
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, []);

    const startTimer = useCallback(() => {
        previousTime.current = performance.now();
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, [updateTimer]);

    useEffect(() => {
        if (props.record === true) {
            startHandler();
        } else {
            stopHandler();
        }
        if (isStartTimer && !isStopTimer) {
            startTimer();
        }
        if (isStopTimer && !isStartTimer) {
            streamDuration.current = 0;
            cancelAnimationFrame(requestAnimationFrameId.current);
            setRenderedStreamDuration("00:00:00");
        }

        const cleanup = () => {
            cancelAnimationFrame(requestAnimationFrameId.current);
        };

        return cleanup;

    }, [isStartTimer, isStopTimer, startTimer, props.record]);

    const startHandler = () => {
        setIsStartTimer(true);
        setIsStopTimer(false);
    };

    const stopHandler = () => {
        setIsStopTimer(true);
        setIsStartTimer(false);
        setIsPauseTimer(false);
        setIsResumeTimer(false);
    };

    return (
        <div className="timer-controller-wrapper">{renderedStreamDuration}s</div>
    );
};

function AudioRecord({ setMessageType, setDisable }) {

    const [state, setState] = useState("");
    const [record, setRecord] = useState(false);
    const [play, setPlay] = useState(false);
    let { isRecording, startRecording, stopRecording } = useRecorder();
    const { audioURL , audios, setAudios } = useContext(ChatContext);

    function Buttonstart() {
        setState("red");
        setRecord(true);
        startRecording();
    }
    async function Buttonstop() {
        if (isRecording === true) {
            stopRecording();
            setState("#4695da");
            setRecord(false);
            setMessageType('audio');
            setDisable(true);
            setAudios([audioURL]);
        } else {
            alert("Give permission audio to record");
        }
    }


    return (
        <>
            {record ? (
                <Button
                    variant='contained'
                    sx={{
                        background: 'red',
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "center", 
                        cursor: 'pointer'
                    }}
                    onClick={Buttonstop}
                >
                    <span style={{ marginLeft: "20px" }}>
                        <TimerController record={record} />
                    </span>
                </Button>
            ) : (
                <MicIcon
                        onClick={Buttonstart}                  
                    sx={{ verticalAlign: 'middle', fontSize: '35px', color: 'black', cursor: 'pointer' }}
                />
            )}
        </>
    );
}

export default AudioRecord;

import { toast } from "react-toastify";

export const showToast = (messageKey) => {
    // Split the key by dots to handle nested keys
    const keys = messageKey.split('.');
    
    // Traverse the MESSAGES object to get the final message object
    let messageObject = MESSAGES;
    for (let key of keys) {
      messageObject = messageObject[key];
      
      // If the key is not found, stop and handle the error
      if (!messageObject) {
        console.error(`Message key '${messageKey}' not found`);
        return;
      }
    }
    
    // Display the toast with the appropriate type and message
    toast[messageObject.type](messageObject.text);
  };

export const MESSAGES = {
    Error: {
      somethingFailed: {type: 'error', text:"Something failed. Please try again."},
      errorOccured: {type: 'error', text:"Error Occured. Please try again."},
      upload: {type: 'error', text:"You are not allowed to upload."},
      edit: {type: 'error', text:"You are not allowed to edit."},
      access: {type: 'error', text:"You do not have access."},
      share: {type: 'error', text:"You are not allowed to share."},
      delete: {type: 'error', text:"You are not allowed to delete."},
      download: {type: 'error', text:"You are not allowed to download."},


      fileUpload: {type: 'error', text:"Error uploading file"}, 
      opportunityUpdate: {type: 'error', text:"Error in updating opportunity"}, 



      addProject: {type: 'error', text:"You are not allowed to add project."},
      selectWorkflow: {type: 'error', text:"Please select workflow to continue."},
      projectUnarchived: {type: 'error', text:"Error in unarchiving project."},
      orgIdReset: {type: 'error', text:"Failed to reset organization ID"},
      recordComparison: {type: 'error', text:"Insufficient records for comparison"},
    },


    Restriction: {
      addRole: {type: 'error', text:"You are not allowed to add role."},
      editRole: {type: 'error', text:"You are not allowed to edit role."},
      deleteRole: {type: 'error', text:"You are not allowed to delete role."},
      editFeature: {type: 'error', text:"You are not allowed to edit features."},


      openRfp: {type: 'error', text:"You do not have access to open RFP."},
      openProposal: {type: 'error', text:"You do not have access to open Proposal."},
      addManualOpportunity : {type: 'error', text:"You are not allowed to add manual opportunity."}
    },

    Warning:{
        selectDocDownload: {type: 'warn', text:"Select documents to download."},
        enterEmail: {type: 'warn', text:"Please enter the email."},
        selectEmail: {type: 'warn', text:"Please select email to grant access."},
        allFields: {type: 'warn', text:"Please fill all the fields."},
        enterMessage: {type: 'warn', text:"Please enter the message."},

        noPreview : {type: 'warn', text:"No preview available"},
        noFile:  {type: 'warn', text:"No file selected"},
        upload: {type: 'warn', text:"Please upload a file"},

        docUpload: {type: 'warn', text:"Please upload a DOCX file"},
        PdfUpload: {type: 'warn', text:"Please upload a PDF file"},
        imgUpload : {type: 'warn', text:"Please select a file with JPG, JPEG, or PNG format."},

        projectComplete: {type: 'warn', text:"This project has been completed"},
        projectNotFound: {type: 'warn', text:"Project not found"},
    },

    Success:{

        clauseAdded: {type: 'success', text:"Clause added successfully"},
        delete: {type: 'success', text:"Deleted successfully"},
        upload: {type: 'success', text:"Uploaded successfully"},
        update: {type: 'success', text:"Updated successfully"},
        add: {type: 'success', text:"Added successfully"},

        dataAdded: {type: 'success', text:"Data added successfully"},
        dataDeleted: {type: 'success', text:"Data deleted successfully"},
        dataUpdated: {type: 'success', text:"Data updated successfully"},
        dataSaved: {type: 'success', text:"Data saved successfully"},
        projectUnarchived: {type: 'success', text:"Project unarchived successfully"},


        fileUpload: {type: 'success', text:"File uploaded successfully"},
        fileDownload: {type: 'success', text:"File downloaded successfully"},
        fileUpdate: {type: 'success', text:"File updated successfully"},


        templateDeleted: {type: 'success', text:"Template deleted successfully"},
        resourceAllocated: {type: 'success', text:"Resource allocated successfully"},
        featureAdded: {type: 'success', text:"Features added successfully"},


        clearActivities:{type: 'success', text:"Successfully cleared all activities."},
        copyCreated: {type: 'success', text:"Copy created successfully."},
        allFilesDownload: {type: 'warn', text:"All files downloaded successfully"},



    },

    Organization:{
        reset: {type: 'success', text:"Organization ID reset successfully"},
        dataAdded: {type: 'success', text:"Organization data added successfully"},
        resetFailed: {type: 'error', text:"Failed to reset organization ID"},
        inviteUserNotAllowed : {type: 'error', text:"You are not allowed to invite user"},
        invitationsSent : {type: 'success', text:"Invitations sent successfully"},
        invitationsFailed: {type: 'error', text:"Failed to send invitations"},

        errUserExist : {type: 'error', text:"User(s) does not exist in organization"},
        createOrgNotAllowed : {type: 'error', text:"You are not allowed to create organization"},
        selectOneField: {type: 'warn', text:"Please select any one of the field"},

        
        deleteRequestSent: {type: 'info', text:"Delete Request has been sent to Organization Admin"},
        invalidGroup: {type: 'error', text:"Invalid group name"},


        accountActivate:{type: 'success', text:"Account activated successfully"},
        accountActivateFailed:{type: 'error', text:"Failed to activate account"},

        accountDeactivate:{type: 'success', text:"Account deactivated successfully"},
        accountDeactivateFailed:{type: 'error', text:"Failed to deactivate account"},

        accountDelete:{type: 'success', text:"User account deleted successfully"},
        accountDeleteFailed:{type: 'error', text:"Failed to delete user account"},

        noActiveProjects:{type: 'info', text:"No active projects available."},
        noAllocatedProjects:{type: 'info', text:"No allocated projects available."},
        noCancelledProjects:{type: 'info', text:"No cancelled projects available."},
        noCompletedProjects:{type: 'info', text:"No completed projects available."},


        selectDropdown: { type: 'error', text: "Please select dropdown" },
        selectUser: { type: 'error', text: "Please select any one of the user" },

        workflowFailed: { type: 'error', text: "Workflow activation failed. Please try again" },
        workflowActive: { type: 'success', text: "Default workflow is active" },

        deleteResourceDetails : { type: 'warn', text: "Please select a row to delete resource details." },
        editResourceDetails : { type: 'warn', text: "Please select a row to edit resource details." },

        addWorkflow: { type: 'error', text: "You are not allowed to add workflow." },
        deleteWorkflow: { type: 'error', text: "You are not allowed to delete workflow." },
        workflowInUse: { type: 'error', text: "This workflow is currently in use and cannot be deleted" },

        statusAdded : { type: 'success', text: "Status added successfully" },
        stageAdded : { type: 'success', text: "Stage added successfully" },
        stageDelete : { type: 'success', text: "Stage deleted successfully" },


        featureSave : { type: 'success', text: "Selected features saved successfully" },
        errFeatureSave : { type: 'error', text: "Error while saving selected features" },
        errFetchRoles : { type: 'error', text: "Error while fetching roles" },




    },

    Proposal:{
      fetchAsset: {type: 'error', text:"Error in fetching asset."},
      accessDenied: {type: 'error', text:"Access denied for this section."},
      accessEdit: {type: 'error', text:"You do not have access to edit"},
      pdfExtraction: {type: 'warn', text:"No response received from the PDF extraction."},
      oneDoc: {type: 'warn', text:"Please select atleast one document."},
      oneSec: {type: 'warn', text:"Please select atleast one section."},
      errKeywordData: {type: 'error', text:"Error in generating keyword data."},
      errExtractText: {type: 'error', text:"Error while extract text from file."},
      subsectionSuccess: {type: 'success', text:"Subsection added successfully."},
      subsectionDelete: {type: 'success', text:"Subsection deleted successfully."},
      errSavingFile: {type: 'error', text:"Error while saving the file content."},
      errSubsectionData: {type: 'error', text:"Error in getting subsection data."},
      proposalCompleted: {type: 'success', text:"Proposal is completed and ready for submission."},
      clausesNotFound: {type: 'warn', text:"Clauses not found."},
      proposalSubmit: {type: 'success', text:"Proposal submitted to next team for review."},
      proposalSubmit2: {type: 'success', text:"Proposal submitted successfully."},
      selectTeam: {type: 'error', text:"Please select a team."},
      sentBack: {type: 'success', text:"Proposal sent back."},
      errSentBack: {type: 'error', text:"Send back failed. Please try again."},
      errSendReply: {type: 'error', text:"Error in sending reply."},
      errDocLimit: {type: 'error', text:"Document selection limit exceeded."},
      noAttachments: {type: 'warn', text:"Attachments are not available."},
      taskReject: {type: 'success', text:"Task is rejected."},
      bothTaskApproved: {type: 'success', text:"Both tasks are approved."},
      taskApprove: {type: 'success', text:"Task is approved."},
      taskAccept : {type: 'success', text:"Task is accepted."},
      taskAcceptManually : {type: 'success', text:"Task accepted manually."},
      taskReview : {type: 'success', text:"Task reviewed successfully."},
      sectionNotProposal: {type: 'warn', text:"This section does not belong to proposal."},
      taskAssign: {type: 'error', text:"You are not allowed to assign task."},
      taskSubmit : {type: 'success', text:"Task submitted successfully."},
      sectionShare: {type: 'warn', text:"This section is not allowed to be shared."},
      enterComment: {type: 'warn', text:"Please enter a comment."},
      checkDeadline: {type: 'error', text:"Please check deadline."},
      errProposalSubmit: {type: 'error', text:"Error in submitting proposal."},
      taskComplete: {type: 'success', text:"Tasks marked complete successfully."},
      allTaskComplete: {type: 'error', text:"All tasks needs to be completed."},
      projectTerminate: {type: 'success', text:"Project terminated successfully."},
      fillAllSection: {type: 'warn', text:"Please fill all section content."},
      taskPending: {type: 'error', text:"Assigned task completion is pending."},
      selectPDF: {type: 'warn', text:"Please select PDF file."},
      sectionProcessed: {type: 'success', text:"Section processed successfully."},
      errDoc: {type: 'warn', text:"Error in document."},
      titleReq: {type: 'warn', text:"Title and description is required."},
      errSubsectionDelete: {type: 'error', text:"Error deleting subsection."},
      wordLimit: {type: 'warn', text:"Words limit exceeded."},
      noChangeDetected: {type: 'warn', text:"No change detected."},
      nothingDownload: {type: 'warn', text:"Nothing to download."},
      reasonAddClause : {type: 'warn', text:"Please provide reason to add clause."},
      reasonDeleteClause : {type: 'warn', text:"Please provide reason to delete clause."},
      checkSubsection : {type: 'warn', text:"Please check subsection detail."},
      clauseDelete: {type: 'success', text:"Clause deleted successfully."},
      provideClause: {type: 'warn', text:"Please provide clause to add."},
      clauseExist: {type: 'warn', text:"This clause already exist."},
      noClauseDownload: {type: 'error', text:"No clauses to download."},
      selectRow: {type: 'error', text:"Please select a row."},
      errSectionProcessed: {type: 'error', text:"Error occured while processing sections"},
      errSectionAdd: {type: 'error', text:"Error occured while adding sections"},
      taskAlreadyAssigned: {type: 'warn', text:"Task is already assigned to this user"},
      errTaskAssign: {type: 'error', text:"Error in assigning task"},
      rfpNotSent: {type: 'error', text:"RFP is not sent"},
      
    },

    signup: {
       
        username: { type: 'warn', text: "Please provide the username" },
        email: { type: 'warn', text: "Please provide the email" },
        nameEmpty: { type: 'warn', text: "Please enter Organization Name" },
        idEmpty: { type: 'warn', text: "Please enter Organization ID" },
        roleEmpty: { type: 'warn', text: "Please select User Role" },
        password: { type: 'warn', text: "Please enter the password" },
        tooShort: { type: 'warn', text: "Password must contain at least 6 characters" },
        mobileNo: { type: 'warn', text: "Please enter the Mobile Number" },
        invalid: { type: 'warn', text: "Invalid Phone Number" },
        EIN: { type: 'warn', text: "Please enter the EIN" },
        terms: { type: 'warn', text: "Please accept the Terms & Conditions" },
        otp: { type: 'success', text: "OTP Sent Successfully" },


        sessionExpired: { type: 'error', text: "Session expired! Please login" },
        emailVerification: { type: 'success', text: "Email verification is successful." },
      
  },


  chat:{

    chatArchive: { type: 'success', text: "Chat archived successfully" },
    chatUnarchive: { type: 'success', text: "Chat unarchived successfully" },

    chatDelete: { type: 'success', text: "Chat deleted successfully" },
    groupChatDelete: { type: 'success', text: "Group chat deleted successfully" },

    userRemoved: { type: 'success', text: "User removed from contacts successfully" },
    exitGroup: { type: 'success', text: "Exited group successfully" },

  },


  RFP:{
    downloadClause: {type: 'error', text:"No clauses to download."},
    downloadClauseAuth: {type: 'warn', text:"You are not allowed to download the clause."},
    rfpBidSuccessfully: {type: 'warn', text:"This RFP has been bid. No further changes can be made."},
    rfpBidRequested: {type: 'warn', text:"This RFP has been sent to management for bid request approval."},
    rfpBidRequestedFailed: {type: 'error', text:"Bid request failed. Please try again."},
    rfpBidRejected: {type: 'success', text:"This Bid has been Cancelled."},
    rfpBidRejecteError: {type: 'error', text:"No Bid failed. Please try again."},
    rfpBidRequesteFail: {type: 'error', text:"This RFP Bid failed. Please try again."},
    addSection: {type: 'warn', text:"You are not allowed to Add Section."},
    previewCheck: {type: 'error', text:"You are not allowed to preview."},
    previewUnavailable: {type: 'error', text:"Preview not available for .doc file."},
    downloadSection: {type: 'error', text:"You are not allowed to download a section"},
    deleteSectionAuth: {type: 'error', text:"You are not allowed to Delete Section"},
    deleteSection: {type: 'success', text:"Section deleted successfully"},
    assetSectionUpload: {type: 'success', text:"Asset section upload successfully"},
    assetCommentDelete: {type: 'success', text:"Asset comment deleted successfully"},
    assetCommentUpdate: {type: 'success', text:"Asset comment updated successfully"},
    addAssetCommentAuth: {type: 'error', text:"You are not allowed to Add Comment"},
    addNoteAuth: {type: 'error', text:"You are not allowed to add note"},
    swapAuth: {type: 'error', text:"You are not allowed to swap"},
    reorderAuth: {type: 'error', text:"You are not allowed to Reorder"},
    shareSectionAuth: {type: 'error', text:"You are not allowed to share section."},
    taskFetchError: {type: 'error', text:"Error in fetching tasks. Please try again."},
    generateSectionWarning: {type: 'warn', text:"Please generate the sections first."},
    addSectionError: {type: 'error', text:"Error occurred while adding section."},
    processSectionError: {type: 'error', text:"Error occurred while processing sections."},
    processSectionSuccess: {type: 'error', text:"Sections processed successfully"},
    generateSectionAuth: {type: 'info', text:"Please select at least one attachment to generate sections"},
    searchSectionAuth: {type: 'error', text:"Please select section for search."},
    
  },

SubadminManageuser2:{
  updateUserdata: {type: 'success', text:"User role saved successfully!"},
  updateUserdataFail: {type: 'error', text:"Failed to update user role. Please try again later!"},
  fetchWorkflowListFail: {type: 'error', text:"Error in fetching workflow list."},
  fetchWorkflowRoleListFail: {type: 'error', text:"Error in fetching workflow role list."},
  fetchAssetDataFail: {type: 'error', text:"Error in fetching asset data."},
  addUserTeamFail: {type: 'error', text:"Error in adding user to team."},
  removeUserTeamFail: {type: 'error', text:"Error in removing user from team."},
  deleteUserTeamFail: {type: 'error', text:"You are not allowed to Delete this user."},

},
roleModal:{
  rolenameAuth: {type: 'error', text:"The role name is not allowed."},
},

stageComponent:{
  addStatusAuth: {type: 'warn', text:"Please enter a status"},
  stageNameAuth: {type: 'warn', text:"Please enter stage name"},
}
    
  };
  
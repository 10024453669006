// Summeriser.js

import React, { useEffect, useState, useRef } from 'react';
import "./Summeriser.css";
import { Editor, EditorState, KeyBindingUtil, ContentState, convertFromHTML, Modifier, SelectionState, RichUtils, CompositeDecorator, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Button from '@mui/material/Button'
import MicIcon from '@mui/icons-material/Mic';
import UploadIcon from '@mui/icons-material/Upload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Slider } from '@mui/material';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import DownloadIcon from '@mui/icons-material/Download';
import { getCheckSummariser, summarizerKeywords } from '../../../services/summariserService';
import { IconButton } from '@material-ui/core';
import Loader from '../../../utils/loader';
import SelectDropdownMui from '../../ReusableComponents/SelectDropdownMui';
import { checkPhrasing } from '../../../services/englishCheckers';
import { toast, ToastContainer } from "react-toastify";
import HeadingB from '../../Cards/HeadingB';
import styled from "styled-components";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import DeleteIcon from '@mui/icons-material/Delete';
import InputBoxMui from '../../ReusableComponents/InputBoxMui';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const Summeriser = () => {
    const [loading, setLoading] = useState(false);
    const [editorState1, setEditorState1] = useState(() => EditorState.createEmpty());
    const [textLength1, setTextLength1] = useState()
    // const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());
    // const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty(createDecorator()));
    // const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty(createDecorator([])));
    const [editorStatePara, setEditorStatePara] = useState("");
    const [paragraphState, setParagraphState] = useState()
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [totalWords, setTotalWords] = useState(0);
    const [totalWords2, setTotalWords2] = useState(0);
    const [activeLink, setActiveLink] = useState(null);
    const [APIData, setAPIData] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const [sliderText, setSliderText] = useState("Shorten")
    const [keywords, setKeywords] = useState([])
    const [selectedKeywords, setSelectedKeywords] = useState()
    const [isBulletPoints, setIsBulletPoints] = useState(false)
    const [noOfBullets, setNoOfBullets] = useState(0)
    const [isParagraph, setIsParagraph] = useState(false)
    const [isCustom, setIsCustom] = useState(false)
    const [customPrompt, setCustomPrompt] = useState("")
    const [isKeyword, setIsKeyword] = useState(false)
    const [maxCountDisplayed, setMaxCountDisplayed] = useState(false)
    const [isSummarize, setIsSummarize] = useState(false)
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isRecording, setIsRecording] = useState(false);
    const [isContinuousListening, setIsContinuousListening] = useState(false);

    const [inputKey, setInputKey] = useState()

    //     const [characters, setCharacters] = useState({ max: 1500, now: 0 });
    //   const [opCharacters, setOpCharacters] = useState({ max: 1500, now: 0 });

    const { hasCommandModifier } = KeyBindingUtil;
    let [keyCode, setKeyCode] = useState(null)
    const sliderMarks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 20,
            label: '20%',
        },
        {
            value: 40,
            label: '40%',
        },
        {
            value: 60,
            label: '60%',
        },
        {
            value: 80,
            label: '80%',
        },
        {
            value: 100,
            label: '100%',
        },
    ]

    let maxWords = 1500;

    const [words, setWords] = useState([])

    const Decorated = ({ children }) => {
        return <span style={{ color: "darkorange" }}>{children}</span>;
    }

    function findWithRegex(words, contentBlock, callback) {
        const text = contentBlock.getText();

        words.forEach(word => {
            const matches = [...text.matchAll(word)];
            matches.forEach(match =>
                callback(match.index, match.index + match[0].length)
            );
        });
    }

    function handleStrategy(contentBlock, callback) {
        findWithRegex(words, contentBlock, callback);
    }

    const createDecorator = () => {
        return new CompositeDecorator([
            {
                strategy: handleStrategy,
                component: Decorated
            }
        ]);
    }

    // const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty(createDecorator([])));
    const [editorState2, setEditorState2] = useState(
        EditorState.createEmpty(new CompositeDecorator([]))
    );
    const [originalText, setOriginalText] = useState("")
    const [paraphrasedText, setParaphrasedText] = useState("")
    const editor = useRef(null);

    function focusEditor() {
        editor.current.focus();
    }

    // useEffect(() => {
    //     if (words.length > 0) {
    //         console.log("words",words)
    //         setEditorState2(EditorState.createEmpty(createDecorator(words)))
    //         focusEditor();
    //     }
    // }, [words])

    // useEffect(() => {
    //     focusEditor();
    // }, []);

    const highlightDecorator = (newWords) => {
        return new CompositeDecorator([
            {
                strategy: (contentBlock, callback) => {
                    const text = contentBlock.getText()
                    // let index = 0
                    newWords.forEach(newWord => {
                        // let index = text.indexOf(newWord);
                        // while (index !== -1) {
                        //     callback(index, index + newWord.length);
                        //     index = text.indexOf(newWord, index + 1);
                        // }

                        const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        const regex = new RegExp(`(?:^|\\W)${escapedNewWord}(?:\\W|$)`, 'gi');
                        let match;
                        while ((match = regex.exec(text)) !== null) {
                            const startIndex = match.index;
                            const endIndex = startIndex + match[0].length;
                            callback(startIndex, endIndex);
                        }

                        // const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        // const regex = new RegExp(`(?:\\b|^|\\W)${escapedNewWord}(?:\\W|\\b|$)`, 'gi');
                        // let match;
                        // while ((match = regex.exec(text)) !== null) {
                        //     const startIndex = match.index;
                        //     const endIndex = startIndex + match[0].length;
                        //     callback(startIndex, endIndex);
                        // }

                        // const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        // const regex = new RegExp(`\\b${escapedNewWord}(\\.|,|;|:|\\b|$)`, 'gi');
                        // let match;
                        // while ((match = regex.exec(text)) !== null) {
                        //     const startIndex = match.index;
                        //     const endIndex = startIndex + match[0].length;
                        //     callback(startIndex, endIndex);
                        // }

                        // const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        // const wordIndex = text.indexOf(escapedNewWord, index);
                        // const nextCharIndex = wordIndex + escapedNewWord.length;
                        // if (wordIndex !== -1) {
                        //     // Check if the next character is a full stop
                        //     const nextChar = text[nextCharIndex];
                        //     const isNextCharFullStop = nextChar === '.' || nextChar === '。' || nextChar === '،'; // Add more punctuation marks if needed

                        //     const startIndex = wordIndex;
                        //     const endIndex = isNextCharFullStop ? nextCharIndex + 1 : nextCharIndex;

                        //     callback(startIndex, endIndex);
                        //     index = endIndex;
                        // }

                        // const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        // const wordIndex = text.indexOf(escapedNewWord, index);
                        // if (wordIndex !== -1) {
                        //   const startIndex = wordIndex;
                        //   const endIndex = startIndex + escapedNewWord.length;
                        //   callback(startIndex, endIndex);
                        //   index = endIndex;
                        // }

                        // const escapedNewWord = newWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
                        // const regex = new RegExp(`(?:\\b|^|\\W)${escapedNewWord}(?:\\b|$|\\W|$)`, 'gi');
                        // let match
                        // while ((match = regex.exec(text)) !== null) {
                        //     const startIndex = match.index;
                        //     const endIndex = startIndex + newWord.length;
                        //     callback(startIndex, endIndex);
                        // }

                        // const matches = [...text.matchAll(newWord)];
                        // matches.forEach(match =>
                        //     callback(match.index, match.index + match[0].length)
                        // );
                    });
                },
                // component: NewWordHighlight,
                component: Decorated
            },
        ]);
    }

    const changeColors = () => {
        // const originalTokens = originalText.split(' ');
        // const paraphrasedTokens = paraphrasedText.split(' ');

        const originalTokens = originalText.split(/\s+/);
        const paraphrasedTokens = paraphrasedText.split(/\s+/);

        const newWords = paraphrasedTokens.filter(word => !originalTokens.includes(word));
        const dec = highlightDecorator(newWords)
        console.log("dec", dec)
        // setEditorState2(EditorState.createEmpty(dec));
        const contentState = ContentState.createFromText(paraphrasedText)
        setEditorState2(EditorState.createWithContent(contentState, dec));

    }

    useEffect(() => {
        if (paraphrasedText !== "") {
            changeColors()
        }
    }, [paraphrasedText])

    useEffect(() => {
        if (undefined !== selectedKeywords) {
            summarizeByKeyWord()
        }
    }, [selectedKeywords])

    const handleWordCountExceed = () => {
        if (!maxCountDisplayed) {
            toast.warn("Word count exceeded than 1500")
            setMaxCountDisplayed(true)
        }
    }


    ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////

    const handleMicClick = () => {
        if (SpeechRecognition.browserSupportsSpeechRecognition()) {
            if (isRecording) {
                SpeechRecognition.stopListening();
                // handleSpeechInput(transcript); // Use the transcript when recording stops
            } else {
                SpeechRecognition.startListening({ continuous: true });
            }
            setIsRecording(!isRecording);
        } else {
            toast.error("Speech recognition is not supported by your browser.");
        }
    };

    const handleTranscriptChange = () => {
        // This function will be called whenever the transcript changes
        if (transcript) {
            handleSpeechInput(transcript);
        }
    };

    const handleSpeechInput = (input) => {
        // Handle the speech input, for example, update the editor state
        const newContentState = ContentState.createFromText(input);
        const newEditorState = EditorState.createWithContent(newContentState);
        setEditorState1(newEditorState);
    };

    useEffect(() => {
        handleTranscriptChange();
    }, [transcript]);


    useEffect(() => {
        if (isCustom) {
            if (customPrompt == "") {
                setIsSummarize(true)
            }
            else {
                setIsSummarize(false)
            }
        }
    }, [customPrompt])

    useEffect(() => {
        if (isCustom == false) {
            setIsSummarize(false)
        }
    }, [isCustom])

    ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////

    useEffect(() => {
        console.log("editorState1", editorState1)
        updateWordCount()
    }, [editorState1])

    useEffect(() => {
        console.log("editorState1", editorState1)
        updateWordCount2()
    }, [editorState2])

    const updateWordCount = () => {
        let contentState = editorState1.getCurrentContent()
        let hasText = contentState.hasText()
        if (hasText) {
            let currentText = contentState.getPlainText()
            let wordCount = currentText.trim().split(/\s+/).length
            console.log("currentText", currentText)
            console.log("wordCount", wordCount)
            setTotalWords(wordCount);
        }
        else {
            setTotalWords(0);
        }
    }

    const updateWordCount2 = () => {
        let contentState = editorState2.getCurrentContent()
        let hasText = contentState.hasText()
        if (hasText) {
            let currentText = contentState.getPlainText()
            let wordCount = currentText.trim().split(/\s+/).length
            console.log("currentText", currentText)
            console.log("wordCount", wordCount)
            setTotalWords2(wordCount);
        }
        else {
            setTotalWords2(0);
        }
    }

    const handleKeyWords = (e) => {
        console.log("handleKeyWords", e)
        let value = e.target.value
        setSelectedKeywords(value)
    }

    const handleDeleteClick = () => {
        setEditorState1(EditorState.createEmpty());
        setEditorState2(EditorState.createEmpty())
        setEditorStatePara("")
        setTotalWords(0)
        setTotalWords2(0)
        setSliderValue(0)
        setIsCustom(false)
        setIsVisible(false)
        setMaxCountDisplayed(false)
        resetTranscript();
    }

    const handleDeleteClick2 = () => {
        setEditorState2(EditorState.createEmpty())
        setEditorStatePara("")
        setTotalWords2(0)
        setSliderValue(0)
        setIsCustom(false)
        setCustomPrompt("")
        setMaxCountDisplayed(false)
        resetTranscript();
    }

    const summarizeByKeyWord = async () => {
        setLoading(true)
        // Please summarize the following phrase based on the keywords
        let sendData = {
            text: selectedKeywords,
            content: editorState1.getCurrentContent().getPlainText(),
            prompts: "three",
        }
        try {
            let response = await getCheckSummariser(sendData)
            console.log("summarizeByKeyWord", response)
            let newContentState = ContentState.createFromText(response.data.output);
            let newEditorState = EditorState.createWithContent(newContentState);
            setEditorState2(newEditorState);
            setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
        }
        catch (e) {
            console.log("summarizeByKeyWorderror", e)
        }
        setLoading(false)
    }

    const summarizeByPercentage = async (value) => {

        //summarize by percentage
        // four, five
        let promptValue = "four"
        let newValue
        if (value == 0) {
            // newValue = 0
            // promptValue = "four"
            handleSummeriseClick()
            return
        }
        else if (value < 60) {
            newValue = 100 - value
            promptValue = "four"
        }
        else if (value >= 60) {
            newValue = value
            promptValue = "five"
        }

        console.log("newValue", newValue)
        let sendData = {
            text: newValue.toString() + "%",
            content: editorState1.getCurrentContent().getPlainText(),
            prompts: promptValue,
        }
        console.log("sendData", sendData)
        setLoading(true)
        try {
            let response = await getCheckSummariser(sendData)
            console.log("summarizeByPercentage", response)
            let newContentState = ContentState.createFromText(response.data.output);
            let newEditorState = EditorState.createWithContent(newContentState);
            setEditorState2(newEditorState);
            setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
        }
        catch (e) {
            console.log("summarizeByPercentageerror", e)
        }
        setLoading(false)
    }

    const handlePastedText = (text) => {
        // const {editorState} = this.state;
        console.log("before text", text);
        text = text.replace(/['"]+/g, '');
        console.log("after text", text);
        const blockMap = ContentState.createFromText(text.trim()).blockMap;
        const newState = Modifier.replaceWithFragment(editorState1.getCurrentContent(), editorState1.getSelection(), blockMap);
        setEditorState1(EditorState.push(editorState1, newState, 'insert-fragment'));
        return true;
    }

    const handlePastedText2 = (text) => {
        // const {editorState} = this.state;
        console.log("before text", text);
        text = text.replace(/['"]+/g, '');
        console.log("after text", text);
        const blockMap = ContentState.createFromText(text.trim()).blockMap;
        const newState = Modifier.replaceWithFragment(editorState2.getCurrentContent(), editorState2.getSelection(), blockMap);
        setEditorState2(EditorState.push(editorState2, newState, 'insert-fragment'));
        return true;
    }

    const handleReplaceText = (newEditorState) => {
        let newState = newEditorState.getCurrentContent()
        let newText = newState.getPlainText()
        let previousState = editorState2.getCurrentContent()
        let previousText = previousState.getPlainText()
        // let previousText = "India is part of the continent of Asia."

        // let words1 = previousText.split(/\s+/);
        // let words2 = newText.split(/\s+/);

        // const originalContentState = editorState2.getCurrentContent();
        const replacedContentState = ContentState.createFromText(newText);

        // Find differences between original and new text
        // let diff = findTextDiff(previousText, newText);
        // console.log("diff", diff)
        setOriginalText(previousText)
        setParaphrasedText(newText)
        // setWords(diff.changedWords)
        // let diff = findTextDiff(words1, words2);

        // Apply inline styles to highlight changed words
        // let newContentState = replacedContentState;
        // const blockMap = newContentState.getBlockMap();
        // diff.diff.forEach(({ start, end }) => {
        //     const blockKey = blockMap.first().getKey(); // Assuming only one block
        //     const blockText = newContentState.getBlockForKey(blockKey).getText();

        //     const startIndex = blockText.indexOf(newText[start]);
        //     console.log("blockText", blockText, startIndex, previousText, end, previousText[end])
        //     const endIndex = blockText.indexOf(newText[end]) + newText[end];

        //     // newContentState = Modifier.applyInlineStyle(newContentState, SelectionState.createEmpty(), 'HIGHLIGHT', start, end)

        //     // newContentState = Modifier.applyInlineStyle(newContentState, SelectionState.createEmpty(), 'HIGHLIGHT',
        //     //     newContentState.getBlockForKey(0).getText().indexOf(previousText[start]),
        //     //     newContentState.getBlockForKey(0).getText().indexOf(previousText[end])
        //     // )

        //     newContentState = Modifier.applyInlineStyle(
        //         newContentState,
        //         SelectionState.createEmpty().merge({
        //             anchorKey: blockKey,
        //             focusKey: blockKey,
        //             anchorOffset: startIndex,
        //             focusOffset: endIndex,
        //         }),
        //         'HIGHLIGHT'
        //     );
        // });
        // let updatedState = EditorState.push(editorState2, newContentState, 'apply-entity');
        // setEditorState2(updatedState);
        // setOriginalText(newText);
    };

    // Helper function to find differences between two strings
    const findTextDiff = (text1, text2) => {
        let data = {
            diff: [],
            changedWords: []
        }
        let { diff, changedWords } = data
        const words1 = text1.split(/\s+/);
        const words2 = text2.split(/\s+/);

        let i = 0;
        let j = 0;

        while (i < words1.length && j < words2.length) {
            if (words1[i] !== words2[j]) {
                diff.push({ start: i, end: i + 1 });
                changedWords.push(words2[j]);
            }
            i++;
            j++;
        }

        // Handle remaining words in either string
        while (i < words1.length) {
            diff.push({ start: i, end: i + 1 });
            i++;
        }
        while (j < words2.length) {
            diff.push({ start: j, end: j + 1 });
            changedWords.push(words2[j]);
            j++;
        }

        // return diff;
        return data
    };

    const handleCopyEditor1 = () => {
        let sentence = editorState1.getCurrentContent().getPlainText()
        if (sentence == "") {
            toast.warn(`You don't have text, to copy`);
            return;
        }
        navigator.clipboard.writeText(sentence).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                if (sentence) toast.info("Your text Copied!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }

    const handleCopyEditor2 = () => {
        let sentence = editorState2.getCurrentContent().getPlainText()
        if (sentence == "") {
            toast.warn(`You don't have text, to copy`);
            return;
        }
        navigator.clipboard.writeText(sentence).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                if (sentence) toast.info("Your text Copied!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }

    const handleDownload = () => {
        if (!editorState2.getCurrentContent().getPlainText()) {
            toast.warn(`You don't have text, to download`);
            return;
        }
        const blob = new Blob([editorState2.getCurrentContent().getPlainText()], { type: 'text/plain' });

        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = 'text_content.doc';
        anchor.click();
        URL.revokeObjectURL(anchor.href);
    };

    const handleFileChange = (e) => {
        console.log("files_", e.target, e)
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const content = event.target.result;
                const text = content.toString();

                // const contentBlocks = convertFromHTML(content);
                // const contentState = ContentState.createFromBlockArray(contentBlocks);
                // const styledContentState = modifyContentWithCustomStyles(contentState);
                // const newEditorState = EditorState.createWithContent(styledContentState);

                const contentState = ContentState.createFromText(text);
                const newEditorState = EditorState.createWithContent(contentState);
                let currentText = newEditorState.getCurrentContent().getPlainText()

                const wordCount = currentText.trim().split(/\s+/).length;
                console.log("currentText", currentText, wordCount)
                if (wordCount > maxWords) {
                    handleWordCountExceed()
                    return
                }

                if (wordCount <= maxWords) {
                    setTotalWords(wordCount);
                    setInputKey((prevKey) => prevKey + 1);
                    setEditorState1(newEditorState);
                    setMaxCountDisplayed(false)
                }
                // setEditorState1(newEditorState);
            };

            reader.readAsText(file);
        }
    }

    const handleParaphrase = () => {
        setActiveLink("paragraph")
        setAPIData(false)
        setIsBulletPoints(false)
        setIsParagraph(true)
        setIsCustom(false)
        setIsKeyword(true)

        GrammerCheckForParaphrase()
    }

    const handleCustomPrompt = (e) => {
        console.log("handleCustomPrompt", e.target.value)
        let value = e.target.value
        setCustomPrompt(value)
    }

    const GrammerCheckForParaphrase = async (type) => {
        console.log("editorState_", editorState2.getCurrentContent().getPlainText());
        let data = {
            text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState2.getCurrentContent().getPlainText()}`
        }
        setLoading(true)
        try {
            let paraphraseResponse = await checkPhrasing(data)
            if (paraphraseResponse.data !== "") {
                let responseOutput = paraphraseResponse?.data?.output
                let wordLength = responseOutput.split(" ")
                console.log("paraphraseResponse_", responseOutput, wordLength.length);
                let contentState = ContentState.createFromText(responseOutput);
                let newEditorState = EditorState.createWithContent(contentState);
                setEditorState2(newEditorState);
                handleReplaceText(newEditorState)
                setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
            }
        }
        catch (e) {
            console.log("error", e)
        }
        setLoading(false)
    }



    const handleParagraph = () => {
        // Get the current content
        // const contentState = editorState2.getCurrentContent();

        const contentState = ContentState.createFromText(editorStatePara)
        const editorStateWithText = EditorState.createWithContent(contentState);
        console.log("handleParagraph", editorStatePara)
        setEditorState2(editorStateWithText)


        // let oldPara = RichUtils.toggleBlockType(paragraphState, 'unstyled');
        // let oldParaUndo = EditorState.undo(editorState2)
        // setEditorState2(oldParaUndo)
        return
        // Convert content to raw format
        const rawContent = convertToRaw(contentState);
        console.log("rawcontent", rawContent)

        // Remove any formatting, styles, or blocks
        rawContent.blocks.forEach(block => {
            block.inlineStyleRanges = [];
            block.entityRanges = [];
            block.data = {};
        });

        // Convert back to ContentState
        const newContentState = convertFromRaw(rawContent);

        // Set the new content in EditorState
        const newEditorState = EditorState.createWithContent(newContentState);
        setEditorState2(newEditorState)
        //   return newEditorState;
    }

    // const handleParagraph = ()=>{
    //     // let newEditorState = editorState2.getCurrentContent().getPlainText('\u0001')
    //         // setEditorState2(newEditorState)

    //         let separator = '\u0001'
    //         let contentStateWithSeparator = ContentState.createFromText(editorStatePara.getCurrentContent().getPlainText());
    //         let newEditorState = EditorState.createWithContent(contentStateWithSeparator)
    //         setEditorState2(newEditorState)
    //         console.log("editorStatePara", editorStatePara)
    //         // let paraContent = EditorState.createWithContent(editorStatePara.getCurrentContent().getPlainText(separator))
    //         // setEditorState2(paraContent)

    //         let currentContent = editorStatePara.getCurrentContent()
    //         let currentContentText = currentContent.getPlainText()
    //         console.log("currentContentText", currentContentText)
    //         let newContentState = ContentState.createFromText(currentContentText, currentContent.getBlockMap());
    //         let newEditorState2 = EditorState.push(editorState2, newContentState, 'insert-characters');
    //         setEditorState2(newEditorState2)
    // }

    const handleLinkClick = (linkType) => {
        setActiveLink(linkType);
        if (linkType === "bulletPoints") {
            setAPIData(true)
            setIsBulletPoints(true)
            setIsParagraph(false)
        }
        else if (linkType === "paragraph") {
            setAPIData(false)
            setIsBulletPoints(false)
            setIsParagraph(true)
            setIsCustom(false)
            setIsKeyword(true)
            handleParagraph()
        }
        else if (linkType == "custom") {
            setIsBulletPoints(false)
            setIsParagraph(false)
            setIsCustom(true)
            setCustomPrompt("")
            setIsSummarize(true)
            setIsKeyword(false)
        }
    };

    const handleKeyCommand = (command, state) => {
        const newState = RichUtils.handleKeyCommand(state, command);
        // if(newState.getCurrentContent().getPlainText().trim().split(/\s+/).length <= maxWords){
        if (newState) {
            handleEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };


    const handleEditorChange = (newEditorState) => {
        console.log("handleEditorChange", newEditorState, keyCode)

        const contentState = newEditorState.getCurrentContent();
        const currentText = contentState.getPlainText();
        let hasText = contentState.hasText()
        // if (textLength1 == 0 && !hasText) {
        //     setTotalWords(0)
        //     return
        // }

        // const rawText = convertToRaw(contentState).blocks
        //     .map((block) => block.text.trim())
        //     .join(' ')
        //     .trim();
        // const rawTextLength = rawText.length > 0 ? rawText.split(/\s+/).length : 0;
        // console.log("rawtext", rawText, rawTextLength)
        // setTextLength1(rawTextLength)

        const wordCount = currentText.split(/\s+/).length;

        console.log("wordcount", wordCount, currentText, hasText)

        if (wordCount > maxWords) {
            console.log("maxwords", wordCount, currentText)
            handleWordCountExceed()
            return
        }

        // if (hasText) {
        setEditorState1(newEditorState);
        setMaxCountDisplayed(false)
        // }

        setTotalWords(hasText ? wordCount : 0)
    };

    const handleEditorChange2 = (newEditorState) => {
        console.log("handleEditorChange", newEditorState, editorStatePara)
        const contentState = newEditorState.getCurrentContent();
        const currentText = contentState.getPlainText();
        const wordCount = currentText.split(/\s+/).length;
        let hasText = contentState.hasText()
        console.log("wordcount", wordCount, currentText, hasText)
        if (wordCount > maxWords) {
            console.log("maxwords", wordCount, currentText)
            handleWordCountExceed()
            return
        }

        // if (wordCount <= maxWords && hasText) {
        setTotalWords2(hasText ? wordCount : 0);
        setEditorState2(newEditorState)
        // setEditorStatePara(newEditorState)
        setMaxCountDisplayed(false)

        // handleReplaceText(newEditorState)
        // }
    }


    // const handleBeforeInput = (chars, editorState) => {
    //     const contentState = editorState.getCurrentContent();
    //     const currentText = contentState.getPlainText();
    //     const wordCount = currentText.trim().split(/\s+/).length;

    //     if (wordCount >= maxWords) {
    //         return 'handled';
    //     }
    //     return 'not-handled';
    // };


    const handleButtonClick = async (buttonText) => {
        setSelectedButtons((prevSelectedButtons) => {
            if (prevSelectedButtons.includes(buttonText)) {
                // Deselect the button
                return prevSelectedButtons.filter((btn) => btn !== buttonText);
            } else {
                // Select the button
                return [...prevSelectedButtons, buttonText];
            }
        });
        console.log("handleButtonClick", buttonText)

    };

    const isButtonSelected = (buttonText) => {
        return selectedButtons.includes(buttonText);
    };

    const handleSummeriseClick = async () => {
        if (editorState1.getCurrentContent().getPlainText().split(/\s+/).length > 1500) {
            toast.warn("Words limit exceeded")
            return;
        }

        setLoading(true)
        try {
            if (editorState1.getCurrentContent().getPlainText().split(/\s+/).length - 1 == 0) {
                toast.warn("Please enter text")
                return;
            }
            else {
                const contentState = editorState1.getCurrentContent();
                const plainText = contentState.getPlainText();

                // Please generate summary of the provided text
                let sendData = {
                    text: plainText,
                    content: "",
                    prompts: "one",
                }

                if (customPrompt !== "") {
                    sendData = {
                        text: customPrompt,
                        content: plainText,
                        prompts: "six"
                    }
                }
                setLoading(true)
                let generateSummary = await getCheckSummariser(sendData);
                console.log("generateSummary", generateSummary)
                const newContentState = ContentState.createFromText(generateSummary.data.output);
                const newEditorState = EditorState.createWithContent(newContentState);
                setEditorState2(newEditorState);
                setEditorStatePara(newEditorState.getCurrentContent().getPlainText())

                // Please generate the keywords based on the provided content
                let sendData2 = {
                    text: plainText,
                    prompts: "two",
                }

                let generateKeywords = await getCheckSummariser(sendData2)
                console.log("generateKeywords", generateKeywords)
                let text = generateKeywords.data.output
                // let extractedWords = text.match(/\b\w+\b/g) || [];
                // let extractedWords = text.match(/[a-zA-Z]+/g) || [];
                // let extractedWords = text.replace(/\n\d+\./g, '');
                const lines = text.split('\n');

                // Filter out lines starting with "\nX."
                const filteredLines = lines.filter(line => !line.match(/^\s*\n\d+\.\s*/));

                // Extract sentences after dots
                const extractedSentences = filteredLines
                    .map(line => line.split(/\.\s*/))
                    .flat()
                    .filter(Boolean);
                let cleanedArray = extractedSentences.filter((item, index) => index % 2 !== 0)
                console.log("extractedSentences", extractedSentences, cleanedArray)
                let finalArray = []
                cleanedArray.map((obj) => {
                    finalArray.push({
                        name: obj,
                        value: obj
                    })
                })

                setKeywords(finalArray)
                setIsVisible(true);
                // setIsVisible(isCustom == false ? true : false);
                // setIsKeyword(true)
                setIsKeyword(isCustom ? false : true)
            }
        }
        catch (error) {
            console.error('summarizeClick', error);
            toast.error("Error Occurred")
        }
        setLoading(false)
    };

    const handleApplyBullets = () => {
        // setAPIData(true)
        // setAPIData(false)
        let hasText = editorState2.getCurrentContent().hasText();
        if (!hasText) {
            toast.info("No content to add bullets")
            return
        }
        setIsBulletPoints(true)
        setIsParagraph(false)
        setIsCustom(false)
        setIsKeyword(true)

        // let text = editorState2.getCurrentContent().getPlainText();
        // let sentences = text.split('. ');
        // let newText = sentences.join('.\n');
        // let newContentState = ContentState.createFromText(newText);
        // let newEditorState = EditorState.createWithContent(newContentState);
        // setEditorState2(newEditorState)

        // Get the current text from the editor
        let text = editorState2.getCurrentContent().getPlainText();

        // Remove all empty lines
        let lines = text.split('\n').filter(line => line.trim() !== '');
        let textWithoutEmptyLines = lines.join('\n');

        // Split the text at every full stop
        let sentences = textWithoutEmptyLines.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.replace(/\n/g, ''));
        setNoOfBullets(sentences.length)
        // Add a newline after every sentence and join them back together
        let formattedText = sentences.join('.\n');

        // Create a new content state with the formatted text
        let newContent = ContentState.createFromText(formattedText);

        // Set the new content state in the editor
        // let newEditorState = EditorState.push(editorState2, newContent);
        let newEditorState = EditorState.createWithContent(newContent);
        let newEditorStateExt = newEditorState.getCurrentContent()
        console.log("newEditorStateExt", newEditorStateExt.getPlainText())
        let firstBlock = newEditorStateExt.getFirstBlock()
        let lastBlock = newEditorStateExt.getLastBlock()
        let firstBlockKey = firstBlock.getKey();
        let lastBlockKey = lastBlock.getKey();
        let firstBlockLength = firstBlock.getLength();
        let lastBlockLength = lastBlock.getLength();
        let selection = newEditorState.getSelection().merge({
            anchorKey: firstBlockKey,
            anchorOffset: 0,
            focusKey: lastBlockKey,
            focusOffset: lastBlockLength,
        })
        let selectedState = EditorState.forceSelection(newEditorState, selection)
        setParagraphState(selectedState)

        let selectionState = selectedState.getSelection();
        let contentState = selectedState.getCurrentContent();
        let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
        // Check if the selection is not collapsed and spans the entire block
        // if (!selectionState.isCollapsed() && selectionState.hasFocus &&
        //     selectionState.getStartOffset() === 0 && selectionState.getEndOffset() === currentBlock.getLength()) {

        // Toggle the block type to unordered-list-item
        let newEditorState2 = RichUtils.toggleBlockType(selectedState, 'unordered-list-item');
        setEditorState2(newEditorState2);
        // }

    }

    const handleSliderChange = (event, newValue) => {
        // Calculate the percentage
        const percentage = ((newValue - 0) / (100 - 0)) * 100;
        // Update the state
        setSliderValue(newValue);
        setSliderText(newValue >= 60 ? "Expand" : "Shorten")
        summarizeByPercentage(newValue)
        // Use the percentage as needed
        console.log('Percentage:', percentage);
    };

    const myKeyBindingFn = (e) => {
        // if you paste thecommand this if conditon will invoke
        if (e.keyCode === 86 /* `V` key */ && hasCommandModifier(e)) {
            keyCode = e.keyCode;
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <div className="container-fluid">
                <div className="col-10 row">
                    {/* <HeadingB text="Summarizer"></HeadingB> */}
                </div>
                <div className='row'>
                    <div className='col-md-6 p-2'>
                        <div
                            // className='row'
                            style={{
                                boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                                padding: '20px 10px',
                                borderRadius: '8px 8px 0px 0px',
                                background: 'white',
                                // minHeight: '270px',
                                // maxHeight: '270px',
                                height: "60vh",
                                overflowY: 'auto'
                            }}
                        >
                            <Editor
                                className="editor1"
                                editorState={editorState1}
                                // keyBindingFn={myKeyBindingFn}
                                onChange={handleEditorChange}
                                //   handleBeforeInput={handleBeforeInput}
                                // handlePastedText={() => handlePastedText}
                                placeholder='Please enter your text'
                            // handleKeyCommand={handleKeyCommand}
                            />
                        </div>

                        <div className=""
                            style={{
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '0px 0px 8px 8px',
                                background: 'white',
                                padding: '20px',
                                // minHeight: '70px', maxHeight: '70px'
                            }}
                        >

                            {/* <div className='row'>
                                <div className='col-md-6'>
                                    {isVisible &&
                                        <div className='px-2 justify-content-start'>
                                            {totalWords} Words
                                        </div>}
                                </div>
                            </div> */}




                            <div className='row'>
                                <div className='col-md-4 d-flex justify-content-start'>
                                    {/* <div className="p-2 justify-content-start"
                                        style={{
                                            borderRadius: "50%",
                                            margin: "0 auto",
                                            border: "1px solid rgba(0, 0, 0, 0.1)",
                                        }}
                                    >2</div> */}
                                    {/* <div className='px-2 justify-content-start'>
                                        <Button variant='outlined' color='primary'
                                        // onClick={showRightSideOnce}
                                        >
                                            Errors</Button>
                                    </div> */}

                                    <div className='mt-2 justify-content-start'
                                        style={{
                                            fontWeight: "bold",
                                            // color:"#0C134F",
                                            color: "#1976d2",
                                            // margin: "auto",
                                        }}
                                    >
                                        <b> {totalWords > 0 && totalWords} {totalWords > 0 && "Words"}</b>
                                    </div>

                                </div>

                                <div className='col-md-8 d-flex justify-content-end'>
                                    <div className='px-2 justify-content-end'>
                                        <LightTooltip title="Delete">
                                            <Button
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    minWidth: '20px',
                                                    padding: '4px',
                                                    color: 'black'
                                                }}
                                                onClick={handleDeleteClick}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </LightTooltip>
                                    </div>
                                    <div className='px-2 justify-content-end'>
                                        <LightTooltip title="Copy">
                                            <Button
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    minWidth: '20px',
                                                    padding: '4px',
                                                    color: 'black'
                                                }}
                                                onClick={handleCopyEditor1}
                                            >
                                                <ContentCopyTwoToneIcon />
                                            </Button>
                                        </LightTooltip>
                                    </div>
                                    <div className='px-2 justify-content-end'>
                                        <LightTooltip title="Mic">
                                            <Button
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    color: isRecording ? 'red' : 'black',
                                                    minWidth: '20px',
                                                    padding: '4px',
                                                }}
                                                onClick={handleMicClick}
                                            >
                                                <MicIcon />
                                            </Button>
                                        </LightTooltip>
                                    </div>
                                    <div className='px-2 justify-content-end'>
                                        <LightTooltip title="Upload File">
                                            <Button
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    color: 'black',
                                                    minWidth: '20px',
                                                    padding: '4px',
                                                }}
                                                component="label"  // Ensure that the button acts as a label for the file input
                                                htmlFor="summeriserFileInput"  // Specify the ID of the associated file input
                                            >
                                                <UploadIcon className='' />
                                                <VisuallyHiddenInput
                                                    id="summeriserFileInput"
                                                    type="file"
                                                    key={inputKey}
                                                    onChange={handleFileChange}  // Directly call the function here
                                                />
                                            </Button>
                                        </LightTooltip>
                                    </div>
                                    <div className='px-2 justify-content-end'>
                                        <Button id="" className='' variant="contained"
                                            disabled={isSummarize}
                                            onClick={handleSummeriseClick}
                                        >Summarize</Button>
                                    </div>
                                </div>
                            </div>

                            {isCustom && (
                                <div className='row'>
                                    <div className='col-md-12 mt-3'>
                                        <InputBoxMui
                                            fullWidth
                                            type="text"
                                            label="Custom Prompt"
                                            value={customPrompt}
                                            onChange={(e) => handleCustomPrompt(e)}
                                            placeholder="Eg., Generate a conclusion for the document"
                                        />
                                    </div>
                                </div>
                            )}

                            {isKeyword && (
                                <div className='mt-3'>
                                    <SelectDropdownMui
                                        optionsList={keywords}
                                        selectLabel={"Select Keyword"}
                                        onChange={handleKeyWords}
                                        value={selectedKeywords}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='col-md-6 p-2'

                    >
                        {isVisible && (
                            <div className=""
                                style={{
                                    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                                    // padding: '20px 5px',
                                    borderRadius: '8px 8px 8px 8px',
                                    background: 'white',
                                    height: "60vh",

                                }}
                            >

                                <div
                                    className='row ml-2 py-2'
                                >
                                    <div className='col-md-3 col-sm-3 p-2'
                                    // style={{display:"inline-block"}}
                                    >
                                        <button
                                            // className={` ${activeLink === 'paragraph' ? 'active-link' : ''}`}
                                            className={isParagraph ? "btn-sm bullet-para-active" : "btn-sm bullet-para"}
                                            onClick={() => handleLinkClick('paragraph')}
                                        >
                                            Paragraph
                                        </button>
                                    </div>
                                    <div className='col-md-3 col-sm-3 p-2'
                                    // style={{display:"inline-block"}}
                                    >
                                        <button
                                            // className={` ${activeLink === 'keySentences' ? 'active-link' : ''}`}
                                            className={isBulletPoints ? "btn-sm bullet-para-active" : "btn-sm bullet-para"}
                                            // onClick={() => handleLinkClick('bulletPoints')}
                                            onClick={handleApplyBullets}

                                        >
                                            Bullet Points
                                        </button>
                                    </div>
                                    <div className='col-md-3 col-sm-3 p-2'
                                    // style={{display:"inline-block"}}
                                    >
                                        <button
                                            className={isCustom ? "btn-sm bullet-para-active" : "btn-sm bullet-para"}
                                            onClick={() => handleLinkClick('custom')}
                                        >
                                            Custom
                                        </button>
                                    </div>
                                </div>

                                {isParagraph &&
                                    <div className='row p-2'>
                                        <div className='col-md-12 d-flex justify-content-center'>
                                            <span className='p-2 mr-2'>Short</span>
                                            <Box sx={{ width: "90%" }}>
                                                {/* <Typography id="non-linear-slider" gutterBottom>
                                                    {sliderText}:
                                                    {sliderValue}%
                                                </Typography> */}
                                                <Slider
                                                    defaultValue={0}
                                                    value={sliderValue}
                                                    step={20}
                                                    marks={sliderMarks}
                                                    min={0}
                                                    max={100}
                                                    size='small'
                                                    onChange={handleSliderChange}
                                                    valueLabelDisplay="off"
                                                />
                                            </Box>
                                            <span className='p-2 ml-2'>Long</span>
                                        </div>
                                    </div>
                                }

                                <div className='mt-1'
                                    onClick={focusEditor}
                                    style={{
                                        boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                                        padding: '0px 5px',
                                        borderRadius: '8px 8px 0px 0px',
                                        background: 'white',
                                        // minHeight: '185px',
                                        // maxHeight: '270px',
                                        // height: "47vh",
                                        height: isParagraph ? "45vh" : "56vh",
                                        overflowY: 'auto'
                                    }}

                                >
                                    {/* {APIData ? (
                                        <ul className=''
                                            style={{ listStyle: "circle" }}
                                        >
                                            {editorState2
                                                .getCurrentContent()
                                                .getPlainText('\u0001')
                                                .split('. ')
                                                .map((sentence, index) => (
                                                    <>
                                                        <li className="S-li" style={{ color: 'black' }} key={index}>
                                                            {sentence.trim()}
                                                        </li>
                                                        <br />
                                                    </>
                                                ))}
                                        </ul>
                                    ) : (editorState2.getCurrentContent().getPlainText('\u0001'))
                                    } */}

                                    {/* {APIData ? (
                                        (editorStatePara.getCurrentContent().getPlainText('\u0001'))
                                    )
                                        :
                                        <Editor
                                            className="editor1"
                                            editorState={editorState2}
                                            keyBindingFn={myKeyBindingFn}
                                            onChange={handleEditorChange2}
                                            handleBeforeInput={handleBeforeInput}
                                            handlePastedText={() => handlePastedText2}
                                        // placeholder='Please enter your task'
                                        />
                                    } */}

                                    <Editor
                                        // className="editor1"
                                        ref={editor}
                                        editorState={editorState2}
                                        // keyBindingFn={myKeyBindingFn}
                                        onChange={handleEditorChange2}
                                        // handleBeforeInput={handleBeforeInput}
                                        // handlePastedText={() => handlePastedText2}
                                        placeholder='Please enter your text'
                                    />

                                </div>
                                <div className=''
                                    style={{
                                        boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                                        padding: '20px 5px',
                                        borderRadius: '0px 0px 8px 8px',
                                        background: 'white',
                                    }}
                                >
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='p-2'
                                                style={{
                                                    fontWeight: "bold",
                                                    // color:"#0C134F",
                                                    color: "#1976d2",
                                                    margin: "auto",
                                                }}
                                            >
                                                {/* {editorState2.getCurrentContent().getPlainText().split(/\s+/).length - 1} Words */}
                                                {totalWords2 > 0 && totalWords2} {totalWords2 > 0 && "Words"}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            {isBulletPoints &&
                                                <div className='p-2'
                                                    style={{
                                                        fontWeight: "bold",
                                                        // color:"#0C134F",
                                                        color: "#1976d2",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    {/* {editorState2.getCurrentContent().getPlainText().split(/\s+/).length - 1} Words */}
                                                    {noOfBullets > 0 && noOfBullets} {noOfBullets > 0 && "Points"}
                                                </div>}
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-end'>
                                            <div className='p-2'>
                                                <button className='btn btn-primary'
                                                    onClick={handleParaphrase}
                                                >Paraphrase</button>
                                            </div>
                                            <div className='p-2'>
                                                <LightTooltip title="Delete">
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            minWidth: '20px',
                                                            padding: '4px',
                                                            // color: 'black'
                                                        }}
                                                        onClick={handleDeleteClick2}
                                                    >
                                                        <DeleteIcon />
                                                    </button>
                                                </LightTooltip>
                                            </div>
                                            <div className='p-2'>
                                                <LightTooltip title="Download">
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            minWidth: '20px',
                                                            padding: '4px',
                                                        }}
                                                        onClick={handleDownload}
                                                    >
                                                        <DownloadIcon />
                                                    </button>
                                                </LightTooltip>
                                            </div>
                                            <div className='p-2'>
                                                <LightTooltip title="Copy">
                                                    <button
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            minWidth: '20px',
                                                            padding: '4px',
                                                        }}
                                                        onClick={handleCopyEditor2}
                                                    >
                                                        <ContentCopyTwoToneIcon />
                                                    </button>
                                                </LightTooltip>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
}

export default Summeriser;

import React from "react";
import SideNavBar from "../SideNavBar";
import SingleNoticeInfo from "../SingleNoticeInfo";
import { useParams } from "react-router-dom";
import SubAdminSideBar from "../SubAdminSideBar";
import { useLocation } from 'react-router-dom';


const SingleNoticeInfoPage = () => {
  // const { documentId } = useParams();
  const location = useLocation();
  const documentId = location.state?.documentId;

  // Use documentId as needed
  // alert('Document ID:', documentId);
  // alert(documentId)
  return (
    <div>
      <SubAdminSideBar>
        <SingleNoticeInfo documentId={documentId} />
        </SubAdminSideBar>
    </div>
  );
};

export default SingleNoticeInfoPage;

import React, { useState, useEffect } from 'react'
import { Radio, FormControlLabel, Avatar } from '@material-ui/core';
import { grammerColor } from './utilize';
import PetAss from "../../../src/static/petAss.svg";
import { toast } from 'react-toastify';

const ItemList = ({ classChange, onSelect, errorCount, gm_color }) => {
    // console.log("itemList")
    const [grammer, setgrammer] = useState([]);
    const [radio, setradio] = useState(false);
    // const gm_color = grammerColor(GrammarChecker);

    useEffect(() => {
        showList();
    }, [errorCount]);

    const showList = () => {
        const grammerList = gm_color.map((obj) => {
            let count = 0;
            obj.list.forEach((errName) => {
                count += errorCount[errName] || 0;
            });
            obj.count = count;
            return obj;
        });
        // console.log('grammerList', grammerList,errorCount);
        setgrammer(grammerList);
    }

    const setSelection = (index, obj) => {
        if (!obj.eligible) {
            toast.info(`You are not eliglible for ${obj.secition}`)
            return;
        }
        obj['status'] = !obj.status;
        grammer[index] = obj;
        setgrammer([...grammer]);
        onSelect(grammer);
    }

    const handleChange = () => {
        setradio(!radio);
        const result = gm_color.map((obj) => {
            if (obj.eligible)
                obj.status = !radio;
            return obj;
        });
        setgrammer(result);
        onSelect(grammer);
    }

    return (
        <div className="mt-3">

            <div onClick={() => classChange()} className="flex_center assist">
                <img src={PetAss} alt='PET ASSISTANCE LOGO' className="img-25" />
                <p className="mb-0 ml-2">PET Assistance</p>
            </div>

            <div className="flex_center my-1">
                {/* <p className="itemContent m-0">Correcting: Grammar</p> */}
                <FormControlLabel
                    className="radioSize"
                    value="other"
                    control={<Radio onClick={handleChange} checked={radio} color="primarys" />}
                    label="All"
                    labelPlacement="start"
                />
            </div>
            <div className="scrollItem">
                {
                    grammer.map((obj, index) => {
                        return (<div
                            onClick={() => setSelection(index, obj)}
                            data-toggle="tooltip" title={obj.list}
                            key={obj.pgm}
                            style={{ borderLeftStyle: "solid", borderLeftColor: obj.color, borderLeftWidth: 4, borderRadius: 2 }}
                            className={(obj.status) ? '' : 'addOpacity'} >
                            <div className="itemList flex_between point zoom mt-2">
                                <div className="my-1">
                                    <p className="itemHead">{obj.secition} </p>
                                    <p className="itemContent">Correcting: Grammar</p>
                                </div>
                                <div>
                                    <Avatar style={{ backgroundColor: obj.color }} className="setAvatar" >
                                        {obj.count}
                                    </Avatar>
                                </div>
                            </div>
                        </div>)
                    })
                }
            </div>
        </div>
    )
}

export default ItemList


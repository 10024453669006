import React from "react";

import AdminNavBar from "../AdminSideNavBar";
import CostumeHome from "../CostumizeLandingPage/CostumHome";

const CostumeHomePage = () => {
  return (
    <AdminNavBar>
      <CostumeHome />
    </AdminNavBar>
  );
};

export default CostumeHomePage;

import React, { useState, useEffect, useRef } from "react";
import { diffWordsWithSpace } from "diff";
import {
  Box,
  Container,
  TextField,
  Button,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import JoditEditor from "jodit-react";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../../css/opportunity_Screen_CSS/versionControle.css";

const dummy =
  "Teenage Mutant Ninja Turtles: Shredder's Revenge is a 2022 beat 'em up game developed by Tribute Games and published by Dotemu. It is inspired by and based on the 1987 Teenage Mutant Ninja Turtles animated series and borrows stylistically from the arcade and home console Turtles games developed by Konami during the 1980s and 1990s. The story follows the Turtles as they set out to stop Shredder and Krang from taking over New York City, whilst facing foes that stand in their way.Nickelodeon approached Dotemu following the release of Wonder Boy: The Dragon's Trap (2017) about the possibility of developing a video game adaptation of one of its franchises. Knowing that Tribute Games had been pitching a Turtles game to Nickelodeon since 2010, the two companies decided to work together. The Turtles voice actors from the 1987 series reprise their roles. The music was composed by Tee Lopes, with contributions from guitarist Jonny Atma, rappers Mega Ran, Ghostface Killah, and Raekwon, and singer Mike Patton.Shredder's Revenge was released for Windows, Linux, Nintendo Switch, PlayStation 4, and Xbox One on June 16, 2022, and for PlayStation 5 on November 15, 2022. The game received critical acclaim, with reviewers praising the pixel-based visual style, playable character roster and combat mechanics, while criticism was directed at audio quality issues and the short length of the story mode. The game was also a commercial success, selling one million copies within a week of release. A downloadable expansion, Dimension Shellshock, released on August 31, 2023.";

const config2 = {
  toolbar: false,

  branding: false,
  // height: "90%",
  height: 420,
  statusbar: false,
  uploader: {
    insertImageAsBase64URI: true, // Enable Base64 encoding
  },
  allowResizeX: false, // Disable horizontal resizing
  allowResizeY: false, // Disable vertical resizing
};

export const VersionComapreModal = (props) => {
  const { versionModalState, handleVersionModalClose, SecId, versiondata, versionNo } = props;
  // useEffect(()=>{
  console.log(
    "versionModalState",
    versionModalState,
    "ok:",
    versionModalState?.versionModalState
  );
  // },[versionModalState])
  const [secondVersion, setSecondVersion] = useState(null);
  const [secondData, setSecondData] = useState(null);


  useEffect(() => {
    setSecondVersion(null);
    setSecondData(null);
  }, [versionNo]);

  useEffect(() => {
    setSecondVersion(null);
    setSecondData(null);
  }, []);

  const filteredData = versiondata?.filter(data => data.version === versionNo);
  console.log("filteredData", filteredData)

  // const handleVersionClick = (version) => {
  //   if (version !== versionNo) {
  //     setSecondVersion(version);
  //   }
  // };

  const handleVersionClick = (version) => {
    if (version !== versionNo && version !== secondVersion) {
      setSecondVersion(version);
      const newSecondData = versiondata.find(data => data.version === version);
      setSecondData(newSecondData);
    } else if (version === secondVersion) {
      setSecondVersion(null);
      setSecondData(null); // Reset secondData when deselecting
    }
  };
  // let secondData = secondVersion ? versiondata.find(data => data.version === secondVersion) : null;

  console.log("secondData", secondData)
  return (
    <>
      <Modal open={versionModalState} onClose={handleVersionModalClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "90%",
            overflow: "auto",
            height: "90%",
          }}
        >
          <div className="Flex">
            <div>
              <h3 className="heading">RFP Version Compare</h3>
            </div>
            <div>
              <IconButton>
                <CloseIcon
                  onClick={handleVersionModalClose}
                  className="closeIocn"
                />
              </IconButton>
            </div>
          </div>
          <div className="Main-flex">
            <div className="Flex-one">
              <div className="Flex1">
                <h4 className="RFP_TITLE">RFP Version </h4>
                {/* <div>
                  <IconButton style={{ padding: "0px" }}>
                    <CloseIcon className="closeIocn" />
                  </IconButton>
                </div> */}
              </div>

              <Divider />

              {/* <div className="parentRFP">
                {versiondata?.map((data)=>{
                //    const className = data.version == versionNo ? 'skyBlueBackground' : 'rfpvdiv';
                 const backgroundColor = data.version == versionNo ? 'skyblue' : 'transparent';
                           return (
                             <div className="rfpvdiv" key={data.assetsectionid} style={{ backgroundColor }}>
                        <h5 onClick={() => handleVersionClick(data.version)} className="textColor"> {data.version}</h5>
                    </div>
                    )
                })}
                
              </div> */}

              <div className="parentRFP" >
                {versiondata?.map((data) => {
                  const backgroundColor = data.version === versionNo
                    ? 'skyblue'
                    : data.version === secondVersion
                      ? 'lightblue'
                      : 'transparent';
                  return (
                    <div className="rfpvdiv" key={data.assetsectionid} style={{ backgroundColor }}>
                      <h5 onClick={() => handleVersionClick(data.version)} className="textColor"> {data.version}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="Flex-two">
              <div className="joditWith">
                {filteredData.map(data => (
                  <div key={data.assetSectionId}>
                    <h5 className="textColor"> {data.title} {data.version}</h5>
                    <JoditEditor
                      value={data.content}
                      config={config2}
                      style={{
                        height: "100%",
                        width: "99%",
                      }}
                    />
                    <h5 className="textColor"> <span style={{ color: "black"}}>Created By: </span>  {data.title} {data.username}</h5>
                  </div>
                ))}
                
              </div>
              <div className="joditWith">
                {secondData && (
                  <>
                    <h5 className="textColor"> {secondData.version}</h5>
                    <JoditEditor
                      value={secondData.content}
                      config={config2}
                      style={{
                        height: '100%',
                        width: '99%',
                      }}
                    />
                    <h5 className="textColor"><span style={{ color: "black"}}>Created By: </span>  {secondData.title} {secondData.username}</h5>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const VersionHistoryCard = (props) => {
  const { date, title, version, createdBy, AssetSectionId, versionModal } = props;

  const [timeDifference, setTimeDifference] = useState();
  useEffect(() => {
    const cuurentTime = Date.now();
    const postTime = new Date(date).getTime();
    // console.log("cuurentTime",cuurentTime)
    // console.log("Date",date)
    const postedTime = formatTimeAgo(postTime, cuurentTime);
    setTimeDifference(postedTime);
    // console.log("postedTime",postedTime)
  }, [date]);


  function formatTimeAgo(postTime, currentTime) {
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return minutes + " min ago";
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return hours + " hr ago";
    } else {
      const date = new Date(postTime);
      return date.toLocaleDateString();
    }
  }
  return (
    <React.Fragment>
      <div className="Card_Style">
        <div className="title-flex">
          <div>
            <h4>Activity in {title}</h4>
          </div>
          <div>
            <h5 className="version-No">{version}</h5>
          </div>
        </div>
        <div className="second-flex">
          <div className="inner-flex">
            <div className="person_name">
              <p>{createdBy}</p>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="time_div">
              <p>
                <b>{timeDifference}</b>
              </p>
            </div>
          </div>
          <div className="inner-flex-1">
            <IconButton onClick={() => { versionModal(AssetSectionId, version) }} style={{ padding: "0px" }} className="Icon-padding">
              <VisibilityIcon className="eye_Icon" />
            </IconButton>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useField,
} from "formik";
import * as Yup from "yup";
import Radio from "@material-ui/core/Radio";
import InputBoxOne from "./FormComponents/InputBoxOne";
import InputBoxTwo from "./FormComponents/InputBoxTwo";
import HeadOne from "./FormComponents/HeadOne";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import deletes from "../../static/delete.png";
import plus from "../../static/plus.png";
import InputBoxThree from "./FormComponents/InputBoxThree";
import InputFeedback from "./FormComponents/InputFeedBack";
import { citationStructure } from "../../utils/citationWithRandomNumber";
import {
  savecitations,
  getStyle,
  updateCitations,
} from "../../services/citationService";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../utils/loader";

const RadioField = (props) => {
  const [field] = useField(props);
  return <Radio {...field} color="primary" />;
};

const normalSchema = Yup.object({
  "container-title": Yup.string().when("citing", {
    is: "chapter_section",
    then: Yup.string().required("Mandatory"),
  }),

  author: Yup.array().of(
    Yup.object().shape({
      given: Yup.string().required("Mandatory"),
      family: Yup.string().required("Mandatory"),
    })
  ),

  title: Yup.string().required("Mandatory"),
  publisher: Yup.string().required("Mandatory"),
  "publisher-place": Yup.string().required("Mandatory"),
  year: Yup.number().required("Mandatory").positive(),

  issued: Yup.object({
    season: Yup.string().required("Mandatory"),
  }),
});

const websitePublicationSchema = Yup.object({
  "container-title": Yup.string().when("citing", {
    is: "chapter_section",
    then: Yup.string().required("Mandatory"),
  }),

  author: Yup.array().of(
    Yup.object().shape({
      given: Yup.string().required("Mandatory"),
      family: Yup.string().required("Mandatory"),
    })
  ),

  title: Yup.string().required("Mandatory"),
  publisher: Yup.string().required("Mandatory"),
  "publisher-place": Yup.string().required("Mandatory"),
  year: Yup.number().required("Mandatory").positive(),

  websitetitle: Yup.string().required("Mandatory"),
  URL: Yup.string().when("displayURL", {
    is: "1",
    then: Yup.string().required("Mandatory"),
  }),
  issued: Yup.object({
    season: Yup.string().required("Mandatory"),
  }),

  accessed: Yup.array().of(
    Yup.object({
      year: Yup.number().required("Mandatory").positive(),
    })
  ),
});

const onlineSchema = Yup.object({
  "container-title": Yup.string().when("citing", {
    is: "chapter_section",
    then: Yup.string().required("Mandatory"),
  }),

  author: Yup.array().of(
    Yup.object().shape({
      given: Yup.string().required("Mandatory"),
      family: Yup.string().required("Mandatory"),
    })
  ),

  title: Yup.string().required("Mandatory"),
  publisher: Yup.string().required("Mandatory"),
  "publisher-place": Yup.string().required("Mandatory"),
  year: Yup.number().required("Mandatory").positive(),

  // DOI: Yup.string().required("Required"),
  URL: Yup.string().when("displayURL", {
    is: "Yess",
    then: Yup.string().required("Mandatory"),
  }),
  issued: Yup.object({
    season: Yup.string().required("Mandatory"),
  }),

  accessed: Yup.array().of(
    Yup.object({
      year: Yup.number().required("Mandatory").positive(),
    })
  ),
});

const Skeleton1 = {
  "container-title": "",
  author: [],
  title: "",
  volume: "",
  edition: "",
  issued: {},
  publisher: "",
  "publisher-place": "",
  year: "",
  websitetitle: "",
  displayURL: "",
  URL: "",
  accessed: [],
  note: "",
  type: "",
};

const Skeleton2 = {
  "container-title": "",
  author: [],
  title: "",
  volume: "",
  edition: "",
  issued: {},
  publisher: "",
  "publisher-place": "",
  year: "",
  displayURL: "",
  URL: "",
  accessed: [],
  note: "",
  type: "",
};

const Skeleton3 = {
  "container-title": "",
  author: [],
  title: "",
  volume: "",
  edition: "",
  issued: {},
  publisher: "",
  "publisher-place": "",
  year: "",
  note: "",
  type: "",
};

const Skeleton4 = {
  "container-title": "",
  author: [],
  title: "",
  volume: "",
  edition: "",
  issued: {},
  publisher: "",
  "publisher-place": "",
  year: "",
  format: "",
  note: "",
  type: "",
};
let value = {};
function BookForm({ formType, history, initialValues, projectID }) {
  const [url, seturl] = useState("");
  const [loading, setLoading] = useState(false);

  if (initialValues) {
    console.log("doneee...!");
    value = initialValues;
  } else {
    value = {
      id: "",
      "container-title": "",
      citing: "",
      author: [
        {
          title: "Author",
          given: "",
          middle: "",
          family: "",
          suffix: "",
        },
      ],
      title: "",
      volume: "",
      edition: "",
      publisher: "",
      "publisher-place": "",
      year: "",

      websitetitle: "",
      URL: "",
      displayURL: "",
      issued: {
        season: "",
        "date-parts": [["", "", ""]],
      },
      accessed: [
        {
          day: "",
          month: "",
          year: "",
        },
      ],

      // DOI: "",
      format: "",
      note: "",
      type: "Book",
    };
  }
  return (
    <div>
      <div className="container bg-white rounded shadow">
        <div className="row pt-5">
          <HeadOne />
        </div>
        <ToastContainer />
        <Formik
          initialValues={value}
          validationSchema={
            formType === "E_Book_Online"
              ? websitePublicationSchema
              : formType === "Online_DB"
              ? onlineSchema
              : normalSchema
          }
          onSubmit={async (values, { resetForm }) => {
            // delete values.issued["date-parts"]
            setLoading(true);
            console.log(values);
            let skeleton = {};
            if (formType === "E_Book_Online") {
              skeleton = Skeleton1;
            } else if (formType === "Online_DB") {
              skeleton = Skeleton2;
            } else if (formType === "In_Print") {
              skeleton = Skeleton3;
            } else {
              skeleton = Skeleton4;
            }
            for (var key of Object.keys(skeleton)) {
              skeleton[key] = values[key];
            }
            skeleton.issued["date-parts"][0][0] = values.year;
            skeleton["sub-type"] = formType;

            console.log(skeleton);
            console.log(values._id);
            let response = "";
            if (!values._id) {
              response = await savecitations(skeleton, projectID);
            } else {
              response = await updateCitations({
                _id: values._id,
                data: skeleton,
              });
            }
            if (response.status !== 200) {
              toast.error(response.message);
            } else {
              toast.info(response.message);
              // history.push('/citationList')
              history.push({
                pathname: `/citationList`,
                state: { projectId: projectID },
              });
              resetForm({});
            }
            setLoading(false);
          }}
          render={({ values, errors, touched, resetForm }) => (
            <Form>
              <Loader loading={loading} />
              <div>
                <div className="row mt-5" style={{ marginLeft: "142px" }}>
                  <h3>What I'm citing</h3>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: "142px", marginTop: "20px" }}
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                    }}
                  >
                    Citing
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "142px" }}>
                  {/* <Field name={`containertitle`} as={InputBoxOne} />  */}

                  {/* {errors.citationtitle && touched.citationtitle ? (
                    <div>{errors.citationtitle}</div>
                  ) : null}  */}

                  <Field as="select" value={values.citing} name={"citing"}>
                    <option value={"whole_book"}>Whole Book</option>
                    <option value={"chapter_section"}>Chapter/Section</option>
                  </Field>
                </div>

                {values.citing === "chapter_section" ? (
                  <div
                    className="row"
                    style={{ marginTop: "20px", marginLeft: "142px" }}
                  >
                    <div className="col-md-4">
                      <h6
                        style={{
                          marginLeft: "-6%",
                          fontSize: "14px",
                          lineHeight: "19px",
                        }}
                      >
                        Chapter/Section name
                      </h6>
                      <br />
                      <div style={{ marginLeft: "-6%", marginTop: "-4%" }}>
                        <Field name="container-title" as={InputBoxOne} />
                        <ErrorMessage
                          as={InputFeedback}
                          name="container-title"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              <FieldArray
                name="author"
                render={(arrayHelpers) => (
                  <div>
                    <div style={{ marginLeft: "140px", marginTop: "10px" }}>
                      <h3>Authors</h3>
                    </div>
                    {values.author && values.author.length > 0
                      ? values.author.map((author, index) => (
                          <div
                            key={index}
                            style={{ marginLeft: "150px", marginTop: "10px" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "20px", marginLeft: "-10px" }}
                            >
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Article title</h6>
                                </div>
                                <div className="row">
                                  <Field
                                    name={`author[${index}].title`}
                                    as="select"
                                    placeholder="title"
                                  >
                                    <option value="Author">Author</option>
                                    <option value="Editor">Editor</option>
                                    <option value="Translator">
                                      Translator
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="row">
                                  <h6>First Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].given`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].given"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>MI / Middle</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].middlename`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Last Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].family`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].family"
                                  />
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="row">
                                  <h6>suffix</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].suffix`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              {values.author && values.author.length == 1 ? (
                                ""
                              ) : (
                                <div className="col-md-1">
                                  <div className="row">
                                    <img
                                      alt="delete"
                                      src={deletes}
                                      style={{
                                        height: "20px",
                                        width: "15px",
                                        marginLeft: "8px",
                                        marginTop: "30%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-md-2"></div>
                            </div>
                          </div>
                        ))
                      : ""}
                    <div
                      className="row"
                      style={{ marginTop: "20px", marginLeft: "125px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outlined-primary"
                        style={{ color: "#1976d2" }}
                        onClick={() =>
                          arrayHelpers.push({
                            title: "Author",
                            given: "",
                            family: "",
                            suffix: "",
                            middle: "",
                          })
                        }
                      >
                        <img src={plus} className="ft_sm mr-2" alt="plus" />
                        Add another Author
                      </button>
                    </div>
                  </div>
                )}
              />

              {/* author */}

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* In print publication info */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>
                    In print publication info
                  </h3>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-2%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                      }}
                    >
                      Source title
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-4px", marginTop: "-4%" }}>
                      <Field name={`title`} as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="title" />
                      {/* {errors.title && touched.title ? (
                        <div>{errors.title}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Advanced info
                  </h6>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "10px", marginLeft: "-4px" }}
                >
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Vol.</h6>
                      <br />
                      <Field name={`volume`} as={InputBoxTwo} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Edition</h6>
                      <br />
                      <Field name={`edition`} as={InputBoxTwo} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Series</h6>
                      <br />
                      <Field name="issued.season" as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="issued.season" />
                      {/* {errors.issued.season && touched.issued.season ? (
                        <div>{errors.issued.season}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Publication info
                  </h6>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "10px", marginLeft: "-4px" }}
                >
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Publisher</h6>
                      <br />
                      <Field name={`publisher`} as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="publisher" />
                      {/* {errors.publisher && touched.publisher ? (
                        <div>{errors.publisher}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>City</h6>
                      <br />
                      <Field name="publisher-place" as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="publisher-place" />
                      {/* {errors.publisherplace && touched.publisherplace ? (
                        <div>{errors.publisherplace}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Year</h6>
                      <br />
                      <Field name={`year`} as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="year" />
                      {/* {errors.year && touched.year ? (
                        <div>{errors.year}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Website publication info - FOR E-BOOK ONLINE */}

              {formType === "E_Book_Online" ? (
                <>
                  <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                    <div className="row">
                      <h3 style={{ marginLeft: "8px" }}>
                        Website publication info
                      </h3>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-4">
                        <h6
                          style={{
                            marginLeft: "-4px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "19px",
                          }}
                        >
                          Website title
                        </h6>
                        <br />
                        <div style={{ marginLeft: "-3%", marginTop: "-4px" }}>
                          <Field name={`websitetitle`} as={InputBoxOne} />
                          <ErrorMessage
                            as={InputFeedback}
                            name="websitetitle"
                          />
                          {/* {errors.websitetitle && touched.websitetitle ? (
                            <div>{errors.websitetitle}</div>
                          ) : null} */}
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-4">
                        <h6
                          style={{
                            marginLeft: "-4px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "19px",
                          }}
                        >
                          Publisher/sponsor
                        </h6>
                        <br />
                        <div style={{ marginLeft: "-4px", marginTop: "-4%" }}>
                          <Field name={`publisher`} as={InputBoxTwo} />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Display URL
                      </h6>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          row={true}
                          value={url}
                          onChange={(e) => seturl(e.target.value)}
                        >
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="Yes"
                                as={RadioField}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="No"
                                as={RadioField}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>

                      <div className="col-md-6">
                        <h6
                          style={{
                            fontSize: "10px",
                            lineHeight: "13px",
                            color: "#707070",
                            marginTop: "2%",
                          }}
                        >
                          Leave out the URL unless the source cannot be located
                          without it.
                        </h6>
                      </div>
                    </div>

                    {url === "Yes" ? (
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <h6
                            style={{
                              marginLeft: "-3%",
                              fontWeight: "bold",
                              fontSize: "14px",
                              lineHeight: "19px",
                            }}
                          >
                            URL
                          </h6>
                          <br />
                          <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                            <Field name={`URL`} as={InputBoxOne} />
                            <ErrorMessage as={InputFeedback} name="URL" />
                            {/* {errors.URL && touched.URL ? (
                                  <div>{errors.URL}</div>
                                ) : null} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row mt-4">
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Electronically Published
                      </h6>
                    </div>

                    <FieldArray
                      name="issued"
                      render={(arrayHelpers) => (
                        <div>
                          <div
                            className="row"
                            style={{ marginTop: "10px", marginLeft: "-4px" }}
                          >
                            {values.issued["date-parts"][0].map(
                              (date, index) => (
                                <div className="col-md-2">
                                  <div className="row">
                                    <h6>
                                      {index == 0
                                        ? "Year"
                                        : index == 1
                                        ? "Month"
                                        : "Day"}
                                    </h6>
                                    <br />
                                    <Field
                                      name={
                                        index == 0
                                          ? "year"
                                          : `issued["date-parts"][0][${index}]`
                                      }
                                      as={
                                        index === 0 ? InputBoxOne : InputBoxTwo
                                      }
                                    />
                                    {index == 0 ? (
                                      <ErrorMessage
                                        as={InputFeedback}
                                        name="year"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <div className="row mt-4">
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Date accessed
                      </h6>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-2">
                        <div className="row" style={{ marginLeft: "-6px" }}>
                          <h6>Day</h6>
                          <br />
                          <Field as={InputBoxTwo} name="accessed[0].day" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="row">
                          <h6>Month</h6>
                          <br />
                          <Field as={InputBoxTwo} name="accessed[0].month" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="row">
                          <h6>Year</h6>
                          <br />
                          <Field as={InputBoxOne} name="accessed[0].year" />
                          <ErrorMessage
                            as={InputFeedback}
                            name="accessed[0].year"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-3">
                        <div className="row">
                          <h6
                            style={{
                              fontSize: "10px",
                              lineHeight: "13px",
                              color: "#707070",
                              marginTop: "13%",
                            }}
                          >
                            Use Today's Date
                          </h6>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* Website publication info */}

              {/* Online Database publication info - FOR ONLINE DATABASE */}

              {formType === "Online_DB" ? (
                <>
                  <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                    <div className="row">
                      <h3 style={{ marginLeft: "8px" }}>
                        Online publication info
                      </h3>
                    </div>

                    {/* <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-4">
                        <h6
                          style={{
                            marginLeft: "-3%",
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "19px",
                          }}
                        >
                          Database
                        </h6>
                        <br />
                        <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                          <Field name="DOI" as={InputBoxTwo} />
                        </div>
                      </div>
                    </div> */}

                    <div className="row mt-4">
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Date accessed
                      </h6>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-2">
                        <div className="row" style={{ marginLeft: "-6px" }}>
                          <h6>Day</h6>
                          <br />
                          <Field as={InputBoxTwo} name="accessed[0].day" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="row">
                          <h6>Month</h6>
                          <br />
                          <Field as={InputBoxTwo} name="accessed[0].month" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="row">
                          <h6>Year</h6>
                          <br />
                          <Field as={InputBoxOne} name="accessed[0].year" />
                          <ErrorMessage
                            as={InputFeedback}
                            name="accessed[0].year"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-3">
                        <div className="row">
                          <h6
                            style={{
                              fontSize: "10px",
                              lineHeight: "13px",
                              color: "#707070",
                              marginTop: "13%",
                            }}
                          >
                            Use Today's Date
                          </h6>
                        </div>
                      </div> */}
                    </div>

                    <div className="row mt-3">
                      <p
                        style={{
                          fontSize: "10px",
                          lineHeight: "13px",
                          color: "#707070",
                          marginLeft: "8px",
                        }}
                      >
                        Note: MLA 7 says to leave out the URL unless the source
                        cannot be located without it or if instructor requires
                        it.
                      </p>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Display URL
                      </h6>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          row={true}
                          value={url}
                          onChange={(e) => seturl(e.target.value)}
                        >
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="Yess"
                                as={RadioField}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="Noo"
                                as={RadioField}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>

                      <div className="col-md-6">
                        <h6
                          style={{
                            fontSize: "10px",
                            lineHeight: "13px",
                            color: "#707070",
                            marginTop: "2%",
                          }}
                        >
                          Leave out the URL unless the source cannot be located
                          without it.
                        </h6>
                      </div>
                    </div>

                    {url === "Yess" ? (
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <h6
                            style={{
                              marginLeft: "-3%",
                              fontWeight: "bold",
                              fontSize: "14px",
                              lineHeight: "19px",
                            }}
                          >
                            URL
                          </h6>
                          <br />
                          <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                            <Field name={`URL`} as={InputBoxTwo} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* Online Database publication info */}

              {/* Other publication info - FOR E-BOOK OTHER */}

              {formType === "E_Book_Other" ? (
                <>
                  <div style={{ marginLeft: "150px", marginTop: "20px" }}>
                    <div className="row">
                      <h3 style={{ marginLeft: "8px" }}>
                        Other publication info
                      </h3>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-4">
                        <h6
                          style={{
                            marginLeft: "-3%",
                            fontWeight: "bold",
                            fontSize: "14px",
                            lineHeight: "19px",
                            marginLeft: "-4px",
                          }}
                        >
                          Ex: PDF, Powerpoint, electronic
                        </h6>
                        <br />
                        <div style={{ marginLeft: "-4px", marginTop: "-4%" }}>
                          <Field name={`format`} as={InputBoxTwo} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* Other publication info */}

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* More Options */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>More Options</h3>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Annotation
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "-4px" }}>
                  <Field name={`note`} as={InputBoxThree} />
                </div>
              </div>

              {/* More Options */}

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}
export default withRouter(BookForm);

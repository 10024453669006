import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Badge from "@mui/material/Badge";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const styles = (muiBaseTheme) => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  content: {
    textAlign: "center",
    padding: muiBaseTheme.spacing(2),
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
});

const colors = [
  "#bff603", "yellow", "#80d4ff", "orange",
  "#ff8b94", "#d3ffce", "#9bb7d4", "#ffdb58",
  "#f1a7fe", "#ff6961", "#ffb347", "#add8e6",
  "#a0bfe6", "#89cff0", "#4f86f7", "#67e6ec",
  "#7fffd4", "#77dd77", "#b19cd9", "#ff9999"
];

const TeamCard = ({ classes, teamdata, openChat }) => {
  const letterToColorIndex = (letter) => {
    const lowercaseLetter = letter.toLowerCase();
    const charCode = lowercaseLetter.charCodeAt(0) - 97;
    return charCode % colors.length;
  };

  const colorIndex = letterToColorIndex(teamdata.username.charAt(0));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: teamdata.status === 'Online' ? '#44b700' : '#d3d6db',
      color: teamdata.status === 'Online' ? '#44b700' : '#d3d6db',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: teamdata.status === 'Online' ? 'ripple 1.2s infinite ease-in-out' : '',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <div className="col-md-3 res-Team-card teamcard DocumentCardHover">
      <Card className={classes.card} style={{ height: 200, marginBottom: "15px", border: "1px solid blue", borderRadius: "10px" }} id="tapp">
        <CardContent className={classes.content} style={{ height: 150 }}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            className="teamDPs"
          >
            <Avatar
              style={{
                backgroundColor: `${colors[colorIndex]}`,
                color: "black",
                width: 54, height: 54,
                border: "4px solid white",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)"
              }}
            >
              <h5
                style={{ marginTop: "4px" }}
                data-toggle="tooltip"
                title={teamdata.username}
              >
                {teamdata.username.charAt(0).toUpperCase()}
              </h5>
            </Avatar>
          </StyledBadge>
          <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
            id="teamMemName"
          >
            {teamdata.username}
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
            id='teamPhone'
          >
            {teamdata.phone || "xxxxxxxxxxx"}
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            id='teamMail'
            variant={"caption"}
          >
            {teamdata.email}
          </Typography>
          <Box display='flex' flexDirection='row' alignContent='center' alignItems='center'>
            <div style={{width:'100%'}}>
              <IconButton aria-label="ChatBox" onClick={openChat}>
                <Badge badgeContent={teamdata.unreadCount} color="secondary">
                  <ChatBubbleOutlineOutlinedIcon
                    className="fa fa-commenting"
                    style={{ color: "dodgerblue", fontSize: "19px", marginTop:'5px' }}
                    aria-hidden="true"
                    data-placement="right"
                    data-dismiss="modal"
                  />
                </Badge>
              </IconButton>
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(TeamCard);

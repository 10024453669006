import React, { useState } from "react";
import ProgressBarMui from "./ProgressBarMui";
import ProgressBarComp from "./ProgressBarComp";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';

const GrammarCheckAssistant = ({handleGoals, handleAIassistant, handleAIReview}) => {
    const [isOpen, setIsOpen] = useState(true)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "9px",
                overflow: "hidden",
                height: isOpen ? "auto" : "3rem",
                transition: "height 0.3s"
            }}
        >
            <div
                style={{
                    color: "#393939",
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    cursor: "pointer"
                }}
                onClick={toggle}
            >
                 {isOpen ? "Hide Assistant >>" : "Show Assistant <<"}
            </div>
            <div
                style={{
                    height: "80vh",
                    // overflowY: "auto",
                    padding: "3px",
                    margin: "0 auto"
                }}
            >
                <div
                    className="mt-4"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >Overall Score</span>
                    <span
                        style={{
                            float: "right"
                        }}
                    >87</span>
                </div>
                <div
                    className="mt-3"
                    onClick={handleGoals}
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >Goals</span>
                    
                </div>
                <div
                onClick={handleAIassistant}
                    className="mt-3"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >AI Assistant</span>
                    
                </div>
                <hr style={{ borderTop: "1px solid #A0A0A0" }} />
                {/* <ProgressBarMui /> */}
                <div className="mt-4">
                    <ProgressBarComp
                        progressLabel="Correctness"
                        checkCircleColor="#EA1537"
                        bgColor="#EA1537"
                        completed={80}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                        progressLabel="Clarity"
                        checkCircleColor="#4A6EE0"
                        bgColor="#4A6EE0"
                        completed={90}
                    />
                </div>
                <div className="mt-4">
                    <ProgressBarComp
                        progressLabel="Engagement"
                        checkCircleColor="#4AE09E"
                        bgColor="#4AE09E"
                        completed={100}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                        progressLabel="Delivery"
                        checkCircleColor="#9D15EA"
                        bgColor="#9D15EA"
                        completed={100}
                    />
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} />

                <div
                    className="mt-2"
                    style={{
                        border: "1px solid #767676",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0px auto",
                        textAlign: "center"
                    }}
                >

                    <ForumRoundedIcon style={{ color: "#767676" }} />
                    <span
                    // style={{
                    //     textAlign: "left"
                    // }}
                    >Comments</span>
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} />

                <div
                    className="mt-2"
                    onClick={handleAIReview}
                    style={{
                        border: "1px solid #3295F7",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0 auto",
                        cursor: "pointer"
                    }}
                >
                    <span
                    // style={{
                    //     textAlign: "left"
                    // }}
                    >AI Review</span>
                </div>
                {/* <ul
                    style={{
                        listStyle: "none"
                    }}
                >
                    {
                        list.map((obj) => {
                            return <li>
                                <div
                                    className="mt-2"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        borderRadius: "8px",
                                        boxShadow: "2px 2px 5px -2px #000000",
                                        padding: "7px"
                                    }}
                                >
                                    <p>{obj}</p>
                                    <p>Sept 17 | 10:00 to 11:00 AM </p>
                                    <div
                                    className="justify-content-end"
                                    >
                                    <button className="btn btn-primary">Join now</button>
                                    </div>
                                </div>
                            </li>
                        })
                    }
                </ul> */}
            </div>
        </div>
    )
}

export default GrammarCheckAssistant
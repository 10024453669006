import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import { Tabs, Tab, Box, AppBar, InputAdornment, FormControlLabel, Select, Modal, FormControl, InputLabel, NativeSelect, TextField, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';

import { Modal as B_Modal, Form } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ContributorForm } from './CitationCard';

const CitationModal = ({selectedValue,setSelectedValue, handleSaveCitations, formData, setFormData, show, setShow, handleModalClose, handleModalShow, contributors, setContributors }) => {


    const getHeadingFromSelectedValue = () => {
      switch (selectedValue) {
        case 0:
          return "Webpage";
        case 1:
          return "Journal Article";
        case 2:
          return "Book";
        case "Video":
        case "Online News Article":
        case "Image":
        case "Report":
        case "Website":
        case "Book Chapter":
        case "Online Dictionary Entry":
          setShow(true)
          return selectedValue;
        default:
          setShow(false)
          return "";
      }
    };
  
  
  
    const handleAddMember = () => {
      setContributors((prevContributors) => [
        ...prevContributors,
        { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
      ]);
    };
  
    const handleDeleteMember = (id) => {
      setContributors((prevContributors) => prevContributors.filter((contributor) => contributor.id !== id));
    };
  
    const handleInputChange = (field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      console.log(formData, "formdata");
    };
  
    const handleContributorChange = (id, field, value) => {
      setContributors((prevContributors) =>
        prevContributors.map((contributor) =>
          contributor.id === id ? { ...contributor, [field]: value } : contributor
        )
      );
    };
  
  
    const headings = ["Webpage", "Book", "Image", "Journal Article", "Online News Article", "Report", "Video"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    return (
      <>
        <a href="#" className="cite-button" onClick={handleModalShow} style={{ whiteSpace: "nowrap" }}>
          <AddIcon className="plus-icon" /> Cite Manually
        </a>
  
        <B_Modal
          show={show}
          onHide={() => {
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              url: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            });
            setShow(false)
            setSelectedValue("")
  
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])
          }}
          centered
          style={{
            fontSize: "13px",
            zIndex: "9000"
          }}
          size='lg'
        >
          <B_Modal.Header closeButton>
            <B_Modal.Title>
              <h3>{getHeadingFromSelectedValue()}</h3>
            </B_Modal.Title>
          </B_Modal.Header>
          <B_Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden', paddingLeft: '60px' }}>
            <Form>
              {/* Text Fields */}
  
  
              {(selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formChapterTitle" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Chapter Title</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  // placeholder="chapterTitle"
                  // style={{'::placeholder': { margin: 0, padding: 0 }}}
                  value={formData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formBookTitle" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Book Title</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  // placeholder="BookTitle"
                  // style={{'::placeholder': { margin: 0, padding: 0 }}}
                  value={formData.bookTitle}
                  onChange={(e) => handleInputChange("bookTitle", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formTitle" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Title</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formJournalName" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Journal Name</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.journalName}
                  onChange={(e) => handleInputChange("journalName", e.target.value)}
                />
              </Form.Group>
              )}
              
  
              {(selectedValue === 0 || selectedValue === 'Image' ) && (
              <Form.Group controlId="formDescription" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Description</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  // placeholder="Description"
                  value={formData.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                />
              </Form.Group>
              )}
  
  
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report') && (
              <Form.Group controlId="formWebsiteName" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Website Name</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  // placeholder="Website Name"
                  value={formData.websiteName}
                  onChange={(e) =>
                    handleInputChange("websiteName", e.target.value)
                  }
                />
              </Form.Group>
              )}
  
              {/* Contributors Dropdown */}
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report') && (
              <Form.Group controlId="formContributors">
                <Accordion style={{ width: '93.3%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Contributors</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    {contributors.map((contributor, index) => (
                      <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                        <ContributorForm
                          contributor={contributor}
                          handleDelete={() => handleDeleteMember(contributor.id)}
                          handleInputChange={(field, value) =>
                            handleContributorChange(contributor.id, field, value)
                          }
                        />
                      </div>
                    ))}
                    <div style={{ width: '100%', marginBottom: '10px' }}>
                      <B_Button variant="primary" onClick={handleAddMember}>Add Member +</B_Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
  
              </Form.Group>
              )}
  
  
            {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formEdition" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Edition</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.edition}
                  onChange={(e) => handleInputChange("edition", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
              {(selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formVolumeNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Volume Number</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.volumeNumber}
                  onChange={(e) => handleInputChange("volumeNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formMedium" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Medium</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.medium}
                  onChange={(e) => handleInputChange("medium", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formIssueNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Issue Number</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.issueNumber}
                  onChange={(e) => handleInputChange("issueNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formLibraryDatabase" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Library Database</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.libraryDatabase}
                  onChange={(e) => handleInputChange("libraryDatabase", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formPage" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Page</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.page}
                  onChange={(e) => handleInputChange("page", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formDOI" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">DOI</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.DOI}
                  onChange={(e) => handleInputChange("DOI", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {/* Publication Date */}
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue
              === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formPublicationDate" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Publication Date</Form.Label>
                <Form.Control
                  className="col-md-2"
                  type="number"
                  placeholder="Year"
                  min={1}
                  style={{ marginRight: '30px' }}
                  value={formData.publicationDate.year}
                  onChange={(e) =>
                    handleInputChange("publicationDate", {
                      ...formData.publicationDate,
                      year: e.target.value,
                    })
                  }
                />
                <Form.Control
                  className="col-md-3"
                  as="select"
                  placeholder="Month"
                  style={{ marginRight: '32px' }}
                  value={formData.publicationDate.month}
                  onChange={(e) =>
                    handleInputChange("publicationDate", {
                      ...formData.publicationDate,
                      month: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control
                  className="col-md-2"
                  type="number"
                  placeholder="Day"
                  style={{ marginRight: '10px' }}
                  value={formData.publicationDate.day}
                  min={1}
                  max={31}
                  onChange={(e) =>
                    handleInputChange("publicationDate", {
                      ...formData.publicationDate,
                      day: e.target.value,
                    })
                  }
                />
              </Form.Group>
              )}
  
              
  
              {(selectedValue === 0 || selectedValue === 'Video' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formAccessDate" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Access Date</Form.Label>
                {/* Similar structure as Publication Date */}
                <Form.Control
                  className="col-md-2"
                  type="number"
                  placeholder="Year"
                  min={1}
                  style={{ marginRight: '30px' }}
                  value={formData.accessDate.year}
                  onChange={(e) =>
                    handleInputChange("accessDate", {
                      ...formData.accessDate,
                      year: e.target.value,
                    })
                  }
                />
                <Form.Control
  
                  className="col-md-3"
                  as="select"
                  placeholder="Month"
                  style={{ marginRight: '32px' }}
                  value={formData.accessDate.month}
                  onChange={(e) =>
                    handleInputChange("accessDate", {
                      ...formData.accessDate,
                      month: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control
                  className="col-md-2"
                  type="number"
                  placeholder="Day"
                  style={{ marginRight: '10px' }}
                  value={formData.accessDate.day}
                  min={1}
                  max={31}
                  onChange={(e) =>
                    handleInputChange("accessDate", {
                      ...formData.accessDate,
                      day: e.target.value,
                    })
                  }
                />
              </Form.Group>
              )}
  
  
            {(selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formPublisher" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Publisher</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.publisher}
                  onChange={(e) => handleInputChange("publisher", e.target.value)}
                />
              </Form.Group>
              )}
  
            
  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Report') && (
          //    <Form.Group controlId="formPlaceOfPublication" className="row justify-content-evenly">
          //    <Form.Label className="col-md-3 pt-2">Place of Publication</Form.Label>
          //    <Form.Control
          //      className="col-md-2"
          //      type="text"
          //      placeholder="Country"
          //      style={{ marginRight: '30px' }}
          //      value={formData.placeOfPublication.country}
          //      onChange={(e) =>
          //        handleInputChange("placeOfPublication", {
          //          ...formData.placeOfPublication,
          //          country: e.target.value,
          //        })
          //      }
          //    />
           
          //    <Form.Control
          //      className="col-md-3"
          //      type="text"
          //      placeholder="State"
          //      style={{ marginRight: '32px' }}
          //      value={formData.placeOfPublication.state}
          //      onChange={(e) =>
          //        handleInputChange("placeOfPublication", {
          //          ...formData.placeOfPublication,
          //          state: e.target.value,
          //        })
          //      }
          //    />
           
          
          //    <Form.Control
          //      className="col-md-2"
          //      type="text"
          //      placeholder="City"
          //      style={{ marginRight: '10px' }}
          //      value={formData.placeOfPublication.city}
          //      onChange={(e) =>
          //        handleInputChange("placeOfPublication", {
          //          ...formData.placeOfPublication,
          //          city: e.target.value,
          //        })
          //      }
          //    />
          //  </Form.Group>
  
          <Form.Group controlId="formPlaceOfPublication" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Place of Publication</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.placeOfPublication}
                  onChange={(e) => handleInputChange("placeOfPublication", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formSource" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Source</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.source}
                  onChange={(e) => handleInputChange("source", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formURL" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">URL</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.url}
                  onChange={(e) => handleInputChange("url", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 'Report') && (
              <Form.Group controlId="formIdentifyingNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Identifying Number</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.identifyingNumber}
                  onChange={(e) => handleInputChange("identifyingNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formISBN" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">ISBN</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.ISBN}
                  onChange={(e) => handleInputChange("ISBN", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formISSN" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">ISSN</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.ISSN}
                  onChange={(e) => handleInputChange("ISSN", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formArticleNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2">Article Number or eLocater</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={formData.articleNumber}
                  onChange={(e) => handleInputChange("articleNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
            </Form>
          </B_Modal.Body>
          <B_Modal.Footer>
            <B_Button variant="primary" onClick={handleSaveCitations}>
              Save Citations
            </B_Button>
          </B_Modal.Footer>
        </B_Modal>
      </>
    );
  };

  export default CitationModal
import React, { Component } from 'react'
import ReactApexChart from "react-apexcharts";
export default class Piechart extends Component {
    state={
       
        
        options: {
            colors: ['#27beb8', '#3ba500', '#ff9500','#0870e7'],
          chart: {
            type: 'donut',
           
           
          },
          fill: {
            colors: ['#27beb8', '#3ba500', '#ff9500','#0870e7']
          },
          labels:['Issues Found','Rejected Issue','Accept Issue','No of words used'],
        
          legend: {
            show:true,
            position: "bottom",
            horizontalAlign: 'center',
            fill:{colors: ['#27beb8', '#3ba500', '#ff9500','#0870e7']}
          },
          dataLabels: {
            enabled: false
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 500
              },
             
            }
          }]
        },
      series : [
        this.props.data.totalError || 0,
        this.props.data.userAction.Reject || 0,
        this.props.data.userAction.Accept || 0,
        this.props.data.noOfWords || 0
      ]
          
    }
async componentDidMount(){
  console.log(this.props.data)
}

componentDidUpdate(prevProps) {
  if (this.props.data !== prevProps.data) {
    this.setState({ 
      series: [
        this.props.data.totalError || 0,
        this.props.data.userAction.Reject || 0,
        this.props.data.userAction.Accept || 0,
        this.props.data.noOfWords || 0
      ]
     })
  }
}
    


    render() {
   
    
        return (
            
                  <div id="chart" style={{marginTop:"20%"}}>
                  <ReactApexChart options={this.state.options}  series={this.state.series} type="donut" height={255} />
                  </div>
           
     
        )
    }
}

import React, { useState, useEffect } from 'react';
import '../FormComponents/CoAuthor_M.css'
import Switch from '@mui/material/Switch';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton,Typography,Modal,Accordion, AccordionSummary, AccordionDetails,} from '@material-ui/core';
import { Card, CardContent,Menu , Checkbox} from '@material-ui/core';

import { Modal as B_Modal, Form } from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuItem from '@mui/material/MenuItem';
import {  TextField} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SelectDropdownMui from "../../ReusableComponents/SelectDropdownMui";
// import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';    
import citationService from '../../../services/citationService';
import { v4 as uuidv4 } from 'uuid';
import { CoAuthor_CitationCard } from '../CitationCard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';











export const Coauthor_Citationcomponent =  ()=>{


  let suggestionArray = [];

    const [citations, setCitations] = useState([]);  
    const [composeState, setComposestate] = useState(false)
    const [createCitationState, setCreateCitationState] = useState(true)
    const [creatClassState, setCreateClassstate] = useState(false)
    const [sourceitem, setSourceitem] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [websiteData, setWebsiteData] = useState(null);
    const [isCopied, setIsCopied] = useState(false);


    
    const [sourceList, setsourceList] = useState([
      {
        "value": "Website",
        "name": "Website"
      },
      {
        "value": "Journal Article",
        "name": "Journal Article"
      },
      {
        "value": "Book",
        "name": "Book"
      }
    ]);
  
    const [professionList, setProfessionList] = useState([
  
      {
        "value": "AMA 10th edition",
        "name": "AMA 10th edition"
      },
      {
        "value": "American Chemical Society",
        "name": "American Chemical Society"
      },
      {
        "value": "APA 11th edition",
        "name": "APA 11th edition"
      },
      {
        "value": "APA 6th edition",
        "name": "APA 6th edition"
      },
      {
        "value": "APA 7th edition",
        "name": "APA 7th edition"
      },
      {
        "value": "Chicago author",
        "name": "Chicago author"
      }
    ]);

    const [formData, setFormData] = useState({
        heading: "",
        title: "",
        description: "",
        websiteName: "",
        contributors: [],
        url: "",
        publicationDate: {
          year: "",
          month: "",
          day: "",
        },
        accessDate: {
          year: "",
          month: "",
          day: "",
        },
        // sourceType: ''
      });
    
      const [contributors, setContributors] = useState([
        { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
      ]);
    
      const [createcitation , setCreatecitation] = useState(false)
      const [Currentpage , setCurrentpage] = useState(true)
      const headings = ["Webpage", "Website", "Book", "Book Chapter", "Image", "Journal Article", "Online Dictionary Entry", "Online News Article", "Report", "Video"];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    
  
    const [citationStyle, setCitationStyle] = useState("")
    // const [homeCitationstate , sethomeCitationstate]=useState(false)
    const handleSource = (e) => {
      setSourceitem(e)
    }
  
    const handleCreateCitation = () => {
      setCreateClassstate(true)
      setCreateCitationState(false)
    }
  
    const handleCompose = () => {
      setCreateClassstate(false)
      setComposestate(true)
  
    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseAccordion = () => {
      setWebsiteData(null);
    };

    const handleCopyClick = () => {
      setIsCopied(true);
      toast.success("Text copied")
    };

    const handleCiteButtonClick = async () => {
      if (!searchInput.trim()) {
        toast.warn("Search bar is empty");
        return;
      }
  
        try {
          const response = await citationService.getCheckCitation(searchInput, citationStyle);
          if (response.status === 400) {
            toast.error("Error fetching data");
            return;
          }
    
          console.log("Backend Response:", response);
          let responseOutput = response?.data?.output;
          const parsedData = JSON.parse(responseOutput);
          suggestionArray = parsedData.replacements?.corrections;
          console.log("changedJSon_", suggestionArray, parsedData);
        } catch (error) {
          toast.warn("Error");
          console.error("Error calling backend API:", error.message);
        }
    
        setWebsiteData(suggestionArray);
      
    };

    const handleSaveCitations2 = async () => {
      // Handle save logic here
      const email = localStorage.getItem("currentUserEmail")
      try {
        const response = await citationService.getAddCitation2(websiteData[0], email);
        console.log("Backend Response:", response);
        if (response.status == 200) {
          toast.success("Citation saved successfully")
          fetchCitationDetails()
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
      }
  
      handleClose();
  
    };

    


  
    const handleCitationdStyle = (e) => {
      let value = e.target.value
      console.log("handleCitationdStyle", e, value)
      setCitationStyle(value)
    }

    const fetchCitationDetails = async () => {
      try {
        const email = localStorage.getItem("currentUserEmail");
        let data={email}
        const response = await citationService.getCitationDetails(data);
        if (response.status === 200) {
          //setInitialCitations(response.citationDetails);
          //setTotalSource(response.citationDetails.length)
          setCitations(response.citationDetails);
          //setTotalSort('');
          //setDisplayByDate(false)
        } else {
          console.error("Error fetching citation details:", response.message);
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
      }
    };
  
    useEffect(() => {
      fetchCitationDetails(); // Fetch data when the component mounts
    }, []);

  
 
    
      const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
        console.log(formData, "formdata");
      };
    
      const handleAddMember = () => {
        setContributors((prevContributors) => [
          ...prevContributors,
          { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
        ]);
      };
    
      const handleContributorChange = (id, field, value) => {
        setContributors((prevContributors) =>
          prevContributors.map((contributor) =>
            contributor.id === id ? { ...contributor, [field]: value } : contributor
          )
        );
      };
    
      const handleDeleteMember = (id) => {
        setContributors((prevContributors) => prevContributors.filter((contributor) => contributor.id !== id));
      };
    
    
      const handleSaveCitations = async () => {
        const email = localStorage.getItem("currentUserEmail")
        // Include contributors data in the formData state
        const updatedFormData = {
          ...formData,
          email:email,
          contributors: contributors,
        };
    
        console.log(updatedFormData,"TESTIIIIIIIIIIINNNNNNNNNNNGGGGGGGGGGGGGGGGGGG")
    
    
        // Handle save logic here using updatedFormData
        try {
          const response = await citationService.getAddCitation(updatedFormData);
          console.log("Backend Response:", response);
    
          if (response.status == 200) {
            toast.success("Citation saved successfully")
            fetchCitationDetails()
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              url: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            })
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])

            console.log("ALL CITATIONS",citations)
          }
        } catch (error) {
          console.error("Error calling backend API:", error.message);
        }
      };
     
      const backeventhanndlrC = ()=>{
             setComposestate(false)
            setCreateClassstate(true)
            setCreatecitation(false)
          }
          
    const backButton = () => {
        setCreateClassstate(false)
        setCreateCitationState(true)
      }
  
  
  
    return (
      <>
        {/* 
          {homeCitationstate && 
          <>
          <CitationclassComponent/>
          </>
          } */}
  
        {createCitationState &&
  
          <div>
            {/* <div> <p style={{colour:'blue',marginLeft:"12px"}}  onClick={Backtohomecitation}>Back</p></div> */}
            {/* <Row
              style={{
                width: '438px',
                zIndex: 1000,
                height: '64px',
                borderRadius: '29px',
                border: '1px solid #9F9F9F',
                backgroundColor: '#FFFFFF',
                // paddingTop: '3px',
                // paddingLeft: '3px',
                // paddingRight: '3px',
                boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
                marginLeft: 'auto',
                marginTop: '10px',
                marginRight: 'auto',
                position: "relative"
  
              }}
              className="citation-accordion"
            > */}
              {/* <Col xs={4}> */}
  
                <div className='mt-3 px-2' 
                >
                  <SelectDropdownMui
                    optionsList={professionList}
                    selectLabel={"Citation Style"}
                    onChange={(e) => handleCitationdStyle(e)}
                    value={citationStyle}
                  />
                </div>
              {/* </Col> */}
            {/* </Row> */}
  
            <div style={{ marginTop: "100px" }}>
              <div 
              style={{ borderRadius: "10px", backgroundColor: "#FCFCFC", color: "#1976D2", width: "134px", display: 'flex', justifyContent: "center", padding: "5px", margin: "auto", border: "1px solid #1976D2", cursor:"pointer" }}
                onClick={handleCreateCitation}>Create Citation</div>
            </div>
          </div>
        }
  
  
  
  {creatClassState &&
        <div>
          <div className='p-2'>
            <Button variant="contained" color="primary"
              onClick={backButton} startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
              Back
            </Button>
          </div>
          {/* <div> <p style={{colour:'blue',marginLeft:"12px"}}  onClick={Backtohomecitation}>Back</p></div> */}
          <Row
            style={{
              width: '438px',
              zIndex: 1000,
              height: '64px',
              borderRadius: '29px',
              border: '1px solid #9F9F9F',
              backgroundColor: '#FFFFFF',
              // paddingTop: '3px',
              // paddingLeft: '3px',
              // paddingRight: '3px',
              boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
              marginLeft: 'auto',
              marginTop: '10px',
              marginRight: 'auto',
              position: "relative"

            }}
            className="citation-accordion"
          >
            <Col xs={4}>

              <div className='' style={{ width: "100px", fontSize: '12px' }}>
                <label style={{ marginLeft: "2px" }}>Citation Source</label>
                <SelectDropdownMui
                  optionsList={sourceList}
                  selectLabel={"Website"}
                  onChange={(e) => handleSource(e.target.value)}
                  value={sourceitem}
                />
              </div>
            </Col>

            <Col xs={1}>
              <div style={{ width: '1px', height: '100%', backgroundColor: "#A0A0A0", }}></div>
            </Col>

            <Col xs={4}>
              <FormControl fullWidth>
                <TextField
                  id="standard-search"
                  label="Enter Url"
                  placeholder="Enter URL"
                  type="search"
                  variant="standard"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                    if (!e.target.value.trim()) {
                      handleCloseAccordion();
                    }
                  }}
                />
                <span></span>
              </FormControl>
            </Col>

            <Col xs={3}>
              <B_Button  onClick={handleCiteButtonClick} style={{ marginTop: '10px', color: 'white', marginRight: '10px', width: "79px", height: '44.84px', borderRadius: "29px", backgroundColor: '#3295f7' }}  >
                Cite
              </B_Button>

            </Col>

            {websiteData && (
              <Accordion style={{ width: '97%', zIndex: 1001 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography><b>{websiteData[0].title} - {websiteData[0].description}</b></Typography>

                </AccordionSummary>
                <AccordionDetails style={{ position: 'relative' }}>

                  <Typography><p>{websiteData[0].sourceType} | {websiteData[0].publicationDate.year}</p></Typography>


                  {/* Save Icon */}
                  <div style={{ display: 'flex' }}>
                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '90px' }}
                      onClick={handleSaveCitations2}
                      title={"Save Citation"}
                    >
                      <AssignmentTurnedInIcon data-toggle="tooltip" />
                    </IconButton>

                    <CopyToClipboard
                      text={`${websiteData[0].title} - ${websiteData[0].description}\n${websiteData[0].pageType} | ${websiteData[0].publicationDate.year}`}
                      onCopy={handleCopyClick}
                    >

                      <IconButton
                        style={{ position: 'absolute', bottom: '10px', right: '50px' }}
                        title={"Copy"}
                      >
                        <FileCopyIcon data-toggle="tooltip" />

                      </IconButton>
                    </CopyToClipboard>

                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '10px' }}
                      onClick={handleCloseAccordion}
                      title={"Close"}
                    >
                      <CloseIcon data-toggle="tooltip" />
                    </IconButton>
                  </div>

                </AccordionDetails>
              </Accordion>
            )}
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div 
            style={{ borderRadius: "10px", backgroundColor: "#FCFCFC", color: "#1976D2", width: "134px", display: 'flex', justifyContent: "center", padding: "5px", margin: "auto", border: "1px solid #1976D2", cursor: "pointer"}} 
            onClick={() => { handleCompose() }}> + Compose</div>
            <div><p style={{ width: "310px", margin: "auto", textAlign: "center" }}>Cite a Wide range of source and export them in your favorite styles!</p></div>
          </div>

        


       
          <Container style={{ marginTop: "35px" }}>
              {citations.map((citation, index) => (
                <CoAuthor_CitationCard citation={citation} index={index} fetchCitationDetails={fetchCitationDetails}/> 
              ))}
            </Container>

          

        </div>

      }




    {composeState &&   
            <>
           <div>
      <div className='p-2'>
        <Button variant="contained" color="primary" onClick={backeventhanndlrC} startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
        Back
      </Button>
      </div>

 <div style={{display:'flex',justifyContent:'center',marginTop:"10px"}}>
 <Dropdown >
              <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                {formData.heading || headings[0]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {headings.map((item) => (
                  <Dropdown.Item
                    key={item}
                    onClick={() => handleInputChange("heading", item)}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
    </Dropdown>
 </div>
    <Form>
            {/* Text Fields */}
            <Form.Group controlId="formTitle" className="row justify-content-between align-items-center mt-4 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Title</Form.Label>
              <Form.Control
                className='col-md-7'
                type="text"
                // placeholder="Title"
                // style={{'::placeholder': { margin: 0, padding: 0 }}}
                value={formData.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2">Description</Form.Label>
              <Form.Control
                className='col-md-7'
                type="text"
                // placeholder="Description"
                value={formData.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </Form.Group>

            <Form.Group controlId="formWebsiteName" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-4 pt-2">Website Name</Form.Label>
              <Form.Control
                className='col-md-7'
                type="text"
                // placeholder="Website Name"
                value={formData.websiteName}
                onChange={(e) =>
                  handleInputChange("websiteName", e.target.value)
                }
              />
            </Form.Group>

            {/* Contributors Dropdown */}
            <Form.Group controlId="formContributors">
              <Accordion style={{ width: '84%', margin:"auto" , marginTop:"15px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Contributors</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  {contributors.map((contributor, index) => (
                    <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                      <ContributorForm
                        contributor={contributor}
                        handleDelete={() => handleDeleteMember(contributor.id)}
                        handleInputChange={(field, value) =>
                          handleContributorChange(contributor.id, field, value)
                        }
                      />
                    </div>
                  ))}
                  <div style={{ width: '100%', marginBottom: '10px' }}>
                    <B_Button variant="primary" onClick={handleAddMember}>Add Member +</B_Button>
                  </div>
                </AccordionDetails>
              </Accordion>

            </Form.Group>


            {/* Publication Date */}
            <Form.Group controlId="formPublicationDate" className="row justify-content-evenly align-items-center custom-form-group-width-date">
              <Form.Label className="col-md-3 pt-2">Publication Date</Form.Label>
              <Form.Control
                className="col-md-3"
                type="number"
                placeholder="Year"
                min={1}
                style={{ marginRight: '5px' ,marginLeft:"5px"}}
                value={formData.publicationDate.year}
                onChange={(e) =>
                  handleInputChange("publicationDate", {
                    ...formData.publicationDate,
                    year: e.target.value,
                  })
                }
              />
              <Form.Control
                className="col-md-3"
                as="select"
                placeholder="Month"
                style={{ marginRight: '5px' }}
                value={formData.publicationDate.month}
                onChange={(e) =>
                  handleInputChange("publicationDate", {
                    ...formData.publicationDate,
                    month: e.target.value,
                  })
                }
              >
                <option value="" disabled>
                  Month
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                className="col-md-2"
                type="number"
                placeholder="Day"
                // style={{ marginRight: '' }}
                value={formData.publicationDate.day}
                min={1}
                max={31}
                onChange={(e) =>
                  handleInputChange("publicationDate", {
                    ...formData.publicationDate,
                    day: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* Access Date */}
            <Form.Group controlId="formAccessDate" className="row justify-content-evenly align-items-center custom-form-group-width-date">
              <Form.Label className="col-md-3 pt-2">Access Date</Form.Label>
              {/* Similar structure as Publication Date */}
              <Form.Control
                className="col-md-3"
                type="number"
                placeholder="Year"
                min={1}
                style={{ marginRight: '5px',marginLeft:"5px" }}
                value={formData.accessDate.year}
                onChange={(e) =>
                  handleInputChange("accessDate", {
                    ...formData.accessDate,
                    year: e.target.value,
                  })
                }
              />
              <Form.Control

                className="col-md-3"
                as="select"
                placeholder="Month"
                style={{ marginRight: '5px' }}
                value={formData.accessDate.month}
                onChange={(e) =>
                  handleInputChange("accessDate", {
                    ...formData.accessDate,
                    month: e.target.value,
                  })
                }
              >
                <option value="" disabled>
                  Month
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                className="col-md-2"
                type="number"
                placeholder="Day"
                // style={{ marginRight: '5px' }}
                value={formData.accessDate.day}
                min={1}
                max={31}
                onChange={(e) =>
                  handleInputChange("accessDate", {
                    ...formData.accessDate,
                    day: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* URL */}
            <Form.Group controlId="formURL" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-2 pt-2">URL</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.url}
                onChange={(e) => handleInputChange("url", e.target.value)}
              />
            </Form.Group>
          </Form>

         <div style={{ marginTop:"35px", display:'flex',justifyContent:'center'}}>
         <B_Button variant="primary" onClick={handleSaveCitations}>
            Save Citations
          </B_Button>
         </div>
     </div>

            </>
            }
        </>
      )
    }
const ContributorForm = ({ contributor, handleDelete, handleInputChange }) => {
    return (
      <>
        <Form>
          <Row>
            {/* Position */}
            <Col md={4}>
              <Form.Group controlId={`position_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Position</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.position}
                  onChange={(e) => handleInputChange('position', e.target.value)}
                />
              </Form.Group>
            </Col>
  
            {/* First Name */}
            <Col md={4}>
              <Form.Group controlId={`firstName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.firstName}
                  onChange={(e) => handleInputChange('firstName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`middleName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Middle </Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.middleName}
                  onChange={(e) => handleInputChange('middleName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`lastName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.lastName}
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`suffix_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Suffix</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.suffix}
                  onChange={(e) => handleInputChange('suffix', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            {/* Middle Name */}
            {/* ... (similar structure for other fields) */}
  
            {/* Delete Icon */}
            <Col md={1}>
              <IconButton onClick={handleDelete} style={{ marginTop: '15px' }}>
                <DeleteIcon style={{ marginTop: '7px' }} />
              </IconButton>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
import React, { useState } from 'react';
import '../FormComponents/CoAuthor_M.css'
import Switch from '@mui/material/Switch';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Modal as B_Modal, Form } from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import FeedbackIcon from '@mui/icons-material/Feedback';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SelectDropdownMui from "../../ReusableComponents/SelectDropdownMui";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import citationService from '../../../services/citationService';
import { v4 as uuidv4 } from 'uuid';
import { CoAuthor_Searchcomponent } from './CoAuthor_Search';
import { CoAuthor_Aireviewcomponent } from './CoAuthor_Aireview';
import { Coauthor_Notescomponent } from './CoAuthor_Notes';
import { Coauthor_Citationcomponent } from './CoAuthor_Citation';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import CoAuther_PlagiarismChecker_Text from './CoAuther_PlagiarismChecker_Text';
import CoAuther_PlagiarismChecker_addWords from './CoAuther_PlagiarismChecker_addWords';
import CoAuther_PlagiarismChecker_Blank from './CoAuther_PlagiarismChecker_Blank';
const label = { inputProps: { 'aria-label': 'Switch demo' } };




const CoauthorModal = (props) => {

  const [sourceList, setsourceList] = useState([
    {
      "value": "Individual",
      "name": "Individual"
    },
    {
      "value": "Organization",
      "name": "Organization"
    }
  ]);

  // const [activeButton, setActiveButton] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showResearchContent, setShowResearchContent] = useState(true);
  const [showTextSnippetContent, setShowTextSnippetContent] = useState(false);
  const [showFormatQuoteContent, setShowFormatQuoteContent] = useState(false);
  const [showAirplayContent, setShowAirplayContent] = useState(false);
  const [showAireviewContent, setshowAireviewContent] = useState(false)
  const [noteInput, setNoteInput] = useState('');
  const [coAuther_PlagiarismChecker, setCoAuther_PlagiarismChecker] = useState(false)

  const handletextContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(true)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)

  }

  
  const handleresarchContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(true)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)

  }

  const handcitationContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(true)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)


  }
  const handleAireview = () => {
    setShowModal(true)
    setshowAireviewContent(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setCoAuther_PlagiarismChecker(false)

  }

  const handleCloseModal = () => {
    setShowModal(false);
    // Reset all content states if needed
  };


  const handlePlagiarismCheck = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(true)

  }

  const handleTextInput = (text) => {
    setNoteInput(text);
  };

  return (
    <>
      <div>
        {/* <IconButton style={{position:"absolute",top:"100px",right:"10px"}} onClick={() => setShowModal(true)}>
        Open Modal
      </IconButton> */}

        <div style={{ minWidth: '6%', maxWidth: '6%', boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)', borderRadius: '35px', background: 'white', minHeight: '330px', maxHeight: '330px', display: 'flex', flexDirection: 'column', alignContent: 'center', position: 'fixed', top: '285px', right: '10px' }}>
          <div onClick={() => handleresarchContente()} className='search_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', paddingTop: '5px', margin: '0px 0px 10px 0px' }}>
            <div><LanguageIcon /></div>
            <div><h6>Search</h6></div>
          </div>
          <div onClick={() => handletextContente()} className='notes_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><TextSnippetIcon /></div>
            <div><h6>Notes</h6></div>
          </div>
          <div onClick={() => handcitationContente()} className='citation_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><FormatQuoteIcon /></div>
            <div><h6>Citations</h6></div>
          </div>
          <div onClick={() => handleAireview()} className='aireview_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><AirplayIcon /></div>
            <div><h6>AI Review</h6></div>
          </div>
          <div onClick={() => handlePlagiarismCheck()} style={{ display: 'flex', paddingBottom: "10px", flexDirection: 'column', color: 'black', textDecoration: 'none', textAlign: 'center', marginTop: "10px", cursor: "pointer" }}>
            <div><PlagiarismOutlinedIcon /></div>
            <div><h6 >Plagiarism Checker</h6></div>
          </div>









          <div style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0', marginTop: '15px' }}>
            <div><FeedbackIcon /></div>
            <div><h6>Feedback</h6></div>
          </div>

        </div>

        <Modal open={showModal} onClose={handleCloseModal}>
    <div className="Serive_div" style={{ width: '468px', zIndex: '1000', overflow: 'scroll',marginRight:"-5px" ,height: '100vh', position: "absolute", top: "0px", right: "0px", backgroundColor: "white", border: '1px solid  grey' , padding:"none"}}>
        <div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey " ,marginTop:"0px"}}>
          <div style={{ display: 'flex', marginBottom:"0px" }}>
            <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none',borderRight:'none', alignItems: "center"}}>
              <IconButton
              disableTouchRipple
              style={{backgroundColor: showResearchContent ? '#3ded97' : 'transparent',borderRadius:"0px",border:"none",outline: "none",marginBottom:"0px",marginTop:"0px"}}
                         
              onClick={() => handleresarchContente()}>
                <div  style={{ color: 'black', display: 'flex',alignItems: "center"}}>< LanguageIcon />
                {showResearchContent &&
                <h6  style={{ color:'black',marginLeft:'3px' , marginBottom:"0px" }}>Search</h6>
                }
                </div>
 
              </IconButton>
 
            </div>
            <div style={{ border: "1px solid grey", borderTop: 'none', borderRight:'none', borderBottom: 'none', }}>
              <IconButton
              disableTouchRipple
               style={{backgroundColor: showTextSnippetContent ? '#3ded97' : 'transparent',borderRadius:"0px",border:"none",outline: "none",}}
               onClick={() => handletextContente()}>
                <div style={{ color: 'black', display: 'flex',alignItems: "center"}}><TextSnippetIcon />
                {showTextSnippetContent &&
                  <h6  style={{ color: 'black' , marginLeft:'3px',marginBottom:"0px"  }}>Notes</h6>
                } </div>
              </IconButton>
            </div>
            <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' ,borderRight:'none'}}>
              <IconButton
              style={{backgroundColor: showFormatQuoteContent ? '#3ded97' : 'transparent',borderRadius:"0px" ,border:"none",outline: "none",alignItems: "center",display: 'flex'}}
              disableTouchRipple
               onClick={() => handcitationContente()}>
 
                <div style={{ color: 'black', display: 'flex',alignItems: "center"}}><FormatQuoteIcon />
                {showFormatQuoteContent &&
                  <h6  style={{ color: 'black' , marginLeft:'2px',marginBottom:"0px"  }}>Citation</h6>
                }</div>
              </IconButton>
            </div>
            <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
              <IconButton
              disableTouchRipple
                style={{backgroundColor: showAireviewContent ? '#3ded97' : 'transparent',borderRadius:"0px",border:"none",outline: "none",display: 'flex',alignItems: "center"}}
                onClick={() =>  handleAireview()}>
                <div style={{ color: 'black', display: 'flex',alignItems: "center"}}><AirplayIcon />
                {showAireviewContent &&
                  <h6  style={{ color: 'black' , marginLeft:'2px',marginBottom:"0px"  }}>Ai Review</h6>
                }</div>
              </IconButton>
            </div>
          </div>
          <div onClick={handleCloseModal}  className='close_coauthormodal'>
            <CloseIcon  />
          </div>
        </div>
 
 
 
 
            {showResearchContent && (
              <CoAuthor_Searchcomponent />
            )}
 
 
            {showTextSnippetContent && (
      <Coauthor_Notescomponent noteInput={noteInput} handleTextInput={handleTextInput}/>
      )}
 
 
 
            {showFormatQuoteContent && (
              <Coauthor_Citationcomponent />
 
            )}
 
      {showAireviewContent && (
        <>
        < CoAuthor_Aireviewcomponent/>
        </>
      )}
     </div>
     </Modal>
      </div>
    </>
  )
}
export default CoauthorModal;
import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import Select from 'react-select';
import JoditEditor from "jodit-react";
import httpService from "../services/httpService";
import { getPrompts, getSectorKeys, updatePrompt } from "../services/promptsService";
import { gridColumnPositionsSelector } from "@mui/x-data-grid";
import Loader from "../utils/loader";
import { toast } from "react-toastify";

const PromptsGenerator = () => {
  const [sectorData, setSectorData] = useState([
    { label: 'Defense and Aerospace', value: 'Defense and Aerospace' },
    { label: 'Healthcare and Medical Research', value: 'Healthcare and Medical Research' },
    { label: 'Information Technology', value: 'Information Technology' },
    { label: 'Construction and Infrastructure', value: 'Construction and Infrastructure' },
    { label: 'Environmental Services', value: 'Environmental Services' },
    { label: 'Education and Training', value: 'Education and Training' },
    { label: 'Public Safety and Law Enforcement', value: 'Public Safety and Law Enforcement' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Transportation', value: 'Transportation' },
  ]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 360,
    toolbar: false,
    buttons: false,
    branding: false,
    statusbar: false,
  };

  const handleSectorFieldChange = (selectedOption) => {
    setSelectedSector(selectedOption);
  
  };

  const getSectors = async () => {
    try {
      setLoading(true);
      if(selectedSector){
        const sector = selectedSector;
        const data=sector?.value;
        const response = await getSectorKeys(data)
        console.log(response,"keysresponse")
        if (response?.status === 200) {
          const keysOptions = response?.keys.map((key) => ({
            value: key,
            label: key,
          }));
          setOptions(keysOptions);
        } else {
          console.log('Sector not found or no data available.');
        }
      }
    } catch (err) {
      console.log('Error retrieving sector keys.');
    }
    finally{
      setLoading(false);
    }
  };
  useEffect(()=>{
    getSectors();
  },[selectedSector])
  console.log(options,"optionsData")

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '200px',
    }),
  };

  const handleSave = async() => {
   try {
    setLoading(true);
    const sector=selectedSector?.value;
    const section=selectedOption?.value;
    const prompt=editorContent;
    const data={sector,section,prompt}
    const response=await updatePrompt(data)
    console.log(response,"updateedResponse");
    if(response?.status==200){
      setEditorContent(response?.updatedPrompt[0]?.value);
      toast.success("Prompt Updated successfully")
    }else{
      toast.error("Error While Updating Prompt");
    }
    } catch (error) {
    console.log("Error Updating prompts")
   }finally{
    setLoading(false)
   }
  };


  const generatePrompt = async() =>{
    setLoading(true);
    try {
      const sector=selectedSector?.value;
      const section=selectedOption?.value
      const data={sector,section}
      console.log(data,"promptData")
      const response=await getPrompts(data);
      console.log(response,"promptsResponse")
      if(response.status==200){
        setEditorContent(response?.prompts[0]?.value);
        toast.success("Prompt Generated Successfully.")
      }else{
        toast.error("Error in Generating Prompt")
      }
    } catch (error) {
      console.log("Error generating prompt.")
    }finally{
      setLoading(false);
    }
  }

  return (
    <>
    <Loader loading={loading} />
      <div className='prompt-main' style={{ width: '100%', height: '80vh', backgroundColor: 'white', borderRadius: '15px' }}>
      <div className="prompt-top-bar" style={{ height: '15%', display: 'flex', alignItems: 'center', padding: '0px 20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '40%' }}>
          <div style={{ marginRight: '10px' }}>Select Sector:</div>
          <Select
            options={sectorData}
            value={selectedSector}
            onChange={handleSectorFieldChange}
            placeholder="Select"
            styles={customStyles}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', width: '40%' }}>
          <div style={{ marginRight: '10px' }}>Select Contract Type:</div>
          <Select
            options={options}
            value={selectedOption}
            onChange={handleSelectChange}
            placeholder="Select"
            styles={customStyles}
          />
        </div>
        <div style={{ alignItems: 'center', width: '20%' }}>
          <Button
            onClick={generatePrompt}
            variant="contained"
            color="primary"
            style={{ margin: '10px' }}
          >
            Generate
          </Button>
        </div>
      </div>
      <div className="prompt-editor" style={{ height: '65%' }}>
        <JoditEditor
          ref={editor}
          value={editorContent}
          config={config}
          onBlur={newContent => setEditorContent(newContent)} // Save content on editor blur
          // onChange={newContent => {}}
        />
      </div>
      <div className="prompt-footer" style={{ textAlign: 'center', padding: '16px', height: '10%' }}>
        <Button
          variant="outlined"
          color='error'
          style={{ margin: '15px' }}
          onClick={()=> {
            setEditorContent('');
            setSelectedSector(null);
            setSelectedOption(null)

        }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '15px' }}
          onClick={()=> handleSave()}

        >
          Save
        </Button>
      </div>
    </div>
    </>
  
  );
};

export default PromptsGenerator;

import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ContactCard from '../ContactCard/ContactCard';
import CreateGroupCard from '../ContactCard/CreateGroupCard';
import { ChatContext } from '../../../contexts/ChatContext';
import { Box, Typography } from '@mui/material';
import ChatServices from '../../../services/ChatServices';
import TextField from '@material-ui/core/TextField';
import Modal from "@material-ui/core/Modal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateGroup = ({ contacts , openDialog, setOpenDialog, fetchUsersChat }) => {


  const [selectedContacts, setSelectedContacts] = useState([]);
  const { userId } = useContext(ChatContext);


  const [groupName , setGroupName] = useState('');

const onSelect = (contact, isSelected) => {
  if (isSelected) {
    setSelectedContacts(prev => [...prev, contact.userId]); // Add contact if it's selected
  } else {
    setSelectedContacts(prev => prev.filter(id => id !== contact.userId)); // Remove contact if it's deselected
  }
};


  console.log(selectedContacts);


  const handleSubmit = async () => {

    if (selectedContacts.length > 0) {
      let groupId = ''
    try {
      const response = await ChatServices.createGroup(groupName, selectedContacts, groupId, userId);
      if (response.status === 200) {
        handleClose();
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }
  else{
      console.log("No contacts selected");
  }
    };

    const handleClose = () => {
      setOpenDialog(false);
      // setCloseModal(!openDialog);
      fetchUsersChat();
      setSelectedContacts([])
    };

    // const handleOnChange=(event) =>{
    //   console.log(event,"event 11")
    //   setGroupName(event.target.value)
      
    // }

    const handleChange = (event) => {
      const { name, value } = event.currentTarget;
      setGroupName( value );
  };

return (
  <>
 <Modal
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
     
        <Typography id="modal-modal-title" variant="h6" component="h2">
          New Group
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            fullWidth
            id="outlined-controlled"
            label="Enter Group Name"
            value={groupName}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
        </form>
        <div className="contacts__list">
          {contacts.map((contact, index) => (
            <CreateGroupCard key={contact._id} contact={contact} index={index} onSelect={onSelect} />
          ))}
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button onClick={handleClose} sx={{ marginRight: 1 }}>CANCEL</Button>
          <Button onClick={handleSubmit}>CREATE</Button>
        </Box>
        </Box>
      
    </Modal>
</>
)
}

export default CreateGroup;

import React, { useState, useEffect } from "react";
import ImageCard from "./ReusableComponents/ImageCard";
import SearchInputField from "./ReusableComponents/SearchInputField";
import ProjectCard from "./ReusableComponents/ProjectCard";
import rfp from ".././static/admin_dashboard/rfp.png";
import pending_for_approval from ".././static/admin_dashboard/pending_for_approval.png";
import Cancel from ".././static/admin_dashboard/Cancel.png";
import Completed from ".././static/admin_dashboard/Completed.png";
import PieChartComp from "./ReusableComponents/PieChartComp";
import "react-datepicker/dist/react-datepicker.css";
import filter from ".././static/admin_dashboard/filter.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RecentActivityCard from "./ReusableComponents/RecentActivityCard";
import ReminderCard from "./ReusableComponents/ReminderCard";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { TextField, MenuItem, Popover } from "@mui/material";
import Draggable from "react-draggable";
import ActiveProjectsPage from "./ActiveProjectsPage";
import { useDispatch, useSelector } from "react-redux";
import {
  myprojectScreen,
  proposalWritingData,
  reminderAssetid,
  rfmManagmentData,
} from "../redux/opportunity/opportunity.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  getCancelledProjects,
  getCompletedProjects,
  getInprogressProjects,
  getProjectProgress,
  getSpecificAsset,
  getactiveassets,
  getassetsbyid,
  getrecentactivities,
  getAllocatedProjects
} from "../services/assetService";
// import { parseISO } from "date-fns";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { markasread, markallasread } from "../services/activityTransaction";
import { toast } from "react-toastify";
import CancelledProjectsPage from "./CancelledProjectsPage";
import CompletedProjectsPage from "./CompletedProjectPage";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getDashboardSectionsVisibility,
  resetDashboardCustomization,
  updateDashboardSectionsPosition,
  updateDashboardSectionsVisibility,
} from "../services/dashboardServices";
import "../css/dashboard.css";
import { showToast } from "../messages";

const DashboardPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [showAllDeadlines, setShowAllDeadlines] = useState(false);
  const [showActiveProjectsPopup, setShowActiveProjectsPopup] = useState(false);
  const [showCancelledProjectsPopup, setShowCancelledProjectsPopup] =
    useState(false);
  const [showCompletedProjectsPopup, setShowCompletedProjectsPopup] =
    useState(false);
  const [visibleDeadlineData, setVisibleDeadlineData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [activeProjectCount, setActiveProjectCount] = useState();
  const [cancelledProjectCount, setCancelledProjectCount] = useState();
  const [completedProjectCount, setCompletedProjectCount] = useState();
  const [pieChartData, setPieChartData] = useState([]);
  const [filterOption, setFilterOption] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  const [visibleProjectData, setVisibleProjectData] = useState([]);
  const [activityFilter, setActivityFilter] = useState(null);
  const [projectTypeFilter, setProjectTypeFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [activityAnchorEl, setActivityAnchorEl] = useState(null);
  const [projectTypeAnchorEl, setProjectTypeAnchorEl] = useState(null);
  const [visibleActivities, setVisibleActivities] = useState([]);
  const [activitySearchTerm, setActivitySearchTerm] = useState("");
  const [filterIndicator, setFilterIndicator] = useState("");
  const [activityId, setActivityId] = useState("");
  const [activityFilterAnchorEl, setActivityFilterAnchorEl] = useState(null);
  const [clearAllAnchorEl, setClearAllAnchorEl] = useState(null);
  const [statusType, setStatusType] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [searchedDataCount, setSearchedDataCount] = useState(0);
  const [rfpSearchedDataCount, setRfpSearchedDataCount] = useState(0);
  const [visibilityStates, setVisibilityStates] = useState({
    projects: { visible: true, position: { x: 0, y: 0 } },
    projectStatusCards: { visible: true, position: { x: 0, y: 0 } },
    projectProgress: { visible: true, position: { x: 0, y: 0 } },
    recentActivities: { visible: true, position: { x: 0, y: 0 } },
    deadlineReminder: { visible: true, position: { x: 0, y: 0 } },
    searchandCustomizeBtn: { visible: true, position: { x: 0, y: 0 } },
  });

  const [allocatedProjectCount, setAllocatedProjectCount] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);




  const [isMobileView, setIsMobileView] = useState(false);


  const handleResize = () => {
    if (window.innerWidth <= 500) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Check the screen size on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const visibilityStateMapping = {
    "Projects": "projects",
    "Project Status Cards": "projectStatusCards",
    "Project Progress": "projectProgress",
    "Recent Activities": "recentActivities",
    "Deadline Reminder": "deadlineReminder",
  };
  const userEmail = sessionStorage.getItem("currentUserEmail");
  const handleResetToDefault = async () => {
    try {
      const response = await resetDashboardCustomization({
        userEmail: userEmail,
      });
      if (response.status === 200) {
        setVisibilityStates(response.data);
        console.log("Dashboard reset to default successfully.");
      } else {
        console.error(
          "Failed to reset dashboard customization:",
          response.message
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while resetting dashboard customization:",
        error
      );
    }
  };

  const handleDragStop = async (e, data, section) => {
    const newPosition = { x: data.x, y: data.y };
    try {
      const response = await updateDashboardSectionsPosition({
        userEmail: userEmail,
        section,
        position: newPosition,
      });
      console.log(response.data.message);

      setVisibilityStates((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          position: newPosition,
        },
      }));
    } catch (error) {
      console.error("Error updating section position:", error);
    }
  };

  const menuItemStyles = {
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#A8D2FF",
      color: "white",
    },
    //  borderBottom:"1px solid #ccc"
  };
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleVisibilityToggle = async (option) => {
    const userEmail = sessionStorage.getItem("currentUserEmail");
    try {
      const newVisibilityStates = {
        ...visibilityStates,
        [visibilityStateMapping[option]]: {
          ...visibilityStates[visibilityStateMapping[option]],
          visible: !visibilityStates[visibilityStateMapping[option]].visible,
        },
      };
      const mappedSectionName = visibilityStateMapping[option];
      const response = await updateDashboardSectionsVisibility({
        userEmail: userEmail,
        section: mappedSectionName,
        visibility: newVisibilityStates[visibilityStateMapping[option]].visible,
      });

      if (response.status === 200) {
        setVisibilityStates(newVisibilityStates);
      } else {
        console.error("Failed to update visibility:", response.message);
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  const menuOptions = [
    "Projects",
    "Project Status Cards",
    "Project Progress",
    "Recent Activities",
    "Deadline Reminder",
  ];
  useEffect(() => {
    async function fetchVisibility() {
      try {
        const response = await getDashboardSectionsVisibility({
          userEmail: userEmail,
        });
        if (response) {
          setVisibilityStates(response?.data);
        }
      } catch (error) {
        console.error("Error fetching visibility:", error);
      }
    }

    if (userEmail) {
      fetchVisibility();
    }
  }, [userEmail]);

  const handleClearAllIconClick = (event) => {
    setClearAllAnchorEl(event.currentTarget);
  };

  const handleCloseClearAll = () => {
    setClearAllAnchorEl(null);
  };

  const handleClearAll = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      await markallasread(email);
      setRecentActivities([]);
      handleCloseClearAll();
      showToast('Success.clearActivities')
    } catch (error) {
      console.error("Error marking all activities as read:", error);
    }
  };

  const handleActivityFilterIconClick = (event) => {
    setActivityFilterAnchorEl(event.currentTarget);
  };

  const handleActivitySearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setActivitySearchTerm(value);

    // Filter recent activities by title
    const filteredData = recentActivities.filter((activity) =>
      activity.projectname.toLowerCase().includes(value)
    );
    setVisibleActivities(filteredData);
    setSearchedDataCount(filteredData.length);
  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActivityFilterChange = (filter) => {
    setActivityFilter(filter);
    filterVisibleActivities(filter, projectTypeFilter);
    setFilterIndicator(
      filter && projectTypeFilter
        ? `Filtered by Activity Type: ${filter} and Project Type: ${projectTypeFilter}`
        : filter
          ? `Filtered by Activity Type: ${filter}`
          : projectTypeFilter
            ? `Filtered by Project Type: ${projectTypeFilter}`
            : ""
    );
  };

  const handleProjectTypeFilterChange = (filter) => {
    setProjectTypeFilter(filter);
    filterVisibleActivities(activityFilter, filter);
    setFilterIndicator(
      filter && activityFilter
        ? `Filtered by Project Type: ${filter} and Activity Type: ${activityFilter}`
        : filter
          ? `Filtered by Project Type: ${filter}`
          : activityFilter
            ? `Filtered by Activity Type: ${activityFilter}`
            : ""
    );
  };

  const handleFilterOptionChange = (option) => {
    switch (option) {
      case "all":
        setActivityFilter(null);
        setProjectTypeFilter(null);
        setFilterIndicator("");
        break;
      case "activity":
        setProjectTypeFilter(null);
        break;
      case "project":
        setActivityFilter(null);
        break;
      default:
        break;
    }
    setFilterOption(option);
    filterVisibleActivities(null, null);
  };

  const filterVisibleActivities = (activityFilter, projectTypeFilter) => {
    let filteredData = recentActivities;

    if (projectTypeFilter) {
      filteredData = filteredData.filter(
        (activity) => activity.assetType === projectTypeFilter
      );
    }
    if (activityFilter) {
      filteredData = filteredData.filter((activity) =>
        activity.msg.toLowerCase().startsWith(activityFilter)
      );
    }
    setVisibleActivities(filteredData);
  };

  const filterDeadlineData = () => {
    switch (filterOption) {
      case "all":
        return visibleDeadlineData;
      // case "passed":
      //   return visibleDeadlineData.filter((item) => item.color === "black");
      case "near":
        return visibleDeadlineData.filter((item) => item.color === "red");
      case "moderatelyNear":
        return visibleDeadlineData.filter((item) => item.color === "orange");
      case "onTime":
        return visibleDeadlineData.filter((item) => item.color === "green");
      default:
        return visibleDeadlineData;
    }
  };
  const handleViewMoreDeadlines = () => {
    setShowAllDeadlines(true);
  };
  useEffect(() => {
    fetchDeadlineData();
    fetchProjectsData();
    getActiveProjectsCount();
    fetchRecentActivities();
    fetchActiveprojectsCount();
    fetchCompletedprojectsCount();
    fetchCancelledprojectsCount();
    fetchPieChartData();
    fetchAllocatedProjectsCount()
  }, []);
  async function getActiveProjectsCount() {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const ActiveProjects = await getactiveassets(data);
      setActiveProjectCount(ActiveProjects?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }
    const datePart = dateString.split(" at ")[0];
    const date = new Date(datePart);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const timeAgo = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diff = now - createdAt;

    if (diff < 60000) {
      return `${Math.floor(diff / 1000)} seconds ago`;
    } else if (diff < 3600000) {
      return `${Math.floor(diff / 60000)} minutes ago`;
    } else if (diff < 86400000) {
      return `${Math.floor(diff / 3600000)} hours ago`;
    } else {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return createdAt.toLocaleDateString(undefined, options);
    }
  };

  const getColorBasedOnDeadline = (deadlineDate) => {
    const currentDate = new Date();
    const differenceInDays = Math.ceil(
      (new Date(deadlineDate) - currentDate) / (1000 * 60 * 60 * 24)
    );

    const totalDays = 30;
    const percentageRemaining = (differenceInDays / totalDays) * 100;

    if (differenceInDays < 0) {
      return "black";
    } else if (percentageRemaining <= 33.33) {
      return "red";
    } else if (percentageRemaining <= 66.33) {
      return "orange";
    } else {
      return "green";
    }
  };

  async function fetchRecentActivities() {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getrecentactivities(data);
      if (response && response.simplifiedTransactions) {
        const activities = response.simplifiedTransactions.map((item) => ({
          projectname: item.title,
          username: item.createdBy,
          id: item._id,
          time: timeAgo(item.createdOn),
          msg: item.msg,
          assetType: item.assetType,
          assetId: item.assetId,
        }));
        const id = response.simplifiedTransactions[0]._id;
        setActivityId(id);
        setRecentActivities(activities);
      }
    } catch (error) {
      console.error("Error fetching recent activities:", error);
    }
  }

  const fetchDeadlineData = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getassetsbyid(data);
      const deadlineData = response.data
        .filter((item) => {
          const deadlineDate = new Date(item.opportunity.responseDeadLine);
          const currentDate = new Date();
          return item.opportunity.responseDeadLine !== null && deadlineDate > currentDate;
        })
        .map((item) => ({
          projectname: item.opportunity.title,
          _id: item._id,
          date: formatDate(item.opportunity.responseDeadLine),
          color: getColorBasedOnDeadline(
            formatDate(item.opportunity.responseDeadLine)
          ),
        }));
      setVisibleDeadlineData(
        deadlineData.sort((a, b) => new Date(a.date) - new Date(b.date))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // const fetchProjectsData = async () => {
  //   try {
  //     const email = sessionStorage.getItem("currentUserEmail");
  //     const data = { email };
  //     const response = await getassetsbyid(data);
  //     const projectsData = response.data
  //       .filter((project) => {
  //         const deadlineDate = new Date(project.opportunity.responseDeadLine);
  //         return deadlineDate >= new Date();
  //       })
  //       .map((project) => ({
  //         projectName: project.opportunity.title,
  //         _id:project._id,
  //         date: formatDate(project.opportunity.responseDeadLine),
  //         status: project.status
  //       }));
  //     setProjectData(projectsData);
  //     setVisibleProjectData(projectsData); // Add this line to set visibleProjectData
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchProjectsData = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getassetsbyid(data);

      const projectsData = response.data
        .filter((project) => {
          const deadlineDate = new Date(project.opportunity.responseDeadLine);
          return deadlineDate >= new Date();
        })
        .map((project) => {
          let statusDisplay;
          if (project.status) {
            switch (project.status) {
              case "Task Denied":
                statusDisplay = "Cancelled";
                break;
              case "Task Approved":
                statusDisplay = "Completed";
                break;
              case "Review Inprogress":
                statusDisplay = "In Progress";
                break;
              default:
                statusDisplay = project.status;
            }
          } else {
            statusDisplay = "Not Available";
          }
          return {
            projectName: project.opportunity.title,
            _id: project._id,
            date: formatDate(project.opportunity.responseDeadLine),
            status: statusDisplay,
          };
        });

      setProjectData(projectsData);
      setVisibleProjectData(projectsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleActiveProjectsPopupOpen = (type) => {
    setStatusType(type);
    setShowActiveProjectsPopup(true);
  };

  const handleActiveProjectsPopupClose = () => {
    setShowActiveProjectsPopup(false);
    setStatusType("");
  };

  const handleCancelledProjectsPopupOpen = () => {
    setShowCancelledProjectsPopup(true);
  };

  const handleCancelledProjectsPopupClose = () => {
    setShowCancelledProjectsPopup(false);
  };

  const handleCompletedProjectsPopupOpen = () => {
    setShowCompletedProjectsPopup(true);
  };

  const handleCompletedProjectsPopupClose = () => {
    setShowCompletedProjectsPopup(false);
  };
  // const pieChartData = [
  //   { title: "Completed", value: 30, color: "#18A350", count: 10 },
  //   { title: "Pending", value: 40, color: "#FC7575", count: 20 },
  //   { title: "In Progress", value: 30, color: "#DBBC00", count: 15 },
  // ];

  const radius = "150px";

  const MoreIconClick = () => {
    console.log("MoreIconClick clicked on Page 1");
  };
  useEffect(() => {
    setVisibleActivities(
      showAll ? recentActivities : recentActivities.slice(0, 4)
    );
  }, [recentActivities, showAll]);

  // const visibleActivities = showAll
  //   ? recentActivities
  //   : recentActivities.slice(0, 4);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    //search-related actions here
    const filteredData = projectData.filter((project) => {
      const projectNameMatch = project.projectName
        .toLowerCase()
        .includes(value);
      const deadlineMatch = project.date.toLowerCase().includes(value);
      const statusMatch = project.status?.toLowerCase().includes(value);
      return projectNameMatch || deadlineMatch || statusMatch;
    });
    setVisibleProjectData(filteredData);
    setRfpSearchedDataCount(filteredData.length);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleMarkAsReadActivity = async () => {
    try {
      const id = activityId;
      const userEmail = sessionStorage.getItem("currentUserEmail");
      const data = { id, userEmail };
      await markasread(data);
      // Remove the activity from recentActivities
      setRecentActivities(
        recentActivities.filter((activity) => activity._id !== id)
      );
    } catch (error) {
      console.error("Error marking activity as read:", error);
    }
  };
  const handleRedirecttoRfp = async (assetId, assetType) => {
    console.log("called", assetId, assetType);
    try {
      let data = {
        assetId,
      };
      const response = await getSpecificAsset(data);
      if (response.status == 200) {
        console.log(response, "specific asset found");
        const data = response.data;
        if (assetType === "rfp") {
          dispatch(
            rfmManagmentData(
              data.opportunityid.title,
              data.opportunityid.noticeId,
              data.opportunityid.descriptionData,
              data.opportunityid._id,
              data.opportunityid.type,
              data.opportunityid?.responseDeadLine,
              data.opportunityid?.fullParentPathName,
              data._id,
              data.opportunityid?.resourceLinksPath
            )
          );
          dispatch(myprojectScreen("Oppoupload"));
          history.push("/rfpManagement");
        } else if (assetType === "proposal") {
          dispatch(
            proposalWritingData(
              data.opportunityid.title,
              data.opportunityid.noticeId,
              data.opportunityid.descriptionData,
              data.opportunityid._id,
              data.opportunityid.type,
              data.opportunityid?.responseDeadLine,
              data.opportunityid?.fullParentPathName,
              data._id
            )
          );

          history.push("/proposalWriting");
        }
        handleMarkAsReadActivity();
      }
    } catch (error) {
      console.log("error in finding specific asset", error);
    }
  };

  const handleRedirect = (reminderAssetId) => {
    console.log("redirect", reminderAssetId);
    dispatch(reminderAssetid(reminderAssetId));
    history.push(`/OppourtunityManagementSubadminRedirect/${reminderAssetId}`);
  };

  const fetchActiveprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getInprogressProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count;
        setActiveProjectCount(projectsCount);
      } else {
        console.error("Error fetching projects:", response);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const fetchCancelledprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getCancelledProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count;
        setCancelledProjectCount(projectsCount);
      } else {
        console.error("Error fetching projects:", response);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const fetchCompletedprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getCompletedProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count;
        setCompletedProjectCount(projectsCount);
      } else {
        console.error("Error fetching projects:", response);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  async function fetchPieChartData() {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getProjectProgress(data);
      if (response.status === 200) {
        setPieChartData(response.pieChartData);
      } else {
        console.error("Failed to fetch project progress");
      }
    } catch (error) {
      console.error("Error fetching project progress:", error);
    }
  }

  const fetchAllocatedProjectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getAllocatedProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count;
        setAllocatedProjectCount(projectsCount);
      } else {
        console.error("Error fetching projects:", response);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };



  return (
    <div style={{
      width: window.innerWidth < 850 ? "98%" :"100%",
      height:window.innerWidth < 850 ? "" : "",
      display: "flex", justifyContent: "space-between",
      flexDirection: window.innerWidth < 850 ? "column" : "row",
      // border:"10px solid black",
      margin:window.innerWidth < 500 ? "auto" : "",
      paddingLeft:'10px'
    }}>
         {/* left section */}
         <div style={{
        width: window.innerWidth < 850 ? "100%" : "49.5%",
      
        // border:"5px solid yellow"
      }}>
        <div style={{ width: window.innerWidth < 768 ? "100%" : "100%",height:"100%" }}>
          <div style={{  width: "100%", display: "flex", justifyContent: "space-between", alignItems:window.innerWidth < 500 ? "center" : "top", flexDirection: window.innerWidth < 768 ? "column" : "row", flexWrap: "wrap",marginBottom: window.innerWidth < 500 ? "10px" : "" }}>
          {visibilityStates.projects?.visible && (
            <div style={{ width: window.innerWidth < 768 ? "100%" : '42%',marginBottom:"10px" }}>
              <SearchInputField
                placeholder="Search RFPs or Proposals.."
                onChange={handleSearchChange}
                value={searchTerm}
                height="40px"
                width="100%"
              />
            </div>
          )}
            <div style={{ width: window.innerWidth < 500 ? "100%" : "56%", display: "flex", justifyContent: "space-between"}}>
              <button
                onClick={handleMenuClick}
                style={{
                  width: "48%",
                  // border:"2px solid red",
                  height: "40px",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  // padding: "0 10px",
                  // width: "70px",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize:window.innerWidth < 500 ? "13px" : "15px"

                }}
              >
                Customization
              </button>
              <button
                onClick={handleResetToDefault}
                style={{
                  // marginLeft: "10px",
                  height: "40px",
                  width: "48%",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  padding: "0 10px",
                  // width: "23%",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize: window.innerWidth < 500 ? "13px" : "15px",
                }}
              >
                Reset to Default
              </button>
              <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  "& .MuiList-root": {
                    paddingTop: '0px !important',
                  },
                },
              }}
            >
              {menuOptions.map((option) => (
                <MuiMenuItem
                  key={option}
                  onClick={() => handleVisibilityToggle(option)}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#A8D2FF",
                      color: "white", // Optional: Change the text color to white when hovering
                    },
                  }}
                >
                  {option}
                  {visibilityStates[visibilityStateMapping[option]]?.visible ? (
                    <VisibilityOffIcon
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                        color: "gray",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleVisibilityToggle(option);
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                        color: "gray",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleVisibilityToggle(option);
                      }}
                    />
                  )}
                </MuiMenuItem>
              ))}
            </Menu>
            </div>
          </div>



          {/* working area */}

          <div style={{ width: "100%", display: "flex",height:"50%", justifyContent: "space-between",flexDirection: window.innerWidth < 768 ? "column":"row" }}>
            <div style={{ width:window.innerWidth < 768 ?"100%": "42%", height: "100%",display: "flex",flexWrap:"wrap" }}>


              <div style={{ width:"100%",height:"100%"}}>
        
        {visibilityStates.projectStatusCards?.visible && (
          <Draggable
          position={visibilityStates.projectStatusCards.position}
          onStop={(e, data) =>
            handleDragStop(e, data, "projectStatusCards")
          }
          cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
        >
            <div style={{ width:"100%",height:"100%",marginBottom: window.innerWidth < 500 ? "10px" : "",display: window.innerWidth < 500 ? "flex" : "",flexWrap: window.innerWidth < 500 ? "wrap" : "",justifyContent: window.innerWidth < 500 ? "space-between" : ""}}>
              <div
                style={{ marginBottom: "12px", cursor: "pointer",height:"23%",width: window.innerWidth < 500 ? "48%" : "100%"}}
                onClick={() =>
                  handleActiveProjectsPopupOpen("Active Projects")
                }
                className="non-draggable"
              >
                <ImageCard
                  bgColor="#4676A5"
                  altImg="Active Projects"
                  imgSrc={rfp}
                  cardName="Active Projects "
                  number={activeProjectCount}
                  height={window.innerWidth < 500 ? "70px" : "70px"}
                  width={window.innerWidth < 500 ? "100%" : "100%"}
                />
              </div>

              <div
                onClick={() =>
                  handleActiveProjectsPopupOpen("Allocated Projects")
                }
                style={{ marginBottom: "12px", cursor: "pointer",height:"23%",width: window.innerWidth < 500 ? "48%" : "100%" }}
                className="non-draggable"
              >
                <ImageCard
                  bgColor="#DBBC00"
                  altImg="In Progress"
                  imgSrc={pending_for_approval}
                  cardName="Allocated Projects"
                  number={allocatedProjectCount}
                  height={window.innerWidth < 500 ? "70px" : "70px"}
                  width={window.innerWidth < 500 ? "100%" : "100%"}
                />
              </div>
              <div
                onClick={handleCancelledProjectsPopupOpen}
                style={{ marginBottom: "12px", cursor: "pointer",height:"23%",width: window.innerWidth < 500 ? "48%" : "100%" }}
                className="non-draggable"
              >
                <ImageCard
                  bgColor="#DA4141"
                  altImg="Cancelled"
                  imgSrc={Cancel}
                  cardName="Cancelled"
                  number={cancelledProjectCount}
                  height={window.innerWidth < 500 ? "70px" : "70px"}
                  width={window.innerWidth < 500 ? "100%" : "100%"}
                />
              </div>
              <div
                onClick={handleCompletedProjectsPopupOpen}
                style={{  cursor: "pointer",height:"23%",width: window.innerWidth < 500 ? "48%" : "100%" }}
                className="non-draggable"
              >
                <ImageCard
                  bgColor="#18A350"
                  altImg="Completed"
                  imgSrc={Completed}
                  cardName="Completed"
                  number={completedProjectCount}
                  height={window.innerWidth < 500 ? "70px" : "70px"}
                  width={window.innerWidth < 500 ? "100%" : "100%"}
                />
              </div>
            </div>
            </Draggable>
        )}

      
      </div>
            </div>
            <div style={{ width: window.innerWidth < 500 ? "100%" : "56%", height: window.innerWidth < 500 ? "" : "100%",}}>

             
            {visibilityStates.projectProgress?.visible && (
          <Draggable
            position={visibilityStates.projectProgress.position}
            onStop={(e, data) => handleDragStop(e, data, "projectProgress")}
            cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
          >
            <div className="non-draggable" style={{width:"100%"}}>
              <PieChartComp
                chartHeading="Project Progress"
                // overallPercentageHeading="Success Rate"
                // overallTaskPercentage={98}
                pieChartData={pieChartData.map((data) => ({
                  ...data,
                  radius,
                }))}
                selectDropdownDisabled={false}
                // boxWidth={380}
                boxHeight={window.innerWidth < 500 ? "400px" : "345px"}
              />
           </div>
          </Draggable>
        )}
            


            </div>

            {/*end working area */}
          </div>
          <div style={{ width: "100%" }}>
            {visibilityStates.projects?.visible && (
              <Draggable
                position={visibilityStates.projects.position}
                onStop={(e, data) => handleDragStop(e, data, "projects")}
                cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
              >
                <div
                  style={{
                    marginTop: "15px",
                    border: "1px solid #ccc",
                    // marginLeft: "10px",
                    padding: "10px",
                    height: window.innerWidth < 500 ? "" : '100%',
                    maxHeight: "290px",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    // border: "1px solid #ccc",
                    boxShadow: "2px 2px 5px -2px #000000",
                    position: "relative",
                  }}
                >
                  <div style={{ marginBottom: "5px", paddingBottom: "3px", borderBottom: "1px solid lightgray" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#1976d2",
                        margin: 0,
                      }}
                    >
                      Projects
                    </p>
                  </div>
                  {searchTerm && (
                    <span style={{ fontSize: "15px", marginBottom: "10px", display: "block" }}>
                      <span style={{ fontWeight: 'bold' }}>{rfpSearchedDataCount}</span> Project Found!
                    </span>
                  )}
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "230px",
                      marginTop: "10px", // Adjusted margin to ensure proper spacing
                      width: "100%"
                    }}
                  >
                    {visibleProjectData.map((project, index) => (
                      <ProjectCard
                        key={index}
                        projectName={project.projectName}
                        date={project.date}
                        status={project.status}
                        // cardWidth={580}
                        redirect={handleRedirect}
                        assetid={project._id}
                      />
                    ))}
                  </div>
                  {/* {visibleProjectData.filter((project) => {
        const projectNameMatch = project.projectName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const deadlineMatch = project.date
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const statusMatch = project.status
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        return projectNameMatch || deadlineMatch || statusMatch;
      }).length === 0 && (
        <p style={{ alignContent: "center" }}>No search data found</p>
      )} */}
                </div>
              </Draggable>
            )}
          </div>
        </div>





      </div>
      {/* right section  */}
      <div
        style={{
          // height: "100%",
          width: windowWidth < 850 ? "100%" : "49.5%",
        
          marginLeft: windowWidth < 850 ? "0" : "10px",
          display: window.innerWidth < 500 ? "" : "flex",
          // flexWrap:"wrap",
          // flexDirection: window.innerWidth < 500 ? "column" : "row",
          // marginLeft: "10px",
          justifyContent: "space-between",
          marginTop: windowWidth < 850 ? "20px" : "",

        }}
      >

        {visibilityStates.recentActivities?.visible && (
          <Draggable
            position={visibilityStates.recentActivities.position}
            onStop={(e, data) => handleDragStop(e, data, "recentActivities")}
            cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
          >
            <div
              style={{
                width: window.innerWidth < 500 ? "100%" : "49%",
                maxHeight: 680,
                height: "100%",
                // border: "10px solid red",
                overflowY: "auto",
                boxShadow: "2px 2px 5px -2px #000000",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                marginBottom : window.innerWidth < 500 ? "10px" : ""
              }}
              className="non-draggable"
            >
              <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "3px", borderBottom: "1px solid lightgray" }}>
                <strong style={{ fontSize: "15px", color: "#1976d2" }}>
                  Recent Activities
                </strong>
                <FilterAltIcon
                  style={{ marginLeft: "110px", color: "#1976D2", cursor: "pointer" }}
                  onClick={handleActivityFilterIconClick}
                />
                <Popover
                  open={Boolean(activityFilterAnchorEl)}
                  anchorEl={activityFilterAnchorEl}
                  onClose={() => setActivityFilterAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      // padding: "5px",
                      width: "150px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #1976D2"
                    }}
                  >
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleFilterOptionChange("all")}

                    >
                      All Activities
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={(event) =>
                        setActivityAnchorEl(event.currentTarget)
                      }
                    >
                      By Type of Activity
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={(event) =>
                        setProjectTypeAnchorEl(event.currentTarget)
                      }
                    >
                      By Type of Project
                    </MenuItem>
                  </div>
                </Popover>

                {/* Sub popover for Activity */}
                <Popover
                  open={Boolean(activityAnchorEl)}
                  anchorEl={activityAnchorEl}
                  onClose={() => setActivityAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      // padding: "5px",
                      width: "90px",
                      display: "flex",
                      flexDirection: "column",

                    }}
                  >
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleActivityFilterChange("uploaded")}
                    >
                      Uploaded
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleActivityFilterChange("added")}
                    >
                      Added
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleActivityFilterChange("updated")}
                    >
                      Updated
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleActivityFilterChange("deleted")}
                    >
                      Deleted
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleActivityFilterChange("shared")}
                    >
                      Shared
                    </MenuItem>
                  </div>
                </Popover>

                {/* Sub popover for Project Type */}
                <Popover
                  open={Boolean(projectTypeAnchorEl)}
                  anchorEl={projectTypeAnchorEl}
                  onClose={() => setProjectTypeAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      // padding: "5px",
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleProjectTypeFilterChange("rfp")}
                    >
                      RFP
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => handleProjectTypeFilterChange("proposal")}
                    >
                      Proposal
                    </MenuItem>
                  </div>
                </Popover>

                {/* <MoreVertIcon
                  style={{ marginLeft: "8px", cursor: "pointer" }}
                  onClick={handleClearAllIconClick}
                /> */}
                <Popover
                  open={Boolean(clearAllAnchorEl)}
                  anchorEl={clearAllAnchorEl}
                  onClose={handleCloseClearAll}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      // padding: "5px",
                      width: "80px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MenuItem sx={menuItemStyles} onClick={handleClearAll}>
                      Clear All
                    </MenuItem>
                  </div>
                </Popover>
              </div>
              <div style={{ marginTop: "12px" }}>
                <SearchInputField
                  placeholder="Search Project Title.."
                  onChange={handleActivitySearchChange}
                  value={activitySearchTerm}
                  height="30px"
                // width="148%"
                />
              </div>
              {activitySearchTerm && searchedDataCount > 0 && (
                <span style={{ marginTop: "10px", marginLeft: "50px" }}>
                  <span style={{ fontWeight: 'bold' }}>{searchedDataCount}</span> Project's Activities Found!
                </span>
              )}

              <span
                style={{
                  textAlign: "center",
                  marginTop: filterIndicator ? "10px" : "0",
                }}
              >
                {filterIndicator}
              </span>
              {/* <div style={{ marginTop: '10px' }}>
                    <p style={{ color: '#1976D2', fontSize: '16px' }}>Today</p>
                    </div> */}
              <div style={{ marginTop: "5px", flex: 1 }}>
                {visibleActivities.length > 0 ? (
                  visibleActivities.map((activity, index) => (
                    <RecentActivityCard
                      key={index}
                      {...activity}
                      redirectToRfp={handleRedirecttoRfp}
                    />
                  ))
                ) : (
                  <p style={{ textAlign: "center" }}>No Activities Found!</p>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                   marginLeft:window.innerWidth < 500 && window.innerWidth > 390 ? "-25px" : ""
                }}
              >
                {recentActivities.length > 4 &&
                  visibleActivities.length >= 4 &&
                  !showAll && (
                    <button
                      onClick={() => setShowAll(true)}
                      style={{
                        cursor: "pointer",
                        color: "#1976D2",
                        border: "none",
                        background: "white",
                        fontSize: "14px",
                        border: "1px solid #1976D2",
                        boxShadow: "2px 2px 5px -2px #000000",
                        width: "300px",
                       
                      }}
                    >
                      View More
                    </button>
                  )}

{recentActivities.length > 4 &&
                  visibleActivities.length >= 4 &&
                  showAll && (
                    <button
                      onClick={() => setShowAll(false)}
                      style={{
                        cursor: "pointer",
                        color: "#1976D2",
                        border: "none",
                        background: "white",
                        fontSize: "14px",
                        border: "1px solid #1976D2",
                        boxShadow: "2px 2px 5px -2px #000000",
                        width: "300px",
                       
                      }}
                    >
                      View Less
                    </button>
                  )}
              </div>
            </div>
          </Draggable>
        )}


        {visibilityStates.deadlineReminder?.visible && (
          <Draggable
            position={visibilityStates.deadlineReminder.position}
            onStop={(e, data) => handleDragStop(e, data, "deadlineReminder")}
            cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
          >
            <div
              style={{
                // marginLeft: "20px",
                width:window.innerWidth < 500 ? "100%" : "49%",
                maxHeight: 680,
                height: "100%",
                // border: "1px solid #ccc",
                overflowY: "auto",
                boxShadow: "2px 2px 5px -2px #000000",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "15px",
              }}
              className="non-draggable"
            >
              <div style={{ display: "flex", paddingBottom: "3px", borderBottom: "1px solid lightgray", justifyContent:'space-between' }}>
                <strong style={{ fontSize: "15px", color: "#1976d2" }}>
                  Deadline Reminder
                </strong>
                <FilterAltIcon
                  style={{color: "#1976D2", cursor: "pointer" }}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                />
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => {
                        setFilterOption("all");
                        setShowFilterOptions(false);
                      }}
                    >
                      All Reminders
                    </MenuItem>
                    {/* <MenuItem
                    style={{ padding: "5px", cursor: "pointer" }}
                    onClick={() => {
                      setFilterOption("passed");
                      setShowFilterOptions(false);
                    }}
                  >
                    Deadline passed
                  </MenuItem> */}
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => {
                        setFilterOption("near");
                        setShowFilterOptions(false);
                      }}
                    >
                      Near
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => {
                        setFilterOption("moderatelyNear");
                        setShowFilterOptions(false);
                      }}
                    >
                      Moderately near
                    </MenuItem>
                    <MenuItem
                      sx={menuItemStyles}
                      onClick={() => {
                        setFilterOption("onTime");
                        setShowFilterOptions(false);
                      }}
                    >
                      On time
                    </MenuItem>
                  </div>
                </Popover>
                {/* <MoreVertIcon style={{ marginLeft: "10px" }} /> */}
              </div>

              <div style={{ marginTop: "15px" }}>
                {showAllDeadlines
                  ? // Render all deadline reminders if showAllDeadlines is true
                  filterDeadlineData().map((item, index) => (
                    <ReminderCard
                      key={index}
                      projectname={item.projectname}
                      date={item.date}
                      color={item.color}
                      onMoreIconClick={MoreIconClick}
                      assetid={item._id}
                      redirect={handleRedirect}
                    />
                  ))
                  : // Render only a subset of deadline reminders if showAllDeadlines is false
                  filterDeadlineData()
                    .slice(0, 5)
                    .map((item, index) => (
                      <ReminderCard
                        key={index}
                        projectname={item.projectname}
                        date={item.date}
                        color={item.color}
                        onMoreIconClick={MoreIconClick}
                        assetid={item._id}
                        redirect={handleRedirect}
                      />
                    ))}
              </div>
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {!showAllDeadlines && filterDeadlineData().length > 5 && (
                  <button
                    onClick={handleViewMoreDeadlines}
                    style={{
                      cursor: "pointer",
                      color: "#1976D2",
                      border: "none",
                      background: "white",
                      fontSize: "14px",
                      border: "1px solid #1976D2",
                      boxShadow: "2px 2px 5px -2px #000000",
                      width: "100%",
                    }}
                  >
                    View More
                  </button>
                )}

{showAllDeadlines && filterDeadlineData().length > 5 && (
                  <button
                    onClick={()=>setShowAllDeadlines(false)}
                    style={{
                      cursor: "pointer",
                      color: "#1976D2",
                      border: "none",
                      background: "white",
                      fontSize: "14px",
                      border: "1px solid #1976D2",
                      boxShadow: "2px 2px 5px -2px #000000",
                      width: "100%",
                    }}
                  >
                    View Less
                  </button>
                )}
              </div>
            </div>
          </Draggable>
        )}
      </div>
   
      {showActiveProjectsPopup && (
        <ActiveProjectsPage
          onClose={handleActiveProjectsPopupClose}
          statusType={statusType}
        />
      )}
      {showCancelledProjectsPopup && (
        <CancelledProjectsPage onClose={handleCancelledProjectsPopupClose} />
      )}
      {showCompletedProjectsPopup && (
        <CompletedProjectsPage onClose={handleCompletedProjectsPopupClose} />
      )}
    </div>
  );
};
export default DashboardPage;

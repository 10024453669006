import React, { Component } from "react";
import {
  EditorState,
  Modifier,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  AtomicBlockUtils,
  KeyBindingUtil,
} from "draft-js";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import createCounterPlugin from "draft-js-counter-plugin";
import createImagePlugin from "draft-js-image-plugin";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./draft.css";
import { BlockStyleControls, InlineStyleControls } from "./StyleButton";
import { toast } from "react-toastify";
import adminService from "../../services/adminServices";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { InlineSelect, InlineColor, InlineSize } from "./SelectItem";
import { FONT_TYPES, styleMap, FONT_COLOR, FONT_SIZE } from "./utilize";
import Tags from "../Tags";
import Loader from "../../utils/loader";
import DocUpload from "../DocUpload";
import ImageUpload from "./ImageUpload";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import ReactTour from "../ReactTour";
import { option_ } from "./utilize";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
import seoServices from "../../services/seoServices";
import { getProducts } from "../../services/productService";
import { withRouter } from "react-router-dom";
const { hasCommandModifier } = KeyBindingUtil;
const counterPlugin = createCounterPlugin();
const resizePlugin = createResizeablePlugin({
  vertical: "absolute",
  horizontal: "absolute",
});

const decorator = composeDecorators(resizePlugin.decorator);

const imagePlugin = createImagePlugin({ decorator });

const { CharCounter, WordCounter, LineCounter, CustomCounter } = counterPlugin;
const plugins = [counterPlugin, imagePlugin, resizePlugin];

class BlogManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      popoverOpen: false,
      anchorOpen: false,
      blockType: null,
      url: "",
      list: [],
      toogleClassName: {
        editor: "col-md-12",
        correction: "hideCorr",
        status: false,
      },
      sent: { emojiStatus: "Normal", emojiImg: "" },
      loading: false,
      docTitle: "",
      grade: { notes: "", schoolLevel: "", score: 0, grade: 0 },
      character_limit: null,
      reactTour: false,
      steps: [
        {
          selector: ".reactour-1",
          content: "Give document title",
          position: "bottom",
        },
        {
          selector: ".reactour-2",
          content: "Write your document content here",
          position: "top",
        },
        {
          selector: ".reactour-3",
          content: "Document Toolbar",
          position: "bottom",
        },
        {
          selector: ".reactour-4",
          content: "Add tags to current document",
          position: "bottom",
        },
        {
          selector: ".reactour-5",
          content:
            "Utility bar displaying the total characters, words, paragraphs, sentences",
          position: "top",
        },
        {
          selector: ".reactour-6",
          content: "Flesch-Kincaid Grade Level of content",
          position: "top",
        },
        {
          selector: ".reactour-7",
          content: "Tone Detector",
          position: "top",
        },
        {
          selector: ".reactour-8",
          content:
            "Upload .txt or word file to extract content and display on editor",
          position: "bottom",
        },
      ],
      title: "",
      urlValue: "",
      description: "",
      main_category: "blogs",
      category: "blog",
      blogData: "",
      tagInputRef: "",
      tags: [],
      image: [],
      junk: [],
      isUpdate: false,
      updatedDocumentId: "",
      products: [],
      keywords: "",
    };
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
  }

  componentDidMount() {
    console.log(this.props.blogDetails.location.seo);
    if (this.props.blogDetails.location.seo) {
      // console.log('props====>', this.props.blogDetails.location.seo.detail);
      let {
        main_category,
        category,
        documentName,
        editor,
        imageUrl,
        metaDescription,
        onlyDocumentContent,
        tags,
        title,
        urlValue,
        _id,
        metaKeywords,
      } = this.props.blogDetails.location.seo.detail;
      this.setState(
        {
          main_category: main_category,
          category: category,
          docTitle: documentName,
          image: imageUrl ? (imageUrl.length > 0 ? imageUrl : []) : [],
          tags: tags ? (tags.length > 0 ? tags : []) : [],
          urlValue,
          description: metaDescription,
          title: title,
          blogData: onlyDocumentContent,
          updatedDocumentId: _id,
          keywords: metaKeywords,
        },
        () => this.insertTextInTheEditor(onlyDocumentContent)
      );
    } else {
      console.log("no props were passed");
    }
    this.getCharacterLimit();
    this.Blog_Products();
  }

  Blog_Products = async () => {
    let { data } = await getProducts();
    this.setState({ products: data.products });
  };

  insertTextInTheEditor = (text) => {
    let convertToRaw = JSON.parse(text);
    const updatedContentState = convertFromRaw(convertToRaw);
    const updatedEditorState = EditorState.createWithContent(
      updatedContentState
    );
    this.onChange(updatedEditorState);
  };

  componentWillUnmount() {
    //delete from db if no title was added
  }

  handleTab = (e) => {
    e.preventDefault();
    const tabCharacter = "    ";
    let currentState = this.state.editorState;
    let newContentState = Modifier.replaceText(
      currentState.getCurrentContent(),
      currentState.getSelection(),
      tabCharacter
    );
    this.setState({
      editorState: EditorState.push(
        currentState,
        newContentState,
        "insert-characters"
      ),
    });
  };

  myKeyBindingFn = (e) => {
    // if you paste thecommand this if conditon will invoke
    if (e.keyCode === 86 /* `V` key */ && hasCommandModifier(e)) {
      this.state.currentKey = e.keyCode;
    }
  };

  removeString = (event) => {
    let maxLength = this.state.character_limit;
    const contentState = event.getCurrentContent();
    let convertRaw = convertToRaw(contentState);
    console.log("convertRaw", convertRaw);
    const blocks = convertRaw.blocks;

    let end = 0;
    const updatedBlock = blocks.map((block) => {
      const blockText = block.text;
      const blockTextLen = blockText.length;

      if (maxLength < 0) {
        end = 0;
        toast(
          `You are reached maximum character limit ${this.state.character_limit}`
        );
      } else {
        if (blockTextLen < maxLength) {
          maxLength = maxLength - blockTextLen;
          console.log("maxLength", maxLength, "blockTextLen", blockTextLen);
          end = blockTextLen;
        } else {
          end = Math.abs(maxLength);
          toast(
            `You are reached maximum character limit ${this.state.character_limit}`
          );
          maxLength = -1;
        }
      }
      console.log("endLen", end);
      const text = blockText.slice(0, end);
      block.text = text;
      return block;
    });

    convertRaw.blocks = updatedBlock;
    console.log("updatedRaw", convertRaw);
    const updatedContentState = convertFromRaw(convertRaw);
    const updatedEditorState = EditorState.createWithContent(
      updatedContentState
    );
    // setEditorState(updatedEditorState)
    return updatedEditorState;
  };

  // when you paste the conetent , need to check the grammer error
  pasteFn = (editorState_) => {
    if (this.state.currentKey !== 86) return;

    const editorState = this.removeString(editorState_);
    this.state.currentKey = null;
    this.onChange(editorState);
  };

  getCharacterLimit = async () => {
    let data = await adminService.getCharacterLimit();
    this.setState({ character_limit: data.character_limit });
  };
  setPropsValue = async (data, documentId, status = false) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({ loading: true });
    this.setState({ editorState, loading: false });
  };

  addUploadedText = (text_str, file_name) => {
    if (!text_str) return;
    this.setState({ loading: true });

    if (!this.state.docTitle) {
      this.setState({ docTitle: file_name });
    }

    let text = text_str.split(/\n+/g);
    const solt = "12345abcde";
    let convertRaw = convertToRaw(this.state.editorState.getCurrentContent());
    let blocks = convertRaw.blocks;
    text.forEach((str) => {
      const key = [...Array(5)].reduce(
        (str) => (str += solt[Math.floor(Math.random() * solt.length)]),
        ""
      );
      const structure = {
        data: {},
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key,
        text: str,
        type: "unstyled",
      };
      blocks.push(structure);
    });
    convertRaw.blocks = blocks;
    this.setPropsValue(JSON.stringify(convertRaw), null, true);
  };

  _toggleBlockType(blockType) {
    // console.log("bolockType", blockType);
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    if (inlineStyle == "ANCHOR")
      this.setState({ anchorOpen: !this.state.anchorOpen });
    else
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
      );
  }

  checkLink = () => {
    const { url } = this.state;
    let check = url.match(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
    );
    if (check == null) {
      toast.error("Please enter valid URL");
      return;
    } else {
      this.createAnchorLink();
    }
  };

  createAnchorLink = () => {
    this.setState({ anchorOpen: false });
    const { editorState, url } = this.state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    // remove anchor from tag
    //   if (!selection.isCollapsed()) {
    //     this.onChange(RichUtils.toggleLink(editorState, selection, null))
    //  }
    //  else{
    //  add anchor from tag
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: `https://${url}` }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
  };

  onChange = (editorState) => {
    const current_content = editorState.getCurrentContent();
    let convertRaw = convertToRaw(current_content);
    this.setState({ editorState, blogData: JSON.stringify(convertRaw) });
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };
  // _toggleColor = toggledColor => {
  //     // console.log("toggledColor", toggledColor);
  //     const { editorState } = this.state;
  //     const selection = editorState.getSelection();
  //     // Let's just allow one color at a time. Turn off all active colors.
  //     const nextContentState = Object.keys(styleMap).reduce(
  //         (contentState, color) => {
  //             return Modifier.removeInlineStyle(contentState, selection, color);
  //         },
  //         editorState.getCurrentContent()
  //     );
  //     let nextEditorState = EditorState.push(
  //         editorState,
  //         nextContentState,
  //         "change-inline-style"
  //     );
  //     const currentStyle = editorState.getCurrentInlineStyle();
  //     // Unset style override for current color.
  //     if (selection.isCollapsed()) {
  //         nextEditorState = currentStyle.reduce((state, color) => {
  //             return RichUtils.toggleInlineStyle(state, color);
  //         }, nextEditorState);
  //     }
  //     // If the color is being toggled on, apply it.
  //     if (!currentStyle.has(toggledColor)) {
  //         nextEditorState = RichUtils.toggleInlineStyle(
  //             nextEditorState,
  //             toggledColor
  //         );
  //     }
  //     this.onChange(nextEditorState);
  //     //  this.editor.focus();
  //     this.focus();
  // };

  onChangeTitle = ({ currentTarget: input }) => {
    this.setState({ docTitle: input.value });
  };

  saveDocumentOnBlur = () => {
    this.props.createDocument();
  };
  inputKeyDown = (e) => {
    const { documentId, tags } = this.state;
    const val = e.target.value.toLowerCase();
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...tags, val], tagInputRef: null }, () => {
        const data = {
          documentId: documentId,
          tags: [...tags, val],
        };
      });
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };
  removeTag = (i) => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
  };

  triggerLoading = (isLoading) => {
    console.log("triggerLoading");
    this.setState({ loading: isLoading });
  };

  insertImage = (base64, key) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: base64, name: key }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    if (key) {
      this.addImage(key);
      toast.info(
        "Image Uploaded Succesfully.Please wait till it finsihed loading"
      );
      toast.info("Adjust the Image as per your wish", { autoClose: 8000 });
    }
    this.setState({ loading: false }, () => {
      this.onChange(
        AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
      );
    });
  };

  customCountFunction(str) {
    const wordArray = str.match(/\b((?!=|\.).)+(.)\b/g);
    return wordArray ? wordArray.length : 0;
  }
  handlePastedText = (text, html, editorState) => {
    var content = editorState.getCurrentContent();
    var selection = editorState.getSelection();
    var newContent = Modifier.insertText(content, selection, text);
    var newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    this.onChange(newEditorState);
    return "handled";
  };
  handleSEOTitle = ({ currentTarget: input }) => {
    this.setState({ title: input.value });
  };

  handleSEODescription = ({ currentTarget: input }) => {
    this.setState({ description: input.value });
  };
  handleSEOKeywords = ({ currentTarget: input }) => {
    console.log(input.value);
    this.setState({ keywords: input.value });
  };

  handleSEOUrlValue = ({ currentTarget: input }) => {
    // let url = process.env.NODE_ENV !== "production" ? `http://localhost:3000/${input.value}` : `https://www.professionaleditingtool.com/${input.value}`
    this.setState({ urlValue: input.value });
  };

  handleCategory = ({ currentTarget: input }) => {
    this.setState({ category: input.value });
  };
  handleMainCategory = ({ currentTarget: input }) => {
    this.setState({ main_category: input.value });
  };
  removeDeletedImages = () => {
    const { blogData } = this.state;
    let junk = [];
    const storedState = JSON.parse(blogData);
    const contentState = convertFromRaw(storedState);
    let RawData = stateToHTML(contentState, option_);
    let ImageURLS = [];
    let reg = "";
    let regex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
    while ((reg = regex.exec(RawData))) {
      let length = reg[1].length;
      ImageURLS.push(reg[1].substring(49, length));
    }

    for (let i = 0; i < this.state.image.length; i++) {
      if (ImageURLS.includes(this.state.image[i])) {
        // Nothing
      } else {
        junk.push(this.state.image[i]);
      }
    }
    this.setState({ junk, image: ImageURLS });
    // data.imageUrl = ImageURLS;
    return ImageURLS;
  };
  saveBlog = async () => {
    const { history } = this.props;
    const {
      blogData,
      docTitle,
      main_category,
      category,
      urlValue,
      description,
      title,
      tags,
      image: imageUrls,
      updatedDocumentId,
      keywords,
    } = this.state;
    const data = {
      blogData,
      docTitle,
      main_category,
      category,
      urlValue,
      description,
      title,
      keywords,
    };
    for (let i in data) {
      if (!data[i]) {
        toast.warn("Please fill all the Contents");
        return;
      }
    }
    if (tags.length > 0) {
      data["tags"] = tags;
    }
    if (imageUrls.length > 0) {
      const image = this.removeDeletedImages();
      data["image"] = image;
    }

    console.log("saveBlog===>", data);
    let response;
    this.setState({ loading: true });
    if (updatedDocumentId) {
      data["_id"] = updatedDocumentId;
      response = await seoServices.updateSEOBlog(data);
    } else {
      response = await seoServices.addSEOBlogs(data);
    }
    console.log(response);
    if (response.status === 200) {
      toast.info(response.message);
      history.push("/adminBlogs");
    } else {
      toast.warn(response.message);
    }
    this.setState({ loading: false });
  };

  addImage = (imageUrl) => {
    const { image } = this.state;
    image.push(imageUrl);

    this.setState({ image });
  };
  render() {
    const {
      toogleClassName,
      editorState,
      urlValue,
      title,
      description,
      category,
      docTitle,
      main_category,
      keywords,
    } = this.state;

    return (
      <div>
        <Loader loading={this.state.loading} />
        <ReactTour
          steps={this.state.steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <br />
        <div className="row">
          <div className={toogleClassName.editor}>
            <div className="d-flex">
              <div>
                <i
                  className="fa fa-question-circle mt-2"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    color: "dodgerblue",
                    fontSize: "15px",
                  }}
                  onClick={() => this.setState({ reactTour: true })}
                ></i>
              </div>

              <div className="flex-fill ml-3 reactour-4">
                <Tags
                  className="flex-fill"
                  tags={this.state.tags}
                  onKeyDown={this.inputKeyDown}
                  ref={this.state.tagInputRef}
                  removeTags={this.removeTag}
                />
              </div>

              <div className="d-flex align-items-center justify-content-end reactour-8">
                <DocUpload getDoc={this.addUploadedText} />
              </div>

              {/* <div className="d-flex align-items-center justify-content-end">
                                {saved != null &&
                                    (saved ? (
                                        <CircularProgress className="mx-2" size={24} />
                                    ) : (
                                            <span className="mx-2 text-info">Saved</span>
                                        ))}
                            </div> */}

              {/* <button onClick={() => this.citations() }> click me</button> */}
            </div>
            <div
              className="d-sm-flex bg-white px-2 pt-3 mt-2 reactour-3"
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: "4rem",
                zIndex: "999",
              }}
            >
              <div className="d-flex">
                <InlineSelect
                  placeholder="sans_serif"
                  list={FONT_TYPES}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />

                <InlineColor
                  placeholder="Black"
                  list={FONT_COLOR}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />

                <InlineSize
                  placeholder="14"
                  list={FONT_SIZE}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />
              </div>
              <div className="d-flex">
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                />
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
                <ImageUpload
                  triggerLoading={this.triggerLoading}
                  insertImage={this.insertImage}
                />
              </div>
            </div>
            <input
              id="docTitle"
              placeholder={"Blog Title"}
              name="docTitle"
              value={docTitle}
              onChange={this.onChangeTitle}
              // onBlur={this.saveDocumentOnBlur}
              ref="documentName"
              className="reactour-1"
            />
            <div
              id="editorId"
              className="editor ft_md reactour-2"
              onClick={this.focus}
            >
              <Editor
                placeholder="Write your story..."
                ref={(element) => {
                  this.editor = element;
                }}
                customStyleMap={styleMap()}
                editorState={editorState}
                onChange={(e) => this.onChange(e)}
                plugins={plugins}
                onTab={this.handleTab}
                autoCapitalize="sentences"
                handlePastedText={this.handlePastedText}
              />
            </div>
          </div>
        </div>
        <br />
        <div class="form-group w-25">
          <label for="category">Main Category</label>
          <select
            value={main_category}
            onChange={this.handleMainCategory}
            class="form-control"
            id="category"
          >
            <option value={"blogs"}>Blogs</option>
            <option value={"products"}>Products</option>
          </select>
        </div>
        <div class="form-group w-25">
          <label for="category">Category</label>
          <select
            value={category}
            onChange={this.handleCategory}
            class="form-control"
            id="category"
          >
            {this.state.products.map((product) => (
              <option value={product}>
                {(product.charAt(0).toUpperCase() + product.slice(1)).replace(
                  /_/g,
                  " "
                )}
              </option>
            ))}
            {/* <option value={"blog"} >Blog</option>
                        <option value={'plagiarism'}>Plagiarism</option>
                        <option value={'citation'}>Citation</option>
                        <option value={'paraphrase'}>Paraphrase</option>
                        <option value={'grammar_checker'}>Grammar Check</option>
                        <option value={'teams'}>Teams</option>
                        <option value={'share'}>Document Sharing</option> */}
          </select>
        </div>
        <div class="form-group w-25">
          <label for="url-name">URL Name</label>
          <input
            type="text"
            class="form-control"
            onChange={this.handleSEOUrlValue}
            id="url-name"
            placeholder="name to be appeared on the url"
            value={urlValue}
          />
        </div>
        <div class="form-group w-50">
          <label for="meta-description">Meta Description</label>
          <textarea
            class="form-control"
            id="meta-description"
            rows="4"
            onChange={this.handleSEODescription}
            placeholder="Short Description"
            value={description}
          />
        </div>
        <div class="form-group w-50">
          <label for="meta-keywords">Meta Keywords</label>
          <textarea
            class="form-control"
            id="meta-keywords"
            rows="4"
            onChange={this.handleSEOKeywords}
            placeholder="Keywords"
            value={keywords}
          />
          <label style={{ color: "#ffc107" }}>
            Note: For every new keyword please seperate by comma.
          </label>
        </div>
        <div class="form-group w-50">
          <label for="title">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            onChange={this.handleSEOTitle}
            placeholder="Title"
            value={title}
          />
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={this.saveBlog}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(BlogManagement);

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {setCurrentUser } from "../redux/user/user.actions";
const Logout = () => {
  let history = useHistory();
  useEffect(() => {
    setCurrentUser({});
    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
  });
  return null;
};

export default Logout;

import * as React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@material-ui/core';

const defaultTheme = createTheme();

const InputBoxMui = ({ value, label, multiline, rows, statusColor,color,fontBold,hideCursor, ...props }) => {
  const theme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                '& .MuiInputBase-input': {
                  color: statusColor ? statusColor : color ? color : '#0000FF',
                  padding: '14px',
                  // paddingTop:"8px",
                  // paddingBottom:"8px",
                  fontSize:"16px",
                  fontWeight: fontBold ? fontBold : "400",
                  caretColor: hideCursor ? 'transparent' : 'auto',
                },
                '& .MuiInputLabel-root': {
                  color: 'black',
                  fontSize: '14px', 
                  paddingRight: '10px', 
                  fontWeight: 'bold' 
                },
                '& .MuiOutlinedInput-root': {
                  paddingRight: '10px', 
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
              },
            },
          },
        },
      }),
    [statusColor,color,fontBold]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }}>
        <TextField
         InputLabelProps={{ shrink: true }}
          {...props}
          value={value}
          label={label}
          size="small"
          fullWidth
          multiline={multiline}
          rows={rows}
          variant="outlined"
          inputProps={{
            readOnly: hideCursor // Set readOnly to true when hideCursor is true
          }}
        />
      </Box>
    </ThemeProvider>


  );
};

export const OpportunityInputBoxMui = (props) => {
  return <InputBoxMui {...props} multiline={false} />;
};

export const OpportunityMultilineInputBoxMui = (props) => {
  return <InputBoxMui {...props} multiline rows={props.rows} />;
};

import React, { useState } from 'react';
import { Modal, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const SendBackModal = ({ open, handleClose, teams, handleSendBack }) => {
  const [selectedTeam, setSelectedTeam] = useState('');

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleSendBackClick = () => {
    handleSendBack(selectedTeam);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', marginTop: '15%', width: '300px', textAlign: 'center' }}>
        <h2>Send Back</h2>
        <FormControl fullWidth>
          <InputLabel>Select Team</InputLabel>
          <Select value={selectedTeam} onChange={handleChange}>
            {teams.map((team, index) => (
              <MenuItem key={team._id} value={team._id}>{team.teamName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleSendBackClick} style={{ marginRight: '10px' }}>Send Back</Button>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default SendBackModal;

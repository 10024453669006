import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import dateFn from "date-fn";
import Loader from "../utils/loader";
import Avatar from "@material-ui/core/Avatar";
import avatar from "../static/Avatar.png";
import DocumentService from "../services/document";
import StarRatings from "react-star-ratings";
import { convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "./Editor/utilize";
import {
  addRating,
  addComments,
  addBlogComments,
} from "../services/feedbackServices";
import { getProfile } from "../services/userService";
import { withRouter } from "react-router-dom";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ReactHtmlParser from "react-html-parser";
import CommentsCard from "./Cards/CommentsCard";
import BreadCrumb from "./Cards/BreadCrumbs";
import isEmptyObject from "../utils/isEmptyObject";
import "./documentPreview.css";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import BlogServices from "../services/blogServices";
import macroServices from "../services/macroServices";
import swal from "sweetalert";
import adminServices from "../services/adminServices";

export class DocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    console.log(props);
    this.state = {
      loading: true,
      rating: 0,
      commands: "",
      isAdmin: this.props.currentUser.isAdmin,
      _id: props.data._id,
      //  ? props.data._id
      //  : props.data.orginalDocumentID,
      username: "",
      display_picture: "",
      document_Average_Rating: "",
      document_Total_Rating: "",
      new_Average_Rating: "",
      new_Total_Rating: "",
      documentName: "",
      onlyDocumentContent: "",
      date: "",
      tags: [],
      itemsToShow: 5,
      expanded: false,
      readOnly: "",
      htmlContent: null,
      adminViewedUserData: {},
      userDetails: {},
      userFeatures: {},
      RawData: "",
      blockData: {},
      plainWords: "",
      wordFrequency: {},
      wordFrequencyLimit: 5,
      expandWords: false,
      documentType: "",
      dashLink: "/dashboard",
      missingimg: {},
      blogType: "",
      originalImageURL: [],
      sentence_limit: []
    };

    this.showMore = this.showMore.bind(this);
  }

  showMore() {
    this.state.itemsToShow === 5
      ? this.setState({
        itemsToShow: this.state.tags.length,
        expanded: true,
      })
      : this.setState({ itemsToShow: 5, expanded: false });
  }

  configData = async () => {
    let configData = await adminServices.getSentenceLimit();
    let sentenceLimit = configData.sentence_limit;
    sentenceLimit = sentenceLimit?.split(',');
    sentenceLimit = sentenceLimit?.map(Number);
    this.setState({ sentence_limit: sentenceLimit });
  }

  async componentDidMount() {
    const { currentUser, setCurrentUser, features } = this.props;
    this.setState({ userFeatures: features });
    let user;
    if (!currentUser) {
      user = await getProfile();
      user = user.data;
      setCurrentUser(user);
    } else {
      user = { ...user };
    }
    console.log(user);
    if (user.isAdmin) {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(userData);
      if (userData) {
        this.setState(
          {
            adminViewedUserData: userData,
            username: userData.username,
            display_picture: userData.display_picture,
            userDetails: user,
            isAdmin: true,
            dashLink: "/admindashboard",
          },
          () => this.getDocumentData()
        );
      } else {
        this.setState(
          {
            userDetails: user,
            isAdmin: true,
          },
          () => this.getDocumentData()
        );
      }
    } else {
      this.setState(
        {
          userDetails: user,
        },
        () => this.getDocumentData()
      );
    }
  }

  convertToHtml = (data) => {
    const storedState = JSON.parse(data);
    const onlyTextData = storedState.blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    console.log(onlyTextData);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    let htmlContent = ReactHtmlParser(editorContentHtml);
    this.setState(
      { htmlContent, RawData: editorContentHtml, blockData: storedState },
      () => {
        this._wordFrequency();
        this.configData();
        this.getImageUrl();
      }
    );
  };

  getDocumentData = async () => {
    let user;
    const { currentUser, setCurrentUser, history } = this.props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    let data;
    if (this.props.data.documentType === "document") {
      data = await DocumentService.getDocumentbyId(this.state._id);
      this.setState({ documentType: "document" });
    } else {
      data = await BlogServices.getBlogById(this.state._id);
      this.setState({
        documentType: "blog",
        blogType: this.props.location.state.blogType,
      });
      console.log(this.state.blogType);
    }
    console.log(data);
    if (data.status === 200) {
      const { ratings, totalRatings } = data.data;
      this.setState(
        {
          document_Average_Rating: ratings,
          document_Total_Rating: totalRatings,
          documentName: data.data.documentName,
          onlyDocumentContent: data.data.onlyDocumentContent,
          date: data.data.date,
          tags: data.data.tags,
          readOnly: data.data.readOnly,
          documentType: data.data.documentType,
          loading: false,
          username:
            this.state.username.length > 0
              ? this.state.username
              : user.username,
          display_picture:
            this.state.display_picture.length > 0
              ? this.state.display_picture
              : user.display_picture,
        },
        () => this.convertToHtml(data.data.onlyDocumentContent)
      );
    } else {
      toast.error("document has been deleted");
      this.setState({ loading: false }, () => {
        history.push({ pathname: `/mydocuments` });
      });
    }
  };
  goToDocument = () => {
    try {
      const { userData } = this.props.location.state;
      this.props.history.push({
        pathname: `/mydocuments`,
        state: { userData },
      });
    } catch (e) {
      this.props.history.push({ pathname: `/mydocuments` });
    }
  };

  handleEdit = async (documentId, documentName, onlyDocumentContent, tags) => {
    const { history } = this.props;
    let data;

    if (this.state.documentType === "blog") {
      data = await BlogServices.getBlogById(this.state._id);
      if (data.data.isPublished === true) {
        swal(
          "Wanna Edit The Published Blog ?",
          "To edit the blog please un-publish it and doing so you have to again re-publish the blog for admin approval"
        );
        return;
      }
    } else {
      data = await DocumentService.getDocumentbyId(this.state._id);
    }

    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        let docs = {
          tags: tags,
          documentName: documentName,
          onlyDocumentContent: onlyDocumentContent,
          _id: documentId,
          imageUrl: this.state.originalImageURL,
        };

        history.push({
          pathname: `/texteditor`,
          data: { document: docs, documentType: this.state.documentType },
        });
      } else {
        toast.error("document has been deleted");
        history.push({ pathname: `/mydocuments` });
      }
    } else {
      toast.error("document has been deleted");
      history.push({ pathname: `/mydocuments` });
    }
  };

  addRating = async (rating) => {
    const { history } = this.props;
    const sendData = {
      rating,
      documentId: this.state._id,
    };
    const addRating_res = await addRating(sendData);
    let status = addRating_res.status;
    if (status === 200) {
      toast.success("Rating successfully added");
    } else {
      alert("document has been deleted");
      history.push({ pathname: `/mydocuments` });
    }
  };

  // addRating = async (rating) => {
  //   const sendData = {
  //     rating,
  //     documentId: this.state._id,
  //   };
  //   const addRating_res = await addRating(sendData);
  //   let status = addRating_res.status;
  //   if (status === 200) {
  //     toast.success("Rating successfully added");
  //   } else {
  //     toast.error("Oops something went wrong");
  //   }
  // };

  sendCommand = async (comment, rating) => {
    let documentType = this.state.documentType;
    const sendData = {
      comment,
      documentId: this.state._id,
      rating,
      date: new Date(),
      reply: false,
      commentsID: "",
      total: this.state.new_Total_Rating + 1,
      max: this.state.MaxRatings + rating,
    };
    // console.log(sendData);
    let response = {};
    if (documentType === "document") {
      response = await addComments(sendData);
    } else {
      response = await addBlogComments(sendData);
    }
    if (response.status === 200) toast.success(response.message);
    else toast.error("Oops something went wrong");
  };

  sendPost = async () => {
    if (this.state.userFeatures.Commenting === false) {
      toast("Please upgrade your plan to enable comments feature");
      return;
    }
    this.setState({ loading: true });
    const { rating, commands } = this.state;
    console.log("rating", rating, "commands", commands);
    if (!rating) {
      this.setState({ loading: false });
      toast.error("Please select rating");
      return;
    } else if (!commands) {
      this.setState({ loading: false });
      toast.error("Please enter your comment.");
      return;
    }
    // await this.addRating(rating);
    await this.sendCommand(commands, rating);
    await this.getDocumentData();
    this.child.current.getAllCommentsForDocs();
    this.setState({ commands: "", rating: 0 });
  };

  _plainWordDetector = async (value) => {
    let word = "";
    if (value === 0) {
      word = this.state.plainWords;
    } else if (value === 1) {
      word = "I, me, my, myself, mine, we, us, our, ours";
    } else if (value === 2) {
      word = "you, your, yours, yourself, yourselves";
    } else if (value === 3) {
      word =
        "he, him, his, himself, she, her, hers, herself, it, its, itself, they, them, their, theirs, themselves";
    }

    let nameArr = word.split(",");
    let content = this.state.RawData;
    // console.log(content);
    let convert = content.replace(/\&nbsp;/g, "\u00a0");
    let mainIndex = [];
    let sortIndex = [];
    let weights = [];
    let wordCount = [];

    if (nameArr.length === 1 && nameArr[0].trim().length === 0) {
      // Nothing
    } else {
      for (let j = 0; j < nameArr.length; j++) {
        if (
          nameArr[j].trim() === "" ||
          nameArr[j].trim() === "<" ||
          nameArr[j].trim() === ">" ||
          nameArr[j].trim() === "/" ||
          nameArr[j].trim() === "p"
        ) {
          continue;
        }
        let indices = this.getIndicesOf(nameArr[j].trim(), convert);
        wordCount[j] = 0;
        let wordLength = nameArr[j].trim();
        wordLength = wordLength.length;
        if (indices.length > 0) {
          for (let i = 0; i < indices.length; i++) {
            if (
              convert[indices[i] - 1] === " " ||
              convert[indices[i] - 1] === ">" ||
              convert[indices[i] - 1] === "." ||
              convert[indices[i] - 1] === "," ||
              convert[indices[i] - 1] === "(" ||
              convert[indices[i] - 1] === "[" ||
              convert[indices[i] - 1] === "'" ||
              convert[indices[i] - 1] === '"' ||
              convert[indices[i] - 1] === "\u00a0"
            ) {
              if (
                convert[indices[i] + wordLength] === " " ||
                convert[indices[i] + wordLength] === "<" ||
                convert[indices[i] + wordLength] === "." ||
                convert[indices[i] + wordLength] === "," ||
                convert[indices[i] + wordLength] === ")" ||
                convert[indices[i] + wordLength] === "]" ||
                convert[indices[i] + wordLength] === "'" ||
                convert[indices[i] + wordLength] === '"' ||
                convert[indices[i] + wordLength] === ';' ||
                convert[indices[i] + wordLength] === "\u00a0"
              ) {
                for (let k = indices[i]; k >= 0; k--) {
                  if (convert[k] === "<") {
                    break;
                  }
                  if (convert[k] === ">") {
                    wordCount[j] = wordCount[j]+1;
                    mainIndex.push(indices[i]);
                    sortIndex.push(indices[i]);
                    weights.push(wordLength);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    if (mainIndex.length > 0) {
      sortIndex = Array.from(new Set(mainIndex));
      sortIndex = sortIndex.sort((a, b) => a - b);
      let newWeights = new Array(sortIndex.length).fill(1);

      for (let x = 0; x < mainIndex.length; x++) {
        let new_index = sortIndex.indexOf(mainIndex[x]);
        newWeights[new_index] = weights[x];
      }
      // console.log(mainIndex);
      // console.log(sortIndex);
      // console.log(weights);
      // console.log(newWeights);

      for (let y = 0; y < sortIndex.length; y++) {
        if (y === 0) {
          convert = [
            convert.slice(0, sortIndex[y]),
            "<mark>",
            convert.slice(sortIndex[y]),
          ].join("");
          convert = [
            convert.slice(0, sortIndex[y] + newWeights[y] + 6),
            "</mark>",
            convert.slice(sortIndex[y] + newWeights[y] + 6),
          ].join("");
        } else {
          let dyno = y * 13;
          convert = [
            convert.slice(0, sortIndex[y] + dyno),
            "<mark>",
            convert.slice(sortIndex[y] + dyno),
          ].join("");
          convert = [
            convert.slice(0, sortIndex[y] + newWeights[y] + dyno + 6),
            "</mark>",
            convert.slice(sortIndex[y] + newWeights[y] + dyno + 6),
          ].join("");
        }
      }
    }

    let htmlContent = ReactHtmlParser(convert);
    this.setState({ htmlContent, wordCount });
  };

  getIndicesOf = (searchStr, str, caseSensitive) => {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  _wordFrequency = async () => {
    let content = this.state.RawData;
    content = content.replace(/(<([^>]+)>)/gi, "");
    content = content.replace(/\&nbsp;/g, "");

    let words = content
      .replace(/[\[\].();,#@$%&*^<>/`~+='"!?:1234567890]/g, "")
      .split(/\s/);
    let freqMap = {};

    words.forEach((w) => {
      // w = w.toLowerCase();
      if (w === "") {
        // Nothing
      } else {
        if (!freqMap[w]) {
          freqMap[w] = 0;
        }
        freqMap[w] += 1;
      }
    });

    let keysSorted = Object.entries(freqMap).sort(function (a, b) { return b[1] - a[1] });
    let frequency = {};

    keysSorted.forEach(w => {
      frequency[w[0]] = w[1]
    })

    this.setState({ wordFrequency: frequency });
  };

  _macroMenu = () => {
    let sidebar = document.getElementById("mySidebar");
    let main = document.getElementById("mainPreview");
    let accord = document.getElementById("macroAccordion");

    if (sidebar.style.width === "250px") {
      sidebar.style.width = "0px";
      main.style.marginRight = "0px";
      setTimeout(() => {
        accord.style.display = "none";
      }, 200);
      this._resetMacro();
    } else {
      sidebar.style.width = "250px";
      main.style.marginRight = "250px";
      accord.style.display = "block";
    }
  };

  _resetMacro = () => {
    let RawData = this.state.RawData;
    let htmlContent = ReactHtmlParser(RawData);
    this.setState({ htmlContent, wordFrequencyLimit: 5, expandWords: false });
  };

  _sentenceHighlighter = async (value) => {
    if (value == 0) {
      this._resetMacro();
      return;
    }

    let rawData = this.state.RawData;
    let content = rawData;
    content = content.replace(/\&nbsp;/g, "\u00a0");
    console.log(content);

    let dots = this.getIndicesOf(".", content);
    let quests = this.getIndicesOf("?", content);
    let exclams = this.getIndicesOf("!", content);
    let marks = _.concat(dots, quests, exclams);
    marks = marks.sort((a, b) => a - b);
    console.log(marks);

    let space = 1;
    for (let x = 0; x < marks.length; x++) {
      if (content[marks[x] + space] === "<") {
        content = [
          content.slice(0, marks[x] + space),
          " ",
          content.slice(marks[x] + space),
        ].join("");
        space += 1;
      } else if (
        content[marks[x] + space] === '"' ||
        content[marks[x] + space] === "'"
      ) {
        content = [
          content.slice(0, marks[x] + space + 1),
          " ",
          content.slice(marks[x] + space + 1),
        ].join("");
        space += 1;
      }
    }

    rawData = content;
    console.log(content);
    // let sentences ;
    // sentences = content.split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|\!)\s/);
    // console.log(sentences);
    let { sentence: sentences } = await macroServices.executeRegex({
      sentence: content,
      pattern: "sentenceHighlighterRegex",
    });
    console.log(sentences);
    var x = sentences.length;
    while (x--) {
      let noHTML = sentences[x].replace(/(<([^>]+)>)/gi, "");
      let wordCount = noHTML.split(/[\s,]+/).length;
      if (noHTML[noHTML.length - 1] === "!") {
        if (wordCount <= 2) {
          sentences[x] = sentences[x] + " " + sentences[x + 1];
          sentences.splice(x + 1, 1);
        }
      }
    }
    console.log(sentences);

    for (let i = 0; i < sentences.length; i++) {
      let noHTML = sentences[i].replace(/(<([^>]+)>)/gi, "");
      let og = sentences[i];
      let sentence = sentences[i];
      let sentenceLength = sentence.length;
      let wordCount = noHTML.split(/[\s,]+/).length;

      if (wordCount >= value) {
        console.log(sentence);
        console.log(sentenceLength);
        console.log(wordCount);

        let ps = this.getIndicesOf("<p>", sentence);
        let psotp = 0;
        if (ps.length > 0) {
          for (let j = 0; j < ps.length; j++) {
            if (
              sentence[ps[j] + 3 + 6 * psotp] === "<" &&
              sentence[ps[j] + 4 + 6 * psotp] === "b" &&
              sentence[ps[j] + 5 + 6 * psotp] === "r"
            ) {
              continue;
            }
            if (psotp === 0) {
              sentence = [
                sentence.slice(0, ps[j] + 3),
                "<mark>",
                sentence.slice(ps[j] + 3),
              ].join("");
              psotp += 1;
            } else {
              sentence = [
                sentence.slice(0, ps[j] + 3 + 6 * psotp),
                "<mark>",
                sentence.slice(ps[j] + 3 + 6 * psotp),
              ].join("");
              psotp += 1;
            }
          }
        } else {
          sentence = [sentence.slice(0, 0), "<mark>", sentence.slice(0)].join(
            ""
          );
        }
        console.log(sentence);

        if (sentence[0] === "<") {
          if (sentence[1] !== "p") {
            if (sentence[1] !== "/") {
              if (sentence[1] !== "m") {
                sentence = [
                  sentence.slice(0, 0),
                  "<mark>",
                  sentence.slice(0),
                ].join("");
              }
            }
          }
        } else {
          sentence = [sentence.slice(0, 0), "<mark>", sentence.slice(0)].join(
            ""
          );
        }

        let pe = this.getIndicesOf("</p>", sentence);
        let otp = 0;
        if (pe.length > 0) {
          for (let k = 0; k < pe.length; k++) {
            if (pe[k] === 0) {
              continue;
            }
            if (
              sentence[pe[k] - 1 + 7 * otp] === ">" &&
              sentence[pe[k] - 2 + 7 * otp] === "r" &&
              sentence[pe[k] - 3 + 7 * otp] === "b"
            ) {
              continue;
            }
            if (otp === 0) {
              sentence = [
                sentence.slice(0, pe[k]),
                "</mark>",
                sentence.slice(pe[k]),
              ].join("");
              otp += 1;
            } else {
              sentence = [
                sentence.slice(0, pe[k] + 7 * otp),
                "</mark>",
                sentence.slice(pe[k] + 7 * otp),
              ].join("");
              otp += 1;
            }
          }
        }
        console.log(sentence);

        if (sentence[sentence.length - 1] === ">") {
          // sentence = [sentence.slice(0, sentence.length-4),"</mark>",sentence.slice(sentence.length-4),].join("");
        } else {
          sentence = [
            sentence.slice(0, sentence.length),
            "</mark>",
            sentence.slice(sentence.length),
          ].join("");
        }
        console.log(sentence);

        console.log("================================================");
        content = content.replace(og, sentence);
      }
    }

    console.log(content);
    let htmlContent = ReactHtmlParser(content);
    this.setState({ htmlContent });
  };

  _macroSpace = async () => {
    this._resetMacro();
    let rawData = this.state.RawData;
    let content = rawData;
    let indices = this.getIndicesOf("&nbsp;", content);

    console.log(content);
    console.log(indices);

    var x = indices.length;
    while (x--) {
      if (indices[x] - 6 > 0) {
        if (indices[x] - 6 === indices[x - 1]) {
          indices.splice(x, 1);
        }
      }
    }
    console.log(indices);

    for (let i = 0; i < indices.length; i++) {
      let dyno = i * 26;
      indices[i] += dyno;
      let carry1 = 0;
      let carry2 = 0;
      let skip = 1;

      if (content[indices[i] - 1] === " " || content[indices[i] + 6] === "&") {
        // ******************** FIRST WORD ***************************
        if (content[indices[i] - 1] === ">") {
          content = [
            content.slice(0, indices[i]),
            "<mark>",
            content.slice(indices[i]),
          ].join("");
        }

        if (content[indices[i] - 1] === " ") {
          for (let j = indices[i] - 2; j >= 0; j--) {
            if (content[j] === " ") {
              content = [
                content.slice(0, j + 1),
                "<mark>",
                content.slice(j + 1),
              ].join("");
              break;
            }
            if (content[j] === ">") {
              content = [
                content.slice(0, j + 1),
                "<mark>",
                content.slice(j + 1),
              ].join("");
              break;
            }
          }
        }

        for (let k = indices[i] + 6; k < content.length; k += 6) {
          if (content[k] === "&") {
            // Nothing
          } else {
            content = [content.slice(0, k), "</mark>", content.slice(k)].join(
              ""
            );
            carry1 = k;
            break;
          }
        }
        // ******************** FIRST WORD ***************************

        // ******************** SECOND WORD ***************************
        for (let a = carry1 + 7; a < content.length; a++) {
          if (a === content.length - 4) {
            content = [content.slice(0, a), "<mark>", content.slice(a)].join(
              ""
            );
            carry2 = a;
            break;
          }
          if (content[a] !== "<") {
            content = [content.slice(0, a), "<mark>", content.slice(a)].join(
              ""
            );
            carry2 = a;
            break;
          }
          if (content[a] === "<") {
            for (let b = a + 1; b < content.length; b++) {
              if (content[b] === ">") {
                skip = 0;
                continue;
              }
              if (content[b] === "<") {
                skip = 1;
                continue;
              }
              if (skip === 0) {
                content = [
                  content.slice(0, b),
                  "<mark>",
                  content.slice(b),
                ].join("");
                carry2 = b;
                break;
              }
            }
            break;
          }
        }

        for (let c = carry2 + 6; c < content.length; c++) {
          if (content[c] === " " || content[c] === "<") {
            content = [content.slice(0, c), "</mark>", content.slice(c)].join(
              ""
            );
            break;
          }
        }
        // ******************** SECOND WORD ***************************
      } else {
        // Nothing
      }
    }

    console.log(content);
    let htmlContent = ReactHtmlParser(content);
    this.setState({ htmlContent });
  };

  //*************************************** */
  // Comparing s3 bucket with imageurl array
  getImageUrl = async () => {
    // let RawData = this.state.RawData;
    // let ImageURLS = [];
    // let reg = "";
    let imgdata = {};
    // let regex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

    // while ((reg = regex.exec(RawData))) {
    //   let length = reg[1].length;
    //   ImageURLS.push(reg[1].substring(49, length));
    // }
    // console.log(ImageURLS);

    if (this.state.documentType === "blog") {
      imgdata = await BlogServices.getBlogById(this.state._id);
    } else {
      imgdata = await DocumentService.getDocumentbyId(this.state._id);
    }

    // console.log(imgdata);
    let ogImageUrl = imgdata.data.imageUrl;
    this.setState({ originalImageURL: ogImageUrl });

    // for(let i=0; i<ogImageUrl.length; i++){
    //   if(ImageURLS.includes(ogImageUrl[i])){
    //     // Nothing
    //   }else{
    //     await uploadImageServices.DeleteImageFromS3(ogImageUrl[i]);
    //   }
    // }

  };

  _calculateRatings = async (total, avg, max) => {
    // console.log(total, avg);
    this.setState({
      new_Average_Rating: avg,
      new_Total_Rating: total,
      MaxRatings: max,
    });
  };

  render() {
    const { _id: documentId } = this.props.data;
    const {
      username,
      display_picture,
      commands,
      document_Average_Rating,
      document_Total_Rating,
      documentName,
      onlyDocumentContent,
      date,
      tags,
      readOnly,
      htmlContent,
      isAdmin,
      wordFrequency,
      documentType,
      wordFrequencyLimit,
      expandWords,
      blogType,
      sentence_limit,
      plainWords,
      userDetails
    } = this.state;
    return (
      <div className="">
        <Loader loading={this.state.loading} />
        {documentType === "blog" ? (
          <BreadCrumb
            l1="Home"
            l2="Blogs"
            l3="Blogs Preview"
            dashboardlink={
              isAdmin === true ? "/admindashboard" : this.state.dashLink
            }
            documentLink={isAdmin === true ? "/adminBlogs" : "/blogpage"}
          />
        ) : (
          <>
            <BreadCrumb
              l1="Home"
              l2="Documents"
              l3="Document Preview"
              dashboardlink={
                isAdmin === true ? "/admindashboard" : this.state.dashLink
              }
              documentLink={"/mydocuments"}
            />
          </>
        )}

        <div id="mySidebar" className="sidebar">
          <h3 className="p-2">Details</h3>

          <div className="accordion" id="macroAccordion">
            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Word Frequency
              </button>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <div className="row">
                    <table className="table table-hover table-sm table-striped">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Word
                          </th>
                          <th className="text-center" scope="col">
                            Frequency
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(wordFrequency)
                          .slice(0, wordFrequencyLimit)
                          .map((word) => (
                            <tr>
                              <td className="text-center">{word}</td>
                              <td className="text-center">{wordFrequency[word]}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    {!expandWords ? (
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          this.setState({
                            expandWords: true,
                            wordFrequencyLimit: Object.keys(wordFrequency)
                              .length,
                          });
                        }}
                      >
                        Show More
                      </button>
                    ) : (
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            this.setState({
                              expandWords: false,
                              wordFrequencyLimit: 5,
                            });
                          }}
                        >
                          Show Less
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Highlight Sentences
              </button>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <InputLabel htmlFor="filled-age-native-simple">
                    Word Length in Sentences
                  </InputLabel>
                  <Select
                    native
                    // value={state.age}
                    onChange={(e) => this._sentenceHighlighter(e.target.value)}
                    inputProps={{
                      name: "age",+
                      id: "filled-age-native-simple",
                    }}
                    className="w-100"
                  >
                    { sentence_limit?.map(limit => (
                      <option value={limit}>{limit}</option>
                    )) }
                    {/* <option value={0}>0</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option> 
                  </Select>
                </div>
              </div>
            </div> */}

            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Person Perspective Words
              </button>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(1)}
                  >
                    Detect First Person Words
                  </button>
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(2)}
                  >
                    Detect Second Person Words
                  </button>
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(3)}
                  >
                    Detect Third Person Words
                  </button>
                </div>
              </div>
            </div>

            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Plain Word Detection
              </button>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <TextField
                    className="w-100"
                    id="outlined-basic"
                    label="Target Words"
                    variant="outlined"
                    onChange={(e) => 
                      this.setState({ plainWords: e.target.value })
                    }
                  />
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(0)}
                  >
                    Detect
                  </button>

                  <div className="text-center mt-3">
                    { plainWords && plainWords.split(',').map((word, index)=>(
                      <ul className="pl-3 pr-3 m-0">
                      { this.state.wordCount && (
                          <li>{word} : {this.state.wordCount[index] || 0}</li>
                        ) }
                      </ul>
                    )) }
                  </div>

                </div>
              </div>
            </div>

            <div className="card mb-2">
              <button
                className="btn w-100 p-2"
                onClick={this._macroSpace}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
              >
                Detect Multiple Spaces
              </button>
            </div>
          </div>
        </div>

        <div id="mainPreview">
          <div className="row pr-2">
            <div className="col-sm-5">
              <div className="d-flex align-items-center">
              <Avatar
                      // aria-controls={menuId}
                      aria-haspopup="true"
                      // alt={username}
                      alt=
                        {this.props.data.ownerName
                          ? this.props.data.ownerName
                          : this.props.data.editor?.username
                            ? this.props.data.editor?.username
                            : username}
                      
                      // src={profilePic ? profilePic : avatar}
                      src={
                        this.props.data?.ownerDP
                          ? this.props.data?.ownerDP
                          : this.props.data.editor?.display_picture
                            ? this.props.data.editor?.display_picture
                            : display_picture
                      }
                      id="profilePic"
                      // className={`${classes.avatar} profilePic point`}
                      color="inherit"
                    />
                {/* <img
                  className="docImg"
                  src={
                    this.props.data.ownerDP
                      ? this.props.data.ownerDP
                      : this.props.data.editor.display_picture
                        ? this.props.data.editor.display_picture
                        : display_picture
                  }
                  alt=""
                /> */}

                <p className="ml-3 mb-0">
                  {this.props.data.ownerName
                    ? this.props.data.ownerName
                    : this.props.data.editor?.username
                      ? this.props.data.editor?.username
                      : username}
                  <br />
                  <span className="ft_xs text-e">{dateFn.date(date, 110)}</span>
                </p>
              </div>
            </div>

            {isAdmin === true ? (
              ""
            ) : (
                <div className="col-sm-7 ">
                  {documentType == "document" ? (
                    <div style={{ float: "right" }}>
                      <button
                        className="btn btn-primary"
                        onClick={this._macroMenu}
                      >
                        Document Analyzer
                    </button>
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              )}
          </div>
          <hr />

          <div className="row">
            <div className="col-sm-5">
              <div className="d-flex align-items-center">
                <p className="ft_xl mb-2"> {documentName} </p>
              </div>
            </div>

            <div className="col-sm-7 text-right rateCol">
              <StarRatings
                rating={this.state.new_Average_Rating || 0}
                starRatedColor="orange"
                starDimension="15px"
                starSpacing="0px"
                numberOfStars={5}
                name="rating"
                style={{ paddingBottom: "2%" }}
              />

              {/* <Rating
              className="mx-2"
              initialRating={`${document_Average_Rating}`}
              emptySymbol={<i className="fa fa-star-o" aria-hidden="true"></i>}
              fullSymbol={<i className="fa fa-star" aria-hidden="true"></i>}
              style={{ color: "orange" }}
              readonly
            /> */}
              <div>
                <span className="ft_sm mt-2" style={{ opacity: "0.5" }}>
                  {this.state.new_Total_Rating} ratings
                </span>
              </div>
            </div>
          </div>

          <div className="row pl-3">
            {tags.length > 0 &&
              tags.slice(0, this.state.itemsToShow).map((tag) => (
                <button
                  key={tag}
                  type="button"
                  className="border ft_xs mr-2 p-1"
                  style={{
                    backgroundColor: "#f1f1f1",
                    borderRadius: "4px",
                    color: "#757575",
                  }}
                >
                  {tag}
                </button>
                // <Chip
                //   key={tag}
                //   label={tag}
                //   clickable
                //   variant="outlined"
                //   id="chip"
                //   // style={{
                //   //   marginRight: "1%",
                //   //   backgroundColor: "#1976d2",
                //   //   color: "white"
                //   // }}
                // >
                //   {tag}
                // </Chip>
              ))}
            {tags.length > 5 && (
              <a
                className="ft_xs"
                onClick={this.showMore}
                style={{
                  cursor: "pointer",
                  color: "#2196f3",
                  textDecoration: "underline",
                  marginTop: "1%",
                }}
              >
                {this.state.expanded ? (
                  <span>Show less</span>
                ) : (
                    <span>Show more</span>
                  )}
              </a>
            )}
          </div>

          <br />
          <div className="d-flex justify-content-between align-items-start">
            <p> {htmlContent} </p>
            {isAdmin && documentType === "blog" && blogType === "my" ? (
              <i
                className="fa fa-edit point text-primary ft_lg"
                data-toggle="tooltip"
                title="Edit"
                onClick={() =>
                  this.handleEdit(
                    documentId,
                    documentName,
                    onlyDocumentContent,
                    tags
                  )
                }
              ></i>
            ) : this.props.data.reviewStatus === true ? (
              ""
            ) : this.props.data.pendingPublish === true ? (
              ""
            ) : readOnly === false && documentType === "document" && !isAdmin ? (
              <i
                className="fa fa-edit point text-primary ft_lg"
                data-toggle="tooltip"
                title="Edit"
                onClick={() =>
                  this.handleEdit(
                    documentId,
                    documentName,
                    onlyDocumentContent,
                    tags
                  )
                }
              ></i>
            ) : (
                      documentType === "blog" &&
                      blogType === "my" &&
                      (
                        <i
                          className="fa fa-edit point text-primary ft_lg"
                          data-toggle="tooltip"
                          title="Edit"
                          onClick={() =>
                            this.handleEdit(
                              documentId,
                              documentName,
                              onlyDocumentContent,
                              tags
                            )
                          }
                        ></i>
                      )
                    )}
          </div>

          <br />
          <br />
          <br />
          <hr />

          {(this.props.data.editor !== this.props.currentUser._id && documentType === "document") || (blogType === "all" && documentType === "blog" && this.props.data.editor._id !== this.props.currentUser._id) || (documentType === "document" && this.props.data.status === "draft") ? (
            <>
              <p className="mt-4" 
              // style={{ opacity: "0.3" }}
              >
                Rate and Review here
              </p>
              <div
                className="card p-2"
                style={{
                  border: "1px solid #dedcdc",
                  borderRadius: "12px",
                  boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="row">
                  <div className="col-md-1">

                  <Avatar
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        // alt={username}
                        // alt={username.length > 0
                        //   ? username
                        //   : username}
                        alt={userDetails.username}
                        // src={profilePic ? profilePic : avatar}
                        // src={currentUser.display_picture ? currentUser.display_picture : avatar}
                        id="profilePic"
                        // className={`${classes.avatar} profilePic point`}
                        color="inherit"
                      />

                    {/* <img
                      src={display_picture}
                      className="mx-2 mt-2"
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                      }}
                    /> */}
                  </div>

                  <div className="col-md-8 rm-focus">
                    <TextareaAutosize
                      aria-label="minimum height"
                      rows={3}
                      value={commands}
                      onChange={(e) => {
                        this.setState({ commands: e.target.value });
                      }}
                      className="removeBorder w-100"
                      placeholder="Write a response..."
                      style={{ height: "35px", marginTop: "15px" }}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <div>
                      <StarRatings
                        rating={this.state.rating}
                        starRatedColor="orange"
                        changeRating={(rate) => this.setState({ rating: rate })}
                        starDimension="15px"
                        starSpacing="0px"
                        numberOfStars={5}
                        name="rating"
                      />
                      {/* <Rating
                  initialRating={this.state.rating}
                  emptySymbol='fa fa-star-o'
                  fullSymbol='fa fa-star'
                  style={{ color: "orange" }}
                  fractions={2}
                  onChange={rate => {
                    this.setState({ rating: rate });
                  }}
                /> */}
                      <p
                        className="text-right text-primary point"
                        onClick={() => this.sendPost()}
                      >
                        Post
                  </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : ""}
          <br />
          <div>
            <CommentsCard
              currentUser={this.props.currentUser}
              documentType={this.state.documentType}
              data={this.props.data}
              _id={this.state._id}
              ref={this.child}
              _calculateRatings={this._calculateRatings}
              blogType={blogType}
            />


          </div>
          <br />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentPreview));

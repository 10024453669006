import React, { useState } from "react";
import { Card, CardContent, Checkbox, IconButton, Typography, Menu, MenuItem, Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';


export const SuggestionCards = () => {
    return(
        <>
            <div>
                <Card style={{width:'100%',height:'130px',backgroundColor:'white', marginBottom:'15px', borderRadius: '10px'}}>
                    <CardContent style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div><Typography variant="body2">title</Typography></div>
                            <div><Typography variant='caption' style={{color:'grey'}}>Error message</Typography></div>
                        </div>
                        <div style={{ overflowY:'auto'}}>
                            <Typography variant="caption" ><p>Lorem Ipsum is simply dummy 
                                text of the printing and typesetting industry.Lorem Ipsum is simply dummy 
                                text of the printing and typesetting industry.</p>
                            </Typography>
                        </div>
                        <div style={{paddingTop:'15px'}}>
                            <Button variant="contained" color="primary">Accept</Button>
                            <Button variant="text" style={{marginLeft:'10px'}}>Ignore</Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

export const RewriteCard = ({handleCloseRewriteCard}) =>{
    return(
        <>
        <div style={{position:'absolute', left:'90%', top:'10px', zIndex:'999', minWidth:'250px'}}>
        <Card style={{position:'relative' , padding:'10px 20px', borderRadius:'10px'}}>
            <div style={{display:'flex', justifyContent:'right'}}>
            <IconButton
                onClick={handleCloseRewriteCard}
            >
                <CloseIcon />
            </IconButton>
            </div>
            <div style={{marginBottom:'10px'}}>
            <Typography variant="body1" >
                Rewrite card content...
                Rewrite card content...
                Rewrite card content...
                Rewrite card content...
            </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'right'}}>
                <Button variant="outlined" color="primary" onClick={handleCloseRewriteCard}>Rewrite</Button>
            </div>
        </Card>
        </div>
        </>
    )
}


export const StatisticalFactCard = ({handleCloseStatisticalFactCard}) =>{
    return(
        <>
        <div style={{position:'absolute', left:'90%', top:'10px', zIndex:'999', minWidth:'250px'}}>
        <Card style={{position:'relative' , padding:'10px 20px', borderRadius:'10px'}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Typography variant="body1">
                    <strong>Add Statistical Data</strong>
                </Typography>
            </div>
            <div style={{margin:'15px 0px'}}>
            <Typography variant="body1" >
                Rewrite card content...
                Rewrite card content...
                Rewrite card content...
                Rewrite card content...
            </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Button variant="contained"  onClick={handleCloseStatisticalFactCard}>Ignore</Button>
                <Button variant="contained" color="primary" onClick={handleCloseStatisticalFactCard}>Accept</Button>
            </div>
        </Card>
        </div>
        </>
    )
}
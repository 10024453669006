import React, { useState } from "react";
import style from "./paraphrase.module.css";

import {
  TiThumbsUp,
  TiThumbsDown,
  TiArrowForward,
  TiMessage,
  TiArrowBack,
  TiLockClosed,
  TiLockOpen
} from "react-icons/ti";
import { FaUndoAlt, FaUndo, FaRegFileAlt,FaEdit,FaUserEdit } from "react-icons/fa";
import {
  AiOutlineCopy,
  AiTwotonePieChart,
  AiFillCaretUp,
  AiFillCaretDown,
  AiFillEdit,
  AiOutlineEdit,
} from "react-icons/ai";
import { FcDoughnutChart } from "react-icons/fc";
// import { RiEditFill, RiFileEditFill, } from "react-icons/ri";
import { FiPenTool } from "react-icons/fi";
import petLogo from "../../static/pet_logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { checkPlagiarism } from "../../services/plagiarismServices";
import PlagMetrics from "../Plagiarism/Statistics";
import ReactHtmlParser from "react-html-parser";
import ToggleSwitch from "./toggleButton";
// import EditToggleButton from "./EditToggleButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
  },
}));

let plagarizedResponse = "";
let statdetails = "";
let tab = 0;

export function RightBottomBtn({ plagCheckContent, onHandleNavigation, colChange, sentanceNav, onHandleCopy, plagarizedContent, setLoading, grammerCheck, isGrammerCheck, isEditMode, changeEditMode }) {
  const [isCopy, setIsCopy] = useState(false);

  // const onHandleCopy = () => {
  //   const editorRight = document.getElementById("editorRight").innerText;
  //   if (!editorRight) {
  //     toast.warn(`You don't have text, to copy`);
  //     return;
  //   }
  //   navigator.clipboard.writeText(editorRight).then(
  //     function () {
  //       console.log("Async: Copying to clipboard was successful!");
  //       if (editorRight) toast.info("Your text Copied!");
  //     },
  //     function (err) {
  //       console.error("Async: Could not copy text: ", err);
  //     }
  //   );
  // };

  const PlagiarismCheck = async () => {
    const editorRight = plagCheckContent;
    if (!editorRight) {
      toast.warn(`You don't have text, to paraphrase`);
      return;
    }
    setLoading(true)
    let plagiarismdata = await checkPlagiarism(editorRight);
    plagiarismdata = JSON.parse(plagiarismdata.response);
    console.log(plagiarismdata);
    statdetails = plagiarismdata;
    plagarizedResponse = plagiarismdata.sources;

    const { details } = plagiarismdata;
    let plagiarizedLine = ``;
    details.map((line, index) => {
      if (line.unique !== "true") {
        plagiarizedLine += `<p><text style="text-decoration: none; border-bottom: 3px solid red;font-size:large">${line.query}</text></p>`;
      } else if (line.paraphrase === "true") {
        plagiarizedLine += `<p><text style="text-decoration: underline yellow solid;font-size:large">${line.query}</text></p>`;
      }
    });
    let htmlContent = ReactHtmlParser(plagiarizedLine);
    plagarizedContent(htmlContent);

    tab = 1;
    colChange("statistics");
    setLoading(false)
  }

  return (
    <div className={`text-e d-flex justify-content-between reactour-4 ${style.moveup}`}>
      <div className="d-flex">
        <button
          onClick={() => onHandleNavigation("down")}
          className={style.btn_trns}
        >
          <AiFillCaretUp size={"22px"} className="point" />
        </button>
        <button
          onClick={() => onHandleNavigation("up")}
          className={style.btn_trns}
        >
          <AiFillCaretDown size={"22px"} className="point" />
        </button>
        <p className="ft_md ml-2">
          {/* <text style={{ color: "#4cb2ff" }}> {sentanceNav.current}</text> /
          <text style={{ color: "red" }}> {sentanceNav.total}</text> Sentences */}
          <button
            className="btn btn-outline-primary ml-2"
            style={{ borderRadius: "15px" }}
            onClick={PlagiarismCheck}>
            Plagarism
          </button>
        </p>
        <p className='ml-5 ft_md' ><ToggleSwitch value={isGrammerCheck} grammerCheck={grammerCheck} /></p>
        {/* <p className='ml-5 ft_md' ><EditToggleButton isEditMode={isEditMode} changeEditMode={changeEditMode} /></p> */}

      </div>

      <div className="d-flex align-items-center">
        <button className={style.btn_trns}
          onClick={onHandleCopy}>
          <AiOutlineCopy
            size={"20px"}
            data-toggle="tooltip"
            data-placement="top"
            title={isCopy ? "Copied" : "Copy to clipboard"}
            style={{ color: "#3ba500" }}
          />
        </button>

        <span className="mx-1"></span>
        {/* <TiMessage
          data-toggle="tooltip"
          data-placement="top"
          title="Feedback"
          size={"20px"}
          className="point mx-2"
          onClick={() => colChange('feedback')}
        /> */}
        <button
          className={style.btn_trns}
          onClick={() => {
            tab = 0;
            colChange("statistics")
          }}
        >
          <FcDoughnutChart
            data-toggle="tooltip"
            data-placement="top"
            title="Statistics"
            size={"24px"}
          />
        </button>
      </div>
    </div>
  );
}

export function PetAssistanceBtn({ characters, onPetAssistant, onHandleClear, isPara, limitChara, }) {
  console.log("check====>", isPara)
  return (
    <div className={`text-e d-flex justify-content-between ${style.moveup}`}>
      <div>
        <p className="ft_sm">
          <text style={{ color: "#4cb2ff" }}>{characters.now}</text>/
          <text style={{ color: "red" }}>{characters.max}</text> Characters
        </p>
      </div>
      <div className="d-flex align-items-center">
        <div
          className={
            isPara && !limitChara
              ? style.pet_clicked
              : limitChara
                ? style.charaLimitCSS
                : style.pet_out
          }
          onClick={onPetAssistant}
        >
          {/* <FiPenTool size={"28px"}/> */}
          <img className={style.logo} src={petLogo} alt="pet logo" />
          {isPara ? (
            <span className="mr-2 ft_md" style={{ color: "white" }}>Re-Phrase</span>
          ) : (
              <span className="mr-2 ft_md" style={{ color: "white" }}>Paraphrase</span>
            )}
        </div>
        {/* <p
          onClick={onHandleClear}
          data-toggle="tooltip"
          data-placement="top"
          title="Clear editor"
        >
          {" "}
          <i className={`fa fa-trash ml-3 ${style.trash}`}></i>{" "}
        </p> */}
      </div>
    </div>
  );
}

export function SideBtn(props) {
  // const [selectedText, setselectedText] = useState("");

  // const getSelectionText = () => {
  //   let text = "";
  //   if (window.getSelection) text = window.getSelection().toString();
  //   else if (document.selection && document.selection.type != "Control")
  //     text = document.selection.createRange().text;
  //   setselectedText(text);
  // };

  return (
    <div className={style.sidebtn}>
      <ul>
        <li>
          <button
            className={style.btn_side}
            onClick={props.onSelectedTextCopy}
            data-toggle="tooltip"
            data-placement="top"
            title="Copy selected text"
          >
            <FaRegFileAlt size={"20px"} />{" "}
          </button>
        </li>

        <li>
          <button
            className={style.btn_side}
            onClick={() => props.showHistory("inc")}
            data-toggle="tooltip"
            data-placement="top"
            title="ForwardResult"
          >
            <TiArrowForward size={"23px"} />{" "}
          </button>
        </li>

        <li>
          <button
            className={style.btn_side}
            onClick={() => props.showHistory("dec")}
            data-toggle="tooltip"
            data-placement="top"
            title="PreviousResult"
          >
            <TiArrowBack size={"23px"} />{" "}
          </button>
        </li>

        <li className={props.isLocked && style.enableIcon}>
          <button
            className={style.btn_side}
            // onMouseEnter={getSelectionText}
            onClick={() => props.onHandleLock()}
            data-toggle="tooltip"
            data-placement="top"
            title="Lock selected text"
          >
            {
              props.isLocked ?
                <TiLockClosed size={"25px"} />
                :
                <TiLockOpen size={"25px"} />
            }


          </button>
        </li>
        <li
          data-toggle="tooltip"
          data-placement="top"
          title={"Edit Mode"}
        >
          <button
            onClick={props.changeEditMode}
            className={style.btn_side}
          >
            {props.isEditMode ? <FaUserEdit size={"23px"} /> : <FaEdit size={'23px'} />}
          </button>
        </li>
      </ul>
    </div>
  );
}

export function Statistics({ colChange, statistics, plagarizedContent }) {
  const classes = useStyles();

  return (
    <div>
      { tab === 0 ? (
        <Card className={classes.root}>
          <CardHeader
            action={
              <IconButton
                onClick={() => colChange("statistics")}
                aria-label="settings"
              >
                <CloseIcon />
              </IconButton>
            }
            title="Statistics"
          />
          <hr />

          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              color="primary"
              className="ml-1"
            >
              Fluency
          </Typography>
            <div
              className="ml-2"
              style={{ fontSize: "10px", lineHeight: "13px" }}
            >
              <div className="row mb-3 mt-3">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  AVG. WORDS IN A SENTENCE
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {" "}
                  {statistics.avgWords}{" "}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  AVG. SYLLLABLES IN A WORD
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {" "}
                  {statistics.syllables}{" "}
                </div>
              </div>

              <div className="row">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  READABILITY
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {" "}
                  {statistics.readability}{" "}
                </div>
              </div>
            </div>

            <hr />

            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              color="primary"
              className="ml-1"
            >
              Difference
          </Typography>

            <div
              className="ml-2"
              style={{ fontSize: "10px", lineHeight: "13px" }}
            >
              <div className="row mb-3 mt-3">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  SENTENCE COUNT
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {" "}
                  {statistics.sentance}{" "}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  WORD COUNT
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {statistics.words}
                </div>
              </div>

              <div className="row">
                <div className="col-md-8" style={{ color: "#707070" }}>
                  PERCENT CHANGE
              </div>
                <div
                  className="col-md-4"
                  style={{ float: "right", color: "#1976d2" }}
                >
                  {statistics.percentChange}
                </div>
              </div>
            </div>
          </CardContent>
          <br />
          <br />
          {/* <hr /> */}

          {/* <div className="text-center">
          <Button href="#text-buttons" color="primary" size="small" style={{fontSize: "10px", lineHeight: "13px"}}>
            LONGEST UNCHANGED WORDS
          </Button>
          <br />
          <Button variant="outlined" className="mt-2 mb-4">
            PREMIUM
          </Button>
        </div> */}
        </Card>
      ) : (
          <PlagMetrics
            source={plagarizedResponse}
            data={statdetails}
            clearResult={() => {
              colChange("statistics");
              plagarizedContent("");
            }}
          />
        )}
      {/* {plagarizedResponse && (
        <PlagMetrics source={plagarizedResponse} data={statdetails} />
      )} */}
    </div>
  );
}

export function Feedback({ colChange }) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton
              onClick={() => colChange("feedback")}
              aria-label="settings"
            >
              <CloseIcon />
            </IconButton>
          }
          title="Feedback"
        />
        <hr />

        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            How am I doing?
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Have a suggestion, found some bug, enjoy the product? Let me know.
          </Typography>
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            placeholder="Start here"
            rows={4}
            //   defaultValue="Default Value"
            variant="outlined"
          />
        </CardContent>

        <div className="text-center">
          <Button href="#text-buttons" color="primary">
            Use Discord
          </Button>
          <br />
          <Button variant="outlined" className="mt-2 mb-4">
            SUBMIT
          </Button>
        </div>
      </Card>
    </div>
  );
}

import React, { Component } from "react";
import DocTags from "../DocTags";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
class DocTagsPage extends Component {
  render() {
    return (
      <SideNavBar>
        <DocTags />
      </SideNavBar>
    );
  }
}

export default NetworkDetector(DocTagsPage);

import React, { useEffect, useState } from "react";
import LogoImg from "../../static/Group 571.png";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { getHomeCMS, postHomeCMS } from "../../services/adminServices";
import imageCompression from "browser-image-compression";
import uploadImageServices from "../../services/uploadImageServices";
import HeadingB from "../Cards/HeadingB";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
    width: 150,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function Logo() {
  const [imageURL, setImageURL] = useState("");
  const [cmsData, setCMSData] = useState({});
  const [loading, setLoading] = useState(true);
  const [compressedFile, setCompressedFile] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = async () => {
    let { data } = await getHomeCMS();
    setCMSData(data);
    setImageURL(data.Navbar_logo.Image);
    setLoading(false);
    console.log(data);
  };

  const uploadPic = () => {
    document.getElementById("fileup").click();
  };

  const _handleImageChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      const options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        maxIteration: 10,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(file, options);
      setCompressedFile(compressedImage);

      let reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onload = function () {
        // console.log(reader.result);
        setImageURL(reader.result);
      };
    }
  };

  const _handleSave = async () => {
    setLoading(true);

    if (compressedFile) {
      const uploadConfig = await uploadImageServices.UploadImageToS3(
        compressedFile,
        cmsData.Navbar_logo.ImageKey
      );

      let mainData = cmsData;
      mainData.Navbar_logo.Image = uploadConfig.data.displayUrl;
      mainData.Navbar_logo.ImageKey = uploadConfig.data.key;
      console.log(mainData);
      let post = await postHomeCMS(mainData);
      if (post.status === 200) {
        toast.success("Changes Updated");
      } else {
        toast.error("Failed To Save");
      }
    }
    setLoading(false);
  };

  const _resetChanges = async () => {
    setLoading(true);
    setImageURL("");
    if (cmsData.Navbar_logo.ImageKey) {
      await uploadImageServices.DeleteImageFromS3(cmsData.Navbar_logo.ImageKey);
    }
    let mainData = cmsData;
    mainData.Navbar_logo.Image = mainData.Navbar_logo.Default_Image;
    mainData.Navbar_logo.ImageKey = "";
    console.log(mainData);
    let post = await postHomeCMS(mainData);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }
    setLoading(false);
  };

  return (
    <div className="container text-center">
      <Loader loading={loading} />
      <HeadingB className="text-center alertHead pt-5" text="NAVBAR LOGO" />
      <div className="row pt-2">
        <div
          className="col-md-6"
          style={{ height: "300px", borderRight: "2px solid #dee2e6" }}
        >
          <Card
            className={classes.root}
            variant="outlined"
            style={{ textAlign: "center", marginLeft: "35%", marginTop: "22%" }}
          >
            <CardContent>
              <Icon
                className="fa fa-plus-circle point"
                style={{ fontSize: 30, color: "#007bff" }}
                color="primary"
                onClick={uploadPic}
              />
              <Typography variant="body2" component="p">
                Upload Image
              </Typography>
            </CardContent>
          </Card>
          {/* <Button variant="contained" style={{marginTop:"25%"}} color="primary" onClick={uploadPic}>
            <i
              className="fa fa-cloud-upload point "
              aria-hidden="true"
              style={{color:"white"}}
            ></i>&nbsp; Upload
          </Button> */}
          <input
            className="fileInput"
            type="file"
            id="fileup"
            accept="image/*"
            onChange={(e) => _handleImageChange(e)}
            style={{ display: "none" }}
          />
          <br />
          <br />
        </div>
        <div className="col-md-6">
          <img
            id="ImagePreview"
            className="petLogo"
            alt="Group570"
            style={{ width: "auto", height: "100px", marginTop: "20%" }}
            src={imageURL || LogoImg}
          ></img>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-6 text-center">
          <button className="btn btn-danger w-50" onClick={_resetChanges}>
            Reset Image
          </button>
        </div>
        <div className="col-6 text-center">
          <button className="btn btn-primary w-50" onClick={_handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default Logo;

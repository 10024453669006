import {
  apiUrl
} from "./config.json";
 
 
import httpService from "./httpService.js";
 
let apiEndpoint = "";
 
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/organizations`;
 
} else {
  apiEndpoint = `${apiUrl}/organizations`;
 
}
 


export async function getuserdata(data){
  const response=await httpService.post(`${apiEndpoint}/getuserdata`,{data});
  return response;
}
export async function deleteorganization(data){
  const response=await httpService.post(`${apiEndpoint}/deleteorganization`,{data});
  return response;
}
export async function getOrganization(data){
  const response=await httpService.post(`${apiEndpoint}/getorganization`,{data});
  return response;
}

export async function updateorganization(data){
  const response=await httpService.post(`${apiEndpoint}/updateorganization`,{data});
  return response;
}
 
export async function getcurrentuserdata(data){
  const response=await httpService.post(`${apiEndpoint}/getuserdata`,{data});
  return response;
}

export async function setnullreforg(data){
  const response=await httpService.post(`${apiEndpoint}/setnullreforg`,{data});
  return response;
}

export async function getAllUserForSameOrg(data){
  const response=await httpService.post(`${apiEndpoint}/getAllUserForSameOrg`,{data});
  return response;
}
export async function addorgdata(data){
  const response=await httpService.post(`${apiEndpoint}/addorgdata`,{data});
  return response;
}

export async function getorgdata(orgId){
  const response = await httpService.get(`${apiEndpoint}/getorgdata?orgId=${orgId}`);
  return response;
}

export async function getorgdetails(data){
  const response=await httpService.post(`${apiEndpoint}/getorgdetails`,{data});
  return response;
}



export default {
  getuserdata,
  getOrganization,
  updateorganization,
  deleteorganization,
  getcurrentuserdata,
  setnullreforg,
  getAllUserForSameOrg,
  addorgdata,
  getorgdata
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import "../CitationList.css";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import copy from "copy-to-clipboard";
import { Document, Packer } from "docx";
import { saveAs } from "file-saver";
import Loader from "../../../utils/loader";
import { asBlob } from 'html-docx-js-typescript'

function createData(name) {
  return { name };
}

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  return stabilizedThis.map((el) => el[0]);
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: "#fff",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const testFunction = () => {
  console.log("test");
}

const download = (data) => {
  console.log(data);

  let citations = "";
  data.style.map((cite, index) => (
    citations += "<li>" + cite.ref + " " + cite["in-text-citations"][0].cite + "</li>"
  ))

  const htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Document</title>
      </head>
      <body>
        <h2>Citations</h2>
        <hr/>
        <ol>
          ${citations}
        </ol>
      </body>
      </html>`

  asBlob(htmlString).then(blog => {
    saveAs(blog, "citations.docx");
  })

  // const doc = new Document();
  // doc.createParagraph("CITATIONS").center().title().thematicBreak();
  // for (let i = 0; i < data.style.length; i++) {
  //   doc
  //     .createParagraph(
  //       `${i + 1} . ${data.style[i].ref.replace(/<.+?>/g, "")}  ${
  //       data.style[i]["in-text-citations"][0].cite
  //       }`
  //     )
  //     .justified();
  // }
  // const packer = new Packer();

  // packer.toBlob(doc).then((blob) => {
  //   console.log(blob);
  //   saveAs(blob, "citations.docx");
  //   toast.info("Citation downloaded!");
  // });
  // setloading(false)
};

const CopyAlltext = (data) => {
  function listener(e) {
    let text = '';
    let response = data
    response.style.map((cite, index) => {
      text += `${cite.ref}`;
      text += `${cite["in-text-citations"][0].cite}`;
      text += '\n';
    })
    e.clipboardData.setData("text/html", text);
    e.clipboardData.setData("text/plain", text);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
  // let text = '';
  // let response = data
  // response.style.map((cite, index) => {
  //   text += `${cite.ref.replace(/<.+?>/g, "")}`;
  //   text += `${cite["in-text-citations"][0].cite}`;
  //   text += '\n';
  // })


  // console.log(text);
  // let a = copy(text);

  // if (a) {
  //   toast.success("All citation copied to clipboard!")
  // }
  // else {
  //   toast.error("Failed to copy!")
  // }

}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, citations } = props;

  return (
    <>
      <Toolbar
        style={{ backgroundColor: "#1976d2", color: "white" }}
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            align="center"
            id="tableTitle"
            component="div"
          >
            Works Cited
          </Typography>
        )}
        <Tooltip title="Download Citations">
          <IconButton onClick={() => download(citations)} aria-label="Download">
            <i
              className="fa fa-download point"

              aria-hidden="true"

            ></i>
          </IconButton>
        </Tooltip>

        <Tooltip title="Copy all">
          <IconButton onClick={() => CopyAlltext(citations)} aria-label="Copy all">
            <i
              className="fa fa-files-o"
              aria-hidden="true"
            ></i>
          </IconButton>
        </Tooltip>

        {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
        <IconButton aria-label="delete">
        <DeleteIcon />
        </IconButton>
        </Tooltip>
        ) : (
          <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
          <FilterListIcon />
          </IconButton>
          </Tooltip>
        )} */}
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
  },
  table: {
    minWidth: 500,
    backgroundColor: "#fff",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  citations: { response },
  selectedCitations,
  edit,
  deleteCite,
  openUrl
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [citation, setCitation] = React.useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("useEffect calling...!");
    if (response) {
      if (response.style.length < 5) {
        setRowsPerPage(response.style.length);
      }
    }
  });

  // useEffect(() => {
  //   if (response) {
  //     setCitation({ ...response.style });
  //   }
  //   setSelected([]);
  // }, [citation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    selectedCitations(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // copy to clipboard citation
  // const copyText = (text) => {
  //   // text = text.replace(/(<.*?>|<\/.*?>)/g)
  //   // let r = text.replace(/\w*(undefined)+/g)
  //   // replace(/<.+?>/g
  //   console.log(text);
  //   toast.info("Copy to clipboard!");
  // };

const copyText=(text)=>{
  function listener(e) {
    e.clipboardData.setData("text/html", text);
    e.clipboardData.setData("text/plain", text);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}
  if (response && response.style && response.style.length > 0) {
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, response.style.length - page * rowsPerPage);
    return (
      <div className={classes.root}>
        <Loader loading={loading} />
        <ToastContainer />
        {/* <button className="btn btn-info" onClick={CopyAlltext}>
          Copy to Clipboard
                    </button>   */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <EnhancedTableToolbar
          citations={response}
          numSelected={selected.length}
        />
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <TableBody>
                {stableSort(response.style)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className="hvrRow point"
                        hover
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                      // selected={isItemSelected}
                      >
                        <TableCell
                          className="point"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        // onClick={(event) => handleClick(event, row.id)}
                        >
                          <Typography
                            dangerouslySetInnerHTML={{ __html: row.ref }}
                          />
                        </TableCell>
                        <TableCell align="right" className="">
                          <i
                            className="fa fa-link hvrIcon pt-1 mr-5 text-primary"
                            data-toggle="tooltip"
                            title="URL"
                            onClick={() => openUrl(row.id)}
                            aria-hidden="true"
                          ></i>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <CopyToClipboard
                            text={row.ref.replace(/<.+?>/g, "")}
                            onCopy={() =>
                              copyText(row.ref.replace(/<.+?>/g, ""))
                            }
                          > */}
                            <i
                            onClick={() =>
                              copyText(row.ref)
                            }
                              className="fa fa-clipboard hvrIcon pt-1 mr-5 text-primary"
                              data-toggle="tooltip"
                              title="Copy-biblography-citation"
                              aria-hidden="true"
                            ></i>
                          {/* </CopyToClipboard> */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <CopyToClipboard
                            text={row["in-text-citations"][0].cite}
                            onCopy={() =>
                              copyText(row["in-text-citations"][0].cite)
                            }
                          > */}
                            <i
                            onClick={() =>
                              copyText(row["in-text-citations"][0].cite)
                            }
                              className="fa fa-clipboard hvrIcon pt-1 mr-5"
                              style={{ color: "red" }}
                              data-toggle="tooltip"
                              title="Copy-in-text-citation"
                              aria-hidden="true"
                            ></i>
                          {/* </CopyToClipboard> */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <i
                            className="fa fa-pencil-square-o mr-3 point text-primary"
                            onClick={() => edit(row.id)}
                            data-toggle="tooltip"
                            title="Edit"
                            aria-hidden="true"
                          ></i>
                          <i
                            className="fa fa-trash mr-3 point text-primary"
                            data-toggle="tooltip"
                            title="Delete!"
                            onClick={() => deleteCite(row.id)}
                            aria-hidden="true"
                          ></i>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="drop"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={response.style.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  } else {
    return null;
  }
}

import React, { Component } from "react";
import FAQ from "../FAQ";
import SubAdminSideBar from "../SubAdminSideBar";
class FAQPage extends Component {
  state = {};
  componentDidMount() {
    console.log(this.props.location.aboutProps);
  }
  render() {
    return (
      <div>
        <SubAdminSideBar>
          <FAQ />
        </SubAdminSideBar>
      </div>
    );
  }
}

export default FAQPage;


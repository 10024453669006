import React, { Component } from "react";
import TeamCollaboration from "../Editor/TeamCollaboration";
import SideNavBar from "../SideNavBar";
export default class TeamCollaborationPage extends Component {
  render() {
    return (
      <SideNavBar>
        <TeamCollaboration />
      </SideNavBar>
    );
  }
}

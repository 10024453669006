import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/workflows";
} else {
  apiEndpoint = herokuUrl + "/workflows";
}


export async function getworkflows(orgid) {
    let data = await httpService.post(`${apiEndpoint}/getworkflows`,{"email":orgid});
    console.log(data);
    return data;
  }


  
export async function getSpecificWorkFlow(workflowid) {
  let data = await httpService.post(`${apiEndpoint}/getworkflowsbyid`,{"workflowId":workflowid});
  console.log(data);
  return data;
}

  


  export async function addWorkflow(userEmail,addWorkflowName ,addWorkflowDescription) {
    try {
      const data = await httpService.post(`${apiEndpoint}/addWorkflow`, {
        "workflowName": addWorkflowName,
        "email": userEmail,
        "description":addWorkflowDescription,
      });
      return data;
    } catch (error) {
      console.error("Error Adding workflow:", error.message);
      throw error;
    }
}



  
// export async function deleteworkflows(id) {
//     try {
//         const response = await httpService.delete(`${apiEndpoint}/deleteWorkflow?id=${id}`);
//         console.log(response);
//         return response;
//     } catch (error) {
//         console.error("Error deleting workflow:", error.message);
//         throw error; 
//     }
// }


export async function updateWorkflow(documentId,description,workflowName,userEmail) {
  try {
    const data = await httpService.post(`${apiEndpoint}/updateWorkflow`, {
      // "orgid": orgid,
      "workflowid":documentId,
      "description":description,
      "workflowName": workflowName,
      // "createdby":createdBy,
      "email":userEmail


    });
    return data;
  } catch (error) {
    console.error("Error updating workflow:", error.message);
    throw error;
  }
}

export async function saveGraphToWorkflow(workflowid,userEmail,graphDetails,stage) {
  try {
    const data = await httpService.post(`${apiEndpoint}/saveGraphToWorkflow`, {
      "workflowid":workflowid,
      "email":userEmail,
      "graphDetails":graphDetails,
      "stageName":stage
    });
    return data;
  } catch (error) {
    console.error("Error updating workflow:", error.message);
    throw error;
  }
}

  //////////////////////Add Stage service///////////////
export async function addStages(workflowid,stageName,StageDescription,vertexId) {
  try {
    const data = await httpService.post(`${apiEndpoint}/addstage`, {
      "workflowid": workflowid,
      "stageName": stageName,
      "description":StageDescription,
      "vertexId":vertexId
    });
    return data;
  } catch (error) {
    console.error("Error Adding workflow:", error.message);
    throw error;
  }
}

export async function updateStageName(workflowid,stageOrgId,stageName) {
  try {
    const data = await httpService.put(`${apiEndpoint}/updateStageName`, {
      "workflowid": workflowid,
      "stageOrgId": stageOrgId,
      "stageName":stageName,
    });
    return data;
  } catch (error) {
    console.error("Error Adding workflow:", error.message);
    throw error;
  }
}

export async function updateStatusName(workflowid,stageOrgId,statusId,statusName) {
  try {
    const data = await httpService.put(`${apiEndpoint}/updateStatusName`, {
      "workflowid": workflowid,
      "stageOrgId": stageOrgId,
      "statusId":statusId,
      "statusName":statusName,
    });
    return data;
  } catch (error) {
    console.error("Error Adding workflow:", error.message);
    throw error;
  }
}



export async function getStages(workflowid) {
  let data = await httpService.post(`${apiEndpoint}/getstage`,{"workflowid":workflowid});
  console.log(data);
  return data;
}


export async function deleteStage(workflowid,stageName) {
  try {
      const response = await httpService.post(`${apiEndpoint}/deletestage`,{
       "workflowid":workflowid,
       "stageName":stageName
      });
      console.log(response);
      return response;
  } catch (error) {
      console.error("Error deleting workflow:", error.message);
      throw error; 
  }
}

export async function deleteTransition(workflowid,transitionData) {
  let { fromstageid, fromstatusid, tostageid, tostatusid } = transitionData 
  try {
      const response = await httpService.post(`${apiEndpoint}/deleteTransition`,{
       "workflowid":workflowid,
       "fromstageid":fromstageid,
       "fromstatusid":fromstatusid,
       "tostageid":tostageid,
       "tostatusid":tostatusid
      });
      console.log(response);
      return response;
  } catch (error) {
      console.error("Error deleting workflow:", error.message);
      throw error; 
  }
}

export async function deletestatus(workflowid,stageId,statusId) {
  try {
    alert("checkapi")
      const response = await httpService.post(`${apiEndpoint}/deletestatus`,({statusId}))
      console.log(response);
      return response;
  } catch (error) {
      console.error("Error deleting workflow:", error.message);
      throw error; 
  }
}



export async function addStatus(workflowid,stageid,statusName,statusDescription,vertexId) {
  try {
    const data = await httpService.post(`${apiEndpoint}/addStatus`, {
      "workflowid":workflowid,
      "stageid": stageid,
      "statusName": statusName,
      "description":statusDescription,
      "vertexId":vertexId
    });
    return data;
  } catch (error) {
    console.error("Error Adding workflow:", error.message);
    throw error;
  }
}


export async function getSelectedStage(stageid) {
  let data = await httpService.post(`${apiEndpoint}/getSelectedStage`,{"stageid":stageid});
  console.log(data);
  return data;
}

export async function createDuplicate(documentId,email) {
  let data = await httpService.post(`${apiEndpoint}/createCopyOfWorkflow`,{"id":documentId,"email":email});
  console.log(data);
  return data;
}

export async function callUseForWorkflow(documentId,email,orgid) {
  let data = await httpService.post(`${apiEndpoint}/useForWorkflow`,{"id":documentId,"email":email, "orgid":orgid});
  console.log(data);
  return data;
}

export async function deleteworkflows(id,email,isAdmin) {
  let data = await httpService.post(`${apiEndpoint}/deleteWorkflow`,{"id":id,"email":email,"isAdmin":isAdmin});
  console.log(data);
  return data;
}

export async function addOrgInDefaultWorkflow(data){
  const response=await httpService.post(`${apiEndpoint}/addOrgInDefaultWorkflow`,{data});
  return response;
}

export async function checkOrgInDefaultWorkflow(data){
  const response=await httpService.post(`${apiEndpoint}/checkOrgInDefaultWorkflow`,{data});
  return response;
}

export async function checkIsworkflowAdded(orgId) {
  let data = await httpService.post(`${apiEndpoint}/checkIsworkflowAdded`,{orgId});
  console.log(data);
  return data;
}

export async function getMultipleWorkflows(data) {
  let response = await httpService.post(`${apiEndpoint}/getmultipleWorkflowsbyid`,{data});
  console.log(data);
  return response;
}

export async function getWorkflowStages(data) {
  let response = await httpService.post(`${apiEndpoint}/getWorkflowStages`,data);
  console.log(data,"getWorkflowStages");
  return response;
}


export async function mergedStages(data) {
  let response = await httpService.post(`${apiEndpoint}/mergeStages`,data);
  console.log(data,"mergeStages");
  return response;
}


export async function splitedStages(data) {
  let response = await httpService.post(`${apiEndpoint}/splitStage`,data);
  console.log(data,"splitStage");
  return response;
}






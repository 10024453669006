
import React, { useState, useEffect } from "react";
import ImageCard from "./ReusableComponents/ImageCard";
import SearchInputField from "./ReusableComponents/SearchInputField";
import rfp from ".././static/admin_dashboard/rfp.png";
import pending_for_approval from ".././static/admin_dashboard/pending_for_approval.png";
import Cancel from ".././static/admin_dashboard/Cancel.png";
import Completed from ".././static/admin_dashboard/Completed.png";
import PieChartSubAdmin from "./ReusableComponents/PieChartSubAdmin";
import "react-datepicker/dist/react-datepicker.css";
import filter from ".././static/admin_dashboard/filter.png";
import Calendar from ".././static/admin_dashboard/Calendar.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RecentActivityCard from "./ReusableComponents/RecentActivityCard";
import ReminderCard from "./ReusableComponents/ReminderCard";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BarChartComp from "./ReusableComponents/BarChartComp";
import Draggable from "react-draggable";
import CompletedProjectPage from "./CompletedProjectPage";
import SearchIcon from '@mui/icons-material/Search';
import assetService, {
  getCancelledProjects,
  getCompletedProjects,
  getInprogressProjects,
  getSpecificAsset,
  getassetsbyid,
  getrecentactivities,
} from "../services/assetService";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  myprojectScreen,
  proposalWritingData,
  reminderAssetid,
  rfmManagmentData,
} from "../redux/opportunity/opportunity.action";
import { MenuItem, Popover } from "@mui/material";
import moment from 'moment';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { markasread, markallasread } from "../services/activityTransaction";
import CancelledProjectsPage from "./CancelledProjectsPage";
import ActiveProjectsPage from "./ActiveProjectsPage";
import AllocatedProjectsPage from "./AllocatedProjectsPage";
import { allocatedProjects } from "../services/assetsSharingService";
import { getSubadminDashboardSectionsVisibility, resetSubadminDashboardCustomization, updateSubadminDashboardSectionsPosition, updateSubadminDashboardSectionsVisibility, proposalProgressTracker, getTaskCompletionCount } from "../services/dashboardServices";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "react-bootstrap";
import { ReportModal } from "./pages/Modals/teamDeadLineModal";
import { Loader } from "semantic-ui-react";
import { getMultipleWorkflows } from "../services/workflowServices";
import { showToast } from "../messages";
import {
  ListItemIcon,
} from '@material-ui/core';
import { getPerfomanceMatrics } from "../services/performanceMetrics";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography
} from '@mui/material';
import TaskCompletionChart from "./TaskCompletionChart";
import RestoreIcon from '@mui/icons-material/Restore'; // Import RestoreIcon
import Divider from '@mui/material/Divider';

const visibilityStateMapping = {
  "Project Status Cards": "projectStatusCards",
  "Recent Activities": "recentActivities",
  "Deadline Reminder": "deadlineReminder",
  "Proposal Progress": "proposalProgress",
  "Task Completion Overview": "taskCompleteionOverview",
  "Project Completeion Metrics": "projectCompleteionMetrics",
  "Report & Analytics": "reportBtn",
};

const DashboardPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [showAllDeadlines, setShowAllDeadlines] = useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const history = useHistory();
  const [showActiveProjectsPopup, setShowActiveProjectsPopup] = useState(false);
  const [visibleDeadlineData, setVisibleDeadlineData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [activeProjectCount, setActiveProjectCount] = useState();
  const [filterOption, setFilterOption] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const dispatch = useDispatch();
  const [recentActivities, setRecentActivities] = useState([]);
  const [activityFilter, setActivityFilter] = useState(null);
  const [projectTypeFilter, setProjectTypeFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterIndicator, setFilterIndicator] = useState("");
  const [activityId, setActivityId] = useState("")
  const [activityAnchorEl, setActivityAnchorEl] = useState(null);
  const [projectTypeAnchorEl, setProjectTypeAnchorEl] = useState(null);
  const [visibleActivities, setVisibleActivities] = useState([]);
  const [activitySearchTerm, setActivitySearchTerm] = useState("");
  const [activityFilterAnchorEl, setActivityFilterAnchorEl] = useState(null);
  const [clearAllAnchorEl, setClearAllAnchorEl] = useState(null);
  const [showCancelledProjectsPopup, setShowCancelledProjectsPopup] = useState(false);
  const [showCompletedProjectsPopup, setShowCompletedProjectsPopup] = useState(false);
  const [showAllocatedProjectsPopup, setShowAllocatedProjectsPopup] = useState(false);
  const [cancelledProjectCount, setCancelledProjectCount] = useState();
  const [allocatedProjectCount, setAllocatedProjectCount] = useState();
  const [completedProjectCount, setCompletedProjectCount] = useState();
  const [statusType, setStatusType] = useState('');
  const [searchedDataCount, setSearchedDataCount] = useState(0);
  const [rfpSearchedDataCount, setRfpSearchedDataCount] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [deadlineReminderList, setDeadlineReminderList] = useState(null);
  const [visibilityStates, setVisibilityStates] = useState({
    projectStatusCards: { visible: true, position: { x: 0, y: 0 } },
    recentActivities: { visible: true, position: { x: 0, y: 0 } },
    deadlineReminder: { visible: true, position: { x: 0, y: 0 } },
    proposalProgress: { visible: true, position: { x: 0, y: 0 } },
    projectCompleteionMetrics: { visible: true, position: { x: 0, y: 0 } },
    taskCompleteionOverview: { visible: true, position: { x: 0, y: 0 } },
    reportBtn: { visible: true, position: { x: 0, y: 0 } },
  });

  const [proposalProgressData, setProposalProgressData] = useState(null)
  const [chartData, setChartData] = useState([]);
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgId = sessionStorage.getItem("organizationId")
        const response = await getTaskCompletionCount(orgId); // Replace with actual orgId
        const data = response.assetCompletionCounts;

        // Transform data into required format
        const transformedData = data.map(item => ({
          projectTitle: item.ProjectTitle,
          Pending: item.Pending,
          Complete: item.Complete,
        }));

        setChartData(transformedData);
      } catch (error) {
        console.error('Error fetching task completion data:', error);
      }
    };

    fetchData();
  }, []);

  const StatusColors = {
    pending: "Orange",
    complete: "#18A350",
  };
  const menuItemStyles = {
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#A8D2FF",
      color: "white",
    },
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleResetToDefault = async () => {
    try {
      const response = await resetSubadminDashboardCustomization({ userEmail: userEmail });
      if (response.status === 200) {
        setVisibilityStates(response.data);
        console.log('Dashboard reset to default successfully.');
      } else {
        console.error('Failed to reset dashboard customization:', response.message);
      }
    } catch (error) {
      console.error('An error occurred while resetting dashboard customization:', error);
    }
  };

  const handleDragStop = async (e, data, section) => {
    const newPosition = { x: data.x, y: data.y };
    try {
      const response = await updateSubadminDashboardSectionsPosition({
        userEmail: userEmail,
        section,
        position: newPosition,
      });
      console.log(response.data.message);

      setVisibilityStates((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          position: newPosition,
        },
      }));
    } catch (error) {
      console.error("Error updating section position:", error);
    }
  };
  const handleVisibilityToggle = async (option) => {
    const userEmail = sessionStorage.getItem("currentUserEmail");
    try {
      const newVisibilityStates = {
        ...visibilityStates,
        [visibilityStateMapping[option]]: {
          ...visibilityStates[visibilityStateMapping[option]],
          visible: !visibilityStates[visibilityStateMapping[option]].visible,
        },
      };
      const mappedSectionName = visibilityStateMapping[option];
      const response = await updateSubadminDashboardSectionsVisibility({
        userEmail: userEmail,
        section: mappedSectionName,
        visibility: newVisibilityStates[visibilityStateMapping[option]].visible,
      });

      if (response.status === 200) {
        setVisibilityStates(newVisibilityStates);
      } else {
        console.error("Failed to update visibility:", response.message);
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  const menuOptions = [
    "Project Status Cards",
    "Recent Activities",
    "Deadline Reminder",
    "Proposal Progress",
    "Task Completion Overview",
    "Project Completeion Metrics",
    "Report & Analytics",

  ];
  const userEmail = sessionStorage.getItem("currentUserEmail")
  useEffect(() => {
    async function fetchVisibility() {
      try {
        const response = await getSubadminDashboardSectionsVisibility({
          userEmail: userEmail,
        });
        if (response) {
          setVisibilityStates(response?.data);
        }
      } catch (error) {
        console.error("Error fetching visibility:", error);
      }
    }

    if (userEmail) {
      fetchVisibility();
    }
  }, [userEmail]);

  const handleClearAllIconClick = (event) => {
    setClearAllAnchorEl(event.currentTarget);
  };

  const handleCloseClearAll = () => {
    setClearAllAnchorEl(null);
  };

  const handleClearAll = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      await markallasread(email);
      setRecentActivities([]);
      handleCloseClearAll();
      showToast('Success.clearActivities')
    } catch (error) {
      console.error("Error marking all activities as read:", error);
    }
  };
  const handleActivityFilterIconClick = (event) => {
    setActivityFilterAnchorEl(event.currentTarget);
  };

  const handleActivitySearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setActivitySearchTerm(value);

    // Filter recent activities by title
    const filteredData = recentActivities.filter((activity) =>
      activity.projectname.toLowerCase().includes(value)
    );
    setVisibleActivities(filteredData);
    setSearchedDataCount(filteredData.length);

  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActivityFilterChange = (filter) => {
    setActivityFilter(filter);
    filterVisibleActivities(filter, projectTypeFilter);
    setFilterIndicator(
      filter && projectTypeFilter
        ? `Filtered by Activity Type: ${filter} and Project Type: ${projectTypeFilter}`
        : filter
          ? `Filtered by Activity Type: ${filter}`
          : projectTypeFilter
            ? `Filtered by Project Type: ${projectTypeFilter}`
            : ""
    );
  };

  const handleProjectTypeFilterChange = (filter) => {
    setProjectTypeFilter(filter);
    filterVisibleActivities(activityFilter, filter);
    setFilterIndicator(
      filter && activityFilter
        ? `Filtered by Project Type: ${filter} and Activity Type: ${activityFilter}`
        : filter
          ? `Filtered by Project Type: ${filter}`
          : activityFilter
            ? `Filtered by Activity Type: ${activityFilter}`
            : ""
    );
  };

  const handleFilterOptionChange = (option) => {
    switch (option) {
      case "all":
        setActivityFilter(null);
        setProjectTypeFilter(null);
        setFilterIndicator("");
        break;
      case "activity":
        setProjectTypeFilter(null);
        break;
      case "project":
        setActivityFilter(null);
        break;
      default:
        break;
    }
    setFilterOption(option);
    filterVisibleActivities(null, null);
  };
  const handleCompletedClick = () => {
    setShowCompletedPopup(true);
  };

  const handleClosePopup = () => {
    setShowCompletedPopup(false);
  };
  const pieChartData = [
    { title: "Sec A", value: 31, color: "#80D699" },
    { title: "Sec B", value: 25, color: "#FC7575" },
    { title: "Sec C", value: 18, color: "#EDD544" },
    { title: "Sec D", value: 20, color: "#F67F4B" },
    { title: "Sec E", value: 6, color: "#9144ED" },
  ];

  const radius = 50;
  const xAxisData = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // const barChartData = [
  //   { day: "Sun", secA: 80, secB: 60, secC: 40, secD: 30, secE: 50 },
  //   { day: "Mon", secA: 85, secB: 65, secC: 45, secD: 35, secE: 55 },
  //   { day: "Tue", secA: 80, secB: 60, secC: 40, secD: 30, secE: 50 },
  //   { day: "Wed", secA: 85, secB: 65, secC: 45, secD: 35, secE: 55 },
  //   { day: "Thu", secA: 80, secB: 60, secC: 40, secD: 30, secE: 50 },
  //   { day: "Fri", secA: 85, secB: 65, secC: 45, secD: 35, secE: 55 },
  //   { day: "Sat", secA: 80, secB: 60, secC: 40, secD: 30, secE: 50 },
  // ];

  const sectionColors = {
    secA: "#EE7F33",
    secB: "#2E75B6",
    secC: "#EDD544",
    secD: "#62BB7C",
    secE: "#DE3472",
  };

  const barChartSelectOptions = [
    { label: "This Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];
  const legendStyles = {
    container: {
      color: "black",
    },
    items: [
      {
        value: "Sec A",
        type: "circle",
        color: sectionColors.secA,
        id: "legend-secA",
      },
      {
        value: "Sec B",
        type: "circle",
        color: sectionColors.secB,
        id: "legend-secB",
      },
      {
        value: "Sec C",
        type: "circle",
        color: sectionColors.secC,
        id: "legend-secC",
      },
      {
        value: "Sec D",
        type: "circle",
        color: sectionColors.secD,
        id: "legend-secD",
      },
      {
        value: "Sec E",
        type: "circle",
        color: sectionColors.secE,
        id: "legend-secE",
      },
    ],
  };

  const activityData = [
    {
      type: "updates",
      username: "John Doe",
      projectname: "Project X - IT Infrastructure Upgrade",
      time: "5 minutes ago",
    },
    {
      type: "comments",
      username: "Alice Smith",
      projectname: "Project Y - Software Development",
      time: "2 hour ago",
    },
    {
      type: "approval",
      username: "Bob Johnson",
      projectname: 'for section 3.4 of "Project Z - Network Security Audit."',
      time: "3 hour ago",
    },
    {
      type: "approval",
      username: "Bob Johnson",
      projectname: 'for section 2.3 of "Project Z - Network Security Audit."',
      time: "7 hour ago",
    },
    {
      type: "comments",
      username: "Alice Smith",
      projectname: 'Project L - Network Security Audit."',
      time: "2 hour ago",
    },
    {
      type: "comments",
      username: "Alice Smith",
      projectname: "Project Y - Software Development",
      time: "2 hour ago",
    },
  ];
  const MoreIconClick = () => {
    console.log("MoreIconClick clicked on Page 1");
  };

  // const visibleActivities = showAll ? activityData : activityData.slice(0, 4)

  const deadlineData = [
    {
      projectname: "Project X - IT Infrastructure Upgrade",
      date: "13 March 2024",
      color: "red",
    },
    {
      projectname: "Project Y - Software Development",
      date: "20 March 2024",
      color: "yellow",
    },
    {
      projectname: "Project Z - Network Security Audit",
      date: "25 March 2024",
      color: "green",
    },
    {
      projectname: "Project XA - IT Infrastructure Reset",
      date: "27 March 2024",
      color: "green",
    },
    {
      projectname: "Project SX - SOIB Central Service",
      date: "29 March 2024",
      color: "green",
    },
    {
      projectname: "Project SX - SOIB Central Service",
      date: "29 March 2024",
      color: "green",
    },
    {
      projectname: "Project SX - SOIB Central Service",
      date: "29 March 2024",
      color: "green",
    },
    {
      projectname: "Project SX - SOIB Central Service",
      date: "29 March 2024",
      color: "green",
    },
  ];
  // const visibleDeadlineData = showAllDeadlines ? deadlineData : deadlineData.slice(0, 5);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    //search-related actions here
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filterDeadlineData = () => {
    switch (filterOption) {
      case "all":
        return visibleDeadlineData;
      case "passed":
        return visibleDeadlineData.filter((item) => item.color === "black");
      case "near":
        return visibleDeadlineData.filter((item) => item.color === "red");
      case "moderatelyNear":
        return visibleDeadlineData.filter((item) => item.color === "orange");
      case "onTime":
        return visibleDeadlineData.filter((item) => item.color === "green");
      default:
        return visibleDeadlineData;
    }
  };
  const handleViewMoreDeadlines = () => {
    setShowAllDeadlines(true);
  };
  const handleViewLessDeadlines = () => {
    setShowAllDeadlines(false);
  };
  useEffect(() => {
    fetchDeadlineData();
    fetchRecentActivities();
    fetchActiveprojectsCount();
    fetchCompletedprojectsCount();
    fetchCancelledprojectsCount();
    fetchAllocatedprojectsCount()
    getAllExistingData()
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }
    const datePart = dateString.split(" at ")[0];
    const date = new Date(datePart);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const timeAgo = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diff = now - createdAt;

    if (diff < 60000) {
      return `${Math.floor(diff / 1000)} seconds ago`;
    } else if (diff < 3600000) {
      return `${Math.floor(diff / 60000)} minutes ago`;
    } else if (diff < 86400000) {
      return `${Math.floor(diff / 3600000)} hours ago`;
    } else {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return createdAt.toLocaleDateString(undefined, options);
    }
  };

  const getColorBasedOnDeadline = (deadlineDate) => {
    const currentDate = new Date();
    const differenceInDays = Math.ceil(
      (new Date(deadlineDate) - currentDate) / (1000 * 60 * 60 * 24)
    );

    const totalDays = 30;
    const percentageRemaining = (differenceInDays / totalDays) * 100;

    if (differenceInDays < 0) {
      return "black";
    } else if (percentageRemaining <= 33.33) {
      return "red";
    } else if (percentageRemaining <= 66.33) {
      return "orange";
    } else {
      return "green";
    }
  };

  async function fetchRecentActivities() {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getrecentactivities(data);
      console.log(response, "Response OF recent activities");
      const activities = response.simplifiedTransactions.map((item) => ({
        projectname: item.title,
        username: item.createdBy,
        id: item._id,
        time: timeAgo(item.createdOn),
        msg: item.msg,
        assetType: item.assetType,
        assetId: item.assetId,
      }));
      const id = response.simplifiedTransactions[0]._id
      console.log(id, "iiiiiid")
      setActivityId(id)
      setRecentActivities(activities);
      console.log(response.simplifiedTransactions, "dataaaa");
    } catch (error) {
      console.error("Error fetching recent activities:", error);
    }
  }

  const fetchDeadlineData = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getassetsbyid(data);
      const deadlineData = response.data
        .filter((item) => {
          const deadlineDate = new Date(item.opportunity.responseDeadLine);
          const currentDate = new Date();
          return item.opportunity.responseDeadLine !== null && deadlineDate > currentDate;
        })
        .map((item) => ({
          projectname: item.opportunity.title,
          _id: item._id,
          date: formatDate(item.opportunity.responseDeadLine),
          color: getColorBasedOnDeadline(
            formatDate(item.opportunity.responseDeadLine)
          ),
        }));
        console.log(deadlineData,"deadlineDatadeadlineData")
        setDeadlineReminderList(deadlineData)
      setVisibleDeadlineData(
        deadlineData.sort((a, b) => new Date(a.date) - new Date(b.date))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const visibleActivities = showAll ? recentActivities : recentActivities.slice(0, 4)
  const handleMarkAsReadActivity = async () => {
    try {
      const id = activityId
      const userEmail = sessionStorage.getItem("currentUserEmail");
      const data = { id, userEmail };
      await markasread(data);
      // Remove the activity from recentActivities
      setRecentActivities(recentActivities.filter(activity => activity._id !== id));
    } catch (error) {
      console.error("Error marking activity as read:", error);
    }
  };

  const handleRedirecttoRfp = async (assetId, assetType) => {
    console.log("called", assetId, assetType);
    try {
      let data = {
        assetId,
      };
      const response = await getSpecificAsset(data);
      if (response.status == 200) {
        console.log(response, "specific asset found");
        const data = response.data;
        if (assetType === "rfp") {
          dispatch(
            rfmManagmentData(
              data.opportunityid.title,
              data.opportunityid.noticeId,
              data.opportunityid.descriptionData,
              data.opportunityid._id,
              data.opportunityid.type,
              data.opportunityid?.responseDeadLine,
              data.opportunityid?.fullParentPathName,
              data._id,
              data.opportunityid?.resourceLinksPath
            )
          );
          dispatch(myprojectScreen("Oppoupload"));
          history.push("/rfpManagement");
        } else if (assetType === "proposal") {
          dispatch(
            proposalWritingData(
              data.opportunityid.title,
              data.opportunityid.noticeId,
              data.opportunityid.descriptionData,
              data.opportunityid._id,
              data.opportunityid.type,
              data.opportunityid?.responseDeadLine,
              data.opportunityid?.fullParentPathName,
              data._id
            )
          );

          history.push("/proposalWriting");
        }
        handleMarkAsReadActivity();
      }
    } catch (error) {
      console.log("error in finding specific asset", error);
    }
  };

  const handleRedirect = (reminderAssetId) => {
    console.log("redirect", reminderAssetId);
    dispatch(reminderAssetid(reminderAssetId));
    history.push(`/OppourtunityManagementSubadminRedirect/${reminderAssetId}`);
  };

  const filterVisibleActivities = (activityFilter, projectTypeFilter) => {
    let filteredData = recentActivities;

    if (projectTypeFilter) {
      filteredData = filteredData.filter(
        (activity) => activity.assetType === projectTypeFilter
      );
    }
    if (activityFilter) {
      filteredData = filteredData.filter((activity) =>
        activity.msg.toLowerCase().startsWith(activityFilter)
      );
    }
    setVisibleActivities(filteredData);
  };

  useEffect(() => {
    setVisibleActivities(
      showAll ? recentActivities : recentActivities.slice(0, 4)
    );
  }, [recentActivities, showAll]);

  const handleActiveProjectsPopupOpen = (type) => {
    setStatusType(type)
    setShowActiveProjectsPopup(true);
  };

  const handleActiveProjectsPopupClose = () => {
    setShowActiveProjectsPopup(false);
    setStatusType('')
  };

  const handleCancelledProjectsPopupOpen = () => {
    setShowCancelledProjectsPopup(true);
  };

  const handleCancelledProjectsPopupClose = () => {
    setShowCancelledProjectsPopup(false);
  };

  const handleCompletedProjectsPopupOpen = (type) => {
    setStatusType(type)
    setShowCompletedProjectsPopup(true);
  };

  const handleCompletedProjectsPopupClose = () => {
    setShowCompletedProjectsPopup(false);
  };

  const handleAllocatedProjectsPopupOpen = () => {
    setShowAllocatedProjectsPopup(true);
  };

  const handleAllocatedProjectsPopupClose = () => {
    setShowAllocatedProjectsPopup(false);
  };
  const fetchActiveprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const data = { email }
      const response = await getInprogressProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count
        setActiveProjectCount(projectsCount);
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  const fetchCancelledprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const data = { email }
      const response = await getCancelledProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count
        setCancelledProjectCount(projectsCount);
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchAllocatedprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { loggedInEmail: email };
      const response = await allocatedProjects(data);

      if (response.status === 200) {
        const projectsCount = response.count
        setAllocatedProjectCount(projectsCount);
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  const fetchCompletedprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const data = { email }
      const response = await getCompletedProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count
        setCompletedProjectCount(projectsCount);
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  const [data, setData] = useState([]);
  const [project, setProject] = useState([]);
  const [isReportModal, setIsReportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [workflowData, setWorkflowData] = useState([])
  const getOppo = async () => {
    try {
      setLoading(true);
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      console.log(email, "email");
      const response = await assetService.getassetsbyid(data);
      if (response) {
        console.log("response of getopportunitymatch ", response);
        setData(response?.data);
        const projectOpportunities = response.data.filter(
          (item) => item.type === "Project" && item.status !== "Cancel" && item.status !== "Complete"
        );
        setProject(projectOpportunities);

        let WorkflowIds = []
        let data = {
          WorkflowIds
        }
        projectOpportunities.map((item) => WorkflowIds.push(item.workflowId));
        console.log(WorkflowIds, "WorkflowIds");
        const response1 = await getMultipleWorkflows(data);
        console.log(response1, "response1");
        setWorkflowData(response1.data)

        setIsReportModal(true)
      } else {
        console.log("error in response");
      }
    } catch (e) {
      console.log(e, "errrororrooror");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseReportModal = () => {
    setIsReportModal(false)
  }
  const [barChartData, setBarChartData] = useState([]);
  const [rows, setRows] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const getAllExistingData =async ()=>{
    try{
      let data = {
        orgId:sessionStorage.getItem("organizationId")
      }
      const res = await getPerfomanceMatrics(data);
      if(res.status == 200){
        console.log("rows",res)
        setRows(res.data)
        const transformedData = transformData(res.data);
        setBarChartData(transformedData);
      }
    }catch(error){
      console.log("error",error)
    }finally{
      return true
    }
  }

  useEffect(() => {
    // Re-transform data when the selected year changes
    const transformedData = transformData(rows, selectedYear);
    setBarChartData(transformedData);
  }, [selectedYear, rows]);



  const transformData = (rows) => {
    const groupedData = rows.reduce((acc, row) => {
      const monthYear = moment(row.submissionDate).format('MMM YYYY');
      if (!acc[monthYear]) {
        acc[monthYear] = { month: monthYear, winningPercentage: 0, lostPercentage: 0 };
      }
      acc[monthYear].winningPercentage += row.winningPercentage;
      acc[monthYear].lostPercentage += row.lostPercentage;
      return acc;
    }, {});
  
    return Object.values(groupedData);
  };



  const handleProposalProgress = async () => {
    try {
      const orgId = sessionStorage.getItem("organizationId")
      const response = await proposalProgressTracker({ orgId });
      if (response.status === 200) {
        console.log(response.data, "resssss")
        setProposalProgressData(response.data);
        console.log('Proposal progress data fetched successfully.');
      } else {
        console.error('Failed to fetch proposal progress data.');
      }
    } catch (error) {
      console.error('Failed to fetch proposal progress data.');
    }
  };
  console.log(proposalProgressData, "proposalProgressData")


  useEffect(() => {
    handleProposalProgress()
    const intervalId = setInterval(handleProposalProgress, 60000);
    return () => clearInterval(intervalId);
  }, [])

  const getCellBackgroundColor = (stage) => {
    switch (stage) {
      case 'Cancel':
        return '#DA4141'; // Red background
      case 'Inprogress':
        return '#DBBC00'; // Yellow background
      case 'Complete':
        return '#18A350'; // Green background
      default:
        return 'inherit';
    }
  };

  const getCellTextColor = (stage) => {
    switch (stage) {
      case 'Cancel':
      case 'Inprogress':
      case 'Complete':
        return '#ffffff'; // White text for these stages
      default:
        return 'inherit';
    }
  };





  return (
    <>
      <div style={{ display: "grid" ,
        width:"100%",
        justifyContent:"space-between",
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '15px',
      }}>
        <Loader loading={loading} />
        <div 
        style={{
          width:"100%",
          boxSizing: 'border-box',
          gridColumn: windowWidth ? '1 / -1' : 'auto',
          paddingLeft: windowWidth ? '15px': 0,
          // border:"2px solid black"
        }}
        >
    
            <div style={{ marginLeft: "10px", width: "620px" }}>
              {/* {visibilityStates.searchRFPorProposal?.visible && (
                <Link to="/OppourtunityManagementSubadmin">
                  <button
                    style={{
                      marginLeft: "8px",
                      height: "40px",
                      border: "1px solid gray",
                      color: "gray",
                      backgroundColor: "white",
                      cursor: "pointer",
                      padding: "0 10px",
                      width: "300px",
                      boxShadow: "2px 2px 5px -2px #000000",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  // onClick={handleButtonClick}
                  >
                    <SearchIcon />
                    Search RFPs or Proposals..
                  </button>
                </Link>
              )} */}
              {/* <button
                onClick={handleMenuClick}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  padding: "0 10px",
                  width: "140px",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize: "15px",
                }}
              >
                Customization
              </button> */}
              {/* <button
               onClick={handleResetToDefault}
                 style={{
                marginLeft: "10px",
                height: "40px",
                border: "1px solid #1976D2",
                 color: "#007BFF",
                   backgroundColor: "white",
                  cursor: "pointer",
                   padding: "0 10px",
                width: "140px",
                 boxShadow: "2px 2px 5px -2px #000000",
                borderRadius: "10px",
                  fontSize: "15px",
                  }}
                    >
              Reset to Default
                </button> */}
              {/* <button
                onClick={getOppo}
                style={{
                  marginLeft: "10px",
                  height: "40px",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  padding: "0 10px",
                  width: "140px",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize: "15px",
                }}
              >
                Report
              </button> */}
<Menu
  anchorEl={menuAnchorEl}
  open={Boolean(menuAnchorEl)}
  onClose={handleMenuClose}
    PaperProps={{
    sx: {
      "& .MuiList-root": {
        paddingTop: '0px !important',
      },
    },
  }}

>
  {menuOptions.map((option) => (
    <MenuItem
      key={option}
      onClick={() => handleVisibilityToggle(option)}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "#A8D2FF",
          color: "white", // Optional: Change the text color to white when hovering
        },
      }}
    >
      {option}
      {visibilityStates[visibilityStateMapping[option]]?.visible ? (
        <VisibilityOffIcon
          style={{ marginLeft: "20px", cursor: "pointer", color: "gray" }}
          onClick={(event) => {
            event.stopPropagation();
            handleVisibilityToggle(option);
          }}
        />
      ) : (
        <VisibilityIcon
          style={{ marginLeft: "20px", cursor: "pointer", color: "gray" }}
          onClick={(event) => {
            event.stopPropagation();
            handleVisibilityToggle(option);
          }}
        />
      )}
    </MenuItem>
  ))}
  <Divider />
  <MenuItem
    onClick={handleResetToDefault}
    sx={{
      color:'#1976d2',
      alignItems:'center',
      display: 'flex',
      justifyContent: 'center',
      "&:hover": {
        backgroundColor: "#A8D2FF",
        color: "white",
      },
    }}
  >
    Reset to Default Layout
  </MenuItem>
</Menu>

            </div>
          {/* <div style={{ 
            display: "flex",
            border:"10px solid yellow" 
            ,height:"250px",width:"620px",marginLeft:"2%"}}> */}
<div 
style={{

  // backgroundColor:"gray"
}}
>
  <div>
              <Draggable
                position={visibilityStates.projectStatusCards.position}
                onStop={(e, data) =>
                  handleDragStop(e, data, "projectStatusCards")
                }
                cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
              >
                <div className="col-md-15" style={{ display:"flex",flexWrap:"wrap",width:"100%",height:"200px"}}>
                  <div
                   className="non-draggable"
                    // className="col-md-3"
                    onClick={() => handleActiveProjectsPopupOpen('Active Projects')}
                    style={{ marginBottom: "10px", cursor: "pointer",width:"48%",height:"90px" }}
                  >
                  {visibilityStates.projectStatusCards?.visible && (
                    <ImageCard
                      bgColor="#4676A5"
                      altImg="Active Projects"
                      imgSrc={rfp}
                      cardName="Active Projects"
                      number={activeProjectCount}
                      height="100%"
                      width="100%"
                      subAdmin={true}
                    />
                      )}
                  </div>
                  <div
                   className="non-draggable"
                    // className="col-md-3"
                    onClick={handleCancelledProjectsPopupOpen}
                    style={{ marginBottom: "10px", cursor: "pointer" ,width:"48%",height:"90px",marginLeft:"4%"}}
                  >
                  {visibilityStates.projectStatusCards?.visible && (
                    <ImageCard
                      bgColor="#DA4141"
                      altImg="Cancelled"
                      imgSrc={Cancel}
                      cardName="Cancelled"
                      number={cancelledProjectCount}
                       height="100%"
                      width="100%"
                      subAdmin={true}
                    />
                      )}
                  </div>
                  <div
                   className="non-draggable"
                    // className="col-md-3"
                    style={{ marginBottom: "10px", cursor: "pointer" ,width:"48%",height:"90px",marginTop:"5px"}}
                    onClick={handleCompletedProjectsPopupOpen}
                  >
                  {visibilityStates.projectStatusCards?.visible && (
                    <ImageCard
                      bgColor="#18A350"
                      altImg="Completed"
                      imgSrc={Completed}
                      cardName="Completed"
                      number={completedProjectCount}
                       height="100%"
                      width="100%"
                      subAdmin={true}
                    />
                  )}
                  </div>
                  <div
                   className="non-draggable"
                    // className="col-md-3"
                    style={{ marginBottom: "10px", cursor: "pointer" ,width:"48%",height:"90px",display:"flex",flexDirection:"column",justifyContent:"space-between",marginTop:"5px",marginLeft:"4%"}}
                  >
                     <button
                onClick={handleMenuClick}
                style={{
                  // marginLeft: "10px",
                  height: "40px",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  padding: "0 10px",
                  width: "100%",
                  height: "45%",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize: "15px",
                }}
              >
                Customization
              </button>
              {visibilityStates.reportBtn?.visible && (
              <button
                onClick={getOppo}
                style={{
                  // marginLeft: "10px",
                  height: "40px",
                  border: "1px solid #1976D2",
                  color: "#007BFF",
                  backgroundColor: "white",
                  cursor: "pointer",
                  padding: "0 10px",
                  width: "100%",
                  height: "45%",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  fontSize: "15px",
                }}
              >
                Report & Analytics
              </button>
              )}
                  </div>

                 
                </div>
              </Draggable>
            {/* )} */}
  </div>
  <div
  style={{
    width:"100%"
  }}
  >
 
 
       <div
            style={{
              marginTop: windowWidth? "10px" : "25px",
              // marginLeft: "1%",
              width: "100%",
              // border:"2px solid green"
            }}
          >
            {visibilityStates.taskCompleteionOverview?.visible && (
              <Draggable
                position={visibilityStates.taskCompleteionOverview.position}
                onStop={(e, data) =>
                  handleDragStop(e, data, "taskCompleteionOverview")
                }
                cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
              >
              <div className="non-draggable">
              <TaskCompletionChart
                chartData={chartData}
                chartHeading="Task Completion Overview"
                sectionColors={StatusColors}
                boxWidth={620}
                boxHeight={255}
              />
              </div>
              </Draggable>
            )}
            {visibilityStates.projectCompleteionMetrics?.visible && (
              <Draggable
                position={visibilityStates.projectCompleteionMetrics.position}
                onStop={(e, data) =>
                  handleDragStop(e, data, "projectCompleteionMetrics")
                }
                cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
              >
            <div className="non-draggable">
            <BarChartComp
              chartHeading="Projects Completion Metrics"
              xAxisData={xAxisData}
              barChartData={rows}
              isSelectDropdownShown={true}
              barChartSelectOptions={barChartSelectOptions}
              selectDropdownDisabled={false}
              boxWidth={620}
              boxHeight={255}
              sectionColors={sectionColors}
              legendStyles={legendStyles}
              rows={rows}
            />
            </div>
            </Draggable>
            )}
          </div>
  </div>
 
</div>
         

            {/* {visibilityStates.taskCompletionCount?.visible && (
              <Draggable
                position={visibilityStates.taskCompletionCount.position}
                onStop={(e, data) =>
                  handleDragStop(e, data, "taskCompletionCount")
                }
              >
                <div style={{ marginTop: "10px" }}>
                  <PieChartSubAdmin
                    chartHeading="Task Completion Count"
                    pieChartData={pieChartData.map((data) => ({ ...data, radius }))}
                    // selectDropdownDisabled={false}
                    boxWidth={380}
                    boxHeight={310}
                  />
                </div>
              </Draggable>
            )} */}
          {/* </div> */}

        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gridTemplateRows: 'auto auto',
            gridColumn: windowWidth ? '1 / -1' : 'auto',
            // gap:"20px",
            paddingLeft: windowWidth ? '15px' : 0 ,
            // border:"2px solid blue"
          }}
        >
          <div 
          style={{
            width:"100%",
            height:"500px",
            display:"grid",
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            justifyContent:"space-between",
            // border:"3px solid orange",
            gridRow: windowWidth ? "2 / 3" : "1 / 2",
            order: windowWidth ? 2 : 1,
            gap:"15px"
  
          }}
          >
         
<div style={{  width: "100%",
                  height: "500px",
                  // border: "5px solid #ccc",
                  }}>

          {visibilityStates.recentActivities?.visible && (
            <Draggable
              position={visibilityStates.recentActivities.position}
              onStop={(e, data) => handleDragStop(e, data, "recentActivities")}
              cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
            >
              <div
               className="non-draggable"
                style={{
                  width: "100%",
                  height: "100%",
                  overflowY: "auto",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between",paddingBottom:"3px",borderBottom:"1px solid lightgray" }}>
                  <strong style={{ fontSize: "15px", color: "#1976d2" }}>
                    Recent Activities
                  </strong>
                  <FilterAltIcon
                    style={{ marginLeft: "90px",color:"#1976D2", cursor: "pointer" }}
                    onClick={handleActivityFilterIconClick}
                  />
                  <Popover
                    open={Boolean(activityFilterAnchorEl)}
                    anchorEl={activityFilterAnchorEl}
                    onClose={() => setActivityFilterAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div
                      style={{
                        // padding: "5px",
                        width: "150px",
                        display: "flex",
                        flexDirection: "column",
                        border:"1px solid #1976D2"
                      }}
                    >
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleFilterOptionChange("all")}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={(event) =>
                          setActivityAnchorEl(event.currentTarget)
                        }
                      >
                        By Type of Activity
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={(event) =>
                          setProjectTypeAnchorEl(event.currentTarget)
                        }
                      >
                        By Type of Project
                      </MenuItem>
                    </div>
                  </Popover>

                  {/* Sub popover for Activity */}
                  <Popover
                    open={Boolean(activityAnchorEl)}
                    anchorEl={activityAnchorEl}
                    onClose={() => setActivityAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div
                      style={{
                        // padding: "5px",
                        width: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleActivityFilterChange("uploaded")}
                      >
                        Uploaded
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleActivityFilterChange("added")}
                      >
                        Added
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleActivityFilterChange("updated")}
                      >
                        Updated
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleActivityFilterChange("deleted")}
                      >
                        Deleted
                      </MenuItem>
                    </div>
                  </Popover>

                  {/* Sub popover for Project Type */}
                  <Popover
                    open={Boolean(projectTypeAnchorEl)}
                    anchorEl={projectTypeAnchorEl}
                    onClose={() => setProjectTypeAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div
                      style={{
                        // padding: "5px",
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleProjectTypeFilterChange("rfp")}
                      >
                        RFP
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => handleProjectTypeFilterChange("proposal")}
                      >
                        Proposal
                      </MenuItem>
                    </div>
                  </Popover>

                  {/* <MoreVertIcon style={{ marginLeft: "8px", cursor: "pointer" }} onClick={handleClearAllIconClick} /> */}
                  <Popover
                    open={Boolean(clearAllAnchorEl)}
                    anchorEl={clearAllAnchorEl}
                    onClose={handleCloseClearAll}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div
                      style={{
                        // padding: "5px",
                        width: "90px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={handleClearAll}
                      >
                        Clear All
                      </MenuItem>
                    </div>
                  </Popover>
                </div>
                <div style={{ marginTop: "12px" }}>
                  <SearchInputField
                    placeholder="Search Project Title.."
                    onChange={handleActivitySearchChange}
                    value={activitySearchTerm}
                    height="30px"
                    width="100%"
                  />
                </div>
                {activitySearchTerm && searchedDataCount > 0 && (
                  <span style={{ marginTop: "10px", marginLeft: '50px' }}>
                    {searchedDataCount} Project's Activities Found!
                  </span>
                )}
                <span
                  style={{
                    textAlign: "center",
                    marginTop: filterIndicator ? "10px" : "0",
                  }}
                >
                  {filterIndicator}
                </span>
                {/* <div style={{ marginTop: '10px' }}>
                    <p style={{ color: '#1976D2', fontSize: '16px' }}>Today</p>
                    </div> */}
                <div style={{ marginTop: "5px", flex: 1 }}>
                  {visibleActivities.length > 0 ? (
                    visibleActivities.map((activity, index) => (
                      <RecentActivityCard
                        key={index}
                        {...activity}
                        redirectToRfp={handleRedirecttoRfp}
                      />
                    ))
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      No Activities Found!
                    </p>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {recentActivities.length > 4 &&
                    visibleActivities.length >= 4 &&
                    !showAll && (
                      <button
                        onClick={() => setShowAll(true)}
                        style={{
                          cursor: "pointer",
                          color: "#1976D2",
                          border: "none",
                          background: "white",
                          fontSize: "14px",
                          border: "1px solid #1976D2",
                          boxShadow: "2px 2px 5px -2px #000000",
                          width: "300px",
                        }}
                      >
                        View More
                      </button>
                    )}

{recentActivities.length > 4 &&
                    visibleActivities.length >= 4 &&
                    showAll && (
                      <button
                        onClick={() => setShowAll(false)}
                        style={{
                          cursor: "pointer",
                          color: "#1976D2",
                          border: "none",
                          background: "white",
                          fontSize: "14px",
                          border: "1px solid #1976D2",
                          boxShadow: "2px 2px 5px -2px #000000",
                          width: "300px",
                        }}
                      >
                        View Less
                      </button>
                    )}
                </div>
              </div>
            </Draggable>
          )}
          </div>
<div style={{  width: "100%",
                  height: "500px",
                  // border:'2px solid purple'
                  }}>
          {visibilityStates.deadlineReminder?.visible && (
            <Draggable
              position={visibilityStates.deadlineReminder.position}
              onStop={(e, data) => handleDragStop(e, data, "deadlineReminder")}
              cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
            >
              <div
               className="non-draggable"
                style={{
                  width: "100%",
                  height: "100%",
                  // border: "5px solid #ccc",
                  overflowY: "auto",
                  boxShadow: "2px 2px 5px -2px #000000",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  padding: "15px",
                }}
              >
                <div style={{ display: "flex",paddingBottom:"3px",borderBottom:"1px solid lightgray" ,color: "#1976d2", justifyContent: "space-between",}}>
                  <strong style={{ fontSize: "15px", color: "#1976d2" }}>
                    Deadline Reminder
                  </strong>
                  <FilterAltIcon
                    style={{ cursor: "pointer" }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  />
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div
                      style={{
                        // padding: "5px",
                        width: "130px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => {
                          setFilterOption("all");
                          setShowFilterOptions(false);
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => {
                          setFilterOption("passed");
                          setShowFilterOptions(false);
                        }}
                      >
                        Deadline passed
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => {
                          setFilterOption("near");
                          setShowFilterOptions(false);
                        }}
                      >
                        Near
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => {
                          setFilterOption("moderatelyNear");
                          setShowFilterOptions(false);
                        }}
                      >
                        Moderately near
                      </MenuItem>
                      <MenuItem
                        sx={menuItemStyles}
                        onClick={() => {
                          setFilterOption("onTime");
                          setShowFilterOptions(false);
                        }}
                      >
                        On time
                      </MenuItem>
                    </div>
                  </Popover>
                  {/* <MoreVertIcon style={{ marginLeft: "10px" }} /> */}
                </div>
                <div style={{ marginTop: "15px" }}>
                  {showAllDeadlines
                    ? // Render all deadline reminders if showAllDeadlines is true
                    filterDeadlineData().map((item, index) => (
                      <ReminderCard
                        key={index}
                        projectname={item.projectname}
                        date={item.date}
                        color={item.color}
                        onMoreIconClick={MoreIconClick}
                        redirect={handleRedirect}
                        assetid={item._id}
                      />
                    ))
                    : // Render only a subset of deadline reminders if showAllDeadlines is false
                    filterDeadlineData()
                      .slice(0, 4)
                      .map((item, index) => (
                        <ReminderCard
                          key={index}
                          projectname={item.projectname}
                          date={item.date}
                          color={item.color}
                          onMoreIconClick={MoreIconClick}
                          redirect={handleRedirect}
                          assetid={item._id}
                        />
                      ))}
                </div>
                <div
  style={{
    width: "100%",
    display: "flex",
    marginBottom: "15px",
    justifyContent: "flex-end",
    // border: "2px solid red",
  }}
>
  {!showAllDeadlines && filterDeadlineData().length >= 4 && (
    <button
      onClick={handleViewMoreDeadlines}
      style={{
        cursor: "pointer",
        color: "#1976D2",
        border: "none",
        background: "white",
        fontSize: "14px",
        border: "1px solid #1976D2",
        boxShadow: "2px 2px 5px -2px #000000",
        width: "280px",
      }}
    >
      View More
    </button>
  )}
  {showAllDeadlines && (
    <button
      onClick={handleViewLessDeadlines}
      style={{
        cursor: "pointer",
        color: "#1976D2",
        border: "none",
        background: "white",
        fontSize: "14px",
        border: "1px solid #1976D2",
        boxShadow: "2px 2px 5px -2px #000000",
        width: "280px",
      }}
    >
      View Less
    </button>
  )}
</div>

              </div>
            </Draggable>
          )}

</div>
</div>
<div
  style={{
    width:"100%",
    height:"290px",
    gridRow: windowWidth ? "1 / 2" : "2 / 3",
    order: windowWidth ? 1 : 2,
    // border:'2px solid red',
    marginTop:windowWidth? 0 :'20px'
  }}
>

{proposalProgressData?.length > 0 ? (

<div 
style={{
  // border:"4px solid purple"
}}
>
  {visibilityStates.proposalProgress?.visible && (
    <Draggable
      position={visibilityStates.proposalProgress.position}
      onStop={(e, data) => handleDragStop(e, data, "proposalProgress")}
      cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}
    >
      <div
        style={{
          maxHeight: 650,
          // height: "100%",
          border: "1px solid #ccc",
          overflowY: "auto",
          boxShadow: "2px 2px 5px -2px #000000",
          borderRadius: "10px",
          backgroundColor: "white",
          // marginLeft: "15px",
          width: "100%",
          height: "275px",
        }}
      >
        <div style={{ display: "flex", margin:"10px" }}>
          <strong style={{ fontSize: "15px", color: "#1976d2" }}>
            Proposal Progress
          </strong>
        </div>
        <div style={{ marginTop: "15px" }}>
          <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#1976d2' }}>Sr. No.</TableCell>
                  <TableCell sx={{ color: '#1976d2' }}>Name of the proposal</TableCell>
                  <TableCell sx={{ color: '#1976d2' }}>Team</TableCell>
                  <TableCell sx={{ color: '#1976d2' }}>Stage</TableCell>
                  <TableCell sx={{ color: '#1976d2' }}>Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalProgressData?.map((item, index) => (
                  <TableRow key={item.assetid}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.opportunityname}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold"
                      }}
                    >{item.teamname || '-'}</TableCell>
                    <TableCell
                      sx={{
                        color: getCellTextColor(item.stage),
                        backgroundColor: getCellBackgroundColor(item.stage),
                      }}
                    >
                      {item.stage}
                    </TableCell>
                    <TableCell>{item.DueDate ? formatDate(item.DueDate) : '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>
      </div>
    </Draggable>
  )}
</div>
) : (
<div style={{ boxShadow: "2px 2px 5px -2px #000000",
  borderRadius: "10px",
  backgroundColor: "white",
  padding: "15px",
  marginTop: "7px", marginLeft: "15px",
  width: "610px",
  height: "50px",}}>
  <strong style={{ fontSize: "15px", }}>
    No proposal progress data available.
  </strong>
</div>
)}
</div>
        </div>
        {showCompletedProjectsPopup && (
          <CompletedProjectPage onClose={handleCompletedProjectsPopupClose} statusType={statusType} />
        )}
        {showCancelledProjectsPopup && (
          <CancelledProjectsPage onClose={handleCancelledProjectsPopupClose} />
        )}
        {showActiveProjectsPopup && (
          <ActiveProjectsPage onClose={handleActiveProjectsPopupClose} statusType={statusType} />
        )}
        {showAllocatedProjectsPopup && (
          <AllocatedProjectsPage onClose={handleAllocatedProjectsPopupClose} />
        )}



        <ReportModal
          open={isReportModal}
          close={handleCloseReportModal}
          reportData={project}
          workflow={workflowData}

        />

      </div>
      {/* <div style={{ display: "flex",width:"700px",border:"5px solid red" ,top:"0"}}> */}

       
      

    </>
  );
};
export default DashboardPage;


{/* <BarChartComp
chartHeading="User Performance"
xAxisData={xAxisData}
barChartData={barChartData}
isSelectDropdownShown={true}
barChartSelectOptions={barChartSelectOptions}
selectDropdownDisabled={false}
boxWidth={610}
boxHeight={270}
sectionColors={sectionColors}
legendStyles={legendStyles}
/> */}


import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
// import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/farcauses`;
} else {
  apiEndpoint = `${herokuUrl}/farcauses`;
}
export async function getfarCauses() {
    let response = await http.get(`${apiEndpoint}/getparts`, );
    return response;
   
  }

  export async function getFarParts(data){
    console.log(data,"servicedata")
    let response=await http.post(`${apiEndpoint}/getFarParts`,{data});
    return response;
  }
  
  export async function getSectionsAndSubpartsByName(link) {
    let response = await http.post(`${apiEndpoint}/getSectionsAndSubpartsByName`,{link} );
    console.log(response, "API Response");
    return response;
   
  }
  // console.log(response)
  export default {
    getfarCauses ,
    getFarParts,
    getSectionsAndSubpartsByName
  }
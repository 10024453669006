import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/roles";
} else {
  apiEndpoint = herokuUrl + "/roles";
}


  export async function getappfeatureroles2(data) {
    let response = await httpService.post(`${apiEndpoint}/getappfeatureroles2`,data);
    return response
   
  }

 
import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}`;
} else {
    apiEndpoint = `${herokuUrl}`;
}


export async function userIsConfirmed(email, token) {
    const data = await http.get(
        `${apiEndpoint}/${email}/${token}`
    );
    return data;
}


export default {
    userIsConfirmed,
};

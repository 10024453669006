import React from 'react';
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import DcoumentMgmt from '../DocumentMgmt';

const DocumentManagementPage = () => {
  return (
    <React.Fragment>
    <SideNavBar>
       <DcoumentMgmt />
    </SideNavBar>
  </React.Fragment>
  );
}

export default NetworkDetector(DocumentManagementPage);




import React from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SubmissionHistoryModal = ({ open, handleClose, submissionHistory }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="submission-history-modal"
            aria-describedby="submission-history-table"
        >
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                width: '90vw', 
                maxWidth: '800px', 
                maxHeight: '95vh',
                overflowY: 'auto'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ margin: 0, fontSize:window.innerWidth<768?'17px':'20px', fontWeight:'bold' }}>Submission History</p>
                    <IconButton onClick={handleClose} style={{ padding: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div style={{ overflowX: 'auto', marginTop: '10px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #ddd' }}>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Sr. No.</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Proposal Name</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Submitted By</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Team Name</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Date</th>
                                <th style={{ textAlign: 'left', padding: '8px' }}>Mode Of Submission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {submissionHistory.map((item, index) => (
                                <tr key={item.lastAudit._id} style={{ borderBottom: '1px solid #ddd' }}>
                                    <td style={{ textAlign: 'left', padding: '8px' }}>{index + 1}</td>
                                    <td style={{ textAlign: 'left', padding: '8px' }}>{item.assetTitle}</td>
                                    <td style={{ textAlign: 'left', padding: '8px' }}>{item.lastAudit.userName}</td>
                                    <td style={{ textAlign: 'left', padding: '8px' }}>{item.lastAudit.fromTeam}</td>
                                    <td style={{ textAlign: 'left', padding: '8px' }}> {`${('0' + new Date(item.lastAudit.createdAt).getDate()).slice(-2)}/
                                                                                        ${('0' + (new Date(item.lastAudit.createdAt).getMonth() + 1)).slice(-2)}/
                                                                                        ${new Date(item.lastAudit.createdAt).getFullYear()}`}</td>
                                    <td style={{ textAlign: 'left', padding: '8px' }}>{item.modeOfSubmission}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    );
};

export default SubmissionHistoryModal;

import React from 'react'
import {Typography} from "@material-ui/core";
import mammoth from 'mammoth';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'

export default function DocUpload( {getDoc} ) {

    let file_name = "";

    const _handleFileUploadChange = async e => {
      e.preventDefault();
      let file = e.target.files[0];
      console.log(file)
      if(!file) return;

       if(file.size > 4194304){
        //  alert("document exceeded the limit of 4 Mb");
        Swal.fire({
          title: 'Document exceeded the limit of 4 Mb!',
          type: 'error',
          confirmButtonText: 'ok'
        })
       }
 else {
      if(file.name){
         file_name = file.name.split(".")[0]
      }
      console.log(file)
      if(!file) return
      let reader = new FileReader();

      if(file.name.includes("doc")){
        reader.readAsArrayBuffer(file);
      }
      else if(file.type == "text/plain"){
        reader.readAsBinaryString(file)
      }
      else{
        toast.info('Your file format is not supported, Please upload txt or doc files.')
      }

      reader.onload = function(event) {
        let data = event.target.result;
        if(file.name.includes("doc")){
        mammoth.extractRawText({arrayBuffer: data})
        .then(function(result){
             const text = result.value;
             getDoc(text, file_name)
        }) .done();   
      }
      else{
          const text = data;
          getDoc(text, file_name)
       }
    }
  }

      // reader.readAsDataURL(file);
      // reader.readAsBinaryString(file);
      // reader.readAsArrayBuffer(file);
      // reader.readAsText(file);
  
    }

    return (
          <div className="point mx-1"  data-toggle="tooltip" title="upload your document">
        <label for="fileup" className="m-0 point">
            <input
              type="file"
              id="fileup"
              name="fileup"
              accept=".txt, .docx"
              onChange={e => _handleFileUploadChange(e)}
              style={{ display: "none" }}
            />

          <Typography style={{color:"#009900"}}> 
              <i className="fa fa-upload m-1"
                    aria-hidden="true" style={{color:"#009900"}}
                  ></i> Upload</Typography>
   </label>

        </div>
    )
}

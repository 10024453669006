import React, { useState, useEffect } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png"
import { FaEdit } from 'react-icons/fa';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import { WorkFlowComp } from "./ReusableComponents/WorkFlowComp";
import { TransitionComp } from "./ReusableComponents/TransitionComp";

const SubAdminWorkFlow = ({ currentUser }) => {
    const [loading, setLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [workFlowShow, setWorkFlowShow] = useState(false);
    const [transitionShow, setTransitionShow] = useState(false);
    // const [workflowData, setWorkflowData] = useState([]);
    const [msg, setMsg] = useState("")
    const [userDetails, setUserDetails] = useState("")
    const [currentRow, setCurrentRow] = useState(null)


    const [workflowData, setWorkflowData] = useState([
        {
            workFlowID: 1,
            workFlowName: "Work flow 1",
            workFlowDescription: "Work flow for test",
            currentStage: "Stage 1"
        },
        {
            workFlowID: 2,
            workFlowName: "Work flow 2",
            workFlowDescription: "Work flow for test 2",
            currentStage: "Stage 2"
        }
    ]);

    useEffect(() => {
        // getAllUserRequests()
    }, []);


    const getAllUserRequests = async () => {
        let currentUserEmail = sessionStorage.getItem("currentUserEmail")
        setLoading(true)
        try {
            const response = await adminServices.getAllRequests(currentUserEmail);
            // const response = await adminServices.getAllOrganizationUsers(currentUserEmail);
            console.log("userRequests", response)
            if (response.status === 200) {
                setTeamMembers(response.data);

                let newData = response.data
                let finalArr = []
                newData.map((obj) => {
                    finalArr.push({
                        ...obj,
                        // "status": obj.isApproved == true ? "Approved" : "Pending"
                        // "typeOfRequest": "Delete Request"
                    })
                })
                console.log("finalArr", finalArr)
                setWorkflowData(finalArr);
                // setWorkflowData(response.data);
                setLoading(false);
            } else if (response.status === 404) {
                setLoading(false);
                setTeamMembers(response.data);
                setWorkflowData(response.data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
        setLoading(false)
    }

    const columns = [
        {
            field: 'workFlowID',
            headerName: 'ID',
            // type: 'text',
            flex: 0.1,

        },
        {
            field: 'workFlowName',
            headerName: 'Name',
            // flex: 0.2,
        },
        {
            field: 'workFlowDescription',
            headerName: 'Description',
            flex: 0.1,
        },
        {
            field: 'currentStage',
            headerName: 'Current Stage',
            flex: 0.1,
        },
        {
            field: '',
            headerName: 'Transition',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => handleEdit(params)}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<BorderColorRoundedIcon fontSize="small" />}
                        {/* {<FaEdit />} */}
                    </button>
                )
            }
        },
    ];

    const handleEdit = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        openTransistionPopup()
        let { workFlowID, workFlowName } = params.row
        // setUserDetails(email)
    }

    const handleApproveDelete = async (email) => {
        setLoading(true)
        try {
            let response = await adminServices.deleteOrganizationUser(email)
            console.log("handleApproveDelete", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const openWorkFlowPopup = () => {
        setWorkFlowShow(true)
    }

    const openTransistionPopup = ()=>{
        setTransitionShow(true)
    }

    const handleWorkFlowOk = ()=>{
        setWorkFlowShow(false)
    }

    const handleWorkFlowCancel = ()=>{
        setWorkFlowShow(false)
    }

    return (
        <div>
            <Loader loading={loading} />
            <ToastContainer />
            <div className="container-fluid" style={{ background: "#f0f0f7" }}>
                {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
                <div className="row justify-content-end w-100">
                    <div className="col-md-2 justify-content-end">

                        <button
                            className="btn btn-primary inviteMember"
                            style={{ width: "90%" }}
                            disabled={false}
                            onClick={openWorkFlowPopup}
                        >
                            Create Workflow
                        </button>
                    </div>
                </div>

                <div className="row mt-2 w-100">
                    {/* {workflowData.length === 0 ? ( */}
                    {workflowData.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No WorkFlow found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data.workFlowID}
                                    columns={columns}
                                    rows={workflowData}
                                    checkboxSelection={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Modal
            <Modal
                isOpen={modalShow}
                toggle={() => setModalShow(!modalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setModalShow(!modalShow)}
                    className="text-center"
                >
                    User Request
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            {msg !== "" ? msg : ""} <br />
                            <strong>{userDetails}</strong>
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-primary"
                                    onClick={handleApprove}
                                >
                                    Approve
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-secondary"
                                    onClick={showRejectModal}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}


            <WorkFlowComp
                open={workFlowShow}
                handleWorkFlowOk={handleWorkFlowOk}
                handleWorkFlowCancel={handleWorkFlowCancel}
                // toggle={() => setWorkFlowShow(!workFlowShow)}
            />

<TransitionComp
isOpen={transitionShow}
toggle={() => setTransitionShow(!transitionShow)}
/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubAdminWorkFlow));

import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/dashboard`;
} else {
  apiEndpoint = `${herokuUrl}/dashboard`;
}

export async function getDashboardDetails(ok) {
  let detail = { year: ok }
  const data = await http.post(`${apiEndpoint}/getUserDashBoardDetails`,detail);
  // console.log(data);
  return data;
}

export async function getDetailsSubAdminDash(email) {
  let data = await http.post(`${apiEndpoint}/dashboardDetails`, {
    email,
  });
  return data;
}

export async function getDetailsSubAdminBarChart(email) {
  let data = await http.post(`${apiEndpoint}/dashboardBarGraphDetails`, {
    email,
  });
  return data;
}

export async function getDetailsSubAdminPieChart(email) {
  let data = await http.post(`${apiEndpoint}/dashBoardPieChartDetails`, {
    email,
  });
  return data;
}

export async function getWidgetDetails(year) {
  const data = await http.post(`${apiEndpoint}/getWidgetDetails`, { year });
  return data;
}

export async function getMonthWiseRatings(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getMonthwiseRatingsDetail`,detail);
  console.log(data);
  return data;
}

export async function getMonthWiseTeamDetails(year) {
  let detail = { year: year }
  const data = await http.post(`${apiEndpoint}/getMonthWiseTeamDetails`,detail);
  console.log(data);
  return data;
}

export async function getRatedDocumentMonthwise(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getRatedDocumentMonthwise`,detail);
  console.log(data);
  return data;
}

export async function getUsersCount() {
  const data = await http.get(`${apiEndpoint}/getUsersCount`);
  return data;
}
export async function getOrganizationCount() {
  const data = await http.get(`${apiEndpoint}/getOrganizationCount`);
  return data;
}
export async function countryPercentageOfUser() {
  const data = await http.get(`${apiEndpoint}/countryPercentageOfUser`);
  return data;
}
export async function organizationPlanBreakdown(orgName) {
  const data = await http.post(`${apiEndpoint}/organizationPlanBreakdown`, orgName);
  return data;
}

export async function getDashboardSectionsVisibility(userEmail) {
  const data = await http.post(`${apiEndpoint}/getDashboardSectionsVisibility`, userEmail);
  return data;
}

export async function updateDashboardSectionsVisibility(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateDashboardSectionsVisibility`, data);
  return updatedData;
}

export async function getSubadminDashboardSectionsVisibility(userEmail) {
  const data = await http.post(`${apiEndpoint}/getSubadminDashboardSectionsVisibility`, userEmail);
  return data;
}

export async function updateSubadminDashboardSectionsVisibility(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateSubadminDashboardSectionsVisibility`, data);
  return updatedData;
}


export async function getSuperadminDashboardSectionsVisibility(userEmail) {
  const data = await http.post(`${apiEndpoint}/getSuperadminDashboardSectionsVisibility`, userEmail);
  return data;
}

export async function updateSuperadminDashboardSectionsVisibility(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateSuperadminDashboardSectionsVisibility`, data);
  return updatedData;
}

export async function updateDashboardSectionsPosition(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateDashboardSectionsPosition`, data);
  return updatedData;
}

export async function resetDashboardCustomization(userEmail) {
  const data = await http.post(`${apiEndpoint}/resetDashboardCustomization`, userEmail);
  return data;
}
export async function updateSubadminDashboardSectionsPosition(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateSubadminDashboardSectionsPosition`, data);
  return updatedData;
}

export async function resetSubadminDashboardCustomization(userEmail) {
  const data = await http.post(`${apiEndpoint}/resetSubadminDashboardCustomization`, userEmail);
  return data;
}

export async function resetSuperadminDashboardCustomization(userEmail) {
  const data = await http.post(`${apiEndpoint}/resetSuperadminDashboardCustomization`, userEmail);
  return data;
}
export async function updateSuperadminDashboardSectionsPosition(data) {
  const updatedData = await http.post(`${apiEndpoint}/updateSuperadminDashboardSectionsPosition`, data);
  return updatedData;
}

export async function addEventToOutlook(events) {
  // try {
  //   const data = await http.post(`${apiEndpoint}/addEventToOutlook`, events);
  //   return data; 
  // } catch (error) {
  //   console.error("Error adding events:", error);
  //   throw error;
  // }
}

export async function proposalProgressTracker(orgId) {
  const updatedData = await http.post(`${apiEndpoint}/proposalProgressTracker`, orgId);
  return updatedData;
}
export async function getTaskCompletionCount(orgId) {
  const response = await http.post(`${apiEndpoint}/getTaskCompletionCount`, {orgId});
  return response;
}
export default {
  getDashboardDetails,
  getDetailsSubAdminDash,
  getDetailsSubAdminBarChart,
  getDetailsSubAdminPieChart,
  getWidgetDetails,
  getMonthWiseRatings,
  getMonthWiseTeamDetails,
  getRatedDocumentMonthwise,
  getOrganizationCount,
  countryPercentageOfUser,
  organizationPlanBreakdown,
  getDashboardSectionsVisibility,
  updateDashboardSectionsVisibility,
  updateSubadminDashboardSectionsVisibility,
  getSubadminDashboardSectionsVisibility,
  addEventToOutlook
,
  updateSuperadminDashboardSectionsVisibility,
  getSuperadminDashboardSectionsVisibility,
  updateDashboardSectionsPosition,
  resetDashboardCustomization,
  resetSubadminDashboardCustomization,
  updateSubadminDashboardSectionsPosition,
  updateSuperadminDashboardSectionsPosition,
  resetSuperadminDashboardCustomization,
  getTaskCompletionCount
};

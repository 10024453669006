import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DefaultBlogImage from "../../static/defaultBlogThree.jpg";
import StarRatings from "react-star-ratings";
import "./BlogCard.css";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
} from "change-case";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { ShareableLink } from "../../services/blogServices";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import { imgUrl } from "../../services/config.json";
import { connect, useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 215,
    marginTop: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});

export default function BlogCard({
  data,
  showBlog,
  search,
  mypost,
  blogPub,
  blogunpub,
  shareableLink,
  isAdmin,
  dialogBox,
  permanentDelete,
  approveBlog,
}) {
  const classes = useStyles();
  const userDetails = useSelector((user) => user)
  // console.log(data);
  let content = getTextFromEditorState(data.onlyDocumentContent, true);
  let link = ShareableLink(data._id, paramCase(data.documentName));
  const mdate = new Date(data.date);
  let blogDate = new Date(mdate).toString().split(" ").splice(1, 3).join(" ");
  console.log("userDetails__",userDetails,data)
  let currentUser = userDetails.user.currentUser.username
  // let blogDate =
  //   new Date(mdate).getDate() +
  //   "/" +
  //   parseInt(new Date(mdate).getMonth() + 1) +
  //   "/" +
  //   new Date(mdate).getFullYear();

  // console.log(blogDate);

  const newCont = (data) => {
    let newContent = data + " ";
    let length = 100 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  const handleDownload = (data)=>{
    console.log("handleDownload",data)
    let docName = data.documentName
    let docContent = JSON.parse(data.onlyDocumentContent)
    let docText = docContent.blocks[0]["text"]
    let finalContent = `${docName} \n\n ${docText}`
     const blob = new Blob([finalContent], { type: 'text/plain' });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    // anchor.download = 'text_content.doc';
    anchor.download = `${docName}.doc`
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  }

  return (
    <div>
      <div className="row" style={{ padding: "2%" }}>
        {/* <div className="col-sm-2">
          {data.imageUrl && data.imageUrl.length > 0 ? (
            <img
              src={
                `${imgUrl}/` +
                data.imageUrl[0]
              }
              alt="BlogImage"
              style={{ width: "100%", height: "140px" }}
            />
          ) : (
            <img
              src={DefaultBlogImage}
              alt="BlogImage"
              style={{ width: "100%", height: "140px" }}
            />
          )}
        </div> */}
        <div className="col-sm-6">
          <h2 className="head">
            <b>{data.documentName}</b>
          </h2>
          <div
            className="row headtwo mb-1"
            style={{ marginLeft: "0%", marginTop: "2%" }}
          >
            <small>
              {" "}
              {data.editor ? data.editor.username : "PET User"} &nbsp; - &nbsp;
            </small>
            <small>{blogDate}</small>
          </div>

          <p style={{ fontSize: "15px" }}> {newCont(content)} </p>
          <br />
          <br />
          <p
            onClick={() => showBlog(data)}
            style={{ color: "#3b81f2", cursor: "pointer", marginTop: "-5%" }}
          >
            Continue Reading
          </p>
          <button
          className="btn btn-secondary btn-sm"
            onClick={() => handleDownload(data)}       
          >
            Download
          </button>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-md-5 py-2">
              {isAdmin && !mypost ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary "
                    data-toggle="tooltip"
                    title="Approve Blog"
                    onClick={() => approveBlog(data)}
                  >
                    <i
                      className="fa fa-check point "
                      style={{ color: "#449efc" }}
                      aria-hidden="true"
                    ></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-danger ml-2 "
                    onClick={() => dialogBox(true, data)}
                    data-toggle="tooltip"
                    title="Reject Blog"
                  >
                    <i
                      className="fa fa-times point "
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                  </button>
                </>
              ) : (
                ""
              )}
              {mypost === true ? (
                <>
                  {data.pendingPublish === false && data.isPublished === false && (
                    <button
                      type="button"
                      className="border ft_xs mr-2 p-1"
                      style={{
                        backgroundColor: "#3b81f2",
                        color: "#ffffff",
                        marginBottom: "4px",
                      }}
                      onClick={() => blogPub(data)}
                    >
                      Publish
                    </button>
                  )}

                  {data.pendingPublish === true && data.isPublished === false && (
                    <small style={{ color: "red" }}>
                      <b>WAITING FOR APPROVAL</b>
                    </small>
                  )}

                  {(data.isPublished === true && currentUser ==  data.editor.username) ? (
                    <>
                      <button
                        type="button"
                        className="border ft_xs mr-2 p-1"
                        style={{
                          backgroundColor: "#3b81f2",
                          color: "#ffffff",
                          marginBottom: "4px",
                        }}
                        onClick={() => blogunpub(data)}
                      >
                        Unpublish
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {/* {!isAdmin && data.isPublished === true ? (
                <i
                  className="fa fa-link mr-3 point ml-2"
                  style={{ color: "#449efc" }}
                  onClick={() => shareableLink(data)}
                  data-toggle="tooltip"
                  title="Shareable Link"
                  aria-hidden="true"
                ></i>
              ) : (

                // <FacebookShareButton
                //   url={link}
                //   hashtag={`#PET_Tool`}
                //   quote={data.documentName}
                //   className="Demo__some-network__share-button"
                // >
                //   <FacebookIcon size={32} round />
                // </FacebookShareButton>
                ""

              )} */}
              <br />
              {data.tags &&
                data.tags.length > 0 &&
                data.tags.slice(0, 3).map((tag) => (
                  <button
                    key={tag}
                    type="button"
                    className="border ft_xs mr-2 p-2 mb-1"
                    onClick={() => search(tag)}
                    style={{
                      backgroundColor: "#007bff",
                      borderRadius: "20px",
                      color: "white",
                    }}
                  >
                    {capitalCase(tag)}
                  </button>
                ))}
            </div>
            <div className="col-sm-3">
            <div>
                <StarRatings
                  rating={data.ratings}
                  starRatedColor="orange"
                  starDimension="13px"
                  borderRadius="2px"
                  starSpacing="0px"
                  numberOfStars={5}
                  name="rating"
                  style={{ paddingBottom: "2%" }}
                />
              </div>
              <div>
                {data.totalRatings} ratings
                {(mypost === true && currentUser ==  data.editor.username) ? (
                  <Tooltip title="Permanent Delete">
                    <DeleteIcon
                      className="point ml-2"
                      // data-toggle="tooltip"
                      // title="Permanent Delete"
                      style={{ fontSize: "25px", color: "#2196f3" }}
                      onClick={() => permanentDelete(data)}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div className="col-sm-4" style={{ float: "right" }}>
              {data.totalRatings} ratings
              {mypost === true ? (
                <Tooltip title="Permanent Delete">
                  <DeleteIcon
                    className="point ml-2"
                    // data-toggle="tooltip"
                    // title="Permanent Delete"
                    style={{ fontSize: "25px", color: "#2196f3" }}
                    onClick={() => permanentDelete(data)}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

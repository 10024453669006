import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/admin`;
} else {
  apiEndpoint = `${herokuUrl}/admin`;
}

export async function getUserInvoices(userId) {
  const data = await http.post(`${apiEndpoint}/listPaymentByUser`,{userId});
  //console.log(data);
  return data;
}
export async function getAllpaymentsForAdmin() {
  const data = await http.get(`${apiEndpoint}/listPayments`);
  //console.log(data);
  return data;
}
export default {
  getUserInvoices,
  getAllpaymentsForAdmin 
};

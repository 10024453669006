import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/plagiarism`;
} else {
  apiEndpoint = `${herokuUrl}/plagiarism`;
}

// export async function checkPlagiarism(data){
//     const response = await http.post(`${apiEndpoint}/plagiarismCheck`,{data});
//     return response;
// }

export async function checkPlagiarism(data){
  const response = await http.post(`${apiEndpoint}/checkPlagiarism`,data);
  return response;
}

export async function getPlagiarismDetails(data){
  const response = await http.post(`${apiEndpoint}/plagiarismDetails`,data);
  return response;
}

export async function getUniqueSentence(data){
  const response = await http.post(`${apiEndpoint}/uniqueSentence`,data);
  return response;
}

export default {
    checkPlagiarism,
    getPlagiarismDetails,
    getUniqueSentence
}
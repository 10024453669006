

import React from "react"
import "./TemplateGallery.css";
import { useState, useEffect, useRef } from "react"
import TableRowsIcon from '@mui/icons-material/TableRows';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { InputLabel, FormControl, Input, Box, CardContent, Typography, IconButton, Card, Button, Modal } from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import { CenterFocusStrong } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import JoditEditor from 'jodit-react';
import { getUserInfo } from "../services/opportunityService";
import { UploadTemplateInGallery, GetGalleryList, GetPreviewContent, deleteGalleryTemplate, UpdateFile } from "../services/TemplateGalleryServices";
import Loader from "../utils/loader";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from "axios"
import EditNoteIcon from '@mui/icons-material/EditNote';
import ShareIcon from '@mui/icons-material/Share';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from "@mui/icons-material/Add";
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { showToast } from "../messages";
import { asBlob } from 'html-docx-js-typescript';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const config = {
    readonly: true,
    toolbar: false,
    branding: false,
    statusbar: false, // Set to false to hide the status bar
    height: 500,
    uploader: {
        insertImageAsBase64URI: true, // Enable Base64 encoding
    },


}

const config2 = {

    readonly: false,
    toolbar: true,
    branding: false,
    statusbar: false, // Set to false to hide the status bar
    height: 500,
    uploader: {
        insertImageAsBase64URI: true, // Enable Base64 encoding
    }
}




export default function TemplateGallery() {
    const history = useHistory();
    const [isPopupOpen, setIsPopUpOpen] = useState(false)
    const [content, setContent] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [galleryTemplateList, setGalleryTemplateList] = useState([])

    const [file, setFile] = useState(null);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(false)
    const [editClick, setEditClick] = useState(false)
    const [editContent, setEditContent] = useState(null)
    const [categoryForSave, setCategoryForSave] = useState(null);
    const [fileForSave, setFileForSave] = useState(null);
    const [open, setOpen] = useState(false);
    const [templateId, setTemplateId] = useState(null)
    const [templateIdForSave, setTemplateIdForSave] = useState(null)
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [totalWords, setTotalWords] = useState(0)
    const [previewName, setPreviewName] = useState(null)
    const editor = useRef(null)
    const [mapCount, setMapCount] = useState(0)
    const [openDeletemodal, setOpenDeleteModal] = useState(false);
    const [fileDelete, setFileDelete]= useState(null)
    const [categoryDelete, setCategoryDelete]= useState(null)
    const [idDelete, setIdDelete]= useState(null)

    const [searchTerm, setSearchTerm] = useState(""); 
    const [filteredGalleryTemplateList, setFilteredGalleryTemplateList] = useState(galleryTemplateList); 


    useEffect(() => {
        if (searchTerm === "") {
            setFilteredGalleryTemplateList(galleryTemplateList);
        } else {
            const filteredList = galleryTemplateList.map((category) => ({
                ...category,
                files: category.files.filter((file) =>
                    file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
                )
            })).filter(category => category.files.length > 0);
            setFilteredGalleryTemplateList(filteredList);
        }
    }, [searchTerm, galleryTemplateList]);





    const handleClickOpen = (fileName, category, id) => {
        setFileDelete(fileName);
        setCategoryDelete(category);
        setIdDelete(id)
        setOpenDeleteModal(true);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };









    let maxWords = 10000;

    const handleDownload = () => {
        setShowDownloadModal(true)
    }





    const downloadSinglePDF = () => {
        let fileName = fileForSave.slice(0, -5);

        const pdfContent = `<div><h5>${fileName}</h5><p>${editor.current.value}</p></div>`;

        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: `${fileName}.pdf`, // Set the filename to the document name
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, `${fileName}.pdf`);
        });
        //onHide();
        setShowDownloadModal(false)
    };




    const handleDocxDownload = async () => {
        // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
        // const contentHTML = stateToHTML(contentState);
        let fileName = fileForSave.slice(0, -5);

        const htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>${fileName}</title>
    </head>
    <body>
        <h3>${fileName}</h3>
        <hr/>
        ${editor.current.value}
    </body>
    </html>`;

        asBlob(htmlString).then(docx => {
            saveAs(docx, `${fileName}.docx`);
            setShowDownloadModal(false) // Close the modal after the download
        });
    };












    function handlePreviewClick(filename, categary) {
        setPreviewName(filename)

        // setFileName(filename)
        // setCategory(categary)
        fetchPreviewContent(filename, categary)


        setIsPopUpOpen(true)
        // fetchPreviewContent()
    }

    function HandleClosePopUp() {
        setIsPopUpOpen(false)
        setContent("")
    }

    useEffect(() => {
        const Username = sessionStorage.getItem("currentUserEmail")

        getCuurentUser(Username)
        getGalleryTemplateList()
    }, [])

    const getCuurentUser = async (Username) => {
        try {
            const response = await getUserInfo(Username)
            console.log("userinfo for gallery", response.data.Role)
            setUserInfo(response.data.Role)

        } catch (error) {
            console.log(error)
        }
    }

    // const getTemplateIdByFileName = (fileName) => {
    //     const fileData = templateId.find((item) => item.fileName === fileName);
    //     return fileData ? fileData._id : null;
    // };


    const getGalleryTemplateList = async () => {
        try {
            const email = sessionStorage.getItem("currentUserEmail")
            const responce = await GetGalleryList(email)
            console.log("Gallery responce", responce.data)
            console.log("__idddddddd", responce.data1)




            // console.log("Gallresponce", responce.data1.map(k=>k.userid))
            // setUserIds(responce.data1.map(k=>k.userid))
            // const pankajbhai = responce.map(k => k.files).map(a => a[a.length - 1])
            // console.log(pankajbhai, "pankajbhai")
            setGalleryTemplateList(responce.data)
            console.log("___id ", responce.data1)
            setTemplateId(responce.data1.map(k => k._id))
            // setpankaj(pankajbhai)
            // setlistdata()
        }
        catch (error) {
            console.log("Error from Gallery List api", error)
        }

    }


    console.log(galleryTemplateList, "galleryTemplateListgalleryTemplateList")
    console.log("templateId  ", templateId)


    const fetchPreviewContent = async (filename, category) => {
        console.log(filename, " this ", category)
        setLoading(true)
        try {
            const response = await GetPreviewContent(category, filename);



            // const content = JSON.parse(response.textResponse)
            // console.log("content $$$",content.data.documentBody)
            // console.log("content $$$",response)
            // setContent(content.data.documentBody)
            console.log("$$$$$$   ", response)
            setContent(response.textResponse)



        } catch (error) {
            console.error('Error fetching preview content:', error.message);
        }

        setLoading(false)

    };





    const handleDeleteFile = async (fileName, category, id) => {

        setLoading(true)
        try {
            const response = await deleteGalleryTemplate(categoryDelete, fileDelete, idDelete);
            console.log('File deleted successfully:', response);
            showToast('Success.templateDeleted')
        } catch (error) {
            console.error('Error deleting file:', error);
            // Handle error (e.g., show error message to user)
        }
        handleClose()
        getGalleryTemplateList()

        setLoading(false)
    };




    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            console.log('Selected file:', selectedFile);
            setFile(selectedFile); // Store the selected File object in state
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };




    const handleUploadFile = async () => {

        setLoading(true)

        try {
            if (!file || !category) {
                console.error('File and category are required.');
                return;
            }
            const allowedFormats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            if (!allowedFormats.includes(file.type)) {
                showToast('Warning.docUpload')
                setLoading(false)
                return;

            }
            const email = sessionStorage.getItem("currentUserEmail")

            const formData = new FormData();
            formData.append('filename', file);
            formData.append('email', email);
            formData.append('category', category);



            const response = await axios.post(`https://api.contractpro360.com/api/templateGallery/uploadfromlocals`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            //   const response = await UploadTemplateInGallery(category, file);
            console.log('File uploaded successfully:', response);

            showToast('Success.fileUpload')
            setOpen(false)
            getGalleryTemplateList()
            // Handle success (e.g., show success message to user)
        } catch (error) {
            console.error('Error uploading file:', error);
            // Handle error (e.g., show error message to user)
        }

        setLoading(false)
    };

    const handleEditClick = async (filename, category, id) => {
        setTemplateIdForSave(id)
        setCategoryForSave(category)
        const decodedFileName = decodeURIComponent(filename);
        setFileForSave(decodedFileName)

        console.log(filename, " this ", category)
        setLoading(true)
        try {
            const response = await GetPreviewContent(category, filename);


            console.log(response, "responseresponse")
            setEditContent(response.textResponse);


        } catch (error) {
            console.error('Error fetching preview content:', error.message);
        }

        setEditClick(true)
        setLoading(false)

    }



    const handleUpdateFile = async () => {
        // if (totalWords > maxWords) {
        //     showToast('Proposal.wordLimit')
        //     return;
        // }

        try {
            const response = await axios.post(`https://api.contractpro360.com/api/templateGallery/updateFile`, {
                fileContent: editContent, category: categoryForSave, fileName: fileForSave, templateId: templateIdForSave
            });

            if (response.status === 200) {
                console.log("Upload response:", response.data);
                showToast('Success.fileUpdate')
                //  fetchData(); // Assuming fetchData is a function to fetch updated data
            } else {
                console.log("Error in upload");
                showToast('Error.fileUpload')
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
            showToast('Error.fileUpload')
        }

    }




    const handleEditBack = () => {
        getGalleryTemplateList()
        setEditClick(false)
    }









    const trimFileName = (fileName) => {



        if (fileName.length > 15) {
            return fileName.substring(0, 15) + '...'; // Trim file name if it's too long
        }
        return fileName;
    };



    const handleEditorChange = () => {



        if (editor.current) {


            const htmlContent = editor.current.value; // Access the HTML content
            console.log("htmlContent:", htmlContent);

            let wordCount = 0;
            let textContent = '';
            if (htmlContent === "") {
                setTotalWords(0);
                return
            }

            if (htmlContent.trim() !== '') {
                // Replace line breaks with newline characters
                const processedHtmlContent = htmlContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
                // Count words
                const tempElement = document.createElement('div');
                tempElement.innerHTML = processedHtmlContent;
                textContent = tempElement.innerText.trim(); // Use innerText instead of textContent
                wordCount = textContent.split(/\s+/).length;
            }

            setTotalWords(wordCount);

        }

    }




    return (
        <div className="Gallery-container">
            <Loader loading={loading} />


            {editClick ? (


                <div style={{ backgroundColor: "white", height: '100vh', marginRight: "10px", padding: "20px" }}>
                    {/* Buttons Row */}
                    {/* <div style={{backgroundColor:"violet", display: 'flex', marginBottom: '20px', width: "75%" }}>


                     


                    </div> */}

                    {/* White Background Div Row */}
                    <div style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.4)", borderRadius: "10px", width: '100%', height: "40px", display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: "20px" }}>
                        {/* Your content here */}
                        <div>
                            <h4>{fileForSave}</h4>
                        </div>



                        <div style={{ position: "absolute", right: "57px" }}>
                            <Button onClick={() => handleDownload()} variant="contained" color="primary" style={{ fontSize: "12px", borderRadius: "10px", color: "white", borderColor: "#1976D2", textTransform: "capitalize", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)" }}  >Download <span style={{ marginLeft: "8px" }}> <DownloadIcon /></span> </Button>



                            <Modal
                                open={showDownloadModal}
                                onHide={() => setShowDownloadModal(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%', // Adjusted width
                                        maxWidth: 400,
                                        bgcolor: 'white',
                                        borderRadius: 8,
                                        boxShadow: 24,
                                        p: 2,
                                        textAlign: 'center',
                                        overflowY: 'auto', // Enable scrolling if content exceeds box height
                                    }}
                                >
                                    <div className="modal-header">
                                        <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                                        <CloseIcon onClick={() => setShowDownloadModal(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 20 }} />
                                    </div>

                                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <span>Download as...</span>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <Button onClick={downloadSinglePDF} variant="contained" color="primary" style={{ marginRight: "30px", marginBottom: "10px" }}>
                                            PDF
                                        </Button>
                                        <Button onClick={handleDocxDownload} variant="contained" color="primary" style={{ marginBottom: "10px" }}>
                                            Word
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>

                        </div>




                    </div>

                    {/* Jodit Editor Row */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>


                        <JoditEditor
                            config={config2}
                            ref={editor}
                            value={editContent}
                            onChange={() => { handleEditorChange() }}
                            //  expand={isRightSideBar}
                            tabIndex={1}
                            onBlur={newContent => setEditContent(newContent)}

                        />


                    </div>


                    {/* Back Button Row */}

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px', width: '50%' }}>
                        <div>
                            {/* <b>10000 Words limit | Total words: {totalWords}</b> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={{ marginRight: '10px' }}
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditBack()}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdateFile()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>








                </div>




            ) : (





                <>





                    <div className="Gallery-topBar">
                        <div className="Gallery-input">

                            <input className="Gallery-inputBox"
                                type="text"
                                placeholder="Search template"
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)}

                            />


                        </div>
                        <div className="Gallery-IconDiv" >

                            {/* <FilterAltIcon className="Gallery-Icon" /> */}

                            {/* <FileUploadIcon /> */}


                            {userInfo === "SuperAdmin" ? (
                                <div className="FileUploadIcon">
                                    {/* <input type="file" onChange={handleFileChange} />
      <FormControl>
        <InputLabel htmlFor="category-input">Category</InputLabel>
        <Input
          id="category-input"
          value={category}
          onChange={handleCategoryChange}
          placeholder="Enter category"
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadFile}
        disabled={!file || !category}
      >
        Upload
      </Button> */}
                                    <FileUploadIcon className="Gallery-Icon" onClick={() => { setOpen(true); setCategory(""); setFile(null) }} />





                                    <Modal
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        aria-labelledby="upload-modal-title"
                                        aria-describedby="upload-modal-description"
                                    >
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 500,
                                            height: 250,
                                            backgroundColor: 'white',
                                            boxShadow: 24,
                                            borderRadius: 8,
                                            padding: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            {/* Close Icon */}
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => setOpen(false)}
                                                style={{ position: 'absolute', top: 10, right: 10, color: 'red' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>

                                            {/* Modal Title */}
                                            <div style={{ color: "#1976D2", height: "40px", textAlign: "start", marginBottom: "20px", width: "100%" }} id="upload-modal-title">
                                                <h2 style={{ margin: 0 }}>Upload File</h2>
                                            </div>

                                            {/* File Input */}
                                            <input type="file" onChange={handleFileChange} style={{ marginBottom: 20, width: "100%" }} />


                                            {/* Category Input */}
                                            <FormControl fullWidth style={{ marginBottom: 20 }}>
                                                <InputLabel htmlFor="category-input">Category</InputLabel>
                                                <Input
                                                    id="category-input"
                                                    value={category}
                                                    onChange={handleCategoryChange}
                                                    placeholder="Enter category"
                                                    style={{ width: "100%" }}
                                                />
                                            </FormControl>

                                            {/* Upload and Cancel Buttons */}
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "80%", gap: "10px" }}>

                                                <Button
                                                    variant="outlined"
                                                    color="error" // Set color to red
                                                    onClick={() => setOpen(false)}
                                                    style={{ flex: 1 }}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleUploadFile}
                                                    disabled={!file || !category}
                                                    style={{ flex: 1 }}
                                                >
                                                    Upload
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>


                                </div>
                            ) : (<p></p>)}





                        </div>
                    </div>






                    <div className="Gallery-templateDiv">

                        {filteredGalleryTemplateList && filteredGalleryTemplateList.length > 0 ? (
                            filteredGalleryTemplateList.map((categoryData, index) => (
                                <div className="catagoryDiv" key={index}>
                                    <h3>{categoryData.category}</h3>
                                    <div className="templateCardDiv">
                                        {categoryData.files.map((data, fileIndex) => (
                                            <Card key={fileIndex} className="templateCard">
                                                <CardContent className="cardContent">
                                                    <Typography className="templateHeading" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom variant="h5" component="div">
                                                        <h3> {trimFileName(data.fileName)}</h3>
                                                    </Typography><br></br>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        <b>Created by :</b> person 1 {/* You can replace 'person 1' with actual creator info */}
                                                    </Typography>
                                                    <div className="cardIcon" style={{ display: "flex", justifyContent: userInfo === "SuperAdmin" ? "space-between" : "center", alignItems: "center" }}>

                                                        <PreviewIcon style={{ color: "green", fontSize: "25px", marginRight: userInfo === "SuperAdmin" ? "15px" : "0px" }} onClick={() => handlePreviewClick(data.fileName, categoryData.category)} />

                                                        {userInfo === "SuperAdmin" ? (<>
                                                            <BorderColorIcon onClick={() => handleEditClick(data.fileName, categoryData.category, data._id)} style={{ color: "#1976D2", fontSize: "20px", marginRight: "15px" }} />
                                                            {/* <DeleteIcon onClick={() => handleDeleteFile(data.fileName, categoryData.category, data._id)} style={{ color: "red", fontSize: "25px" }} /> */}
                                                            <DeleteIcon
                                                                onClick={()=>handleClickOpen(data.fileName, categoryData.category, data._id)}
                                                                style={{ color: 'red', fontSize: '25px' }}
                                                            />
                                                            <Dialog open={openDeletemodal} onClose={handleClose} sx={{backgroundColor:"transparent"}}>
                                                                <DialogTitle><b>Are you sure you want to delete this file permanently?</b></DialogTitle>
                                                                <DialogContent>
                                                                    {/* Optionally add more content in the dialog body */}
                                                                </DialogContent>
                                                                <DialogActions sx={{ justifyContent: 'center' }}>
                                                                <Button onClick={() => handleDeleteFile(data.fileName, categoryData.category, data._id)} color="error" variant="outlined">
                                                                        Yes
                                                                    </Button>
                                                                    <Button onClick={handleClose} color="primary"  variant="contained">
                                                                        No
                                                                    </Button>
                                                                  
                                                                </DialogActions>
                                                            </Dialog>






                                                        </>) : (<p></p>)}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>No data available</div>
                        )}



                        {/* </div> */}
                        <div className="backButtonDiv">
                            <Button color="primary" variant="contained" onClick={() => history.goBack()} >Back</Button>
                        </div>

                    </div>




                    {isPopupOpen && (
                        <div
                            style={{
                                position: 'fixed',
                                top: "8%",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 2,
                            }}
                        >

                            <div
                                style={{
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    width: '70%',  // Set a fixed width
                                    height: '85%', // Set a fixed height
                                    overflow: 'auto', // Hide any overflow
                                    position: 'relative',
                                }}
                            >

                                <div style={{ height: "40px", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#1976D2", height: "40px", width: "90%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                        <h3>{previewName}</h3>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", width: "10%" }}>


                                        <CloseIcon onClick={HandleClosePopUp} className="close-icon" style={{ color: 'red', cursor: 'pointer' }} />


                                    </div>
                                </div>



                                <div className="p-2 mt-2">
                                    <JoditEditor
                                        value={content}
                                        //ref={editor}
                                        config={config}
                                        style={{ height: '80%', width: "90%" }}
                                    />
                                </div>


                            </div>



                        </div>
                    )}





                </>)}


        </div>
    )
}


import React, { useContext, useEffect, useRef, useState } from "react";
import Video from "../Video/Video";
import { Dialog , Box, Typography, Button} from "@mui/material"; // Import Dialog component from Material-UI
import VideoContext from "../../../contexts/VideoContext";
import './VideoChat.css';
import { toast } from "react-toastify";

const VideoChat = () => {

        const { 
        openVideoChat, 
        setOpenVideoChat, 
        callAccepted, 
        setStream,
        stream,
        myVideo,
        userVideo,
        callRejected, 
        setCallRejected,
        leaveCall1,
        otherUser,
        call,
        callEnded,
        } = useContext(VideoContext);

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    useEffect(()=>{
        if(callAccepted){
            setMaxWidth('md')
        }
        else{
            setMaxWidth('xs');
        }
    }, [callAccepted]);
  

    const cancelCall = () => {
        leaveCall1();
        setCallRejected(false);
        setOpenVideoChat(false);
        stream.getTracks().forEach((track) => {
            track.stop();
        });
    }

    useEffect(()=>{
        if (callRejected){
            toast.error('User Cannot Join the Call at the Moment');
        }
    }, [callRejected]);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: true, audio: true
        }).then((currentStream) => {
            setStream(currentStream);
            if (myVideo.current) {
                if ('srcObject' in myVideo.current) {
                    myVideo.current.srcObject = currentStream;
                } else {
                    // Fallback for older browsers
                    myVideo.current.src = URL.createObjectURL(currentStream);
                }
                // myVideo.current.play();
            } else {
                console.error("Video element not found.");
            }
        }).catch((error) => {
            console.log("Error accessing media devices:", error);
        });
    }, [callAccepted]);


    return (

        <Dialog 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openVideoChat} 
        >
            {callAccepted ? (
            <Box sx={{height: "100%", width: "100%" }} p={1}>
            <Video  />
            </Box>
            )
            : (
            <Box sx={{ display: 'flex',flexDirection:'column',alignItems: 'center', justifyContent: 'center', height: "100%", width: "100%" }} p={2}>
            <div id="SqCo">
            <p>
            {callRejected ? 'User Cannot Join the Call at the Moment'
             :
             'Waiting for the call to be accepted'
             }
            <span className="point one"> .</span>
            <span className="point two">...</span>
            <span className="point three">. </span>
            </p>
            </div>
            <Box display='flex' justifyContent='center' py={2}> 
               <Button variant="contained" color='error' onClick={cancelCall}>Cancel</Button>
            </Box>
            </Box>
            )}

        </Dialog>
    );
};

export default VideoChat;

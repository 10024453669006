import React from "react";
import FilterOpportunityinOrg from "../FilterOpportunityinOrg";
import SubAdminSideBar from "../SubAdminSideBar";

const FilterOpportunityOrgPage = () => {
  return (
    <React.Fragment>
      <SubAdminSideBar>
        <FilterOpportunityinOrg />
      </SubAdminSideBar>
    </React.Fragment>
  );
};

export default FilterOpportunityOrgPage;

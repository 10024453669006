import React, { Component } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import { toast, ToastContainer } from "react-toastify";
import "./ReviewRating.css";
import Loader from "../utils/loader";
import publicBlogService from "../services/publicBlogService";
import CommentCard from "../components/Cards/AdminCommentsCard";
import _ from "lodash";
import lastModified from "../utils/lastModfied";
import swal from "sweetalert";
import Adminservice from "../services/adminServices";
export class ReviewRating extends Component {
  state = {
    loading: true,
    dashlink: "./admindashboard",
    comments: [],
    filterComments: [],
    DocumentList: [],
    ratings: "all",
    search: "",
  };

  async componentDidMount() {
    await this.getallComments();
  }

  getallComments = async () => {
    let response = await publicBlogService.getAllPublicBlogComment();

    if (response.status === 200) {
      this.setState({
        comments: response.data,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      toast.warn("No Comments Found !");
    }
  };

  removecomment_ = async (id) => {
    this.setState({ loading: true });

    const res = await publicBlogService.deletePublicBlogComment(id);
    if (res.status === 200) {
      toast.success(res.message);
      this.getallComments();
    } else {
      toast.error("Oops something went wrong..!");
      this.setState({ loading: false });
    }
  };

  refreshContent = () => {
    this.getallComments();
  };

  approveComment = async (data) => {
    swal({
      title: "Approve Comment",
      text: "Are you sure ? You want to approve this Comment",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willApprove) => {
      if (willApprove) {
        const res = await Adminservice.acceptPublicBlogComment(data._id);
        if (res.status === 200) {
          this.getallComments();
          toast.success("Comment approved successfully.");
        } else {
          toast.error(res.message);
        }
      }
    });
  };

  rejectComment = async (data) => {
    swal({
      title: "Reject Comment",
      text: "Are you sure ? You want to reject this Comment",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willApprove) => {
      if (willApprove) {
        const res = await Adminservice.rejectPublicBlogComment(data._id);
        if (res.status === 200) {
          this.getallComments();
          toast.success("Comment rejected successfully.");
        } else {
          toast.error(res.message);
        }
      }
    });
  };

  render() {
    const { comments } = this.state;
    const today = new Date();
    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />

        <div className="row mb-3">
          <div className="col-md-4">
            <h4 className="ft_lg" style={{ color: "#3e80f1" }}>
              Manage Public Reviews
            </h4>
          </div>
        </div>

        {comments.map((com, index) => (
          <div key={index} className="d-flex justify-content-between pb-2 mt-3">
            <div className="d-flex">
              <img
                src={
                  com.userDP
                    ? com.userDP
                    : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                }
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />

              <div className="mx-3 ft_xs">
                <p className="m-0" style={{ fontWeight: "900" }}>
                  {com.userName}
                  <small className="ml-2">
                    {lastModified(today, new Date(com.date))}
                  </small>
                </p>
                <p className="ft_sm "> {com.comment} </p>
              </div>
            </div>

            <div className="d-flex text-right">
              {com.isApproved === false ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary mr-3"
                    data-toggle="tooltip"
                    title="Approve Blog"
                    onClick={() => this.approveComment(com)}
                  >
                    <i
                      className="fa fa-check point "
                      style={{ color: "#449efc" }}
                      aria-hidden="true"
                    ></i>
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-outline-danger mr-3"
                    onClick={() => this.rejectComment(com)}
                    data-toggle="tooltip"
                    title="Reject Blog"
                  >
                    <i
                      className="fa fa-times point "
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                  </button> */}
                </>
              ) : (
                <span
                  className="mt-2 mr-1"
                  style={{ color: "green", fontWeight: 800 }}
                >
                  Approved
                </span>
              )}

              <i
                onClick={() => this.removecomment_(com._id)}
                className="fa fa-trash ft_md text-primary point mr-0 mr-sm-4 mt-2"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ReviewRating;

import React, { Component, useState, useEffect } from 'react'
import { EditorState, Modifier, CompositeDecorator } from "draft-js";
import Editor, { createEditorStateWithText, composeDecorators } from "draft-js-plugins-editor";
import DraftFn from '../Editor/DraftFn';
import { RightBottomBtn, SideBtn } from "./ParaphraseComponent";
import { toast } from "react-toastify";
import services from "../../services/englishCheckers";
import _ from 'lodash';
import './draftParaphase.css'
import { Button } from '@material-ui/core';
import { v1 as uuid } from 'uuid';
import englishCheckers from '../../services/englishCheckers';
import CustomModal from './CustomModal';
import Tokenizer from 'sentence-tokenizer';






export default function Paraphrase({ rightEditorText, leftSuggestion, _colChange, onLeftHandleNavigation, setPlagarizedContent, setLoading, setCurrentParaphraseText }) {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isLocked, setIsLocked] = useState(false);
    const [currentNavId, setCurrentNavId] = useState(0);
    let [sentenceSuggestion, setSentenceSuggestion] = useState([]);
    const [hover, setHover] = useState({ x: 0, y: 0, show: false, suggestion: [] });
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [currentUpdatedText, setCurrentUpdatedText] = useState('');
    let [tempSuggestion, setTempSuggestion] = useState([]);
    const [batchSentence, setBatchSentence] = useState([]);
    const [batchLength, setBatchLength] = useState('');
    const [trigger, setTrigger] = useState(false);
    const [tempRightEditorText, setTempRightEditorText] = useState('');
    const [isAllsentenceFetched, setIsAllsentenceFetched] = useState(true);
    const [isGrammarCheck, setIsGrammarCheck] = useState(false);
    const [grammerSuggestion, setGrammarSuggestion] = useState([]);
    const decoratorInit = () => {
        const editorStateWithDec = EditorState.set(editorState, { decorator: generateDecorator() });
        setEditorState(editorStateWithDec);
    }
    const [iteration, setIteration] = useState(0);
    let [onRephraseUpdatedSentenceCount, setOnRephraseUpdatedSentenceCount] = useState(0);
    const decoratorDeInit = () => {
        const editorStateWithDec = EditorState.set(editorState, { decorator: "" });
        setEditorState(editorStateWithDec);
    }
    const [isAllSentenceLocked, setIsAllSentenceLocked] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const handleChangeText = (text) => {
        if (!text) return
        console.log('handleChangeText called', text);
        const editorState = EditorState.createEmpty();
        const newContentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text
        );
        const updatedEditor = EditorState.push(editorState, newContentState, 'insert-characters')
        setEditorState(EditorState.set(updatedEditor, { decorator: generateDecorator() }));
        setCurrentUpdatedText(text);
        setCurrentParaphraseText(text);
    }

    const getSizeHover = element => {
        const doc = document.documentElement;
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const ele = element.target;
        const rect = ele.getBoundingClientRect();
        const size = { left: rect.left, bottom: rect.bottom + top };
        return size;
    };

    const checkIsAllSentenceLocked = () => {
        if (sentenceSuggestion.length > 0) {
            let lockedSentence = sentenceSuggestion.filter(sentece => sentece.isLocked);
            let isAllSentenceLocked = lockedSentence.length === sentenceSuggestion.length ? true : false;
            setIsAllSentenceLocked(isAllSentenceLocked);
            return isAllSentenceLocked;
        }
        return false;
    }

    function changeStartAndEndPostion(sentenceIdUndergoingChange, selectedSentence) {
        let lengthOfSelectedSentence = selectedSentence.trim().length;
        let rightEditorText = ``;
        let recentEndIndex;
        let recentlyUpdatedSuggestion = sentenceSuggestion.map(sentence => {
            if (sentence.id === sentenceIdUndergoingChange) {
                sentence['updatedSentence'] = `${selectedSentence} `;
                sentence.end = sentence.start + lengthOfSelectedSentence + 1;
                recentEndIndex = sentence.end;
            } else if (sentence.id > sentenceIdUndergoingChange) {
                sentence.start = recentEndIndex;
                if (sentence.updatedSentence) {
                    sentence.end = sentence.start + sentence.updatedSentence.trim().length + 1;
                } else {
                    sentence.end = sentence.start + sentence.inputStringSentence.trim().length + 1;
                }
                recentEndIndex = sentence.end;
            }
            if (sentence.updatedSentence) {
                // console.log(`${sentence.id}`)
                rightEditorText += `${sentence.updatedSentence}`
            } else {
                rightEditorText += `${sentence.inputStringSentence}`;
            }
            return sentence;
        });
        let response = {
            sentenceSuggestion: recentlyUpdatedSuggestion,
            updatedText: rightEditorText,
        }
        return response;
    }


    const onRandomRephrase = () => {
        const response = generateRandomSentenceOnRePhrase();
        console.log('response', response);
        history.push(JSON.parse(JSON.stringify(response)));
        setHistory(history);
        setHistoryIndex(history.length - 1);
        handleChangeText(response.rightEditorText);
        sentenceSuggestion = response.sentenceSuggestion;
        setSentenceSuggestion(response.sentenceSuggestion);
    }

    const onRephrase = () => {
        console.log('onRephrase callig');
        let isAllSentenceLocked = checkIsAllSentenceLocked();
        if (isAllSentenceLocked) {
            toast(`Please unlock atleast one Sentence`);
            return;
        }
        if (sentenceSuggestion.length > 0) {
            if (batchSentence.length > 0) {
                if (iteration >= 3) {
                    console.log('onRandomRephrase callig');
                    onRandomRephrase();
                } else {
                    let times = iteration;
                    setIteration(iteration + 1);
                    setOnRephraseUpdatedSentenceCount(0);
                    setIsAllsentenceFetched(false);
                    for (let i = 0; i < batchSentence.length; i++) {
                        let id = `${uuid()}`;
                        rephraseApiCall(batchSentence[i], id, times + 1);
                    }
                }
            } else {
                console.log('No Batch Sentences');
            }
        } else {
            const { text, isTextChanged, isClear } = rightEditorText;
            paraPhase2(text);
        }
    }
    const getUpdateSentenceFromSentenceSuggestion = () => {
        let rightEditorText = ``;
        sentenceSuggestion.map(sentence => {
            rightEditorText += sentence.updatedSentence;
            return sentence;
        });
        return rightEditorText;
    }
    const rephraseApiCall = async (text, id, times) => {
        let paraphrase = await services.paraphrase(text, id, times);
        if (paraphrase && paraphrase.status === 200) {
            const test = paraphrase.suggestion.map(sentence => {
                let suggestion = sentenceSuggestion.filter(temp => temp.text === sentence.inputStringSentence);
                console.log(suggestion);
                let updatedSentenceSuggestion = _.map(sentenceSuggestion, stateItem => {
                    if (stateItem.id === suggestion[0].id) {
                        stateItem['paraphraseSentences'][iteration] = sentence.paraphraseSentences[0];
                        stateItem['iteration'] = stateItem['iteration'] + 1;
                    }
                    return stateItem;
                });
                sentenceSuggestion = updatedSentenceSuggestion;
                if (!suggestion[0].isLocked) {
                    const result = changeStartAndEndPostion(suggestion[0].id, sentence.paraphraseSentences[0]);
                    sentenceSuggestion = result.sentenceSuggestion;
                    handleChangeText(result.updatedText);
                } else {
                    let updatedText = getUpdateSentenceFromSentenceSuggestion();
                    handleChangeText(updatedText);
                }
                return { ...suggestion[0] };
            });
            onRephraseUpdatedSentenceCount = onRephraseUpdatedSentenceCount + 1;
            if (batchSentence.length === onRephraseUpdatedSentenceCount) {
                setIsAllsentenceFetched(true);
                let updatedText = getUpdateSentenceFromSentenceSuggestion();
                history.push(JSON.parse(JSON.stringify({
                    rightEditorText: updatedText,
                    sentenceSuggestion,
                })));
                setHistory(history);
            }
        }
    }
    function generateRandomSentenceOnRePhrase() {
        let updatedText = ``;
        let recentlyUpdatedSuggestion = [];
        sentenceSuggestion.map(sentence => {
            if (sentence.isLocked) {
                if (sentence.updatedSentence) {
                    updatedText += `${sentence.updatedSentence}`
                } else {
                    updatedText += `${sentence.inputStringSentence}`
                }
            } else {
                let randomParaPhrasedSentence = _.sample(sentence.paraphraseSentences);
                while (sentence.updatedSentence === randomParaPhrasedSentence) {
                    randomParaPhrasedSentence = _.sample(sentence.paraphraseSentences);
                }
                let updatedSuggestion = changeStartAndEndPostion(sentence.id, randomParaPhrasedSentence);
                recentlyUpdatedSuggestion = updatedSuggestion.sentenceSuggestion;
                updatedText += `${randomParaPhrasedSentence} `;
            }
        })
        let response = {
            rightEditorText: updatedText,
            sentenceSuggestion: recentlyUpdatedSuggestion,
        }
        return response;
    }

    useEffect(() => {
        decoratorInit();
    }, [grammerSuggestion]);

    useEffect(() => {
        if (sentenceSuggestion.length > 0) {
            checkForGrammerMistakes("", true);
            if (!isGrammarCheck) {
                console.log('tokenizeSentenceAndUpdateTheSentenceSuggestion is calling===>');
                tokenizeSentenceAndUpdateTheSentenceSuggestion();
            }
        }
    }, [isGrammarCheck])
    const tokenizeSentenceAndUpdateTheSentenceSuggestion = () => {
        const tokenizer = new Tokenizer('Chuck');
        const current_content = editorState.getCurrentContent();
        const text = current_content.getPlainText();
        tokenizer.setEntry(text);
        const sentences = tokenizer.getSentences()
        let sentenceSuggestion_local = sentenceSuggestion;
        if (sentences.length === sentenceSuggestion.length) {
            sentences.map((sentence, index) => {
                let old_updatedSentence = sentenceSuggestion_local[index].updatedSentence;
                console.log("obj.paraphraseSentences", sentenceSuggestion_local[index].paraphraseSentences);

                console.log('old_updatedSentence===>', old_updatedSentence);
                console.log('old_updatedSentence===>', old_updatedSentence.length);
                console.log('sentence===>', sentence);
                if (old_updatedSentence !== sentence) {
                    let { sentenceSuggestion: newSentenceSuggestion } = changeStartAndEndPostion(sentenceSuggestion_local[index].id, sentences[index]);
                    let i = _.findIndex(sentenceSuggestion_local[index].paraphraseSentences, (e) => {
                        return e == old_updatedSentence.trim();
                    }, 0);
                    sentenceSuggestion = _.map(newSentenceSuggestion, stateItem => {
                        if (stateItem.id === sentenceSuggestion_local[index].id) {
                            stateItem['paraphraseSentences'][i] = sentence;
                        }
                        return stateItem;
                    })
                }
                return sentence;
            })
        }
        return sentenceSuggestion;
    }
    const correctTheMistakes = (suggestedWord, obj) => {
        console.log('init correctTheMistakes');
        let sentenceToChange = sentenceSuggestion.filter(text => text.id === obj.id);
        let text = sentenceToChange[0].updatedSentence;
        let trimmedSentenceBeforeTheWrongWord = `${sentenceToChange[0].updatedSentence.slice(0, obj.id_level_offset)}`;
        // let suggestedWord = `${trimmedSentenceBeforeTheWrongWord}${suggestedWord}`;
        let lastSentenceAfterTheWrongWord = `${text.slice(obj.id_level_length, text.length)}`;
        let newSentence = `${trimmedSentenceBeforeTheWrongWord}${suggestedWord}${lastSentenceAfterTheWrongWord}`;
        console.log("new Sentences===>", newSentence)
        console.log("new Sentences length===>", newSentence.length);
        // let newSentence = `${sentenceToChange[0].updatedSentence.slice(0, obj.id_level_offset)}${suggestedWord}`;
        const result = changeStartAndEndPostion(obj.id, newSentence.trim());
        let updatedSentenceSuggestion = _.map(result.sentenceSuggestion, stateItem => {
            if (stateItem.id === obj.id) {
                console.log("paraphraseSentences===>", stateItem['paraphraseSentences']);
                console.log("text===>", text);
                let i = _.findIndex(stateItem['paraphraseSentences'], (e) => {
                    return e.trim() == text.trim();
                }, 0);
                console.log('index==>', i);
                stateItem['paraphraseSentences'][i] = newSentence;
                // stateItem['paraphraseSentences'][3] = newSentence;
            }
            return stateItem;
        });
        handleChangeText(result.updatedText);
        setSentenceSuggestion(updatedSentenceSuggestion);
        // decoratorDeInit();
        checkForGrammerMistakes(result.sentenceSuggestion, false);
        let grammarSuggestion = grammerSuggestion.filter(text => text.actual !== obj.actual);
        setGrammarSuggestion(grammarSuggestion);
        setHover({ ...hover, show: false });
    }

    const updateTextSuggestion = (suggestedText, Id) => {
        console.log('init updateTextSuggestion');
        console.log(suggestedText, Id)
        const result = changeStartAndEndPostion(Id, suggestedText);
        console.log('result', result)
        handleChangeText(result.updatedText);
        setSentenceSuggestion(result.sentenceSuggestion);
        history.push(JSON.parse(JSON.stringify({
            rightEditorText: result.updatedText,
            sentenceSuggestion: result.sentenceSuggestion
        })));
        setHistory(history);
        setHover({ ...hover, show: false });
    }
    const spanClickForGrammarMistake = (e, resObj) => {
        const size = getSizeHover(e);
        // console.log('size', size, resObj);
        const suggestion_ = resObj.suggestion;
        console.log(suggestion_);
        const suggestion = suggestion_.map((suggest, index) => {
            return <p className='cursor-pointer' key={`${suggest}${index}`} onClick={() => correctTheMistakes(suggest, resObj)}>{suggest}</p>
        })
        setHover({ x: 100, y: size.bottom - 273, show: true, suggestion })
    }
    const spanClick = (e, resObj) => {
        const size = getSizeHover(e);
        console.log('size', size, resObj);
        const suggestion_ = resObj.paraphraseSentences;
        console.log(suggestion_);
        const suggestion = suggestion_.map((suggest) => {
            return <p onClick={() => updateTextSuggestion(suggest, resObj.id)}>{suggest}</p>
        })
        setHover({ x: 100, y: size.bottom - 273, show: true, suggestion })
    }

    useEffect(() => {
        decoratorInit();
    }, [isGrammarCheck])


    const handleSpan = props => {
        if (isEditMode) {
            return null;
        }
        // console.log("handleSpan==>", props);
        const { start, end } = props;
        let className = ''
        let resObj;
        if (isGrammarCheck) {
            grammerSuggestion.forEach((obj) => {
                if (start === obj.start && end === obj.end) {
                    resObj = obj;
                }
            })
            return (<span onClick={(e) => spanClickForGrammarMistake(e, resObj)} {...props} className={`${className} span-bar-words`}>{props.children}</span>);
        }
        sentenceSuggestion.forEach((obj) => {
            // console.log(start, end, 'obj', obj.start, obj.end)
            if (start === obj.start && end === obj.end) {

                if (obj.isLocked && obj.id === currentNavId) {
                    className = 'select-highlight'
                    resObj = obj;
                }
                else if (obj.isLocked) {
                    className = 'text-secondary'
                    resObj = obj;
                }
                else if (obj.id === currentNavId) {
                    className = 'select-highlight'
                    resObj = obj;
                }
                else {
                    resObj = obj;
                }
            }
            // suggestion = obj.paraPhaseSentence;
        });
        // return (<span  {...props} className={`${className} span-bar`}>{props.children}</span>);
        return (<span onClick={(e) => spanClick(e, resObj)} {...props} className={`${className} span-bar`}>{props.children}</span>);
    };

    const handleStrategy = (contentBlock, callback, contentState) => {
        if (isEditMode) {
            return;
        }
        if (!isGrammarCheck) {
            sentenceSuggestion.forEach((obj) => {
                const id = obj.id;
                callback(obj.start, obj.end)
            });
        } else {
            grammerSuggestion.forEach((obj) => {
                const id = obj.id;
                callback(obj.start, obj.end)
            });
        }
    }

    const generateDecorator = () => {
        console.info('generateDecorator_called');
        return new CompositeDecorator([
            {
                strategy: handleStrategy,
                component: handleSpan
            }
        ]);
    };

    useEffect(() => {
        console.log('currentNavId', currentNavId);
        const getObj = sentenceSuggestion.find((obj) => obj.id === currentNavId);
        const isLocked = getObj && getObj.isLocked;
        if (isLocked)
            setIsLocked(true)
        else
            setIsLocked(false)
        decoratorInit();
    }, [currentNavId])

    // useEffect(() => {
    //     decoratorInit();
    //     return () => {
    //         console.log('clean up')
    //     }
    // }, [sentenceSuggestion])
    useEffect(() => {
        if (sentenceSuggestion.length === tempSuggestion.length) {
            let test = _.orderBy(sentenceSuggestion, ['id'], ['asc']);
            console.log("after batch call done==>", test);
        }
        return () => {
            console.log('clean up')
        }
    }, [sentenceSuggestion])

    const onHandleNavigation = (status) => {
        if (!isAllsentenceFetched) {
            return;
        }
        if (isGrammarCheck) {
            setIsGrammarCheck(false);
        }

        if (isEditMode) {
            setIsEditMode(false);
        }
        // down, up
        const length = sentenceSuggestion.length;
        console.log(length, currentNavId)
        let currentId = currentNavId;
        console.log('status', status);
        if (status === 'up')
            currentId++;
        else
            currentId--;

        if (length < currentId)
            currentId = 1;
        else if (currentId <= 0)
            currentId = length;

        onLeftHandleNavigation(currentId, rightEditorText.text)
        setCurrentNavId(currentId);
        setHover({ ...hover, show: false })
    }

    const paraPhase2 = async (text) => {
        let isAllSentenceLocked = checkIsAllSentenceLocked();
        if (isAllSentenceLocked) {
            toast(`Please unlock atleast one Sentence`);
            return;
        }
        setLoading(true);
        setIsGrammarCheck(false);
        decoratorDeInit();
        onLeftHandleNavigation(0, rightEditorText.text)
        setIsAllsentenceFetched(false);
        setTempSuggestion([]);
        setSentenceSuggestion([]);
        const result = await services.getSentences(text);
        if (result.status === 200) {
            let tokenized_sentences = [...result.sentences];
            setTempSuggestion(tokenized_sentences);
        } else {
            toast.error("Please try again Later");
        }
        console.log('paraPhaseResult', result);
        setLoading(false);
    }

    useEffect(() => {
        if (tempSuggestion.length > 0) {
            console.log("tempSuggestion", tempSuggestion);
            let tempRightEditorText = ``;
            tempSuggestion.map(text => {
                tempRightEditorText += text.text;
            });
            let batch = _.chunk(tempSuggestion, 4);
            let batchSentence = batch.map(sentence => {
                let string = [];
                sentence.map(inputSentence => {
                    string.push(inputSentence.text);
                });
                return string;
            });
            console.log("batchSentence===>", batchSentence);
            setBatchLength(batchSentence.length);
            setBatchSentence([...batchSentence]);
            handleChangeText(tempRightEditorText);
            setTempRightEditorText(tempRightEditorText);
        }
    }, [tempSuggestion]);
    const checkForGrammerMistakes = async (data, withLoader) => {
        if (!isGrammarCheck) {
            return;
        }
        if (!data) {
            data = sentenceSuggestion;
        }
        setLoading(withLoader);
        const response = await englishCheckers.checkGrammerCheckForParaphrase(data);
        console.log("response", response);
        if (response.length > 0) {
            setGrammarSuggestion(response);
        } else {
            setGrammarSuggestion([]);
        }
        setLoading(false);
    }
    useEffect(() => {
        if (batchSentence.length > 0) {
            let test = [];
            let times = iteration;
            setIteration(iteration + 1);
            for (let i = 0; i < batchSentence.length; i++) {
                let id = `${uuid()}`;
                console.log(batchSentence[i], id, times + 1);
                ApiCall(batchSentence[i], id, times + 1);
            }
            async function ApiCall(text, id, times) {
                let paraphrase = await services.paraphrase(text, id, times);
                if (paraphrase && paraphrase.status === 200) {
                    setLoading(false);
                    console.log("output sentence===>", paraphrase.suggestion);
                    paraphrase.suggestion.map(sentence => {
                        console.log('input String===>', sentence.inputStringSentence);
                        let suggestion = tempSuggestion.filter(temp => temp.text === sentence.inputStringSentence);
                        console.log(suggestion);
                        suggestion[0]['paraphraseSentences'] = sentence.paraphraseSentences;
                        console.log(suggestion[0]);
                        sentenceSuggestion.push(JSON.parse(JSON.stringify(suggestion[0])));
                        return { ...suggestion[0] };
                    });
                    setSentenceSuggestion(sentenceSuggestion);
                    let temp = _.orderBy(sentenceSuggestion, ['id'], ['asc']);
                    setTrigger(temp);
                    if (sentenceSuggestion.length === tempSuggestion.length) {
                        let recentEndIndex = 0;
                        let updatedText = ``;
                        let updatedSuggestion = []
                        let test = _.orderBy(sentenceSuggestion, ['id'], ['asc']);
                        console.log("all text updated", test);
                        updatedSuggestion = test.map((sentence, index) => {
                            updatedText += `${sentence.paraphraseSentences[0]} `;
                            sentence['updatedSentence'] = sentence.paraphraseSentences[0].trim();
                            sentence['updatedSentence'] = `${sentence['updatedSentence']} `;
                            sentence['start'] = recentEndIndex > 0 ? recentEndIndex : 0;
                            sentence['end'] = sentence.start + sentence.updatedSentence.trim().length + 1;
                            recentEndIndex = sentence['end'];
                            return sentence;
                        });
                        console.log('updatedSuggestion===>', updatedSuggestion);
                        leftSuggestion(JSON.parse(JSON.stringify(tempSuggestion)));
                        handleChangeText(updatedText);
                        setSentenceSuggestion(updatedSuggestion);
                        history.push(JSON.parse(JSON.stringify({
                            rightEditorText: updatedText,
                            sentenceSuggestion: updatedSuggestion
                        })));
                        setHistory(history);
                        setLoading(false);
                        setIsAllsentenceFetched(true);

                        // checkForGrammerMistakes(updatedSuggestion);
                    }
                }

            }
        }
    }, [batchSentence])

    useEffect(() => {
        if (sentenceSuggestion.length > 0) {
            console.log("rightEditorText", rightEditorText.text);
            let temp = _.orderBy(sentenceSuggestion, ['id'], ['asc']);
            console.log('temp===>', sentenceSuggestion);
            console.log('trigger called');
            let updatedText = ``;
            temp.map(sentence => {
                updatedText += `${sentence.paraphraseSentences[0]}\n`;
                // updatedText.replace(`${sentence.text}`, `${sentence.paraphraseSentences[0]}`);
            });
            console.log(updatedText);
            handleChangeText(updatedText);
            // setTempRightEditorText(updatedText);
            // setLoading(true);
        }
    }, [trigger]);

    useEffect(() => {
        const { text, isTextChanged, isClear } = rightEditorText;
        console.log('rightEditorText', rightEditorText);
        if (!text) {
            return;
        }
        if (isClear) {
            setEditorState(EditorState.createEmpty());
            setSentenceSuggestion([]);
            setHistory([]);
            setIteration(0);
            setCurrentNavId(0)
        }
        else
            if (text && isTextChanged) {
                // if (text) {
                paraPhase2(text);
            }
            else {
                console.log("second time");
                setIsGrammarCheck(false);
                setIsEditMode(false);
                onRephrase();
            }
        return () => {
            console.log('component willUnmounted')
        }
    }, [rightEditorText]);


    const onHandleLock = () => {
        console.log('init onHandleLock');
        const status = !isLocked;
        const lockedObj = sentenceSuggestion.map((obj) => {
            if (obj.id === currentNavId)
                obj.isLocked = status
            return obj
        });
        setSentenceSuggestion(lockedObj);
        setIsLocked(status);
    }

    const showHistory = (status) => { // inc, dec
        // console.log('history', history);
        // return;
        console.log('status', status);
        let [index, data] = [0, {}];
        if (status === 'inc') {
            index = historyIndex - 1;
            if (index < 0)
                return
            data = history[index];
        }
        else {
            index = historyIndex + 1;
            if (index > history.length - 1) {
                return
            }
            data = history[index]
        }
        console.log('data', data);
        console.log('historyIndex', index);
        setSentenceSuggestion(data.sentenceSuggestion);
        handleChangeText(data.rightEditorText);
        setHistoryIndex(index);
    }

    const copy = (text) => {
        // console.log('editorRight', editorRight);
        if (!text) {
            toast.warn(`You don't have text, to copy`);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                if (text) toast.info("Your text Copied!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }

    const onHandleCopy = () => {
        const editorRight = document.getElementById("editorRight").innerText;
        copy(editorRight)
    }

    const onSelectedTextCopy = () => {
        console.log('init onSelectedTextCopy');
        const data = sentenceSuggestion.find((obj) => obj.id === currentNavId);
        console.log('data', data)
        if (!data.id) return
        data.updatedSentence ? copy(data.updatedSentence) : copy(data.inputStringSentence)
    }

    const colChange = () => {
        _colChange()
    }

    const assignplagarizedContent = (htmlContent) => {
        setPlagarizedContent(htmlContent);
    }

    const unLockAllSentenceAndRephrase = () => {
        if (sentenceSuggestion.length > 0) {
            let updatedSentence = sentenceSuggestion.map((obj) => {
                obj.isLocked = false;
                return obj;
            });
            sentenceSuggestion = updatedSentence;
            onRephrase();
        } else {
            const { text } = rightEditorText;
            paraPhase2(text);
        }
    }
    const _handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent().getPlainText('');
        console.log(currentContent);
    }
    const onRightEditorChange = (event) => {
        setEditorState(event);
    }
    const onBlurFunction = () => {
        const sentenceSuggestion_local = tokenizeSentenceAndUpdateTheSentenceSuggestion();
        checkForGrammerMistakes(sentenceSuggestion_local, false);
    }
    useEffect(() => {
        decoratorInit();
    }, [isEditMode])

    const handleChangeEditMode = () => {
        toast.info(`Editing in the Right Side is ${isEditMode ? "disabled" : "enabled"} `);
        if (isGrammarCheck) {
            setIsGrammarCheck(false);
        }
        setIsEditMode(!isEditMode)
    }
    return (
        <div>
            {/* <button onClick={this.handleChangeText}></button> */}
            <SideBtn
                // onHandleLike={onHandleLike}
                onSelectedTextCopy={onSelectedTextCopy}
                isLocked={isLocked}
                // isLike={isLike}
                onHandleLock={onHandleLock}
                showHistory={showHistory}
                isEditMode={isEditMode}
                changeEditMode={handleChangeEditMode}

            />
            <div className={`editor ft_md reactour-3 ${isAllsentenceFetched ? "" : "disabledEffect"}`} id="editorRight" style={{ paddingRight: "8%", minHeight: "70vh", maxHeight: "70vh", overflowY: "auto" }}>
                <Editor
                    onChange={(e) => onRightEditorChange(e)}
                    readOnly={!isEditMode}
                    editorState={editorState}
                    autoCapitalize="sentences"
                    stripPastedStyles={true}
                    handleBeforeInput={_handleBeforeInput}
                    onBlur={onBlurFunction}
                />
            </div>
            <CustomModal
                isOpen={isAllSentenceLocked}
                unLockAllSentenceAndRephrase={unLockAllSentenceAndRephrase}
            />
            <div className="reactour-4">
                <RightBottomBtn
                    // sentanceNav={sentanceNav}
                    onHandleNavigation={onHandleNavigation}
                    onHandleCopy={onHandleCopy}
                    colChange={_colChange}
                    plagarizedContent={assignplagarizedContent}
                    setLoading={(value) => setLoading(value)}
                    plagCheckContent={currentUpdatedText}
                    grammerCheck={() => setIsGrammarCheck(!isGrammarCheck)}
                    isGrammerCheck={isGrammarCheck}
                />
            </div>
            <div>
                {/*    need to add the cursor tool tip hand icon concept customize the suggestion showing concept */}
                {hover.show && (
                    <div
                        className={`border  rounded shadow ${isGrammarCheck ? "suggestion-words" : "suggestion"}`}
                        // className={`border  rounded shadow suggestion `}
                        style={{ left: hover.x, top: hover.y }}
                    >
                        {!isGrammarCheck && <div className="text-left s-text px-2 bg-white py-2 rounded-bottom">
                            {hover.suggestion}
                        </div>}
                        {isGrammarCheck && <div className="px-2 bg-white py-2 rounded-bottom">
                            {hover.suggestion}
                        </div>}
                        <div className='bg-white text-right px-2 pb-2'>
                            <Button onClick={() => setHover({ ...hover, show: false })}>Cancel</Button>
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}
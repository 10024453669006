import React, { useState } from 'react';
import { Button, Modal, TextField, TextareaAutosize, IconButton } from '@mui/material';
import InputBoxMui from './InputBoxMui';
import CloseIcon from '@mui/icons-material/Close';
 
import { postfeaturelist } from '../../services/features';
 
 
const ModalFeatureUpdate = ({ onSave, onClose }) => {
    const [nameValue, setNameValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [frontendurlValue, setFrontendurlValue] = useState('');
    const [apiurlValue, setApiurlValue] = useState('');
    const [moduleValue, setModuleValue] = useState('');
    const [submoduleValue, setSubmoduleValue] = useState('');
    const [open, setOpen] = useState(false);
 
    const handleOpen = () => {
        setOpen(true);
    };
 
    const handleClose = () => {
        setOpen(false);
    };
 
    const handleSave =  async () =>  {
        const formData = {
            name: nameValue,
            description: descriptionValue,
            frontendurl: frontendurlValue,
            apiurl: apiurlValue,
            module: moduleValue,
            submodule: submoduleValue,
           
        };
 
        // console.log("formdata",formData)
       try{
        const response = await postfeaturelist(formData)
        if(response){
            console.log("test",response)
 
        }
       }catch(error){
        console.log("error in getting respone ",error)
       }
       
        onSave(formData);
        handleClose(); // Close the modal after saving
    };
 
 
    return (
        <div style={{ backgroundColor: '#f0f0f0', opacity: 2 }}>
            {/* <button
                className="btn btn-primary inviteMember"
                style={{ width: "90%" }}
                onClick={handleOpen}
            // disabled={true}
            >
                Add features
            </button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'white', // Background color
                    borderRadius: '8px', // Border radius
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    padding: '20px', // Padding
                    background: 'white'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        <h2>Add features</h2>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Name"
                        value={nameValue}
                        onChange={(e) => setNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem' , backgroundColor:'#fff'}} // Add some margin
                    /> */}
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="name"
                            label="name"
                            value={nameValue}
                            onChange={(e) => setNameValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="description"
                            value={descriptionValue}
                            onChange={(e) => setDescriptionValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    {/* <TextareaAutosize
                        aria-label="minimum height"
                        value={descriptionValue}
                        onChange={(e) => setDescriptionValue(e.target.value)}
                        minRows={3}
                        placeholder="Description"
                        style={{ width: '100%', marginBottom: '0.2rem' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Frontend URL"
                            value={frontendurlValue}
                            onChange={(e) => setFrontendurlValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Frontend URL"
                        value={frontendurlValue}
                        onChange={(e) => setFrontendurlValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="API URL"
                            value={apiurlValue}
                            onChange={(e) => setApiurlValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Api URL"
                        value={apiurlValue}
                        onChange={(e) => setApiurlValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Module"
                            value={moduleValue}
                            onChange={(e) => setModuleValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    {/* <TextField
                        id="outlined-basic"
                        label="Module"
                        value={moduleValue}
                        onChange={(e) => setModuleValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Submodule"
                            value={submoduleValue}
                            onChange={(e) => setSubmoduleValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Submodule"
                        value={submoduleValue}
                        onChange={(e) => setSubmoduleValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <Button variant="contained" onClick={handleSave} color="primary">Save</Button>
                </div>
            </Modal>
        </div>
    );
};
 
export default ModalFeatureUpdate
// import * as React from "react";
// import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// // import { createTheme } from '@material-ui/core';
// import { createTheme, ThemeProvider } from "@mui/material/styles";

// const theme = createTheme({
//   components: {
//     MuiSelect: {
//       styleOverrides: {
//         select: {
//           color: "#646464",
//           borderStyle: "none",
//           boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
//           transition: "box-shadow 0.3s",
//           // height: "30px",
//           paddingBottom: "15px",
//           paddingTop: "15px",
//           paddingLeft: "15px",
//           paddingRight: "15px",
//           // marginBottom:"15px",
//           // marginTop: "10px",
//           // marginLeft:"15px",
//           // marginRight:"15px",
//           // padding: "7px",
//           padding: "7px 13px",
//           fontFamily: "Montserrat, sans-serif",
//           fontWeight: "900",
//           fontSize: "14px",
//           "&:focus": {
//             outline: "none",
//             boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
//             // borderColor: "red"
//           },
//         },
//       },
//     },
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           fontFamily: "Montserrat, sans-serif",
//           fontWeight: "500",
//           fontSize: "14px",
//           // borderRadius: "4px", // Add border radius for dropdown options
//           // border: "1px solid #ccc", // Add border color for dropdown options
//           // boxShadow: "none", // Remove box shadow from dropdown options
//           "&:hover": {
//             backgroundColor: "#92C7CF", // Change the background color on hover
//           },
//         },
//       },
//     },
//     MuiInputLabel: {
//       styleOverrides: {
//         root: {
//           color: "black",
//           fontFamily: "Montserrat, sans-serif",
//           fontWeight: "500",
//           fontSize: "0.9rem",
//           // fontSize: "14px",
//           lineHeight: "1.4375em",
//           letterSpacing: "0.00938em",
//           padding: 0,
//           position: "relative",
//           display: "block",
//           transformOrigin: "top left",
//           whiteSpace: "nowrap",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//           maxWidth: "calc(100% - 24px)",
//           position: "absolute",
//           left: 0,
//           top: 0,
//           zIndex: 1,
//           pointerEvents: "none",
//           "&.Mui-focused": {
//             color: "#6E6E6E",
//             position: "absolute",
//             left: 0,
//             top: 0,
//           },
//         },
//       },
//     },
//   },
// });

// function SelectDropdownMui({
//   selectLabel,
//   id,
//   name,
//   value,
//   onChange,
//   optionsList,
//   margin,
//   disabled,
//   height,
//   labelKey,
//   type,
// }) {
//   console.log("optionslist", optionsList);

//   return (
//     <ThemeProvider theme={theme}>
//       <Box sx={{ m: 1, background: "white", margin: { margin } }}>
//         <FormControl sx={{ m: 1, margin: "unset" }} fullWidth size="small">
//           <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
//           <Select
//             labelId="demo-select-small-label"
//             name={name}
//             id={id}
//             value={value}
//             label={selectLabel}
//             options={optionsList}
//             getOptionLabel={(option) => option[labelKey]}
//             onChange={(e) => onChange(e)}
//             disabled={disabled}
//           >
//             {optionsList.length > 0 &&
//               optionsList.map((obj) => {
//                 let { value, name } = obj;
//                 return <MenuItem value={value}>{name}</MenuItem>;
//               })}
//             {
//               type === "noticeType" &&
//               optionsList.map((option) => (
//                 <MenuItem key={option.code}>
//                   {option.value}
//                 </MenuItem>
//               ))}
//             {
//               type === "country" &&
//               optionsList.map((option) => (
                
//                 <MenuItem key={option.countrycode}>
//                   {option.country}
//                 </MenuItem>
//               ))}
//             {
//               type === "state" &&
//               optionsList.map((option) => (
                
//                 <MenuItem key={option.stateCode}>
//                   {option.state}
//                 </MenuItem>
//               ))}
//           </Select>
//         </FormControl>
//       </Box>
//     </ThemeProvider>
//   );
// }

// export default SelectDropdownMui;



import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#646464",
          borderStyle: "none",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s",
          padding: "7px 13px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "900",
          fontSize: "14px",
          "&:focus": {
            outline: "none",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "500",
          fontSize: "14px",
          "&:hover": {
            backgroundColor: "#92C7CF",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "500",
          fontSize: "0.9rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
          padding: 0,
          position: "relative",
          display: "block",
          transformOrigin: "top left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(100% - 24px)",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 1,
          pointerEvents: "none",
          "&.Mui-focused": {
            color: "#6E6E6E",
            position: "absolute",
            left: 0,
            top: 0,
          },
        },
      },
    },
  },
});

function SelectDropdownMui({
  selectLabel,
  id,
  name,
  value,
  onChange,
  optionsList,
  margin,
  disabled,
  height,
  width,
  labelKey,
  type,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ m: 1, background: "white", margin: { margin }, width: { width } }}>
        <FormControl sx={{ m: 1, margin: "unset" }} fullWidth size="small">
          <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
          <Select
            labelId="demo-select-small-label"
            name={name}
            id={id}
            value={value}
            label={selectLabel}
            options={optionsList}
            getOptionLabel={(option) => option[labelKey]}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            sx={{ height: height ? height : "40px" }} // Set height based on the prop, default to 40px if not provided
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null, // Disable offset calculation based on the input
              PaperProps: {
                style: {
                  maxHeight: "350px", // Adjust maxHeight as needed
                },
              },
            }}
          >
            {optionsList.length > 0 &&
              optionsList.map((obj) => {
                let { value, name } = obj;
                return <MenuItem value={value}>{name}</MenuItem>;
              })}
            {
              type === "noticeType" &&
              optionsList.map((option) => (
                <MenuItem key={option.code}>
                  {option.value}
                </MenuItem>
              ))}
            {
              type === "country" &&
              optionsList.map((option) => (
                
                <MenuItem key={option.countrycode}>
                  {option.country}
                </MenuItem>
              ))}
            {
              type === "state" &&
              optionsList.map((option) => (
                
                <MenuItem key={option.stateCode}>
                  {option.state}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default SelectDropdownMui;



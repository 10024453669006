import React, { useEffect, useState } from "react";
import "./ChatNav.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VideocamIcon from "@material-ui/icons/Videocam";
import CallIcon from "@material-ui/icons/Call";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import { Avatar, Stack, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MoreVert } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChatServices from "../../../services/ChatServices";
// import LetterAvatars from "../../Avatar";
import LetterAvatars from "../Contacts/Avatar";
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';
import { message } from "antd";
import VideoContext from "../../../contexts/VideoContext";
import CallModal from "../options/CallModal";
import Teams from "../assests/teams.mp3";
import LetterAvatarsecond from "../../AvatarSecond";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import { toast } from "react-toastify";
import { showToast } from "../../../messages";

const ChatNav = (props) => {

  const { selectedChat, setSelectedContactChat, selectedGroupId,DeleteGroupId , setDeleteGroupId, userId, selectedContactId, showFilePage, setShowFilePage, colorIndex , selectedInboxId, setSelectedInboxId, fetchUsersChat } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [Title, setTitle]= useState('');
  const [Content, setContent] = useState('');
  const [from, setFrom] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [Status, setStatus] = useState(false);

  const {
    call,
    callAccepted,
    name,
    setName,
    leaveCall,
    callUser,
    answerCall,
    callEnded,
    me,
    setOtherUser,
    leaveCall1,
    isModalVisible,
    setIsModalVisible,
    audio,
    idToCall,
    setOpenVideoChat,
    setIdToCall,
    setCallRejected
  } = useContext(VideoContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearSelectedChat = () => {
    setSelectedInboxId(null);
    setDeleteGroupId(null);
  };


  const handleArchive = async () => {
    try {
      if (selectedChat) {
        const response = await ChatServices.archiveChat(selectedChat?.userId, Status, userId);
        if (response.status === 200) {
          await fetchUsersChat();
          toast.success(Status ? showToast('chat.chatArchive') : showToast('chat.chatUnarchive'));
          setOpenConfirmationDialog(false);
        } else {
          console.error("Error Opening Chat:", response.message);
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
      toast.error(error.message);
    }
  }

  const handleArchiveGroup = async () => {
    try {
      if (selectedChat) {
        const response = await ChatServices.manageArchiveGroupChat(selectedGroupId, Status, userId);
        if (response.status === 200) {
          await fetchUsersChat();
          toast.success(Status ? showToast('chat.chatArchive') : showToast('chat.chatUnarchive'));
          setOpenConfirmationDialog(false);
        } else {
          console.error("Error Opening Chat:", response.message);
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
      toast.error(error.message);
    }
  }

  const handleopenFileSection =() =>{
   setShowFilePage(!showFilePage);
  }


  const handleCall = async () => {
    try {
      if (selectedChat) {
        setCallRejected(false);
        const response = await ChatServices.getSocketId(selectedChat?.userId);
        if (response.status === 200) {
          callUser(response.socketId);
          setOpenVideoChat(true);
        } else {
          console.error("Error Opening Chat:", response.message);
        }
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }


  const handleDeleteChat = async () => {
    try {
      if (!selectedChat) return; // If no chat is selected, exit the function
      let response;
      if (!selectedGroupId && selectedGroupId === null) {
        // If it's a personal chat
        response = await ChatServices.deleteAllMessages(userId, selectedChat.userId);
      } else {
        // If it's a group chat
        response = await ChatServices.deleteAllMessagesinGroup(userId, selectedGroupId);
      }

      if (response.status === 200) {
        await fetchUsersChat();
        setOpenConfirmationDialog(false);
        toast.success((!selectedGroupId && selectedGroupId === null) ? showToast('chat.chatDelete') : showToast('chat.groupChatDelete'));
      }
      else {
        console.error("Error Opening Chat:", response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
      toast.error(error.message);
    }
  }


  const handleDeleteContact = async () => {
    try {
      if (!selectedChat) return; // If no chat is selected, exit the function
      let response;
      if (!selectedGroupId && selectedGroupId === null) {
        // If it's a personal chat
        response = await ChatServices.deleteContact(userId, selectedChat.userId);
      } else {
        // If it's a group chat
        response = await ChatServices.deleteGroupContact(userId, selectedGroupId);
      }
      if (response.status === 200) {
        await fetchUsersChat();
        clearSelectedChat();
        setOpenConfirmationDialog(false);
        toast.success((!selectedGroupId && selectedGroupId === null) ? showToast('chat.userRemoved') : showToast('chat.exitGroup'));
      }
      else {
        console.error("Error Opening Chat:", response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
      toast.error(error.message);
    }
  }

  const HandleDialog = (item) => {
    setFrom(item);
    setOpenConfirmationDialog(true);
    let title = '';
    let content = '';
    switch (item) {
      case "Archive":
        setStatus(true);
        title = 'Archive Chat Confirmation';
        content = 'Are you sure you want to archive this chat? Once archived, the chat will be moved to the archive section.';
        break;
      case "UnArchive":
        setStatus(false);
        title = 'Unarchive Chat Confirmation';
        content = 'Are you sure you want to unarchive this chat? Once unarchived, the chat will be moved back to the main chat section.';
        break;
      case "DeleteChat":
        title = 'Delete Chat Confirmation';
        content = 'Are you sure you want to delete this chat? This action cannot be undone.';
        break;
      case "RemoveContact":
        title = 'Remove Contact Confirmation';
        content = 'Are you sure you want to remove this contact? This action will delete the chat history with this contact.';
        break;
      case "DeleteGroupChat":
        title = 'Delete Group Chat Confirmation';
        content = 'Are you sure you want to delete this group chat? This action cannot be undone.';
        break;
      case "ExitGroup":
        title = 'Exit Group Confirmation';
        content = 'Are you sure you want to exit this group? This action will remove you from the group chat.';
        break;
      case "ArchiveGroupChat":
        setStatus(true);
        title = 'Archive Chat Confirmation';
        content = 'Are you sure you want to archive this chat? Once archived, the chat will be moved to the archive section.';
        break;
      case "UnArchiveGroupChat":
        setStatus(false);
        title = 'Unarchive Chat Confirmation';
        content = 'Are you sure you want to unarchive this chat? Once unarchived, the chat will be moved back to the main chat section.';
        break;
      default:
        break;
    }
    setTitle(title);
    setContent(content);
    handleClose();
  }


  return (
    <div className="chatnav"> 
      <ConfirmationDialog 
      handleDeleteContact={handleDeleteContact} 
      handleDeleteChat={handleDeleteChat} 
      handleArchive={handleArchive}
      Title={Title}
      Content={Content}
      from={from}
      openConfirmationDialog={openConfirmationDialog}
      setOpenConfirmationDialog={setOpenConfirmationDialog}
      handleArchiveGroup={handleArchiveGroup}
      />
      <div className="chatnav__left">
        <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={clearSelectedChat} />
        {
          selectedGroupId && selectedGroupId !== null ? 
          <LetterAvatarsecond name={selectedChat?.name} />
          :
            <LetterAvatars name={selectedChat?.name} status={selectedChat?.status} />
        }
        <Stack sx={{ marginLeft: 1.5 }}>
          <Typography variant="h6" color='#fff' >{capitalize(selectedChat?.name)}</Typography>
          {
            (!selectedGroupId && selectedGroupId === null) && 
            <Typography variant="caption" color='#fff'>{selectedChat?.status}</Typography>
           }
        </Stack>
      </div>
      <div className="chatnav__right">
        <Typography sx={{ cursor: 'pointer' }} variant="h6" color='#fff' onClick={handleopenFileSection} fontWeight={600}>
          {
            !showFilePage ? 'Shared Files' : 'Chats'
          }
         </Typography>
         {
          !selectedGroupId && selectedGroupId === null && <></>
          // <CallIcon sx={{ cursor: 'pointer' }} onClick={handleCall} />
         }
        
        <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        >
       <MoreVertIcon sx={{ color: '#fff', cursor: 'pointer' }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '20ch',
            },
          }}
        >
          {!selectedGroupId && selectedGroupId === null ? (
            [
              selectedChat?.isArchive ? (
                <MenuItem key="unarchive" onClick={() => HandleDialog('UnArchive')}>
                  Remove From Archive
                </MenuItem>
              ) : (
                <MenuItem key="archive" onClick={() => HandleDialog('Archive')}>
                  Archive Chat
                </MenuItem>
              ),
              <MenuItem key="delete-chat" onClick={() => HandleDialog('DeleteChat')}>
                Delete Chat
              </MenuItem>,
              <MenuItem key="remove-contact" onClick={() => HandleDialog('RemoveContact')}>
                Remove Contact
              </MenuItem>,
            ]
          ) : (
            [
                selectedChat?.isArchive ? (
                <MenuItem key="unarchive-group" onClick={() => HandleDialog('UnArchiveGroupChat')}>
                  Remove From Archive
                </MenuItem>
                ) : (
                  <MenuItem key="archive-group" onClick={() => HandleDialog('ArchiveGroupChat')}>
                    Archive Group Chat
                  </MenuItem>
                ),
                 <MenuItem key="delete-group" onClick={() => HandleDialog('DeleteGroupChat')}>
                  Delete Group Chat
                </MenuItem>,
              <MenuItem key="exit-group" onClick={() => HandleDialog('ExitGroup')}>
                Exit Group
              </MenuItem>,
            ]
          )}
        </Menu>
      </div>
    </div>
  );
};

export default withRouter(ChatNav);

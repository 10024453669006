import React, { useState } from "react";
import ThreeBtn from "./ThreeBtn";
import PriceTag from "./PriceTag";

export default function BasicPlan({ heading, ul, btn_list, price_detail, plan_mode, hide }) {
  const [planPeriod, setplanPeriod] = useState(null);
  const bg_img = ["bg_a", "bg_b", "bg_c", "bg_d"];

  return (
    <div id={heading.replace(/\s+/g, "_")}>
      <div className="row basic">
        <div className="col-md-8">
          <ThreeBtn
            btn_list={btn_list}
            planPeriod={period => setplanPeriod(period)}
          />
          <h2> {heading} </h2>
          <br />

          <div>
            {heading == "PET Educational Institutions Plan" && (
              <p>
                Since Educational Institutions break-up the academic year into
                different lengths of time, PET allow more than just Monthly,
                Quarterly, and Yearly subscriptions. Your PET offers
                subscription by Quarter, Semester, and by Trimester. These three
                subscriptions are broken down into Groups.
              </p>
            )}

            {ul}
          </div>
        </div>
        <div
          className={`col-md-4 d-flex justify-content-center ${
            bg_img[Math.floor(Math.random() * 4 + 0)]
          }`}
        >
          <PriceTag
            price_detail={price_detail}
            planPeriod={planPeriod}
            planName={heading}
            plan_mode={plan_mode}
            hide_mode={hide}
          />
        </div>
      </div>
      <hr />
      <br />
      <br />
    </div>
  );
}

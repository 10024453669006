import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import {
  getOrganizationData,
  getUsersOfOrganization,
  unFreezeUserBySuperAdmin,
  freezeUserBySuperAdmin,
  deleteUserAccountBySuperAdmin,
} from "../services/adminServices";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png";
import "./ManageOrganizationStyle.css";
import DataGridMui from "./ReusableComponents/DataGridMui";
import organizationService from "../services/organizationService";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import Activate from ".././static/admin_dashboard/ActivateUser.png";
import Deactivate from ".././static/admin_dashboard/DeactivateUser.png";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { resetOrganizationVirtualId } from "../services/adminServices";
import Switch from "@mui/material/Switch";
import * as XLSX from "xlsx";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import { showToast } from "../messages";

const ManageOrganization = () => {
  const [organizationDetails, setOrganizationDetails] = useState([]);
  let [loading, setLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState("");
  const [superadminid, setsuperadminid] = useState(null);
  const [newOrgId, setNewOrgId] = useState(null);

  const handleResetOrgId = async () => {
    try {
      const response = await resetOrganizationVirtualId({
        organizationName: selectedOrg.orgName,
        userId: superadminid,
      });
      showToast('Organization.reset')
      setNewOrgId(response.data);
      setOrganizationDetails((prevOrgDetails) => {
        return prevOrgDetails.map((org) => {
          if (org.orgName === selectedOrg.orgName) {
            return {
              ...org,
              orgVirtualId: response.data,
            };
          } else {
            return org;
          }
        });
      });
      console.log("New organization ID:", response.data);
    } catch (error) {
      console.error("Error resetting organization ID:", error);
      showToast('Organization.resetFailed')
    }
  };

  const handleToggleChange = (userId, isChecked) => {
    if (isChecked) {
      handleActivate(userId);
    } else {
      setSelectedUserId(userId);
      setShowDeactivateModal(true);
    }
  };

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);
  const handleDeactivateModelClose = () => setShowDeactivateModal(false);

  useEffect(() => {
    if (!showDeactivateModal) {
      setDeactivateReason("");
    }
  }, [showDeactivateModal]);
  useEffect(() => {
    console.log(newOrgId, "newOrgId");
  }, [newOrgId]);
  let email = sessionStorage.getItem("currentUserEmail");
  const getcurrentuserdata = async () => {
    try {
      const data = email;
      const response = await organizationService.getcurrentuserdata(data);
      console.log(response, "responsee");
      setsuperadminid(response?.data?._id);
    } catch (error) { }
  };

  const handleActivate = async (userId) => {
    try {
      const superAdminId = superadminid;
      const response = await unFreezeUserBySuperAdmin({ userId, superAdminId });
      if (response.status === 200) {
        showToast('Organization.accountActivate')
        handleViewUsers(selectedOrg);
      } else {
        showToast('Organization.accountActivateFailed')
      }
    } catch (error) {
      console.error("Error while activating account:", error);
      showToast('Organization.accountActivateFailed')
    }
  };

  const handleDeactivate = async () => {
    try {
      const superAdminId = superadminid;
      const response = await freezeUserBySuperAdmin({
        userId: selectedUserId,
        superAdminId,
        freezeReason: deactivateReason,
      });
      if (response.status === 200) {
        showToast('Organization.accountDeactivate')
        handleViewUsers(selectedOrg);
      } else {
        showToast('Organization.accountDeactivateFailed')
      }
    } catch (error) {
      console.error("Error while deactivating account:", error);
      showToast('Organization.accountDeactivateFailed')
    }
    handleDeactivateModelClose();
  };

  const handleDelete = async () => {
    try {
      const superAdminId = superadminid;
      const response = await deleteUserAccountBySuperAdmin({
        userId: selectedUserId,
        superAdminId,
      });
      console.log(superAdminId, "superAdminId");
      console.log(selectedUserId, "selectedUserId");
      if (response.status === 200) {
        showToast('Organization.accountDelete')
        setUsers(users.filter((user) => user._id !== selectedUserId));
      } else {
        showToast('Organization.accountDeleteFailed')
      }
    } catch (error) {
      console.error("Error while deleting user account:", error);
      showToast('Organization.accountDeleteFailed')
    }
    handleClose();
  };

  const handleViewUsers = async (org) => {
    setSelectedOrg(org);
    try {
      setLoading(true);
      const response = await getUsersOfOrganization(org.orgName);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred while fetching users:", error);
    }
  };

  const handleCloseModal = () => {
    setSelectedOrg(null);
    setUsers([]);
  };

  useEffect(() => {
    getcurrentuserdata();
    getAllOrgDetails();
  }, []);
  const userColumns = [
    {
      field: "username",
      headerName: "User Name",
      flex: 0.3,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      type: "number",
      flex: 0.2,
    },
    {
      field: "Role",
      headerName: "User Role",
      flex: 0.2,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.2,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 0.2,
      renderCell: (params) => (
        <>
          <span style={{ color: params.row.freeze ? "#ed4f4a" : "#28a745" }}>
            {params.row.freeze ? "Inactive" : "Active"}
          </span>
          <Tooltip title={params.row.freeze ? "Activate" : "Deactivate"}>
            <Switch
              checked={!params.row.freeze}
              onChange={(event) =>
                handleToggleChange(params.row._id, event.target.checked)
              }
              style={{ color: params.row.freeze ? "#ed4f4a" : "#28a745" }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      field: "Delete",
      headerName: "Delete",
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title="Delete Account">
          <DeleteOutlineIcon
            style={{ cursor: "pointer", color: "#ed4f4a" }}
            onClick={() => {
              setSelectedUserId(params.row._id);
              handleShow();
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const columns = [
    {
      field: "orgName",
      headerName: "Organization Name",
      flex: 0.2,
      editable: false,
    },
    {
      field: "numberOfUsers",
      headerName: "Number of Users",
      flex: 0.1,
      renderCell: (params) => (
        <>
          {params.value}
          <IconButton onClick={() => handleViewUsers(params.row)}>
            <Tooltip title="View Users">
              <VisibilityIcon style={{ color: "#3d83fa" }} />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
    {
      field: "orgVirtualId",
      headerName: "Organization ID",
      flex: 0.4,
    },
    {
      field: "planName",
      headerName: "Plan Name",
      flex: 0.2,
    },
  ];

  const getAllOrgDetails = async () => {
    try {
      setLoading(true);
      const res = await getOrganizationData();
      setOrganizationDetails(res?.orgDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred while fetching organization data:", error);
    }
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const filteredData = organizationDetails.map((org) => ({
      orgName: org.orgName,
      numberOfUsers: org.numberOfUsers,
      orgVirtualId: org.orgVirtualId,
      planName: org.planName,
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    saveAs(excelData, "organizations" + fileExtension);
  };

  const exportToCSV = () => {
    const filteredData = organizationDetails.map((org) => ({
      orgName: org.orgName,
      numberOfUsers: org.numberOfUsers,
      orgVirtualId: org.orgVirtualId,
      planName: org.planName,
    }));

    const csvData = parse(filteredData);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(csvBlob, "organizations.csv");
  };

  const handleExport = (format) => {
    if (format === "excel") {
      exportToExcel();
    } else if (format === "csv") {
      exportToCSV();
    }
  };

  const exportUsersToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const filteredUsers = users.map((user) => ({
      username: user.username,
      email: user.email,
      phone: user.phone,
      Role: user.Role,
      organizationName: user.organizationName,
      country: user.country,
      Status: user.freeze ? "Inactive" : "Active",
    }));

    const ws = XLSX.utils.json_to_sheet(filteredUsers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    saveAs(
      excelData,
      `${selectedOrg ? selectedOrg.orgName : "organization"}_users` +
      fileExtension
    );
  };

  const exportUsersToCSV = () => {
    const filteredUsers = users.map((user) => ({
      username: user.username,
      email: user.email,
      phone: user.phone,
      Role: user.Role,
      organizationName: user.organizationName,
      country: user.country,
      Status: user.freeze ? "Inactive" : "Active",
    }));

    const csvData = parse(filteredUsers);
    const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(
      csvBlob,
      `${selectedOrg ? selectedOrg.orgName : "organization"}_users.csv`
    );
  };

  const handleUsersExport = (format) => {
    if (format === "excel") {
      exportUsersToExcel();
    } else if (format === "csv") {
      exportUsersToCSV();
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="export-dropdown">
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            Export
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => handleExport("excel")}
              className="dropdown-item custom-dropdown-item"
            >
              Export to Excel
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleExport("csv")}
              className="dropdown-item custom-dropdown-item"
            >
              Export to CSV
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="row mt-2 w-100">
        {organizationDetails.length === 0 ? (
          <div className="col-md-12 text-center">
            <img src={team} alt="No Teams" />
            <h3>No teams found!</h3>
          </div>
        ) : (
          <div className="col-md-12">
            <DataGridMui
              height="75vh"
              getRowId={(data) => data.orgName}
              columns={columns}
              rows={organizationDetails.filter(
                (user) =>
                  user.username !== "Unknown" ||
                  !!user.email ||
                  !!user.phone ||
                  !!user.Role ||
                  !!user.organizationName ||
                  !!user.country ||
                  user.freeze !== true
              )}
              bgWhite={true}
            />
          </div>
        )}

        {/* User Modal */}
        <div
          className="modal-overlay"
          style={{ display: selectedOrg ? "block" : "none" }}
        >
          <div className="modal-content" style={{ padding: "0px" }}>
            <div style={{ padding: "15px 0px 15px 0px", borderBottom: "2px solid black" }}>
              <button className="close-button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
              <p style={{ paddingLeft: "15px" }}>
                <b>Organization User Details</b>
              </p>
            </div>

            {selectedOrg && (
              <div>
                {/* <b>Organization:</b> {selectedOrg.orgName} */}
                <div className="changedlayout">
                  <div
                    style={{
                      width: "85%",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                      padding: "12px"
                    }}>
                    <div style={{ width: "80%" }}>
                      <h6>
                        <b> {selectedOrg.orgName} :</b>{" "}
                        {newOrgId || selectedOrg.orgVirtualId}
                      </h6>
                    </div>
                    <div style={{ width: "10%", display: "flex", justifyContent: "end" }}>
                      <Button onClick={handleResetOrgId} 
                      style={{
                        backgroundColor: "white",
                        color: "#1976D2",
                        borderColor: "#1976D2"
                      }}
                      >Reset Id</Button>
                    </div>
                  </div>
                  <div style={{ width: "15%", display: "flex", justifyContent: "end" }}>
                    <Dropdown className="ml-auto">
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleUsersExport("excel")}
                          className="dropdown-item custom-dropdown-item"
                        >
                          Export to Excel
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleUsersExport("csv")}
                          className="dropdown-item custom-dropdown-item"
                        >
                          Export to CSV
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </div>
                <div style={{ padding: "15px" }}>
                  {users.length === 0 ? (
                    <h6>No users found for this organization</h6>
                  ) : (
                    <DataGridMui
                      // height="50vh"
                      getRowId={(data) => data._id}
                      columns={userColumns}
                      rows={users}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Delete Modal */}
        <Modal
          show={showDeleteModal}
          onHide={handleClose}
          centered
          className="delete-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you really want to delete this user? The user will be deleted
              permanently.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Deactivate Modal */}
        <Modal
          show={showDeactivateModal}
          onHide={handleDeactivateModelClose}
          centered
          className="deactivate-modal"
          style={{ zIndex: 99999 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Deactivate Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Provide a reason for deactivating this account:</p>
            <Form.Control
              as="textarea"
              rows={3}
              value={deactivateReason}
              onChange={(e) => setDeactivateReason(e.target.value)}
              className="reason-textarea"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeactivateModelClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeactivate}
              disabled={!deactivateReason.trim()}
            >
              Deactivate
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default withRouter(ManageOrganization);

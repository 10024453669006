import React, { useState } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { toast, ToastContainer } from "react-toastify";
import authService from "../services/authService.js";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./LoginForm.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { setUserFeatures } from "../redux/features/features.action";
import { getHomeCMS } from "../services/adminServices";
import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import Login1 from "../static/login-3.png";
import Login2 from "../static/login-2.png";
import Login3 from "../static/login-1.png";
import {imgUrl} from "../services/config.json";

const useStyles = makeStyles((theme) => ({
  // container: {
  //   display: "flex",
  //   flexWrap: "wrap",
  // },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: "100%",
  // },
  // dense: {
  //   marginTop: 19,
  // },
}));

function Login({ history, setCurrentUser, currentUser, setUserFeatures }) {
  const classes = useStyles();
  const facebookUrl = "http://localhost:4000/api/auth/facebook";
  let [email, setEmail] = useState("");
  const [values, setValues] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading, showPassword] = useState(false);
  const [isRememberMe, setRemeberMe] = useState(false);
  const [emailerrors, setEmailErrors] = useState("");
  const [passerrors, setPassErrors] = useState("");
  const [cms, setCms] = useState({});

  async function handleEmail(e) {
    setEmail(e.target.value);
    let email = e.target.value;
    console.log(email);
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email === "") {
      setEmailErrors("Email is not allowed to be empty.");
    } else {
      setEmailErrors("");
      if (reg.test(email) === false) {
        setEmailErrors("Invalid Email.");
      } else {
        setEmailErrors(" ");
        setEmail(email);
      }
      //  setPassword(password);
    }
  }
  async function handlePassword(e) {
    let password = e.target.value;
    if (password === "") {
      setPassErrors("Password is not allowed to be empty.");
    } else {
      setPassErrors("");
      setPassword(password);
    }
  }

  async function handleLogin() {
    setLoading(true);
    let user = {
      email: email.toLowerCase(),
      password: password,
    };

    let response = await authService.login(user);

    if (response.status !== 200) {
      setLoading(false);
      toast.error(response.message);
    } else {
      const { user, features } = response;
      setCurrentUser(user);
      setUserFeatures(features);
      // toast.info(response.message);
      console.log(response,"currentUsre");
      localStorage.setItem("token", response.token);
      localStorage.setItem("orgToken", response.organizationId);
      if (localStorage.getItem("rememberMe")) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("orgToken", response.organizationId);
      }
      // const user = await authService.getCurrentUser();
      setLoading(false);
      if (user.isAdmin === true && user.isSubAdmin === false) {
        history.push({
          pathname: `/admindashboard`,
        });
      } else if (user.isAdmin === false && user.isSubAdmin === false) {
        history.push({
          pathname: `/dashboard`,
        });
      } else {
        history.push({
          pathname: `/subAdminManageUser`,
        });
      }
    }
  }

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    if (localStorage.getItem("rememberMe")) {
      history.push("/dashboard");
    }
    getCms();
    // return () => toast.dismiss();
  }, []);

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      handleLogin();
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleRememberMe = (e) => {
    const isRememberMe = e.target.checked;
    if (isRememberMe) {
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.setItem("rememberMe", false);
    }
    setRemeberMe(isRememberMe);
  };
  function statusChangeCallback(response) {
    // Called with the results from FB.getLoginStatus().
    console.log("statusChangeCallback");
    console.log(response); // The current login status of the person.
    if (response.status === "connected") {
      // Logged into your webpage and Facebook.
      testAPI();
    } else {
      // Not logged into your webpage or we are unable to tell.
      document.getElementById("status").innerHTML =
        "Please log " + "into this webpage.";
    }
  }

  function checkLoginState() {
    // Called when a person is finished with the Login Button.
    window.FB.getLoginStatus(function (response) {
      // See the onlogin handler
      statusChangeCallback(response);
    });
  }

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "2516433218621372",
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: "v8.0", // Use this Graph API version for this call.
    });

    window.FB.getLoginStatus(function (response) {
      // Called after the JS SDK has been initialized.
      statusChangeCallback(response); // Returns the login status.
    });
  };

  (function (d, s, id) {
    // Load the SDK asynchronously
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  function testAPI() {
    // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    console.log("Welcome!  Fetching your information.... ");
    window.FB.api("/me", function (response) {
      console.log("Successful login for: " + response.name);
      document.getElementById("status").innerHTML =
        "Thanks for logging in, " + response.name + "!";
    });
  }

  return (
    <div className="container-fluid" style={{ height: "100%" }}>
      <ToastContainer />
      <Loader loading={loading} />

      {/* <div className="flexCont"> */}
      <div className="row" style={{ height: "100vh" }}>
        {/* Flex Two */}

        <div
          className="col-md-5 p-4"
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Flex Two Content */}

          <div className="text-center mt-2">
            <Link to="/">
              <img
                // src={image1}
                // alt="image1"
                className="petLogo"
                alt="Group570"
                src={
                  cms && cms.Navbar_logo
                    ? cms.Navbar_logo.Image
                    : `${imgUrl}/pet-website-static-images/Group+571.png`
                }
                style={{
                  width: "40%",
                  height: "10%",
                  // objectFit: "contain",
                }}
              ></img>
            </Link>
          </div>

          <div
            style={{
              paddingLeft: "15%",
              paddingRight: "15%",
              paddingTop: "8%",
              paddingBottom: "2%",
              marginTop: "-1%",
            }}
          >
            <Card
              className="text-center"
              style={{ border: "none", borderRadius: "8px" }}
            >
              <div className="text-center pt-4">
                <h3 className="mainHeading">Welcome to PET</h3>
              </div>

              <div className="loginForm">
                <Form
                  // className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <InputGroup size="lg" className="mb-1 loginInput">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="email"
                      label="Email"
                      value={email}
                      onChange={(e) => handleEmail(e)}
                      placeholder="Username or Email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <p className="p-2" style={{ color: "red", fontSize: "10px" }}>
                    {emailerrors}
                  </p>

                  <InputGroup size="lg" className="mb-1 loginInput">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-key" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(e) => handlePassword(e)}
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <p className="p-2" style={{ color: "red", fontSize: "10px" }}>
                    {passerrors}
                  </p>
                </Form>
              </div>

              <div className="row" style={{ marginTop: "2%" }}>
                <div className="col-md-6">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className="loginCheckbox"
                  >
                    <Form.Check
                      size="xl-lg"
                      type="checkbox"
                      inline
                      label="Remember me"
                      checked={isRememberMe}
                      onChange={(e) => handleRememberMe(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="primary"
                    className="loginBtn"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </div>
              </div>

              <div className="row" style={{ marginTop: "2%" }}>
                <div className="col-md-6">
                  <Link to="/signup" className="register">
                    Register now
                  </Link>
                </div>
                <div className="col-md-6">
                  <Link to="/forgotpassword" className="forgotpass">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div className="text-center" style={{ marginTop: "7%" }}>
                <div
                  className="btn-group btn-group-lg mb-3 socialBtn"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#c53126", color: "white" }}
                  >
                    <i className="fa fa-google fa-fw"></i>
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#f44336", color: "white" }}
                  >
                    <a
                      href="https://www.professionaleditingtool.com/api/auth/google"
                      className="googlefblink"
                    >
                      LOGIN WITH GOOGLE
                    </a>
                  </button>
                </div>

                <br />

                <div
                  className="btn-group btn-group-lg mb-5 socialBtn"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#263c68", color: "white" }}
                  >
                    <i className="fa fa-facebook fa-fw"></i>
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#3b5998", color: "white" }}
                  >
                    <a
                      href="https://www.professionaleditingtool.com/api/auth/facebook"
                      className="googlefblink"
                    >
                      LOGIN WITH FACEBOOK
                    </a>
                  </button>
                </div>

                <br />
              </div>
            </Card>

            <div className="text-center mt-4">
              <Link to="/privacypolicy" className="privacyLink">
                Term of use. Privacy policy
              </Link>
            </div>
          </div>
        </div>

        {/* Flex One */}
        <div
          className="col-md-7"
          style={{
            backgroundImage: `url(${Login1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* Flex One Content */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setUserFeatures: (features) => dispatch(setUserFeatures(features)),
});

export default connect(null, mapDispatchToProps)(withRouter(Login));

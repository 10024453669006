import React from 'react'
import ManualOpportunity from '../ManualOpportunity'
import SubAdminSideBar from '../SubAdminSideBar'

const ManualOpportunityPage = () => {
  return (
    <SubAdminSideBar>
    <ManualOpportunity />
  </SubAdminSideBar>
  )
}

export default ManualOpportunityPage
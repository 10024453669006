import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";


function FeatureButton({ text, onClick,size,style,disable, buttonClassName,divClassName }) {
    return (
        <div className={divClassName}>
            <Button variant="outline-secondary" disabled={disable} style={style} size={size} className={buttonClassName} onClick={onClick}>{text}</Button>
        </div>
    )
}

export default FeatureButton;


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  }
}));

const colors = [
  "#bff603", "yellow", "#80d4ff", "orange",
  "#ff8b94", "#d3ffce", "#9bb7d4", "#ffdb58",
  "#f1a7fe", "#ff6961", "#ffb347", "#add8e6",
  "#a0bfe6", "#89cff0", "#4f86f7", "#67e6ec",
  "#7fffd4", "#77dd77", "#b19cd9", "#ff9999"
];

const letterToColorIndex = (letter) => {
  // Convert letter to lowercase to ensure consistency
  const lowercaseLetter = letter.toLowerCase();
  // Get ASCII code of the lowercase letter
  const charCode = lowercaseLetter.charCodeAt(0) - 97; // ASCII code of 'a' is 97
  // Wrap the charCode to ensure it stays within the bounds of the colors array
  return charCode % colors.length;
};

export default function LetterAvatars({ name, status }) {
  const classes = useStyles();
  const colorIndex = letterToColorIndex(name.charAt(0));


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: status === 'Online' ? '#44b700' : '#d3d6db',
      color: status === 'Online' ? '#44b700' : '#d3d6db', 
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: status === 'Online' ? 'ripple 1.2s infinite ease-in-out' : '',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  return (

          <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
      <Avatar
        style={{
          backgroundColor: `${colors[colorIndex]}`,
          color: "black"
        }}
      >
        <h5
          style={{ marginTop: "4px" }}
          data-toggle="tooltip"
          title={name}
        >
          {name.charAt(0).toUpperCase()}
        </h5>
      </Avatar>
      </StyledBadge>



  );
}

export const menuItems = [
    {
      path: '/dashboard',
      label: 'Dashboard',
      icon: 'Dashboard',
    },
    {
      path: '/searchOpportunity',
      label: 'Search',
      icon: 'SearchIcon',
    },
    {
      path: '/OppourtunityManagement',
      label: 'Opportunity Management',
      icon: 'PostAddIcon',
    },
    {
      path: '/manualOpportunity',
      label: 'Manual Opportunity',
      icon: 'Documents',
    },
    {
      path: '/communicationHub',
      label: 'Communications Hub',
      icon: 'GroupsIcon',
    },
    {
      path: '/userRole',
      label: 'User Role',
      icon: 'ManageAccountsIcon',
    },
    {
      path: '/helpandSupport',
      label: 'Help and Support',
      icon: 'LiveHelpIcon',
    },
  ];


  export const subadminMenuItems = [
    {
      path: '/subAdminDashboard',
      label: 'Dashboard',
      icon: 'Dashboard',
    },
    {
      path: '/organization',
      label: 'Organization',
      icon: 'LocalLibraryIcon',
    },
    {
      path: '/filterOpportunityinOrg',
      label: 'Matching Opportunities',
      icon: 'ScreenSearchDesktopIcon',
    },
    {
      path: '/searchOpportunity',
      label: 'Opportunity Finder',
      icon: 'SearchIcon',
    },
    {
      path: '/OppourtunityManagementSubadmin',
      label: 'Opportunity Management',
      icon: 'PostAddIcon',
    },
    // {
    //   path: '/rfpManagement',
    //   label: 'RFP Management',
    //   icon: 'AddToPhotosIcon',
    // },
    // {
    //   path: '/proposalWriting',
    //   label: 'Proposal Writing',
    //   icon: 'Documents',
    // },
    {
      path: '/TemplateManagement',
      label: 'Template Management',
      icon: 'ArticleIcon',
    },
    {
      path: '/workflow',
      label: 'Workflow Administration',
      icon: 'AccountTreeIcon',
    },
    {
      path: '/subAdminManageUser',
      label: 'User Management',
      icon: 'PeopleAltIcon',
    },
    
    // {
    //   path: '/subAdminManageUser',
    //   label: 'Manage User',
    //   icon: 'PeopleAltIcon',
    // },
    // {
    //   path: '/subAdminUserRequest',
    //   label: 'User Request',
    //   icon: 'ManageAccountsRoundedIcon',
    // },
    // {
    //   path: '/manualOpportunity',
    //   label: 'Manual Opportunity',
    //   icon: 'Documents',
    // },
    {
      path: '/appfeatureRolelist',
      label: 'Role Management',
      icon: 'ManageAccountsIcon',
    },
    {
      path: '/FARclause',
      label: 'Clause Generation',
      icon: 'Documents',
    },
    // {
    //   path: '/communicationHubSubadmin',
    //   label: 'Communications Hub',
    //   icon: 'GroupsIcon',
    // },
    // {
    //   path: '/helpAndSupportSubadmin',
    //   label: 'Help and Support',
    //   icon: 'LiveHelpIcon',
    // },
    // {
    //   path: '/traningandsetting',
    //   label: 'Training and Settings',
    //   icon: 'SettingsIcon',
    // },
   
  ];


  export const userMenuItems = [
    {
      path: '/dashboard',
      label: 'Dashboard',
      icon: 'Dashboard',
    },
    {
      path: '/organization',
      label: 'Organization',
      icon: 'LocalLibraryIcon',
    },
    {
      path: '/filterOpportunityinOrg',
      label: 'Matching Opportunities',
      icon: 'ScreenSearchDesktopIcon',
    },
    {
      path: '/searchOpportunity',
      label: 'Opportunity Finder',
      icon: 'SearchIcon',
    },
    {
      path: '/OppourtunityManagementSubadmin',
      label: 'Opportunity Management',
      icon: 'PostAddIcon',
    },
    // {
    //   path: '/rfpManagement',
    //   label: 'RFP Management',
    //   icon: 'AddToPhotosIcon',
    // },
    // {
    //   path: '/proposalWriting',
    //   label: 'Proposal Writing',
    //   icon: 'Documents',
    // },
    {
      path: '/TemplateManagement',
      label: 'Template Management',
      icon: 'ArticleIcon',
    },
    {
      path: '/workflow',
      label: 'Workflow Administration',
      icon: 'AccountTreeIcon',
    },
    {
      path: '/subAdminManageUser',
      label: 'User Management',
      icon: 'PeopleAltIcon',
    },
    
    // {
    //   path: '/subAdminManageUser',
    //   label: 'Manage User',
    //   icon: 'PeopleAltIcon',
    // },
    // {
    //   path: '/subAdminUserRequest',
    //   label: 'User Request',
    //   icon: 'ManageAccountsRoundedIcon',
    // },
    // {
    //   path: '/manualOpportunity',
    //   label: 'Manual Opportunity',
    //   icon: 'Documents',
    // },
    {
      path: '/appfeatureRolelist',
      label: 'Role Management',
      icon: 'ManageAccountsIcon',
    },
    {
      path: '/FARclause',
      label: 'Clause Generation',
      icon: 'Documents',
    },
    {
      path: '/faq',
      label: 'FAQ',
      icon: 'QuizIcon',
    },
    // {
    //   path: '/communicationHubSubadmin',
    //   label: 'Communications Hub',
    //   icon: 'GroupsIcon',
    // },
    // {
    //   path: '/helpAndSupportSubadmin',
    //   label: 'Help and Support',
    //   icon: 'LiveHelpIcon',
    // },
    // {
    //   path: '/traningandsetting',
    //   label: 'Training and Settings',
    //   icon: 'SettingsIcon',
    // },
   
  ];


  export const superAdminMenuItems = [
    {
      path: '/admindashboard',
      label: 'Dashboard',
      icon: 'Dashboard',
    },
    {
      path: '/manageuser',
      label: 'Manage Users',
      icon: 'PeopleAltIcon',
    },
    {
      path: '/manageorganization',
      label: 'Manage Organizations',
      icon: 'CorporateFareIcon',
    },
    {
      path: '/appfeatureRolelist',
      label: 'Manage Feature',
      icon: 'ManageAccountsIcon',
    },
    {
      path: '/pricing',
      label: 'Manage Subscription',
      icon: 'AttachMoneyIcon',
    },
    {
      path: '/manageinvoices',
      label: 'Manage Invoices',
      icon: 'ReceiptIcon',
    },
    {
      path: '/managereviews',
      label: 'Manage Reviews',
      icon: 'Review',
    },
    {
      path: '/adminBlogs',
      label: 'Manage Blogs',
      icon: 'BookIcon',
    },
    {
      path: '/costumHome',
      label: 'Customize Landing Page',
      icon: 'AccountBalanceIcon',
    },
    {
      path: '/TemplateGallery',
      label: 'Manage Templates',
      icon: 'ReceiptLongIcon',
    },
    {
      path: '/promptsGenerator',
      label: 'Prompts Generator',
      icon: 'HistoryEduIcon',
    },
  ];
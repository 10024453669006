import React from "react";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import PlagiarismContextProvider from "../../contexts/PlagiarismContext";
import Organization from "../Organizations";
import SubAdminSideBar from "../SubAdminSideBar";
const OrganizationPage = (props) => {

  return (
    <React.Fragment>
      <SubAdminSideBar>
       <Organization />
    </SubAdminSideBar>
    </React.Fragment>
  );
};

export default NetworkDetector(OrganizationPage);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "./AlertsCard.css";

const useStyles = makeStyles({
  card: {
    //maxWidth: 345
  },
  media: {
    height: 140
  }
});

const AlertCard = ({ data, readAlert }) => {
  // console.log(data);
  const classes = useStyles();
  return (
    <div className="col-6 p-2 userCard-res-col">
      <Card id="DocCard" className={classes.card}>
        <CardActionArea>
          <CardContent>
            <div className="col-1 text-primary ft_lg exIcon">
              <i className="fa fa-exclamation-circle"></i>
            </div>
            <div
              className="col-1 ml-auto text-primary ft_lg"
              style={{ marginTop: "-25px" }}
            >
              <i onClick={() => readAlert(data)} class="fa fa-close"></i>
            </div>

            <div className=" d-flex" style={{ marginTop: "-25px" }}>
              <div className="ml-5 ">
                <p className="alertTitle ft_lg m-1"> {data.title} </p>
                <p className="alertMsg ft_sm text-a mt-2 ml-1">
                  {" "}
                  {data.message}
                </p>
              </div>
            </div>

            <div
              className="MuiCardActions-spacing"
              style={{ display: "none" }}
            ></div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default AlertCard;

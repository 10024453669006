



import React, { useState, useEffect } from 'react';
import NetworkDetector from '../HigherOrderComponents/NetworkDetector';
import TemplateGallery from '../TemplateGallery';
import SubAdminSideBar from '../SubAdminSideBar';
import AdminSideNavBar from '../AdminSideNavBar';
import { getUserInfo } from '../../services/opportunityService';

export const TemplateGalleryPage = () => {
const [userInfo, setUserInfo] = useState(null)


useEffect(() => {
  const Username = sessionStorage.getItem("currentUserEmail")

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo(Username);
      console.log("User info for gallery:12", response.data.Role);
      setUserInfo(response.data.Role);
    //  setLoading(false); // Set loading to false after successfully fetching user info
    } catch (error) {
      console.log("Error fetching user info:", error);
    }
  };

  fetchUserInfo();
}, []);

  return (
    <React.Fragment>

<React.Fragment>
      {userInfo === "SuperAdmin" ? (
        <AdminSideNavBar>
          <TemplateGallery />
        </AdminSideNavBar>
      ) : (
        <SubAdminSideBar>
          <TemplateGallery />
        </SubAdminSideBar>
      )}
    </React.Fragment>

  </React.Fragment>
  );
}

export default NetworkDetector(TemplateGalleryPage);


import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import PieChartComp from "./ReusableComponents/PieChartComp";
import individual_user from ".././static/admin_dashboard/solar_user-bold.png";
import registered_organization from ".././static/admin_dashboard/fluent_building-people-24-filled.png";
import ImageCard from "./ReusableComponents/ImageCard";
import SimpleBarChart from "./ReusableComponents/SimpleBarChart";
import RevenueChart from "./RevenueChart";
import { Link } from "react-router-dom";
import {
  getSuperadminDashboardSectionsVisibility,
  getUsersCount,
  resetSuperadminDashboardCustomization,
  updateSuperadminDashboardSectionsPosition,
  updateSuperadminDashboardSectionsVisibility,
} from "../services/dashboardServices";
import { getOrganizationCount } from "../services/dashboardServices";
import { getOrganizationData } from "../services/adminServices";
import {
  countryPercentageOfUser,
  organizationPlanBreakdown,
} from "../services/dashboardServices";
import { Menu, MenuItem as MuiMenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Draggable from "react-draggable";

export function AdminDashboard() {
  const [selectedChartType, setSelectedChartType] = useState("monthly");
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [totalOrgCount, setTotalOrgCount] = useState(0);
  const [orgUserData, setOrgUserData] = useState([]);
  const [countryPieChartData, setCountryPieChartData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [planData, setPlanData] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibilityStates, setVisibilityStates] = useState({
    countryBreakdwon: { visible: true, position: { x: 0, y: 0 } },
    planBreakdwon: { visible: true, position: { x: 0, y: 0 } },
    orgUsersChart: { visible: true, position: { x: 0, y: 0 } },
    revenueChart: { visible: true, position: { x: 0, y: 0 } },
    totalUsersCard: { visible: true, position: { x: 0, y: 0 } },
    orgsCard: { visible: true, position: { x: 0, y: 0 } },
  });
  const visibilityStateMapping = {
    "Country Breakdwon": "countryBreakdwon",
    "Plan Breakdwon": "planBreakdwon",
    "Org Users Chart": "orgUsersChart",
    "Revenue Chart": "revenueChart",
    "Total Users Card": "totalUsersCard",
    "Organizations Card": "orgsCard",
  };
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleVisibilityToggle = async (option) => {
    const userEmail = sessionStorage.getItem("currentUserEmail");
    try {
      const newVisibilityStates = {
        ...visibilityStates,
        [visibilityStateMapping[option]]: {
          ...visibilityStates[visibilityStateMapping[option]],
          visible: !visibilityStates[visibilityStateMapping[option]].visible,
        },
      };
      const mappedSectionName = visibilityStateMapping[option];
      const response = await updateSuperadminDashboardSectionsVisibility({
        userEmail: userEmail,
        section: mappedSectionName,
        visibility: newVisibilityStates[visibilityStateMapping[option]].visible,
      });

      if (response.status === 200) {
        setVisibilityStates(newVisibilityStates);
      } else {
        console.error("Failed to update visibility:", response.message);
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  const menuOptions = [
    "Country Breakdwon",
    "Plan Breakdwon",
    "Org Users Chart",
    "Revenue Chart",
    "Total Users Card",
    "Organizations Card",
  ];
  const userEmail = sessionStorage.getItem("currentUserEmail");
  useEffect(() => {
    async function fetchVisibility() {
      try {
        const response = await getSuperadminDashboardSectionsVisibility({
          userEmail: userEmail,
        });
        if (response) {
          setVisibilityStates(response?.data);
        }
      } catch (error) {
        console.error("Error fetching visibility:", error);
      }
    }

    if (userEmail) {
      fetchVisibility();
    }
  }, [userEmail]);
  const handleResetToDefault = async () => {
    try {
      const response = await resetSuperadminDashboardCustomization({
        userEmail: userEmail,
      });
      if (response.status === 200) {
        setVisibilityStates(response.data);
        console.log("Dashboard reset to default successfully.");
      } else {
        console.error(
          "Failed to reset dashboard customization:",
          response.message
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while resetting dashboard customization:",
        error
      );
    }
  };

  const handleDragStop = async (e, data, section) => {
    const newPosition = { x: data.x, y: data.y };
    try {
      const response = await updateSuperadminDashboardSectionsPosition({
        userEmail: userEmail,
        section,
        position: newPosition,
      });
      console.log(response.data.message);

      setVisibilityStates((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          position: newPosition,
        },
      }));
    } catch (error) {
      console.error("Error updating section position:", error);
    }
  };

  const monthlyRevenueData = [
    { month: "Jan", basicPlan: 5000, premiumPlan: 8000 },
    { month: "Feb", basicPlan: 6000, premiumPlan: 10000 },
    { month: "Mar", basicPlan: 7000, premiumPlan: 12000 },
    { month: "Apr", basicPlan: 8000, premiumPlan: 11000 },
    { month: "May", basicPlan: 9000, premiumPlan: 13000 },
    { month: "Jun", basicPlan: 10000, premiumPlan: 15000 },
    { month: "Jul", basicPlan: 11000, premiumPlan: 16000 },
    { month: "Aug", basicPlan: 12000, premiumPlan: 17000 },
    { month: "Sep", basicPlan: 13000, premiumPlan: 18000 },
    { month: "Oct", basicPlan: 14000, premiumPlan: 19000 },
    { month: "Nov", basicPlan: 15000, premiumPlan: 20000 },
    { month: "Dec", basicPlan: 16000, premiumPlan: 21000 },
  ];

  const yearlyRevenueData = [
    { year: 2024, basicPlan: 80000, premiumPlan: 150000 },
  ];

  useEffect(() => {
    async function fetchTotalUsersCount() {
      try {
        const response = await getUsersCount();
        setTotalUsersCount(response.data);
      } catch (error) {
        console.error("Error fetching total users count:", error);
      }
    }
    fetchTotalUsersCount();
  }, []);

  useEffect(() => {
    async function fetchOrganizationCount() {
      try {
        const response = await getOrganizationCount();
        setTotalOrgCount(response.organizationCount);
      } catch (error) {
        console.error("Error fetching total users count:", error);
      }
    }
    fetchOrganizationCount();
  }, []);

  useEffect(() => {
    async function fetchOrgUserData() {
      try {
        const response = await getOrganizationData();
        setOrgUserData(response.orgDetails);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    }
    fetchOrgUserData();
  }, []);

  useEffect(() => {
    async function fetchCountryData() {
      try {
        const response = await countryPercentageOfUser();
        setCountryPieChartData(response);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    }
    fetchCountryData();
  }, []);
  useEffect(() => {
    async function fetchOrganizationList() {
      try {
        const response = await getOrganizationData();
        setOrgList(response.orgDetails);

        if (response.orgDetails.length > 0) {
          setSelectedOrg(response.orgDetails[0].orgName);
        }
      } catch (error) {
        console.error("Error fetching organization list:", error);
      }
    }
    fetchOrganizationList();
  }, []);

  useEffect(() => {
    async function fetchPlanData() {
      if (selectedOrg) {
        try {
          const response = await organizationPlanBreakdown({
            organizationName: selectedOrg,
          });
          setPlanData(response);
        } catch (error) {
          console.error("Error fetching organization plan breakdown:", error);
        }
      }
    }
    console.log("Selected organization on effect:", selectedOrg);

    if (selectedOrg || selectedOrg === "") {
      console.log("Fetching plan data initially...");
      fetchPlanData();
    }
  }, [selectedOrg]);

  const handleOrgChange = (orgName) => {
    setSelectedOrg(orgName);
  };

  return (
    <>
    <div style={{ display: "flex", flexDirection: "column", width: "100%",marginLeft:window.innerWidth < 850 ? "2.5%" : "",marginTop:window.innerWidth < 850 ? "-20px" : "" }}>
      <div style={{display: "flex", width: "100%", justifyContent: "space-between", flexDirection: window.innerWidth < 850 ? "column" : "row"}}>
  <div style={{ width: window.innerWidth < 850 ? "100%" : "16%"}}>
    <div style={{ width: "100%",display: "flex",flexWrap:"wrap", flexDirection: window.innerWidth < 850 ? "row" : "column", justifyContent: "space-between", alignItems:"center" }}>
      <button
        onClick={handleMenuClick}
        style={{
          // height: window.innerWidth < 850 ? "80px" : "40px",
          border: "1px solid #1976D2",
          color: "#007BFF",
          backgroundColor: "white",
          cursor: "pointer",
          width: window.innerWidth < 850 ? "48%" : "100%",
          boxShadow: "2px 2px 5px -2px #000000",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        Customization
      </button>
      <button
        onClick={handleResetToDefault}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          // height: window.innerWidth < 850 ? "80px" : "40px",
          border: "1px solid #1976D2",
          color: "#007BFF",
          backgroundColor: "white",
          cursor: "pointer",
          width: window.innerWidth < 850 ? "48%" : "100%",
          boxShadow: "2px 2px 5px -2px #000000",
          borderRadius: "10px",
          fontSize: "15px",
        }}
      >
        Reset to Default
      </button>
      <Menu 
      anchorEl={menuAnchorEl} 
      open={Boolean(menuAnchorEl)} 
      onClose={handleMenuClose} 
      PaperProps={{
                sx: {
                  "& .MuiList-root": {
                    paddingTop: '0px !important',
                  },
                },
              }}>
        {menuOptions.map((option) => (
          <MuiMenuItem
            key={option}
            onClick={() => handleVisibilityToggle(option)}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#A8D2FF",
                color: "white",
              },
            }}
          >
            {option}
            {visibilityStates[visibilityStateMapping[option]]?.visible ? (
              <VisibilityOffIcon
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleVisibilityToggle(option);
                }}
              />
            ) : (
              <VisibilityIcon
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleVisibilityToggle(option);
                }}
              />
            )}
          </MuiMenuItem>
        ))}
      </Menu>
 
      {visibilityStates.totalUsersCard?.visible && (
        <Draggable position={visibilityStates.totalUsersCard.position} onStop={(e, data) => handleDragStop(e, data, "totalUsersCard")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
          <div className="non-draggable" style={{ width: window.innerWidth < 850 ? "48%" : "100%", display: window.innerWidth < 850 ? "flex" : "", justifyContent: window.innerWidth < 850 ? "center" : "", alignItems: window.innerWidth < 850 ? "center" : ""}}>
            <Link style={{width:"100%"}} to="/manageuser">
              <ImageCard
                bgColor="#46A57F"
                altImg="Total Users"
                imgSrc={individual_user}
                cardName="Total Users"
                number={totalUsersCount}
                height="80px"
                width="100%"
              />
            </Link>
          </div>
        </Draggable>
      )}
 
      {visibilityStates.orgsCard?.visible && (
        <Draggable position={visibilityStates.orgsCard.position} onStop={(e, data) => handleDragStop(e, data, "orgsCard")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
          <div className="non-draggable" style={{ width: window.innerWidth < 850 ? "48%" : "100%", display: window.innerWidth < 850 ? "flex" : "", justifyContent: window.innerWidth < 850 ? "center" : "", alignItems: window.innerWidth < 850 ? "center" : ""}}>
            <Link style={{width:"100%"}}to="/manageorganization">
              <ImageCard
                bgColor="#4676A5"
                altImg="Organizations"
                imgSrc={registered_organization}
                cardName="Organizations"
                number={totalOrgCount}
                height="80px"
                width="100%"
              />
            </Link>
          </div>
        </Draggable>
      )}
    </div>
  </div>
 
   {visibilityStates.countryBreakdwon?.visible && (
    <Draggable position={visibilityStates.countryBreakdwon.position} onStop={(e, data) => handleDragStop(e, data, "countryBreakdwon")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
      <div className="non-draggable" style={{ width: window.innerWidth < 850 ? "100%" : "40%", marginTop: window.innerWidth < 850 ? "20px" : "",}}>
        {countryPieChartData ? (
          <PieChartComp
            chartHeading="Overall Organizations Country Breakdown (In Percentage)"
            pieChartData={countryPieChartData.map((data) => ({ ...data, radius: 50 }))}
            boxWidth="100%"
            boxHeight={window.innerWidth < 850 ? 400 : 290}
            // pieChartWidth={250}
            // pieChartHeight={150}
          />
        ) : (
          <Loader />
        )}
      </div>
    </Draggable>
  )}
 
  {visibilityStates.planBreakdwon?.visible && (
    <Draggable position={visibilityStates.planBreakdwon.position} onStop={(e, data) => handleDragStop(e, data, "planBreakdwon")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
      <div className="non-draggable" style={{ width: window.innerWidth < 850 ? "100%" : "40%", marginTop: window.innerWidth < 850 ? "20px" : "", height: "100%", }}>
        <PieChartComp
          chartHeading="Organization Plan Breakdown"
          pieChartData={planData}
          isSelectDropdownShown={true}
          pieChartSelectOptions={orgList.map((org) => ({
            label: org.orgName,
            value: org.orgName,
          }))}
          onSelectChange={handleOrgChange}
          boxWidth="100%"
          boxHeight={window.innerWidth < 850 ? 350 : 290}
          // pieChartWidth={250}
          // pieChartHeight={150}
        />
      </div>
    </Draggable>
  )}
</div>
{/* second div */}
<div style={{ display: "flex", width: "100%", justifyContent: "space-between", flexDirection: window.innerWidth < 850 ? "column" : "row" }}>
  {visibilityStates.orgUsersChart?.visible && (
    <Draggable position={visibilityStates.orgUsersChart.position} onStop={(e, data) => handleDragStop(e, data, "orgUsersChart")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
      <div className="non-draggable" style={{display:"flex",width: window.innerWidth < 850 ? "100%" : "49%", marginTop: window.innerWidth < 850 ? "20px" : ""}}>
        <SimpleBarChart
          data={orgUserData}
          xKey="orgName"
          barKey="numberOfUsers"
          chartHeading="Organization Users"
          xAxisLabel="Organizations"
          yAxisLabel="Organization Users Count"
          barColor="#8884d8"
          tooltipFormatter={(value) => `${value}`}
          boxHeight={window.innerWidth < 850 ? 400 : 330}
          // boxWidth="100%"
        />
      </div>
    </Draggable>
  )}
 
    {visibilityStates.revenueChart?.visible && (
      <Draggable position={visibilityStates.revenueChart.position} onStop={(e, data) => handleDragStop(e, data, "revenueChart")} cancel={window.innerWidth <=1440 ? ".non-draggable" : undefined}>
  <div className="non-draggable" style={{ width: window.innerWidth < 850 ? "100%" : "49%", marginTop: window.innerWidth < 850 ? "20px" : "", }}>
       
        <RevenueChart
          data={selectedChartType === "monthly" ? monthlyRevenueData : yearlyRevenueData}
          // boxWidth="100%"
          boxHeight={330}
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
        />
  </div>
      </Draggable>
    )}
</div>
</div>
    </>
  );
}

export default AdminDashboard;

import React from "react";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Translator_1  from "../Translator/Translator_1";
const TranslatorPage = (props) => {

  return (
    <React.Fragment>
      <SideNavBar>
         <Translator_1/>
      </SideNavBar>
    </React.Fragment>
  );
};

export default NetworkDetector(TranslatorPage);
import React from "react";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import { Plagiarism } from "../Plagiarism/Plagiarism";
import PlagiarismContextProvider from "../../contexts/PlagiarismContext";
import Plagiarism_1 from "../Plagiarism/Plagiarism_1";
const PlagiarismPage = (props) => {

  return (
    <React.Fragment>
      <SideNavBar>
        <PlagiarismContextProvider>
         <Plagiarism_1/>
       
        </PlagiarismContextProvider>
      </SideNavBar>
    </React.Fragment>
  );
};

export default NetworkDetector(PlagiarismPage);

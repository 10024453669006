import React from "react";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Modal, Button } from "antd";
import InputBoxMui from "./InputBoxMui";
import { StageComp } from "./StageComp";

export const WorkFlowComp = ({ open, toggle,handleWorkFlowOk,handleWorkFlowCancel }) => {

    const addStage = () => {

    }

    const handleSubmitWorkFlow = () => {

    }

    const stageCompData = (data) => {
        console.log("stageCompData", data)
    }

    const stageData = (data) => {
        console.log("stageData", data)

    }

    return (
        <>
            <Modal
                title="Create Workflow"
                // style={{ width: "65%" }}
                width={"60%"}
                open={open}
                onOk={handleWorkFlowOk}
                onCancel={handleWorkFlowCancel}
                // toggle={toggle}
                // style={{
                //     position: "absolute",
                //     left: "37%",
                //     top: "30%",
                //     width: "70%"
                // }}
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        <Button
                            className="btn btn-primary btn-sm"
                            type="primary"
                            onClick={handleSubmitWorkFlow}
                        >
                            Submit
                        </Button>,
                        <Button className="btn btn-secondary btn-sm" type="secondary" 
                        onClick={handleWorkFlowCancel}
                        >
                            Cancel
                        </Button>,
                    </div>
                ]}
            >
                {/* <ModalHeader
                    toggle={toggle}
                    className="text-center"
                >
                    Create Workflow
                </ModalHeader> */}
                {/* <ModalBody> */}
                <div
                >

                    {/* <div className="p-2">
                        <InputBoxMui
                            fullWidth
                            label="Workflow Name "
                        />
                    </div>

                    <div className="p-2">
                        <InputBoxMui
                            fullWidth
                            label="Description"
                        />
                    </div>

                    <div className="p-2">
                        <button className="btn btn-secondary"
                            onClick={addStage}
                        >
                            Add Stage
                        </button>
                    </div> */}

                    <div className="p-2 mt-2"
                        // style={{ border: "1px solid rgba(255,0,0,0.5)", borderRadius: "3px" }}
                    >
                        <StageComp
                            onStatusChange={stageCompData}
                            onStageCreated={stageData}
                        />
                    </div>

                    {/* <div className="row justify-content-end mt-4">
                        <div className="col-md-3 d-flex justify-content-end">
                            <button className="btn btn-primary"
                                onClick={handleSubmitWorkFlow}
                            >
                                Submit
                            </button>
                        </div>

                    </div> */}

                </div>
                {/* </ModalBody> */}
            </Modal>
        </>
    )
}
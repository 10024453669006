import React, { Component } from "react";
import Tab from "./Tabs";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import SearchBox from "./SearchBox";
import documentService from "../services/document";
import DocList from "./DocumentList"
import ReactTour from "./ReactTour";

const steps = [
  {
    selector: ".reportour-1",
    content: ()=>(
      <div>
        <b>document Tab :</b><br/>
        <p>
          Displays all document related metrics.
        </p>
      </div>
    ),
    position: "right",
  },
  {
    selector: ".reportour-2",
    content: ()=>(
      <div>
        <b>Payment Tab :</b><br/>
        <p>
          Displays all historical payment details of user. User can also download payment report.
        </p>
      </div>
    ),
    position: "right",
  },
  {
    selector: ".reportour-3",
    content: "Left side displays all the current documents.",
    position: "right",
  },
  {
    selector: ".reportour-4",
    content: "Right side displays the metric details about the document selected.",
    position: "left",
  },
  {
    selector: ".reportour-5",
    content: ()=>(
      <div>
        <b>Document Report</b><br/>
        <p>
          Display document details for the selected document.
        </p>
      </div>
    ),
    position: "right",
  },
  {
    selector: ".reportour-6",
    content: ()=>(
      <div>
        <b>Grammar Report</b><br/>
        <p>
          Displays various graphical visualization of grammatical metrics for the selected document.
        </p>
      </div>
    ),
    position: "right",
  },
  {
    selector: ".reportour-7",
    content: ()=>(
      <div>
        <b>Document Analyzer</b><br/>
        <p>
          Displays metrics like "word frequency, sentence length, person perspective words" for the selected document.
        </p>
      </div>
    ),
    position: "right",
  },
];

export default class Report extends Component {

  state={
    dashLink: "/dashboard",
    documents: [],
    filterdocuments: [],
    searchValue:"",
    reactTour: false,
  }

  handleSearch = e => {
    console.log(e.target.value)
    this.setState({
      searchValue: e.target.value
    })
    if (e.target.value === "") {
      this.setState({
        filterdocuments: this.state.documents
      });
    } else {
      let value = e.target.value;
      let documents = this.state.documents;

      let result1 = [];
      result1 = documents
      .filter(docName => {
        console.log(docName);
        if (
          docName.documentName.toLowerCase().search(value.toLowerCase()) !==
          -1
        ) {
          return docName;
        }
      });

      this.setState({ filterdocuments: result1 });
    }
  };

  render() {
    const {searchValue}= this.state;
    return (
      <div>
        <ReactTour
          steps={steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <BreadCrumb l1="Home" l2="Reports"   dashboardlink={this.state.dashLink} />

        <div className="row mb-4">
          <div className="col-md-11">
            <HeadingB text="Reports" />
          </div>

          <div className="col-md-1">
            <i
              className="fa fa-question-circle mt-2"
              aria-hidden="true"
              data-toggle="tooltip"
              title="Need Help ?"
              style={{
                cursor: "pointer",
                color: "dodgerblue",
                fontSize: "15px",
              }}
              onClick={() => this.setState({ reactTour: true })}
            ></i>
          </div>
          {/* <div className="col-md-6">
            <SearchBox
              placeholder="&#xF002; Search Name"
              name="searchQuery"              
              onSearch={this.handleSearch}
            />
          </div> */}
        </div>
{/* <DocList/> */}
        <Tab data={searchValue}/>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";


const SuggestionComp = ({ changedSentence, setState,setIsFeatureButton, setIsSuggestion,copiedText }) => {

    const handleAccept = () => {
        setIsSuggestion(true)
        setState({
            paraText: changedSentence?.changedWords,
            loading: false
        })
    }

    const handleIgnore = () => {
        setIsFeatureButton(false)
        setState({
            paraText: copiedText,
            loading: false
        })
    }
    useEffect(() => {
        console.log("changedSentence_", changedSentence)
    }, [changedSentence])

    return (
        <> 
        <div
            className="mt-5"
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "9px"
            }}
        >
            <div className="row">
                <div className="col-md-6"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        // color: "#EA1537",
                        fontSize: "0.8rem",
                        // textDecoration: "line-through",
                    }}
                >
                    {/* <p style={{color: "#EA1537", textDecoration: "line-through", display: "inline"}} >renoned</p> 
                    <p style={{color: "#000000", display: "inline", marginLeft: "4%"}} >renowned</p> */}
                </div>
                {/* <div className="col-md-4"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        color: "#000000",
                        fontSize: "0.8rem",
                    }}
                > 
                renowned
                </div> */}
                <div className="col-md-6 d-flex justify-content-end"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 300,
                        color: "#858585",
                        fontSize: "0.8rem",
                    }}
                >
                    {changedSentence?.heading}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        color: "#676767",
                        fontSize: "0.8rem",
                    }}
                >
                    {changedSentence?.changedWords}
                    
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <button className="btn btn-primary"
                        onClick={handleAccept}
                    >Accept</button>
                </div>
                <div className="col-md-3">
                    <button className="btn btn-outline primary"
                        onClick={handleIgnore}
                    >Ignore</button>
                </div>
            </div>
        </div>
        </>
    )
}
export default SuggestionComp
import React from "react";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import RefreshIcon from "@mui/icons-material/Refresh";
import FolderIcon from "@mui/icons-material/Folder";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from "react";
import { showToast } from "../../messages";
import {
  DocumentGridCard,
  DocumentListCard,
  WorkFlowGridCard,
} from "../MyDocumentsCards";
import { addWorkflow, callUseForWorkflow, createDuplicate, deleteworkflows, getSpecificWorkFlow, getworkflows, updateWorkflow, useForWorkflow, useWorkflow } from "../../services/workflowServices";
import { toast } from "react-toastify";
import { set } from "lodash";
import "../pages/workflow.css"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WorkflowVisual } from "./WorkflowVisual";
import Loader from "../../utils/loader";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const WorkflowComponent = () => {
  const [loading, setLoading] = useState(false);
  const [listView, setListView] = useState(false);
  const [myDocs, setMyDocs] = useState([]);
  const [DefaultWorkflow, setDefaultWorkflow] = useState([]);
  const [userEmail, setuserEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowName, setWorkflowName] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [documentId, setDocumentid] = useState('');
  const [orgId, setOrgid] = useState('');
  const [description, setDescription] = useState('')
  const [isAddmodal, setisAddmodal] = useState(false)
  const [isaddModalOpen, setisaddModalOpen] = useState(false)
  const [addWorkflowName, setAddWorkflowName] = useState()
  const [addWorkflowDescription, setAddWorkflowDescription] = useState('')
  const [orgIdFound, setOrgIdFound] = useState(true)
  const [specificWorkflowData, setSpecificWorkflowData] = useState([])
  const [displayWorkflowcreen, setDisplayWorkflowcreen] = useState(true)
  const [specificWorkflowDescription, setspecificWorkflowDescription] = useState('')
  const [backgroundColorCard, setbackgroundColorCard] = useState('')
  const [allowEditforSelectedWorkflow, setallowEditforSelectedWorkflow] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortOrder, setSortOrder] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let screenName = "Workflow Management";
  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }


  const history = useHistory()

  const handleAddModalOpen = () => {
    // alert("open")
    setAddWorkflowDescription('')
    setAddWorkflowName('')
    let featurename = "Add"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Organization.addWorkflow')
    } else {
      setisaddModalOpen(true)
    }


  }
  const handleAddModalClose = () => {
    setisaddModalOpen(false)
  }

  const getAllDocuments = () => {
    getDocs();
    // getDeletedDocs();
    // getSharedDocs();
  };

  useEffect(() => {
    
    fetchData();
  }, []);


  const fetchData = async () => {
    setLoading(true)
    try {
      const email = await sessionStorage.getItem("currentUserEmail");
      console.log("email__", email);
      if (email) {
        await setuserEmail(email);
      }
      await getDocs(email);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    setLoading(false)
  };


  const apiCall = () => {
    getAllDocuments();
  };
  const getDocs = async (email) => {
    setLoading(true)
    try {
      const response = await getworkflows(email);
      if (response.status === 200) {
        setMyDocs(response.data);
        setDefaultWorkflow(response?.defaultData)
        // setInitialMyDocs(response.data);
        setOrgid(response.orgId);
        console.log("response__", response);
      }
      else if (response.status === 201) {
        setMyDocs(response.data);
        setOrgIdFound(false)
      }
      else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  };

  // Individual Card Selection
  const handleMyDocsCardChange = (index) => {
    setMyDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[index].isChecked = !newDocuments[index].isChecked;
      return newDocuments;
    });
  };

  //   delete function workflow

  const handleDeleteworkflow = async (id, title, isAdmin) => {
    console.log("delete__id", id, isAdmin);
    setLoading(true);
    try {
      let featurename = "Delete";
      const checkfeatureName = accessFeatures?.filter(k => k.name == featurename);
      console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast('Organization.deleteWorkflow')
      } else {
        const email = sessionStorage.getItem("currentUserEmail")
        const response = await deleteworkflows(id, email, isAdmin);
        console.log("delete response_", response);
        if (response.status === 200) {
          getDocs(userEmail);
          toast.success(`${title} deleted successfully`);
          console.log("response__", response);
        } else if (response.status === 400 && response.message.includes("in use")) {
          showToast('Organization.workflowInUse')
        } else if (response.status === 404) {
          toast.error(`Workflow with ID ${id} not found`);
        } else {
          toast.error(`Error while deleting: ${response.message}`);
          console.error("Error while deleting:", response.message);
        }
      }
    } catch (error) {
      toast.error("Error calling backend API: " + error.message);
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false);
  };


  const handleOpenModal = (documentId, title, createdby, description) => {

    console.log("discription__", description)
    setCreatedBy(createdby)
    let featurename = "Update"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      
      showToast('Error.edit')
    } else {
      setIsModalOpen(true);
    }

    setDescription(description)
    setWorkflowName(title)
    setDocumentid(documentId)

  };

  const handleDuplicate = async (documentId) => {
    try {
      setLoading(true);
      const email = sessionStorage.getItem("currentUserEmail")
      const response = await createDuplicate(documentId, email)
      if (response.status == 200) {
        toast.success(response.message);
        getDocs(email);
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }
  const handleUseForWorkflow = async (documentId) => {
    const email = sessionStorage.getItem("currentUserEmail")
    const orgid = sessionStorage.getItem("organizationId")
    const response = await callUseForWorkflow(documentId, email, orgid)
    if (response.status == 200) {
      toast.success(response.message);
      getDocs(email);
    } else {
      toast.error(response.message)
    }
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setisAddmodal(false)
  };

  const handleEditFunction = async () => {
    setLoading(true)
    try {
      const response = await updateWorkflow(documentId, description, workflowName, userEmail)
      console.log("userEmail", userEmail);
      console.log("edit Respone__", response)
      if (response.status === 200) {
        getDocs(userEmail);
        handleCloseModal()
        showToast('Success.update')
        console.log("response__", response);
      }
      else if (response.status === 400) {
        toast.warn(response.message)
      }
      else {
        console.error("Error while updating:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  }

  const handleAddSaveWorkflow = async () => {

    if (!addWorkflowName || !addWorkflowDescription) {
      return showToast('Proposal.titleReq')
    }

    setLoading(true)
    try {
      const response = await addWorkflow(userEmail, addWorkflowName, addWorkflowDescription)
      console.log("response_", response)
      if (response.status === 201) {
        getDocs(userEmail);
        handleAddModalClose()
        showToast('Success.add')
        console.log("response__", response);
      } else if (response.status == 401) {
        toast.error(response.message)
      } else if (response.status == 409) {
        toast.warn(response.message)
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  }

  const handleGetworkflow = async (id, value) => {
    setLoading(true)

    console.log("workflow_Id_is-", id, value)
    setbackgroundColorCard(id)
    setDisplayWorkflowcreen(value)

    try {
      const response = await getSpecificWorkFlow(id);
      console.log("Selected Workflow response_", response)
      if (response.status === 200) {
        setSpecificWorkflowData(response.data)
        setspecificWorkflowDescription(response.data.description)
        console.log("Selected Workflow response__", response);

      } else {
        console.error("Error while finding Selected Workflow :", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  }

  const handleSpecificWorkflowDescription = (e) => {
    console.log("handleSpecificWorkflowDescription", e)
    setspecificWorkflowDescription(e)

    if (e !== specificWorkflowDescription) {

      updateWorkflowInfo();
    }

  }

  const handleEditSelectedWorkflow = () => {
    setallowEditforSelectedWorkflow(true)
  }


  const updateWorkflowInfo = async () => {
    setLoading(true)
    let workflowName = specificWorkflowData.workflowName
    let description = specificWorkflowDescription
    let documentId = backgroundColorCard
    try {
      const response = await updateWorkflow(documentId, description, workflowName, userEmail);
      console.log("userEmail", userEmail);
      console.log("edit Response:", response);
      if (response.status === 200) {
        getDocs(userEmail);
        // handleCloseModal();
        // toast.success(`Workflow updated successfully`);
        console.log("response:", response);
      } else if (response.status === 400) {
        toast.warn(response.message);
      } else {
        console.error("Error while updating:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  };

  const handlePreviousPage = () => {
    setDisplayWorkflowcreen(true)
    setSpecificWorkflowData([])
    setspecificWorkflowDescription('')
  }

  const iconColor = {
    color: "#3d83fa"
  }










  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const sortData = (data, key) => {
    return data.sort((a, b) => {
      const valueA = (a[key] || '').toString().toLowerCase();
      const valueB = (b[key] || '').toString().toLowerCase();
  
      return valueA.localeCompare(valueB);
    });
  };
  
  
  const handleSort = (key) => {
    
console.log("1111 ", DefaultWorkflow)
console.log("2222 ", myDocs)


    const sortedDefaultWorkflow = sortData([...DefaultWorkflow], key, true);
    const sortedMyDocs = sortData([...myDocs], key, true);
  
    if (!isSorted(DefaultWorkflow, key)) {
      setDefaultWorkflow(sortedDefaultWorkflow);
    }
  
    if (!isSorted(myDocs, key)) {

      setMyDocs(sortedMyDocs);
    }
    console.log("1111 ", sortedDefaultWorkflow)
    console.log("2222 ", sortedMyDocs)
    handleMenuClose(); // Close menu after selection
  };
  
useEffect(()=> {
  console.log("myyyy   ", myDocs)
}, [myDocs])


  // Helper function to check if the data is already sorted
  const isSorted = (data, key) => {
    for (let i = 0; i < data.length - 1; i++) {
      if (data[i][key].toString().toLowerCase() > data[i + 1][key].toString().toLowerCase()) {
        return false;
      }
    }
    return true;
  };
  

 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Loader loading={loading} />
      {displayWorkflowcreen &&
        <>
          <div
            style={{
              width: "100%",
              boxshadow: " 0px 0px 10px 5px black",
              height: "100%",
              backgroundColor: "white",
              padding: "10px",
              backgroundColor: "white"
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "right", width: "100%" }}
            >

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={()=> fetchData()}>
                  <RefreshIcon style={iconColor} />
                </IconButton>
                <IconButton onClick={() => { handleAddModalOpen() }}>
                  <CreateNewFolderIcon style={iconColor} />
                </IconButton>
             


                <IconButton onClick={handleMenuOpen}>
                  <div title="Sort">
                    <SortByAlphaIcon style={iconColor}  />
                  </div>
                </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleSort('workflowName')}>Sort by Title</MenuItem>
                <MenuItem onClick={() => handleSort('createdby')}>Sort by Created By</MenuItem>
                <MenuItem onClick={() => handleSort('createddate')}>Sort by Created Date</MenuItem>
              </Menu>

              </div>
            </div>
            <div>
              <h3 style={{ color: "#1976d2" }}>Default Workflow</h3>
            </div>
            {DefaultWorkflow.length > 0 && (
              <div className="row Default" >
                {DefaultWorkflow.map((document, index) => {
                  return (
                    // <div
                    //   className={`col-md-3 col-sm-6 col-12 mt-3`}
                    //   key={index}
                    //   style={{backgroundColor:"pink", marginLeft:"10px", display:"flex", justifyContent:"center", height:"100%"}}
                    // >

                    <div
                      className={`col-md-3 col-sm-6 col-12 mt-3 ${windowWidth < 770 ? 'col-xs-12' : ''}`}
                      key={index}
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        position: "relative"                      }}
                    >
                      <WorkFlowGridCard
                          workFlowId={handleGetworkflow}
                          title={document.workflowName}
                          orgIdFound={orgIdFound}
                          appname={document.appname}
                          createdby={document.createdby}
                          createddate={document.createddate}
                          deleteWorkflow={handleDeleteworkflow}
                          openeditWorkflowModal={handleOpenModal}
                          createDuplicate={handleDuplicate}
                          callUseForWorkflow={handleUseForWorkflow}
                          closeModal={handleCloseModal}
                          description={document.description}
                          cardChecked={document.isChecked || false}
                          documentId={document._id}
                          onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                          getAllDocuments={getAllDocuments}
                          copy={true}
                          isActive={document?.isactive}
                          type={document?.type}
                          isAdmin={document?.isAdmin}
                          windowWidth={windowWidth}
                        />
                    </div>
                  );
                })}
              </div>
            )}
            <div>
              <h3 style={{ color: "#1976d2" }}>Org Workflow</h3>
              {myDocs.length > 0 && (
                <div className="row Default">
                  {myDocs.map((document, index) => {
                    return (
                      <div
                        // className={listView ? "col-md-12" : "col-md-3 mt-3"}
                        // key={index}
                      // style={{backgroundColor:document?.isactive==1 ? "lightseagreen":""}}
                     
                      className={`col-md-3 col-sm-6 col-12 mt-3}`}
                      key={index}
                      style={{
                        width:"50vw",
                        margin: "10px",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        position: "relative"                      }}
                     >
                         <WorkFlowGridCard
                            workFlowId={handleGetworkflow}
                            title={document.workflowName}
                            orgIdFound={orgIdFound}
                            appname={document.appname}
                            createdby={document.createdby}
                            createddate={document.createddate}
                            deleteWorkflow={handleDeleteworkflow}
                            openeditWorkflowModal={handleOpenModal}
                            closeModal={handleCloseModal}
                            description={document.description}
                            cardChecked={document.isChecked || false}
                            documentId={document._id}
                            onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                            getAllDocuments={getAllDocuments}
                            isActive={document?.isactive}
                            type={document?.type}
                            isAdmin={document?.isAdmin}
                          />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <Modal open={isModalOpen} onClose={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px' }}>
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', minWidth:windowWidth < 420 ? "250px": '350px', minHeight: windowWidth < 420 ? "200px": "300px"}}>
              <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div >
                  <h3 style={{ color: "#3d83fa" }}>Update Workflow</h3>
                </div>
                <div >
                  <IconButton onClick={handleCloseModal} >
                    <HighlightOffIcon style={{ color: "red" }} />
                  </IconButton>
                </div>
              </div>


              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <TextField
                  style={{ borderRadius: "15px", width: "auto" }}
                  label="Workflow Name"
                  placeholder="Enter Workflow Name"
                  value={workflowName}
                  onChange={(e) => setWorkflowName(e.target.value)}
                  variant="outlined"
                  fullWidth
                />

                <TextField
                  style={{ marginBottom: '10px', width: "auto" }}
                  placeholder="Enter Description"
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
                <Button
                  variant='outlined'
                  style={{
                    fontSize: '12px',
                    marginRight: '10px',
                    borderRadius: '10px',
                    color: 'white',
                    borderColor: '#1976D2',
                    textTransform: 'capitalize',
                    boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)',
                    backgroundColor: "#1976D2",
                    marginRight: "15px"
                  }}
                  onClick={handleEditFunction}
                >
                  Save
                </Button>

                <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: 'red', borderColor: 'red', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleCloseModal}>
                  Cancel
                </Button>
              </div>

            </div>
          </Modal>




          {/* ///////////////////////////////////Add Workflow Modal /////////////////////*/}
          {/* <Modal open={isaddModalOpen} onClose={handleAddModalClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px', backgroundColor:"violet" }}>
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', minWidth: '350px', minHeight: "300px" }}> */}
       
       <Modal 
  open={isaddModalOpen} 
  onClose={handleAddModalClose} 
  style={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    borderRadius: '10px', 
  }}
>
  <div style={{ 
    backgroundColor: 'white', 
    padding: '20px', 
    borderRadius: '5px', 
    maxWidth: '90%', 
    minWidth: '300px', // Set a minimum width
    minHeight: "300px" 
  }}>
       
              <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div >
                  <h3  style={{ color: "#3d83fa" }}>Add New Workflow</h3>
                </div>
                <div >
                  <IconButton onClick={handleAddModalClose} >
                    <HighlightOffIcon style={{color: "red"}} />
                  </IconButton>
                </div>
              </div>


              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <TextField
                  style={{ borderRadius: "15px", width: "auto" }}
                  label="Workflow Name"
                  placeholder="Enter Workflow Name"
                  value={addWorkflowName}
                  onChange={(e) => setAddWorkflowName(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  style={{ marginBottom: '10px' }}
                  label="Description"
                  placeholder="Enter Description"
                  value={addWorkflowDescription}
                  onChange={(e) => setAddWorkflowDescription(e.target.value)}
                  multiline
                  rows={4}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      width: "400px"
                    }
                  }}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
              <Button
                variant='outlined'
                style={{
                  fontSize: '12px',
                    marginRight: '10px',
                    borderRadius: '10px',
                    color: 'white',
                    borderColor: '#1976D2',
                    textTransform: 'capitalize',
                    boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)',
                    backgroundColor: "#1976D2",
                    marginRight: "15px"
                }}
                disabled={!orgIdFound}
                onClick={handleAddSaveWorkflow}
              >
                Save
              </Button>
              <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: 'red', borderColor: 'red', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleAddModalClose}>
                Cancel
              </Button>
              </div>
            </div>
          </Modal>
        </>
      }

      {!displayWorkflowcreen &&
        <>
          <div>
            <div className="row" style={{ backgroundColor: "white", padding: "10px" }}>
              <div className="col-md-12">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreviousPage}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <WorkflowVisual workflowId={backgroundColorCard} workflowName={specificWorkflowData.workflowName} handlDescription={handleSpecificWorkflowDescription} InputValue={specificWorkflowDescription} allowEdit={allowEditforSelectedWorkflow} allowEditFunction={handleEditSelectedWorkflow} />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

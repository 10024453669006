import React, { Component } from "react";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";
import Citationproj from "../Forms/Citationproj";

const CitationProjPage = (props) => {
  let id = null;
  let value = null;

  // if( props.history.location.data){
  //   id=  props.history.location.data.projectID
  // }
  if (props.location.state) {
    id = props.location.state.projectID;
    console.log(props);
    if (props.location.state.initialValues) {
      value = props.location.state;
    }
  }
  return (
    <SideNavBar>
      <Citationproj prjID={id} value={value} />
    </SideNavBar>
  );
};

export default withRouter(NetworkDetector(CitationProjPage));

import React, { Component } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import CitationStyles from "./FormComponents/Dropdown_materialUI";
import CitationTable from "./FormComponents/Table_MaterialUI";
import Typography from "@material-ui/core/Typography";
import citationService from "../../services/citationService";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../utils/loader";
import { Button } from "reactstrap";
import { convertNodeToElement } from "react-html-parser";
import ReactTour from "../ReactTour";

class Citation extends Component {
  state = {
    citations: {},
    style: "MLA",
    cites: [],
    citationsObject: {},
    loading: true,
    projectId: "",
    reactTour: false,
    steps: [
      {
        selector: '.reactour-1',
        content: 'Create New Citation over here!',
        position: 'bottom',
      },
      {
        selector: '.reactour-2',
        content: 'After creating citation we can change view style of citations',
        position: 'bottom',
      },
    ],
  };

  async componentDidMount() {
    const response = await citationService.getMyCitations(
      this.props.location.state.projectId
    );
    // console.log(this.props.location.state.projectId);
    this.setState({
      citations: response,
      citationsObject: response.citationObject,
      cites: [],
      loading: false,
      projectId: this.props.location.state.projectId,
    });
  }

  handleGetCitations = async () => {
    this.setState({ isItemSelected: false, loading: true });
    const response = await citationService.getMyCitations(this.state.projectId);
    this.setState({
      citations: response,
      citationsObject: response.citationObject,
      loading: false,
    });
  };

  citateStyle = async (value) => {
    const { cites, citationsObject, citations } = this.state;
    this.setState({ loading: true });
    let data = {};
    const { response, status } = await citationService.changeCitationStyle({
      style: value,
      data: { ...citationsObject },
    });
    if (status === 200) {
      citations.response.style = response;
      this.setState({ citations, style: value, loading: false });
    }
  };

  selected = (citates) => {
    this.setState({
      cites: citates,
    });
  };

  handleEdit = (id) => {
    const { history } = this.props;
    const { citationsObject } = this.state;
    this.setState({ loading: true });
    let initialValues = { ...citationsObject[id] };
    history.push({
      pathname: `/citationproj`,
      state: {
        initialValues,
        formtype: initialValues["sub-type"],
        projectID: this.state.projectId,
      },
      // data: { projectID: this.state.projectId },
    });
    this.setState({ loading: false });
    // take the values to the respective form based the type
  };
  handleUrl = (id) => {
    console.log(id);
    const { history } = this.props;
    const { citationsObject } = this.state;
    let initialValues = { ...citationsObject[id] };
    let link = initialValues.URL;
    if (link) {
      window.open(link, "_blank");
    } else {
      toast.info("No URL to visit!");
    }
  };
  handleDelete = (id) => {
    const { citationsObject } = this.state;
    this.setState({ loading: true });
    let citations = { ...citationsObject[id] };

    swal({
      title: "Delete Citation",
      text: "Are you sure ? You want to delete this citation.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await citationService.deleteMyCitations(
          citations._id,
          this.props.prjId
        );
        if (response.status === 200) {
          toast.info("Citation Deleted Successfully");
          this.handleGetCitations();
        } else {
          toast.error("Oops..Something went wrong!!");
        }
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    this.setState({ loading: false });
  };

  createCitation = () => {
    const { history } = this.props;
    history.push({
      pathname: `/citationproj`,
      state: { projectID: this.state.projectId },
    });
  };

  render() {
    const { citations, style } = this.state;
    return (
      <div>
        <Loader loading={this.state.loading} />

        <BreadCrumb
          l1="Home"
          l2="Citations"
          l3="MyCitations"
          dashboardlink={"/dashboard"}
          documentLink={"/citation"}
        />
        <ToastContainer />
        <Typography
          className="pb-1"
          variant="h6"
          id="tableTitle"
          component="div"
          // onClick={this.changeStyle}
        >
          My Citation List
        </Typography>
        <div className="pb-4">
          <div className="row">
            <div className="col-md-3 reactour-2">
              <CitationStyles handlestyle={this.citateStyle} />
            </div>
            <div className="col-md-5"></div>
            <div className="col-md-3">
              <Button
                className="reactour-1"
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={this.createCitation}
              >
                Create New Citation
              </Button>
            </div>
            <div className="col-md-1"> 
              <i 
                className="fa fa-question-circle mt-2" 
                aria-hidden="true"
                data-toggle="tooltip"
                title="Need Help ?"
                style={{
                  cursor: "pointer",
                  color: "dodgerblue",
                  fontSize: "15px"
                }}
                onClick={()=>this.setState({ reactTour: true })}
              ></i>
            </div>
            {/* <div className="col-md-6 text-center">
              <h3 style={{marginTop: "3%", fontSize: "15px", lineHeight: "18px"}}>Citation Style: {style.toUpperCase()}</h3>
            </div> */}
          </div>
        </div>
        <div className="container-fluid bg-white ">
          <div className="container p-4">
            <Typography
              className="p-2"
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {this.state.style.toUpperCase()}
            </Typography>
            <CitationTable
              citations={citations}
              selectedCitations={this.selected}
              edit={this.handleEdit}
              deleteCite={this.handleDelete}
              openUrl={this.handleUrl}
              isItemSelected
            />
            <div className="row">
              <Typography
                style={{ flex: 1, textAlign: "center" }}
                className="p-2"
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Check your citations
              </Typography>
            </div>
            <div className="row">
              <Typography
                style={{ flex: 1, textAlign: "center" }}
                className=""
                variant="h8"
                id="tableTitle"
                component="div"
              >
                Make sure you didn't forget a source or cite incorrectly{" "}
              </Typography>
            </div>
          </div>
        </div>
        <ReactTour 
          steps={this.state.steps} 
          isOpen={this.state.reactTour} 
          closeTour={()=>this.setState({ reactTour: false })}
        />
      </div>
    );
  }
}

export default withRouter(Citation);

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import html2canvas from 'html2canvas';
import Loader from "../utils/loader";
import jsPDF from 'jspdf';
import { List, ListItem, ListItemText } from "@material-ui/core";
import opportunityService, {
  fetchViewChanges,
  getFile,
} from "../services/opportunityService";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from 'axios'
import DownloadIcon from "@mui/icons-material/Download";
import { MdArrowBack } from "react-icons/md";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import HistoryBar from "./ReusableComponents/HistoryBar";
import { Button } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PreviewIcon from "@mui/icons-material/Preview";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import html2pdf from 'html2pdf.js';
import { FaDownload } from "react-icons/fa";
import {Document, Packer,  Paragraph , TextRun} from "docx";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import 'jspdf-autotable';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "../messages";




const SingleNoticeInfo = () => {
  const history = useHistory();
  const location = useLocation();
  const generalInfoRef = useRef(null);
  const classificationRef = useRef(null);
  const awardRef = useRef(null);
  const descriptionRef = useRef(null);
  const attachmentsRef = useRef(null);
  const contactInfoRef = useRef(null);
  const historyRef = useRef(null);
  const contractOpportunityInfoRef = useRef(null);
  const [documentData, setDocumentData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [redirectingData, setRedirectingData] = useState(null);
  const [isLeftSectionOpen, setIsLeftSectionOpen] = useState(true);
  const [loading, setLoading] = useState(true); // Initial loading state
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [viewChanges, setViewChanges] = useState([]);
  const [viewOtherChanges, setViewOtherChanges] = useState([]);
  const [viewState, setViewState] = useState(false);
  const [comparedDescription,setComparedDescription]=useState();
  const [orderOpportunity,setorderOpportunity]=useState();
  const { documentId, showPastOpportunities } = location.state;



  const [isMobileView, setIsMobileView] = useState(false);

  
  const handleResize = () => {
    if (window.innerWidth <= 500) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Check the screen size on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log(location.state,"Locaitoncc")
  const toggleTableBody = () => {
    setIsOpen(!isOpen);
  };
  let Department = documentData?.data?.fullParentPathName;

  let splitDepartment;
  let first_value;
  let second_value;
  let third_value;
  let fourth_value;
  let fifth_value;
  let sixth_value;
  let last_value;

  if (Department) {
    splitDepartment = Department.split(".");

    last_value = splitDepartment[splitDepartment.length - 1];
    console.log(last_value, "last_value");
    first_value = splitDepartment[0];
    second_value = splitDepartment[1];
    third_value = splitDepartment[2];
    fourth_value = splitDepartment[3];
    fifth_value = splitDepartment[4];
    sixth_value = splitDepartment[5];
  }

  const checkOrder=async()=>{
    try {
      const data=documentData?.data?.solicitationNumber;
      const response=await opportunityService?.checkOpportunityOrder(data);
      console.log(response?.data[0]?.noticeId,"orderResponse");
      setorderOpportunity(response.data[0])
      console.log(orderOpportunity,"order")
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = documentId;
        const response = await opportunityService?.getopportunities(data,showPastOpportunities);
        console.log(response,"__response")
        setDocumentData(response);
      } catch (error) {
        console.log("Error fetching document data:", error);
      }finally{
        setLoading(false);
      }
    };

    fetchData();
    // Clean up function
    return () => {
      // Any cleanup code if needed
    };
  }, [documentId]);

  const fetchHistory = async () => {
    try {
      const data = documentData?.data?.solicitationNumber;
      const response = await opportunityService.fetchHistory(data);
      console.log(response.response, "Redirectresponse");
      setRedirectingData(response?.response);
      setHistoryData(response.hisdata);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching document data:", error);
    }
  };


  console.log(historyData, "historyData");
  useEffect(() => {
    fetchHistory();
  checkOrder();

    
  }, [documentData]);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetTop = ref.current.offsetTop - 74;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const toggleLeftSection = () => {
    setIsLeftSectionOpen(!isLeftSectionOpen);
  };

  console.log(documentData, "documentDatadocumentData");
  console.log(documentData?.data?.resourceLinks, "vrushabhdata");
  console.log(
    typeof documentData?.data?.postedDate,
    "documentData?.data?.postedDate"
  );
  const dateString = documentData?.data?.postedDate;
  const dateString1 = documentData?.data?.responseDeadLine;
  const dateString2 = documentData?.data?.archiveDate;
  let dateString3;
  if (documentData?.data?.type == "Award Notice") {
    dateString3 = documentData?.data?.award[0].date;
  }

  // Convert string to Date object
  const date = new Date(dateString);

  // Format date
  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });
  const date1 = new Date(dateString1);
  const formattedDate1 = date1.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });
  const date2 = new Date(dateString2);
  const formattedDate2 = date2.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });
  let formattedDate3;
  if (documentData?.data?.type == "Award Notice") {
    const date3 = new Date(dateString3);
    formattedDate3 = date3.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });
  }
  let updatedPostedDate;
  if (viewChanges?.postedDate) {
    console.log(viewChanges?.postedDate, "datepost");
    const date3 = new Date(viewChanges?.postedDate);
    updatedPostedDate = date3.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });
  }
  let updatedResponse;
  if (viewChanges?.postedDate) {
    console.log(viewChanges?.responseDeadLine, "datepost");
    const date3 = new Date(viewChanges?.responseDeadLine);
    updatedResponse = date3.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });
  }
  let updatedInactive;
  if (viewChanges?.archiveDate) {
    console.log(viewChanges?.archiveDate, "datepost");
    const date3 = new Date(viewChanges?.responseDeadLine);
    updatedInactive = date3.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });
  }

  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of the selected item
  // const [selectedItemStyle, setSelectedItemStyle] = useState(null);

  const handleItemClick = (ref) => {
    scrollToRef(ref); // Function to scroll to the selected section
    setSelectedItem(ref); // Set the selected item
    // setSelectedItemStyle(ref);
  };

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (
      documentData &&
      documentData.data &&
      documentData?.data?.resourceLinks
    ) {
      setAttachments(documentData?.data?.resourceLinksPath);
    }
  }, [documentData]);

  // const handleDownloadAll = () => {
  //   // Function to open attachment links sequentially
  //   const openAttachmentsSequentially = (index) => {
  //     if (index >= attachments.length) return; // Exit if index exceeds the length

  //     // Open the attachment URL in a new window or tab
  //     window.open(attachments[index], "_blank");

  //     // Open the next attachment after a short delay
  //     setTimeout(() => {
  //       openAttachmentsSequentially(index + 1);
  //     }, 500); // Adjust the delay as needed (in milliseconds)
  //   };

  //   openAttachmentsSequentially(0); // Start opening attachments sequentially from index 0
  // };





  const handleDownloadAll = async (attachments) => {
    try {
      console.log(attachments,"atttttachments")
      const id = documentData?.data?._id;
      if (!id) {
        throw new Error("Document ID is not available.");
      }
  
      // Loop through each attachment and trigger the download
      for (const attachment of attachments) {
        const fileName = attachment.linkname; // Extract the filename from the attachment object
  let clause;
  console.log(fileName,"hhhhhhhhhhhhhhh")
        const response = await axios.get(`https://api.contractpro360.com/api/opportunities/getFile/${id}/${clause}/${fileName}`, {
          responseType: 'blob' // Set responseType to 'blob' to handle binary data
        });
  
        if (response.status !== 200) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
  
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
        // Create a temporary URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
  
        // Trigger a click event on the link to initiate the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        // Cleanup: revoke the temporary URL
        window.URL.revokeObjectURL(url);
      }
  
      // Display success message after all files are downloaded
      showToast('Success.allFilesDownload')
    } catch (error) {
      console.error("Error downloading files:", error);
      showToast('Error.errorOccured')
    }
  }
  


  const handleViewChanges = () => {
    setViewState((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (viewState) {
          const data = documentData?.data?.solicitationNumber;
          const response = await fetchViewChanges(data);
          if(response.insufficientRecord){
            return showToast('Error.recordComparison')
          }
          console.log(response, "viewChangeResponse");
          setViewChanges(response?.data?._doc?.oldValue);
          setViewOtherChanges(response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
   
    // Cleanup function to prevent unnecessary calls
    return () => {};
  }, [viewState]);

  let oldDescription=viewOtherChanges?.data?._doc?.oldValue?.descriptionData
  let newDescription=viewOtherChanges?.data?._doc?.newValue?.descriptionData
 
  useEffect(() => {
    if (oldDescription && newDescription) {
        function compareDescriptions() {
            const oldWords = oldDescription.split(/\s+/);
            const newWords = newDescription.split(/\s+/);

            let comparedWords = '';

            for (let i = 0; i < Math.max(oldWords.length, newWords.length); i++) {
              if (oldWords[i] !== newWords[i]) {
                  comparedWords += `${oldWords[i]}<span><b style="color:red;">${newWords[i] || ''}</b><span> `;
              } else {
                  comparedWords += `${oldWords[i]} `;
              }
          }

            return comparedWords.trim();
        }

        const comparedDescription = compareDescriptions();
        setComparedDescription(comparedDescription);
    }
}, [oldDescription, newDescription]);




  

  const handleButtonClick = () => {
    if (JSON.stringify(documentData?.data?.noticeId) === JSON.stringify(orderOpportunity?.noticeId)) {
      handleViewChanges();
    } else {
      // history.push(`/singleNoticePage/${orderOpportunity._id}`);
      history.push({
        pathname: '/singleNoticePage',
        state: { documentId: orderOpportunity._id },
      });
    }
  };


  const DownloadAttachments = async (fileName) => {
    try {
      const id = documentData?.data?._id;
      if (!id) {
        throw new Error("Document ID is not available.");
      }
      let clause;
  
      const response = await axios.get(`https://api.contractpro360.com/api/opportunities/getFile/${id}/${clause}/${fileName}`, {
        responseType: 'blob' // Set responseType to 'blob' to handle binary data
      });
  
      if (response.status !== 200) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
  
      // Trigger a click event on the link to initiate the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Cleanup: revoke the temporary URL
      window.URL.revokeObjectURL(url);
  
      // Display success message
      showToast('Success.fileDownload')
    } catch (error) {
      console.error("Error downloading file:", error);
      showToast('Error.errorOccured')
    }
  }
  const contentRef = useRef();




  // const downloadPdf = () => {
  //   const element = contentRef.current;

  //   // Temporarily hide elements that should not be included in the PDF
  // const elementsToHide = document.querySelectorAll('.back-button, .download-icon, .preview-icon');
  // elementsToHide.forEach(el => el.style.display = 'none');

  //   html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/jpeg');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = 210; // A4 width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pdfHeight;

  //     while (heightLeft >= 0) {
  //       position -= pdfHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pdfHeight;
  //     }

  //     pdf.save('document.pdf');
  //   });

  // };

  
  const downloadPdf = () => {
    const element = contentRef.current;
  
    if (!element) {
      console.error("Content element is not available.");
      return;
    }
  
    // Temporarily hide elements that should not be included in the PDF
    const elementsToHide = Array.from(document.querySelectorAll('.back-button, .download-icon, .preview-icon'));
    
    // Store the original display styles
    const originalStyles = elementsToHide.map(el => ({
      el,
      display: el.style.display,
    }));
    
    // Hide elements
    elementsToHide.forEach(el => {
      el.style.display = 'none'; // Use display none to fully hide the element
    });
  
    html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      let heightLeft = imgHeight;
      let position = 0;
  
      // Add the main content to the PDF
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
  
      while (heightLeft >= 0) {
        position -= pdfHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }
  
      // Check if there are attachments and add a table
      if (attachments && attachments.length > 0) {
        pdf.addPage();
        pdf.text("Attachments", 10, 10);
  
        // Define table headers
        const headers = ["File Name"];
        // const rows = attachments.map((attachment, index) => {
        //   const fileNameWithoutExtension = attachment?.linkname.split('.')[0];
        //   return [fileNameWithoutExtension];
        // });
        const rows = attachments.map((attachment, index) => [attachment?.linkname]);
  
        // Define the table columns width
        const columnWidths = [pdf.internal.pageSize.getWidth() - 20]; // Adjust as needed
  
        // Add table to PDF
        pdf.autoTable({
          startY: 20,
          head: [headers],
          body: rows,
          columnStyles: { 0: { cellWidth: columnWidths[0] } },
          margin: { top: 20, left: 10 },
        });
      }
  
      pdf.save('document.pdf');
    }).catch(error => {
      console.error('Error generating PDF:', error);
    }).finally(() => {
      // Restore the hidden elements
      originalStyles.forEach(({ el, display }) => {
        el.style.display = display;
      });
    });
  };
  
  
  
  return (
    <>

<Loader loading={loading} />






      {documentData && (
        <>
          <Grid container spacing={2} style={{ marginLeft:isMobileView ? "-10px" : "-30px", border:"1px solid #ccc",borderRadius:isMobileView ? "5px" : ""}}>
            {/* Left Section */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                position: { xs: "relative", md: "sticky" },
                top: { xs: "auto", md: "65px" },
                maxHeight: { xs: "initial", md: "calc(100vh - 74px)" },
                overflowY: "auto",
                display: isLeftSectionOpen ? "block" : "none",
                "&::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar for Webkit (Chrome, Safari, etc.)
                },
                "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                scrollbarWidth: "none", // Hide scrollbar for Firefox
                ".Mui-selected": { border: "3px solid #4682B4" },
              }}
            >
              <Paper
                elevation={3}
                style={{ padding: "1rem", marginBottom: "1rem",border:"2px solid black" }}
              >
                <img
                  style={{ width: "75%", height: "45%", marginLeft: "12%" }}
                  // src="/noticeView.png"
                  src="/logonot.png"
                  alt="Notice Board"
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ height: "32px", borderLeft: "5px solid #4682B4" }}
                >
                  <strong
                    onClick={() => scrollToRef(contractOpportunityInfoRef)}
                  >
                    <h2
                      style={{
                        marginLeft: "13px",
                        marginTop: "8px",
                        fontWeight: "bolder",
                      }}
                    >
                      Contract Opportunity
                    </h2>
                  </strong>
                </Typography>
                <List>
                  {documentData?.data?.type === "Award Notice" && (
                    <ListItem
                      sx={{ height: "15px" }}
                      button
                      selected={selectedItem === awardRef}
                      onClick={() => handleItemClick(awardRef)}
                    >
                      <ListItemText primary="Award Details" />
                    </ListItem>
                  )}
                  <ListItem
                    sx={{ height: "15px" }}
                    button
                    selected={selectedItem === generalInfoRef}
                    onClick={() => handleItemClick(generalInfoRef)}
                  >
                    <ListItemText primary="General Information" />
                  </ListItem>
                  <ListItem
                    sx={{ height: "15px" }}
                    button
                    selected={selectedItem === classificationRef}
                    onClick={() => handleItemClick(classificationRef)}
                  >
                    <ListItemText primary="Classification" />
                  </ListItem>
                  {!documentData?.data?.descriptionData ? (
                    <></>
                  ) : (
                    <>
                      <ListItem
                        sx={{ height: "15px" }}
                        button
                        selected={selectedItem === descriptionRef}
                        onClick={() => handleItemClick(descriptionRef)}
                      >
                        <ListItemText primary="Description" />
                      </ListItem>
                    </>
                  )}

                  <ListItem
                    sx={{ height: "15px" }}
                    button
                    selected={selectedItem === attachmentsRef}
                    onClick={() => handleItemClick(attachmentsRef)}
                  >
                    <ListItemText primary="Attachments/Links" />
                  </ListItem>
                  <ListItem
                    sx={{ height: "15px" }}
                    button
                    selected={selectedItem === contactInfoRef}
                    onClick={() => handleItemClick(contactInfoRef)}
                  >
                    <ListItemText primary="Contact Information" />
                  </ListItem>
                  <ListItem
                    sx={{ height: "15px" }}
                    button
                    selected={selectedItem === historyRef}
                    onClick={() => handleItemClick(historyRef)}
                  >
                    <ListItemText primary="History" />
                  </ListItem>
                </List>
              </Paper>
              {/* Toggle Button */}
              <IconButton
                style={{
                  position: "absolute",
                  right: "-2px",
                  top: "6%",
                  color: "red",
                  transform: "translateY(-50%)",
                }}
                onClick={toggleLeftSection}
              >
                {isLeftSectionOpen ? (
                  <>
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src="/image (6).png"
                    />
                  </>
                ) : (
                  <></>
                )}
              </IconButton>
            </Grid>

            {/* Right Section */}  
            <Grid item xs={12} md={isLeftSectionOpen ? 8 : 12} id="pdfContent"  ref={contentRef}  >
             
              {/* <h2>{documentData?.data?.title}</h2> */}
              <Paper
                elevation={3}
                style={{ padding: "1rem", marginBottom: "1rem", backgroundColor:"red" }}
              >
                {/* Contract Opportunity Info */}
                <Typography >
                  <div>
                    {!isLeftSectionOpen && (
                      <IconButton
                        style={{
                          top: 0,
                        }}
                        onClick={toggleLeftSection}
                      >
                        <img
                          style={{
                            marginLeft: "-9px",
                            marginTop: "-6px",
                            width: "30px",
                            height: "30px",
                          }}
                          src="/image (5).png"
                        />
                      </IconButton>
                    )}
                  </div>
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                > 
                  <div
                    style={{
                      width: "85%",
                      display: "flex",
                      justifyContent: "flex-start",
                      top: 0,
                    }}
                  >
                    <h2 style={{ marginTop: "3px", marginBottom: "5px" }}>
                      {documentData?.data?.title}
                    </h2>
                  </div>

                  <div style={{ width: "15%" }}>
                    {documentData?.data?.active === "Yes" ? (
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "9px",
                        backgroundColor: "green",
                        marginTop: "0px", // Align with center
                        marginRight: "6px", // Add some spacing
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bolder",
                        margin: "0px", // Remove default margin
                        marginLeft: "6px", // Add some spacing
                      }}
                    >
                      Active
                    </p>
                  
                    <IconButton
                      style={{ marginLeft: "6px" }} // Add some spacing
                      onClick={downloadPdf}
                      data-tip
                      data-for="download"
                      className="download-icon"
                    >
                      <ReactTooltip id="download" place="bottom" effect="solid">
                        Download
                      </ReactTooltip>
                      <DownloadIcon />
                    </IconButton>
                  </div>
                  
                      
                    ) : (
                      <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "9px",
                          backgroundColor: "red",
                          marginRight: "6px", // Add some spacing
                        }}
                      ></div>
                      <p
                        style={{
                          margin: "0px", // Remove default margin
                          marginLeft: "6px", // Add some spacing
                        }}
                      >
                        Inactive
                      </p>
                    
                      <IconButton
                        style={{ marginLeft: "6px" }} // Add some spacing
                        onClick={downloadPdf}
                        data-tip
                        data-for="download1"
                      >
                        <ReactTooltip id="download1" place="bottom" effect="solid">
                          Download
                        </ReactTooltip>
                        <DownloadIcon />
                      </IconButton>
                    </div>
                    
                      
                    )}
                    
                  </div>
                  <div>
                 
                  {orderOpportunity && (
  <IconButton
    // onClick={JSON.stringify(documentData?.data?.noticeId) === JSON.stringify(orderOpportunity?.noticeId) ? handleViewChanges : fetchHistory}
    onClick={handleButtonClick}
    title={JSON.stringify(documentData?.data?.noticeId) === JSON.stringify(orderOpportunity?.noticeId) ? "View Changes" : "Click here to view latest view of this opportunity"}
    style={{ color: "blue", fontSize: "20px" }}
    className="preview-icon"
  >
    <PreviewIcon />
  </IconButton>
)}

                  
                  </div>
                </Typography>
                <Typography
                  sx={{ borderBottom: "1px solid lightgray",marginTop:"50px" }}
                  variant="h6"
                  gutterBottom
                  ref={contractOpportunityInfoRef}
                >
                  <h4 style={{ color: "blue" }}>
                    Notice ID: {documentData?.data?.solicitationNumber}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "13px",
                      marginBottom: "20px",
                    }}
                  >
                    {first_value !== last_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>Department/Ind. Agency :</h5>
                        <p style={{ marginLeft: "3px" }}>{first_value}</p>
                      </div>
                    )}
                    {second_value !== last_value && second_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>Sub-tier :</h5>
                        <p style={{ marginLeft: "3px" }}>{second_value}</p>
                      </div>
                    )}
                    {third_value !== last_value && third_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>MAJOR Command :</h5>
                        <p style={{ marginLeft: "3px" }}>{third_value}</p>
                      </div>
                    )}
                    {fourth_value !== last_value && fourth_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>Sub Command :</h5>
                        <p style={{ marginLeft: "3px" }}>{fourth_value}</p>
                      </div>
                    )}
                    {fifth_value !== last_value && fifth_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>Sub Command 2 :</h5>
                        <p style={{ marginLeft: "3px" }}>{fifth_value}</p>
                      </div>
                    )}
                    {sixth_value !== last_value && sixth_value && (
                      <div style={{ display: "flex", height: "12%" }}>
                        <h5>Sub Command 3 :</h5>
                        <p style={{ marginLeft: "3px" }}>{sixth_value}</p>
                      </div>
                    )}

                    <div style={{ display: "flex", height: "12%" }}>
                      {!last_value ? (
                        <></>
                      ) : (
                        <>
                          <h5>Office :</h5>{" "}
                          <p style={{ marginLeft: "3px" }}>{last_value}</p>
                        </>
                      )}{" "}
                    </div>
                  </div>
                </Typography>

                {documentData?.data?.type == "Award Notice" && (
                  <Typography
                    sx={{
                      borderBottom: "1px solid lightgray",
                      marginTop: "25px",
                    }}
                    variant="h6"
                    gutterBottom
                    ref={awardRef}
                  >
                    {documentData?.data?.type == "Award Notice" ? (
                      <>
                        <h3 style={{ color: "#4682B4" }}>Award Details</h3>
                      </>
                    ) : (
                      <></>
                    )}
                    {documentData?.data?.type == "Award Notice" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: "13px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {!documentData?.data?.award ? (
                              <></>
                            ) : (
                              <>
                                <h5>Contract Award Date :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {formattedDate3}
                                </p>
                              </>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {!documentData?.data?.award ? (
                              <></>
                            ) : (
                              <>
                                <h5>Contract Award Number :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {documentData?.data?.award[0]?.number}
                                </p>
                              </>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {!documentData?.data?.award ? (
                              <></>
                            ) : (
                              <>
                                <h5>Contractor Awarded Unique Entity ID :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {
                                    documentData?.data?.award[0]?.awardee
                                      ?.ueiSAM
                                  }
                                </p>
                              </>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {!documentData?.data?.award ? (
                              <></>
                            ) : (
                              <>
                                <h5>Contractor Awarded Name :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {documentData?.data?.award[0]?.awardee?.name}
                                </p>
                              </>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {!documentData?.data?.award ? (
                              <></>
                            ) : (
                              <>
                                <h5>
                                  Base and All Options Value (Total Contract
                                  Value) :$
                                </h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {documentData?.data?.award[0]?.amount}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                )}

                {/* General Info */}
                <Typography
                  sx={{
                    borderBottom: "1px solid lightgray",
                    marginTop: "25px",
                  }}
                  variant="h6"
                  gutterBottom
                  ref={generalInfoRef}
                >
                  <h3 style={{ color: "#4682B4" }}>General Information</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "13px",
                    }}
                  >
                    {viewState ? (
                      <>
                        <div style={{ display: "flex" }}>
                          {documentData?.data?.type === viewChanges?.type ? (
                            <>
                              <h5>Contract Opportunity Type :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.type}
                              </p>
                            </>
                          ) : (
                            <>
                              <h5>Contract Opportunity Type :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                <del> {viewChanges?.type}</del>
                              </p>
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.type}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.type ? (
                            <></>
                          ) : (
                            <>
                              <h5>Contract Opportunity Type :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.type}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        <div style={{ display: "flex" }}>
                          {documentData?.data?.postedDate ===
                          viewChanges?.postedDate ? (
                            <>
                              <h5>Original Published Date :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate}
                              </p>
                            </>
                          ) : (
                            <>
                              <h5>Original Published Date :</h5>{" "}
                              <p
                                style={{
                                  marginLeft: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                <del style={{ textDecorationThickness: "2px" }}>
                                  {updatedPostedDate}
                                </del>
                              </p>
                              <p
                                style={{
                                  marginLeft: "3px",
                                  fontWeight: "bold",
                                }}
                              >
                                {formattedDate}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.postedDate ? (
                            <></>
                          ) : (
                            <>
                              <h5>Original Published Date :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        <div style={{ display: "flex" }}>
                          {documentData?.data?.responseDeadLine ===
                          viewChanges?.responseDeadLine ? (
                            <>
                              <h5>Original Date Offers Due :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate1}{" "}
                              </p>
                            </>
                          ) : (
                            <>
                              <h5>Original Date Offers Due :</h5>{" "}
                              <p
                                style={{
                                  marginLeft: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                <del style={{ textDecorationThickness: "2px" }}>
                                  {updatedResponse}
                                </del>{" "}
                              </p>
                              <p
                                style={{
                                  marginLeft: "3px",
                                  fontWeight: "bold",
                                }}
                              >
                                {formattedDate1}{" "}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.responseDeadLine ? (
                            <></>
                          ) : (
                            <>
                              <h5>Original Date Offers Due :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate1}{" "}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                       {documentData?.data?.archiveType && 
                        <div style={{ display: "flex" }}>
                          {documentData?.data?.archiveType ===
                          viewChanges?.archiveType ? (
                            <>
                              <h5>Inactive Policy :</h5>
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.archiveType === "auto15" &&
                                  "15 days after date offers due"}
                                {documentData?.data?.archiveType ===
                                  "autocustom" && "Manual"}
                                {documentData?.data?.archiveType === "" &&
                                  "15 days after contract award date"}
                              </p>
                            </>
                          ) : (
                            <>
                              <h5>Inactive Policy :</h5>
                              <p style={{ marginLeft: "3px" }}>
                                <del>
                                  {viewChanges?.archiveType === "auto15" &&
                                    "15 days after date offers due"}
                                  {viewChanges?.archiveType === "autocustom" &&
                                    "Manual"}
                                  {viewChanges?.archiveType === "" &&
                                    "15 days after contract award date"}
                                </del>
                              </p>
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.archiveType === "auto15" &&
                                  "15 days after date offers due"}
                                {documentData?.data?.archiveType ===
                                  "autocustom" && "Manual"}
                                {documentData?.data?.archiveType === "" &&
                                  "15 days after contract award date"}
                              </p>
                            </>
                          )}
                        </div>
}
                      </>
                    ) : (
                      <>
                        {documentData?.data?.archiveType && 
                        <div style={{ display: "flex" }}>
                        <h5>Inactive Policy :</h5>
                        <p style={{ marginLeft: "3px" }}>
                          {documentData?.data?.archiveType === "auto15" &&
                            "15 days after date offers due"}
                          {documentData?.data?.archiveType === "autocustom" &&
                            "Manual"}
                          {documentData?.data?.archiveType === "" &&
                            "15 days after contract award date"}
                        </p>
                      </div>
                        }
                      </>
                    )}

                    {viewState ? (
                      <>
                        <div style={{ display: "flex" }}>
                          {documentData?.data?.archiveDate ===
                          viewChanges?.archiveDate ? (
                            <>
                              <h5>Original Inactive Date :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate2}
                              </p>
                            </>
                          ) : (
                            <>
                              <h5>Original Inactive Date :</h5>{" "}
                              <p
                                style={{
                                  marginLeft: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                <del style={{ textDecorationThickness: "2px" }}>
                                  {updatedInactive}
                                </del>
                              </p>
                              <p
                                style={{
                                  marginLeft: "3px",
                                  fontWeight: "bold",
                                }}
                              >
                                {formattedDate2}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.archiveDate ? (
                            <></>
                          ) : (
                            <>
                              <h5>Original Inactive Date :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {formattedDate2}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Typography>

                {/* Classification */}
                <Typography
                  sx={{
                    borderBottom: "1px solid lightgray",
                    marginTop: "25px",
                  }}
                  variant="h6"
                  gutterBottom
                  ref={classificationRef}
                >
                  <h3 style={{ color: "#4682B4" }}>Classification</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "13px",
                    }}
                  >
                    {viewState ? (
                      <>
                        {viewOtherChanges?.setasides && (
                          <div style={{ display: "flex" }}>
                            {documentData?.setasides?.setAsideName ==
                            viewOtherChanges?.setasides?.setAsideName ? (
                              <>
                                <h5>Original Set Aside :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  {documentData?.setasides?.setAsideName}
                                </p>
                              </>
                            ) : (
                              <>
                                <h5>Original Set Aside :</h5>{" "}
                                <p style={{ marginLeft: "3px" }}>
                                  <del>
                                    {viewOtherChanges?.setasides?.setAsideName}
                                  </del>
                                </p>
                                <p style={{ marginLeft: "3px" }}>
                                  {documentData?.setasides?.setAsideName}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.setasides?.setAsideName ? (
                            <></>
                          ) : (
                            <>
                              <h5>Original Set Aside :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.setasides?.setAsideName}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        {viewOtherChanges?.pns && (
                          <>
                            <div style={{ display: "flex" }}>
                              {documentData?.pns?.pscCode ===
                              viewOtherChanges?.pns?.pscCode ? (
                                <>
                                  <h5>Product Service Code :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    {documentData?.pns?.pscCode}-
                                    {documentData?.pns?.pscName}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <h5>Product Service Code :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    <del>
                                      {viewOtherChanges?.pns?.pscCode}-
                                      {viewOtherChanges?.pns?.pscName}
                                    </del>
                                    {documentData?.pns?.pscCode}-
                                    {documentData?.pns?.pscName}
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.pns ? (
                            <></>
                          ) : (
                            <>
                              <h5>Product Service Code :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.pns?.pscCode}-
                                {documentData?.pns?.pscName}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        {viewOtherChanges?.naics && (
                          <>
                            <div style={{ display: "flex" }}>
                              {documentData?.data?.naicsCode ===
                              viewOtherChanges?.naics?.naicsCode ? (
                                <>
                                  <h5>NAICS Code :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    {documentData?.data?.naicsCode}-
                                    {documentData?.naics?.naicsTitle}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <h5>NAICS Code :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    <del>
                                      {viewOtherChanges?.naics?.naicsCode}-
                                      {viewOtherChanges?.naics?.naicsTitle}
                                    </del>
                                  </p>
                                  <p style={{ marginLeft: "3px" }}>
                                    {documentData?.data?.naicsCode}-
                                    {documentData?.naics?.naicsTitle}
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.naicsCode ? (
                            <></>
                          ) : (
                            <>
                              <h5>NAICS Code :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {documentData?.data?.naicsCode}-
                                {documentData?.naics?.naicsTitle}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        {viewOtherChanges?.data?.placeOfPerformance && (
                          <>
                            <div style={{ display: "flex" }}>
                              {/* {documentData?.data?.placeOfPerformance == viewOtherChanges?.data?.placeOfPerformance?.oldValue ? ( */}
                              {JSON.stringify(
                                documentData?.data?.placeOfPerformance
                              ) ===
                              JSON.stringify(
                                viewOtherChanges?.data?.placeOfPerformance
                                  ?.oldValue
                              ) ? (
                                <>
                                  <h5>Place of Performance :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    {/* {documentData?.data?.placeOfPerformance?.streetAddress} */}
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.city?.name
                                    }
                                    
                                    ,{" "}
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.state?.code && ""
                                    }
                                    -
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.zip && ""
                                    }
                                  </p>
                                </>
                              ) : (
                                <>
                                  <h5>Place of Performance :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    {/* {documentData?.data?.placeOfPerformance?.streetAddress} */}
                                    <del>
                                      {
                                        viewOtherChanges?.data
                                          ?.placeOfPerformance?.oldValue?.city
                                          ?.name
                                      }
                                      ,{" "}
                                      {
                                        viewOtherChanges?.data?.placeOfPerformance?.oldValue?.state?.code ||
                                        viewOtherChanges?.data?.placeOfPerformance?.oldValue?.state?.name
                                      }
                                      -
                                      {
                                        viewOtherChanges?.data
                                          ?.placeOfPerformance?.oldValue?.zip
                                      }
                                    </del>
                                    {/* {documentData?.data?.placeOfPerformance?.streetAddress} */}
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.city?.name
                                    }
                                    ,{" "}
                                    {
                                      documentData?.data?.placeOfPerformance?.state?.code ||
                                      documentData?.data?.placeOfPerformance?.state?.name
                                    }
                                    -
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.zip
                                    }
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.placeOfPerformance ? (
                            <></>
                          ) : (
                            <>
                              <h5>Place of Performance :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {/* {documentData?.data?.placeOfPerformance?.streetAddress} */}
                                {
                                  documentData?.data?.placeOfPerformance?.city
                                    ?.name
                                }
                                ,{" "}
                                {
                                  documentData?.data?.placeOfPerformance?.state?.code ||
                                  documentData?.data?.placeOfPerformance?.state?.name
                                }
                                -{documentData?.data?.placeOfPerformance?.zip}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {viewState ? (
                      <>
                        {viewOtherChanges?.data?.placeOfPerformance && (
                          <>
                            <div style={{ display: "flex" }}>
                              {JSON.stringify(
                                documentData?.data?.placeOfPerformance
                              ) ===
                              JSON.stringify(
                                viewOtherChanges?.data?.placeOfPerformance
                                  ?.oldValue
                              ) ? (
                                <>
                                  <h5>Country :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                  {documentData?.data?.placeOfPerformance?.country?.code ||
                                  documentData?.data?.placeOfPerformance?.country?.name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <h5>Country :</h5>{" "}
                                  <p style={{ marginLeft: "3px" }}>
                                    <del>
                                      {
                                        viewOtherChanges?.data?.placeOfPerformance?.oldValue?.country?.code ||
                                        viewOtherChanges?.data?.placeOfPerformance?.oldValue?.country?.name
                                      }
                                    </del>
                                  </p>
                                  <p style={{ marginLeft: "3px" }}>
                                    {
                                      documentData?.data?.placeOfPerformance
                                        ?.country?.code
                                    }
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          {!documentData?.data?.placeOfPerformance ? (
                            <></>
                          ) : (
                            <>
                              <h5>Country :</h5>{" "}
                              <p style={{ marginLeft: "3px" }}>
                                {
                                  documentData?.data?.placeOfPerformance?.country?.code ||
                                  documentData?.data?.placeOfPerformance?.country?.name
                                }
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Typography>

                {/* Description */}
              {viewState ? <>
                {!documentData?.data?.descriptionData ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid lightgray",
                        marginTop: "25px",
                      }}
                      variant="h6"
                      gutterBottom
                      ref={descriptionRef}
                    >
                      <h3 style={{ color: "#4682B4" }}>Description</h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          fontSize: "13px",
                        }}
                      >
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: comparedDescription,
                            }}
                          />
                        </p>
                      </div>
                    </Typography>
                  </>
                )}
              </> : <>
              {!documentData?.data?.descriptionData ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid lightgray",
                        marginTop: "25px",
                      }}
                      variant="h6"
                      gutterBottom
                      ref={descriptionRef}
                    >
                      <h3 style={{ color: "#4682B4" }}>Description</h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          fontSize: "13px",
                        }}
                      >
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentData?.data?.descriptionData,
                            }}
                          />
                        </p>
                      </div>
                    </Typography>
                  </>
                )}
              </>}

                {/* Attachments/Links */}
                {!documentData?.data?.resourceLinksPath?.length > 0 ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid lightgray",
                        marginTop: "25px",
                      }}
                      variant="h6"
                      gutterBottom
                      ref={attachmentsRef}
                      className="back-button"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ color: "#4682B4", margin: 0 }}>
                          Attachments/Links
                        </h3>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{ color: "#4682B4", fontSize: "16px" }}
                            title="Download All Attachments"
                          >
                            <DownloadIcon onClick={()=>handleDownloadAll(attachments)}  className="back-button"/>
                          </div>
                          {/* <Button
                onMouseEnter={() => handleTooltipVisibility(true)}
                onMouseLeave={() => handleTooltipVisibility(false)}
                style={{
                    backgroundColor: "black",
                    color: "white",
                    marginBottom: "5px"
                }}
                variant="contained"
                color="primary"
                onClick={handleDownloadAll}
            >
                Download All Attachments
            </Button>
            {isTooltipVisible && (
                <div style={{ position: "absolute", top: "100%", left: 0, backgroundColor: "rgba(0, 0, 0, 0.8)", color: "white", padding: "5px", borderRadius: "5px" }}>
                    Download all attachments
                </div>
            )} */}
                        </div>
                      </div>
                      {!attachments?.length > 0 ? (
                        <>
                          <p style={{ fontSize: "14px" }}>
                            No attachments or links have been added to this
                            opportunity.
                          </p>
                        </>
                      ) : (
                        <>
                          {/* <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button style={{
                                                backgroundColor: "black", color: "white", marginBottom: "5px"
                                            }} variant="contained" color="primary" onClick={handleDownloadAll}>
                                                Download All Attachments
                                            </Button>
                                        </div> */}
                          <TableContainer >
                            <Table>
                              <TableHead sx={{ backgroundColor: "lightgray" }}>
                                <TableRow>
                                  <TableCell sx={{ textAlign: "start" }}>
                                    Attachments
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "start" }}>
                                    Link
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: "end", width: "80px" }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "10px",
                                        backgroundColor: "black",
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        marginLeft: "50px",
                                      }}
                                      onClick={toggleTableBody}
                                    >
                                      {isOpen ? "-" : "+"}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {isOpen && (
                                <TableBody>
                                  {attachments.map((attachment, index) => {
                                      const fileNameWithoutExtension = attachment?.linkname.split('.')[0];
                                      return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <InsertDriveFileIcon
                                            style={{ marginRight: "5px" }}
                                          />
                                          {/* Attachment {index + 1} */}
                                          {fileNameWithoutExtension}
                                        </div>
                                      </TableCell>
                                      <TableCell >
                                        <div
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          // onClick={() =>
                                          //   window.open(attachment, "_blank")
                                          // }
                                          onClick={()=>DownloadAttachments(attachment?.linkname)}
                                        >
                                          Download
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                      );
})}
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Typography>
                  </>
                )}
                {/* Contact Information */}
                <Typography 
                  sx={{
                    borderBottom: "1px solid lightgray",
                    marginTop: "25px",
                  }}
                  variant="h6"
                  gutterBottom
                  ref={contactInfoRef}
                >
                  <h3 style={{ color: "#4682B4" }}>Contact Information</h3>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "13px",
                    }}
                  >
                    {viewState ? (
                      <>
                      {viewOtherChanges?.data?.officeAddress && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4 style={{ fontWeight: "bold" }}> Contracting Office Address :</h4>
                        {JSON.stringify(documentData?.data?.officeAddress)===JSON.stringify(viewOtherChanges?.data?.officeAddress?.oldValue) ?<>
                          <p
                          style={{
                            display: "flex",
                            marginTop: "-7px",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p>
                            {documentData?.data?.officeAddress?.city},
                            {documentData?.data?.officeAddress?.state},
                            {documentData?.data?.officeAddress?.zipcode}
                          </p>
                          <p style={{ marginTop: "-11px" }}>
                            {documentData?.data?.officeAddress?.countryCode}
                          </p>
                        </p>
                        </>:<>
                        <p
                          style={{
                            display: "flex",
                            marginTop: "-7px",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p>
                            <del>
                            {viewOtherChanges?.data?.officeAddress?.oldValue?.city},{" "}
                            {viewOtherChanges?.data?.officeAddress?.oldValue?.state},{" "}
                            {viewOtherChanges?.data?.officeAddress?.oldValue?.zipcode}
                            </del>
                          </p>
                          <p style={{ marginTop: "-11px" }}>
                           <del>
                           {viewOtherChanges?.data?.officeAddress?.oldValue?.countryCode}
                           </del>
                          </p>
                          <p>
                            {documentData?.data?.officeAddress?.city},{" "}
                            {documentData?.data?.officeAddress?.state},{" "}
                            {documentData?.data?.officeAddress?.zipcode}
                          </p>
                          <p style={{ marginTop: "-11px" }}>
                            {documentData?.data?.officeAddress?.countryCode}
                          </p>
                        </p>
                        </>}
                      </div>
                      )}
                      </>
                    ) : (
                      <>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h4 style={{ fontWeight: "bold" }}>
                            Contracting Office Address :
                          </h4>
                          <p
                            style={{
                              display: "flex",
                              marginTop: "-7px",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                          >
                            <p>
                              {documentData?.data?.officeAddress?.city},{" "}
                              {documentData?.data?.officeAddress?.state},{" "}
                              {documentData?.data?.officeAddress?.zipcode}
                            </p>
                            <p style={{ marginTop: "-11px" }}>
                              {documentData?.data?.officeAddress?.countryCode}
                            </p>
                          </p>
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "13px",
                        marginBottom: "25px",
                      }}
                    >
                      <div style={{ width: "45%", marginTop: "25px" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            marginTop: "5px",
                            fontSize: "15px",
                          }}
                        >
                          Primary Point of Contact :
                        </p>

                        {viewState ? <>
                        {JSON.stringify(documentData?.data?.pointOfContact[0]?.fullName) ===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[0]?.fullName) ?<>
                          <p>{documentData?.data?.pointOfContact[0]?.fullName}</p>
                        </>:<>
                        <p>
                          <del>{viewOtherChanges?.data?.pointOfContact?.oldValue[0]?.fullName}</del>
                          {documentData?.data?.pointOfContact[0]?.fullName}
                        </p>
                        </> }
                        </>:<>
                        <p>{documentData?.data?.pointOfContact[0]?.fullName}</p>
                        </>}

                        {viewState ? <>
                        {viewOtherChanges?.data?.pointOfContact && (
                          <>
                          {JSON.stringify(documentData?.data?.pointOfContact[0]?.email)===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[0]?.email) ? <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.email}
                            </p>
                            </> :<>
                            <p>
                              <del>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {viewOtherChanges?.data?.pointOfContact?.oldValue[0].email}
                              </del>
                           
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.email}
                            </p>
                            </>}
                            </>
                        )}
                        </> : <>
                        {!documentData?.data?.pointOfContact[0]?.email ? (
                          <></>
                        ) : (
                          <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.email}
                            </p>
                          </>
                        )}
                        </>}

                        
                        {viewState ? <>
                        {viewOtherChanges?.data?.pointOfContact && (
                          <>
                          {JSON.stringify(documentData?.data?.pointOfContact[0]?.phone)===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[0]?.phone) ? <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.phone}
                            </p>
                            </> :<>
                            <p>
                              <del>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {viewOtherChanges?.data?.pointOfContact?.oldValue[0].phone}
                              </del>
                           
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.phone}
                            </p>
                            </>}
                            </>
                        )}
                        </> : <>
                        {!documentData?.data?.pointOfContact[0]?.phone ? (
                          <></>
                        ) : (
                          <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[0]?.phone}
                            </p>
                          </>
                        )}
                        </>}


                      </div>
                      
                      <div style={{ width: "45%", marginTop: "25px" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            marginTop: "5px",
                            fontSize: "15px",
                          }}
                        >
                          Secondary Point of Contact :
                        </p>

                        {viewState ? <>
                        {JSON.stringify(documentData?.data?.pointOfContact[1]?.fullName) ===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[1]?.fullName) ?<>
                          <p>{documentData?.data?.pointOfContact[1]?.fullName}</p>
                        </>:<>
                        <p>
                          <del>{viewOtherChanges?.data?.pointOfContact?.oldValue[1]?.fullName}</del>
                          {documentData?.data?.pointOfContact[1]?.fullName}
                        </p>
                        </> }
                        </>:<>
                        <p>{documentData?.data?.pointOfContact[1]?.fullName}</p>
                        </>}

                        {viewState ? <>
                        {viewOtherChanges?.data?.pointOfContact?.oldValue[1] && (
                          <>
                          {JSON.stringify(documentData?.data?.pointOfContact[1]?.email)===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[1]?.email) ? <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.email}
                            </p>
                            </> :<>
                            <p>
                              <del>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {viewOtherChanges?.data?.pointOfContact?.oldValue[1].email}
                              </del>
                           
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.email}
                            </p>
                            </>}
                            </>
                        )}
                        </> : <>
                        {!documentData?.data?.pointOfContact[1]?.email ? (
                          <></>
                        ) : (
                          <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaEnvelope sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.email}
                            </p>
                          </>
                        )}
                        </>}

                        
                        {viewState ? <>
                        {viewOtherChanges?.data?.pointOfContact[1] && (
                          <>
                          {JSON.stringify(documentData?.data?.pointOfContact[1]?.phone)===JSON.stringify(viewOtherChanges?.data?.pointOfContact?.oldValue[1]?.phone) ? <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.phone}
                            </p>
                            </> :<>
                            <p>
                              <del>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {viewOtherChanges?.data?.pointOfContact?.oldValue[1].phone}
                              </del>
                           
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.phone}
                            </p>
                            </>}
                            </>
                        )}
                        </> : <>
                        {!documentData?.data?.pointOfContact[1]?.phone ? (
                          <></>
                        ) : (
                          <>
                            <p>
                              {" "}
                              <span
                                style={{ color: "#4682B4", marginRight: "3px" }}
                              >
                                <FaPhone sx={{ color: "blue" }} />{" "}
                              </span>{" "}
                              {documentData?.data?.pointOfContact[1]?.phone}
                            </p>
                          </>
                        )}
                        </>}


                      </div>
                    </div>
                  </div>
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ marginTop: "25px" }}
                  gutterBottom
                  ref={historyRef}
                >
                  <h3 style={{ color: "#4682B4" }}>History</h3>
                  <div>
                    <HistoryBar
                      loading={loading}
                      redirectingData={redirectingData}
                      historyData={historyData}
                    />
                  </div>
               
                </Typography>
              </Paper>
              
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button variant="primary" onClick={() => history.goBack()} className="back-button">Back</Button>
              </div>

            </Grid>
          </Grid>
          
        </>
      )
      //  : (
      //   <>
      //     <h3>Loading...</h3>
      //   </>
      // )
      }
    </>
  );
};

export default SingleNoticeInfo;

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Documents from "@material-ui/icons/Description";
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Dashboard from "@material-ui/icons/Equalizer";
import Team from "@material-ui/icons/SupervisorAccount";
import Alerts from "@material-ui/icons/Forum";
import Reports from "@material-ui/icons/LibraryBooks";
import { NavLink } from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import { getProfile } from "../services/userService";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import NotificationServices from "../services/notficationService";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import "./Navbar.css";
import "./SideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import BookIcon from "@material-ui/icons/Book";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { getHomeCMS } from "../services/adminServices";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import PetLogo from "../static/signup_login/PetLogo.png"

import ContactProLogo from "../static/signup_login/contactPrologo.PNG";
import { imgUrl } from "../services/config.json"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import { menuItems } from '../components/sidebarItems';
import { sideBarClose, sideBaropen } from "../redux/sidenavbar/sidenavbar.action";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 240;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
}));

function NewHeader(props) {
  const classes = useStyles();
  const [AnchorEl, setAnchorEl] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [username, setusername] = useState("");
  const [notifications, setnotifications] = useState([]);
  const [alerts, setalerts] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = React.useState();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cms, setCms] = useState({});
  const [userData, setuserData] = useState({});
  const [bottomText, setBottomText] = useState("")
  const [selectedMenu, setSelectedMenu] = useState('ContractPRO');
  
  const location = useLocation()
  console.log(username, "username");

  const formatDate = () => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date();
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.toLocaleDateString('en-US', { day: 'numeric' });
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return `${month} ${day} | ${year}`;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const currentPath = window.location.pathname;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    switch (currentPath) {
      case '/paraphrase':
        setSelectedMenu('Paraphraser');
        break;
      case '/dashboard':
        const capitalizedUsername = capitalizeFirstLetter(username);
        setSelectedMenu(`Welcome ${capitalizedUsername}`);
        setBottomText(`${formatDate()}`);
        break;
      case '/texteditor':
        setSelectedMenu('New Document');
        break;
      case '/mydocuments':
        setSelectedMenu('Documents');
        break;
      case '/myteam':
        setSelectedMenu('Team');
        break;
      case '/docTags':
        setSelectedMenu('Tags');
        break;
      case '/citation':
        setSelectedMenu('Citation Generator');
        break;
      case '/plagiarism':
        setSelectedMenu('Plagiarism');
        break;
      case '/coauthor':
        setSelectedMenu('Co Author');
        break;
      case '/Summarizer':
        setSelectedMenu('Summarizer');
        break;
      case '/translator':
        setSelectedMenu('Translator');
        break;
      case '/reports':
        setSelectedMenu('Reports');
        break;
      case '/alerts':
        setSelectedMenu('Alerts');
        break;
      case '/grammarcheck':
        setSelectedMenu('Grammar Check');
        break;
      case '/blogpage':
        setSelectedMenu('Blogs');
        break;
      case '/accountsettings':
        setSelectedMenu('Account Settings');
        break;
      case '/pricing':
        setSelectedMenu('Subcriptions');
        break;
      case '/invoices':
        setSelectedMenu('Invoice');
        break;
      case '/contactUs':
        setSelectedMenu('Contact Us');
        break;
      case '/coauthor-newproject':
        setSelectedMenu('Co Author');
        break;
      case '/OppourtunityManagement':
        setSelectedMenu('Opportunity Management');
        break;
      case '/manualOpportunity':
        setSelectedMenu('Manual Opportuntiy');
        break;
      case '/communicationHub':
        setSelectedMenu('Communication Hub');
        break;
      case '/userRole':
        setSelectedMenu('User Role');
        break;
      case '/helpandSupport':
        setSelectedMenu('Help and support');
        break;
      case '/searchOpportunity':
        setSelectedMenu('Opportunity Finder');
        break;
      default:
        setSelectedMenu('Opportunitty Details');
    }
  }, [username]);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCms = async () => {
    const { currentUser, setCurrentUser } = props;
    let cms = await getHomeCMS();
    setCms(cms.data);
  };
  // const getUser = async () => {
  //   const { currentUser, setCurrentUser } = props;
  //   if (!JSON.stringify(currentUser)) {
  //     let user = (user = await getProfile());
  //     console.log("storing in redux");
  //     console.log(user.sidenavOption)
  //     user = { ...user.data };
  //     // setOpen(user.sidenavOption)
  //     setCurrentUser(user);
  //   }
  // }

  useEffect(() => {

    async function getUser() {
      const { currentUser, setCurrentUser } = props;
      if (!JSON.stringify(currentUser)) {
        let user = (user = await getProfile());
        console.log("storing in redux");
        console.log(user.sidenavOption);
        user = { ...user.data };
        setuserData(user);
        setCurrentUser(user);
      }
    }
    getUser();
    getNotifications();
    getAlerts();
    displyPic();
    getCms();
  }, []);

  useEffect(() => {
    const { currentUser } = props;
    setOpen(currentUser.sidenavOption);
  }, [props]);

  const notfication_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content
        style={{
          overflowY: "auto",
          maxHeight: "40vh",
          // scroll: "auto",
        }}
      >
        <NotificationCard data={notifications} />
      </Popover.Content>
    </Popover>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(sideBaropen({ stateValue: true }))
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(sideBarClose({ stateValue: true }))
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const getAlerts = async () => {
    let alerts = await NotificationServices.getAlerts();
    setalerts(alerts);
    // console.log(alerts);
  };

  const getNotifications = async () => {
    let notifications = await NotificationServices.getnotifications();
    setnotifications(notifications);
    // console.log(notifications);
  };

  const openContactUs = async () => {
    props.history.push({
      pathname: `/contactUs`,
    });
  };

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
      user["status"] = 200;
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    if (user.status === 200) {
      const { display_picture, username } = user;
      setProfilePic(display_picture);
      setusername(username);
    }
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/accountsettings") && "background-grey"}
          >
            <Typography>Account Settings</Typography>
          </MenuItem>
        </NavLink>
        {props.currentUser && props.currentUser.accountType === "Personal" && (
          <NavLink
            to={{ pathname: "/pricing" }}
            style={{ color: "black", paddingTop: "2px" }}
          >
            <MenuItem
              onClick={handleMenuClose}
              className={isUrlMatch("/pricing") && "background-grey"}
            >
              Subscriptions
            </MenuItem>
          </NavLink>
        )}
        {props.currentUser && props.currentUser.accountType === "Personal" && (
          <NavLink to={{ pathname: "/invoices" }} style={{ color: "black" }}>
            <MenuItem
              onClick={handleMenuClose}
              className={isUrlMatch("/invoices") && "background-grey"}
            >
              Invoices
            </MenuItem>
          </NavLink>
        )}

        <NavLink to={{ pathname: "/contactUs" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Support</MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/logout" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );

  // function isUrlMatch(url) {
  //   if (window.location.pathname.match(url)) {
  //     console.log("url_")
  //     return true;
  //   }
  //   return false;
  // }

  function isUrlMatch(path) {
    return matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })
  }

  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };


  const handleMenuSelection = (menuName) => {
    setSelectedMenu(menuName);
  };

  useEffect(() => {
    console.log("test_");
    handleMenuSelection(selectedMenu)
  }, [selectedMenu])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {getThemeSwitch()}
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div
            style={{ color: "#007bff"}}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            id="normalsidenav"
          >
             <ChevronRightIcon style={{ color: "#007bff" }} />
            <img
            alt="logo"
            // className="img-fluid lcss"
            src={ContactProLogo}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : `${imgUrl}/pet-website-static-images/Group+570.png`
            // }
            style={{
              width: "100px",
              height: "40px",
              objectFit: "contain",
              marginLeft:"10px"
            }}
          // src={logo}
          ></img>
            
          </div>

          {/* <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff"
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography> */}

          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              {selectedMenu !== 'ContractPRO' ? (
                <>
                <text
                  style={{
                    fontFamily: "ProximaNova-Semibold",
                    lineHeight: "1.18",
                    fontWeight: 600,
                    fontSize: "22px",
                    color: "#3d83fa",
                  
                  }}
                >
                  {selectedMenu}
                </text><br />
                {bottomText && (
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#3d83fa",
                      backgroundColor:'red'
                    }}
                  >
                    {bottomText}
                  </small>
                )}
              </>
              ) : (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                      marginRight: "5px"
                    }}
                  >
                    ContractPRO
                  </text>
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "22px",
                      fontWeight: 300,
                      color: "#3d83fa",
                    
                    }}
                  >
                    {/* EDITING TOOL */}
                  </small>
                </>
              )}
            </p>
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: "6%" }}>
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={openContactUs}
              >
                <ContactMailIcon />
              </IconButton> */}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={notfication_popover}
                rootClose={true}
              >
                <IconButton
                  style={{ color: "#4d4f5c" }}
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge
                    badgeContent={
                      notifications.notificationsCount > 0
                        ? notifications.notificationsCount
                        : null  
                    }
                    color="secondary"
                  >
                    <NotificationsIcon style={{color:"#1976D2"}} />
                  </Badge>
                </IconButton>
              </OverlayTrigger>
            </div>
            {/*  */}
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <LightTooltip title={username} onClick={handleProfileMenuOpen}>
                    <Avatar
                      aria-controls={menuId}
                      aria-haspopup="true"
                      alt={username}
                      src={profilePic ? profilePic : avatar}
                      id="profilePic"
                      className={`${classes.avatar} profilePic point`}
                      color="inherit"
                    />
                  </LightTooltip>
                </OverlayTrigger>
              </Grid>
            </div>
          </div>

          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={notfication_popover}
              rootClose={true}
            >
              <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge
                  badgeContent={
                    notifications.notificationsCount > 0
                      ? notifications.notificationsCount
                      : null
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
              >
                <MoreIcon />
              </IconButton> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt={username}
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={username}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
        {/* <div id="mobilesidenav">
          <MobileSidenav />
        </div> */}
      </AppBar>
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        // open={open}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        id="normalsidenav"
      >
        <div
          className={classes.toolbarB}
          style={{
            backgroundColor: "white",
            position:"sticky",
            top:0,
            left:0
            // borderStartEndRadius: "2rem",

          }}
        // id="sidebar"
        >
          <img
            alt="logo"
            className="img-fluid lcss"
            src={ContactProLogo}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : `${imgUrl}/pet-website-static-images/Group+570.png`
            // }
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
            }}
          // src={logo}
          ></img>
          <IconButton className="p-1" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon style={{ color: "#007bff" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "#007bff" }} />
            )}
          </IconButton>
        </div>
        <Divider />

        <List className="drawerMenu pointer" id="menuList" style={{ overflowY: "auto",height:"100vh"}}>
        {menuItems.map((item, index) => (
          <NavLink
          
          className="sidenavbar-textlink"
          key={index}
          to={{ pathname: item.path }}
        >
          <ListItem
            // button
            className={
              isUrlMatch(item.path) || isUrlMatch(`/blogpreview/:id`)
                ? "background-red hvr-pulse"
                : "hvr-pulse"
            }
            onClick={() => {
              handleMenuSelection(item.label);
              // Additional onClick logic if needed
            }}
          >
            <ListItemIcon className="drawerMenu" style={{ color: "#007bff",marginLeft:'-8px'}}>
              <LightTooltip title={item.label}>
               <p style={{margin:'auto'}} className={ isUrlMatch(item.path)? "iconCol" : "hvr-pulse"}> {getIconComponent(item.icon)}</p>
                
              </LightTooltip>
            </ListItemIcon>
            <Typography style={{marginLeft:'14px'}} >{item.label}</Typography>
          </ListItem>
        </NavLink>
        
          ))}
        
        </List>

        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isAuth: state.user.isAuth,
});

const getIconComponent = (icon, isActive) => {
  const color = isActive ? "red" :  "hvr-pulse"; // Set the color to blue when isActive is true
  switch (icon) {
    case 'Dashboard':
      return <Dashboard  style={{ color }} />;
    case 'SearchIcon':
      return <SearchIcon   style={{ color }} />;
    case 'PostAddIcon':
      return <PostAddIcon  style={{ color }} />;
    case 'Documents':
      return <Documents  style={{ color }} />;
    case 'GroupsIcon':
      return <GroupsIcon  style={{ color }} />;
    case 'ManageAccountsIcon':
      return <ManageAccountsIcon  style={{ color }} />;
    case 'LiveHelpIcon':
      return <LiveHelpIcon  style={{ color }} />;
    // Add more cases as needed
    default:
      return null;
  }
};


const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewHeader));

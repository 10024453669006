import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

function Semantic_loader({ loading }) {
    return (
        <div 
            className={loading ? "loading-overlay" : ""} 
        >
            <Dimmer active={loading} >
                <Loader indeterminate size='large'>
                    <div>
                        <b>Hold On! This may take a while</b><br/>
                        <p>Generating some graphical visualizations</p>
                    </div>
                </Loader>
            </Dimmer>
        </div>
    )
}

export default Semantic_loader

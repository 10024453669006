import React, { useState, useEffect, Component } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import HeadingB from "../Cards/HeadingB";
import { withRouter, Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import JournalForm from "./JournalForm";
import BookForm from "./BookForm";
import WebsiteForm from "./websiteForm";
import LegalCaseForm from "./LegalCaseForm";
import Button from "@material-ui/core/Button";
import SpeechForm from "./speechForm";
import Loader from "../../utils/loader";
import Citationimg from "../../static/citations.png";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import ReactTour from "../ReactTour";

const buttonStyle = {
  paddingTop: "2%",
  paddingBottom: "2%",
  paddingRight: "10%",
  paddingLeft: "10%",
};

class Citationproj extends Component {
  state = {
    form: "",
    formtype: "",
    initialValues: null,
    // showcitationforms: false,
    loading: false,
    projectid: this.props.location.state.projectID,
    reactTour: false,
    steps: [
      {
        selector: ".reactour-1",
        content: "Create New Citation of various form types",
        position: "bottom",
      },
      {
        selector: ".reactour-2",
        content: "View all citations",
        position: "bottom",
      },
    ],
  };

  componentDidMount() {
    const { value, currentUser, features } = this.props;
    if (value) {
      const { formtype, initialValues } = value;
      this.setState({
        form: initialValues.type,
        formtype: formtype ? formtype : [],
        initialValues,
        // showcitationforms: true,
      });
    }
  }

  componentWillReceiveProps() {}

  handleCitationOptions = (form) => {
    const { value } = this.props;
    if (!value) {
      this.setState({ form, formtype: "" });
    } else {
      toast.info("Please save the current Form");
    }
  };

  handleFormType = (formtype) => {
    this.setState({ formtype });
  };

  gotoCitationList = () => {
    const { history } = this.props;
    history.push({
      pathname: `/citationList`,
      state: { projectId: this.state.projectid },
    });
  };

  render() {
    const {
      form,
      formtype,
      initialValues,
      // showcitationforms,
      projectid,
    } = this.state;
    console.log(form, formtype);

    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />
        <ReactTour
          steps={this.state.steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <BreadCrumb
          l1="Home"
          l2="Citation"
          l3="CitationProject"
          dashboardlink={"/dashboard"}
          documentLink={"/citation"}
        />

        <div className="row" style={{ marginBottom: "-30px" }}>
          <div className="col-md-6">
            <div
              class="btn-group reactour-1"
              role="group"
              aria-label="Basic example"
              style={{ marginBottom: "-15px" }}
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={() => this.handleCitationOptions("website")}
                style={{
                  color: "#5b6b82",
                  borderColor: "#5b6b82",
                  width: "120px",
                }}
              >
                WEBSITE
              </button>
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={() => this.handleCitationOptions("Book")}
                style={{
                  color: "#5b6b82",
                  borderColor: "#5b6b82",
                  width: "120px",
                }}
              >
                BOOK
              </button>
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={() => this.handleCitationOptions("article-journal")}
                style={{
                  color: "#5b6b82",
                  borderColor: "#5b6b82",
                  width: "120px",
                }}
              >
                JOURNAL
              </button>

              <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: "#5b6b82",
                  borderColor: "#5b6b82",
                  width: "120px",
                }}
              >
                MORE
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  className="dropdown-item"
                  onClick={() => this.handleCitationOptions("speech")}
                >
                  Speech
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => this.handleCitationOptions("legal_case")}
                >
                  Legal Case
                </a>
              </div>
            </div>
            {/* <button
              type="button"
              className="btn btn-primary reactour-1"
              onClick={() => {
                if (this.props.features.Citations === true) {
                  this.setState({ showcitationforms: true });
                } else {
                  toast("Please Upgrade your plan to enable Citation Feature");
                }
              }}
            >
              CREATE A NEW CITATION
            </button> */}
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-3">
            {/* <Link
              to={{
                pathname:
                  this.props.features.Citations === true ? "/citationList" : "",
              }}
            > */}
            <button
              type="button"
              className="btn btn-primary reactour-2"
              style={{ float: "right" }}
              onClick={this.gotoCitationList}
            >
              MY CITATIONS
            </button>
            {/* </Link> */}
          </div>
          <div className="col-md-1">
            <i
              className="fa fa-question-circle mt-2"
              aria-hidden="true"
              data-toggle="tooltip"
              title="Need Help ?"
              style={{
                cursor: "pointer",
                color: "dodgerblue",
                fontSize: "15px",
              }}
              onClick={() => this.setState({ reactTour: true })}
            ></i>
          </div>
        </div>

        <br />
        <br />

        <h2>{form}</h2>
        <div className="row">
          <div className="col-md-3">
            <h4>Cite it manually: {formtype}</h4>
          </div>

          <div className="col-md-2" style={{ marginTop: "-2%", float: "left" }}>
            {form === "Book" ||
            (form === "article-journal" && form.length > 0) ? (
              <FormControl variant="outlined">
                <Select
                  native
                  value={formtype}
                  onChange={(e) => this.handleFormType(e.target.value)}
                  inputProps={{
                    name: "type",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value="">Select type</option>
                  {form === "Book" ? (
                    <>
                      <option value={"E_Book_Online"}>E_Book_Online</option>
                      <option value={"Online_DB"}>Online_DB</option>
                      <option value={"In_Print"}>In_Print</option>
                      <option value={"E_Book_Other"}>E_Book_Other</option>
                    </>
                  ) : form === "article-journal" ? (
                    <>
                      <option value={"Online_DB"}>Online_DB</option>
                      <option value={"In_Print"}>In_Print</option>
                    </>
                  ) : (
                    ""
                  )}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: "2%" }}>
          {form === "website" ? (
            <WebsiteForm projectID={projectid} initialValues={initialValues} />
          ) : (
            ""
          )}

          {form === "Book" ? (
            formtype.length > 0 ? (
              <BookForm
                projectID={projectid}
                formType={formtype}
                initialValues={initialValues}
              />
            ) : null
          ) : null}

          {form === "article-journal" ? (
            formtype.length > 0 ? (
              <JournalForm
                projectID={projectid}
                formType={formtype}
                initialValues={initialValues}
              />
            ) : (
              <p>Please Select the formType</p>
            )
          ) : null}

          {form === "legal_case" ? (
            <LegalCaseForm
              projectID={projectid}
              initialValues={initialValues}
            />
          ) : (
            ""
          )}

          {form === "speech" ? (
            <SpeechForm projectID={projectid} initialValues={initialValues} />
          ) : (
            ""
          )}

          {form != "Book" &&
          form != "article-journal" &&
          form != "website" &&
          form != "legal_case" &&
          form != "speech" ? (
            <div className="text-center">
              <img
                className="img-fluid"
                alt="citation"
                src={Citationimg}
                style={{ height: "40%", width: "40%" }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(mapStateToProps)(withRouter(Citationproj));

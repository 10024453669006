import React, { Component } from 'react'
import ReactApexChart from "react-apexcharts";
export default class ScoreGraph extends Component {
    state ={
        
        options: {
          chart: {
            height: 40,
            type: 'radialBar',
          },
          labels : ['Score'],
          fill: {
            colors: ['#0870e7']
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '48%',
               
              }
            },
          },
         
        },
        
      
    };
    render() {
      let series = [this.props.data ]
        return (
            
                <div id="chart">
                        
                    <ReactApexChart options={this.state.options} series={series} type="radialBar" height={250} />
                    <h6>This text scores better than {series}% of all texts checked by PET</h6>
                    </div>
           
        )
    }
}

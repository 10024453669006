import React from "react";

import AdminNavBar from "../AdminSideNavBar";
import ReviewRatings from "../ReviewsRatingsTabs";

const ReviewRatingPage = () => {
  return (
    <AdminNavBar>
      <ReviewRatings />
    </AdminNavBar>
  );
};

export default ReviewRatingPage;

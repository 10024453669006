import { opportunityActionTypes } from "./opportunity.types"
export const rfmManagmentData = ( title,noticeId,descriptionData,id,type,date1,agancy,assetid,resourceLinksPath )=> ({
    type : opportunityActionTypes.SET_RFP_DATA,
    payload: {
        title,
        noticeId,
        descriptionData,
        id,
        type,
        date1,
        agancy,
        assetid,
        resourceLinksPath
      }
})


export const proposalWritingData = ( title,noticeId,descriptionData,id,type,date1,agancy,assetid )=> ({
  type : opportunityActionTypes.SET_PROPOSAL_DATA,
  payload: {
      title,
      noticeId,
      descriptionData,
      id,
      type,
      date1,
      agancy,
      assetid
    }
})



export const myprojectScreen = (data)=> ({
  type : opportunityActionTypes.SET_MYPROJECT,
  payload: {
     data
    }
})


export const reminderAssetid = (data)=> ({
  type : opportunityActionTypes.SET_REMINDER_ASSETID,
  payload: {
     data
    }
})
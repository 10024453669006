import { convertFromRaw } from "draft-js";

export function getTextFromEditorState(onlyDocumentContent,sliceIt)  {
    const storedState = JSON.parse(onlyDocumentContent);
    const contentState = convertFromRaw(storedState);
    const text = contentState.getPlainText();
    if(sliceIt){
        return text.slice(0, 120);
    }
    return text;
}
import React, {useEffect, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from "@material-ui/core/Divider";
import TeamCard from './TeamCard';
import ChatBoxPage from './ChatBoxPage';
import ChatServices from '../services/ChatServices';
import { getUserInfo } from '../services/opportunityService';


const ChatModal = ({ open, handleClose, orgUsers }) => {

const [openChatClicked, setOpenChatClicked] = useState(false)
const [userInfo, setUserInfo] = useState([])

  const Username = sessionStorage.getItem("currentUserEmail")


  const getCuurentUser = async (name) => {
    try {
      const response = await getUserInfo(name)
      setUserInfo(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const userId  = userInfo?._id

const handleOpenChat = async (id) => {
    try {
      const response = await ChatServices.createContact(userId, id);
      if (response.status === 200) {
        setOpenChatClicked(true)
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
}

useEffect(()=>{
  getCuurentUser(Username)
},[])

const handleCloseModal = ()=>{
  handleClose();
                      setOpenChatClicked(false);
}
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="submission-history-modal"
            aria-describedby="submission-history-table"
        >
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                width: '90%',
                height:'95%',
                overflowY:'auto'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ margin: 0 }}>Chat</h2>
                    <IconButton 
                    onClick={()=>{
                      handleCloseModal();
                      
                    }} 
                    style={{ padding: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider/>


                {!openChatClicked  ?
                <div>
                {orgUsers.length === 0 ? (
            <div className="text-center">
              <h1 style={{ marginTop: "15%" }}>
                No Organization members available
              </h1>
            </div>
          ) : (
            <>
              {orgUsers.length > 0 && (
                <p className="mt-4 mb-2 ft_md ft-wt-500" style={{ color: "#3f51b5" }}>Organization Members</p>
              )}
              <div className="row pl-2" style={{marginTop:'20px'}}>
                {orgUsers.map((team) => (
                  <TeamCard
                    key={team._id}
                    teamdata={team}
                    openChat = {()=>handleOpenChat(team._id)}
                  />
                ))}
              </div>
              </>
          )}
                    
            </div>
            
            :

            <ChatBoxPage setOpenChatClicked = {setOpenChatClicked}/>
            
        }
            </div>
        </Modal>
    );
};

export default ChatModal;

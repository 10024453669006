import React, { Component } from "react";
import Loader from "../../utils/loader";
import "../Cards/DocumentCard.css";
import { Detector } from "react-detect-offline";
import authService from "../../services/authService";
import { Redirect } from "react-router-dom";
export default function(ComposedComponent) {
  class NetworkDetector extends Component {
    state = {};
 
    constructor(props) {
      super(props);
      this.state = {
        isOnline: navigator.onLine, // Initial online state
      };
    }
 
    componentDidMount() {
      window.addEventListener('online', this.handleOnlineStatusChange);
      window.addEventListener('offline', this.handleOnlineStatusChange);
    }
 
    componentWillUnmount() {
      window.removeEventListener('online', this.handleOnlineStatusChange);
      window.removeEventListener('offline', this.handleOnlineStatusChange);
    }
 
    handleOnlineStatusChange = () => {
      this.setState({ isOnline: navigator.onLine });
    }
 
    renderRedirect = () => {
      if (window.location.pathname === "/") {
        return true;
      }
      if (window.location.pathname.includes('/dashboard')) {
        if (!this.props.location.search) {
          return false;
        } else {
          return true;
        }
      }
      if (!authService.getCurrentUser()) {
        return true;
      }
    };
    render() {
      const { isOnline } = this.state;
      return (
        <React.Fragment>
          <Loader loading={!isOnline} online={!isOnline} />
          {this.renderRedirect() && <Redirect to='/login'/>}
          <ComposedComponent {...this.props} />
        </React.Fragment>
      );
    }
  }
 
  return NetworkDetector;
}
import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
// import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/roles`;
} else {
  apiEndpoint = `${herokuUrl}/roles`;
}
export async function getappfeaturesrole(data) {
  let response = await http.post(`${apiEndpoint}/getappfeatureroles2`,data);

  console.log("Roles",response)
  return response;
 
}
export async function getappfeaturerolesDefault() {
  let roles = await http.get(`${apiEndpoint}/rolesbyids`,);
  console.log()
  return roles

}

export async function postappfeaturelist(data) {
  let response = await http.post(`${apiEndpoint}/addnewroles`, data);
  return response;
}
 
export async function editappfeaturerole(_id, updatedData) {
  let response = await http.put(`${apiEndpoint}/updateRole/${_id}`, updatedData);
  return response;
}
 
export async function deleteappfeaturerole(data) {
  let response = await http.post(`${apiEndpoint}/deleteapprole`, (data));
  return response;
 
}

export async function saveFeatures(data) {
  console.log("saveFeatures", data)   
  const response = await http.put(`${apiEndpoint}/addnewroles/save-features`,data);
  return response;
}

export async function updateFeatures(_id, updateData){
  console.log(_id,"zzzzzzzzzzzzzzzz",updateData)
  try{
  let response = await http.put(`${apiEndpoint}/updateRole/${_id}`,updateData)
  return response;

}catch(error){
console.error('Failed to update role features', error) 
}
}

export async function getFeature(data) {
  const response = await http.post(`${apiEndpoint}/AddingRoles`,data);
  return response;
}

export async function getRoles(data) {
  const response = await http.post(`${apiEndpoint}/getRoles`,data);
  return response;
}


export async function updateRoles(data) {
  const response = await http.post(`${apiEndpoint}/updateRoles`,data);
  return response;
}

 
// console.log(response)
export default {
  getappfeaturesrole,
  getappfeaturerolesDefault,
  postappfeaturelist,
  editappfeaturerole,
  deleteappfeaturerole,
  updateFeatures,
  saveFeatures,
  getFeature,
  updateRoles
}
import React from "react";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";
import HomeSubscription from "../HomeSubscription";
import SubAdminNavBar from "../SubAdminSideBar";

import SubAdminSideBar from "../SubAdminSideBar";
import AdminSideNavBar from "../AdminSideNavBar";

const PricingPage = props => {
  const user = authService.getCurrentUser();
  console.log(user);
  if (!user.isAdmin  && !user.isSubAdmin) {
    return (
      <SubAdminSideBar>
        <HomeSubscription />
      </SubAdminSideBar>
    );
  } else  if(user.isAdmin && !user.isSubAdmin){
    return (
      <AdminSideNavBar>
        <HomeSubscription />
      </AdminSideNavBar>
    );
  }else {
    return (
  
      <SubAdminSideBar>
        <HomeSubscription />
      </SubAdminSideBar>
  
     );
  }
};

export default PricingPage;

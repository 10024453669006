import { opportunityActionTypes } from "./opportunity.types"
const INITIAL_STATE = {
    value: {},
    ProposalData:{},
    assetSectionData: [],
    ProjectType:"",
    reminderAssetId :""

}
const rfpManagmentReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case opportunityActionTypes.SET_RFP_DATA: 
            return {
                ...state,
                value: action.payload, // Update value, not features
            };
            case opportunityActionTypes.SET_PROPOSAL_DATA: 
            return {
                ...state,
                ProposalData: action.payload, // Update value, not features
            };   
            case opportunityActionTypes.SET_MYPROJECT:
            return {
            ...state,
            ProjectType: action.payload
            };    
            case opportunityActionTypes.SET_REMINDER_ASSETID:
                return {
                ...state,
                reminderAssetId: action.payload
                };    
        default:
            return state;
    }
};



export default rfpManagmentReducer;
import React, { useState, useEffect, useMemo } from 'react';
import citationService from '../../services/citationService';
import { toast } from 'react-toastify';
import { Card, CardContent, IconButton,Menu, MenuItem, Typography , Checkbox} from '@material-ui/core';
import { Form, Row, Col } from 'react-bootstrap';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';


export const CitationCard = ({  setShow = { setShow },citation, handleCardCheckboxChange, handleDeleteCitation, fetchCitationDetails,initialCitations,displayByDate,yearsArray,citationsByYear }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [cardChecked, setCardChecked] = useState(false);
    console.log(yearsArray,"array")
  
    const filteredCitationsByYear = useMemo(() => {
      return yearsArray.map((year) => ({
        year,
        citations: initialCitations.filter(
          (cit) => cit.publicationDate.year === year
        ),
      }));
    }, [initialCitations, yearsArray]);
  
  
    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    //Duplicate function /////////////////////////////////////////////////
    const handleDuplicateCitation = (id) => {
      console.log('working');
      citationService.Duplicate(id).then((response) => {
        if (response.status === 200) {
          toast.success("Citation duplicated successfully")
          fetchCitationDetails()
        }
      })
    }
  
  
  //handleViewsourceCitation   View source citation function
  const handleViewsourceCitation = (id)=>{
    citationService.GetOnecitation(id).then((response)=>{
      if (response.status === 200) {
        const websiteUrl = response.citationDetails.url;
        if (websiteUrl) {
          let formattedUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://")
            ? websiteUrl
            : `https://${websiteUrl}`;
         
            window.open(formattedUrl, "_blank");
        } else {
          toast.error("No URL found for the citation");
        }
    }})
  }
   
    
  
    return (
     <>
      {displayByDate ? (
        <>
           {filteredCitationsByYear.map(({ year, citations }) => (
            <div key={year}>
              <ul><li>{year}</li></ul>
              {citations.map((filteredCitation) => (
                <Card
                  key={filteredCitation._id}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
                    backgroundColor: 'white',
                  }}
                >
                  <CardContent style={{ position: "relative", padding: "10px" }}>
                    {/* Your card content */}
                    <Checkbox
                      style={{ position: "absolute", top: "10px", left: "10px" }}
                      checked={filteredCitation.isChecked || cardChecked}
                      onChange={() => handleCardCheckboxChange(filteredCitation._id)}
                    />
                    <div style={{ marginLeft: "50px" }}>
                      <Typography variant="h6">{filteredCitation.title}</Typography>
                      <Typography variant="body2" style={{ fontSize: '12px' }}>
                        Full Citation: {filteredCitation.fullCitation}
                      </Typography>
                      <Typography variant="body2" style={{ fontSize: '10px' }}>
                        In-text Citation: {filteredCitation.publicationDate.year}
                      </Typography>
                    </div>
                    {/* <IconButton
                      style={{ position: "absolute", top: "10px", right: "10px" }}
                      onClick={handleMenuClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleDeleteCitation(filteredCitation._id)}>Delete</MenuItem>
                      <MenuItem onClick={() => handleDuplicateCitation(filteredCitation._id)}>Duplicate</MenuItem>
                      {filteredCitation.url && (
                        <MenuItem onClick={() => handleViewsourceCitation(filteredCitation._id)}>View Source</MenuItem>
                      )}
                    </Menu> */}
                  </CardContent>
                </Card>
              ))}
            </div>
          ))}
        
     </>) : <>
     <Card
        style={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
          backgroundColor: 'white',
        }}
      >
        <CardContent style={{ position: "relative", padding: "10px" }}>
          {/* Checkbox */}
          <Checkbox
            style={{ position: "absolute", top: "10px", left: "10px" }}
            checked={citation.isChecked || cardChecked}
            onChange={() => handleCardCheckboxChange(citation._id)}
          />
  
          {/* Citation content */}
          <div style={{ marginLeft: "50px" }}>
            <Typography variant="h6">{citation.title}</Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              Full Citation: {citation.fullCitation}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '10px' }}>
              In-text Citation: {`(${citation.inTextCitation})`}
            </Typography>
          </div>
  
          {/* Menu Icon */}
          <IconButton
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
  
          {/* Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleDeleteCitation(citation._id)}>Delete</MenuItem>
            <MenuItem onClick={() => handleDuplicateCitation(citation._id)}>Duplicate</MenuItem>         
            {citation.url && (
              <MenuItem onClick={() => handleViewsourceCitation(citation._id)}>View Source</MenuItem>
            )}
          </Menu>
        </CardContent>
      </Card>
      </>}
     </>
    );
  };





  export const CoAuthor_CitationCard = ({citation,index, fetchCitationDetails}) =>{
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick =  (event, citationId) => {
      setAnchorEl(event.currentTarget);
      
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    const handleDeleteCitation = async (id) => {
      // Show a confirmation toast
      const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?", 
        () => handleConfirmDeleteCitation(id, toastId), // onConfirm
        () => toast.dismiss(toastId) // onCancel
      );
    
    };
    
    const handleConfirmDeleteCitation = async (id) => {
      try {
        // Call the API to delete the citation
        const response = await citationService.deleteCitation(id);
    
        console.log('Backend Response:', response);
        if (response.status === 200) {
          toast.success('Deleted successfully');
          fetchCitationDetails();
        }
      } catch (error) {
        console.error('Error calling backend API:', error.message);
      }
      handleMenuClose();
    };

    const handleDuplicateCitation = (id) => {
      console.log('working');
      citationService.Duplicate(id).then((response) => {
        if (response.status === 200) {
          toast.success("Citation duplicated successfully")
          fetchCitationDetails()
        }
      })
      handleMenuClose();
    }

    const handleViewsourceCitation = (id)=>{
      citationService.GetOnecitation(id).then((response)=>{
        if (response.status === 200) {
          const websiteUrl = response.citationDetails.url;
          if (websiteUrl) {
            let formattedUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://")
              ? websiteUrl
              : `https://${websiteUrl}`;
           
              window.open(formattedUrl, "_blank");
          } else {
            toast.error("No URL found for the citation");
          }
      }})
      handleMenuClose()
    }

    const showConfirmationToast = (message, onConfirm, onCancel) => {
      const toastId = toast.info(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{marginBottom:'10px'}}><h4>Delete Citation</h4></div>
          <p style={{ marginBottom: '10px' }}>{message}</p>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              onClick={() => {
                onConfirm();
                toast.dismiss(toastId);
              }}
              style={{
                padding: '8px 12px',
                backgroundColor: 'white',
                color: '#1976D2',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Delete Citation
            </button>
            <button
              onClick={() => {
                onCancel();
                toast.dismiss(toastId);
              }}
              style={{
                padding: '8px 12px',
                backgroundColor: 'white',
                color: '#1976D2',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Cancel
            </button>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false, // Do not auto-close the toast
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    
      return toastId;
    };




    return(
      <>
      <Card
                  key={index} // Add a key to each Card element
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
                    backgroundColor: 'white',
                    position:'relative'
                  }}
                >
                  <CardContent style={{ position: "relative", padding: "10px" }}>
                    {/* Checkbox */}
                    {/* Uncomment the Checkbox component if needed */}
                    {/* <Checkbox
                      style={{ position: "absolute", top: "10px", left: "10px" }}
                      checked={citation.isChecked || cardChecked}
                      onChange={() => handleCardCheckboxChange(citation._id)}
                    /> */}
              
                    {/* Citation content */}
                    <div style={{ marginLeft: "30px" }}>
                      <Typography variant="body1"><strong>{citation.title}</strong></Typography>
                      <Typography variant="body2" style={{ fontSize: '12px' }}>
                        Full Citation: {citation.fullCitation}
                      </Typography>
                      <Typography variant="body2" style={{ fontSize: '10px' }}>
                        In-text Citation: {`(${citation.inTextCitation})`}
                      </Typography>
                    </div>
            
                     <IconButton
                      style={{ position: "absolute", top: "10px", right: "10px" }}
                      onClick={(event) => handleMenuClick(event)} 
                    >
                      <MoreVertIcon />
                    </IconButton>
              
                    
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={()=>handleDeleteCitation(citation._id)}>Delete</MenuItem>
                      <MenuItem onClick={()=>handleDuplicateCitation(citation._id)}>Duplicate</MenuItem>         
                      {citation.url && (
                      <MenuItem onClick={()=>handleViewsourceCitation(citation._id)}>View Source</MenuItem>
                     )}  
                    </Menu>
                  </CardContent>
                </Card>
      </>
    )
  }

  export const ContributorForm = ({ contributor, handleDelete, handleInputChange }) => {
    return (
      <>
        <Form>
          <Row>
            {/* Position */}
            <Col md={2}>
              <Form.Group controlId={`position_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Position</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.position}
                  onChange={(e) => handleInputChange('position', e.target.value)}
                />
              </Form.Group>
            </Col>
  
            {/* First Name */}
            <Col md={2}>
              <Form.Group controlId={`firstName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.firstName}
                  onChange={(e) => handleInputChange('firstName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={2}>
              <Form.Group controlId={`middleName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Middle </Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.middleName}
                  onChange={(e) => handleInputChange('middleName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={2}>
              <Form.Group controlId={`lastName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.lastName}
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={2}>
              <Form.Group controlId={`suffix_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Suffix</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.suffix}
                  onChange={(e) => handleInputChange('suffix', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            {/* Middle Name */}
            {/* ... (similar structure for other fields) */}
  
            {/* Delete Icon */}
            <Col md={1}>
              <IconButton onClick={handleDelete} style={{ marginTop: '15px' }}>
                <DeleteIcon style={{ marginTop: '7px' }} />
              </IconButton>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
  
  
  
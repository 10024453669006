import _ from "lodash";
import englishCheckers, { sentimentAnalyzer, getmywords } from "../../services/englishCheckers";
import reportServices from "../../services/reportServices";
export { sentimental, readabilityCheck };
// @ts-check
export function concotArray(_this, response, anchorKey) {
  const { allSuggesion } = _this.state;
  // console.log('allSuggesion', allSuggesion)
  allSuggesion[anchorKey] = response
  _this.state.allSuggesion = allSuggesion;
  const errorList = fnErrorList(_this, allSuggesion, anchorKey)
  _this.state.errorList = errorList;  
 
  const errorCount = errorSeperation(errorList);
  _this.state.errorCount = errorCount;  
  // console.log('allSuggesion', _this.state.allSuggesion, 'anchorKey',anchorKey, response)
}


function fnErrorList(_this, allSuggesion, anchorKey){
  let { errorList } = _this.state;
  const array = allSuggesion[anchorKey];
  let errorData = {}
  array.forEach((obj) =>{
   const pgm = obj.pgm;
   let count = errorData[pgm] || 0;
   count++;
   errorData[pgm] = count
  });
  errorList[anchorKey] = errorData
  return errorList; 
 
}

function errorSeperation(errorList){
  let errorCount ={}
  for( const key in errorList){
     const obj = errorList[key]
     for(const errorKey in obj){
       let count = obj[errorKey];
       let ecount = errorCount[errorKey] || 0;
       errorCount[errorKey] = count + ecount;
     }
  }
  return errorCount;
}

async function sentimental(_this, text) {
  const emoji = await sentimentAnalyzer(text);
  _this.setState({ sent: emoji });
}

async function readabilityCheck(this_, text) {
  const readability = require('readability-meter');
  const grade = await readability.ease(text);
  const grade_score = await readability.grade(text)
  grade.grade = grade_score;
  // Flesch-Kincaid Grade Level : response of readability.grade(text);
  //Flesch Reading Ease : response of readability.ease(text) show the score object,
  //console.log(readability.grade());
  this_.setState({ grade  });
}

export const getDictionaryWords = async(this_) =>{
  let respone = await getmywords();
  if(respone.status === 200)
    this_.state.dictionary = respone.words;
}

export const grammerChecker = async(this_ ,sentences, anchorKey, chkTempeditor=true) => {
  console.log('grammerChecker', anchorKey)
  if (!sentences){
    concotArray(this_, [], anchorKey);
    return false;
  }
  if( chkTempeditor && !tempEditor(this_, anchorKey, sentences))
    return false;
  
  // let matches = await englishCheckers.grammerCheck(sentences,anchorKey);
  // // console.log("matches",anchorKey, matches)
  // if(matches.status == 500){
  //   console.log(sentences, anchorKey)
  //   // console.error(matches);
  //   return false;
  // }
  // const filtmatch = matches.filter(mathch => Object.keys(mathch).length !== 0);
  // concotArray(this_, filtmatch, anchorKey);
  // this_.onSearch(anchorKey);
  return true;
}


// only the grammerCheck api called, if the line is changed by the user
const tempEditor = (this_, anchorKey, text) =>{
  let { tempEditorState } = this_.state;
  if(text == tempEditorState[anchorKey]){
    return false
  }
  else{
    tempEditorState[anchorKey] = text;
    this_.state.tempEditorState =tempEditorState;
    return true;
  }
}

export const restError = (allSuggesion, documentID) =>{
  console.log('allSuggesion', allSuggesion)
  const anchorKeys = Object.keys(allSuggesion)
  const error = anchorKeys.reduce((arr, anchorKey) =>{
    const data_arr = allSuggesion[anchorKey];
    const convert_data = data_arr.map((obj) =>{
              return {
                      documentID,
                      errorName:obj.pgm || 'undefined',
                      userAction:"View", 
                      suggested:"",

                      actual:obj.actual,
                      anchorKey:anchorKey,
                      expected:obj.expected,
                      length:obj.length,
                      message: obj.message,
                      offset:obj.offset,
                      pgm:obj.pgm,
                      span_id:obj.span_id

              }
        });
    return [...arr, ...convert_data]
  }, []);
  console.log("error", error);
  if(error.length > 0) reportServices.addGrammarError2(error);
}
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, IconButton, Menu, MenuItem, Typography, Card } from '@material-ui/core';
import { Editor, EditorState, ContentState } from 'draft-js';

import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import TitleIcon from '@mui/icons-material/Title';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import ImageIcon from '@mui/icons-material/Image';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SegmentIcon from '@mui/icons-material/Segment';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import 'draft-js/dist/Draft.css';
import GrammarCheckAssistant from "./ReusableComponents/GrammarCheckAssistant";
import { CoAuthor_Aireviewcomponent } from "./Forms/FormComponents/CoAuthor_Aireview";
import AIReview_Modal from "./MyDocumentsAIRModal";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MicIcon from '@mui/icons-material/Mic';
import UploadIcon from '@mui/icons-material/Upload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SuggestionCards, RewriteCard, StatisticalFactCard } from "./MyDocumentsNewProjectCards";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';



const MyDocumentsNewProject = () => {

    const [titleEditorState, setTitleEditorState] = useState(EditorState.createEmpty());
    const [contentEditorState, setContentEditorState] = useState(EditorState.createEmpty());

    const [isAIReviewOpen, setIsAIReviewOpen] = useState(false)
    const [showHintIcon, setShowHintIcon] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const [selectedOption, setSelectedOption] = useState(null);
    const [rewriteCard, setRewriteCard] = useState(false)
    const [statisticalFactCard, setStatisticalFactCard] = useState(false)

    

 ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
   
 

    const handleAIReview = () => {
        setIsAIReviewOpen(!isAIReviewOpen)
    }

    const handleContentEditorChange = (event) => {
        setContentEditorState(event);
        const contentState = contentEditorState.getCurrentContent();
        const isContentEmpty = contentState.getPlainText().trim() === '';
        setShowHintIcon(!isContentEmpty);
      }

    const handleTipsAndUpdatesClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (option) => {
        setSelectedOption(option);
        if (option === 'rewrite') {
            setRewriteCard(true);
          }
        if (option === 'statisticalFact') {
        setStatisticalFactCard(true);
        } 
        handleCloseMenu();
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseRewriteCard = () => {
        setRewriteCard(false);
    };

    const handleCloseStatisticalFactCard = () => {
        setStatisticalFactCard(false);
    };
    
    
    

    
    const iconButtonStyle = {
        width: '20px',
        height: '20px',
        borderRadius: '0'
    };

    const iconButtonBorderStyle = {
        width: '20px',
        height: '20px',
        borderRadius: '0',
        borderLeft: '2px solid #ccc'
    };

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return(
        <>
        <div className="container-fluid row">
            <div className="col-md-5">
                <Link to="/mydocuments">
                    <Button variant="contained" color="primary" startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
                        Back
                    </Button>
                </Link>

                <div style={{ maxWidth: '100%', marginBottom: '5px', marginTop: '3%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '5px', borderRadius: '8px', background: 'white' }}>

                    <IconButton style={iconButtonStyle}><UndoIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><RedoIcon /></IconButton>
                    <IconButton style={iconButtonBorderStyle}><TitleIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><FormatSizeIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><TextFormatIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><FormatBoldIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><FormatItalicIcon /></IconButton>
                    <IconButton style={iconButtonBorderStyle}><SegmentIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><FormatListBulletedIcon /></IconButton>
                    <IconButton style={iconButtonBorderStyle}><AttachFileIcon /></IconButton>
                    <IconButton style={iconButtonStyle}><ImageIcon /></IconButton>
                </div>

                {/* <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Title"
                        style={{ marginTop: '10px', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)', padding: '10px', borderRadius: '8px', background: 'white' }}
                /> */}

                <div style={{ marginTop:'10px', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)', padding: '10px 10px', borderRadius: '10px', background: 'white', minHeight: '40px', maxHeight: '40px', overflowY: 'auto' }}>
                    <Editor
                        editorState={titleEditorState}
                        //onChange={handleEditorChange}
                        placeholder="Title"
                    />
                </div >

                <div style={{ position:'relative', marginTop:'10px', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 0px 5px 0px rgba(0,0,0,0.25)', padding: '25px 10px', borderRadius: '10px 10px 0px 0px', background: 'white', minHeight: '270px', maxHeight: '270px' }}>
                    <Editor
                        editorState={contentEditorState}
                        onChange={handleContentEditorChange}
                        placeholder="Document Content"
                    />
                    {showHintIcon && (
                        <IconButton onClick={handleTipsAndUpdatesClick} style={{ position: 'absolute',top:'1px', right:'1px' }}>
                            <TipsAndUpdatesIcon />
                        </IconButton>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        
                    >
                        <MenuItem
                            onClick={() => handleMenuItemClick("rewrite")}
                            sx={{ fontSize: '14px', textAlign: 'center', marginBottom: '8px' }}
                        >
                            <div style={{display:'flex', flexDirection:'column'}}>
                            <div><strong>Rewrite</strong></div>
                            <div><p style={{ fontSize: '12px', margin: '0' }}>Rewrite the sentence</p></div>
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleMenuItemClick("statisticalFact")}
                            sx={{ fontSize: '14px', textAlign: 'center', marginBottom: '8px' }}
                        >
                            <div style={{display:'flex', flexDirection:'column'}}>
                            <div><strong>Statistical Fact</strong></div>
                            <div><p style={{ fontSize: '12px', margin: '0' }}>Add Quantitative data</p></div>
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleMenuItemClick("explain")}
                            sx={{ fontSize: '14px', textAlign: 'center', marginBottom: '8px' }}
                        >
                            <div style={{display:'flex', flexDirection:'column'}}>
                            <div><strong>Explain</strong></div>
                            <div><p style={{ fontSize: '12px', margin: '0' }}>Add a detailed explanation</p></div>
                            </div>
                        </MenuItem>
                    </Menu>

                    {rewriteCard && (<RewriteCard handleCloseRewriteCard={handleCloseRewriteCard}/>)}
                    {statisticalFactCard && (<StatisticalFactCard handleCloseStatisticalFactCard={handleCloseStatisticalFactCard}/>)}


                    
                </div >
                <div  style={{ display:'flex', justifyContent:'space-between', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.25)', padding: '10px 10px', borderRadius: '0px 0px 10px 10px', background: 'white', minHeight: '60px', maxHeight: '60px' }}>
                    <div style={{ display:'flex'}}>
                        <div style={{boxShadow:'3px 2px 8px 0px rgba(0,0,0,0.25)', borderRadius:'20px', width:'40px', height:'40px'}}>
                            <h3 style={{position:'relative', top:'9px', left:'14px'}}>4</h3>
                        </div>
                        <div style={{marginLeft:'10px', marginTop:'3px'}}>
                            <Button variant="outlined" color="primary">Fix Errors</Button>
                        </div>
                    </div>
                    
                    <div>
                        <IconButton style={{width:'30px', height:'20px', marginTop:'5px'}}>                     
                            <AssignmentTurnedInIcon/>
                        </IconButton>
                        <IconButton style={{width:'30px', height:'20px', marginTop:'5px'}}>                     
                            <MicIcon/>
                        </IconButton>
                        <IconButton style={{width:'30px', height:'20px', marginTop:'5px'}}>                     
                            <UploadIcon/>
                        </IconButton>
                        <IconButton style={{width:'30px', height:'20px', marginTop:'5px'}}>                     
                            <ContentCopyIcon/>
                        </IconButton>

                    </div>
                </div>
            </div>




            <div className="col-md-4">
                <div style={{marginTop:'45px', overflowY:'auto'}}>
                    <h3>Suggestions</h3>
                    <SuggestionCards/>
                    <SuggestionCards/>
                    <SuggestionCards/>
                </div>
            </div>




            <div className="col-md-3">

                    <GrammarCheckAssistant
                        handleAIReview={handleAIReview}
                    />

                    {isAIReviewOpen && (
                        
                        <AIReview_Modal handleClose={() => setIsAIReviewOpen(false)}/>
                    )}

            </div>
        </div>
        </>
    )
}

export default MyDocumentsNewProject
import React, { useState, useEffect, useRef } from 'react';
import './FarCauses.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getfarCauses } from '../../services/farCauses';
import Checkbox from '@mui/material/Checkbox';

const FarCauses = () => {
    // const [sideDivVisible, setSideDivVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [masterdata, setMasterdata] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownSubpart, setShowDropdownSubpart] = useState(false);
    const [selectedShowSubpart, setShowselectedSubpart] = useState(false)
    const [selectedPart, setSelectedPart] = useState(null);
    const [iconState, setIconState] = useState({});
    const [farPartshow, setFarPartshow] = useState(false);
    const [selectedFarCauseId, setSelectedFarCauseId] = useState(null);
    const [selectedSubpart, setSelectedSubpart] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [sideDivVisible, setSideDivVisible] = useState(true);


    const descriptionRef = useRef(null);

    const handleSubpartClick = (subpart) => {
        setSelectedSubpart(subpart);
        setSelectedSection(null);
        scrollToDescription();
    };


    const handleSectionClick = (section) => {
        setSelectedSection(section);
        setSelectedSubpart(null);
        scrollToDescription();
    }
    const scrollToDescription = () => {
        if (descriptionRef.current) {
            descriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const handleFarCauseClick = (farCauseId) => {
        setSelectedFarCauseId(farCauseId);
        setFarPartshow(true);
    };

    const selectedFarCause = masterdata.find(farCause => farCause._id === selectedFarCauseId);

    const handlePartClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handlePartSelect = (part) => {
        setSelectedPart(part);
        setShowDropdown(false);
    };
    const handleSubpartSelect = (subpart) => {
        alert("Icon is clicked..!!")
        setSelectedSubpart(subpart);
        setShowDropdown(false);
      
    };
  
    // const toggleSideDiv = () => {
    //     setSideDivVisible(!sideDivVisible);
    // };
    const toggleSideDiv = () => {
        setSideDivVisible(!sideDivVisible);
    };
    
    const getFarCausesData = async () => {
        setLoading(true);
        try {
            const response = await getfarCauses();
            console.log("getAppFeatureRoleresponse", response);
            setMasterdata(response);
        } catch (error) {
            console.log("Error calling backend API:", error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        getFarCausesData();
    }, []);



    console.log(masterdata, "masterdatamasterdata");
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const handleIconToggle = (id) => {
        setIconState(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <>
            <div className="Farcontainer">
                {farPartshow && selectedFarCause ? (
                    <div className='FarcontentDiv'>
                        <h2>{selectedFarCause.title}</h2>
                        <div>
                            {selectedSubpart && (
                                <p style={{ color: 'green' }}>{selectedSubpart.descriptionSubpart}</p>
                            )}
                            {selectedSection && (
                                <p style={{ color: 'green' }}>{selectedSection.descriptionSection}</p>
                            )}
                        </div>
                        {/* Render subparts and sections with click handlers */}
                        <div>
                            {selectedFarCause.subparts.map((subpart, index) => (
                                <div key={index}>
                                    <p
                                        style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                                        onClick={() => handleSubpartClick(subpart)}
                                    >
                                        {subpart.titleSubpart}
                                    </p>
                                    {subpart.sections.map((section, secIndex) => (
                                        <p
                                            key={secIndex}
                                            style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => handleSectionClick(section)}
                                        >
                                            {section.titleSection}
                                        </p>
                                    ))}
                                </div>
                            ))}
                        </div>

                        {selectedFarCause.subparts.map((subpart, subIndex) => (
                            <div key={subIndex}>
                                <p>{subpart.titleSubpart}</p>
                                <p>{subpart.descriptionSubpart}</p>
                                {subpart.sections.map((section, secIndex) => (
                                    <div key={secIndex}>
                                        <p>{section.titleSection}</p>
                                        <p>{section.descriptionSection}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button onClick={() => setFarPartshow(false)}>Back</button>
                    </div>
                ) : (
                    <>
                        {/* Placeholder content when farPartshow or selectedFarCause is not available */}
                        <div className="FarDiv">
                            FAR Clause
                        </div>
                        <div className='horizontal' />
                        <div className="AdditionalDiv">
                            <div className="searchBox">
                                <div className="searchIcon">
                                    <i className="fa fa-search" style={{ color: "#007bff" }}></i>
                                </div>
                                <input type="text" className="searchInput" placeholder="Search Clause" />
                            </div>

                            <div className="rightDivPart" onClick={handlePartClick}>
                                <p>Part</p>
                                <ArrowDropDownIcon />
                                {showDropdown && (
                                    <div className="dropdownMenu">
                                        {masterdata.map(farCause => (
                                            <div key={farCause._id} onClick={() => handlePartSelect(farCause.part)}>
                                                {farCause.part}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="rightDivSubPart">
                                <p>Subpart</p>
                                {
                                    showDropdownSubpart && (
                                        <div className='dropdownMenu'>
                                            {selectedFarCause.subparts.map((subpart, index) => (
                                                <div key={index} onClick={() => handleSubpartSelect(subpart.titleSubpart)}>
                                                    <p>{subpart.titleSubpart}</p>
                                                    
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }

                                <ArrowDropDownIcon />
                            </div>
                            <div className="rightDivSection"><p>Section</p> <ArrowDropDownIcon /></div>
                            <div className="rightDivAdd"> <p>Add</p> <AddIcon style={{ marginLeft: '10px', marginBottom: '6px' }} /></div>
                            <div className="rightDivUpload"><p>Upload</p> <PublishIcon style={{ marginLeft: '10px', marginBottom: '6px' }} /></div>
                            <div className="rightDivDelete">Delete <DeleteIcon /></div>
                            <div className="rightDivFilterAltIcon"><FilterAltIcon /></div>
                        </div>

                        <div className="mainfardiv">
                            <div className="FarClauseHeading">
                                <h3 style={{color:'#1A57F2'}}>Commonly Required FAR Clauses</h3>
                            </div>
                            <div className='FarClausePartTitles' style={{ overflow: 'auto' }}>
                                {masterdata.map(farCause => (
                                    <Accordion key={farCause._id}>
                                        <AccordionSummary
                                            // expandIcon={<AddCircleOutlineIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography style={{ lineHeight: '0.3' }}>
                                                <div key={farCause._id} className="farCause" style={{ lineHeight: '0.8' }}>
                                                    {/* <input type="checkbox" className="checkbox" /> */}
                                                    <Checkbox {...label} />

                                                    {iconState[farCause._id] ? (
                                                        <RemoveCircleOutlineIcon className="blueIcon" onClick={() => handleIconToggle(farCause._id)} />
                                                    ) : (
                                                        <AddCircleOutlineIcon className="blueIcon" onClick={() => handleIconToggle(farCause._id)} />
                                                    )}
                                                    {/* <AddCircleOutlineIcon className="blueIcon" /> */}
                                                    <h3 style={{ marginTop: '-03px' }}>{farCause.title}</h3>
                                                </div>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                {farCause.subparts.map(subpart => (
                                                    <div key={subpart._id} className="farCause" style={{ lineHeight: '0.8' }}>
                                                        <AddCircleOutlineIcon className="blueIcon" style={{ marginTop: '23px',marginLeft:'50px' }} />
                                                        <h4 style={{marginLeft:'20x'}}>{subpart.titleSubpart}</h4>
                                                    </div>
                                                ))}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                <div className="arrowContainer" onClick={toggleSideDiv} >
                    <KeyboardDoubleArrowRightIcon style={{ color: '#fff' }}  />
                </div>
                <div className={`sideFarDiv ${sideDivVisible ? 'visible' : 'hidden'}`}>
                    <div className="numberContainer">
                        {masterdata.map((farCause, index) => (
                            <div key={farCause._id} className="numberItem" onClick={() => handleFarCauseClick(farCause._id)}>
                                {index + 1}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    );
};

export default FarCauses;

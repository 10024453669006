import React, { useState, useRef } from 'react';
import { Editor, EditorState, ContentState, Modifier, SelectionState,RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Popover, List, ListItem, ListItemText, Switch, Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SubscriptIcon from '@mui/icons-material/Subscript'; 
import CodeIcon from '@mui/icons-material/Code';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';
import Immutable from 'immutable';
import { Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
// import {handleresarchContente, handcitationContente} from './CoauthorModal';

const CoAuthorTopMenubar = (props) => {
 
  // File, Edit, Format, Help
  const [fileMenuAnchor, setFileMenuAnchor] = useState(null);
  const [editMenuAnchor, setEditMenuAnchor] = useState(null);
  const [formatMenuAnchor, setFormatMenuAnchor] = useState(null);
  const [helpMenuAnchor, setHelpMenuAnchor] = useState(null);
  const [copyMessage, setCopyMessage] = useState(null);
  const [copyTooltip, setCopyTooltip] = useState(false); 
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);


  const handleMenuClick = (event, menuType) => {
    switch (menuType) {
      case 'file':
        setFileMenuAnchor(event.currentTarget);
        break;
      case 'edit':
        setEditMenuAnchor(event.currentTarget);
        break;
      case 'format':
        setFormatMenuAnchor(event.currentTarget);
        break;
      case 'help':
        setHelpMenuAnchor(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleCloseMenu = () => {
    setFileMenuAnchor(null);
    setEditMenuAnchor(null);
    setFormatMenuAnchor(null);
    setHelpMenuAnchor(null);
  };

  const history = useHistory();

  const handleCloseProject = () => {
    history.push('/coauthor');
  };

  const handleDelete = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    props.onChange(EditorState.createEmpty());
    setDeleteConfirmationOpen(false);
    history.push('/coauthor');
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };


  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const content = props.editorState.getCurrentContent().getPlainText();
    
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write('<pre>' + content + '</pre>');
    printWindow.document.write('</body></html>');
    
    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = () => {
    printWindow.close();
    };
  
    handleCloseMenu();
  };
  const handleExport = () => {
    const content = props.editorState.getCurrentContent().getPlainText();
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_content.txt'; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  const editorRef = useRef(); // Make sure to initialize the editorRef
  
  const handleUndo = () => {
    props.onChange(EditorState.undo(props.editorState));
  };

  const handleRedo = () => {
    props.onChange(EditorState.redo(props.editorState));
  };

  const handleSelectAll = () => {
    const contentState = props.editorState.getCurrentContent();
    const selection = SelectionState.createEmpty(contentState.getFirstBlock().getKey())
      .merge({
        anchorOffset: 0,
        focusKey: contentState.getLastBlock().getKey(),
        focusOffset: contentState.getLastBlock().getText().length,
      });
  
    const newEditorState = EditorState.forceSelection(props.editorState, selection);
    props.onChange(newEditorState);
    handleCloseMenu()
  };
  


  const handleCopy = () => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();
  
    if (selection.isCollapsed()) {
      // No text is selected
      return;
    }
  
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = selection.getStartKey() === selection.getEndKey()
      ? editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText().slice(start, end)
      : editorState.getCurrentContent().getText().slice(start, end);
  
    navigator.clipboard.writeText(selectedText).then(
      function () {
        setCopyTooltip(true);
        setTimeout(() => setCopyTooltip(false), 1000);
    },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };
  
  
  
  const handleCut = () => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithCut = Modifier.removeRange(contentState, selection, 'backward');
      const newEditorState = EditorState.push(editorState, contentStateWithCut, 'remove-range');
      props.onChange(newEditorState);
    }
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = selection.getStartKey() === selection.getEndKey()
      ? editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText().slice(start, end)
      : editorState.getCurrentContent().getText().slice(start, end);
  
    navigator.clipboard.writeText(selectedText).then(
      function () {
        console.log("text copied");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
    
    handleCloseMenu();
  };

  

  const handlePaste = () => {
    navigator.clipboard.readText()
      .then((clipboardText) => {
        const editorState = props.editorState;
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const newContentState = Modifier.replaceText(
          currentContent,
          selection,
          clipboardText
        );
        const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
        props.onChange(newEditorState);
      })
      .catch((error) => {
        console.error("Unable to paste text: ", error);
      });
  };

 

  const renderMenuOptions = (options) => {
    const iconMapping = {
      'New Project': <AddIcon />,
      'Open Project': <FolderOpenIcon />,
      'Import File': <ImportExportIcon />,
      'Rename': <EditIcon />,
      'Delete': <DeleteIcon />,
      'Export': <SaveIcon />,
      'Print': <PrintIcon />,
      'Close Project': <CloseIcon />,
      'Undo': <UndoIcon />,
      'Redo': <RedoIcon />,
      'Cut': <ContentCutIcon />,
      'Copy': <ContentCopyIcon />,
      'Paste': <ContentPasteIcon />,
      'Select All': <SelectAllIcon />,
      'Bold': <FormatBoldIcon />,
      'Italic': <FormatItalicIcon />,
      'Underline': <FormatUnderlinedIcon />,
      'Strikethrough': <StrikethroughSIcon />,
      'Superscript': <SuperscriptIcon />,
      'Subscript': <SubscriptIcon />,
      'Code': <CodeIcon />,
      'Clear Formatting': <FormatClearIcon />,
      'Tutorials': <EmojiObjectsIcon />, 
      'FAQs': <HelpOutlineIcon />,
      'Feedback':<FeedbackIcon />, 
      "What's New":<NewReleasesIcon />
    };
    
    const actionHandlers = {
        'Undo': handleUndo,
        'Redo': handleRedo,
        'Cut': handleCut,
        'Copy': handleCopy,
        'Paste': handlePaste,
        'Select All': handleSelectAll,
        'Bold': handleBold,
        'Italic':handleItalic,
        'Underline':handleUnderline,
        'Strikethrough':handleStrikeThrough,
        'Superscript':handleSuperscript,
        'Subscript':handleSubscript,
        'Code':handleCode,
        'Clear Formatting': handleClearFormatting,
        'Close Project': handleCloseProject,
        'Print': handlePrint,
        'Export': handleExport,
        'Delete': handleDelete,
      };
  
      return options.map((option) => (
        <MenuItem key={option} onClick={() => actionHandlers[option]()}>
          {iconMapping[option]}
          <Typography style={{ marginLeft: '8px' }}>{option}</Typography>
        </MenuItem>
      ));
    };
  
    const handleBold = () => {
        applyInlineStyle('BOLD');
    };   
    const handleItalic = () => {
        applyInlineStyle('ITALIC');
    };
    const handleUnderline = () => {
        applyInlineStyle('UNDERLINE');
    };
    const handleStrikeThrough = () => {
        applyInlineStyle('STRIKETHROUGH');
    };
    const handleSuperscript = () => {
        applyInlineStyle('SUPERSCRIPT');
    };
    const handleSubscript = () => {
        applyInlineStyle('SUBSCRIPT');
    };
    const handleCode = () => {
        applyInlineStyle('CODE');
    };
    const handleClearFormatting = () => {
      const editorState = props.editorState;
      const contentState = editorState.getCurrentContent();
      const plainText = contentState.getPlainText();
      const clearedContentState = ContentState.createFromText(plainText);
      const newEditorState = EditorState.push(
        editorState,
        clearedContentState,
        'remove-range'
      );
      props.onChange(newEditorState);
      handleCloseMenu()
    };

    const applyInlineStyle = (style) => {
      const editorState = props.editorState;
      const selection = editorState.getSelection();
      const currentStyle = editorState.getCurrentInlineStyle();
      
      const newContentState = currentStyle.has(style)
        ? Modifier.removeInlineStyle(
            editorState.getCurrentContent(),
            selection,
            style
          )
        : Modifier.applyInlineStyle(
            editorState.getCurrentContent(),
            selection,
            style
          );
      
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'change-inline-style'
      );
      
      props.onChange(newEditorState);
    };
      


return (
  <>
    
    <div style={{ display: 'flex' ,justifyContent: 'space-between'}}>
      <div className='Left-side-coauthor' style={{ flex: '65%' }}>
        <div style={{ marginTop: '1%',  padding: '5px'}}>
        {copyMessage && <span>{copyMessage}</span>}
        <Button onClick={(e) => handleMenuClick(e, 'file')} style={{textTransform: 'capitalize', fontSize: '15px', color: 'black', width: '5px'}}>File</Button>
      <Popover
        open={Boolean(fileMenuAnchor)}
        anchorEl={fileMenuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        
      
        {renderMenuOptions(['New Project', 'Open Project', 'Import File', 'Rename', 'Delete', 'Export', 'Print', 'Close Project'])}
      </Popover>

      {/* Edit Menu */}
      <Button onClick={(e) => handleMenuClick(e, 'edit')} style={{textTransform: 'capitalize', fontSize: '15px', color: 'black'}}>Edit</Button>
      <Popover
        open={Boolean(editMenuAnchor)}
        anchorEl={editMenuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderMenuOptions([ 'Undo', 'Redo', 'Cut', 'Copy', 'Paste', 'Select All'])}
      </Popover>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '8px',
          borderRadius: '5px',
          display: copyTooltip ? 'block' : 'none',
        }}
      >
        Copied!
      </div>
      {/* Format Menu */}
      <Button onClick={(e) => handleMenuClick(e, 'format')} style={{textTransform: 'capitalize', fontSize: '15px', color: 'black'}}>Format</Button>
      <Popover
        open={Boolean(formatMenuAnchor)}
        anchorEl={formatMenuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderMenuOptions(['Bold', 'Italic', 'Underline','Strikethrough', 'Superscript', 'Subscript', 'Code', 'Clear Formatting'])}
      </Popover>

      {/* Help Menu */}
      <Button onClick={(e) => handleMenuClick(e, 'help')} style={{textTransform: 'capitalize', fontSize: '15px', color: 'black'}}>Help</Button>
      <Popover
        open={Boolean(helpMenuAnchor)}
        anchorEl={helpMenuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderMenuOptions(['Tutorials', 'FAQs', 'Feedback', "What's New"])}
      </Popover>

      <span style={{ textTransform: 'capitalize', fontSize: '15px', color: 'black', marginLeft: '200px'}}>Untitled Project</span>
      {/* Replace the following placeholder with your cloud save icon */}
      <CloudDoneIcon style={{ marginLeft: '5px' }} />
      {/* <Button style={{ textTransform: 'capitalize', fontSize: '13px', color: 'white', marginLeft: '10px', backgroundColor: '#1976D2', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'}}>
        <ShareIcon style={{ marginRight: '5px', tabSize: '5px'}} />
        Share
        </Button>
         */}
        </div>
      </div>
    </div>
    <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the content? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </>
);
};

export default CoAuthorTopMenubar;

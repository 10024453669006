import React, { useState } from 'react'
import { InputText, DropDownComp } from "../Cards/TexboxValidate"

export function ContentList({ head, label, name, submit, clear, handleFn, type }) {

    const [selected, setselected] = useState('')
    const [contenkey, setcontenkey] = useState("")
    const [temp_data, settemp_data] = useState("");
    const [contentSubmit, setcontentSubmit] = useState(false)
    const [errorSelect, seterrorSelect] = useState("")
    const contentKey = ['Subscription Cost', 'What’s Included', 'Reports Module Includes',];
    // let temp_data = {};
    const terms = [
        {
            term: 'Yearly',
            period: 365
        },
        {
            term: 'Monthly',
            period: 30
        },
        {
            term: 'Quarterly',
            period: 168
        },
        {
            term: 'Semester',
            period: 254
        },
        {
            term: 'Trimester',
            period: 45
        },
        {
            term: "none",
            period: 0,
        }
    ]

    function validate(obj) {
        setcontentSubmit(true)
        const { key, value } = obj;
        if (!value) return
        if (!key.trim()) {
            seterrorSelect('error_bor');
            return
        }
        else {
            handleFn(obj)
        }
    }

    return (
        <div>
            <div className="d-flex align-items-center">
                <DropDownComp
                    placeholder="Select Term "
                    selected={selected}
                    terms={terms.map(obj => obj.term)}
                    handleFn={(obj) => setselected(obj.value === "none" ? '' : obj.value )}
                />

                <DropDownComp
                    placeholder="Select List * "
                    selected={contenkey}
                    errorSelect={errorSelect}
                    terms={contentKey}
                    handleFn={(obj) => setcontenkey(obj.value)}
                />
            </div>
            <div className="row">
                <div className="col-md-4">
                    <InputText
                        className="w-100"
                        required={true}
                        label={label}
                        name="value"
                        min={5}
                        submit={contentSubmit}
                        clear={clear}
                        getValue={(obj) => settemp_data(obj.value)}
                    />
                </div>
                <div className="col-md-1 d-flex align-items-end">
                    <button className="btn btn-primary px-5"
                        onClick={() => validate({ value: temp_data, key: `${selected} ${contenkey}` })} >
                        Add</button>
                </div>
            </div>
        </div>
    )
}


export function List({ list, handleFn, type }) {

    return (
        <div className="row">
            <div className="col-md-5">
                <br />
                <ul>
                    {
                        list.map((text) => {
                            return (
                                <li key={text}>
                                    <div className="d-flex justify-content-between">
                                        {text}
                                        <i onClick={() => handleFn({ type, value: text })}
                                            className="fa fa-trash text-primary point" aria-hidden="true"></i>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )

}
import React from "react";
import SubAdminNavBar from "../SubAdminSideBar";
import authService from "../../services/authService";
import Invoice from "../Invoice";
import SideNavBar from "../SideNavBar";

import SubAdminSideBar from "../SubAdminSideBar";

const UserInvoice = () => {
  const user = authService.getCurrentUser();
  console.log(user);
  if (!user.isAdmin && user.isSubAdmin) {
    return (
      <SubAdminSideBar>
        <Invoice />
      </SubAdminSideBar>
    );
  } else {
    return (
      <SubAdminSideBar>
        <Invoice />
      </SubAdminSideBar>
    );
  }
};

export default UserInvoice;

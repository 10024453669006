import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import "./manualOpportunity.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CallIcon from "@mui/icons-material/Call";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CustomDatePicker from "./ReusableComponents/DatePicker";
import locationcountryService from "../services/locationcountryService";
import locationstateService from "../services/locationstateService";
import noticetypesService from "../services/noticetypesService";
import opportunityService from "../services/opportunityService";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import InputTextField from "./ReusableComponents/InputTextField";
import SingleSelectDropDown from "./ReusableComponents/SigleSelectDropDown";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Loader from "../utils/loader";
import TextAreaField from "./ReusableComponents/TextAreaField";
import Axios from "axios";
import { callUseForWorkflow } from "../services/workflowServices";
import { workFlowList } from "../services/assetService";
import Select from 'react-select';
import {
  TextField
} from "@material-ui/core";
import { showToast } from "../messages";

const ManualOpportunity = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [statesData, setstatesData] = useState([]);
  const [countriesData, setcountriesData] = useState([]);
  const [countryState, setCountryState] = useState([]);
  const [noticetypesData, setNoticetypesData] = useState([]);
  const [selectCountries, setSelectCountries] = useState([]);
  const [selectedWorkflowList, setSelectedWorkflowList] = useState();
  const [dueDate, setDueDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [workflowList, setWorkFlowList] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    solicitationNumber: "",
    description: "",
    name: "",
    phoneNumber: "",
    email: "",
    noticeType: "",
    department: "",
    subtier: "",
    office: "",
    resourceLinks: [],
    responseDate: null,
    country: "",
    state: "",
    city: "",
  });
  const [showLable, setShowLabel] = useState(true);
  const handleCalendarOpen = () => {
    setShowLabel(false); // Hide label when calendar opens
  };

  const handleCalendarClose = () => {
    setShowLabel(true); // Show label when calendar closes
  };

  const customStyles1 = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#1976d2",
      color: "white",
      height: "40px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      ":hover": {
        backgroundColor: "#145a86",
        color: "white",
      },
    }),
  };

  const getCountries = async () => {
    try {
      const response = await locationcountryService.getcountry();
      let countryResponse = response.checkcountry;
      setcountriesData(countryResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
    }
  };

  const getStates = async () => {
    try {
      const response = await locationstateService.getlocationStates();
      let stateResponse = response.data;
      setstatesData(stateResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
    }
  };
  const getNoticetypes = async () => {
    try {
      const response = await noticetypesService.getnoticetypes();
      let noticetypesResponse = response.data;
      setNoticetypesData(noticetypesResponse);
    } catch (error) {
      console.error("Error fetching Noticetype data:", error.message);
    }
  };
  console.log(statesData, "notice");
  useEffect(() => {
    getCountries();
    getStates();
    getNoticetypes();
  }, []);

  const noticeoptionsList = noticetypesData.map((item) => ({
    value: item.value,
    name: "noticeType",
  }));
  const countryoptionsList = countriesData.map((item) => ({
    value: item.country,
    name: "country",
  }));
  const stateoptionsList = countryState.map((item) => ({
    value: item.state,
    name: "state",
  }));
  console.log(stateoptionsList, "states");

  const handleChange = (event) => {
    const { name, value } = event;
    console.log(event, "eventt");
    if (name === "country") {
      const selectedCountryCode = value;
      const selectedCountry = countriesData.find(
        (country) => country.countrycode === selectedCountryCode
      );
      const selectedLabel = selectedCountry ? selectedCountry.country : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [name]: value });
      const filteredData = statesData.filter(
        (state) => state.country.country === value
      );
      console.log(value, "filtervalue");
      console.log(filteredData, "filteredData");
      setCountryState(filteredData);
    } else if (name === "noticeType") {
      const selectedNoticeType = noticetypesData.find(
        (type) => type.code === value
      );
      const selectedLabel = selectedNoticeType ? selectedNoticeType.value : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [name]: value });
    } else if (name === "state") {
      const selectedState = statesData.find(
        (state) => state.stateCode === value
      );
      const selectedLabel = selectedState ? selectedState.state : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [name]: value });
    } else {
      console.log("working", event, name);
      setFormData({ ...formData, [name]: value });
    }
  };

  console.log(formData, "form data");

  const handleDateChange = (date) => {
    setFormData({ ...formData, responseDate: date });
  };
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      const org = sessionStorage.getItem("orgToken");
      event.preventDefault();
      console.log(formData, "res");
      const workflow=selectedWorkflowList?.value;
      if(!workflow){
        showToast('Error.selectWorkflow')
      }else{
        const currentUserEmail=sessionStorage.getItem("currentUserEmail")
        const data = { formData, org,workflow,currentUserEmail};
        const response = await opportunityService.addManualOpportunities(data);

        console.log(response.status, "manual_add_response");
        if (response.status === 201) {
          // alert("if");
          // Remove redefinition of formData here
          const filedata = new FormData();
          console.log(formData, "data-form");
          // Check if formData.resourceLinks is defined and is an array before using it
          if (Array.isArray(formData.resourceLinks)) {
            for (const file of formData.resourceLinks) {
              console.log(file, "filesarray");
              const fileFormData = new FormData();
              const type = "attchments";
              fileFormData.append("filename", file);
              fileFormData.append("opportunityid", response.data._id);
              fileFormData.append("folderType", type);

              try {
                // alert(response.data._id);
                const uploadResponse = await Axios.post(`https://api.contractpro360.com/api/newbucket/uploadlinks/${response.data._id}`, fileFormData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
    
                if (uploadResponse.status === 200) {
                  console.log("upload response", uploadResponse);
                  // toast.success("File uploaded successfully");
                  toast.success(response.message);
                } else {
                  console.log("error in upload");
                }
                // Handle success
                console.log("File uploaded successfully");
              } catch (error) {
                console.error("Error uploading file:", error.message);
              } finally {
                setLoading(false);
              }
            }
          } else {
            console.log(
              "formData.resourceLinks is not an array or is undefined"
            );
          }
        } else if (response.status === 400) {
          console.log(response.message, "messagemessage");
          toast.error(response.message);
        } else if (response.status == 200) {
          toast.warn(response.message);
        }
      }
    } catch (error) {
      showToast('Error.errorOccured')
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    setFormData({
      title: "",
      solicitationNumber: "",
      description: "",
      department: "",
      subtier: "",
      office: "",
      name: "",
      phoneNumber: "",
      email: "",
      noticeType: "",
      resourceLinks: [],
      responseDate: null,
      country: "",
      state: "",
      city: "",
    });
  };

  const handleDueDate = (date) => {
    setDueDate(date);
    setFormData({ ...formData, responseDate: date });
  };

  const handleChanges = async (event) => {
    const { name, files } = event.target;
    console.log("Files:", files);
    if (name === "attachments") {
      // Convert the FileList object to an array
      const newFiles = Array.from(files);
      console.log("New files:", newFiles);
      // Update the state with the previous files and the new ones
      setFormData((prevState) => {
        console.log("Old files:", prevState.resourceLinks);
        return {
          ...prevState,
          resourceLinks: [...prevState.resourceLinks, ...newFiles],
        };
      });
    } else {
      setFormData({ ...formData, [name]: event.target.value });
    }
  };

  const redirectToWorkflow = () => {
    setShowModal(false);
    history.push("/workflow");
  };

  const handleSelectWorkflowList = async (option) => {
    console.log("workflow details", option);
    handleUseForWorkflow(option.value);
    setSelectedWorkflowList(option);
  };

  const handleUseForWorkflow = async (documentId) => {
    // enableLoading()
    const email = sessionStorage.getItem("currentUserEmail");
    const orgid = sessionStorage.getItem("organizationId");
    const response = await callUseForWorkflow(documentId, email, orgid);
    if (response.status == 200) {
      console.log(response.message, "useForWorkflow");
    } else {
      console.log(response.message, "useForWorkflow");
    }
    // disableLoading()
  };

  const getWorkflowList = async () => {
    try {
      let data = {
        orgId: sessionStorage.getItem("organizationId"),
      };
      const response = await workFlowList(data);
      console.log("callled", response);
      if (response.status == 200) {
        console.log("workflow list", response);
        setWorkFlowList(response.workflowList);
      }
    } catch (error) {
      console.log(error, "error in geting workflow list ");
    }
  };

  useEffect(() => {
    getWorkflowList();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <Container fluid style={{ paddingLeft:0, paddingRight:0 }}>
        <Row className="form-row">
          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <InputTextField
                customStyles={{ height: "58px" }}
                id="input-id"
                name="title"
                height="54px"
                value={formData.title}
                onChange={handleChanges}
                label="Title"
                type="text"
                screen="ManualOpportunity"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Title"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" >
              <InputTextField
                id="input-id"
                name="solicitationNumber"
                value={formData.solicitationNumber}
                height="54px"
                onChange={handleChanges}
                label="Solicitation Number"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Solicitation Numer"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" >
              <SingleSelectDropDown
                name="Notice Type"
                optionList={noticeoptionsList}
                onChange={handleChange}
                labelKey="value"
                label="Notice Type"
                value={selectedValue || "Notice Type"}
              />
            </div>
          </Col>
        </Row>

        <Row
          className="form-row"
          style={{
            marginTop: window.innerWidth < 768 ? "10px" : 0,
          }}
        >
          <Col xs={12} md={4}>
            <div className="form-container">
              <InputTextField
                customStyles={{ height: "58px" }}
                id="input-id"
                name="department"
                height="54px"
                value={formData.department}
                onChange={handleChanges}
                label="Department"
                type="text"
                screen="ManualOpportunity"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Department"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" >
              <InputTextField
                id="input-id"
                name="subtier"
                value={formData.subtier}
                height="54px"
                onChange={handleChanges}
                label="Subtier"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Subtier"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" >
              <InputTextField
                id="input-id"
                name="office"
                value={formData.office}
                height="54px"
                onChange={handleChanges}
                label="Office"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Office"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
        </Row>
        <h3
          style={{
            color: "#4682B4",
            marginLeft: "8px",
            marginTop: window.innerWidth < 768 ? "10px" : "-5px",
          }}
        >
          Point of Contact
        </h3>
        <Row
          style={{
            marginTop: window.innerWidth < 768 ? "10px" : "-6px",
          }}
          className="form-row"
        >
          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <InputTextField
                id="input-id"
                name="name"
                value={formData.name}
                onChange={handleChanges}
                label="Name"
                height="54px"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                // placeholder= "Enter Your "
                placeholder="Enter Your Name"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <InputTextField
                id="input-id"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChanges}
                label="Phone Number"
                height="54px"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Your Number"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <InputTextField
                id="input-id"
                name="email"
                value={formData.email}
                onChange={handleChanges}
                label="Email"
                height="54px"
                type="text"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Your Email"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
        </Row>

        <h3
          style={{
            color: "#4682B4",
            marginLeft: "8px",
            marginTop: window.innerWidth < 768 ? "10px" : "-5px",
          }}
        >
          Place of Performance
        </h3>
        <Row
          style={{ marginTop: "-6px"}}
          className="form-row"
        >
          {/* <Col>
            <div className="form-container">
              <SingleSelectDropDown
                optionList={countryoptionsList}
                name="Country"
                onChange={handleChange}
                labelKey="value"
                label="Select Country"
                value={selectedValue}
                placeholder="Select Country"
              />
            </div>
          </Col> */}

          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <SingleSelectDropDown
                optionList={countryoptionsList}
                name="Country"
                onChange={handleChange}
                labelKey="value"
                label="Select Country"
                value={selectedValue}
                width="100%"
                height="auto"
              />
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <SingleSelectDropDown
                optionList={stateoptionsList}
                onChange={handleChange}
                labelKey="value"
                label="Select State"
                type="state"
                value={selectedValue || "Select State"}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-container" style={{ width: "100%" }}>
              <InputTextField
                customStyles={{ height: "55px" }}
                id="input-id"
                name="city"
                height="54px"
                value={formData.city}
                onChange={handleChanges}
                label="City"
                type="text"
                screen="ManualOpportunity"
                multiline={false}
                rows={1}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter City"
                autoComplete="off"
                required={true}
              />
            </div>
          </Col>
        </Row>

        <Row
          className="form-row"
          style={{ marginTop: "10px" }}
        >
          <Col xs={12} md={6}>
            <div
              className="form-container"
              style={{ width: "100%", margin: "0 auto" }}
            >
              <DatePicker
                selected={dueDate}
                onChange={(e) => handleDueDate(e)}
                dateFormat="yyyy-MM-dd"
                placeholderText="yyyy-mm-dd"
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "0, 10px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: "viewport",
                  },
                  flip: {
                    enabled: false,
                  },
                }}
                customInput={
                  <TextField
                    label="Select Date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: window.innerWidth < 768 ? 250 : 610}} 
                  />
                }
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              className="form-container"
              style={{
                marginTop: window.innerWidth < 768 ? "10px" : 0,
                width: "100%",
              }}
            >
              <InputTextField
                id="input-id"
                name="attachments"
                // value={formData.resourceLinks} // No need to set value for file input
                onChange={handleChanges}
                label="Attachments"
                type="file"
                multiple // Allow multiple file selection
                // multiline={false}
                // rows={1}
                // helperText="Helper text"
                fullWidth={true}
                // placeholder="Enter Your Email"
                autoComplete="off"
              />
            </div>
          </Col>
        </Row>
        <Row
          className="form-row"
          style={{ marginTop: "10px"}}
        >
          <Col xs={12} md={6}>
            <div
              className="form-container"
              style={{ minHeight: "195px", height: "auto", width: "100%" }}
            >
              <TextAreaField
                id="input-id"
                name="description"
                value={formData.description}
                onChange={handleChanges}
                label={showLable ? "Description" : ""}
                type="text"
                multiline={false}
                rows={7}
                helperText="Helper text"
                fullWidth={true}
                placeholder="Enter Description"
                autoComplete="off"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <p
              style={{
                width: "100%",
                color: "rgb(61, 131, 250",
                fontSize: "17px",
              }}
            >
              Choose the workflow that best suits your needs! We offer a default
              option to get you started, or you can create a custom dynamic
              workflow. Selecting a workflow is mandatory to proceed.
            </p>
            <div style={{ width: "100%" }}>
              <Button
                onClick={redirectToWorkflow}
                style={{ cursor: "pointer", width: "100%", height: "40px" }}
                variant="contained"
                color="primary"
              >
                Add Workflow
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "10px",
                justifyContent: "space-between",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "100%", height: "40px" }}>
                <Select
                  // isMulti
                  options={workflowList.map((workflow) => ({
                    value: workflow._id,
                    label: workflow.workflowName,
                  }))}
                  value={selectedWorkflowList || ""}
                  onChange={(selectedOptions) =>
                    handleSelectWorkflowList(selectedOptions)
                  }
                  styles={customStyles1}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: window.innerWidth < 768 ? "20px" : 0,
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{ width: "100%", maxWidth: "600px" }}
          >
            <Button
              onClick={handleCancel}
              variant="outlined"
              style={{
                width: window.innerWidth <768 ? "100%" : "150px",
                fontSize: "15px",
                backgroundColor: "white",
                outline: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              style={{
                width: window.innerWidth <768 ? "100%" : "150px",
                fontSize: "17px",
                fontWeight: "bold",
              }}
            >
              Save
            </Button>
            <Button
              // onClick={handleClose}
              variant="outlined"
              style={{
                width: window.innerWidth <768 ? "100%" : "150px",
                fontSize: "15px",
                backgroundColor: "white",
                outline: "none",
              }}
            >
              <Link
                to="/OppourtunityManagementSubadmin"
                title="Opportunity Management"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Close
              </Link>
            </Button>
          </Stack>
        </Row>
      </Container>
    </>
  );
};

export default ManualOpportunity;

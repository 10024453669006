import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import { makeStyles } from "@material-ui/core/styles";
import HeadingB from "./Cards/HeadingB";
import BreadCrumb from "./Cards/BreadCrumbs";
import { feature } from "caniuse-lite";
import {
    submitContactForm
} from "../services/userService";
import  support_illustration from "../static/support_illustration.png"
import { toast, ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { showToast } from "../messages";


const useStyles = makeStyles({
    root: {
        minWidth: 150,
        width: 150,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


function ContactUs({ }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [email, setemail] = useState("");
    const [feature, setfeature] = useState("");
    const [message, setmessage] = useState("");

    const history = useHistory();

    const handleGoBack = () => {
        history.push('/dashboard');
    };


    const handleSubmit = async () => {
        if (!email) {
            showToast('Warning.enterEmail')
        } else if (!feature) {
            toast.warn("Please Select Feature.");
        } else if (!message) {
            toast.warn("Please write message to us.");
        }
        else {
            let details = {
                userEmail: email,
                selectedFeature: feature,
                message: message,

            };
            console.log(details)
            let response = await submitContactForm(details);
            console.log(response)
            if (response.status === 200) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }

        }
        setfeature("")
        setmessage("")
        setemail("");
    };



    const options = [
        {
            text: "Newsletter",
            value: "newsletter",
            icon: "sticky note outline"
        },
        { text: "Alerts", value: "alert", icon: "bell outline" }
    ];

    return (
        <div>
            
            <BreadCrumb
            // style={{backgroundColor:"white !important"}}
            l1="Home"
            l2="Support"
            dashboardlink="/dashboard"
          />
        <div className="container-fluid h-100" id="DocCard">
            <Loader loading={loading} />
            <ToastContainer />
            <div className="container" style={{padding:'30px 0px'}} >
            
                {/* <HeadingB className="text-center alertHead pt-5" text="SUPPORT" /> */}
                <Typography variant="h4"><b>Support</b></Typography>
               <div className="row">
                   <div className="col-md-6"> <div className="mt-5">
                    <TextField 
                        id="introTitle" 
                        label="Email" 
                        variant="outlined" 
                        placeholder="Enter Email ID" 
                        style={{ width: '100%' }} 
                        onChange={(e) => setemail(e.target.value)} 
                        value={email || ""} 
                        name="Email" 
                        InputProps={{ inputProps: { maxLength: 50, type: "email" } }} 
                        className="mt-2" 
                    />


                </div>
        

                <div className="mt-5">
                <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={feature}
                            onChange={(e) => setfeature(e.target.value)}
                            label="Reason"
                        >
                            <MenuItem value="">
                                <em>Choose your option</em>
                            </MenuItem>
                            <MenuItem value="textEditor">Text Editor</MenuItem>
                            <MenuItem value="teams">Teams</MenuItem>
                            <MenuItem value="blogs">Blogs</MenuItem>
                            <MenuItem value="citation">Citation</MenuItem>
                            <MenuItem value="paraphrasing">Paraphrasing</MenuItem>
                            <MenuItem value="plagarism">Plagiarism</MenuItem>
                            <MenuItem value="reports">Reports</MenuItem>
                            <MenuItem value="subscription">Subscription</MenuItem>
                            <MenuItem value="invoices">Invoices</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="mt-5">
                    <TextField 
                        id="introSubtext" 
                        label="Description" 
                        variant="outlined" 
                        placeholder="Write here! We are listening..." 
                        style={{width:'100%'}}
                        onChange={(e) => setmessage(e.target.value)} 
                        value={message || ""} 
                        name="Project_subtext" 
                        InputProps={{ inputProps: { maxLength: 350 } }} 
                        multiline
                        rows={6}
                        
                    />

                </div>

                <div className="mt-3 text-center">

                <Button variant="outlined" color='error' style={{marginRight:'20px'}} onClick={handleGoBack}>Cancel</Button>
                <Button variant="contained" color='primary'  onClick={handleSubmit}>Submit</Button>


                    {/* <button
                        className="btn btn-primary mb-4"
                        type="submit"
                        id="backBtn"
                        onClick={handleSubmit}
                        style={{
                            width: "20%",
                        }}
                    // disabled={buttonDisable}
                    >
                        Submit
          </button> */}
                </div></div>
                <div className="col-md-6">
                <img
                src={support_illustration}
                style={{ width: "100%", height: "100%" }}
                alt="Contact Us"
              />
                </div>
               </div>
            </div>
        </div>
        </div>
    );
}
export default ContactUs;

import React from 'react';
import Blog from '../Blog';
import SideNavBar from '../SideNavBar';

const BlogSection = () => {
    return (
        <SideNavBar>
            <Blog />
        </SideNavBar>
    );
}

export default BlogSection;
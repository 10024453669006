import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/assetsharing`;
   
  } else {
    apiEndpoint = `${apiUrl}/assetsharing`;
   
  }
 

 
  export async function getassetsharingbyid(data){
    console.log(data,"dataddddd")
    const response=await httpService.post(`${apiEndpoint}/getassetsharingbyid`,{data});
    return response;
  }


  export async function sendFinalEmail(data){
    console.log(data,"dataddddd")
    const response=await httpService.post(`${apiEndpoint}/sendFinalEmail`,{data});
    return response;
  }

  

  export default {
    getassetsharingbyid,
    sendFinalEmail
  }
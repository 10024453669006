import React from 'react';
import OppourtunityManagement from "../OppourtunityManagement"
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SubAdminSideBar from '../SubAdminSideBar';
const OppourtunityManagementPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <OppourtunityManagement />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(OppourtunityManagementPage);



// @ts-check
import React, { useState } from "react";

export default function ThreeBtn({ btn_list, planPeriod, pricebox }) {
  const [btn_color, setbtn_color] = useState(
    Array(btn_list.length)
      .fill("btn-outline-primary")
      .fill("btn-primary", 0, 1)
  );

  const btnOnclick = (text, index) => {
    let fill_color = btn_color.fill("btn-outline-primary");
    fill_color[index] = "btn-primary";
    setbtn_color([...fill_color]);
    planPeriod(text);
  };

  return (
    <div>
      {pricebox ? (
        <div>
          {/* home subcription */}
          {btn_list.length > 1 &&
            btn_list.map((text, index) => {
              let btn_class = btn_color[index];
              if (btn_class == "btn-outline-primary") btn_class = "text-dark";
              else btn_class = "text-primary";

              return (
                <span
                  className={`mr-2 ${btn_class}`}
                  //    type="button"
                  //    class={`btn px-5 py-2 `}
                  onClick={() => btnOnclick(text, index)}
                >
                  {text}
                </span>
              );
            })}
        </div>
      ) : (
        <div className="btn-group" role="group">
          {btn_list.length > 1 &&
            btn_list.map((text, index) => {
              let btn_class = btn_color[index];
              return (
                <button
                  type="button"
                  className={`btn px-5 py-2 ${btn_class}`}
                  onClick={() => btnOnclick(text, index)}
                >
                  {text}
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
}

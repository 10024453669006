import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/noticetypes";
} else {
    apiEndpoint = apiUrl + "/noticetypes";
}
   
 
  export async function getnoticetypes(){
    const response=await httpService.get(`${apiEndpoint}/getnoticetypes`);
    return response;
  }


   
  export default {
   
    
    getnoticetypes
   
  }
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Mic, Upload, CheckBox, Download, FileCopy } from '@mui/icons-material';
import { checkTranslator, checkTranslatorLang } from '../../services/translatorServices';
import { ContentState, Editor, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import style from "./translator.module.css";
import { Circle } from "@mui/icons-material";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { toast, ToastContainer } from "react-toastify";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import styled from "styled-components";
import Loader from "../../utils/loader";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Translator_1 = () => {

  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [showCount, setShowCount] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [wordsCount, setWordsCount] = useState(0)
  const [opWordsCount, setOpWordsCount] = useState(0)
  const [wordCheck, setWordCheck] = useState("")
  const [loading, setLoading] = useState(false);
  const [hasText, setHasText] = useState(false);
  const [interchangeClicked, setInterchangeClicked] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('');


  const [languageList, setLanguageList] = useState([
    {
      value: '',
      name: 'Select'
    },
    {
      "value": "English",
      "name": "English"
    },
    {
      "value": "Spanish",
      "name": "Spanish"
    },
    {
      "value": "German",
      "name": "German"
    },
    {
      "value": "Chinese",
      "name": "Chinese"
    },
    {
      "value": "Japanese",
      "name": "Japanese"
    },
    {
      "value": "Russian",
      "name": "Russian"
    },
    {
      "value": "Arabic",
      "name": "Arabic"
    },
    {
      "value": "French",
      "name": "French"
    },
    {
      "value": "Portuguese",
      "name": "Portuguese"
    }

  ]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [industryList, setIndustryList] = useState([
    {
      value: '',
      name: 'Select'
    },
    {
      "value": "IT",
      "name": "IT"
    },
    {
      "value": "Healthcare",
      "name": "Healthcare"
    },
    {
      "value": "Finance",
      "name": "Finance"
    },
    {
      "value": "Agriculture",
      "name": "Agriculture"
    },
    {
      "value": "Education",
      "name": "Education"
    },
    {
      "value": "Retail",
      "name": "Retail"
    },
    {
      "value": "Telecommunication",
      "name": "Telecommunication"
    }
  ]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [professionList, setProfessionList] = useState([
    {
      value: '',
      name: 'Select'
    },
    {
      "value": "Content Writer",
      "name": "Content Writer"
    },
    {
      "value": "Bussiness Owner",
      "name": "Bussiness Owner"
    },
    {
      "value": "HR Professional",
      "name": "HR Professional"
    },
    {
      "value": "Marketing",
      "name": "Marketing"
    },
    {
      "value": "Product Manager",
      "name": "Product Manager"
    },
    {
      "value": "Medical Professional",
      "name": "Medical Professional"
    },
    {
      "value": "Legal Professional",
      "name": "Legal Professional"
    }
  ]);
  const [selectedProfession, setSelectedProfession] = useState('');






  const handleDetect = async () => {
    try {
      if (inputText === '') {
        return;
      }
      const response = await checkTranslatorLang(inputText);
      const detectedLanguage = response.data;
      setDetectedLanguage(detectedLanguage);
      console.log('text', inputText)
    } catch (error) {
      console.error('Error calling backend API:', error.message);
    }
  };


  const handleTranslate = async () => {
    if (editorState.getCurrentContent().getPlainText().trim().split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded");
      return
    }

    setLoading(true)
    try {
      let input = editorState.getCurrentContent().getPlainText()
      if (input === '') {
        toast.warn('Please enter text to translate');
        setLoading(false)
        return;
      }
      if (selectedLanguage == '') {
        toast.warn('Please Select the Language to translate');
        setLoading(false)
        return;
      }

      console.log("SelectedLang_", selectedLanguage, selectedIndustry, selectedProfession)

      let data
      if (selectedProfession !== "" && selectedIndustry !== "") {
        data = {
          text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20considering%20yourself%20as%20a%20${selectedProfession}%20working%20in%20${selectedIndustry}%20industry-${input} `
        }
      } else if (selectedProfession !== "") {
        data = {
          text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20considering%20yourself%20as%20a%20${selectedProfession}-${input} `
        }
      } else if (selectedIndustry !== "") {
        data = {
          text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20considering%20yourself%20working%20in%20${selectedIndustry}%20industry-${input} `
        }
      } else {
        data = {
          text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20-${input}`
        }
      }

      const response = await checkTranslator(data)
      const translatedText = response?.data?.output;


      if (response.status == 400) {
        toast.warn(response.message)
        return
      }
      let textWithoutLeadingNewlines = translatedText.replace(/^\n+/, '');
      let wordLength = translatedText.split(/[ -]+/)
      // console.log("paraphraseResponse_", response, wordLength.length);

      setOpWordsCount(wordLength.length)
      const contentState = ContentState.createFromText(textWithoutLeadingNewlines);
      const newEditorState = EditorState.createWithContent(contentState);
      setTranslatedText(translatedText);
      setSecondaryEditorState(newEditorState)
      setLoading(false)
    } catch (error) {
      console.error('Error calling backend API:', error.message);
      toast.warn(error.message)
      setLoading(false)
    }
  };

  const wordCount = inputText.trim().split(/\s+/).filter(Boolean).length;
  const wordCount2 = translatedText.trim().split(/\s+/).filter(Boolean).length;

  const handleInput = (e) => {
    let value = e.target.value
    if (value == "") {
      setDetectedLanguage('')
    }
    setInputText(e.target.value)
  }

  useEffect(() => {
    console.log("Detector_", inputText, wordCheck)
    if (inputText !== "" && wordCheck !== '') {
      handleDetect()
    }
  }, [wordCheck])

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const plainTextContent = newEditorState.getCurrentContent().getPlainText();
    setInputText(plainTextContent);

    if (plainTextContent === "") {
      setDetectedLanguage("");
      setSelectedIndustry("");
      setSelectedProfession("");
      setWordsCount(0);
      setOpWordsCount(0);
      setHasText(false);
    } else {
      setHasText(true);
    }

    if (plainTextContent !== "") {
      const trimmedText = plainTextContent.trim();
      const words = trimmedText.split(/\s+/).filter(word => word !== '').length;
      let splitinput = plainTextContent.split(" ");
      if (splitinput.length > 1) {
        setWordCheck(splitinput[0]);
      }
      console.log("words_", words)
      setWordsCount(words);
    }

   
    // if (plainTextContent !== "") {
    //   let splitinput = plainTextContent.split(" ");
    //   if (splitinput.length > 1) {
    //     setWordCheck(splitinput[0]);
    //   }
    //   let words = plainTextContent.split(/[ -\s]+/).length;
    //   setWordsCount(words);
    // }
    // const contentState = newEditorState.getCurrentContent();
    // const pastedText = contentState.getPlainText();
  
    // if (pastedText.trim() !== "") {
    //   // Create a new content state from plain text to remove styles
    //   const pastedContentState = ContentState.createFromText(pastedText);
    //   const newEditorStateAfterPaste = EditorState.push(
    //     newEditorState,
    //     pastedContentState,
    //     'insert-characters'
    //   );
    //   const editorStateWithCursorAtEnd = EditorState.moveFocusToEnd(newEditorStateAfterPaste);
    //   setEditorState(editorStateWithCursorAtEnd);
    //   console.log("Pasted text:", pastedText);
    // }
    
  };

  const handleOuputChange = (newEditorState) => {
    console.log("words_", newEditorState)
    const contentState = newEditorState.getCurrentContent();
    setSecondaryEditorState(newEditorState)
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    console.log("editorstate_", newState)
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handlecopy = () => {
    // console.log('editorRight', editorRight);
    let text = secondaryeditorState.getCurrentContent().getPlainText()
    if (text == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (text) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleDownload = () => {
    // const contentState = outputSentence;
    // const rawText = outputSentence
    // const textToDownload = rawText.blocks.map(block => block.text).join('\n');
    if (secondaryeditorState.getCurrentContent().getPlainText() == "") {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([secondaryeditorState.getCurrentContent().getPlainText()], { type: 'text/plain' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  const handleLanguage = (e) => {
    console.log("eData_", e)
    let value = e?.target?.value
    console.log("eData_", e, value)

    setSelectedLanguage(value !== undefined ? value : e)
    setSecondaryEditorState(EditorState.createEmpty())

  }

  const handleFileChange = (e) => {
    console.log("files_", e)
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const text = content.toString();

        const contentState = ContentState.createFromText(text);
        const newEditorState = EditorState.createWithContent(contentState);

        setEditorState(newEditorState);
      };

      reader.readAsText(file);
    }
  };

  const handleDelete = () => {
    setEditorState(EditorState.createEmpty());
    setSecondaryEditorState(EditorState.createEmpty());
    setInputText("");
    setTranslatedText("");
    setDetectedLanguage("");
    setSelectedIndustry("");
    setSelectedProfession("");
    setSelectedLanguage("");
    setWordsCount(0);
    setOpWordsCount(0);
    setWordCheck("");
    setHasText(false);
  };

  const handleInterchange = () => {
    // Swap detectedLanguage and selectedLanguage
    const temp = detectedLanguage;
    console.log("temp_", temp)
    setDetectedLanguage(selectedLanguage);
    handleLanguage(temp)
    // setSelectedLanguage(temp);

    // Swap inputText and translatedText in the editor
    const tempText = inputText;
    setInputText(translatedText);
    setTranslatedText(tempText);

    // Swap editorState and secondaryeditorState
    const tempEditorState = editorState;
    console.log("editor_", tempEditorState, secondaryeditorState);
    setEditorState(secondaryeditorState);
    setSecondaryEditorState(tempEditorState);

    const firstEditor = secondaryeditorState.getCurrentContent().getPlainText();
    const secondEditor = tempEditorState.getCurrentContent().getPlainText();
    handleWordsCount(firstEditor, "first")
    handleWordsCount(secondEditor, "second")

    // Update the dropdown value
    setDropdownValue(selectedLanguage);
  };

  const handleWordsCount = (text, type) => {
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/).filter(word => word !== '').length;
    console.log("words_", words)
    if (type == "first") {
      setWordsCount(words);
    } else {
      setOpWordsCount(words)
    }
  }

  useEffect(() => {
    // Update the dropdown value after selectedLanguage changes
    console.log("selectedLang_", selectedLanguage)
    setDropdownValue(selectedLanguage);
  }, [selectedLanguage]);

  
  return (
    <div>
      <Loader loading={loading} />

      <div className='row mt-4 px-1'>
        <div className="d-flex justify-content-evenly col-md-6">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(detectedLanguage == '') ? (
              <Button
                variant="light"
                disabled={true}
                // className="mr-2"
                // onClick={handleDetect}
                style={{
                  borderColor: '#1976D2',
                  color: '#1976D2',
                  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                  width: '130px',
                  transition: 'box-shadow 0.3s',
                  fontSize: '14px'
                }}
              >
                Detect Language
              </Button>
            ) : (
              <Button
                variant="light"
                // className="mr-2"
                // onClick={handleDetect}
                disabled={true}
                style={{
                  borderColor: '#1976D2',
                  color: '#1976D2',
                  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.3s',
                  fontSize: '14px',
                  width: '130px',
                }}
              >
                {detectedLanguage}
              </Button>
            )}
          </div>
          <div className='ml-2 bg-white' style={{ width: '150px', color: 'blue' }} >

            <SelectDropdownMui
              optionsList={professionList}
              selectLabel={"Select Profession"}
              onChange={(e) => setSelectedProfession(e.target.value)}
              value={selectedProfession}
            />
          </div>
          <div className='ml-2 bg-white' style={{ width: '140px' }}>
            <SelectDropdownMui
              optionsList={industryList}
              selectLabel={"Select Industry"}
              onChange={(e) => setSelectedIndustry(e.target.value)}
              value={selectedIndustry}
            />
          </div>
          <div className={`ml-3 flex  ${style.swapContainer}`} title={'Interchange'} onClick={handleInterchange} style={{ width: "62px", marginLeft: '-5px', height: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '2px' }} >
            <div style={{ backgroundColor: 'transparent', transition: 'background-color 0.3s ease' }}  >
              <SwapHorizIcon className={`${style.swapIcon}swapIcon`} style={{ color: "#7C7C7C", cursor: "pointer" }} />
            </div>
          </div>
        </div>
        <div className='ml-4 bg-white ' style={{ width: '150px' }}>
          <SelectDropdownMui
            optionsList={languageList}
            selectLabel={"Select Language"}
            onChange={(e) => handleLanguage(e)}
            value={selectedLanguage}
            id="languageDropdown"
          />
        </div>
      </div>
      <div className="row mt-2 ">
        <div className='col-md-6'>
          <div className="editor  ft_md" style={{
            paddingRight: "42px",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflowY: 'auto',
            borderRadius: "8px",
            paddingBottom: "100px",
            boxShadow: "2px 2px 5px -2px #000000",
          }}
          >
            {hasText && (
              <div className="delete-icon" title={'Delete'} onClick={() => handleDelete()}>
                <AiOutlineDelete size={"20px"} style={{ color: "grey", cursor: "pointer", position: "absolute", top: "20px", right: "30px" }} />
              </div>
            )}

            <Editor
              onChange={handleEditorChange}
              // handleBeforeInput={_handleBeforeInput}
              // keyBindingFn={myKeyBindingFn}
              editorState={editorState}
              // plugins={plugins}
              autoCapitalize="sentences"
            // handlePastedText={handlePastedText}
            // onPaste={(text, html) => handlePastedText(text, html, editorState)}

            />
          </div>
          <div className={`text-e d-flex mt-3 justify-content-between bottomeditor ${style.moveup}`} style={{ boxShadow: "2px 2px 5px -2px #000000", height: "52px" }}>
            <div>
              <p className="ft_sm mt-1 d-flex" style={{ alignItems: "center" }}>
                <Circle style={{ height: "12px", width: "13px", color: "#3295F7" }} />
                <div className="ml-2" style={{ color: "#5F5F5F", fontSize: "16px", fontWeight: "400px", color: "#3295F7" }}><b>{wordsCount}/1500 Words</b></div>
              </p>
            </div>
            

          <div>
              {/* <button className={`${style.btn_trns} pt-1 px-3`}
                 onChange={e => handleFileChange(e)}
              >
                <FileUploadIcon
                  size={"20px"}
                  style={{ color: "#7C7C7C" }}
                ></FileUploadIcon>
                <VisuallyHiddenInput type='file' />
              </button> */}
              <Button
                variant="primary"
                className=""
                style={{ borderRadius: "8px", marginRight: "10px" }}
                onClick={handleTranslate}
                disabled={editorState !== "" ? false : true}
              >
                Translate
              </Button>
            </div>
          </div>

          
        </div>
        <div className='col-md-6'>
          <div className="editor ft_md w-100" style={{
            paddingRight: "5%",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflowY: "auto",
            borderRadius: "8px",
            paddingBottom: "100px",
            boxShadow: "2px 2px 5px -2px #000000",
          }}
          >
            <Editor
              editorState={secondaryeditorState}
              onChange={handleOuputChange}
              readOnly={true}
            // handleKeyCommand={handleKeyCommand}
            // readOnly={secondaryeditorState.getCurrentContent().getPlainText() !== "" ? false : true}
            // plugins={undoredoPlugin}
            />
          </div>
          <div className={`text-e d-flex mt-3 justify-content-between bottomeditor ${style.moveup}`} style={{ boxShadow: "2px 2px 5px -2px #000000", height: "52px" }}>
            <div>
              <p className="ft_sm mt-1 d-flex" style={{ alignItems: "center" }}>
                <Circle style={{ height: "12px", width: "13px", color: "#3295F7" }} />
                <div className="ml-2" style={{ color: "#5F5F5F", fontSize: "16px", fontWeight: "400px", color: "#3295F7" }}><b>{opWordsCount}/1500 Words</b></div>
              </p>
            </div>
            <div>
              <button className={`${style.btn_trns} pt-1 px-2`}
                onClick={handleDownload}
                title={"Download"}
              >
                <DownloadSharpIcon
                  size={"20px"}
                  style={{ color: "#7C7C7C" }}
                />
              </button>
              <button className={`${style.btn_trns} px-2`}
                onClick={handlecopy}
                title={"Copy"}
              >
                <AiOutlineCopy
                  size={"20px"}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={"Copy to clipboard"}
                  style={{ color: "#7C7C7C" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <li className="mt-2" style={{color: "#3295F7"}}><b>1500 Words limit per search</b></li> */}
    </div>
  );
}

export default Translator_1;

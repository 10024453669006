import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, TextareaAutosize, IconButton } from '@mui/material';
import InputBoxMui from './InputBoxMui';
import CloseIcon from '@mui/icons-material/Close';
 
import { editfeaturelist, postfeaturelist } from '../../services/features';
 
 
const ModalUpdateMUI = ({ onSave, onClose,open,close, editValues }) => {
    const [nameValue, setNameValue] = useState ('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [frontendurlValue, setFrontendurlValue] = useState(''); 
    const [apiurlValue, setApiurlValue] = useState('');
    const [moduleValue, setModuleValue] = useState('');
    const [submoduleValue, setSubmoduleValue] = useState('');



    useEffect(() => {
        setNameValue(editValues.name || '');
        setDescriptionValue(editValues.description || '');
        setFrontendurlValue(editValues.frontendurl || '');
        setApiurlValue(editValues.apiurl || '');
        setModuleValue(editValues.module || '');
        setSubmoduleValue(editValues.submodule || '');
    }, [editValues]);

    // const [open, setOpen] = useState(false);
 
    // const handleOpen = () => {
    //     setOpen(true);
    // };
 
    // const handleClose = () => {
    //     setOpen(false);
    // };


    //  const handleUpdateClick = async () => {
    //     try {
    //         // Prepare the updated feature data
    //         const updatedData = { id: editValues.id, /* other updated fields */ };
    //         // Invoke the onUpdate function passed as a prop
    //         await onUpdate(updatedData);
    //         // Close the modal or perform any other necessary actions upon successful update
    //         close(); // Assuming close() closes the modal
    //     } catch (error) {
    //         console.error("Error updating feature:", error.message);
    //         // Optionally, display an error message or handle the error in another way
    //     }
    // };
 
    const handleSave =  async () =>  {
        close()
        const formData = {
            name: nameValue,
            description: descriptionValue,
            frontendurl: frontendurlValue,
            apiurl: apiurlValue,
            module: moduleValue,
            submodule: submoduleValue,
           
        };
 
        // console.log("formdata",formData)
       try{
        const response = await editfeaturelist(editValues.id,formData);
        console.log("EditValues",editValues.id)
       
            console.log("test",response)
            onSave(formData);
        
       }
       catch(error){
        console.log("error in getting respone ",error)
       }
       
        
        // handleClose(); // Close the modal after saving
    };
 
 
    return (
        <div style={{  }}>
            {/* <button
                className="btn btn-primary inviteMember"
                style={{ width: "90%" }}
                // onClick={handleOpen}
            // disabled={true}
            >
                Add features
            </button> */}
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{
                    // position: 'absolute',
                    // top: '50%',
                    // left: '50%',
                    // transform: 'translate(-50%, -50%)',
                    width: 400,
                    // bgcolor: 'white', // Background color
                    borderRadius: '8px', // Border radius
                    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    padding: '20px', // Padding
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        <h2>Add features</h2>
                        <IconButton >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Name"
                        value={nameValue}
                        onChange={(e) => setNameValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem' , backgroundColor:'#fff'}} // Add some margin
                    /> */}
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="name"
                            label="name"
                            value={editValues}
                            onChange={(e) => setNameValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="description"
                            value={editValues}
                            onChange={(e) => setDescriptionValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    {/* <TextareaAutosize
                        aria-label="minimum height"
                        value={descriptionValue}
                        onChange={(e) => setDescriptionValue(e.target.value)}
                        minRows={3}
                        placeholder="Description"
                        style={{ width: '100%', marginBottom: '0.2rem' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Frontend URL"
                            value={editValues}
                            onChange={(e) => setFrontendurlValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Frontend URL"
                        value={frontendurlValue}
                        onChange={(e) => setFrontendurlValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="API URL"
                            value={editValues}
                            onChange={(e) => setApiurlValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Api URL"
                        value={apiurlValue}
                        onChange={(e) => setApiurlValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Module"
                            value={editValues}
                            onChange={(e) => setModuleValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
 
                    {/* <TextField
                        id="outlined-basic"
                        label="Module"
                        value={moduleValue}
                        onChange={(e) => setModuleValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <div className="mt-1 py-1">
                        <InputBoxMui
                            fullWidth
                            type="text"
                            label="Submodule"
                            value={editValues.submodule}
                            onChange={(e) => setSubmoduleValue(e.target.value)}
                            autoComplete="off"
                        // placeholder="Username or Email"
                        />
                        <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
 
                        </p>
                    </div>
                    {/* <TextField
                        id="outlined-basic"
                        label="Submodule"
                        value={submoduleValue}
                        onChange={(e) => setSubmoduleValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: '0.2rem', backgroundColor: '#fff' }}
                    /> */}
 
                    <Button variant="contained" onClick={handleSave} color="primary">Save</Button>
                </div>
            </Modal>
        </div>
    );
};
 
export default ModalUpdateMUI;
 
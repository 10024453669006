import React, { useEffect, useState, useRef } from 'react';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import UploadIcon from '@mui/icons-material/Upload';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MicIcon from '@mui/icons-material/Mic';
import RemoveIcon from '@mui/icons-material/Remove';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import TitleIcon from '@mui/icons-material/Title';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import ImageIcon from '@mui/icons-material/Image';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SegmentIcon from '@mui/icons-material/Segment';
import LanguageIcon from '@mui/icons-material/Language';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Slider from '@mui/material/Slider';
import Dropdown from 'react-bootstrap/Dropdown';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { checkPhrasing, checkSummarizer } from '../../services/englishCheckers';
import { getCheckSummariser } from '../../services/summariserService';
import { Loader } from "../../utils/loader";
import EditIcon from '@mui/icons-material/Edit';
import { toast, ToastContainer } from "react-toastify";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import styled from "styled-components";
import CoAuthorToolbar from '../pages/CoAuthorToolbar';
import CoAutherTopMenubar from '../pages/CoAutherTopMenubar'
import CoauthorModal from './FormComponents/CoAuthor_Model';
import { styleMap } from '../Editor/utilize';
import createUndoPlugin from 'draft-js-undo-plugin';
import SettingsIcon from '@material-ui/icons/Settings';
import { Popover, List, ListItem, ListItemText, Switch, Box } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';
import { checkTranslator } from '../../services/translatorServices';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputBoxMui from "../ReusableComponents/InputBoxMui";
import FeatureButton from "../ReusableComponents/Button";
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CoAuthorNewProject = () => {
  const [isToolbarVisible, setisToolbarVisible] = useState(true)
  const [loading, setLoading] = useState(false);
  const [showRightSide, setShowRightSide] = useState(false);
  const [showSummarizer, setShowSummarizer] = useState(true);
  const [showParaphrase, setShowParaphrase] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const maxWords = 1000;
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [secondaryeditorStateSummarizer, setSecondaryEditorStateSummarizer] = useState(EditorState.createEmpty());
  const [secondaryeditorStateTranslator, setSecondaryEditorStateTranslator] = useState(EditorState.createEmpty());
  const [showTranslationEditor, setShowTranslationEditor] = useState(false);
  const [leftEditorText, setLeftEditorText] = useState('');
  const [rightEditorText, setRightEditorText] = useState({ text: '', status: '', isClear: false });

  const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());

  const [totalWords, setTotalWords] = useState(0);
  const [translatedText, setTranslatedText] = useState('');
  //  -------
  const [inputKey, setInputKey] = useState()
  const [sliderValue, setSliderValue] = useState(0);
  const [sliderText, setSliderText] = useState("Shorten")
  const [maxCountDisplayed, setMaxCountDisplayed] = useState(false)
  const [customPrompt, setCustomPrompt] = useState("")
  const [paragraphState, setParagraphState] = useState()
  const [editorStatePara, setEditorStatePara] = useState("");
  const [isKeyword, setIsKeyword] = useState(false)
  const [APIData, setAPIData] = useState(false);
  const [keywords, setKeywords] = useState([])
  const [isBulletPoints, setIsBulletPoints] = useState(false)
  const [isParagraph, setIsParagraph] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  // const {transcript, resetTranscript } = useSpeechRecognition();
  const [isRecording, setIsRecording] = useState(false);
  const [editDropdown, setEditDropdown] = React.useState(null);
  const [selectedEditOption, setSelectedEditOption] = useState(true);
  const [selectedViewoption, setSelectedViewOption] = useState(false)
  const isSuggestingDisabled = true;
  const editorRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [shortcutPopoverAnchor, setShortcutPopoverAnchor] = useState(null);
  const [grammarPopoverAnchor, setGrammarPopoverAnchor] = useState(null);
  const [isArrowClickedGrammar, setIsArrowClickedGrammar] = useState(false);
  const [isArrowClickedShort, setIsArrowClickedShort] = useState(false);
  const [switchStates, setSwitchStates] = useState({
    autoCorrect: false,
    inlineSuggestions: false,
    doubleClickThesaurus: false,
    suggestText: false,
    flares: false,
    grammaticalErrors: false,
    sentenceRewrites: false,
  });
  const [activeEditor, setActiveEditor] = useState(null);
  const handleTranslateClick = () => {
    setShowRightSide(true);
    setActiveEditor('translator');
  };
  const handleParaphraseClick = () => {
    setShowRightSide(true);
    setActiveEditor('paraphrase');
    setSelectedTone("")
    grammarCheckForParaphrase()
  };
  const handleSummarizerClick = () => {
    setShowRightSide(true);
    setActiveEditor('summarize')
    grammarCheckSummarizer()
  };

  const [dropdownValue, setDropdownValue] = useState('');


  const [languageList, setLanguageList] = useState([
    {
      value: '',
      name: 'Select'
    },
    {
      "value": "English",
      "name": "English"
    },
    {
      "value": "Spanish",
      "name": "Spanish"
    },
    {
      "value": "German",
      "name": "German"
    },
    {
      "value": "Chinese",
      "name": "Chinese"
    },
    {
      "value": "Japanese",
      "name": "Japanese"
    },
    {
      "value": "Russian",
      "name": "Russian"
    },
    {
      "value": "Arabic",
      "name": "Arabic"
    },
    {
      "value": "French",
      "name": "French"
    },
    {
      "value": "Portuguese",
      "name": "Portuguese"
    }

  ]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [toneList, setToneList] = useState([
    {
      "value": "standard",
      "name": "Standard"
    },
    {
      "value": "formal",
      "name": "Formal"
    },
    {
      "value": "casual",
      "name": "Casual"
    },
    {
      "value": "positive",
      "name": "Positive"
    },
    {
      "value": "confident",
      "name": "Confident"
    },
    {
      "value": "friendly",
      "name": "Friendly"
    },
    {
      "value": "simple",
      "name": "Simple"
    },
    {
      "value": "custom",
      "name": "Custom"
    },
  ])
  const [selectedTone, setSelectedTone] = useState("")
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const [customText, setCustomText] = useState("Custom")
  const [customTextValue, setCustomTextValue] = useState("")
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [suggestionText, setSuggestionText] = useState("Custom")
  const handleTone = (e) => {
    console.log("selectedTone",selectedTone)
    let value = e.target.value
    setSelectedTone(value)
    console.log("value", value, e)
  }
  const handleToneSelection = (type) => {
    setActiveEditor('paraphrase');
    grammarCheckForParaphrase(type)
  }
  useEffect(() => {
    if (selectedTone !== "" && selectedTone !== "custom") {
      handleToneSelection(selectedTone)
    }
    else if(selectedTone == "custom"){
      setIsCustomOpen(true)
    }
  }, [selectedTone])
  useEffect(()=>{
console.log("selectedTone",selectedTone)
  },[handleTone])
  const handleCustomModalClose = () => {
    setIsCustomOpen(false)
    setCustomText(customText)
  }
  const handleCustomClick = () => {
    console.log("customText_", customTextValue)
    setIsCustomSuggestion(false)
    setSuggestionText(customTextValue)
  }
  const handleSuggestionChange = (e) => {
    console.log("Suggestion Text", e.target.value)
    let value = e.target.value
    //  value = value.replace(/[0-9]' `@#$%^&*()_+!:"}{|<>,.}]/g,'')
    console.log("value_", value)
    setCustomTextValue(value)
  }
  const handleCustomSelection = (type) => {
    // setCustomTextValue(`Like a${type}`)
    let splitCustomText
    let suggestionType = type
    splitCustomText = suggestionType.split(' ')
    suggestionType = splitCustomText[2]
    console.log("splitCustomText", suggestionType)
    grammarCheckForParaphrase(suggestionType)
    setCustomText(type)
    setIsCustomOpen(false)
    setIsCustomSuggestion(true)
    setSuggestionText("Custom")
    setCustomTextValue("")
  }
  const handleLanguage = (e) => {
    console.log("eData_", e)
    let value = e?.target?.value
    console.log("eData_", e, value)

    setSelectedLanguage(value !== undefined ? value : e)
    setSecondaryEditorStateTranslator(EditorState.createEmpty())

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShortcutClick = (event) => {
    setShortcutPopoverAnchor(event.currentTarget);
    setIsArrowClickedShort(!isArrowClickedShort);
  };

  const handleGrammarClick = (event) => {
    setGrammarPopoverAnchor(event.currentTarget);
    setIsArrowClickedGrammar(!isArrowClickedGrammar);
  };

  const handleClose1 = () => {
    setAnchorEl(null);


  };
  const handleClose = () => {
    setShortcutPopoverAnchor(null);
    setIsArrowClickedShort(false);
    setGrammarPopoverAnchor(null);
    setIsArrowClickedGrammar(false);

  };

  const open = Boolean(anchorEl);
  const shortcutPopoverOpen = Boolean(shortcutPopoverAnchor);
  const grammarPopoverOpen = Boolean(grammarPopoverAnchor);
  const id = open ? 'settings-popover' : undefined;
  const popoverWidth = 250;
  const shortcutPopoverId = shortcutPopoverOpen ? 'shortcut-popover' : undefined;
  const grammarPopoverId = grammarPopoverOpen ? 'grammar-popover' : undefined;


  // ------settingEnd------//

  // Undo redo Functionality
  const undoRedoPlugin = createUndoPlugin();
  const { UndoButton, RedoButton } = undoRedoPlugin;
  const undoredoPlugin = [undoRedoPlugin];


  const handleEditDropdownOpen = (event) => {
    setEditDropdown(event.currentTarget);
  };
  const handleEditDropdownClose = () => {
    setEditDropdown(null);
  };
  const handleMenuItemClick = (option) => {
    handleEditDropdownClose();
    if (option === 'Viewing') {
      setSelectedEditOption(false);
      setSelectedViewOption(true);
    }
    else {
      setSelectedEditOption(true);
      setSelectedViewOption(false);
    }
  };
  // const handleEditItemClick = () => {
  //   setSelectedEditOption(true);
  //   setSelectedViewOption(false);
  // };

  // const handleViewItemClick = ()=>{
  //   setSelectedEditOption(false);
  //   setSelectedViewOption(true);
  // }

  const handleCustomPrompt = (e) => {
    console.log("handleCustomPrompt", e.target.value)
    let value = e.target.value
    setCustomPrompt(value)
  }
  // -----

  const handleOutputChange = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorState(newEditorState)
  }
  // const handleTranslateIconClick = () => {
  //   // Set the state to show only the translation editor
  //   setShowSummarizer(false);
  //   setShowTranslationEditor(true);
  // };

  const handleTranslate = async () => {

    setLoading(true)
    try {
      let input = editorState.getCurrentContent().getPlainText()
      if (input === '') {
        toast.warn('Please enter text to translate');
        setLoading(false)
        return;
      }
      if (selectedLanguage == '') {
        toast.warn('Please Select the Language to translate');
        setLoading(false)
        return;
      }

      // console.log("SelectedLang_", selectedLanguage, selectedIndustry, selectedProfession)
      let data

      data = {
        text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20-${input}`
      }

      const response = await checkTranslator(data)
      const translatedText = response?.data?.output;


      if (response.status == 400) {
        toast.warn(response.message)
        return
      }
      let textWithoutLeadingNewlines = translatedText.replace(/^\n+/, '');
      let wordLength = translatedText.split(/[ -]+/)
      // console.log("paraphraseResponse_", response, wordLength.length);

      // setOpWordsCount(wordLength.length)
      const contentState = ContentState.createFromText(textWithoutLeadingNewlines);
      const newEditorState = EditorState.createWithContent(contentState);
      setTranslatedText(translatedText);
      setSecondaryEditorStateTranslator(newEditorState)
      setLoading(false)
    } catch (error) {
      console.error('Error calling backend API:', error.message);
      toast.warn(error.message)
      setLoading(false)
    }
  };

  const handleOutputChangeSummarizer = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorStateSummarizer(newEditorState)
  }
  const handleOutputChangeTranslator = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorStateTranslator(newEditorState)
  }

  const handleEditorChange2 = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount <= maxWords) {
      setTotalWords(wordCount);
      setEditorState2(newEditorState);
    }
  };

  const handleBeforeInput = (chars, editorState) => {
    console.log(editorState, "handelbeforInput function data")
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount >= maxWords) {
      return 'handled';
    }
    return 'not-handled';
  };


  const grammarCheckForParaphrase = async (type) => {

    console.log("editorState_", editorState, editorState.getCurrentContent().getPlainText());
    let data = {
      text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`
    }
    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        let wordLength = responseOutput.split(" ")
        console.log("paraphraseResponse_", responseOutput, wordLength.length);
        const contentState = ContentState.createFromText(responseOutput);
        const newEditorState = EditorState.createWithContent(contentState);
        setSecondaryEditorState(newEditorState);
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }

  const grammarCheckSummarizer = async (type) => {
    console.log("editorState_", editorState, editorState.getCurrentContent().getPlainText());
    let content = editorState.getCurrentContent();
    let plainText = content.getPlainText();
    let data = {
      // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
      text: plainText,
      content: "",
      prompts: "one",
    }
    setLoading(true)
    try {
      let summarizerResponse = await getCheckSummariser(data)
      console.log("summarizerResponse", summarizerResponse)
      if (summarizerResponse.data !== "") {
        let responseOutput = summarizerResponse?.data?.output
        let wordLength = responseOutput.split(" ")
        console.log("summarizerResponse_", responseOutput, wordLength.length);
        const contentState = ContentState.createFromText(responseOutput);
        const newEditorState = EditorState.createWithContent(contentState);
        setSecondaryEditorStateSummarizer(newEditorState);
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }

  // ------------------------------------------
  // summarizer
  const handleSummeriseClick = async (type) => {
    console.log(editorState, "edit stateee");
    if (editorState.getCurrentContent().getPlainText().split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded")
      return;
    }
    setLoading(true);
    try {
      if (editorState.getCurrentContent().getPlainText().split(/\s+/).length - 1 == 0) {
        toast.warn("Please enter text")
        return;
      } else {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        console.log(plainText, "plaintext");
        const data = {
          // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
          // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
          text: plainText,
          prompts: "one",
          content: ""
        };

        //   if (customPrompt !== "") {
        //     data = {
        //         text: customPrompt,
        //         content: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
        //         prompts: "six"
        //     }
        // }


        const summarizerResponse = await getCheckSummariser(data);
        console.log("summarizerResponse", summarizerResponse);
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output
          let wordLength = responseOutput.split(" ")
          console.log("summarizerResponse_", responseOutput, wordLength.length);
          const contentState = ContentState.createFromText(responseOutput);
          const newEditorState = EditorState.createWithContent(contentState);
          setSecondaryEditorStateSummarizer(newEditorState);
          setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
          console.log(newEditorState, "new editor state");

          // Please generate the keywords based on the provided content
          let sendData2 = {
            // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
            text: plainText,
            prompts: "two",
            content: ""
          }

          let generateKeywords = await getCheckSummariser(sendData2)
          console.log("generateKeywords", generateKeywords)
          if (generateKeywords.data !== "") {
            const text = generateKeywords.data.output;

            // Check if there is content before attempting to add bullets
            if (text.trim().length > 0) {
              // Extract sentences after dots
              const lines = text.split('\n');
              const filteredLines = lines.filter(line => !line.match(/^\s*\n\d+\.\s*/));

              const extractedSentences = filteredLines
                .map(line => line.split(/\.\s*/))
                .flat()
                .filter(Boolean);

              const cleanedArray = extractedSentences.filter((item, index) => index % 2 !== 0);

              const finalArray = cleanedArray.map(obj => ({ name: obj, value: obj }));

              setKeywords(finalArray);
              setIsVisible(!isCustom);
              setIsKeyword(true);
            } else {
              console.error('No content to add bullets');
            }
          }
        }
      }
    } catch (error) {
      console.error('handleSummarizeClick', error);
    }
    setLoading(false);

  };

  // shorten/long
  const summarizeByPercentage = async (value) => {

    //summarize by percentage
    // four, five
    let promptValue = "four"
    let newValue
    if (value == 0) {
      // newValue = 0
      // promptValue = "four"
      handleSummeriseClick()
      return
    }
    else if (value < 60) {
      newValue = 100 - value
      promptValue = "four"
    }
    else if (value >= 60) {
      newValue = value
      promptValue = "five"
    }

    console.log("newValue", newValue)
    let sendData = {
      text: newValue.toString() + "%",
      content: editorState.getCurrentContent().getPlainText(),
      prompts: promptValue,
    }
    console.log("sendData", sendData)
    setLoading(true)
    try {
      let response = await getCheckSummariser(sendData)
      console.log("summarizeByPercentage", response)
      let responseOutput = response?.data?.output
      const contentState = ContentState.createFromText(responseOutput);
      const newEditorState = EditorState.createWithContent(contentState);
      setSecondaryEditorStateSummarizer(newEditorState);
      setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
      console.log(newEditorState, "new editor state");
    }
    catch (e) {
      console.log("summarizeByPercentageerror", e)
    }
    setLoading(false)
  }

  const handleSliderChange = (event, newValue) => {
    // Calculate the percentage
    const percentage = ((newValue - 0) / (100 - 0)) * 100;
    // Update the state
    setSliderValue(newValue);
    setSliderText(newValue >= 60 ? "Expand" : "Shorten")
    summarizeByPercentage(newValue)
    // Use the percentage as needed
    console.log('Percentage:', percentage);
  };


  // ----- delete ------
  const handleDeleteClick = () => {
    console.log("inside delete");
    setEditorState(EditorState.createEmpty());
    setEditorState2(EditorState.createEmpty());
    setSecondaryEditorState(EditorState.createEmpty());
    setSecondaryEditorStateSummarizer(EditorState.createEmpty());
    setTotalWords(0);
    setSliderValue(0);
    setCustomPrompt("");
    setEditorStatePara("");
    // resetTranscript();
  };

  // ---- upload file ----

  const handleWordCountExceed = () => {
    if (!maxCountDisplayed) {
      toast.warn("Word count exceeded than 1500");
      setMaxCountDisplayed(true);
    }
  }

  const handleFileChange = (e) => {
    console.log("inside upload");
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const text = content.toString();
        const newEditorState = EditorState.createWithContent(ContentState.createFromText(text));
        let currentText = newEditorState.getCurrentContent().getPlainText();

        const wordCount = currentText.trim().split(/\s+/).length;

        console.log("currentText", currentText, wordCount);

        if (wordCount > maxWords) {
          handleWordCountExceed();
        }

        // Always continue with the upload process, regardless of the word count
        setTotalWords(wordCount);
        setInputKey((prevKey) => prevKey + 1);
        setEditorState(newEditorState);
        setMaxCountDisplayed(false);
      };

      reader.readAsText(file);
    }
  };

  // ---- download ----
  const handleDownload = () => {
    console.log("inside download");
    console.log(editorState2, "edit state");
    console.log(editorState2.getCurrentContent(), "get current content");
    console.log(secondaryeditorStateSummarizer.getCurrentContent().getPlainText(), "plain text");

    if (!secondaryeditorStateSummarizer.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([secondaryeditorStateSummarizer.getCurrentContent().getPlainText()], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  //  ----- copy to clipboard ---

  const handleCopyEditor2 = () => {
    let sentence = secondaryeditorStateSummarizer.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  const handleCopyEditorTranslator = () => {
    let sentence = secondaryeditorStateTranslator.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  // -----  bullet ----

  const handleParagraph = () => {
    const contentState = ContentState.createFromText(editorStatePara);
    console.log("Paragraph text:", contentState.getPlainText());
    // Update the state or perform any other necessary actions with the content
    setSecondaryEditorStateSummarizer(EditorState.createWithContent(contentState));
  }; // working



  const handleLinkClick = (linkType) => {
    console.log("Inside handlelink", linkType);
    setActiveLink(linkType);
    if (linkType === "bulletPoints") {
      console.log("bullet selected");
      setAPIData(true)
      setIsBulletPoints(true)
      setIsParagraph(false)
    }
    else if (linkType === "paragraph") {
      setAPIData(false)
      setIsBulletPoints(false)
      setIsParagraph(true)
      setIsCustom(false)
      setIsKeyword(true)
      handleParagraph()
    }
    else if (linkType == "custom") {
      setIsBulletPoints(false)
      setIsParagraph(false)
      setIsCustom(true)
      setIsKeyword(false)
    }
  };

  const handleApplyBullets = () => {
    // setAPIData(true)
    // setAPIData(false)
    let hasText = editorState.getCurrentContent().hasText();
    if (!hasText) {
      toast.info("No content to add bullets")
      return
    }
    setIsBulletPoints(true)
    setIsParagraph(false)
    setIsCustom(false)
    setIsKeyword(true)

    // let text = editorState2.getCurrentContent().getPlainText();
    // let sentences = text.split('. ');
    // let newText = sentences.join('.\n');
    // let newContentState = ContentState.createFromText(newText);
    // let newEditorState = EditorState.createWithContent(newContentState);
    // setEditorState2(newEditorState)

    // Get the current text from the editor
    let text = editorState.getCurrentContent().getPlainText();

    // Remove all empty lines
    let lines = text.split('\n').filter(line => line.trim() !== '');
    let textWithoutEmptyLines = lines.join('\n');

    // Split the text at every full stop
    let sentences = textWithoutEmptyLines.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.replace(/\n/g, ''));

    // Add a newline after every sentence and join them back together
    let formattedText = sentences.join('.\n');

    // Create a new content state with the formatted text
    let newContent = ContentState.createFromText(formattedText);

    // Set the new content state in the editor
    // let newEditorState = EditorState.push(editorState2, newContent);
    let newEditorState = EditorState.createWithContent(newContent);
    let newEditorStateExt = newEditorState.getCurrentContent()
    console.log("newEditorStateExt", newEditorStateExt.getPlainText())
    let firstBlock = newEditorStateExt.getFirstBlock()
    let lastBlock = newEditorStateExt.getLastBlock()
    let firstBlockKey = firstBlock.getKey();
    let lastBlockKey = lastBlock.getKey();
    let firstBlockLength = firstBlock.getLength();
    let lastBlockLength = lastBlock.getLength();
    let selection = newEditorState.getSelection().merge({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength,
    })
    let selectedState = EditorState.forceSelection(newEditorState, selection)
    setParagraphState(selectedState)

    let selectionState = selectedState.getSelection();
    let contentState = selectedState.getCurrentContent();
    let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
    // Check if the selection is not collapsed and spans the entire block
    // if (!selectionState.isCollapsed() && selectionState.hasFocus &&
    //     selectionState.getStartOffset() === 0 && selectionState.getEndOffset() === currentBlock.getLength()) {

    // Toggle the block type to unordered-list-item
    let newEditorState2 = RichUtils.toggleBlockType(selectedState, 'unordered-list-item');
    setSecondaryEditorStateSummarizer(newEditorState2);
    // }

  }

  ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////

  const handleMicClick = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      if (isRecording) {
        SpeechRecognition.stopListening();
        // handleSpeechInput(transcript); // Use the transcript when recording stops
      } else {
        SpeechRecognition.startListening({ continuous: true });
      }
      setIsRecording(!isRecording);
    } else {
      toast.error("Speech recognition is not supported by your browser.");
    }
  };

  const handleTranscriptChange = () => {
    // This function will be called whenever the transcript changes
    // if (transcript) {
    //   handleSpeechInput(transcript);
    // }
  };

  const handleSpeechInput = (input) => {
    // Handle the speech input, for example, update the editor state
    const newContentState = ContentState.createFromText(input);
    const newEditorState = EditorState.createWithContent(newContentState);
    setEditorState(newEditorState);
  };

  // useEffect(() => {
  //   handleTranscriptChange();
  // }, [transcript]);

  ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////


  // ----------------------------------

  const showRightSideOnce = () => {
    setShowRightSide(true);
    setShowSummarizer(true)

    grammarCheckForParaphrase()
  };
  const showRightTranslator = () => {
    setShowRightSide(true);
    setShowTranslationEditor(true)
    handleTranslate()
  };

  const showRightSummarizer = () => {
    setShowRightSide(true);
    setShowSummarizer(false)

    handleSummeriseClick()
    handleLinkClick()
  }

  const toggleSummarizer = () => {
    setShowSummarizer(!showSummarizer);
  };


  const handleLefEditorChange = (event) => {

    if (!selectedViewoption) {
      let previous_Content = editorState.getCurrentContent().getPlainText();
      let current_Content = event.getCurrentContent().getPlainText();
      const wordCount = current_Content.trim().split(/\s+/).length;
      if (previous_Content !== current_Content) {
        // setIsParaphrase(false);
        setRightEditorText({ ...rightEditorText, isClear: true });
        // leftSuggestion([]);
        // setCurrentNavId(0);
      }

      if (wordCount <= maxWords) {
        if (previous_Content === "" || current_Content === "" || previous_Content === " " || current_Content === " ") {
          setTotalWords(0)
        } else {
          setTotalWords(wordCount);
        }

        setEditorState(event);
      }
    }
    // console.log(current_Content,"handleLefEditorChange function data")
  }

  const iconButtonStyle = {
    width: '36px',
    height: '36px',
    borderRadius: '0'
  };
  const iconButtonBorderStyle = {
    width: '36px',
    height: '36px',
    borderRadius: '0',
    borderLeft: '2px solid #ccc'
  };
  const disabledStyles = {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  };
  const handleReplace = () => {
    // Update the editorState with the translated text
    const newContentState = ContentState.createFromText(translatedText);
    const newEditorState = EditorState.createWithContent(newContentState);

    // Update the main editor state
    setEditorState(newEditorState);
  };



  return (
    <>

      {/* Coauthor calling */}
      {selectedEditOption &&

        <CoauthorModal state={editorState} setState={setEditorState} count={totalWords} />
      }
      {/* /////////////////////// */}


      <Loader loading={loading} />
      {selectedEditOption &&
      <Link to="/coauthor">
        
        <Button variant="contained" color="primary" startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
          Back
        </Button>
      </Link>}
      {selectedEditOption &&
        <CoAutherTopMenubar editorState={editorState} onChange={handleLefEditorChange} />
      }
      {/* Rich Text Editor start */}
      <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "2%", alignItems: "center" }}>
       
       {selectedEditOption &&
       <CoAuthorToolbar editorState={editorState} onChange={handleLefEditorChange} undo={<UndoButton/>} redo={<RedoButton/>}   />
       }
 
{/* {(externalProps) => (
                <div>
             <DividerButton {...externalProps} />            
              </div>          
               )          
               }         */}
 
{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div style={{ marginTop: "34px", width: "97px",height:"43px" }}>
        <Button
        variant='outlined'
        color='primary'
        style={{ fontSize: '10px', marginRight: '5px', backgroundColor: "white", padding: "8px" }}
        onClick={handleEditDropdownOpen}
      >
        {selectedEditOption ? (
          <>
            <EditIcon style={{ fontSize: "15px" }} /> Edit
          </>
        ) : (
          <>
            <VisibilityIcon style={{ fontSize: "15px" }} /> View
          </>
        )}
        <KeyboardArrowDownIcon style={{ fontSize: "15px", marginLeft: "4px" }} />
      </Button>
          {/* Dropdown Menu */}
          <Menu
            anchorEl={editDropdown}
            open={Boolean(editDropdown)}
            onClose={handleEditDropdownClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{ padding: "0px" }}
          >
            {/* Editor View Option */}
            <MenuItem onClick={() => handleMenuItemClick("Editor View")}
              style={{ backgroundColor: selectedEditOption === "Editor View" ? "#F3F4F9" : "transparent" }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <EditIcon />
                </ListItemIcon>
                <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                  <ListItemText primary="Editor View" style={{ fontWeight: "bolder", color: selectedEditOption === "Editor View" ? "#3f51b5" : "black" }} />
                  <ListItemText primary="Edit the project directly" style={{ color: "gray", fontSize: "14px" }} />
                </div>
                {selectedEditOption === "Editor View" && (
                  <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </div>
            </MenuItem>

            {/* Suggesting Option */}
            <MenuItem onClick={isSuggestingDisabled ? null : () => handleMenuItemClick("Suggesting")}
              style={{ backgroundColor: selectedEditOption === "Suggesting" ? "#F3F4F9" : "transparent", disabledStyles }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", color: isSuggestingDisabled === true ? "#dcdcdc" : "blck" }}>
                  <PostAddIcon />
                </ListItemIcon>
                <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                  <ListItemText primary="Suggesting" style={{ fontWeight: "600", color: isSuggestingDisabled === true ? "#dcdcdc" : "blck" }} />
                  <ListItemText primary="Suggest edits to the project" style={{ color: isSuggestingDisabled === true ? "#dcdcdc" : "blck" }} />
                </div>
                {selectedEditOption === "Suggesting" && (
                  <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", color: isSuggestingDisabled === true ? "#dcdcdc" : "blck" }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </div>
            </MenuItem>

            {/* Viewing Option */}
            <MenuItem onClick={() => handleMenuItemClick("Viewing")}
              style={{ backgroundColor: selectedEditOption === "Viewing" ? "#F3F4F9" : "transparent" }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <RemoveRedEyeIcon />
                </ListItemIcon>
                <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                  <ListItemText primary="Viewing" style={{ fontWeight: "bold", color: selectedEditOption === "Viewing" ? "#3f51b5" : "black" }} />
                  <ListItemText primary="Read or print the project" />
                </div>
                {selectedEditOption === "Viewing" && (
                  <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <>

        <div style={{ display: 'flex' }}>

          <div className='Left-side-coauthor mt-2' style={{ flex: '70%' }}>


            <div style={{ minWidth: '80%', maxWidth: '80%', boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', padding: '20px 5px', borderRadius: '8px 8px 0px 0px', background: 'white', minHeight: '320px', maxHeight: '320px', overflowY: 'auto' }}>
              <Editor
                editorState={editorState}
                customStyleMap={styleMap()}
                onChange={handleLefEditorChange}
                handleBeforeInput={handleBeforeInput}
                placeholder="Please enter your text."
                readOnly={selectedViewoption}
                plugins={undoredoPlugin}
              />
            </div >
            {selectedEditOption &&
              <>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', gap: '5px', maxWidth: '80%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '70px', maxHeight: '70px' }}>
                  <div >
                    <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', borderColor: 'black', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }}><UploadIcon /> Upload
                      <VisuallyHiddenInput
                        id="summeriserFileInput"
                        type="file"
                        key={inputKey}
                        onChange={handleFileChange}
                      />
                    </Button>
                    <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleParaphraseClick}><MenuBookIcon style={{ marginRight: '3px' }} /> Paraphrase</Button>
                    <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleSummarizerClick}><ArticleIcon /> Summarize</Button>
                  </div>
                  <div>
                    <IconButton style={{ fontSize: '10px', marginLeft: '2px', color: 'black' }} onClick={handleClick}><SettingsIcon /></IconButton>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose1}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}

                      style={{ width: popoverWidth }}

                    >

                      <Box style={{ width: popoverWidth, paddingRight: '20px' }}>

                        <List>
                          <ListItem>
                            <ListItemText
                              primary="Auto Correct"
                              style={{ color: switchStates.autoCorrect ? '#1976D2' : 'inherit' }}
                            />
                            <Switch
                              color="primary"
                              checked={switchStates.autoCorrect}
                              onChange={() => setSwitchStates({ ...switchStates, autoCorrect: !switchStates.autoCorrect })}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Inline Suggestions"
                              style={{ color: switchStates.inlineSuggestions ? '#1976D2' : 'inherit' }}
                            />
                            <Switch
                              color="primary"
                              checked={switchStates.inlineSuggestions}
                              onChange={() => setSwitchStates({ ...switchStates, inlineSuggestions: !switchStates.inlineSuggestions })}
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText primary="Shortcuts" style={{ color: isArrowClickedShort ? '#1976D2' : 'inherit', '& span': { color: 'inherit' } }} />
                            <IconButton
                              style={{ fontSize: '10px', marginRight: '5px', color: isArrowClickedShort ? '#1976D2' : 'grey' }}
                              onClick={handleShortcutClick}
                              aria-controls={shortcutPopoverId}
                              aria-haspopup="true"
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </ListItem>
                          <ListItem >
                            <ListItemText style={{ color: isArrowClickedGrammar ? '#1976D2' : 'inherit', '& span': { color: 'inherit' } }} primary="Grammar" />
                            <IconButton
                              style={{ fontSize: '10px', marginRight: '5px', color: isArrowClickedGrammar ? '#1976D2' : 'grey' }}
                              onClick={handleGrammarClick}
                              aria-controls={shortcutPopoverId}
                              aria-haspopup="true"
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </ListItem>
                          <ListItem>
                            <Link to="/grammarcheck" style={{ color: '#1976D2' }}>
                              <ListItemText primary="Advanced Grammar Settings" />
                            </Link>
                          </ListItem>

                        </List>
                      </Box>
                    </Popover>

                    <Popover
                      id={shortcutPopoverId}
                      open={shortcutPopoverOpen}
                      anchorEl={shortcutPopoverAnchor}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      style={{ marginLeft: '55px' }}
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Double click to open thesaurus"
                            style={{ color: switchStates.doubleClickThesaurus ? '#1976D2' : 'inherit' }}
                          />
                          <Switch
                            color="primary"
                            checked={switchStates.doubleClickThesaurus}
                            onChange={() => setSwitchStates({ ...switchStates, doubleClickThesaurus: !switchStates.doubleClickThesaurus })}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="'++' for Suggest Text"
                            style={{ color: switchStates.suggestText ? '#1976D2' : 'inherit' }}
                          />
                          <Switch
                            color="primary"
                            checked={switchStates.suggestText}
                            onChange={() => setSwitchStates({ ...switchStates, suggestText: !switchStates.suggestText })}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="'/' for Flares"
                            style={{ color: switchStates.flares ? '#1976D2' : 'inherit' }}
                          />
                          <Switch
                            color="primary"
                            checked={switchStates.flares}
                            onChange={() => setSwitchStates({ ...switchStates, flares: !switchStates.flares })}
                          />
                        </ListItem>
                      </List>
                    </Popover>

                    <Popover
                      id={grammarPopoverId}
                      open={grammarPopoverOpen}
                      anchorEl={grammarPopoverAnchor}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      style={{ marginLeft: '55px' }}
                    >
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Grammatical errors"
                            style={{ color: switchStates.grammaticalErrors ? '#1976D2' : 'inherit' }}
                          />
                          <Switch
                            color="primary"
                            checked={switchStates.grammaticalErrors}
                            onChange={() => setSwitchStates({ ...switchStates, grammaticalErrors: !switchStates.grammaticalErrors })}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Sentence rewrites"
                            style={{ color: switchStates.sentenceRewrites ? '#1976D2' : 'inherit' }}
                          />
                          <Switch
                            color="primary"
                            checked={switchStates.sentenceRewrites}
                            onChange={() => setSwitchStates({ ...switchStates, sentenceRewrites: !switchStates.sentenceRewrites })}
                          />
                        </ListItem>

                      </List>

                    </Popover>
                    <IconButton style={{ fontSize: '8px', marginLeft: '2px', color: 'black', borderColor: 'white' }} onClick={handleTranslateClick}><TranslateIcon /></IconButton>
                    <IconButton style={{ fontSize: '8px', marginLeft: '2px', color: isRecording ? 'red' : 'black', borderColor: 'white' }} onClick={{ handleMicClick }}><MicIcon /></IconButton>
                    <IconButton style={{ fontSize: '8px', marginLeft: '2px', color: 'black', borderColor: 'white' }}><RemoveIcon /></IconButton>

                  </div>



                </div>

                <div><h6 style={{ marginTop: '15px' }}>
                  <FiberManualRecordIcon fontSize='small' />1000 words limit per search |
                  <b> Total words: {totalWords}</b>
                </h6></div>
              </>
            }
          </div>

          {showRightSide && (
            <div className='Right-side-coauthor' style={{ flex: '35%' }}>
              <div style={{ display: 'flex', alignItems: "center" }}>
                <div style={{ maxWidth: '45%', marginBottom: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '5px', borderRadius: '8px', background: 'white' }}>

                  <IconButton style={iconButtonStyle} onClick={handleParaphraseClick}><MenuBookIcon /></IconButton>
                  <IconButton style={iconButtonBorderStyle} onClick={handleSummarizerClick}><ArticleIcon /></IconButton>
                  <IconButton style={iconButtonBorderStyle} onClick={handleTranslateClick}><TranslateIcon /></IconButton>

                </div>
                {activeEditor == 'paraphrase' &&
                  <div style={{ marginLeft: '10px', width: "38%" }}>
                    <SelectDropdownMui
                      optionsList={toneList}
                      selectLabel={"Tone"}
                      onChange={handleTone}
                      value={selectedTone}
                    />
                </div>}
              </div>

              {activeEditor === 'paraphrase' &&


                (<>
                  {/* Right Paraphrase start */}
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'space-between' }}>
                      <div style={{ minWidth: '75%', maxWidth: '75%', boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', padding: '20px 5px', borderRadius: '8px 8px 0px 0px', background: 'white', minHeight: '270px', maxHeight: '270px', overflowY: 'auto' }}>
                        <Editor
                          // editorState={editorState2}
                          editorState={secondaryeditorState}
                          // onChange={handleEditorChange2}
                          onChange={handleOutputChange}
                          // handleBeforeInput={handleBeforeInput}
                          placeholder="Please enter your text."
                          // readOnly
                          readOnly={secondaryeditorState.getCurrentContent().getPlainText() !== "" ? false : true}
                        />
                      </div >
                    </div>

                    <div style={{ display: "flex", justifyContent: 'space-between', gap: '5px', minWidth: '75%', maxWidth: '75%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '70px', maxHeight: '70px' }}>
                      <div >
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid red', color: 'red' }}><DeleteIcon /></IconButton>
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid black' }}><DownloadIcon /></IconButton>
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid black' }}><ContentCopyIcon /></IconButton>
                      </div>
                      <div >
                        <Button variant='contained' color='primary' style={{ fontSize: '13px', marginRight: '5px', textTransform: 'capitalize' }}
                          onClick={grammarCheckForParaphrase}
                        >Rephrase</Button>
                      </div>
                    </div>

                  </div>
                  {/* Right Paraphrase end */}
                </>)}
              {activeEditor === 'summarize' &&

                (<>
                  {/* Right Summarize start */}
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'space-between' }}>
                      <div style={{ minWidth: '75%', maxWidth: '75%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}><b><p style={{ fontSize: '12px' }}>Summary Length</p></b><p style={{ fontSize: '10px' }}>Short</p><Slider defaultValue={0} step={20} marks min={0} max={100} valueLabelDisplay="off" style={{ width: '40%' }} onChange={handleSliderChange} /><p style={{ fontSize: '10px' }}>Long</p></div>
                        <div style={{ display: 'flex' }}><b><Button variant='Text' style={{ fontSize: '10px', marginRight: '15px' }} onClick={handleApplyBullets}>Key Sentences</Button></b> <b><Button variant='text' style={{ fontSize: '10px' }} onClick={() => handleLinkClick("paragraph")}>Paragraph</Button></b></div>
                        <div style={{ boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', padding: '20px 5px', borderRadius: '8px 8px 0px 0px', background: 'white', minHeight: '210px', maxHeight: '210px', overflowY: 'auto' }}>
                          <Editor
                            editorState={secondaryeditorStateSummarizer}
                            onChange={handleOutputChangeSummarizer}
                            handleBeforeInput={handleBeforeInput}
                            placeholder="Please enter your text."
                            readOnly
                          />
                        </div >
                      </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: 'space-between', gap: '5px', minWidth: '75%', maxWidth: '75%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '70px', maxHeight: '70px' }}>
                      <div >
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid red', color: 'red' }} onClick={handleDeleteClick}><DeleteIcon /></IconButton>
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid black' }} onClick={handleDownload}><DownloadIcon /></IconButton>
                        <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid black' }} onClick={handleCopyEditor2}><ContentCopyIcon /></IconButton>
                      </div>
                      <div >
                        <Button variant='contained' color='primary' style={{ fontSize: '13px', marginRight: '5px', textTransform: 'capitalize' }}
                          onClick={handleSummeriseClick}
                        >Summarize</Button>
                      </div>
                    </div>

                  </div>

                  {/* Right Summarize end */}
                </>)

              }
              {activeEditor === 'translator' && (

                <div>
                  <div style={{ width: '150px', marginTop: '5px' }}>
                    <SelectDropdownMui
                      optionsList={languageList}
                      selectLabel={"Select Language"}
                      onChange={(e) => handleLanguage(e)}
                      value={selectedLanguage}
                      id="languageDropdown"
                    />
                  </div>

                  {/* New Editor Code */}
                  <div style={{ display: 'flex', flexDirection: 'space-between' }}>
                    <div style={{ minWidth: '75%', maxWidth: '75%' }}>
                      <div style={{ boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', padding: '20px 5px', borderRadius: '8px 8px 0px 0px', background: 'white', minHeight: '210px', maxHeight: '210px', overflowY: 'auto', marginTop: '15px' }}>
                        <Editor
                          editorState={secondaryeditorStateTranslator}
                          onChange={handleOutputChangeTranslator}
                          handleBeforeInput={handleBeforeInput}
                          placeholder="Please enter your text."
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: 'space-between', gap: '5px', minWidth: '75%', maxWidth: '75%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '70px', maxHeight: '70px' }}>
                    <div >
                      <IconButton style={{ width: '80px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid', fontSize: '13px' }} onClick={handleReplace}><SwapHorizIcon />Replace</IconButton>
                      <IconButton style={{ width: '30px', height: '30px', borderRadius: '5px', marginRight: '5px', border: '1px solid black' }} onClick={handleCopyEditorTranslator}><ContentCopyIcon /></IconButton>
                    </div>
                    <div >
                      <Button variant='contained' color='primary' style={{ fontSize: '13px', marginRight: '5px', textTransform: 'capitalize' }}
                        onClick={handleTranslate}
                      >Translate</Button>
                    </div>
                  </div>
                </div>
              )}

            </div>
          )}
        </div>
      </>
      {/* Rich Text Editor end */}

      <Modal
        isOpen={isCustomOpen}
        // toggle={() => setIsCustomOpen(true)}
        style={{
          // position: "absolute",
          // left: "43%",
          top: "33%",
        }}
      >
        <ModalHeader toggle={handleCustomModalClose}>
          <div className="d-flex px-2 justify-content-between">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Make my text sound...</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
              className="col-md-10"
            >
              <InputBoxMui
                fullWidth
                placeholder={"E.g., “like a News”"}
                value={customTextValue}
                onChange={e => handleSuggestionChange(e)}

              />
            </p>
            <div className="ml-2">
              <button
                className="btn btn-primary"
                onClick={handleCustomClick}
                disabled={customTextValue !== "" ? false : true}
              >
                Done
              </button>
            </div>
          </div>
          <div className="px-2">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#525252",
                fontSize: "14px",
              }}
            >Suggestions:</div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Story"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Story")} />
              <FeatureButton text={"Like a Poem"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Poem")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Ceo"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Ceo")} />
              <FeatureButton text={"Like a Doctor"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Doctor")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a News"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a News")} />
              <FeatureButton text={"Like a Sales Executive"} divClassName={"ml-3"} style={{ width: "160px" }} size={"md"} onClick={() => handleCustomSelection("Like a Sales Executive")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Letter"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Letter")} />
              <FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection(suggestionText)} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CoAuthorNewProject;

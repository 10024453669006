import React, { Component } from "react";
import "./Alerts.css";
import AlertCard from "./Cards/AlertsCard";
import notficationService from "../services/notficationService";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import SearchBox from "./SearchBox";
import NoAlerts from "../static/noAlerts.png";
import Loader from "../utils/loader";

export class Alert extends Component {
  state = {
    isAdmin: false,
    alerts: [],
    filter: [],
    loading: true,
    noAlerts: false,
  };

  filterOnChange = (e) => {
    if (e.target.value === "") {
      this.setState({ filter: this.state.alerts });
    } else {
      let value = e.target.value;
      let alerts = this.state.alerts;
      let result = [];
      result = alerts.filter((alert) => {
        console.log(alert);
        if (alert.title.toLowerCase().search(value.toLowerCase()) !== -1) {
          return alert;
        } else if (
          alert.message.toLowerCase().search(value.toLowerCase()) !== -1
        ) {
          return alert;
        }
      });
      this.setState({ filter: result });
    }
  };

  componentDidMount() {
    this.getAlertsForuser();
  }

  getAlertsForuser = async () => {
    const alerts = await notficationService.getAlerts();
    console.log(alerts);
    if (alerts.status !== 200) {
      toast.error(alerts.message);
    } else {
      if (alerts.alert.length < 1) {
        this.setState({  loading: false,noAlerts: true });
      } else {
        this.setState({
          alerts: alerts.alert,
          filter: alerts.alert,
          loading: false,
          noAlerts:false
        });
      }
    }
  };

  readAlert = async (alertDetails) => {
    console.log(alertDetails);
    await notficationService.readalerts(alertDetails);
    this.getAlertsForuser();
  };

  render() {
    const { filter , noAlerts} = this.state;
    // console.log(alerts);
    return (
      <div>
        <Loader loading={this.state.loading} />

        <div
          className="alertCont"
          style={{
            background: "#f0f0f7",
          }}
        >
          {/* Bread Crumbs */}
          <BreadCrumb l1="Home" l2="Alerts" dashboardlink="/dashboard" />
          <div className="row pl-1">
            <div className="col-md-1">
              <HeadingB className="alertHead" text="Alerts" />
            </div>
            <div className="col-md-5">
              <SearchBox
                placeholder="&#xF002; Search alert"
                // value={this.state.searchQuery}
                onSearch={this.filterOnChange}
              />
            </div>
          </div>
          {noAlerts ? (

            <div className="text-center mt-4">
              <img
                className=" "
                style={{ height: "40%", width: "40%" }}
                src={NoAlerts}
                alt="404"
              ></img>
              <h3 className="alertHead" style={{color: "#6860F3"}}>No Alerts Found...!</h3>
            </div>
          ) : (
            <div className="row pl-2" style={{ marginTop: "2%" }}>
              {filter.map((alert) => (
                <AlertCard data={alert} readAlert={this.readAlert} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Alert;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Farclause.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { getFarParts, getSectionsAndSubpartsByName } from "../services/farCauses";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import { Button, TextField } from "@material-ui/core";
import PublishIcon from "@mui/icons-material/Publish";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Loader from "../utils/loader";
import farClause from "../static/signup_login/farClause.jpg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getUserInfo } from "../services/opportunityService";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { showToast } from "../messages";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Farclause = () => {
  const [masterdata, setMasterdata] = useState([]);
  const [iconState, setIconState] = useState({});
  const [htmlData, setHtmlData] = useState("");
  const [subpartData, setSubpartData] = useState("");
  const [sectionData, setSectionData] = useState("");
  const [parts, setParts] = useState([]);
  const [partContent, setPartContent] = useState(false);
  const [sideDivVisible, setSideDivVisible] = useState(false);
  const [partValue, setPartValue] = useState("");
  const [fullPartValue, setFullPartValue] = useState("");
  const [fullsubPartValue, setFullsubPartValue] = useState("");
  const [fullSectiontValue, setFullSectionValue] = useState("");
  const [subpartValue, setSubpartValue] = useState("");
  const [sectionValue, setSectionValue] = useState("");
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedSubParts, setSelectedSubParts] = useState([]);
  const [selectedFarCauseId, setSelectedFarCauseId] = useState(null);
  const [clauseValue, setClauseValue] = useState("FAR");
  const [EffectiveDate, setEffectiveDate] = useState("");
  const [chapterValue, setChapterValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [farPartsChecked, setFarPartsChecked] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [addEffectiveDate, setAddEffectiveDate] = React.useState("");
  const [uploadType, setUploadType] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentEffectiveDate, setCurrentEffectiveDate] = useState("");
  const [partLocation, setPartLocation] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [userInfo, setUserInfo] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isOpen = useSelector((state) => state.sideNavbar.isOpen.stateValue);
  console.log(isOpen, "redux isOpen")
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const { clauseType, partNumber } = extractPartDetails(hash);
      // alert(`${partNumber},partNumber`)
      const newPartLocation = `https://contractpro360-bucket.s3.us-east-1.amazonaws.com/Regulations/${clauseType}/part_${partNumber}.html`;
      // alert(newPartLocation)
      setClauseValue(clauseType);
      setPartLocation(newPartLocation);
      setPartValue(partNumber);
    } else {
      getParts({ clauseType: 'FAR' });
    }
  }, []);

  useEffect(() => {
    if (clauseValue && partLocation) {
      getParts({ clauseType: clauseValue });
      // alert(`from useEffect ${partLocation}`);
      handleRedirectToPart(partLocation);
      fetchSectionName();
    }
  }, [clauseValue, partLocation]);

  const extractPartDetails = (hash) => {
    // alert(`hash${hash}`)
   
    // const cleanedHash = hash.replace(/_SubPart/i, '');
    const cleanedHash = hash.replace(/_SubPart|_Part/gi, '');
    // alert(`cleanedHash,${cleanedHash}`)
    const parts = cleanedHash.split('_');
    let clauseType = parts[0];
    let partNumber
    if (hash.includes('_PGI')) {
      partNumber = parts.length > 1 ? parts[2].split('.')[0] : '';
    }else{
    partNumber = parts.length > 1 ? parts[1].split('.')[0] : '';
    }
    // Transform 'DFARS' to 'DFAR'
    if (clauseType === 'DFARS') {
      clauseType = 'DFAR';
    }
    if (hash.includes('_PGI')) {
      clauseType = 'DFARSPGI';
  }

    return { clauseType, partNumber };
  };

  const fetchSectionName = async () => {
    try {
      const hash = window.location.hash;
      const link = hash;
      console.log(link, "link");

      const response = await getSectionsAndSubpartsByName(link);
      console.log(response, "response");

      if (response.status === 200) {
        const { sectionName } = response;
        console.log("Section name fetched:", sectionName);
        highlightSection(sectionName);
      } else {
        console.error("Failed to fetch section name. Response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching section name:", error);
    }
  };

  const highlightSection = async (sectionName) => {
    try {
      // alert(sectionName);

      const hash = window.location.hash.substring(1);
      const { clauseType, partNumber } = extractPartDetails(hash);
      const partLocation = `https://contractpro-bucket.s3.ap-southeast-1.amazonaws.com/Regulations/${clauseType}/part_${partNumber}.html`;
      console.log(partLocation, "partLocation");
      // alert(`highlight ${partLocation}`)

      const htmlData = await getFarClauses(partLocation);
      if (!htmlData) {
        console.error("Failed to fetch HTML data.");
        return;
      }
      console.log(htmlData, "htmlData");

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlData, "text/html");

      const links = Array.from(doc.querySelectorAll('a')).filter(link => link.textContent.trim() === sectionName);
      console.log(links, "links");

      if (links.length === 0) {
        console.warn(`No links found for section name: ${sectionName}`);
        return;
      }

      links.forEach((link) => {
        let parentParagraph = link.closest('p');
        if (parentParagraph) {
          parentParagraph.style.backgroundColor = "yellow";
        } else {
          console.warn("Parent paragraph not found for link:", link);
        }
      });

      setHtmlData(doc.documentElement.outerHTML);
    } catch (error) {
      console.error("Error in highlightSection:", error);
    }
  };


  useEffect(() => {
    const Username = sessionStorage.getItem("currentUserEmail")

    getCuurentUser(Username)
  }, [])

  const getCuurentUser = async (Username) => {
    try {
      const response = await getUserInfo(Username);
      setUserInfo(response.data);
      console.log("userinfo123", response);

    } catch (error) {
      console.log(error);
    }
  };










  console.log(file, "selectedFile");
  let screenName = "Clause Generation";

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }
  // console.log(typeof(features), "featuresfeatures");
  // const matchingFeature = features.find(feature => feature.ScreenName === screenName);
  // console.log(matchingFeature, "matchingFeature");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  console.log(searchQuery, "searchQuery")

  const filterContent = (content) => {
    // Implement filtering logic here
    // For simplicity, this example filters only by part name
    return content?.filter(item => item?.part?.partName?.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const highlightSearchQuery1 = (text) => {
    if (!searchQuery) return text;
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    return typeof text === 'string' ? text.replace(regex, '<span class="highlight">$1</span>') : text;
  };
  console.log(clauseValue, "___clauseValue")

  const renderContentWithHighlight = (content) => {
    return content?.map((farCause) => (
      <Accordion key={farCause?.part?._id}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography style={{ lineHeight: "0.3" }}>
            <div
              key={farCause?.part?._id}
              className="farCause"
              style={{ lineHeight: "0.8" }} // Adjust styles here as needed
            >
              {iconState[farCause?.part?._id] ? (
                <RemoveCircleOutlineIcon
                  className="blueIcon"
                  onClick={() =>
                    handlePartToggle(farCause?.part?._id)
                  }
                />
              ) : (
                <>
                  {(clauseValue === "FAR" || clauseValue === "DFAR") && (
                    <AddCircleOutlineIcon
                      className="blueIcon"
                      onClick={() => handlePartToggle(farCause?.part?._id)}
                    />
                  )}
                </>
              )}
              <h3
                className="pointer"
                onClick={() =>
                  handlepartSidebar(
                    farCause?.location,
                    farCause?.part?.partName
                  )
                }
                style={{ marginTop: "-03px" }}
              >
                {highlightSearchQuery(farCause.part.partName)}
              </h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {farCause.subPart
              .filter((subpart) => subpart.name.startsWith("Subpart"))
              .map((subpart) => (
                <div
                  key={subpart._id}
                  className="farCause11"
                  style={{ lineHeight: "0.8" }}
                >
                  <div style={{ width: "100%", display: "flex" }}>
                    <AddCircleOutlineIcon
                      className="blueIcon"
                      style={{
                        marginTop: "23px",
                        marginLeft: "50px",
                      }}
                      onClick={() =>
                        handleSubpartToggle(
                          farCause._id,
                          subpart._id
                        )
                      }
                    />
                    <h4
                      className="pointer"
                      onClick={() =>
                        handleHomeSuparts(
                          subpart?.link,
                          subpart?.name,
                          farCause?.part?.partName,
                          farCause?.location
                        )
                      }
                      style={{ marginLeft: "20px" }}
                    >
                      {highlightSearchQuery(subpart.name)}
                    </h4>
                  </div>
                  {iconState[farCause._id]?.[subpart._id] && (
                    <div>
                      {subpart.sections.map((section) => (
                        <div key={section._id} className="section">
                          <h5
                            className="pointer"
                            onClick={() =>
                              handleHomSections(
                                section?.link,
                                section?.name,
                                subpart?.name,
                                farCause?.part?.partName,
                                farCause?.location
                              )
                            }
                          >
                            {highlightSearchQuery(section.name)}
                          </h5>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))
  }

  // Function to highlight search query in text
  const highlightSearchQuery = (text) => {
    if (!searchQuery) return text;
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) => (
      regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
    ));
  };



  const extractPart = (url) => {
    // Split the URL string by the domain and get the second part
    const parts = url.split('.com')[1];
    // Remove the first slash if it exists
    const cleanedPart = parts.startsWith('/') ? parts.substring(1) : parts;
    // Return the desired part
    return cleanedPart;
  };

  const handleDownloadPart = async (clause, location) => {
    console.log(clause, location, "clauselocation")
    try {
      setLoading(true)
      const part = extractPart(location);
      console.log(part, "keyPart")
      console.log(clause, location, "downloadDetails");
      const path = location.split('/');
      const encodedFileName = path.pop();
      let id;
      const response = await axios.get(`https://api.contractpro360.com/api/opportunities/getFile/${id}/${clause}/${encodedFileName}`, {
        params: {
          part: part
        },
        responseType: 'blob'
      });
      console.log(response, "downloadResponse");
      if (response.status !== 200) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.download = encodedFileName;

      // Trigger a click event on the link to initiate the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup: revoke the temporary URL
      window.URL.revokeObjectURL(url);

      // Display success message
      showToast('Success.fileDownload')
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  const handleOpen = () => {
    console.log(accessFeatures, "accessFeatures")
    let featurename = "Upload"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Error.upload')
    } else {
      setOpen(true);
    }


  };

  const handleDateChange = (event) => {
    setAddEffectiveDate(event);
  };

  const handleOploadClose = () => {
    setUploadOpen(false);
    setUploadType("");
    setAddEffectiveDate("");
    setFile("");
    setFarPartsChecked("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadButtonClick = (e) => {
    setUploadType(e);
    setUploadOpen(true); // Open the second dialog
    setOpen(false); // Close the first dialog
  };

  const handleAddClick = async () => {
    try {
      setLoading(true);
      const email = sessionStorage.getItem("currentUserEmail")

      const formData = new FormData();
      const screenName = "Clause Generation"
      formData.append("uploadType", uploadType);
      formData.append("farPartsChecked", farPartsChecked);
      formData.append("filename", file);
      formData.append("addEffectiveDate", addEffectiveDate);
      formData.append("email", userInfo.username)
      formData.append("screenName", screenName)
      // Append other form data as needed

      if(!uploadType || !farPartsChecked || !file || !addEffectiveDate || !userInfo?.username || !screenName){
        console.log(uploadType ,farPartsChecked ,file ,addEffectiveDate ,userInfo?.username ,screenName,"formadattadata")
       showToast('Warning.allFields')
      }else{

      const response = await axios.post(
        `https://api.contractpro360.com/api/farcauses/uploadAndUpdateFarClause`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data?.status == 200) {
        showToast('Success.clauseAdded')
        setUploadOpen(false);
        setUploadType("");
        setAddEffectiveDate("");
        setFile("");
        setFarPartsChecked("");
      } else {
        showToast("Error.somethingFailed")
      }
    }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile); // Update the state with the selected file name
    // Additional logic if needed
  };

  const handlePartChange = (selectedOption) => {
    // alert(`from handlePartChange ${selectedOption}`);
    setSectionValue("");
    setSubpartValue("");
    setFullsubPartValue("");
    setFullSectionValue("");
    setPartValue(selectedOption.value);
    setFullPartValue(selectedOption.value);
    const selectedPart = selectedOption.value;

    const selectedPartEntry = partList.find(
      (entry) => entry.value === selectedPart
    );
    setFullPartValue(selectedPartEntry.nameData);
    console.log(selectedPartEntry.nameData, "selectedPartEntry");
    if (selectedPartEntry.location) {
      handleRedirectToPart(selectedPartEntry.location);
      setPartLocation(selectedPartEntry.location)
    }
    // Find all entries in subPartList that match the selected part
    console.log(subPartList,"***subPartList")
    console.log(selectedPart,"++selectedPart")
    const selectedPartEntries = subPartList
      .filter((entry) => entry.name === selectedPart)
      .map((entry) => ({ value: entry.value, label: entry.value }));
    console.log(selectedPartEntries, "selectedPartEntries");
    const filteredSubParts = selectedPartEntries?.filter(entry => entry.label.startsWith('Subpart'));
    // Set the selected subparts
    console.log(filteredSubParts, "_filteredSubParts")
    setSelectedSubParts(filteredSubParts);
  };

  const handleSectionSelect = async (link, ok) => {
    // history.push(`/FARclause/1.000`);
    const dataWithoutHash = link.replace("#", "");
    const parser = new DOMParser();
    console.log(htmlData, "checkhtmldata");
    let doc;
    if (ok) {
      doc = parser.parseFromString(ok, "text/html");
      console.log(doc, "checkdoc");
      const divContent = doc.getElementById(dataWithoutHash);
      console.log(divContent, "divContent");
      if (divContent) {
        // alert("inside")
        setSubpartData(divContent.innerHTML);
      }
    } else {
      doc = parser.parseFromString(htmlData, "text/html");
      console.log(doc, "checkdoc");
      const divContent = doc.getElementById(dataWithoutHash);
      console.log(divContent, "divContent");
      if (divContent) {
        // alert("inside")
        setSubpartData(divContent.innerHTML);
      }
    }
  };

  const handleSectionDataSelect = async (link, ok) => {
    // alert(link);
    // history.push(`/FARclause/1.000`);
    const dataWithoutHash = link.replace("#", "");
    // alert(dataWithoutHash)
    const parser = new DOMParser();
    let doc;
    if (ok) {
      doc = parser.parseFromString(ok, "text/html");
      console.log(doc, "checkdoc");
      const divContent = doc.getElementById(dataWithoutHash);
      console.log(divContent, "divContent");
      if (divContent) {
        // alert("inside")
        console.log(divContent);
        setSectionData(divContent.innerHTML);
      }
    } else {
      doc = parser.parseFromString(subpartData, "text/html");
      console.log(doc, "checkdoc");
      const divContent = doc.getElementById(dataWithoutHash);
      console.log(divContent, "divContent");
      if (divContent) {
        // alert("inside")
        console.log(divContent);
        setSectionData(divContent.innerHTML);
      }
    }
  };

  const handleSubpartChange = (selectedOption) => {
    setSubpartValue(selectedOption.value);
    const selectedPart = selectedOption.value;

    const selectedPartEntry = subPartList.find(
      (entry) => entry.value === selectedPart
    );
    setFullsubPartValue(selectedPartEntry.nameData);
    console.log(selectedPartEntry.nameData, "selectedSubpartLocation");
    if (selectedPartEntry.location) {
      handleSectionSelect(selectedPartEntry.location);
    }

    const selectedPartEntries = sectionList
      .filter((entry) => entry.name === selectedPart)
      .map((entry) => ({ value: entry.value, label: entry.value }));
    console.log(selectedPartEntries, "UUU");
    setSelectedSection(selectedPartEntries);
  };

  const handleDropdownChange = (selectedOption) => {
    setSectionValue(selectedOption.value);
    const selectedPart = selectedOption.value;

    const selectedPartEntry = sectionList.find(
      (entry) => entry.value === selectedPart
    );
    setFullSectionValue(selectedPartEntry.nameData);
    console.log(selectedPartEntry.location, "selectedSectionLocation");
    if (selectedPartEntry.location) {
      handleSectionDataSelect(selectedPartEntry.location);
    }
  };
  // alert(sectionValue)
  // console.log(sectionValue, "eventparteventpart");

  const getFarClauses = async (PartLocation) => {
    try {
      console.log(PartLocation, "VVVV")
      const parts = PartLocation.split("/");
      const clauseType = clauseValue;
      const filename = parts[parts.length - 1];
      const response = await axios.get(
        `https://api.contractpro360.com/api/farcauses/getClauses/${clauseType}/${filename}`
      );
      setHtmlData(response.data);
      parseHTML(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching FAR data:", error);
    }
  };

  const parseHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const partElements = doc.querySelectorAll("h1.title.topictitle1");
    const partsList = Array.from(partElements).map((part) => {
      const partName = part.textContent.trim();
      const subpartElements = Array.from(
        part.nextElementSibling.querySelectorAll(".Subpart")
      );
      const subparts = subpartElements.map((subpart) => {
        const subpartName = subpart.textContent.trim();
        const content = Array.from(subpart.nextElementSibling.children).map(
          (child) => child.outerHTML
        );
        return { name: subpartName, content };
      });
      return { name: partName, subparts };
    });
    setParts(partsList);
  };

  // const partList = masterdata.map((item) => ({
  //   value: item?.part?.partName.split(" - ")[0],
  //   name: item?.part?.partName.split(" - ")[0],
  //   location: item?.location,
  //   nameData: item?.part?.partName,
  // }));

  const partList = masterdata?.map((item) => {
    const partName = item?.part?.partName || "";
    // console.log(partName,"____partName")
    let partValue = '';

    // Check if partName starts with "APPENDIX"
    if (partName.startsWith("APPENDIX")) {
      // Skip this item or handle it as needed
      return null; // Skip this item
    }

    // Regular expression to match "Part X"
    const partMatch = partName.match(/(PARTS? \d+(-\d+)?)/i);

    if (partMatch) {
      partValue = partMatch[0]; // Extract the part number
    } else {
      // If no match found, extract the first word
      partValue = partName.split(/\s+/)[0];
    }
    // console.log(partValue,"**partValue")

    return {
      value: partValue,
      name: partValue,
      location: item?.location,
      nameData: partName,
    };
  }).filter(item => item !== null); // Filter out null items if any were skipped


  // const subPartList = masterdata?.reduce((acc, item) => {
  //   console.log(item,"++item")
  //   const partName = item.part?.partName.split(" - ")[0];
  //   const subParts = item.subPart || [];

  //   subParts.forEach((subItem) => {
  //     const data = subItem.name;
  //     const subPartName = subItem.name.split(" - ")[0];
  //    console.log(subPartName,"extractedSubPartName")
  //     acc.push({
  //       value: subPartName,
  //       name: partName,
  //       location: subItem.link,
  //       nameData: data,
  //     });
  //   });

  //   return acc;
  // }, []);
  const subPartList = masterdata?.reduce((acc, item) => {
    const partName = item.part?.partName || "";
    // const partMatch = partName.match(/Part \d+/);
    const partMatch = partName.match(/(PARTS? \d+(-\d+)?)/i)
    const extractedPartName = partMatch ? partMatch[0] : partName.split(" ")[0];

    const subParts = item.subPart || [];

    subParts.forEach((subItem) => {
      const subPartName = subItem.name || "";

      // Extracting the numerical part of subPartName
      const subPartMatch = subPartName.match(/\bSubpart (\d+\.\d+)/);
      const extractedSubPartName = subPartMatch ? `Subpart ${subPartMatch[1]}` : subPartName;
      // console.log(extractedSubPartName, "extractedSubPartName")
      acc.push({
        value: extractedSubPartName,
        name: extractedPartName,
        location: subItem.link,
        nameData: subPartName,
      });
    });

    return acc;
  }, []);

  // console.log("Final subPartList:", subPartList);

  // console.log("Final subPartList:", subPartList);



  const sectionList = masterdata?.flatMap((item) => {
    const partName = item.part?.partName.split(" - ")[0];
    return (
      item.subPart?.flatMap((subItem) => {
        const subPartName = subItem.name.split(" - ")[0];
        const sectionNames =
          subItem.sections?.map((sectionItem) => ({
            value: sectionItem.name.split(" ")[0],
            name: subPartName,
            nameData: sectionItem.name,
            location: sectionItem.link,
          })) || [];
        return sectionNames;
      }) || []
    );
  });

  // console.log(partList, "partList");
  // console.log(subPartList, "subPartList");
  // console.log(sectionList, "sectionList");

  const getParts = async (clauseType) => {
    try {
      const data = clauseType?.clauseType;
      if (data == "FAR") {
        setClauseValue(data);
      } else {
        setPartContent(false);
        setClauseValue(data);
      }
      setLoading(true)
      const response = await getFarParts(data);
      setLoading(false)
      setMasterdata(response?.data);
      setEffectiveDate(response?.Date);
      setCurrentEffectiveDate(response?.EffectiveDate);
      setChapterValue(response?.chapterData);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(masterdata, "masterdata")
  const handleFarChapters = async () => {
    try {
      const data = "FARChapter";
      if (data == "FAR") {
        setClauseValue(data);
      } else {
        setPartContent(false);
        setClauseValue(data);
      }
      setLoading(true)
      const response = await getFarParts(data);
      setLoading(false)
      setMasterdata(response.data);
      setEffectiveDate(response?.Date);
      setChapterValue(response?.data)
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getParts({ clauseType: "FAR" });
  // }, []);

  const handlePartToggle = (id) => {
    setIconState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSubpartToggle = (partId, subpartId) => {
    setIconState((prevState) => ({
      ...prevState,
      [partId]: {
        ...prevState[partId],
        [subpartId]: !prevState[partId]?.[subpartId],
      },
    }));
  };

  const toggleSideDiv = () => {
    setSideDivVisible(!sideDivVisible);
  };

  const handleFarCauseClick = (farCauseId) => {
    setSelectedFarCauseId(farCauseId);
    // setFarPartshow(true);
  };

  const handleRedirectToPart = (PartLocation) => {
    try {
    //  alert(`redirct ${PartLocation}`)
      setPartContent(true);

      getFarClauses(PartLocation);
    } catch (error) {
      console.log(error);
    }
  };

  const handlepartSidebar = (PartLocation, name) => {
    // alert(`from handlepartSidebar ${PartLocation},${name}`);
    try {
      setFullPartValue(name);
      setPartLocation(PartLocation)
      const newName = name.split(" - ")[0];
      setSectionValue("");
      setFullSectionValue("");
      setSubpartValue("");
      setFullsubPartValue("");
      setPartValue(newName);
      setFullPartValue(name);

      const selectedPartEntries = subPartList
        .filter((entry) => entry.name === newName)
        .map((entry) => ({ value: entry.value, name: entry.value }));
      console.log(selectedPartEntries, "ooooo");
      // Set the selected subparts
      setSelectedSubParts(selectedPartEntries);

      handleRedirectToPart(PartLocation);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHomeSuparts = async (
    PartLocation,
    name,
    partName,
    mainPartLocation
  ) => {
    // alert(`searchsubpart ${PartLocation}, ${name}, ${partName}, ${mainPartLocation}`);
    try {
      const newName = name.split(" - ")[0];
      const newpartName = partName.split(" - ")[0];
      setPartValue(newpartName);
      setFullPartValue(partName);
      setSubpartValue(newName);
      setFullsubPartValue(name);
      setSectionValue("");
      setFullSectionValue("");
      const ok = await getFarClauses(mainPartLocation);
      console.log(ok, "okok");

      const selectedPartEntries = sectionList
        .filter((entry) => entry.name === newName)
        .map((entry) => ({ value: entry.value, name: entry.value }));
      console.log(selectedPartEntries, "FFFF");
      setSelectedSection(selectedPartEntries);
      if (ok) {
        setPartContent(true);
        handleSectionSelect(PartLocation, ok);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleHomSections = async (
    sectionLocation,
    sectionName,
    subPartName,
    partName,
    mainPartLocation
  ) => {
    // alert(`searchsection ${sectionLocation}, ${sectionName}, ${subPartName}, ${partName}, ${mainPartLocation}`);
    try {
      console.log(
        sectionLocation,
        sectionName,
        subPartName,
        partName,
        mainPartLocation,
        "datatosee"
      );
      const secName = sectionName.split("  ")[0];
      const subName = subPartName.split(" - ")[0];
      const partame = partName.split(" - ")[0];
      console.log(secName, subName, partame, "allnames");
      setPartValue(partame);
      setFullPartValue(partName);
      setSubpartValue(subName);
      setFullsubPartValue(subPartName);
      setSectionValue(secName);
      setFullSectionValue(sectionName);
      const ok = await getFarClauses(mainPartLocation);
      console.log(ok, "okok");
      if (ok) {
        setPartContent(true);
        handleSectionDataSelect(sectionLocation, ok);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackButton = async () => {
    try {
      // setMasterdata("")
      setHtmlData("");
      // setSubpartData("")
      // setSectionData("")
      // setParts("")
      setPartContent(false);
      setSideDivVisible(false);
      setPartValue("");
      setFullPartValue("");
      setSubpartValue("");
      setFullsubPartValue("");
      setSectionValue("");
      setFullSectionValue("");
      setSelectedFarCauseId(null)
      setSelectedSection([]);
      setSelectedSubParts([]);
      setSelectedFarCauseId("");
      setPartLocation("")
      getParts({ clauseType: "FAR" });
      // clauseValue === 'FAR' ? getParts({ clauseType: "FAR" }) : getParts({ clauseType: "DFAR" })
    } catch (error) {
      console.log(error);
    }
  };

  let contentToRender;
  //  alert(sectionValueMain)
  if (subpartValue && sectionValue) {
    contentToRender = sectionData;
  } else if (subpartValue) {
    console.log(subpartData, "subpartDatasubpartData");
    contentToRender = subpartData;
  } else if (partValue) {
    contentToRender = htmlData;
  }

  const handleUploadButton = async () => { };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // console.log(
  //   partValue,
  //   subpartValue,
  //   sectionValue,
  //   partList,
  //   selectedSubParts,
  //   "alldatavalues"
  // );

  const sidebarStyle = {
    // width:'100%',
    transition: 'width 0.3s ease',
    // height: '130px',
    backgroundColor: 'white',
    zIndex: 1
    //  position:"fixed"

  };

  const clauseTitleBoxStyle = {
    // width: isOpen ? '20%' ? windowWidth <768 ? '49%' : '30%',
    width: isOpen ? '50%' : (windowWidth < 768 ? '60%' : '50%'),
    transition: 'width 0.3s ease',
    // border: "1px solid red"
  };

  const clauseWidth = {
    width: isOpen ? '55%' : (windowWidth < 1110 ? '100%' : '45%'),
    //  border: "1px solid orange"
  }
  // console.log(partList,"_____partList")
  const partOptions = partList?.map(part => ({
    value: part.value,
    label: part.value,  // Using `nameData` as the display label
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
  };
  return (
    <>
      <Loader loading={loading} />
      <div className="Far-container" >
        <div style={sidebarStyle} >
          <div className="mainFarTab">
            <div className="FarDivContainer">
              <div
                className={
                  clauseValue !== "FAR" && clauseValue !== "FARChapter" ? "FarDiv inactiveFarDiv" : "FarDiv"
                }
                onClick={() => getParts({ clauseType: "FAR" })}
                style={clauseTitleBoxStyle}
              >
                FAR Clause
              </div>
              <div
                className={
                  clauseValue !== "DFAR" ? "FarDiv inactiveFarDiv" : "FarDiv"
                }
                onClick={() => getParts({ clauseType: "DFAR" })}
                style={clauseTitleBoxStyle}
              >
                DFARS Clause
              </div>
            </div>
            <div className="clauseDate" >
              <div className="clauseInnerDate" style={clauseWidth}>
                {EffectiveDate && (
                  <div>Last sync with acquisition.gov on {EffectiveDate}</div>
                )}
                {clauseValue === "FAR" ? (
                  <p style={{ fontWeight: "bold" }}>
                    Effective Date: {currentEffectiveDate}
                  </p>
                ) : (
                  <p style={{ fontWeight: "bold" }}>
                    Effective Date: {currentEffectiveDate}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="horizontal" />
          <div className="AdditionalDiv">
            <div className="MainSearchBox">
              <div className="searchBox">
                <div className="searchIcon">
                  <i className="fa fa-search" style={{ color: "#007bff" }}></i>
                </div>
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search Clause"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            <div className="DropdownList">
              <Select
                options={partOptions}
                onChange={handlePartChange}
                value={partValue}
                placeholder={partValue ? partList?.find(option => option.value === partValue)?.value : "Part"}
                styles={customStyles}
              />
            </div>
            <div className="DropdownList">
              <Select
                options={selectedSubParts}
                onChange={handleSubpartChange}
                value={subpartValue}
                placeholder={subpartValue ? selectedSubParts?.find(option => option.value === subpartValue)?.value : "Subpart"}
                styles={customStyles}
              />
            </div>
            <div className="DropdownList">
              <Select
                options={selectedSection}
                onChange={handleDropdownChange}
                value={sectionValue}
                placeholder={sectionValue ? selectedSection?.find(option => option.value === sectionValue)?.value : "Section"}
                styles={customStyles}
              />
            </div>
            {fullPartValue &&
              <div>
                <div data-tip data-for="addcommenttolist" style={{ padding: '5px' }}>
                  <FileDownloadIcon
                    onClick={() => handleDownloadPart(clauseValue, partLocation)}
                    style={{ color: "#1976D2", cursor: 'pointer', marginLeft: "15px", fontSize: '30px', display: "flex", justifyContent: "end" }} // Adding cursor style to indicate it's clickable
                  />
                </div>
                <ReactTooltip id="addcommenttolist" place="bottom" effect="solid">
                  Download clause
                </ReactTooltip>
              </div>
            }
            <div className="tabButton">
              <Button
                onClick={handleBackButton}
                variant="contained"
                // color="primary"
                style={{ backgroundColor: "#1976D2", color: "white", marginLeft: "15px", height: '38px' }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="tabView">
            <div className="tabData">
              <small className="MainBedscrum">{clauseValue}</small>
              {fullPartValue && !fullsubPartValue && !fullSectiontValue && (
                <small className="selectedTab">
                  {" "}
                  {" >"} {fullPartValue}
                </small>
              )}
              {fullPartValue && fullsubPartValue && !fullSectiontValue && (
                <small className="selectedTab">
                  {" "}
                  {" >"} {fullsubPartValue}
                </small>
              )}
              {fullPartValue && fullsubPartValue && fullSectiontValue && (
                <small className="selectedTab">
                  {" "}
                  {" >"} {fullSectiontValue}
                </small>
              )}
            </div>
            {/* <div classname="tabButton">
              <Button
                onClick={handleOpen}
                variant="contained"
                // color="primary"
                style={{ backgroundColor: "#1976D2", color: "white", marginLeft:'15px' }}
                startIcon={<PublishIcon />}
              >
                Upload
              </Button>
            </div> */}
          </div>
        </div>
        <div className="MainContent">
          {partContent ? (
            <>
              {/* <div
                className="mainfardiv"
                style={{ width: sideDivVisible ? "83%" : "100%" }}
              >
                <div dangerouslySetInnerHTML={{ __html: contentToRender }} />
                <div className="parts-list">
                  {parts.map((part, index) => (
                    <div key={index} className="part">
                      <div className="part-header">{part.name}</div>
                      <div className="subparts">
                        {part.subparts.map((subpart, subIndex) => (
                          <div key={subIndex} className="subpart">
                            <div className="subpart-header">{subpart.name}</div>
                            <div className="subpart-content">
                              {subpart.content.map((content, contentIndex) => (
                                <div
                                  key={contentIndex}
                                  dangerouslySetInnerHTML={{ __html: content }}
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
              <>
                <div
                  className="mainfardiv"
                  style={{ width: sideDivVisible ? "83%" : "100%" }}
                >
                  {/* <div dangerouslySetInnerHTML={{ __html: htmlData }} /> */}
                  <div dangerouslySetInnerHTML={{ __html: highlightSearchQuery1(contentToRender) }} />
                  <div className="parts-list">
                    {parts.map((part, index) => (
                      <div key={index} className="part">
                        <div className="part-header" dangerouslySetInnerHTML={{ __html: highlightSearchQuery1(part.name) }} />
                        <div className="subparts">
                          {part.subparts.map((subpart, subIndex) => (
                            <div key={subIndex} className="subpart">
                              <div className="subpart-header" dangerouslySetInnerHTML={{ __html: highlightSearchQuery1(subpart.name) }} />
                              <div className="subpart-content">
                                {subpart.content.map((content, contentIndex) => (
                                  <div
                                    key={contentIndex}
                                    dangerouslySetInnerHTML={{ __html: highlightSearchQuery1(content) }}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              <div
                className="mainfardiv"
                style={{ width: sideDivVisible ? "83%" : "100%" }}
              >

                <div
                  className="FarClausePartTitles"
                  style={{ overflow: "auto" }}
                >
                  {renderContentWithHighlight(filterContent(masterdata))}

                </div>
              </div>
            </>
          )}
          {sideDivVisible && (
            <div className="sideFarDivMain">
              <div className="sideFarDivHeader">
                {clauseValue === "FAR" && "FAR Parts"}
                {clauseValue === "FARChapter" && "FAR Chapters" && <KeyboardBackspaceIcon style={{marginRight: "10px", cursor: "pointer"}} onClick={()=> handleBackButton()} />}
                {clauseValue === "FARChapter" && "FAR Chapters"}
                {clauseValue === "DFAR" && "DFAR Parts"}
              </div>
              <div className="numberContainer">
                {masterdata?.map((farCause, index) => (
                  <div
                    key={farCause._id}
                    className="numberItem"
                    onClick={() =>
                      handlepartSidebar(
                        farCause?.location,
                        farCause?.part?.partName
                      )
                    }
                  >
                    <div>
                      {clauseValue === "FAR" && index + 1}
                      {clauseValue === "FARChapter" && "990" + (1 + index)}
                      {clauseValue === "DFAR" && index + 200 + 1}
                    </div>
                  </div>
                ))}
              </div>
              {clauseValue == "FAR" && (
                <div className="ChapterData" onClick={handleFarChapters}>
                  {clauseValue == "FAR" && "Chapter 99 (CAS)"}
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={!sideDivVisible ? "arrowContainer" : "arrowContainerLeft"}
          onClick={toggleSideDiv}
          data-tip
          data-for="farparts"
        >
          <ReactTooltip id="farparts" place="bottom" effect="solid">
            Far Parts
          </ReactTooltip>
          {!sideDivVisible ? <KeyboardDoubleArrowLeftIcon style={{ color: "#fff" }} /> : <KeyboardDoubleArrowRightIcon style={{ color: "#fff" }} />}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ className: "dialogPaper" }}
      >
        <div className="dialogHeader">
          <DialogTitle className="dialogTitle">Upload Clause</DialogTitle>
          <div className="dialogClose">
            <CloseIcon onClick={handleClose} />
          </div>
        </div>
        <DialogContent className="dialogContent">
          {/* Add content for the dialog */}
          <Button
            className="dialogButton"
            onClick={() => handleUploadButtonClick("FAR")}
            variant="contained"
          // color="primary"
          >
            FAR
          </Button>
          <Button
            className="dialogButton"
            onClick={() => handleUploadButtonClick("DFAR")}
            variant="contained"
            // color="primary"
            style={{ backgroundColor: "#1976D2", color: "white" }}
          >
            DFAR
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={uploadOpen}
        onClose={handleOploadClose}
        PaperProps={{ className: "dialogClause" }}
      >
        <div className="dialogHeader">
          <DialogTitle className="dialogTitle">
            Upload {uploadType} Clause
          </DialogTitle>
          <div className="dialogClose">
            <CloseIcon onClick={handleOploadClose} />
          </div>
        </div>
        <DialogContent>
          {uploadType == "FAR" && (
            <div className="checkboxContent">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RadioGroup
                  aria-label="FAR Options"
                  name="farOptions"
                  value={farPartsChecked}
                  onChange={(event) => setFarPartsChecked(event.target.value)}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="parts"
                    control={<Radio color="primary" />}
                    label="FAR Parts"
                  />
                  <FormControlLabel
                    value="Chapter"
                    control={<Radio color="primary" />}
                    label="FAR Chapters"
                  />
                </RadioGroup>
              </div>
            </div>
          )}
          {farPartsChecked === "parts" && (
            <div className="infoText">
              You can upload files in HTML format for FAR Parts.
            </div>
          )}
          {farPartsChecked === "Chapter" && (
            <div className="infoText">
              You can upload files only in PDF format for FAR Chapters.
            </div>
          )}

          <div className="inputContent">
            <label htmlFor="fileInput" className="customFileInput">
              {file.name ? file.name : "Upload File"}{" "}
              <AttachFileIcon className="uploadFileIcon" />
            </label>
            <input
              type="file"
              id="fileInput"
              className="inputData"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>

          <div className="inputContent">

            {/* <input
                value={addEffectiveDate}
                name="responseDate"
                type="date"
                id="dateInput"
                onChange={handleDateChange}
                className="inputData"
              /> */}
            <DatePicker
              selected={addEffectiveDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              placeholderText="yyyy-mm-dd"
              customInput={
                <TextField
                  label="Select Date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />}
            />
            {/* <span className="placeholderText">
                {!addEffectiveDate && "Select Effective Date"}
              </span> */}

            <span className="infoIconWrapper">
              <InfoOutlinedIcon className="infoIcon" />
              <div className="popoverContent">
                <Typography>
                  The effective date will be determined by the date listed as
                  "Effective Date" field on the website{" "}
                  <a href="https://www.acquisition.gov/" />.
                  <img
                    src={farClause}
                    style={{
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Typography>
              </div>
            </span>
          </div>
          <div className="buttonContent">
            <Button
              className="dialogButton"
              onClick={handleOploadClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              className="dialogButton"
              onClick={handleAddClick}
              variant="contained"
              // color="primary"
              style={{ backgroundColor: "#1976D2", color: "white" }}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Farclause;

import React from "react";
import SideNavBar from "../SideNavBar";
import Myteam from "../teamMembers";
const TeamMembersPage = () => {
  return (
    <SideNavBar>
      <Myteam />
    </SideNavBar>
  );
};

export default TeamMembersPage;

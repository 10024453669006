import React, { useState } from "react";

const TagButtonComp = ({ id, name, onClickTagButton, className }) => {
    const [isClicked, setIsClicked] = useState(false)

    const normalButton = {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 300,
        borderRadius: "8px",
        borderStyle: "none",
        boxShadow: "2px 2px 5px -2px #000000",
        color: "#5E5E5E",
        padding: "10px",
        backgroundColor: "#ffffff",
        outline: "none"
    }

    const clickedButton = {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 300,
        borderRadius: "8px",
        borderStyle: "none",
        boxShadow: "2px 2px 5px -2px #000000",
        color: "white",
        padding: "10px",
        backgroundColor: "#82C1FF",
        outline: "none"
    }

    const handleButton = (e) => {
        setIsClicked(!isClicked)
        onClickTagButton(e)
    }

    return (
        <>
            <button
                id={id}
                // className="btn btn-outline-primary tBAppearance"
                style={isClicked ? clickedButton : normalButton}
                onClick={handleButton}
                className={className}
            >
                {name}
                {/* {name} */}
            </button>
        </>
    )
}
export default TagButtonComp
import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/notify`;
} else {
  apiEndpoint = `${herokuUrl}/notify`;
}

export async function getAlerts() {
  const data = await httpService.get(`${apiEndpoint}/getAlerts`);
  // console.log(data);
  return data;
}
// export async function getnotifications() {
//   const data = await httpService.get(`${apiEndpoint}/getNotifications`);
//   // console.log(data);
//   return data;
// }
export async function readnotification(data) {
  const details = await httpService.post(
    `${apiEndpoint}/readNotifications`,
    data
  );
  console.log("25", details);
  return details;
}
export async function readalerts(data) {
  const details = await httpService.post(`${apiEndpoint}/readAlerts`, data);
  console.log("33", details);
  return details;
}

export async function getNotifications(email) {
  const data = await httpService.get(`${apiEndpoint}/notifications?email=${email}`);
  return data;
}

export async function markAsRead(data) {
  const details = await httpService.post(`${apiEndpoint}/markAsRead`, data);
  return details;
}
export async function markAllAsRead(email) {
  const details = await httpService.post(`${apiEndpoint}/markAllAsRead`, {email});
  return details;
}
export async function getReadNotifications(userEmail) {
  const details = await httpService.post(`${apiEndpoint}/getReadNotifications`, {userEmail});
  return details;
}
export async function deleteAllReadNotifications(userEmail) {
  const details = await httpService.post(`${apiEndpoint}/deleteAllReadNotifications`, {userEmail});
  return details;
}
export default {
  getAlerts,
  // getnotifications,
  readnotification,
  readalerts,
  getNotifications,
  markAsRead,
  markAllAsRead,
  getReadNotifications,
  deleteAllReadNotifications
};

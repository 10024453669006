import React, { useState } from 'react';
import Switch from '@mui/material/Switch';

export default function SwitchCompMui({checked,onChange,disabled}) {
  // const [checked, setChecked] = useState(true);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={disabled}
    />
  );
}

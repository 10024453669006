import React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import queryString from "query-string";
import { addUserToTeam, getProfile } from '../../services/userService';
import { connect } from 'react-redux';
import { setCurrentUser } from "../../redux/user/user.actions";
import { withRouter } from 'react-router-dom';
import Loader from '../../utils/loader';
import _ from 'lodash';

const SocialMediaAuth = ({ location, setCurrentUser, history }) => {
    const [userID, setuserID] = useState('');
    const [team, setTeam] = useState({referedEmail:'',accountType:'Personal'});

    useEffect(() => {
        const { token, userID, organizationId, currentUserEmail, Role } = queryString.parse(
            location.search
        );
        if (!token) {
            history.push('/login');
        }
        localStorage.setItem('token', token);
        sessionStorage.setItem('token', token);
        setuserID(userID);
        localStorage.setItem('organizationId', organizationId);
        localStorage.setItem('currentUserEmail', currentUserEmail);
        localStorage.setItem('Role', Role);
        sessionStorage.setItem('organizationId', organizationId);
        sessionStorage.setItem('currentUserEmail', currentUserEmail);
        sessionStorage.setItem('Role', Role);
    }, [location]);

    useEffect(() => {
        const emptyTeam = _.some(team, _.isEmpty);
        console.log("emptyTeam", emptyTeam);
        console.log("Team===>", team);
        if(!emptyTeam){
            console.group("Now add to Team");
            addNewUserToRespectiveTeam(team.referedEmail, team.accountType);
        }else{
            console.group("all are empty");
        }
    },[team])

    useEffect(() => {
        let referedEmail = localStorage.getItem("referedEmail") !== "undefined" || null
            ? localStorage.getItem("referedEmail")
            : "";
        let accountType = localStorage.getItem("accountType") !== "undefined" || null
            ? localStorage.getItem("accountType")
            : "";
        console.log(referedEmail, accountType);
        if (referedEmail) {
            if (accountType) {
                setTeam({ referedEmail, accountType });
            } else {
                setTeam({ referedEmail });
            }
        }
        // const organizationId = localStorage.getItem('organizationId');
        // const currentUserEmail = localStorage.getItem('currentUserEmail')
        // const Role = localStorage.getItem('Role');
        // const orgToken = localStorage.getItem('orgToken')
        // console.log("organizationId", organizationId);
        // console.log("currentUserEmail", currentUserEmail);
        // console.log("Role", Role);
        // console.log("orgToken", orgToken);
        // console.log("userID", userID);
        
    }, []);




    const getUserProfile = async () => {
       try{
        const profile = await getProfile();
        console.log(profile, "profile");
        setCurrentUser({ ...profile.data });
        console.log(profile.data.isAdmin, "roleeee")
        console.log(profile.data.isAdmin, "roleeee")
        if (profile.data.isAdmin === true && profile.data.isSubAdmin === false) {
            return history.push('/admindashboard');
        } else if (profile.data.isAdmin === false && profile.data.isSubAdmin === true) {
            // return history.push('/subAdminManageUser');
            return history.push('/subAdminDashboard');
        } else {
            return history.push('/dashboard');
        }
       }catch(error){
        console.log(error,"error to fetch profile")
       }
    }

    useEffect(() => {
        if (userID) {
            getUserProfile();
        }
    }, [userID]);
    const addNewUserToRespectiveTeam = async (referedEmail, accountType) => {
        console.log('addNewUserToRespectiveTeam',referedEmail, accountType);
        const response = await addUserToTeam({ referedEmail, accountType });
        if (response.status === 200) {
            localStorage.removeItem("accountType");
            localStorage.removeItem("referedEmail");
        }else{
            //do something
        }
    }
    return (
        <Loader />
    );
}
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(withRouter(SocialMediaAuth));
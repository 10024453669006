import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/assetComments";
} else {
  apiEndpoint = herokuUrl + "/assetComments";
}

export async function addAssetComments(
  assetid,
  assetType,
  type,
  text,
  createdby
) {
  let data = await httpService.post(`${apiEndpoint}/addassetComments`, {
    assetid,
    assetType,
    type,
    text,
    createdby,
  });
  console.log(data);
  return data;
}

export async function deleteAssetComment (id){
  let data = await httpService.post(`${apiEndpoint}/deleteAssetComment`,{id:id});
  return data
}

export async function UpdateassetComments (id,username,text){
    let data = await httpService.post(`${apiEndpoint}/UpdateassetComments`,{    id:id,
      editedby:username,
      text:text
  });
  return data
}
export async function getAssetComments (id , assetType,type){
    
    let data = await httpService.post(`${apiEndpoint}/getassetComments`, {
      id,
      assetType,
      type
      });
      return data;
}

export async function replyToComment(
  text,
  createdby,
  commentId
) {
  let data = await httpService.post(`${apiEndpoint}/replyToComment`, {
    text,
    createdby,
    commentId
  });
  console.log(data);
  return data;
}

export async function deleteCommentReply (id){
  let data = await httpService.post(`${apiEndpoint}/deleteCommentReply`,{id:id});
  return data
}

export async function UpdateReplyOfComment (id,username,text){
  let data = await httpService.post(`${apiEndpoint}/UpdateReplyOfComment`,{    id:id,
    editedby:username,
    text:text
});
return data
}

import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Field, Form, FieldArray, useField } from "formik";
import { InputAdornment, TextField, Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { citationStructure } from "../../utils/citationWithRandomNumber";
import {
  savecitations,
  getStyle,
  updateCitations,
} from "../../services/citationService";
import deletes from "../../static/delete.png";
import star from "../../static/noun.png";
import plus from "../../static/plus.png";
import * as yup from "yup";
import InputBoxThree from "./FormComponents/InputBoxThree";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";

const InputField1 = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      id="outlined-error-helper-text"
      {...field}
      error={!!errorText}
      helperText={errorText}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img alt="star" src={star} />
          </InputAdornment>
        ),
        style: {
          height: "30px",
          width: "90%",
        },
      }}
    />
  );
};

const InputField2 = ({ placeholder, ...props }) => {
  const [field] = useField(props);
  return (
    <TextField
      id="outlined-error-helper-text"
      {...field}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        style: {
          height: "30px",
          width: "90%",
        },
      }}
    />
  );
};

const RadioField = (props) => {
  const [field] = useField(props);
  return <Radio {...field} color="primary" />;
};

// const validationSchema = yup.object({
//   title: yup.string().required("Required Field"),
//   author: yup.array().of(
//     yup.object({
//       given: yup.string().required("Required Field"),
//       family: yup.string().required("Required Field"),
//     })
//   ),
//   "container-title": yup.string().required("Required Field"),
//   DOI: yup.string().required("Required Field"),
//   url: yup.string().required("Required Field"),
//   accessed: yup.array().of(
//     yup.object({
//       year: yup.number().required("Required Field").positive(),
//     })
//   ),
// });

const onlineSchema = yup.object({
  title: yup.string().required("Mandatory"),
  author: yup.array().of(
    yup.object({
      given: yup.string().required("Mandatory"),
      family: yup.string().required("Mandatory"),
    })
  ),
  "container-title": yup.string().required("Mandatory"),
  year: yup.number().required("Mandatory").positive(),
  DOI: yup.string().required("Mandatory"),
  displayURL: yup.string().required("Mandatory"),
  URL: yup.string().when("displayURL", {
    is: "1",
    then: yup.string().required("Mandatory"),
  }),
  accessed: yup.array().of(
    yup.object({
      year: yup.number().required("Mandatory").positive(),
    })
  ),
});

const inPrintSchema = yup.object({
  title: yup.string().required("Mandatory"),
  author: yup.array().of(
    yup.object({
      given: yup.string().required("Mandatory"),
      family: yup.string().required("Mandatory"),
    })
  ),
  "container-title": yup.string().required("Mandatory"),
  year: yup.number().required("Mandatory").positive(),
});

const Skeleton1 = {
  title: "",
  note: "",
  author: [],
  "container-title": "",
  volume: "",
  edition: "",
  page: "",
  issued: {},
  DOI: "",
  URL: "",
  accessed: [],
  type: "",
  displayURL: "",
  year: "",
};

const Skeleton2 = {
  title: "",
  note: "",
  author: [],
  "container-title": "",
  volume: "",
  edition: "",
  page: "",
  issued: {},
  type: "",
  year: "",
};
let value = {};
function JournalForm({ formType, history, initialValues, projectID }) {
  const [loading, setLoading] = useState(false);

  if (initialValues) {
    console.log(initialValues);
    value = initialValues;
  } else {
    value = {
      title: "",
      note: "",
      author: [
        {
          title: "Author",
          given: "",
          family: "",
          suffix: "",
          middlename: "",
        },
      ],
      "container-title": "",
      volume: "",
      edition: "",
      page: "",
      year: "",
      issued: {
        season: "",
        "date-parts": [[""]],
      },
      DOI: "",
      URL: "",
      accessed: [
        {
          day: "",
          month: "",
          year: "",
        },
      ],
      displayURL: "0",
      type: "article-journal",
    };
  }
  return (
    <div>
      <ToastContainer />
      <Formik
        initialValues={value}
        validationSchema={
          formType === "Online_DB" ? onlineSchema : inPrintSchema
        }
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          let skeleton = {};
          if (formType === "In_Print") {
            skeleton = Skeleton2;
          } else {
            skeleton = Skeleton1;
          }
          for (var key of Object.keys(skeleton)) {
            skeleton[key] = values[key];
          }
          skeleton.issued["date-parts"][0][0] = values.year;
          skeleton["sub-type"] = formType;
          console.log(skeleton);
          let response = "";
          if (!values._id) {
            response = await savecitations(skeleton, projectID);
          } else {
            response = await updateCitations({
              _id: values._id,
              data: skeleton,
            });
          }
          if (response.status !== 200) {
            toast.error(response.message);
          } else {
            toast.info(response.message);
            // history.push('/citationList')
            history.push({
              pathname: `/citationList`,
              state: { projectId: projectID },
            });
            resetForm({});
          }
          setLoading(false);
        }}
        render={({ values, resetForm }) => (
          <Form className=" container bg-white rounded shadow">
            <Loader loading={loading} />
            <div className="row">
              <div
                class="row"
                style={{
                  backgroundColor: "#f0f0f7",
                  margin: "60px",
                  marginLeft: "150px",
                }}
              >
                <img
                  src={star}
                  alt="start"
                  style={{ height: "20px", width: "20px", margin: "7px" }}
                />
                <h6 style={{ marginLeft: "20px", marginTop: "7px" }}>
                  Add in any information you have below. The starred boxes are
                  mandatory.
                </h6>
              </div>
            </div>
            <div>
              <div className="row" style={{ marginLeft: "142px" }}>
                <h3>What I'm citing</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="row"
                    style={{ marginLeft: "142px", marginTop: "20px" }}
                  >
                    <h6 style={{ marginLeft: "1%" }}>Article title</h6>
                  </div>

                  <div className="row" style={{ marginLeft: "142px" }}>
                    <InputField1 name="title" />
                  </div>
                </div>

                {/* <div className="col-md-8">
                <div
                  className="row"
                  style={{ marginLeft: "142px", marginTop: "20px" }}
                >
                  <h6 style={{ marginLeft: "-3%" }}>Source was</h6>
                </div>

                <div
                  className="row"
                  style={{
                    marginLeft: "142px",
                    height: "20px",
                    marginTop: "-13px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Field
                        name="articleSource"
                        type="radio"
                        value="Originally in print but found online"
                        as={RadioField}
                      />
                    }
                    style={{
                      fontSize: "12px",
                      lineHeight: "13px",
                      color: "#707070",
                    }}
                    label=" Originally in print but found online"
                  />
                </div>
                <div className="row" style={{ marginLeft: "142px" }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="articleSource"
                        type="radio"
                        value="Published directly online"
                        as={RadioField}
                      />
                    }
                    style={{
                      fontSize: "12px",
                      lineHeight: "13px",
                      color: "#707070",
                    }}
                    label=" Published directly online"
                  />
                </div>
              </div> */}
              </div>
            </div>
            <hr
              style={{ marginTop: "30px", width: "900px", marginLeft: "100px" }}
            />

            {/* Contributors */}

            <FieldArray
              name="author"
              render={(arrayHelpers) => (
                <div>
                  <div style={{ marginLeft: "140px", marginTop: "10px" }}>
                    <h3>Authors</h3>
                  </div>
                  {values.author && values.author.length > 0
                    ? values.author.map((author, index) => (
                        <div
                          key={index}
                          style={{ marginLeft: "150px", marginTop: "10px" }}
                        >
                          <div
                            className="row"
                            style={{ marginTop: "20px", marginLeft: "-10px" }}
                          >
                            <div className="col-md-2">
                              <div className="row">
                                <h6>Article title</h6>
                              </div>
                              <div className="row">
                                <Field
                                  name={`author[${index}].title`}
                                  as="select"
                                  placeholder="title"
                                >
                                  <option value="Author">Author</option>
                                  <option value="Editor">Editor</option>
                                  <option value="Translator">Translator</option>
                                </Field>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="row">
                                <h6>First Name</h6>
                                <br />
                                <InputField1 name={`author[${index}].given`} />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="row">
                                <h6>MI / Middle</h6>
                                <br />
                                <InputField2
                                  name={`author[${index}].middlename`}
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="row">
                                <h6>Last Name</h6>
                                <br />
                                <InputField1 name={`author[${index}].family`} />
                              </div>
                            </div>
                            <div className="col-md-1">
                              <div className="row">
                                <h6>suffix</h6>
                                <br />
                                <InputField2 name={`author[${index}].suffix`} />
                              </div>
                            </div>
                            {values.author && values.author.length == 1 ? (
                              ""
                            ) : (
                              <div className="col-md-1">
                                <div className="row">
                                  <img
                                    alt="delete"
                                    src={deletes}
                                    style={{
                                      height: "20px",
                                      width: "15px",
                                      marginLeft: "8px",
                                      marginTop: "30%",
                                    }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="col-md-2"></div>
                          </div>
                        </div>
                      ))
                    : ""}
                  <div
                    className="row"
                    style={{ marginTop: "20px", marginLeft: "125px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outlined-primary"
                      style={{ color: "#1976d2" }}
                      onClick={() =>
                        arrayHelpers.push({
                          title: "Author",
                          given: "",
                          family: "",
                          suffix: "",
                          middle: "",
                        })
                      }
                    >
                      <img src={plus} className="ft_sm mr-2" alt="plus" />
                      Add another Author
                    </button>
                  </div>
                </div>
              )}
            />

            {/* Contributors */}

            <hr
              style={{ marginTop: "30px", width: "900px", marginLeft: "100px" }}
            />

            {/* Journal Publication Info */}
            <div style={{ marginLeft: "150px", marginTop: "10px" }}>
              <div className="row">
                <h3 style={{ marginLeft: "6px" }}>Journal publication info</h3>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-4">
                  <h6 style={{ marginLeft: "-4%" }}>Journal title</h6>
                  <br />
                  <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                    <InputField1 name="container-title" />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <h6 style={{ marginLeft: "6px" }}>Advanced info</h6>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-2">
                  <div className="row" style={{ marginLeft: "-9px" }}>
                    <h6>Vol.</h6>
                    <br />
                    <InputField2 name="volume" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="row">
                    <h6>Edition</h6>
                    <br />
                    <InputField2 name="edition" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="row">
                    <h6>Series/Season</h6>
                    <br />
                    <InputField2 name="issued.season" />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-4">
                  <h6 style={{ marginLeft: "-3%" }}>Year Published</h6>
                  <br />
                  <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                    <InputField1 name="year" />
                    {/* <InputField1 name="issued.date-parts[0][0]" /> */}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <h6 style={{ marginLeft: "8px" }}>Pages</h6>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-3">
                  <div
                    className="row"
                    style={{ marginLeft: "-4px", marginTop: "4%" }}
                  >
                    <h6>Page Start - Page End</h6>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="row">
                    <InputField2 name="page" style={{ float: "left" }} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <h6 style={{ float: "left", marginTop: "4%" }}>
                      * Ex: 354-512
                    </h6>
                  </div>
                </div>
                {/* <div className="col-md-2">
                <div className="row">
                  <h6>End</h6>
                  <br />
                  <InputField2 name="page" />
                </div>
              </div> */}
                {/* <div className="col-md-4">
                  <div className="row" style={{ marginTop: "5%" }}>
                    <FormControlLabel
                    control={
                      <Field
                        name="journalPublication.pages"
                        type="radio"
                        value="Pages are nonconsecutive"
                        as={RadioField}
                      />
                    }
                    label="Pages are nonconsecutive"
                  />
                  </div>
                </div> */}
              </div>
            </div>

            {/* journal publication info */}

            <hr
              style={{ marginTop: "30px", width: "900px", marginLeft: "100px" }}
            />

            {/*///////////// Website Publication Info //////////////// */}
            {/* {formType === "In_Print" ? (
            <>
              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3>Website publication info</h3>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6 style={{ marginLeft: "-3%" }}>Website title</h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <InputField1 name="WebsiteInfo.containertitle" />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6 style={{ marginLeft: "-3%" }}>Publisher/sponsor</h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <InputField2 name="WebsiteInfo.publisher" />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6 style={{ marginLeft: "-3%" }}>URL</h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <InputField1 name="WebsiteInfo.url" />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <h6>Display URL</h6>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <FormControlLabel
                      control={
                        <Field
                          name="WebsiteInfo.displayURL"
                          type="radio"
                          value="Yes"
                          as={RadioField}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name="WebsiteInfo.displayURL"
                          type="radio"
                          value="No"
                          as={RadioField}
                        />
                      }
                      label="No"
                    />
                  </div>

                  <div className="col-md-6">
                    <h6
                      style={{
                        fontSize: "10px",
                        lineHeight: "13px",
                        color: "#707070",
                        marginTop: "2%",
                      }}
                    >
                      Leave out the URL unless the source cannot be located
                      without it.
                    </h6>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6>Electonically published</h6>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Day</h6>
                      <br />
                      <InputField2 name="WebsiteInfo.E_publishedDate.day" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Month</h6>
                      <br />
                      <InputField2 name="WebsiteInfo.E_publishedDate.month" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Year</h6>
                      <br />
                      <InputField1 name="WebsiteInfo.E_publishedDate.year" />
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6>Data accessed</h6>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Day</h6>
                      <br />
                      <InputField2 name="WebsiteInfo.dataAccessDate.day" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Month</h6>
                      <br />
                      <InputField2 name="WebsiteInfo.dataAccessDate.month" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Year</h6>
                      <br />
                      <InputField1 name="WebsiteInfo.dataAccessDate.year" />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="row">
                      <h6
                        style={{
                          fontSize: "10px",
                          lineHeight: "13px",
                          color: "#707070",
                          marginTop: "13%",
                        }}
                      >
                        Use Today's Date
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />
            </>
          ) : (
            ""
          )} */}
            {/* ############  Website Publication Info ##############*/}

            {/* Online database publication info */}
            {formType === "Online_DB" ? (
              <>
                <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                  <div className="row">
                    <h3 style={{ marginLeft: "8px" }}>
                      Online database publication info
                    </h3>
                  </div>

                  <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-4">
                      <h6 style={{ marginLeft: "-3%" }}>
                        Digital Object Identifier (DOI)
                      </h6>
                      <br />
                      <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                        <InputField1 name="DOI" />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <h6 style={{ marginLeft: "8px" }}>Data accessed</h6>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-2">
                      <div className="row" style={{ marginLeft: "-6px" }}>
                        <h6>Day</h6>
                        <br />
                        <InputField2 name="accessed[0].day" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="row">
                        <h6>Month</h6>
                        <br />
                        <InputField2 name="accessed[0].month" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="row">
                        <h6>Year</h6>
                        <br />
                        <InputField1 name="accessed[0].year" />
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                      <div className="row">
                        <h6
                          style={{
                            fontSize: "10px",
                            lineHeight: "13px",
                            color: "#707070",
                            marginTop: "13%",
                          }}
                        >
                          Use Today's Date
                        </h6>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <h6
                      style={{
                        fontSize: "10px",
                        lineHeight: "13px",
                        color: "#707070",
                        marginTop: "2%",
                        marginLeft: "10px",
                      }}
                    >
                      Note: MLA 7 says to leave out the URL unless the source
                      cannot be located without it or if instructor requires it.
                    </h6>
                  </div>

                  {values.displayURL === "1" ? (
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-4">
                        <h6 style={{ marginLeft: "-3%" }}>URL</h6>
                        <br />
                        <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                          <InputField1 name="URL" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row" style={{ marginTop: "20px" }}>
                    <h6 style={{ marginLeft: "8px" }}>Display URL</h6>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <FormControlLabel
                        control={
                          <Field
                            name="displayURL"
                            type="radio"
                            value="1"
                            as={RadioField}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        control={
                          <Field
                            name="displayURL"
                            type="radio"
                            value="0"
                            as={RadioField}
                          />
                        }
                        label="No"
                      />
                    </div>

                    <div className="col-md-6">
                      <h6
                        style={{
                          fontSize: "10px",
                          lineHeight: "13px",
                          color: "#707070",
                          marginTop: "2%",
                        }}
                      >
                        Leave out the URL unless the source cannot be located
                        without it.
                      </h6>
                    </div>
                  </div>
                </div>
                <hr
                  style={{
                    marginTop: "30px",
                    width: "900px",
                    marginLeft: "100px",
                  }}
                />
              </>
            ) : (
              ""
            )}
            {/* ############# Online db publication info ###### */}

            {/* More Options */}
            <div>
              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>More Options</h3>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Annotation
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "-6px" }}>
                  <Field name="note" as={InputBoxThree} />
                  {/* <TextField
                  id="outlined-multiline-static"
                  style={{ width: "500px" }}
                  multiline
                  rows={4}
                  variant="outlined"
                /> */}
                </div>
              </div>
            </div>

            {/* More Options */}

            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-md-12">
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{
                      width: "15%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
          </Form>
        )}
      />
    </div>
  );
}

export default withRouter(JournalForm);

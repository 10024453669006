import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Typography,
} from "@material-ui/core";
import "./NotificationStyles.css";
import { markAsRead } from "../../services/notficationService";
import { markasread } from "../../services/activityTransaction";
import Added from "../.././static/admin_dashboard/Project_Added.png"
import Shared from '../.././static/admin_dashboard/Project_Shared.png'
import Rfp from '../.././static/admin_dashboard/RFP_Notification.png'
import Proposal from '../.././static/admin_dashboard/Proposal_Writing_Notification.png'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { getSpecificAsset } from "../../services/assetService";
import { useDispatch } from "react-redux";
import { showToast } from "../../messages";

import { rfmManagmentData, myprojectScreen, proposalWritingData } from "../../redux/opportunity/opportunity.action";
import { toast } from "react-toastify";

const NotificationCard = ({ notifications, recentActivities , filter }) => {
  const [notificationData, setNotificationData] = useState([]);
  const history = useHistory();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);


console.log(notifications,"notificationData")
  useEffect(() => {
    const combinedData = [...notifications, ...recentActivities];
    if ( combinedData.length > 0) {
      combinedData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
      setNotificationData(combinedData);
      console.log(combinedData,"__combinedData")
    }else{
      setNotificationData("")
    }
  }, [notifications, recentActivities]);


  const dispatch = useDispatch()
  const handleRedirecttoRfp =async (assetId,assetType)=>{
    console.log("called",assetId,assetType) 
    try{
        let data = {
            assetId
        }
        const response = await getSpecificAsset(data)
        if(response.status ==  200){
            console.log(response,"specific asset found")
            const data = response.data
        if(data.status === "Complete"){
          showToast('Warning.projectComplete')
          return
        }
        else if(assetType === "rfp"){
            dispatch(
                rfmManagmentData(
                    data.opportunityid.title,
                    data.opportunityid.noticeId,
                    data.opportunityid.descriptionData,
                    data.opportunityid._id,
                    data.opportunityid.type,
                    data.opportunityid?.responseDeadLine,
                    data.opportunityid?.fullParentPathName,
                    data._id,
                    data.opportunityid?.resourceLinksPath
                    ) 
                    );
                    dispatch(myprojectScreen("Oppoupload"))
                    history.push("/rfpManagement");
                }

        else if(assetType === "proposal"){
            dispatch(
                proposalWritingData(
                data.opportunityid.title,
                data.opportunityid.noticeId,
                data.opportunityid.descriptionData,
                data.opportunityid._id,
                data.opportunityid.type,
                data.opportunityid?.responseDeadLine,
                data.opportunityid?.fullParentPathName,
                data._id,
                )
              );
          
              history.push("/proposalWriting");
        }
        }

    }catch(error){
        console.log("error in finding specific asset",error)
    }
}

  const timeAgo = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diff = now - createdAt;

    if (diff < 60000) {
      return `${Math.floor(diff / 1000)} seconds ago`;
    } else if (diff < 3600000) {
      return `${Math.floor(diff / 60000)} minutes ago`;
    } else if (diff < 86400000) {
      return `${Math.floor(diff / 3600000)} hours ago`;
    } else {
      const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
      return createdAt.toLocaleDateString(undefined, options);
    }
  };

  const handleNotificationClick = async (notificationId, index) => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const response = await markAsRead({ notificationId, userEmail: email });
      if (response.status === 200) {
        // console.log("handleNotificationClick",response)
        const updatedNotifications = [...notificationData];
        updatedNotifications[index].read = true;
        setNotificationData(updatedNotifications);
        // console.log("handleNotificationClick@@",updatedNotifications[index],updatedNotifications[index].route)

        let data = {
          assetId:updatedNotifications[index].assetId
      }
        const response = await getSpecificAsset(data)
        if(response.status ==  200){
            console.log(response,"specific asset found")
            const data = response.data
        if(data?.status === "Complete"){
          showToast('Warning.projectComplete')
          return
        }
        history.push(updatedNotifications[index].route)
      }
      else{
        showToast('Warning.projectNotFound')
      }
       
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleActivityClick = async (notificationId, index, assetId, assetType) => {
    try {
      const userEmail = sessionStorage.getItem("currentUserEmail")
      const response = await markasread({ id: notificationId, userEmail });
      console.log(response,"mark all as read response")
      handleRedirecttoRfp(assetId, assetType)
    } catch (error) {
      console.error(error);
    }
  };
  console.log(notificationData,"__notificationData")
  console.log(recentActivities,"recentActivities")


  const noNotificationsMessage = filter === 'unread' ? 'No unread notifications found!' : 'No read notifications found!';


  return (
    <div style={{width:windowWidth < 500 ? "90vw" : "100%"}}>
 {notificationData && notificationData.length > 0 ? (
  notificationData.map((notification, index) => (
    <Card key={index} className={`card ${notification.read ? "read" : ""}`} onClick={() => {
      if (notification.route) {
        if(notification.route == "/rfpManagement"){
          handleActivityClick(notification._id, index, notification.assetId, notification.assetType);
        }else{
          handleNotificationClick(notification._id, index);
        }
        
      } else {
        handleActivityClick(notification._id, index, notification.assetId, notification.assetType);
      }
    }}>
      <CardHeader
        avatar={<img 
          className={notification.notificationType === "Project Added" || notification.notificationType === "Project Shared" ? "" : "rfp-image" }
          src={notification.notificationType === "Project Added" ? Added : (notification.notificationType === "Project Shared" ? Shared : (notification.assetType === "rfp" ? Rfp : Proposal))} />}

        title={
          <div>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "15px",
                color:
                  notification.notificationType === "Approaching Deadline!"
                    ? "#D82914"
                    : "#1976d2",
              }}
            >
{notification.notificationType ? notification.notificationType : notification.msg.split(" ")[2].charAt(0).toUpperCase() + notification.msg.split(" ")[2].slice(1) + " " + notification.msg.split(" ")[0]}
                    {notification.msg.includes("shared") && <PriorityHighRoundedIcon title="Urgency" style={{color:'red'}} />}
             </Typography>
            <div className="card-text">
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "bolder",
                }}
              >
                {notification.sectionTitle ? (notification.route ? notification.msg : notification.createdBy + " " + notification.msg + " in " + notification.assetType + " \"" + notification.title +"\"" + "for section " + notification.sectionTitle):
                (notification.route ? notification.msg : notification.createdBy + " " + notification.msg + " in " + notification.assetType + " \"" + notification.title +"\"")}
              </Typography>
            </div>
          </div>
        }
        subheader={
          <Typography style={{ fontSize: "11px" }}>
            {timeAgo(notification.createdOn)}
          </Typography>
        }
      />
    </Card>
  ))
):(
  <Typography style={{ padding: '16px', textAlign: 'center' }}>
    {noNotificationsMessage}
  </Typography>
)
}

    </div>
  );
};

export default NotificationCard;

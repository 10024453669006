import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ProjectCard from './ReusableComponents/ProjectCard';
import { getInprogressProjects,getAllocatedProjects } from '../services/assetService';
import { useDispatch } from "react-redux";
import { reminderAssetid } from '../redux/opportunity/opportunity.action';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../messages';

const ActiveProjectsPage = ({ onClose, statusType }) => {
  const [projects, setProjects] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const modalOverlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    outline: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    width:windowWidth? "90%":"60%",
    height: "fit-content",
    zIndex:9999
  };

  const backdropStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9998, 
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }
    const datePart = dateString.split(" at ")[0];
    const date = new Date(datePart);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const fetchProjects = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getInprogressProjects(data);
      if (response.status === 200) {
        const projectsData = response.data
          // .filter((project) => {
          //   const deadlineDate = new Date(project.opportunity.responseDeadLine);
          //   return deadlineDate >= new Date();
          // })
          .map((project) => ({
            projectName: project.opportunity.title,
            _id: project._id,
            date: formatDate(project.opportunity.responseDeadLine),
            status: project.status,
          }));
        if (projectsData.length === 0) {
          showToast('Organization.noActiveProjects')
          onClose();
        } else {
          setProjects(projectsData);
          setModalVisible(true);
        }
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchAllocatedProjects = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getAllocatedProjects(data);
      if (response.status === 200) {
        const projectsData = response.data
          // .filter((project) => {
          //   const deadlineDate = new Date(project.opportunity.responseDeadLine);
          //   return deadlineDate >= new Date();
          // })
          .map((project) => ({
            projectName: project.opportunity.title,
            _id: project._id,
            date: formatDate(project.opportunity.responseDeadLine),
            status: project.status,
          }));
        if (projectsData.length === 0) {
          showToast('Organization.noActiveProjects')
          onClose();
        } else {
          setProjects(projectsData);
          setModalVisible(true);
        }
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  useEffect(() => {
    if(statusType == 'Allocated Projects'){
      fetchAllocatedProjects()
    }else{
      fetchProjects();
    }
    
    
  }, []);

  const handleRedirect = (reminderAssetId) => {
    console.log("redirect", reminderAssetId);
    dispatch(reminderAssetid(reminderAssetId));
    history.push(`/OppourtunityManagementSubadminRedirect/${reminderAssetId}`);
  };

  return (
    <>
      {modalVisible && (
        <div style={backdropStyle}>
          <div style={modalOverlayStyle}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ color: "#1976d2", fontSize: '20px', fontWeight: 'bold' }}>{statusType}</p>
              <HighlightOffIcon onClick={onClose} style={{ color: 'red', cursor: 'pointer' }} aria-label="close" />
            </div>
            <div style={{ height: '500px', width: '100%', padding: '20px', overflowY: 'scroll' }}>
              {projects.map((project, index) => (
                <ProjectCard
                  key={index}
                  // cardWidth={820}
                  projectName={project.projectName}
                  date={project.date}
                  status={project.status}
                  redirect={handleRedirect}
                  assetid={project._id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveProjectsPage;

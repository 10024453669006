import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";



export const TaskAssignmentComentModal = (props)=>{
    const {open,close,commentData} = props

    useEffect(()=>{
        console.log("TaskAssignmentComentModal",props)
    },[])

    return (
        <>
<Modal
  open={open}
  onClose={close}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <Box
    style={{
      width: '70%',
      height:"70vh",
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" id="modal-modal-title">
        Comments
      </Typography>
      <IconButton onClick={close}>
        <CloseIcon id="closeIcon" />
      </IconButton>
    </div>
    
    <div
      style={{
        width: '100%',
        overflowY: 'auto',
        padding: '10px',
        borderRadius: '4px',
        marginTop: '10px',
        
      }}
    >
{commentData?.taskAssignWith?.filter(comment => comment.message && comment.message.trim() !== "").length === 0 ? (
  <p>No Comments</p>
) : (
  commentData?.taskAssignWith
    ?.filter(comment => comment.message && comment.message.trim() !== "")
    .map((comment, index) => (
      <div
        key={index}
        style={{
          marginBottom: '10px',
          padding: '10px',
          backgroundColor: '#f9f9f9',
          borderRadius: '4px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="body1">{comment.message}</Typography>
      </div>
    ))
)}


    </div>
  </Box>
</Modal>
        
        </>
    )
}




export const TaskAssignmentAddComentModal = (props)=>{
    const {open,close,value,onChange,submit} = props

    useEffect(()=>{
        console.log("TaskAssignmentComentModal",props)
    },[])

    return (
        <>
        <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box
                  style={{
                    width: '500px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h5" id="Clause-modal-title">
                      Add Comment
                    </Typography>
                    <IconButton onClick={close}>
                      <CloseIcon id="closeIcon" />
                    </IconButton>
                  </div>
                  <TextField
                    label="Add your comment"
                    multiline
                    rows={8}
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                  >
                    Submit
                  </Button>
                </Box>
              </Modal>
        
        </>
    )
}
import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ReminderCard = ({ projectname, date, color, onMoreIconClick ,redirect,assetid}) => {
  const getDeadlineColor = () => {
    if (color === 'red') {
      return '#D82914'; 
    } else if (color === 'orange') {
      return 'orange'; 
    } else if (color === 'green') {
      return 'green';
    } else {
      return 'black'; 
    }
  };

  return (
    <div style={{ borderLeft: `10px solid ${getDeadlineColor()}`, borderRight: '1px solid #1976D2', borderTop: '1px solid #1976D2', borderBottom: '1px solid #1976D2', borderRadius: '10px', boxShadow: '2px 2px 5px -2px #000000', marginBottom: '15px', height: 90, overflow: 'hidden', position: 'relative' }}>
      <div onClick={()=>{redirect(assetid)}} style={{ display: 'flex', flexDirection: 'column', height: '100%', cursor:'pointer' }}>
        <CardContent style={{ overflowY: 'auto', maxHeight: '30px' }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '13px', marginBottom: 8 }}>{projectname}</Typography>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', borderTop: '1px solid #ccc'  }}>
        <div style={{ position: 'absolute', top: '0', right: '0', display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer'}}>
            {/* <MoreVertIcon onClick={onMoreIconClick}/> */}
          </div>
          <Typography variant="caption" style={{ marginRight: '8px', color:'#1976d2' }}>{date}</Typography>
        </div>
      </div>
    </div>
  );
};

ReminderCard.propTypes = {
  projectname: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['red', 'orange', 'green', 'black']).isRequired,
  onMoreIconClick: PropTypes.func,
};

export default ReminderCard;
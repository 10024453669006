import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import SwitchCompMui from './SwitchCompMui';
import CheckboxMui from './CheckBoxMui';
import { toast } from 'react-toastify';
import adminServices from '../../services/adminServices';
import userService from '../../services/userService';

export const TwoFactorComp = ({ data, getProfile }) => {
    const [is2Factor, setIs2Factor] = useState(true)
    const [is2FMobileChecked, setIsis2FMobileChecked] = useState(true)
    const [is2FEmailChecked, setIs2FEmailChecked] = useState(false)
    const [is2FactorApproved, setIsis2FactorApproved] = useState(false)
    const [is2FReqApprovedMsg, setIs2FReqApprovedMsg] = useState(false)
    const [isRequestSent, setIsRequestSent] = useState(false)
    const [email, setEmail] = useState("")
    const [isSubAdmin, setIsSubAdmin] = useState(false)

    useEffect(() => {
        console.log("twofactdata", data)
        if (undefined !== data || data !== null) {
            // let {is2Factor,is2FEmailChecked,is2FactorApproved} = data
            setIs2Factor(data.is2Factor)
            // setIs2Factor(true)
            setIs2FEmailChecked(data.is2FEmailChecked)
            setIsis2FactorApproved(data.is2FactorApproved)
            setIs2FReqApprovedMsg(data.is2FReqApprovedMsg)
            setIsRequestSent(data.isRequestSent)
            setEmail(data.email)
            setIsSubAdmin(data.isSubAdmin)
        }
    }, [data])

    const handle2FactorSwitch = () => {
        setIs2Factor(!is2Factor)
        // setIsis2FMobileChecked(!is2FMobileChecked)
        setIs2FEmailChecked(is2Factor == false && false)
    }

    const handleEmailCheckBox = () => {
        setIs2FEmailChecked(!is2FEmailChecked)
    }

    const handle2FSave = async () => {

        let sendData = {
            "email": email,
            "is2Factor": is2Factor,
            // "is2FMobileChecked": is2FMobileChecked,
            "is2FEmailChecked": is2FEmailChecked,
            "isSubAdmin": isSubAdmin,
            // "isRequestSent": true,
        }
        console.log("sendData", sendData)
        try {
            let response = await adminServices.OTPRequest(sendData)
            console.log("handle2FSave", response, sendData)
            if (response.status == 200) {
                toast.success(response.message)
                getProfile(data.email)
                return
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log("error", error)
        }
    }

    return (
        <>
            <Card
                className="p-3 mt-4"
                style={{ border: "1px solid #969696", borderRadius: "8px", boxShadow: "3px 4px 8px 0px #000000" }}
            >
                <div className="row">
                    <div className='col-md-6'>
                        <p
                            className="mt-2 h6-headings">Two-Factor Authentication</p>
                    </div>
                    <div className='col-md-6 d-flex justify-content-end'>
                        <SwitchCompMui
                            // checked={is2Factor}
                            checked={is2Factor == true ? true : false}
                            onChange={handle2FactorSwitch}
                            // disabled={is2FactorApproved == true ? false : true}
                            disabled={isRequestSent == false ? false : true}
                            
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {is2Factor &&
                            <div
                                className={`checkbox-container ${is2Factor ? 'visible' : 'hidden'}`}
                            >
                                <CheckboxMui
                                    defaultChecked
                                    // disabled={data.is2FactorApproved == true ? false : true}
                                    disabled={true}
                                    id="mobile"
                                    label="Mobile"
                                    // isChecked={isCheckBoxChecked["mobile"]}
                                    isChecked={is2FMobileChecked}
                                // onChange={handleCheckBox}
                                />

                                <CheckboxMui
                                    id="email"
                                    label="Email"
                                    // isChecked={isCheckBoxChecked["email"]}
                                    isChecked={is2FEmailChecked}
                                    onChange={handleEmailCheckBox}
                                    disabled={isRequestSent == false ? false : true}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-9 d-flex justify-content-start'
                        style={{ textAlign: "left" }}
                    >
                        <p>{isRequestSent == true ? "Request is pending for approval" : ""}</p>
                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>
                        <button
                            className='btn btn-primary'
                            onClick={handle2FSave}
                            disabled={isRequestSent == false ? false : true}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Card>
        </>
    )
}
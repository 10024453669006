import React, { useState, useMemo } from "react";
import { Modal, Button } from "antd";
import JoditEditor from 'jodit-react';
 
export const PreviewEditor = ({ open, content, joditRef, toggle, handlePreviewOk, handlePreviewCancel }) => {
 
    // console.log("Reusable ", content)
 
    const config = {
        readonly: true,
        toolbar:false,
        branding: false,
        statusbar: false, // Set to false to hide the status bar
        height: 500,
        uploader: {
            insertImageAsBase64URI: true, // Enable Base64 encoding
        },
        events: {
            afterInit(instance) {
                joditRef.current = instance;
                console.log('jodit instance', instance.selection, joditRef.current);
            },
        },
    }
 
    const jodit = useMemo(() => {
        return (
            <div>
                <div className="p-2 mt-2">
                    <JoditEditor
                        value={content}
                        ref={joditRef}
                        config={config}
                        style={{ height: '80%', width: "90%" }}
                    />
                </div>
            </div>
        )
    }, [content]) // Include content as a dependency
 
    return (
        <>
            <Modal
                title="Document Preview"
                width={"80%"}
                height="80%"
                open={open}
                onOk={handlePreviewOk}
                onCancel={handlePreviewCancel}
                maskClosable={false}
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        <Button className="btn btn-secondary btn-sm" type="secondary"
                            onClick={handlePreviewCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                {jodit}
            </Modal>
        </>
    )
}
//snehal_22_07

// export default PieChartComp;
import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// CustomDropdown Component
const CustomDropdown = ({ options, onSelectChange, disabled, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optiondata,setOptionData]=useState("");
  const handleSelect = (option) => {
    onSelectChange(option.value);
    setOptionData(option.value)
    setIsOpen(false);
  };

  // Find the selected option's label
  const selectedLabel = optiondata ? optiondata : "Select an option";
  console.log(selectedLabel,"selectedLabel")
  return (
    <div className={`custom-dropdown ${disabled ? "disabled" : ""}`}>
      <div
        className="custom-dropdown-selected"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {selectedLabel}
        <span className="dropdown-arrow"><KeyboardArrowDownIcon /></span> {/* Down arrow icon */}
      </div>
      {isOpen && (
        <ul className="custom-dropdown-list">
          {options.map((option, index) => (
            <li
              key={index}
              className="custom-dropdown-option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const PieChartComp = ({
  chartHeading,
  overallPercentageHeading,
  pieChartData,
  overallTaskPercentage,
  isSelectDropdownShown,
  pieChartSelectOptions,
  onSelectChange,
  selectDropdownDisabled,
  selectedDropdownOption, // Make sure this prop is passed
  boxWidth,
  boxHeight,
  pieChartWidth,
  pieChartHeight,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        border: "1px solid #ccc",
        boxShadow: "2px 2px 5px -2px #000000",
        padding: "2px",
        width: boxWidth || "100%",
        height: boxHeight || "auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "20px", marginTop: "20px" }}>
          <h6
            className="p-2"
            style={{
              color: "#1976d2",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "15px",
            }}
          >
            {chartHeading}
          </h6>
          {overallPercentageHeading && overallTaskPercentage !== undefined && (
            <h6
              className="p-2"
              style={{
                color: "#171717",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                fontSize: "0.9rem",
              }}
            >
              {`${overallPercentageHeading}: ${overallTaskPercentage}%`}
            </h6>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          {isSelectDropdownShown && (
            <CustomDropdown
              options={pieChartSelectOptions}
              onSelectChange={onSelectChange}
              disabled={selectDropdownDisabled}
              selectedOption={selectedDropdownOption} // Pass the selected option here
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 px-4">
          <div>
            <PieChart
              data={pieChartData}
              label={(props) => `${props.dataEntry["value"]}%`}
              labelStyle={{
                fontSize: "0.5rem",
                fontFamily: "sans-serif",
                fill: "#ffffff",
              }}
              labelPosition={70}
              animate
              style={{
                width: pieChartWidth || "63%",
                height: pieChartHeight || "auto",
              }}
            />
          </div>
        </div>
        <div className="col-md-5" style={{marginTop:window.innerWidth < 850 ? "12px" : ""}}>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {pieChartData.map((entry, index) => (
              <li
                key={`item-${index}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <svg width="20" height="20">
                  <circle cx="10" cy="10" r="8" fill={entry.color} />
                </svg>
                <span style={{ marginLeft: "8px" }}>
                  {`${entry.title} (${entry.value})`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Custom CSS styling
const styles = `
.custom-select-dropdown option {
  background-color: #ffffff;
  color: #1976d2;
}

.custom-select-dropdown option:checked {
  background-color: red;
}

.custom-dropdown {
  position: relative;
  width: 130px;
  margin-left: 90px;
}

.custom-dropdown.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.custom-dropdown-selected {
  border: 1px solid #1976D2;
  border-radius: 10px;
  box-shadow: 1px 1px 4px -2px #000000;
  padding: 5px;
  background-color: #ffffff;
  cursor: pointer;
  width:100%;
  display:flex;
  justify-content:space-between;
}

.custom-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 1px 1px 4px -2px #000000;
  z-index: 1000;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 200px; /* Set max height for the list */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-arrow{
color:#1976D2;
}

.custom-dropdown-option {
  padding: 5px;
  cursor: pointer;
}

.custom-dropdown-option:hover {
  background-color: #1976D2;
  color: #ffffff;
}
`;

// Inject custom CSS styling
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default PieChartComp;

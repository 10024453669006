import React from 'react';
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import HelpAndSupport from '../HelpAndSupport';

const HelpAndSupportPage = () => {
  return (
    <div>
       <React.Fragment>
    <SideNavBar>
       <HelpAndSupport />
    </SideNavBar>
  </React.Fragment>
    </div>
  );
}

export default NetworkDetector(HelpAndSupportPage);


import React from 'react';
import NetworkDetector from '../HigherOrderComponents/NetworkDetector';
import ProposalCreation from '../ProposalCreation';
import SubAdminSideBar from '../SubAdminSideBar';
export const ProposalCreationPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <ProposalCreation />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(ProposalCreationPage);


import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/productnServiceCode";
} else {
    apiEndpoint = apiUrl + "/productnServiceCode";
}
   
 
  export async function getproductnServiceCode(){
    const response=await httpService.get(`${apiEndpoint}/getproductnServiceCode`);
    return response;
  }


   
  export default {
   
    getproductnServiceCode
   
  }
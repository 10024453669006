import React, { useEffect, useState } from "react";
import "./ContactCard.css";
import { Avatar } from "@material-ui/core";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import { Badge, Box, Stack, Typography } from "@mui/material"
import LetterAvatarsecond from "../../AvatarSecond";
import { socket } from "../Socket/Socket";
import { capitalize } from 'lodash';
import ChatServices from "../../../services/ChatServices";
import VideoContext from "../../../contexts/VideoContext";
const GroupCard = ({ contact, index }) => {

  const { setSelectedContactId, setShowFilePage, userId, setSelectedGroupId, setSelectedContactChat, setSelectedInboxId, setColorIndex, DeleteGroupId, setDeleteGroupId } = useContext(ChatContext);


  const [unreadMessagesCount, setUnreadMessagesCount] = useState(contact.unreadCount);

  const openChat = (id) => {
    setSelectedContactId(null);
    setSelectedGroupId(id);
    setSelectedContactChat(contact);
    setSelectedInboxId(contact._id);
    setColorIndex(index);
    setShowFilePage(false);
    socket.emit('join_group_room', { groupId: id });
    fetchunReadMsg(id)
    setDeleteGroupId(contact.groupId);
  };

  const member = contact?.members?.reduce((acc, member, index, array) => {
    const name = member.userName + (index === array.length - 1 ? '' : ', ');
    if (acc.length + name.length <= 50) {
      acc += name;
    }
    return acc;
  }, '').trim();


  const fetchunReadMsg = async (id) => {
    try {
      const response = await ChatServices.markReadgroupMsg(userId, id);
      if (response.status === 200) {
        setUnreadMessagesCount(0);
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }

  return (
    <div className="contactcard" style={{ backgroundColor: unreadMessagesCount === 0 ? '#fff' : '#B4D4FF' }} onClick={() => openChat(contact._id)}>
      <Box display='flex' flexDirection='row'>
      <LetterAvatarsecond name={contact.name}  />
      <div className="contactcard__username">
        <h3>{capitalize(contact.name)}</h3>
        <Typography variant='caption'>{member}</Typography>
      </div>
      </Box>
      <Box display='flex' justifyContent='end' alignContent='end'>
        <Badge color="primary" badgeContent={unreadMessagesCount} max={999}>
        </Badge>
      </Box>
    </div>
        );
        };

export default GroupCard;

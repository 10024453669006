import React, { Component } from "react";
import Group417 from "../static/Group417.png";

export class ScrollUp extends React.Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <div>
        {/* <button
          title="Back to top"
          className="scroll"
          onClick={() => {
            this.scrollToTop();
          }}
        > */}
        <img
          alt="Group417"
          src={Group417}
          className="scroll"
          onClick={() => {
            this.scrollToTop();
          }}
          style={{
            width: "49.9px",
            height: "49.9px",
            objectFit: "contain"
            // marginTop: "11%"
          }}
        ></img>
        {/* </button> */}
      </div>
    );
  }
}

export default ScrollUp;

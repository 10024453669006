import React, { Component } from "react";
import DocumentPreview from "../documentPreview";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";

class DocumentPreviewPage extends Component {
  state = {};

  render() {
    const { detail, isReviewing } = this.props.history.location.state;
    const user = authService.getCurrentUser();
    if (!user.isAdmin) {
    
      return (
        <SideNavBar>
          <DocumentPreview data={detail} />
        </SideNavBar>
      );
    } else {
      return (
        <AdminNavBar>
          <DocumentPreview data={detail} />
        </AdminNavBar>
      );
    }
  }
}

export default NetworkDetector(DocumentPreviewPage);



import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/locationcountry";
} else {
    apiEndpoint = apiUrl + "/locationcountry";
}
   
 
  export async function getcountry(){
    const response=await httpService.get(`${apiEndpoint}/getcountry`);
    return response;
  }


   
  export default {
   
    getcountry
   
  }
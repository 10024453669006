import * as React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
// import StyledInputBox from './Reusable_Components/StyledInputBox.jsx'

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "black",
            border: "none",
            // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            // transition: 'box-shadow 0.3s',
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "700",
            // fontSize: '14px',
            fontSize: "1rem",
            height: { screen: "ManualOpportunity" } && "40px",

            "&:focus": {
              color: "black",
              outline: "none",
              // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
            },
          },
          "& .MuiInputLabel-root": {
            color: "black",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "700",

            // fontSize: '14px',
            fontSize: "0.9rem",
            "&.Mui-focused": {
              color: "black",
            },
            // '&:focus': {
            //     color: '#646464',
            // },
          },
          "& .MuiInputBase-multiline": {
            // boxShadow: "none"
            // minHeight: '10px', // Set a minimum height for multiline
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: '#646464', // Change the outline border color
          // borderWidth: '1px', // Add a border width
          "&:focus": {
            // borderColor: '#646464',
          },
        },
        input: {
          "&:focus": {
            boxShadow: "none !important",
          },
        },
        inputSingle: {
          // border: 'none', // Remove the inner border for single-line input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
        },
        inputMultiline: {
          border: "none", // Remove the inner border for multiline input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
          // padding: "none"
          boxShadow: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s",
        },
      },
    },
  },
});



const InputTextField = ({
  id,
  value,
  name,
  height,
  onChange,
  label,
  width,
  type,
  multiline,
  rows,
  onBlur,
  className,
  multiple,
  variant,
  disabled,
  required,
  error,
  helperText,
  maxWidth,
  fullWidth,
  placeholder,
  defaultValue,
  autoComplete,
  onSelect,
}) => {

  console.log(value, typeof(value), "date");

  const handleKeyDown = (event) => {
    let element = event.target.nodeName;
    if (event.key === "Enter" && element !== "TEXTAREA") {
      event.preventDefault();
    }
  };

  const inputClasses = multiline ? "inputMultiline" : "inputSingle";

  const isShrinkable = name === "responseDate" || name === "attachments";

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="form"
        sx={{
          // m: 1,
          width: `${width}`,
          maxWidth: `${maxWidth}`,
          margin: "unset",
          background: !disabled && "white",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          variant="standard"
          className={className}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          label={label}
          type={type}
          multiline={multiline}
          rows={rows}
          multiple={multiple}
          disabled={disabled}
          required={required}
          autoComplete={autoComplete}
          onSelect={onSelect}
          size="small"
          fullWidth={fullWidth}
          placeholder={placeholder}
          InputProps={{
            classes: { inputSingle: inputClasses },
            style: { height: height ? height : "" ,width:width?width:""}, // Conditionally set height
          }}
          InputLabelProps={isShrinkable ? { shrink: true } : {}}
        />
      </Box>
    </ThemeProvider>
  );
};

export default InputTextField;

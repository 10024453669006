import React, { Component } from 'react'
import ReactApexChart from "react-apexcharts";
export default class PerformanceGraph extends Component {


  
    state={
        options: {
          chart: {
            height: 40,
            type: 'radialBar',
            colors:['#f0f2f8'],
           
          },
         
          labels : ['Performance'],
          legend:{
            show : false
          },
         
          dataLabels: {
            enabled: false
          },
          fill: {
            colors: ['#a3a0fb']
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '48%',
              }
            },
          },
         
        },
        series : [this.props.data]
      
    };

    componentDidMount(){
      if(this.props.data === "NaN"){
        this.setState({ series: [0] })
      }else{
        this.setState({ series: [this.props.data] })
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.data !== prevProps.data) {
        if(this.props.data === "NaN"){
          this.setState({ series: [0] })
        }else{
          this.setState({ series: [this.props.data] })
        }
      }
    }


    render() {
        return (
            <div id="chart">
                        
            <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={260} />
            
            </div>
        )
    }
}

import {
  apiUrl,
  herokuUrl
} from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
let apiEndpoint2="";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/citations`;
  apiEndpoint2 = `${apiUrl}/detectors`;
} else {
  apiEndpoint = `${herokuUrl}/citations`;
  apiEndpoint2 = `${apiUrl}/detectors`;
}
// apiEndpoint = `https://www.professionaleditingtool.com/api/citations`;

export async function insertCites() {
  const data = await httpService.post(`${apiEndpoint}/insertCite`);
  console.log(data)
  return data;
}
/////////

////////Duplication service////////////////////
export async function Duplicate(id){
  const data = await httpService.post(`${apiEndpoint}/duplicateCitation`,{ citationId: id })
  return data
}
export async function GetOnecitation(id){
  const data = await httpService.post(`${apiEndpoint}/getoneCitationDetails`,{ citationId: id })
  return data
}
/////////////////////////////////Duplication end//////////////

export async function getCheckCitation(text, citationStyle) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint2}/checkCitations`, {
    text,
    citationStyle: finalTextStyle
  });
  return data;
}

export async function getCitationDetails(data) {
  const response = await httpService.post(`${apiEndpoint}/getCitationDetails`,{data});
  return response;
}

export async function getAddCitation(formData,citationStyle) {
  const allowedSourceTypes = ["Webpage", "Website", "Book", "Book Chapter", "Image", "Journal Article", "Online Dictionary Entry", "Online News Article", "Report", "Video"];

const sourceType = allowedSourceTypes.includes(formData.heading) ? formData.heading : "Webpage";
  const data = await httpService.post(`${apiEndpoint}/addCitation`, {
    title: formData.title,
    description: formData.description,
    contributors: formData.contributors,
    websiteName: formData.websiteName,
    url: formData.url,
    email:formData.email,
    publicationDate: formData.publicationDate,
    accessDate: formData.accessDate,
    textStyle: citationStyle,
    sourceType: sourceType,
   
  });
  return data;
}

export async function getAddCitation2(websiteData, email) {
  const allowedSourceTypes = ["Webpage", "Website", "Book", "Book Chapter", "Image", "Journal Article", "Online Dictionary Entry", "Online News Article", "Report", "Video"];
  const sourceType = allowedSourceTypes.includes(websiteData.sourceType) ? websiteData.sourceType : "Webpage";
  const data = await httpService.post(`${apiEndpoint}/addCitation`, {
    title: websiteData.title,
    description: websiteData.description,
    websiteName: websiteData.websiteName,
    contributors:websiteData.contributors,
    publicationDate: websiteData.publicationDate,
    fullCitation: websiteData.fullCitation,
    textStyle: websiteData.citationStyle,
    url: websiteData.url,
    sourceType: sourceType, 
    email: email,
    inTextCitation: websiteData.inTextCitation
  });
  return data;
}

export async function deleteCitation(id) {
  const response = await httpService.post(`${apiEndpoint}/deleteCitation2`, {
    id,
  });
  return response;
}


// export async function getCitationDetailsByStyle(textStyle,email) {
//   const postData = { textStyle, email };
//   const data = await httpService.post(`${apiEndpoint}/getCitationDetailsByStyle`,postData);
//   return data;
// }


export async function deleteSelectedCitations(ids) {
  const response = await httpService.post(`${apiEndpoint}/deleteSelectedCitations`, {
    ids,
  });
  return response;
}
////////


export async function getStyle(styleDetails) {
  const data = await httpService.post(`${apiEndpoint}/getStyle`, {
    "data": styleDetails
  });
  return data;
}

export async function savecitations(styleDetails, id) {
  const data = await httpService.post(`${apiEndpoint}/savecitations`, {
    "data": styleDetails,
    projectId: id
  });
  return data;
}

export async function getMyCitations(projectId) {
  const data = await httpService.get(`${apiEndpoint}/getCitationsByUser/${projectId}`);
  return data;
}
export async function changeCitationStyle(data) {
  const response = await httpService.post(`${apiEndpoint}/changeStyle`, data);
  return response;
}

export async function updateCitations(data) {
  const response = await httpService.post(`${apiEndpoint}/updateCitations`, data);
  return response;
}

export async function deleteMyCitations(value, projectId) {
  console.log(value)
  const response = await httpService.post(`${apiEndpoint}/deleteCitations`, {
    id: value,
    projectId: projectId
  });
  return response;
}

export async function createProject(data) {
  const response = await httpService.post(`${apiEndpoint}/createProject`, data);
  return response;
}

export async function getAllProjects() {
  const response = await httpService.get(`${apiEndpoint}/getAllProjects`);
  return response;
}

export default {
  Duplicate,
  GetOnecitation,
  deleteSelectedCitations,
  // getCitationDetailsByStyle,
  deleteCitation,
  getAddCitation2,
  getCitationDetails,
  getAddCitation,
  getCheckCitation,
  getStyle,
  savecitations,
  insertCites,
  getMyCitations,
  changeCitationStyle,
  updateCitations,
  deleteMyCitations,
  createProject,
  getAllProjects
}
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    // typography: {
    //     color: '#646464',
    //     fontFamily: 'Montserrat, sans-serif',
    //     fontWeight: '600',
    //     fontSize: '14px',
    // },
    components:{
        MuiTypography:{
            styleOverrides:{
                root:{
                    color: '#4C4C4C',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: '500',
                    fontSize: '14px',
                }
            }
        }
    }
})

export default function RadioButtonMui({ radioButtonTitle, optionsList, handleChange }) {
    console.log("optionsList", optionsList)
    const controlProps = (item) => (
        {
            checked: item,
            onChange: handleChange,
            // value: item.value,
            name: 'size-radio-button-demo',
            inputProps: { 'aria-label': item },
        });
    return (
        <ThemeProvider theme={theme}>
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">{radioButtonTitle}</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                {optionsList.length > 0 && optionsList.map((obj) => {
                    return <FormControlLabel
                        value={obj.value}
                        control={<Radio {...controlProps(obj.status)} size="small" />}
                        label={obj.label} />
                })}

                {/* <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="other"
        /> */}
            </RadioGroup>
        </FormControl>
        </ThemeProvider>
    );
}

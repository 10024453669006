import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel,
} from "react-bootstrap";
import SwitchCompMui from './SwitchCompMui';
import CheckboxMui from './CheckBoxMui';
import adminServices from '../../services/adminServices';
import { toast } from 'react-toastify';

export default function PreferencesAccordionMui({ renderComponent }) {
    const [isSwitchChecked, setSwitchChecked] = useState(true)
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState({
        // "mobile": true,
        "email": false
    })

    const [is2Factor, setIs2Factor] = useState(true)
    const [is2FMobileChecked, setIsis2FMobileChecked] = useState(true)
    const [is2FEmailChecked, setIs2FEmailChecked] = useState(false)

    const handle2FactorSwitch = () => {
        setIs2Factor(!is2Factor)
        setIsis2FMobileChecked(!is2FMobileChecked)
        setIs2FEmailChecked(is2Factor == false && false)
    }

    const handleEmailCheckBox = () => {
        setIs2FEmailChecked(!is2FEmailChecked)
    }

    const handle2FSave = async () => {
        let sendData = {
            "email": sessionStorage.getItem("currentUserEmail"),
            "is2Factor": is2Factor,
            // "is2FMobileChecked": is2FMobileChecked,
            "is2FEmailChecked": is2FEmailChecked,
        }
        try {
            let response = await adminServices.OTPRequest(sendData)
            console.log("handle2FSave",response,sendData)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else{
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const handleSwitch = () => {
        console.log("handleSwitch")
        setSwitchChecked(!isSwitchChecked)
    }

    const handleCheckBox = (e) => {
        let elementID = e.target.id
        console.log("handleCheckBox", isCheckBoxChecked, elementID)
        setIsCheckBoxChecked({ ...isCheckBoxChecked, [elementID]: !isCheckBoxChecked[elementID] })
    }

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Preferences</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card
                        className="p-3"
                        style={{ border: "1px solid #969696", borderRadius: "8px", boxShadow: "3px 4px 8px 0px #000000" }}
                    >
                        <p className="mt-1 h6-headings">Written Language</p>
                        <p className="sub-headings">This is the default English dialect used across our tools</p>
                        {renderComponent()}
                    </Card>

                    {/* <Card
                        className="p-3 mt-4"
                        style={{ border: "1px solid #969696", borderRadius: "8px", boxShadow: "3px 4px 8px 0px #000000" }}
                    >
                        <div className="row">
                            <div className='col-md-6'>
                                <p
                                    className="mt-2 h6-headings">Two-Factor Authentication</p>
                            </div>
                            <div className='col-md-6 d-flex justify-content-end'>
                                <SwitchCompMui
                                    checked={is2Factor}
                                    onChange={handle2FactorSwitch}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {is2Factor &&
                                    <div
                                        className={`checkbox-container ${is2Factor ? 'visible' : 'hidden'}`}
                                    >
                                        <CheckboxMui
                                            defaultChecked
                                            disabled
                                            id="mobile"
                                            label="Mobile"
                                        // isChecked={isCheckBoxChecked["mobile"]}
                                        // onChange={handleCheckBox}
                                        />

                                        <CheckboxMui
                                            id="email"
                                            label="Email"
                                            // isChecked={isCheckBoxChecked["email"]}
                                            isChecked={is2FEmailChecked}
                                            onChange={handleEmailCheckBox}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row justify-content-end'>
                            <div className='col-md-3 d-flex justify-content-end'>
                                <button
                                    className='btn btn-primary'
                                    onClick={handle2FSave}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </Card> */}


                    {/* <Typography>
                        hi
                    </Typography> */}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

import React from 'react';
import AdminSideNavBar from '../AdminSideNavBar';
import BlogManagement from '../Editor/BlogManagement';

const BlogManagementPage = (props) => {
    return (<AdminSideNavBar >
        <BlogManagement blogDetails={props} />
    </AdminSideNavBar>);
}

export default BlogManagementPage;
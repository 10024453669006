import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog({ 
    openConfirmationDialog, 
    setOpenConfirmationDialog ,
    from, 
    Title, 
    Content ,
    handleDeleteContact,
    handleDeleteChat,
    handleArchive,
    handleArchiveGroup
}) {
 
    const handleClose = () => {
        setOpenConfirmationDialog(false);
    };

    const handleOperation = () =>{
        switch (from) {
            case "Archive":
                handleArchive();
                break;
            case "UnArchive":
                handleArchive();
                break;
            case "DeleteChat":
                handleDeleteChat();
                break;
            case "RemoveContact":
                handleDeleteContact();
                break;
            case "DeleteGroupChat":
                handleDeleteChat();
                break;
            case "ExitGroup":
                handleDeleteContact();
                break;
            case "ArchiveGroupChat":
                handleArchiveGroup();
                break;
            case "UnArchiveGroupChat":
                handleArchiveGroup();
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={openConfirmationDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{Title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                     {Content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='error' variant='contained'>Cancel</Button>
                    <Button onClick={handleOperation} color='primary' variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/setasides";
} else {
    apiEndpoint = apiUrl + "/setasides";
}
   
 
  export async function getsetAsides(){
    const response=await httpService.get(`${apiEndpoint}/getsetAsides`);
    return response;
  }


   
  export default {
   
    getsetAsides
   
  }
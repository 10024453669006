import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/orgopportunitymatchs`;
   
  } else {
    apiEndpoint = `${apiUrl}/orgopportunitymatchs`;
   
  }

  export async function addorgOpportunity(data){
    const response=await httpService.post(`${apiEndpoint}/addorgOpportunity`,{data});
    return response;
  }

  export async function getopportunitymatch(data){
    console.log(data,"data")
    const response=await httpService.post(`${apiEndpoint}/getopportunitymatch`,{data});
    return response;
  }

  export default {
    addorgOpportunity,
    getopportunitymatch
  }
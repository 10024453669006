import React, { Component } from "react";
import Rating from "react-rating";
import avatar from "../../static/Avatar.png";
import lastModified from "../../utils/lastModfied";
import Loader from "../../utils/loader";
import { addAdminComments } from "../../services/feedbackServices";
import { getProfile } from "../../services/userService";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

class CommentsCard extends Component {
  state = {
    admin: "",
    isAdmin: true,
    hidden: true,
    reply: "",
    loading: false,
  };

  async componentDidMount() {
    await this.getProfile_();
  }

  getProfile_ = async () => {
    const res = await getProfile();
    this.setState({ admin: res.data });
  };

  adminReply = async () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  };

  postReply = async (data) => {
    this.setState({ loading: true });
    if (this.state.reply.length < 1) {
      toast("Empty Comment");
      this.setState({ loading: false });
    } else {
      const sendData = {
        comment: this.state.reply,
        documentId: data.documentID,
        rating: 0,
        date: new Date(),
        reply: true,
        commentsID: data._id,
        documentType: data.documentType,
      };

      // const response = await addComments(sendData);
      const response = await addAdminComments(sendData);
      if (response.status === 200) {
        toast.success(response.message);
        await this.props.adminPost();
        this.setState({ hidden: !this.state.hidden, loading: false });
      } else {
        toast.error("Oops something went wrong");
        this.setState({ loading: false });
      }
    }
  };
  render() {
    const { isAdmin, hidden } = this.state;
    const today = new Date();
    let allComments = this.props.data;
    return (
      <div>
        <Loader loading={this.state.loading} />
        {allComments.reply === false ? (
          <div className="d-flex justify-content-between pb-4">
            <div className="d-flex">
              <img
                src={
                  allComments.postedBy
                    ? allComments.postedBy.display_picture !== ""
                      ? allComments.postedBy.display_picture
                      : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                    : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                }
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                }}
              />

              <div className="mx-3 ft_xs">
                <p className="m-0" style={{ fontWeight: "900" }}>
                  {allComments.postedBy && allComments.postedBy.username}
                  <small className="ml-2">
                    {lastModified(today, new Date(allComments.date))}
                  </small>
                </p>
                <p className="ft_sm "> {allComments.comment} </p>
                <div className="d-flex">
                  <Rating
                    placeholderRating={allComments.rating}
                    emptySymbol={
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    }
                    placeholderSymbol={
                      <i className="fa fa-star" aria-hidden="true"></i>
                    }
                    style={{ color: "orange" }}
                    readonly
                  />

                  <i
                    className="fa fa-reply ft_sm text-primary point ml-4"
                    aria-hidden="true"
                    onClick={() => this.adminReply(allComments)}
                  >
                    &nbsp; reply
                  </i>
                </div>
                {hidden ? (
                  ""
                ) : (
                  <div>
                    <div className="d-flex">
                      <img
                        src={this.state.admin.display_picture}
                        alt=""
                        style={{
                          marginTop: "4%",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                        }}
                      />

                      <form noValidate autoComplete="off">
                        <TextField
                          id="standard-basic"
                          label="Reply"
                          onChange={(e) =>
                            this.setState({ reply: e.target.value })
                          }
                          multiline
                          style={{ width: 400, marginLeft: "5%" }}
                        />
                      </form>
                      <i
                        className="fa fa-check ft_sm text-primary point ml-5"
                        onClick={() => this.postReply(allComments)}
                        aria-hidden="true"
                        style={{
                          marginTop: "7%",
                        }}
                      >
                        &nbsp; Post
                      </i>
                    </div>
                  </div>
                )}

                {/* replies */}

                {allComments.replies.length > 0 &&
                  allComments.replies.map((reply) => (
                    <div>
                      <div className="d-flex mt-3">
                        <img
                          src={
                            reply.postedBy
                              ? reply.postedBy.display_picture !== ""
                                ? reply.postedBy.display_picture
                                : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                              : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                          }
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                          }}
                          className="mr-2"
                        />
                        <p className="m-2" style={{ fontWeight: "900" }}>
                          {reply.postedBy && reply.postedBy.username}
                          <small className="ml-2">
                            {lastModified(today, new Date(reply.date))}
                          </small>
                        </p>
                      </div>
                      <p className="ft_sm" style={{ marginLeft: "30%" }}>
                        {" "}
                        {reply.comment}{" "}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            {isAdmin && (
              <i
                onClick={() =>
                  this.props.delete(
                    allComments.documentID._id,
                    allComments._id,
                    allComments.rating,
                    allComments.replies,
                    allComments.documentID.documentType
                  )
                }
                className="fa fa-trash ft_md text-primary point mr-0 mr-sm-4"
                aria-hidden="true"
              ></i>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CommentsCard;

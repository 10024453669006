import React from 'react';
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import { OpportunityManagementUpload } from './OppourtunityManagmentNewPage';
import SubAdminSideBar from '../SubAdminSideBar';
export const OpportunityrfpPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       <OpportunityManagementUpload />
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(OpportunityrfpPage);


import React from 'react';
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SubAdminSideBar from '../SubAdminSideBar';
import WorkflowAdministration from '../WorkflowAdministration';
import { WorkflowComponent } from './Workflow';

const WorkflowAdministrationSubAdminPage = () => {
  return (
    <React.Fragment>
    <SubAdminSideBar>
       {/* <WorkflowAdministration /> */}
       <WorkflowComponent/>
    </SubAdminSideBar>
  </React.Fragment>
  );
}

export default NetworkDetector(WorkflowAdministrationSubAdminPage);
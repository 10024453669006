import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/federalorganizations";
} else {
    apiEndpoint = apiUrl + "/federalorganizations";
}
   
 
  export async function getfederalorgs(){
    const response=await httpService.get(`${apiEndpoint}/getfederalorgs`);
    return response;
  }
   
  export default {
    getfederalorgs
  }
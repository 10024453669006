import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../utils/loader";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import "./settings.css";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/dist/style.css";
import ThemeSwitch from "./ThemeSwitch";
import { Link } from "react-router-dom";
import authService from "../services/authService";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import isEmptyObject from "../utils/isEmptyObject";
import BreadCrumb from "./Cards/BreadCrumbs";
import adminServices from "../services/adminServices";
import "./hover-min.css";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import {
  addDictionary,
  getmywords,
  deletemywords,
} from "../services/englishCheckers";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import uploadImageServices from "../services/uploadImageServices";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getProducts, addProduct, deleteProduct } from '../services/productService';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PreferencesAccordionMui from "./ReusableComponents/PreferenceAccordionMui";
import RadioButtonMui from "./ReusableComponents/RadioButtonMui";
import ImageAvatarsMui from "./ReusableComponents/ImageAvatarsMui";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { Button } from "@mui/material";
import CryptoJS from 'crypto-js';
import { TwoFactorComp } from "./ReusableComponents/TwoFactorComp";


const inputLabel = {
  fontSize: "15px",
  color: "#707070",
  lineHeight: "1.33",
  width: "90%",
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "none",
    fontSize: 20,
    textAlign: "left",
    width: "auto",
    fontWeight: "bold",
    // padding: "8px 10px",
    marginTop: "-7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
      borderBottom: "2px solid #1976d2",
    },
  },
}))(InputBase);

class Settings extends Component {
  state = {
    loading: true,
    data: {},
    error: {},
    npassword: "",
    cpassword: "",
    imagePreviewUrl: "",
    display: false,
    readonly: true,
    isEdit: false,
    visibility: "hidden",
    isAdmin: false,
    isUser: false,
    isSubAdmin: false,
    dashLink: "/dashboard",
    isDark: "false",
    errors: "",
    cerrors: "",
    disable: true,
    modalIsOpen: false,
    addWord: "",
    Dictionary: [],
    character_limit: 0,
    plagiarism_limit: 0,
    paraphrase_limit: 0,
    expiryDate: "",
    key: "",
    manageUser: false,
    sentence_highlighter: "",
    products: [],
    addProduct: "",
    productModal: false,
    radioSelection: 1,
    radioOptions: [
      {
        label: "American English",
        value: 1,
        status: true
      },
      {
        label: "Canadian English",
        value: 2,
        status: false
      },
      {
        label: "British English",
        value: 3,
        status: false
      }
    ],
    is2Factor: true,
    is2FEmailChecked: false,
    is2FMobileChecked: true,
    is2FactorApproved: false,
    is2FReqApprovedMsg: "",
    accountType: sessionStorage.getItem("accountType"),
    // accountType: "Organization",
    Role: sessionStorage.getItem("Role"),
    organizationId: sessionStorage.getItem("organizationId"),
    currentUserEmail: sessionStorage.getItem("currentUserEmail"),
    username: "",
    email: "",
  };

  OnRadioChange = (e) => {
    console.log('radio_checked', e.target.value);
    let value = e.target.value

    let updatedStatusList = this.state.radioOptions.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    this.setState({ radioSelection: value })
    this.setState({ radioOptions: updatedStatusList })

  };

  // const onRadioChange = (e) => {
  //   console.log('radio_checked', e.target.value);
  //   let value = e.target.value;

  //   let updatedStatusList = state.radioOptions.map((item) => {
  //     if (item.value == value) {
  //       item.status = true
  //     }
  //     else {
  //       item.status = false
  //     }
  //     return item
  //   })
  //   setState({ ...state, "radioSelection": value, "radioOptions": updatedStatusList });
  // };

  handleChangeSidenavBar = (event) => {
    const data = { ...this.state.data };
    data[event.target.name] = event.target.checked
    console.log(data)
    console.log(data["sidenavOption"])
    const { setCurrentUser } = this.props;
    setCurrentUser(data);
    this.setState({ data });
    // setState({ ...state, [event.target.name]: event.target.checked });
  };

  handleToggle = (value) => {
    const data = { ...this.state.data };
    data[value] = data[value] ? !data[value] : true;
    const { setCurrentUser } = this.props;
    setCurrentUser(data);
    this.setState({ data });
  };

  handleChange = ({ currentTarget: { value, name } }) => {
    const data = { ...this.state.data };
    console.log(data);
    data[name] = value;
    const { setCurrentUser } = this.props;
    setCurrentUser(data);
    this.setState({ data });
  };

  onChangeCPassword = (e) => {
    let cpass = e.target.value;
    let npass = this.state.npassword;
    this.setState({ cpassword: cpass });

    if (cpass.length > 0 && npass.length > 0) {
      if (this.state.errors.length > 1) {
        this.setState({ disable: true });
      } else {
        if (cpass !== npass) {
          this.setState({
            disable: true,
            cerrors: "Password & Confirm Password Field Should Match",
          });
        } else {
          this.setState({ errors: "", cerrors: "", disable: false });
        }
      }
    } else {
      this.setState({ errors: "", cerrors: "", disable: true });
    }
  };

  // async componentDidMount() {
  //   console.log("getProfile", "called")
  //   this.userProfileData();
  // }

  userProfileData = async () => {
    let { currentUser } = this.props;
    let params = currentUser.email
    let userData = await userService.getProfile(params);
    console.log("data", userData)
  }

  onChangeNPassword = (e) => {
    let npass = e.target.value;
    let cpass = this.state.cpassword;
    this.setState({ npassword: npass });

    if (npass.length > 0) {
      if (npass.length <= 7) {
        this.setState({
          disable: true,
          errors: "Password contains minimum 8 Characters",
        });
      } else {
        this.setState({ errors: "" });
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (!npass.match(upperCaseLetters)) {
          this.setState({
            disable: true,
            errors: "Password contains at least 1 Capital letter",
          });
        } else {
          this.setState({ errors: "" });
          if (!npass.match(numbers)) {
            this.setState({
              disable: true,
              errors: "Password contains at least 1 number",
            });
          } else {
            this.setState({ errors: "" });
          }
        }
      }
    } else {
      this.setState({ errors: "" });
    }

    if (
      cpass.length > 7 &&
      npass.length > 7 &&
      this.state.cerrors.length < 1 &&
      npass === cpass
    ) {
      this.setState({ errors: "", cerrors: "", disable: false });
    }
  };

  onClickResetPassword = async () => {
    const hashedPassword = CryptoJS.SHA256(this.state.npassword).toString();
    const hashConfirmPassword = CryptoJS.SHA256(this.state.cpassword).toString();
    let user = {
      email: this.state.data.email,
      // password: this.state.npassword,
      // cpassword: this.state.cpassword,
      password: hashedPassword,
      cpassword: hashConfirmPassword,
    };

    const result = await authService.resetPassword(user);
    if (result.status === "good") {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }

    this.setState({
      npassword: "",
      cpassword: "",
    });
  };

  onChangePhone = (e) => {
    const data = { ...this.state.data };
    data["phone"] = e;
    const { setCurrentUser } = this.props;
    setCurrentUser(data);
    this.setState({ data });
  };

  onChangeTimezone = (e) => {
    const data = { ...this.state.data };
    data["timezone"] = e;
    const { setCurrentUser } = this.props;
    setCurrentUser(data);
    this.setState({ data });
  };

  getProfile = async (params) => {
    let data = {};
    const { currentUser } = this.props
    let user = await userService.getUserProfile(params);
    data = { ...user.data };
    console.log("profileData", data, "user", user)
    setCurrentUser(data);
    this.setState(
      {
        isAdmin: data.isAdmin,
        isSubAdmin: data.isSubAdmin,
        data: data,
        loading: false,
        imagePreviewUrl: "dummy",
        isUser: !data.isAdmin,
        readonly: false,
        expiryDate: data.planID ? data.planID.expireDate : "",
        is2Factor: data.user.is2Factor,
        is2FEmailChecked: data?.user.is2FEmailChecked,
        ["is2FactorApproved"]: data?.OTP?.isApproved,
        ["is2FReqApprovedMsg"]: data?.OTP?.message,
        username: data.user.username,
        email: data.user.email

      },
      () => {
        if (data.isAdmin) {
          this.getCharacterLimit();
          this.getParaphraseLimit();
          this.getPlagiarismLimit();
          this.getSentenceLimit();
        }
      }
    );
  };

  handle2FactorSwitch = () => {
    this.setState({
      is2Factor: !this.state.is2Factor,
      is2FMobileChecked: !this.state.is2FMobileChecked,
      is2FEmailChecked: this.is2Factor == false && false
    })
  }

  handleEmailCheckBox = () => {
    this.setState({
      is2FEmailChecked: !this.state.is2FEmailChecked
    })
  }

  handleDeleteOrganizationUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your Request will be sent to Admin for Approval !!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Send Delete Request!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value === true) {
        async function ok() {
          let data = {
            email: this.state.currentUserEmail,
            typeOfRequest: "Delete Account"
          }
          // let response = await adminServices.deleteOrganizationUser(this.state.currentUserEmail)
          let response = await adminServices.updateUserRequest(data)
          if (response.status == 200) {
            toast.info(response.message)
            toast.info("Delete Request has been sent to Organization Admin")
          }
          else {
            toast.error(response.message)
          }
        }
        ok()
      }
      else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    })
  }

  handleDeleteAccount = () => {
    const { history } = this.props;
    console.log("history", history)
    Swal.fire({
      title: "Are you sure?",
      text: "It will permanently deleted !!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value === true) {
        async function ok() {
          let response = await userService.deleteAccount();
          if (response.status === 200) {
            toast.success(response.message);
            history.push({
              pathname: `/`,
            });
          } else {
            toast.error(response.message);
          }
          Swal.fire("Deleted!", "Your Account has been deleted.", "success");
        }
        ok();
      } else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };
  async componentDidMount() {
    console.log("state",this.state)
    // this.setState({
    //   data: {}
    // })
    const { currentUser, data, manageUser } = this.props;
    console.log("componenet", currentUser, data, manageUser)
    let params = currentUser.email

    // let profileData = this.getProfile(params)
    if (manageUser) {
      this.setState({
        dashLink: "/admindashboard",
        isAdmin: true,
        readonly: true,
        data: data,
        loading: false,
        isUser: false,
        manageUser,
      });
      this.getProfile(params);
    }
    else if (currentUser.isSubAdmin === true) {
      console.log(currentUser);
      // this.setState({ dashLink: "/subAdminManageUser" });
      this.setState({ dashLink: "/subAdminDashboard" });
      this.getProfile(params);
    }
    else if (currentUser && !isEmptyObject(currentUser)) {
      this.setState(
        {
          dashLink: currentUser.isAdmin ? "/admindashboard" : "/dashboard",
          isAdmin: currentUser.isAdmin,
          data: currentUser,
          isUser: !currentUser.isAdmin,
          isSubAdmin: currentUser.isSubAdmin,
          readonly: false,
          loading: false,
          expiryDate: currentUser.planID ? currentUser.planID.expireDate : "",
        },
        () => {
          if (currentUser.isAdmin) {
            this.getCharacterLimit();
            this.getParaphraseLimit();
            this.getPlagiarismLimit();
            this.getSentenceLimit();
            this.Blog_Products();
          } else {
            this.getDictionary();
            this.getProfile(params);
          }
        }
      );
    }

    // else {
    //   this.getProfile(params);
    // }
  }

  async componentWillMount() {
    $("#open").click(function () {
      $("#fileup").trigger("click");
    });
  }

  _handleImageChange = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { setCurrentUser } = this.props;
    let file = e.target.files[0];
    let key = document.getElementById("UploadprofilePic").getAttribute("name");
    if (!key) {
      key = null;
    }
    const uploadConfig = await uploadImageServices.UploadImageToS3(file, key);
    let user = { ...this.state.data };
    user.display_picture = uploadConfig.data.displayUrl;
    user.key = uploadConfig.data.key;
    setCurrentUser(user);
    this.setState(
      {
        data: user,
        imagePreviewUrl: uploadConfig.data.displayUrl,
        loading: false,
      },
      () => this.updateProfile()
    );
  };

  uploadPic = () => {
    const { isUser, manageUser } = this.state;
    if (isUser) {
      document.getElementById("fileup").click();
    } else {
      if (!manageUser) {
        document.getElementById("fileup").click();
      }
    }
  };

  updateProfile = () => {
    const { data, is2Factor, is2FMobileChecked, is2FEmailChecked } = this.state;
    data["is2Factor"] = is2Factor
    data["is2FMobileChecked"] = is2FMobileChecked
    data["is2FEmailChecked"] = is2FEmailChecked
    console.log("profileData", data)
    const { setCurrentUser, currentUser } = this.props;
    console.log(data);
    // let obj = {data,is2Factor,is2FEmailChecked}
    // console.log("updateProfileObj", obj)
    this.setState(
      {
        loading: true,
      },
      async () => {
        if (data.username === "") {
          this.setState({ loading: false });
          toast.error("Profile Update failed");
        } else {
          let user = await userService.updateProfile(data);
          if (user.status !== 200) {
            this.setState({ loading: false });
            toast.error(user.message);
          } else {
            toast.success(user.message);
            setCurrentUser(data)
            console.log(currentUser)
            this.setState({
              data,
              loading: false,
            });
          }
        }
      }
    );
  };

  handleActivateUser = async () => {
    const { data } = this.state;
    const user = await adminServices.activateUser({ email: data.email });
    if (user.status !== 200) {
      toast.error(user.message);
    } else {
      toast.info(user.message);
      this.getManagedUserProfile(user.data);
    }
  };

  handleDeactivateUser = async () => {
    const { data } = this.state;
    const user = await adminServices.freezeuser({ email: data.email });
    if (user.status !== 200) {
      toast.error(user.message);
    } else {
      toast.info(user.message);
      this.getManagedUserProfile(user.data);
    }
  };

  getManagedUserProfile = (data) => {
    this.setState({
      data,
    });
  };

  addWordDictonary = async () => {
    let data = await addDictionary(this.state.addWord);
    if (data.status !== 200) {
      toast.error("Failed to add the word");
    } else {
      this.getDictionary();
    }
    this.setState({ addWord: "" });
  };

  getDictionary = async () => {
    let data = await getmywords();
    if (data.status === 200) {
      this.setState({ Dictionary: data.words });
    }
  };

  removeWord = async (word) => {
    let data = await deletemywords(word);
    if (data.status !== 200) {
      toast.error("Failed to delete the word");
    } else {
      this.getDictionary();
    }
  };

  Blog_Products = async () => {
    let { data } = await getProducts();
    this.setState({ products: data.products });
  };

  addNewProduct = async () => {
    let product = this.state.addProduct;
    product = product.trim().toLowerCase().replace(/ /g, "_");
    let data = await addProduct(product);
    if (data.status !== 200) {
      toast.error("Failed to add product");
    } else {
      this.Blog_Products();
    }
    this.setState({ addProduct: "" });
  };

  removeProduct = async (product) => {
    let data = await deleteProduct(product);
    if (data.status !== 200) {
      toast.error("Failed to delete the word");
    } else {
      this.Blog_Products();
    }
  };


  // Character Limit
  getCharacterLimit = async () => {
    let data = await adminServices.getCharacterLimit();
    this.setState({ character_limit: data.character_limit });
    console.log(this.state.character_limit)
  };

  setCharacterLimit = async (limit) => {
    console.log(limit)
    let data = await adminServices.setCharacterLimit(limit);
    if (data.status === 200) {
      toast.success(data.message);
      this.getCharacterLimit();
    } else {
      toast.error(data.message);
    }
  };

  getSentenceLimit = async () => {
    let data = await adminServices.getSentenceLimit();
    this.setState({ sentence_highlighter: data.sentence_limit });
  };

  setSentenceLimit = async () => {
    let limit = this.state.sentence_highlighter;
    limit = limit.trim();
    if (limit[limit.length - 1] === ",") {
      limit = limit.slice(0, -1)
    }
    this.setState({ sentence_highlighter: limit });
    let data = await adminServices.setSentenceLimit(limit);
    if (data.status === 200) {
      toast.success(data.message);
      this.getSentenceLimit();
    } else {
      toast.error(data.message);
    }
  }

  // Plagiarism Limit
  getPlagiarismLimit = async () => {
    let data = await adminServices.getPlagiarismLimit();
    this.setState({ plagiarism_limit: data.plagiarism_limit });
    console.log(this.state.plagiarism_limit)
  };

  setPlagiarismLimit = async (limit) => {
    console.log(limit)
    let data = await adminServices.setPlagiarismLimit(limit);
    if (data.status === 200) {
      toast.success(data.message);
      this.getPlagiarismLimit();
    } else {
      toast.error(data.message);
    }
  };

  //Paraphrase Limit
  getParaphraseLimit = async () => {
    let data = await adminServices.getParaphraseLimit();
    this.setState({ paraphrase_limit: data.paraphrase_limit });
    console.log(this.state.paraphrase_limit)
  };

  setParaphraseLimit = async (limit) => {
    console.log(limit)
    let data = await adminServices.setParaphraseLimit(limit);
    if (data.status === 200) {
      toast.success(data.message);
      this.getParaphraseLimit();
    } else {
      toast.error(data.message);
    }
  };


  handleLimit = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  _biggify = () => {
    var fontSize = $("html").css("fontSize");
    var font = parseInt(fontSize);
    if (font < 20) {
      var newFontSize = parseInt(fontSize) + 2;
      $("html").css("fontSize", newFontSize + "px");
    } else {
      toast.warn("You have reached maximum font limit");
    }
  };

  _smallify = () => {
    var fontSize = $("html").css("fontSize");
    var font = parseInt(fontSize);
    if (font > 8) {
      var newFontSize = parseInt(fontSize) - 2;
      $("html").css("fontSize", newFontSize + "px");
    } else {
      toast.warn("You have reached minimum font limit");
    }
  };

  _fontReset = () => {
    $("html").css("fontSize", "12px");
  };

  render() {
    const {
      data,
      isUser,
      isAdmin,
      Dictionary,
      key,
      manageUser,
      currentUser,
      isSubAdmin,
      radioSelection,
      radioOptions,
      is2FEmailChecked,
      is2Factor
    } = this.state;
    return (
      <div>
        <div className="">
          <Loader loading={this.state.loading} />


          {isSubAdmin ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Account Settings"
              dashboardlink={this.state.dashLink}
            />
          ) : manageUser ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Manage Users"
              l3="Account Settings"
              dashboardlink={this.state.dashLink}
              documentLink="/manageuser"
            />
          ) : (
            <BreadCrumb
              l1="Home"
              l2="Account Settings"
              dashboardlink={this.state.dashLink}
            />
          )}

          {/* {manageUser ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Manage Users"
              l3="Account Settings"
              dashboardlink={this.state.dashLink}
              documentLink="/manageuser"
            />
          ) : (
            <BreadCrumb
              l1="Dashboard"
              l2="Account Settings"
              dashboardlink={this.state.dashLink}
            />
          )} */}
          <h4 className="ft_xl" style={{ margin: "0" }}>
            Account Settings
          </h4>
        </div>
        <br />
        <div className="bg-white rounded shadow">
          {/* <br /> */}

          {/* <h4 className="ft_lg firstcol" style={{ paddingTop: "2%" }}>
            Profile
          </h4>
          <hr /> */}

          <br />
          {/* <form> */}
          <div className="row mt-2">
            <div className="col-md-2 firstcol">
              <div className="text-center">
                <div className="hvrpic"
                  name={data.key}
                >
                  <ImageAvatarsMui
                    id="UploadprofilePic"
                    alt={data.username}
                    src={data.display_picture}
                  />
                  {/* <img
                      alt="profile_picture"
                      id="UploadprofilePic"
                      src={data.display_picture}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      className="fileInput point hvr-shrink settigns-dp"
                      name={data.key}
                      onClick={this.uploadPic}
                    /> */}
                  {isUser || !manageUser ? (
                    <div className="middleIcon">
                      <Button
                        // id="fileup"
                        // variant="contained"
                        // className="uploadBtn"
                        component="span"
                        onClick={this.uploadPic}
                      >
                        <CloudUploadRoundedIcon />
                      </Button>

                    </div>

                    // <div className="middleIcon">
                    //   <i
                    //     className="fa fa-cloud-upload point text-primary  "
                    //     aria-hidden="true"
                    //     onClick={this.uploadPic}
                    //   ></i>
                    // </div>

                  ) : (
                    ""
                  )}
                </div>
                <br />

                <input
                  className="fileInput"
                  type="file"
                  id="fileup"
                  accept="image/*"
                  onChange={(e) => this._handleImageChange(e)}
                  style={{ display: "none" }}
                  readonly={this.state.readonly}
                />
              </div>
            </div>

            <div className="hvrInput col-md-8"
              style={{ textAlign: "left" }}
            >
              <div>
                <FormControl>
                  <BootstrapInput
                    className="ft_xl"
                    // style={{textAlign: "left"}}
                    // defaultValue={data.username}
                    value={this.state.username || ""}
                    id="bootstrap-input"
                    onChange={this.handleChange}
                    name="username"
                    readOnly={this.state.readonly}
                    autoComplete="off"
                  />
                </FormControl>
                {this.state.readonly === false ? (
                  <div className="hvrpencil">
                    <i
                      className="fa fa-pencil point "
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group f1">
                {/* <label className="ft_xs">Email</label> */}
                {/* <p className="sub-headings">{this.state.data.email || ""}</p> */}
                <p className="sub-headings">{this.state.email || ""}</p>
                {/* <input
                    type="email"
                    className="form-control"
                    value={this.state.data.email || ""}
                    name="email"
                    onChange={this.handleChange}
                    readonly="true"
                  /> */}
              </div>
            </div>
          </div>
          <br />

          <hr style={{ width: "95%", margin: "15px auto" }} />

          {/* display pic and name */}
          <div className="row mt-3">
            <div className="col-md-12 firstcol">

              <p className="h6-headings">Email Verified <CheckCircleRoundedIcon />  </p>
              <p className="sub-headings">{this.state.email || ""}</p>
              {/* <p className="sub-headings2">Account linked with Google`</p> */}

              {/* <div className="form-group f1">
                  <label id="timezone" className="ft_xs">
                    Timezone
                  </label>
                  <TimezonePicker
                    // absolute={false}
                    name="timezone"
                    required
                    value={this.state.data.timezone}
                    placeholder="Select timezone..."
                    onChange={this.onChangeTimezone}
                    disabled={manageUser && isAdmin ? "true" : ""}
                    // readOnly="true"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div> */}

            </div>

            {/* <div className="col-sm-6">
                <div className="form-group f1">
                  <label className="ft_xs">Profession</label>
                  <input
                    type="text"
                    className="form-control"
                    name="profession"
                    value={this.state.data.profession || ""}
                    readonly={this.state.readonly}
                    onChange={this.handleChange}
                    disabled={manageUser && isAdmin ? "true" : ""}
                  // readonly="true"
                  />
                </div>
                <div className="form-group f1">
                  <label className="ft_xs">Mobile Number</label>

                  <PhoneInput
                    className="phoneInput"
                    value={this.state.data.phone}
                    name="phone"
                    required
                    onChange={this.onChangePhone}
                    // readOnly="true"
                    disabled={manageUser && isAdmin ? "true" : ""}
                    style={{ width: "50%", height: "100%" }}
                  />
                </div>
              </div> */}
          </div>
          <hr style={{ width: "95%", margin: "15px auto" }} />
          <div className="row mt-2">
            <div
              className="col-md-12 firstcol"
            >

              <div>
                <p className="h6-headings">Subscription Plan</p>
                <p className="sub-headings"><i
                  className="fa fa-check  icon_color mr-2"
                  aria-hidden="true"
                ></i> {data.planName || "free Trial"}</p>
                <p className="sub-headings2">Expiry :{" "}
                  {this.state.expiryDate
                    ? new Date(this.state.expiryDate)
                      .toString()
                      .slice(0, 15)
                    : "No Expiry Date"}</p>
              </div>

              <div className="mt-2">
                <Link to={{ pathname: "/pricing" }}>
                  {/* <button className="btn btn-primary hvr-float-shadow p-3 buysub">
                      <i class="fa fa-diamond" aria-hidden="true"></i> Upgrade Plans
                    </button> */}
                  <button
                    style={{
                      border: "1px solid #1976D2",
                      borderRadius: "8px",
                      boxShadow: "3px 3px 7px -2px #000000",
                      backgroundColor: "#ffffff",
                      color: "#1976D2",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      padding: "7px",
                      width: "150px"
                    }}
                  >
                    Upgrade Plans
                  </button>
                </Link>
              </div>

            </div>
          </div>
          <hr style={{ width: "95%", margin: "15px auto" }} />
          <div className="row mt-2">
            <div className="col-md-11 firstcol">
              <PreferencesAccordionMui
                // is2Factor={this.state.is2Factor}
                // handle2FactorSwitch={this.handle2FactorSwitch}
                // is2FEmailChecked={this.state.is2FEmailChecked}
                // handleEmailCheckBox={this.handleEmailCheckBox}
                renderComponent={() => <RadioButtonMui
                  optionsList={this.state.radioOptions}
                  selectedValue={this.state.radioSelection}
                  handleChange={(event) => this.OnRadioChange(event)}
                />}
              />

              {(this.state.Role == "Organization" && this.state.isSubAdmin == false && this.state.isAdmin == false) &&
                <TwoFactorComp
                  data={"is2FReqApprovedMsg" in this.state && this.state.is2FReqApprovedMsg !== "" ? this.state : this.state}
                  getProfile={this.getProfile}
                />
              }

            </div>
          </div>
          {/* <hr style={{width: "95%", margin: "15px auto"}} /> */}

          {/* email& phn */}
          {/* </form> */}
          {/* <div>
            {!data.isAdmin && (
              <>
                <div className="row mt-3">
                  <h4 className="ft_lg firstcol">Subscription</h4>
                </div>
                <hr />
                <br />
              </>
            )}
            <div className="row firstcol">
              {!data.isAdmin && (
                <div className="col-sm-6">
                  <div>
                    <label className="ft_md bold">Plan Details</label>
                  </div>
                  <br />
                  <div className="form-group pl-2">
                    <div>
                      <label className="ft_sm ">My Plan</label>
                    </div>

                    <i
                      className="fa fa-check  icon_color mr-2"
                      aria-hidden="true"
                    ></i>
                    <label>
                      <h6> {data.planName || "free Trial"}</h6>
                    </label>
                    <br />

                    {manageUser && isAdmin ? (
                      ""
                    ) : (
                      <>
                        <i
                          className="fa fa-clock-o mr-2"
                          aria-hidden="true"
                          style={{ color: "red" }}
                        ></i>
                        <label>
                          <h6>
                            Expiry :{" "}
                            {this.state.expiryDate
                              ? new Date(this.state.expiryDate)
                                .toString()
                                .slice(0, 15)
                              : "No Expiry Date"}
                          </h6>
                        </label>
                      </>
                    )}
                  </div>

                  <br />
                </div>
              )}

              {isUser ? (
                <div className="col-sm-6 ">
                  <div>
                    <label className="ft_md bold">Upgrade to get:</label>
                  </div>
                  <br />
                  <div className="form-group">
                    <i
                      className="fa fa-check  icon_color mr-2"
                      aria-hidden="true"
                    ></i>
                    <label>
                      <h6>Advanced Plagiarism detector.</h6>
                    </label>
                    <br />
                    <i
                      className="fa fa-check  icon_color mr-2"
                      aria-hidden="true"
                    ></i>
                    <label>
                      <h6>Vocabulary enhancement suggestions.</h6>
                    </label>
                  </div>
                  <div className="" style={{ marginTop: "-6%" }}>
                    <Link to={{ pathname: "/pricing" }}>
                      <button className="btn btn-primary hvr-float-shadow p-3 buysub">
                        <i class="fa fa-diamond" aria-hidden="true"></i> Buy
                        Subscription
                      </button>
                    </Link>
                  </div>

                  <br />
                </div>
              ) : !manageUser && isAdmin ? (
                // <div className="col-sm-6">
                //   <div>
                //     <label className="ft_md bold">Plan Details</label>
                //   </div>
                //   <br />
                // </div>
                ""
              ) : null}
            </div>
          </div> */}

          {/* subscription over */}

          {/* <br /> */}

          {!manageUser && isAdmin ? (
            <div>
              <div className="row mt-3">
                <h4 className="ft_lg firstcol">Character Limit</h4>
              </div>
              <hr />
              <br />

              <div className="row firstcol">
                <div className="col-lg-3 col-sm-6">
                  <div className="form-group pl-2">
                    <div>
                      <label className="ft_sm "><b>Document: </b></label>
                    </div>
                    <br />

                    <TextField
                      id="standard-basic"
                      // label={`Current limit ${this.state.character_limit}`}
                      label={`Current limit ${this.state.character_limit === undefined
                        ? 0
                        : this.state.character_limit
                        }`}
                      type="number"
                      name="character_limit"
                      value={this.state.character_limit}
                      onChange={this.handleLimit}
                    />
                    {/* <input
                      type="number"
                      name="character_limit"
                      value={character_limit}
                      onChange={this.handleLimit}
                    /> */}
                    <button
                      className="btn btn-primary mt-3 ml-2"
                      onClick={() =>
                        this.setCharacterLimit(this.state.character_limit)
                      }
                    >
                      Set Limit
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="form-group pl-2">
                    <div>
                      <label className="ft_sm "><b>Paraphrase: </b></label>
                    </div>
                    <br />

                    <TextField
                      id="standard-basic"
                      // label={`Current limit ${this.state.character_limit}`}
                      label={`Current limit ${this.state.paraphrase_limit === undefined
                        ? 0
                        : this.state.paraphrase_limit
                        }`}
                      type="number"
                      name="paraphrase_limit"
                      value={this.state.paraphrase_limit}
                      onChange={this.handleLimit}
                    />
                    {/* <input
                      type="number"
                      name="character_limit"
                      value={character_limit}
                      onChange={this.handleLimit}
                    /> */}
                    <button
                      className="btn btn-primary mt-3 ml-2"
                      onClick={() =>
                        this.setParaphraseLimit(this.state.paraphrase_limit)
                      }
                    >
                      Set Limit
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="form-group pl-2">
                    <div>
                      <label className="ft_sm "><b>Plagiarism: </b></label>
                    </div>
                    <br />

                    <TextField
                      id="standard-basic"
                      // label={`Current limit ${this.state.character_limit}`}
                      label={`Current limit ${this.state.plagiarism_limit === undefined
                        ? 0
                        : this.state.plagiarism_limit
                        }`}
                      type="number"
                      name="plagiarism_limit"
                      value={this.state.plagiarism_limit}
                      onChange={this.handleLimit}
                    />
                    {/* <input
                      type="number"
                      name="character_limit"
                      value={character_limit}
                      onChange={this.handleLimit}
                    /> */}
                    <button
                      className="btn btn-primary mt-3 ml-2"
                      onClick={() =>
                        this.setPlagiarismLimit(this.state.plagiarism_limit)
                      }
                    >
                      Set Limit
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="form-group pl-2">
                    <div>
                      <label className="ft_sm "><b>Sentence Highlighter: </b></label>
                    </div>
                    <br />

                    <TextField
                      id="standard-basic"
                      // label={`Current limit ${this.state.character_limit}`}
                      label={`${this.state.sentence_highlighter === undefined
                        ? 0
                        : this.state.sentence_highlighter
                        }`}
                      type="text"
                      name="sentence_highlighter"
                      value={this.state.sentence_highlighter}
                      onChange={(e) => this.setState({ sentence_highlighter: e.target.value })}
                    />
                    <button
                      className="btn btn-primary mt-3 ml-2"
                      onClick={this.setSentenceLimit}
                    >
                      Set Limit
                    </button>
                  </div>
                </div>

              </div>
            </div>
          ) : (
            ""
          )}

          {!manageUser && isAdmin ? (
            <div className="mb-5">
              <div className="row mt-5">
                <h4 className="ft_lg firstcol">Blog Settings</h4>
              </div>
              <hr />
              <br />
              <div className="row firstcol">
                <div className="d-inline-block ft_md point font-weight-bold del">
                  <button className="btn btn-primary" onClick={() => this.setState({ productModal: true })}>
                    Add Blog Products
                  </button>
                </div>
              </div>
            </div>
          ) : ("")}

          {/* {!manageUser && isUser || !manageUser && isAdmin ? (
            <React.Fragment>
              <div className="row mt-3">
                <div className="col-sm-12">
                  <h4 className="ft_lg firstcol">PET Account</h4>
                </div>
              </div>
              <hr />
              <br />

              <div className="row firstcol">
                <div className="col-sm-4">
                  <label className="ft_md bold">Color Mode</label>
                  <br />
                  <br />
                  <div>
                    <ThemeSwitch />
                  </div>

                  <div>
                    <label className="ft_xs mr-3">Dark</label>
                    <label className="ft_xs">Light</label>
                  </div>
                </div>
                <div className="col-sm-4">
                  <label className="ft_md bold">Font Size</label>
                  <br />
                  <br />
                  <button
                    className="p-1"
                    type="button"
                    style={{ border: "none", background: "white" }}
                    id="_biggify"
                    onClick={this._biggify}
                  >
                    <i className="fa fa-font fa-2x" aria-hidden="true"></i>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    &nbsp;&nbsp;&nbsp;
                  </button>

                  <button
                    className="p-1"
                    type="button"
                    style={{ border: "none", background: "white" }}
                    id="_reset"
                    onClick={this._fontReset}
                  >
                    <i className="fa fa-font fa-2x" aria-hidden="true"></i>
                    &nbsp;&nbsp;&nbsp;
                  </button>

                  <button
                    className="p-1"
                    type="button"
                    style={{ border: "none", background: "white" }}
                    id="_smallify"
                    onClick={this._smallify}
                  >
                    <i className="fa fa-font fa-2x" aria-hidden="true"></i>
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="col-sm-4">
                  <label className="ft_md bold">Sidenavbar (Open/Close)</label>
                  <br />
                  <br />
                  <FormGroup row>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={data["sidenavOption"]}
                          onChange={this.handleChangeSidenavBar}
                          name="sidenavOption"
                          color="primary"
                        />
                      }
                      label={data["sidenavOption"] === true ? "Open" : "Close"}
                    />
                  </FormGroup>
                </div>
              </div>
            </React.Fragment>
          ) : ("")} */}

          {/* <br /> */}

          {isUser ? (
            <div className="row firstcol">
              <div className="">
                {" "}
                {/* <div>
                  <div className="d-inline-block ft_md point font-weight-bold del mt-4">
                    <p
                      className="password_chng"
                      onClick={() => this.setState({ modalIsOpen: true })}
                    >
                      Customize Personal Dictionary
                    </p>
                  </div>
                  <p className="ft_xs mt-2">
                    Add your words in your personal dictionary, which will no
                    longer be flagged as misspellings.
                  </p>
                </div> */}
                <div className="d-inline-block ft_md point font-weight-bold del  mt-3">
                  {/* <p
                    className="h6-headings password_chng"
                    data-toggle="modal"
                    data-target="#ChangePassModal"
                  >
                    Change Password
                  </p> */}
                  <button
                    data-toggle="modal"
                    data-target="#ChangePassModal"
                    // className="accountSettingsBtn"
                    style={{
                      borderStyle: "none",
                      borderRadius: "8px",
                      boxShadow: "2px 2px 5px -2px #000000",
                      backgroundColor: "#ffffff",
                      color: "#1976D2",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      padding: "7px",
                      width: "150px",
                    }}
                  >
                    Change Password
                  </button>
                </div>
                <p className="ft_xs mt-2">
                  Upgrade your security check, by changing your password.
                </p>

                {this.state.isUser &&
                  <div>
                    <div className="d-inline-block ft_md point font-weight-bold del">
                      {/* <p
                    className={!this.state.isUser ? "AdminVisible" : "h6-headings"}
                    onClick={this.handleDeleteAccount}
                  >
                    Delete Account {isUser}
                  </p> */}
                      <button
                        // className={!this.state.isUser ? "AdminVisible" : "accountSettingsBtn"}
                        style={{
                          borderStyle: "none",
                          borderRadius: "8px",
                          boxShadow: "2px 2px 5px -2px #000000",
                          backgroundColor: "#ffffff",
                          color: "#1976D2",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "500",
                          fontSize: "0.9rem",
                          padding: "7px",
                          width: "150px",
                        }}
                        onClick={this.state.Role == "Organization" ? this.handleDeleteOrganizationUser : this.handleDeleteAccount}
                      >
                        Delete Account {isUser}
                      </button>
                    </div>
                    <p className="ft_xs mt-2">
                      This account is no longer available, and all your saved data
                      will be permanently deleted.
                    </p>
                  </div>
                }
              </div>

              <div className="col-sm-6  mt-4">
                <div>
                  {/* <div className="custom-control custom-checkbox">
                    <Checkbox
                      color="primary"
                      checked={
                        data["teamAlertDailyDigest"]
                          ? data["teamAlertDailyDigest"]
                          : false
                      }
                      onChange={() => this.handleToggle("teamAlertDailyDigest")}
                    />
                    <label
                      className="ft_xs"
                      for="teamAlertDailyDigest"
                      style={inputLabel}
                    >
                      RATHER HAVE TEAM FOLDER ALERT IN DAILY DIGEST?
                    </label>
                  </div> */}
                  {/* <div className="custom-control custom-checkbox my-1">
                    <Checkbox
                      color="primary"
                      checked={
                        data.newCommentAlert ? data["newCommentAlert"] : false
                      }
                      onChange={() => this.handleToggle("newCommentAlert")}
                    />
                    <label
                      readonly={this.state.readonly}
                      className=" ft_xs"
                      for="newCommentAlert"
                      style={inputLabel}
                    >
                      WANT EMAIL ALERTS OF NEW COMMENTS ON YOUR DOCUMENTS?
                    </label>
                  </div> */}
                  {/* <div className="custom-control custom-checkbox">
                    <Checkbox
                      // readonly={this.state.readonly}
                      checked={data["analytics"] ? data["analytics"] : false}
                      color="primary"
                      onChange={() => this.handleToggle("analytics")}
                    />
                    <label className="ft_xs" for="analytics" style={inputLabel}>
                      OPT-OUT OF ANALYTICS USED TO MAKE DRAFT BETTER
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <br />
          <hr style={{ width: "95%", margin: "15px auto", color: "#444444", height: "1px" }} />
          <br />
          <br />
          <div className="">
            {!this.state.manageUser || isUser ? (
              <div className="text-center">
                <Link
                  to={{
                    pathname: this.state.isAdmin
                      ? "/admindashboard"
                      : this.state.isSubAdmin
                        ? "/subAdminManageUser"
                        : "/dashboard",
                  }}
                >
                  <button
                    className="btn btn-outline-primary mr-3 mb-4"
                    type="button"
                    id="backBtn"
                    style={{
                      width: "20%",
                    }}
                  >
                    Back
                  </button>
                </Link>

                <button
                  className="btn btn-primary mb-4"
                  type="submit"
                  id="backBtn"
                  onClick={this.updateProfile}
                  style={{
                    width: "20%",
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="text-center">
                <button
                  type="button"
                  id="backBtn"
                  className="btn btn-outline-primary mr-3 mb-4"
                  style={{
                    width: "20%",
                  }}
                  disabled={!data.freeze ? true : false}
                  onClick={this.handleActivateUser}
                >
                  Activate
                </button>
                <button
                  type="button"
                  id="backBtn"
                  className="btn btn-primary mb-4"
                  style={{
                    width: "20%",
                  }}
                  disabled={data.freeze ? true : false}
                  onClick={this.handleDeactivateUser}
                >
                  Deactivate
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Modal */}
        <Modal
          show={this.state.modalIsOpen}
          onHide={() => this.setState({ modalIsOpen: false })}
          centered
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Personal Dictionary
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fluid">
              <div className="input-group mb-3">
                <input
                  type="text"
                  name="addWord"
                  value={this.state.addWord}
                  className="form-control"
                  placeholder="Add a new word"
                  aria-label="Add a new word"
                  onChange={(e) => this.setState({ addWord: e.target.value })}
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.addWordDictonary}
                  >
                    <i className="fa fa-plus ft_sm"></i> ADD
                  </button>
                </div>
              </div>
              <small>
                These words will no longer be flagged as misspellings.
              </small>
              <hr id="dictHr" />
              <div>
                {Dictionary.length > 0 &&
                  Dictionary.map((word) => {
                    return (
                      <div
                        key={word}
                        className="d-flex justify-content-between"
                      >
                        <p className="ft_sm">
                          <i
                            className="fa fa-book text-primary mr-2"
                            aria-hidden="true"
                          ></i>
                          {word}
                        </p>

                        <p
                          className="point ft_sm"
                          onClick={() => this.removeWord(word)}
                        >
                          <i
                            className="fa fa-trash point mr-2 text-primary"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal */}

        {/* Product Modal */}
        <Modal
          show={this.state.productModal}
          onHide={() => this.setState({ productModal: false })}
          centered
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Blog Products
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container-fluid">
              <div className="input-group mb-3">
                <input
                  type="text"
                  name="addWord"
                  value={this.state.addProduct}
                  className="form-control"
                  placeholder="Add a new product"
                  aria-label="Add a new product"
                  onChange={(e) => this.setState({ addProduct: e.target.value })}
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.addNewProduct}
                  >
                    <i className="fa fa-plus ft_sm"></i> ADD
                  </button>
                </div>
              </div>
              <hr id="dictHr" />
              <div>
                {this.state.products.length > 0 &&
                  this.state.products.map((product) => {
                    return (
                      <div
                        key={product}
                        className="d-flex justify-content-between"
                      >
                        <p className="ft_sm">
                          <i
                            className="fa fa-product-hunt text-primary mr-2"
                            aria-hidden="true"
                          ></i>
                          {product}
                        </p>

                        <p
                          className="point ft_sm"
                          onClick={() => this.removeProduct(product)}
                        >
                          <i
                            className="fa fa-trash point mr-2 text-primary"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Product Modal */}

        {/* ........ */}

        <div class="modal fade" id="ChangePassModal">
          <div
            class="modal-dialog modal-dialog-centered"
            style={{ width: "360px" }}
          >
            <div class="modal-content">
              {/* <!-- Modal Header --> */}
              <div class="modal-header">
                <div className="modal-title h4">Change Password</div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                <div className="row ">
                  <label style={{ marginLeft: "35%", marginTop: "3%" }}>
                    New Password
                  </label>
                </div>
                <div className="row">
                  <input
                    type="password"
                    className="form-control"
                    name="npassword"
                    value={this.state.npassword}
                    onChange={(e) => this.onChangeNPassword(e)}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    style={{
                      width: "50%",
                      marginLeft: "25%",
                    }}
                  />
                </div>
                <p
                  className="p-1 text-center"
                  style={{ color: "red", fontSize: "10px" }}
                >
                  {this.state.errors}
                  <br />
                  {this.state.cerrors}
                </p>
                <div className="row">
                  <label style={{ marginLeft: "30%", marginTop: "3%" }}>
                    Confirm New Password
                  </label>
                </div>
                <div className="row">
                  <input
                    type="password"
                    className="form-control"
                    name="cpassword"
                    value={this.state.cpassword}
                    onChange={(e) => this.onChangeCPassword(e)}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    style={{
                      width: "50%",
                      marginLeft: "25%",
                    }}
                  />
                </div>
                <div className="row" style={{ marginTop: "8%" }}>
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      autoComplete="current-password"
                      onClick={this.onClickResetPassword}
                      style={{
                        // color: "white",
                        // backgroundColor: "#1976d2",
                        width: "50%",
                      }}
                      disabled={this.state.disable}
                      data-dismiss="modal"
                    >
                      UPDATE PASSWORD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ................ */}
      </div >
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));

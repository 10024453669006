import React from "react";
import ChatContextProvider from "../contexts/ChatContext";
import Contacts from "../components/ChatBox/Contacts/Contacts"
import Main from "../components/ChatBox/Main/Main";
import { Box , Button} from "@mui/material";
import { QueryClientProvider, QueryClient } from "react-query";
import CallModal from "../components/ChatBox/options/CallModal";
import VideoChat from "../components/ChatBox/Calling/VideoChat";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";



const queryClient = new QueryClient();

const ChatBoxPage = ({setOpenChatClicked}) => {


  // const { InboxId = '', GroupInboxId = '' } = (history.location.state || {});

  return (
         <ChatContextProvider>
        <QueryClientProvider client={queryClient}>
        <div onClick={()=>setOpenChatClicked(false)} style={{ cursor: "pointer", display: "flex", alignItems: "center", color:'blue', margin:'15px' }}>
          <ArrowBackIcon />
          <span>Chat Messages</span>
        </div>
          <Box display='flex' maxHeight='80vh'>
          <CallModal />
          <VideoChat />
          <Contacts />
            <Main />
          </Box>
        </QueryClientProvider>
       </ChatContextProvider>

  );
};

export default ChatBoxPage;

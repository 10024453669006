import React from 'react';
import FileViewer from '../components/Fileviewer'; // Assuming you have the FileViewer component defined in a separate file

const Zipfile = () => {
    return (
        <div>
            <h1>File Content</h1>
            <FileViewer fileId="660ea3f4216f6a5f54d0bc62" />
        </div>
    );
}

export default Zipfile;


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function ImageAvatarsMui({ alt, src, id }) {
    return (
        <Stack direction="row" spacing={2}>
            <Avatar
                id={id}
                alt={alt}
                src={src}
                sx={{ bgcolor: "#1976D2", width: 45, height: 45 }}
            />
        </Stack>
    );
}

import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/publicblog`;
} else {
  apiEndpoint = `${herokuUrl}/publicblog`;
}

export async function getPublicBlogComment(BlogId) {
  const data = await http.post(`${apiEndpoint}/getPublicCommentForBlog`, {
    BlogId,
  });
  return data;
}

export async function postPublicBlogComment(comment) {
  const data = await http.post(`${apiEndpoint}/postPublicBlogComment`, comment);
  return data;
}
export async function getAllPublicBlogComment(comment) {
  const data = await http.get(`${apiEndpoint}/getAllPublicComments`);
  return data;
}
export async function deletePublicBlogComment(commentID) {
  const data = await http.post(`${apiEndpoint}/deletePublicCommentForBlog`, {
    commentID,
  });
  return data;
}

export default {
  getPublicBlogComment,
  postPublicBlogComment,
  getAllPublicBlogComment,
  deletePublicBlogComment,
};

import React, { Component } from 'react'
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Subadminsidebar from "../SubAdminSideBar"

import queryString from "query-string";
// import FeatureLists from '../FeatureLists';

import AppFeatureRoleLists from '../AppFeatureRoleLists';
import { useSelector } from 'react-redux';
import AdminSideNavBar from '../AdminSideNavBar';

const AppFeaturelistPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  const userData = useSelector((state) => state.user.currentUser);
  const isSuperAdmin = userData?.isAdmin

  return (
    <>
      {isSuperAdmin ? (
        <>
          <AdminSideNavBar>
            {/* <SubadminManageUser /> */}
            {/* <FeatureLists/> */}
            <AppFeatureRoleLists />
          </AdminSideNavBar>
        </>
      ) : (
        <>
          <Subadminsidebar>
            {/* <SubadminManageUser /> */}
            {/* <FeatureLists/> */}
            <AppFeatureRoleLists />
          </Subadminsidebar>
        </>
      )}
    </>

  );
};
export default NetworkDetector(AppFeaturelistPage);
import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import authService from "../services/authService";
import Group244 from "../static/Group244.png";
import Group457 from "../static/Group457.png";
import { Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PetLogo from "../static/signup_login/PetLogo.png"
import Login1 from "../static/login-3.png";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import CryptoJS from 'crypto-js';


import {
    Card,
    InputGroup,
    Form,
    Button,
    ButtonGroup,
    Carousel
} from "react-bootstrap";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui";
// import Router from "next/router";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    dense: {
        marginTop: 19
    }
}));

const headOneStyle = {
    fontSize: "42px",
    lineheight: "0.88",
    textAlign: "left",
    color: "#ffffff"
};

const headTwoStyle = {
    fontSize: "23px",
    fontWeight: "300",
    lineHeight: "0.91",
    textAlign: "left",
    color: "#ffffff"
};

function ResetPass({ history }) {
    const classes = useStyles();
    const [values, setValues] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState("");
    const [cpasserrors, setcpassErrors] = useState("");
    const [loading, setLoading] = useState(false);
    const [disable, setdisable] = useState(true);

    async function handlePassword(e) {
        let pass = e.target.value;
        if (pass.length > 0) {
            if (pass.length <= 7) {
                setdisable(true);
                setErrors("Password contains minimum 8 Characters");
            } else {
                setErrors("");

                var upperCaseLetters = /[A-Z]/g;
                var numbers = /[0-9]/g;

                if (!pass.match(upperCaseLetters)) {
                    setdisable(true);
                    setErrors("Password contains at least 1 Capital letter");
                } else {
                    setErrors("");
                    if (!pass.match(numbers)) {
                        setdisable(true);
                        setErrors("Password contains at least 1 number");
                    } else {
                        setErrors("");
                        setPassword(pass);
                        setdisable(false);
                    }
                }
            }
        } else {
            setErrors("");
        }
    }

    async function onClickResetPassword() {
        setLoading(true);
        const hashedPassword = CryptoJS.SHA256(password).toString();
        const hashConfirmPassword = CryptoJS.SHA256(confirmPassword).toString();
        let user = {
            email: sessionStorage.getItem("ForgotEmail"),
            password: hashedPassword,
            cpassword: hashConfirmPassword
        };
        console.log(user);
        if (confirmPassword === "") {
            setLoading(false);
            // errors("Password Field Cant be Empty");
            setcpassErrors("Password Field Cant be Empty");
        } else if (confirmPassword.length <= 7) {
            setLoading(false);
            setcpassErrors("Password Must Contain at Least 8 Characters");
        } else if (password !== confirmPassword) {
            setLoading(false);
            setcpassErrors("Password & Confirm Password Field Should Match");
        } else {
            // console.log(user);
            const result = await authService.resetPassword(user);
            if (result.status === "good") {
                setLoading(false);
                toast.success(result.message);
                history.push("/login");
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <div className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
                // position: "relative"
            }}
        >
             
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2">
                <Link to="/">
                    <img
                        className="petLogo"
                        alt="Group570"
                        src={PetLogo}
                    ></img>
                </Link>
            </div>


            <div
                className="row justify-content-end"
            >
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 text-center">
                    <Card
                        className="justify-content-center card-comp px-3 p-4"
                    >
                        <div className="text-center">
                            <h3 className="mainHeading"
                                style={{ color: "#353535" }}
                            >Create your Password</h3>
                            <p
                                style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.52",
                                    color: "#000000"
                                }}
                            >
                                Reset your password before the link expires
                            </p>
                        </div>

                        <div className="mt-4 py-2">
                            <InputBoxPasswordMui
                                fullWidth
                                type={values.showPassword ? "text" : "password"}
                                label="Password"
                                value={values.password}
                                onChange={e => handlePassword(e)}
                            //   onMouseDown={handleMouseDownPassword}
                            />

                            <p
                                className="p-2"
                                style={{ color: "red", fontSize: "10px", textAlign: "left" }}
                            >
                                {errors}
                            </p>
                        </div>

                        <div className="mt-1 py-1">
                            <InputBoxPasswordMui
                                fullWidth
                                type={values.showPassword ? "text" : "password"}
                                label="Confirm Password"
                                value={values.password}
                                onChange={e => setConfirmPassword(e.target.value)}
                            //   onMouseDown={handleMouseDownPassword}
                            />

                            <p
                                className="pl-2"
                                style={{ color: "red", fontSize: "10px", textAlign: "left"  }}
                            >
                                {cpasserrors}
                            </p>
                        </div>

                        <div className="text-center mt-4 py-2">
                            <Button
                                className="confirmPassword"
                                onClick={onClickResetPassword}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
export default withRouter(ResetPass);

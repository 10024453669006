import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/subscribedusers`;
} else {
  apiEndpoint = `${herokuUrl}/subscribedusers`;
}

export async function subscribedUsers(data) {
  const response = await httpService.post(
    `${apiEndpoint}/postSubscribedUsers`,
    data
  );
  console.log(data);
  return response;
}

export async function blogSubscribedUsers(data) {
  const response = await httpService.post(
    `${apiEndpoint}/postBlogSubscribedUsers`,
    data
  );
  console.log(data);
  return response;
}


export default { subscribedUsers, blogSubscribedUsers };

import { apiUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/resourceAllocations";
} else {
    apiEndpoint = apiUrl + "/resourceAllocations";
}
   
 
  export async function addresourceallocation(data){
    const response=await httpService.post(`${apiEndpoint}/addresourceallocation`,({data}));
    return response;
  }


  
  export async function getresourceallocation(assetId,orgId) {
    console.log(assetId,orgId,"lllllllllllllll")
     const response = await httpService.post(`${apiEndpoint}/getresourceallocation`, { orgId, assetId });
     return response;
 }
  



  export async function deleteresourceallocation(id){
    const response=await httpService.post(`${apiEndpoint}/deleteresourceallocation`,({id}));
    return response;
  }


  export async function updateresourceallocation(data) {
    const { resourceId, ...updatedData } = data; // Destructure resourceId and updatedData from data object
  
    try {
      const response = await httpService.put(`${apiEndpoint}/updateresourceallocation/${resourceId}`, updatedData);
      return response.data; // Return only the data from the response
    } catch (error) {
      throw new Error(`Error updating resource: ${error.message}`);
    }
  }

  export async function updateresourceallocationbyid(data) {
    
  
    try {
      const response = await httpService.put(`${apiEndpoint}/updateresourceallocationbyid`, {data});
      return response.data; // Return only the data from the response
    } catch (error) {
      throw new Error(`Error updating resource: ${error.message}`);
    }
  }

  export async function getRequirementMapping(assetId,orgId) {
     const response = await httpService.post(`${apiEndpoint}/getRequirementMapping`, { assetId, orgId });
     return response;
 }
  

 export async function getRequirementMappingById(id) {
  const response = await httpService.post(`${apiEndpoint}/getRequirementMappingById`, { id });
  return response;
}

export async function revertRequirementMapping(id, requirementID) {
  const response = await httpService.post(`${apiEndpoint}/revertRequirementMapping`, { id, requirementID });
  return response;
}

  
  export async function mappedReuirementClause(data){
    const response=await httpService.post(`${apiEndpoint}/mappedClauseRequirement`, {data});
    return response;
  }


   
  export default {
   
    addresourceallocation,
    getresourceallocation,
    deleteresourceallocation,
    updateresourceallocation,
    updateresourceallocationbyid,
    getRequirementMapping,
    getRequirementMappingById,
    revertRequirementMapping,
    mappedReuirementClause
   
  }
import React, { useState, useEffect, useCallback } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { cardData } from './highlight';
import debounce from "lodash/debounce";
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import { CopyToClipboard } from "react-copy-to-clipboard";

const Correction = ({ allSuggesion, spanProps, addDictionary, correctionList,
  selection, onUpdate, replaceAll, classChange, grammerList }) => {

  const [explain, setexplain] = useState([]);
  let [oldElementId, setoldElementId] = useState("");
  let [spanList, setspanList] = useState([]);
  let list = [];

  const explainFn = (suggesion, index, status = true) => {
    // console.log("explainIndex",suggesion);
    const id = suggesion.span_id;
    let setArr = [];
    addSpanColor(id);
    const boxContent = cardData(suggesion.pgm, suggesion.actual);
    setArr[index] = { content: boxContent.body, head: boxContent.head };
    setexplain(setArr);
    if (status)
      beerequipment(id);
    else
      fuAnimation(index)
  }

  const addSpanColor = (id) => {
    // console.log("oldElementId", oldElementId)
    if (oldElementId) {
      try {
        const element = document.getElementById(oldElementId);
        element.classList.remove("bg_all");
      }
      catch (e) {
        console.error('addSpanColor inside the catch')
      }
    }
    setoldElementId(id)
    try {
      const element = document.getElementById(id);
      element.classList.add("bg_all");
    }
    catch (e) {
      console.error('addSpanColor inside the catch')
    }

  }

  useEffect(() => {
    // console.log('selection', spanProps);
    if (!spanProps) return;
    getAllSpanList();
    $('#scroll').animate({ scrollTop: 1000000 }, '500', 'swing');
    const foundIndex = spanList.findIndex((obj) => obj.span_id == spanProps.span_id);
    const sugest = spanList[foundIndex];
    const lastIndex = spanList.length - 1;
    spanList[foundIndex] = spanList[lastIndex];
    spanList[lastIndex] = sugest;
    setspanList(spanList)

    explainFn(sugest, lastIndex, false);
  }, [spanProps]);

  // useEffect(() =>{
  //    getAllSpanList();
  // }, [onUpdate])

  useEffect(() => {
    getAllSpanList();
  }, [correctionList, onUpdate])



  function getAllSpanList() {
    // console.log("correctionList", correctionList);
    let allArr = [];
    for (const key in correctionList) {
      const arr = correctionList[key];
      allArr.push(...arr);
    }
    // console.log("allArr", allArr)
    setspanList(allArr);
  }

  const fuAnimation = (id) => {
    try {
      const element = document.getElementById(id);
      element.classList.add("animate");
      setTimeout(() => {
        element.classList.remove("animate");
      }, 300);
    }
    catch (error) {
      console.error("fuAnimation catch")
    }
  }

  function getColor(pgm) {
    let color;
    grammerList.forEach((obj) => {
      if (obj.list.indexOf(pgm) >= 0)
        color = obj.color;
    });
    return color;
  }

  function beerequipment(id) {
    try {
      let top = $(`#${id}`).offset().top - 100;
      $("html, body").stop().animate({ scrollTop: top }, 500, 'swing');
    }
    catch (e) {
      console.error(`${id} not found`)
      toast.error('Please deselect your assistance, try again!')
    }
  }

  const copyText = () => {
    toast.info("Copy to clipboard!");
  }

  return (
    <div className="mt-4">
      <ToastContainer />
      <div className="flex_between mb-3">
        <p className="allAlert">All Alerts  </p>
        <i onClick={() => classChange()}
          className="fa fa-times allAlert point" aria-hidden="true"></i>
      </div>

      <div id="scroll" className="scroll">

        <div className="my-2">
          <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionEnter={true} transitionLeave={true}>
            {spanList.map((suggesion, index) => {
              const { actual, pgm, span_id, anchorKey } = suggesion;
              let className = 'itemList d-flex justify-content-between point mt-1 rounded-top';
              className += explain[index] ? ' SentenceCardHighlight' : "";
              let selectedCardClass = explain[index] ? ' SentenceCardHighlight' : "";
              return (
                <div key={index} id={index}
                  onClick={() => explainFn(suggesion, index)}
                  style={{ borderLeftStyle: "solid", borderLeftColor: getColor(pgm), borderLeftWidth: 4 }}
                  className={className}>
                  <div className="my-1">
                    <p className={`itemContent ${selectedCardClass}`}>{cardData(pgm, null)['head']}</p>
                    <p className={`itemHead ${selectedCardClass}`}>{actual}</p>
                    <p className={`itemContent ${selectedCardClass}`}>{explain[index] && explain[index]['content']}</p>
                    {
                      explain[index] && <div>
                        {
                          suggesion.expected && suggesion.expected.map(SuggestWord => {
                            return (
                              <span
                                onClick={() => replaceAll(SuggestWord, 'replace', span_id, pgm, actual)}
                                key={SuggestWord}
                                className="p-2 m-0 select rounded-top"
                              >
                                {SuggestWord || 'omit'}
                              </span>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                  <div className="d-flex flex-column p-2">
                    {
                      explain[index] ?
                        <i className="fa fa-arrow-down iconSize" aria-hidden="true"></i>
                        : <i className="fa fa-arrow-right iconSize" aria-hidden="true"></i>
                    }
                    {
                      explain[index] && <span className="mt-auto">
                        {(pgm == 'TYPOS') &&
                          <i onClick={() => addDictionary(actual, anchorKey)}
                            className="fa fa-book iconSize mb-3" data-toggle="tooltip" title="Add Dictionary"
                            aria-hidden="true"></i>
                        }
                        <i onClick={() => replaceAll(null, 'ignore', span_id, pgm, actual)}
                          data-toggle="tooltip" title="ignore"
                          className="fa fa-trash iconSize" aria-hidden="true"></i>
                        {((pgm == "weaken") || (pgm == "hard") || (pgm == 'complex') || (pgm == 'COLLOCATIONS')) &&
                          <CopyToClipboard
                            text={actual}
                            onCopy={() =>
                              copyText(actual)
                            }
                          >
                            <i
                              className="fa fa-clipboard text-primary"
                              data-toggle="tooltip"
                              title="Copy the Sentence"
                              aria-hidden="true"
                            ></i>
                          </CopyToClipboard>
                        }
                      </span>
                    }
                  </div>
                </div>
              )
            })
            }
          </ReactCSSTransitionGroup>
        </div>
      </div>
    </div>
  )
}

export default Correction

import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/assetsharing`;
   
  } else {
    apiEndpoint = `${apiUrl}/assetsharing`;
   
  }
 
  export async function getassetsharingbyid(data){
    console.log(data,"dataddddd")
    const response=await httpService.post(`${apiEndpoint}/getassetsharingbyid`,{data});
    return response;
  }

  export async function addassetsSharings(data){
    const response=await httpService.post(`${apiEndpoint}/addassetsSharings`,{data});
    return response;
  }
  

  export async function getSectionSharedData(data){
    const response=await httpService.post(`${apiEndpoint}/getSharedSectionbyid`,{data});
    return response;
  }
  export async function allocatedProjects(userEmail){
    const response=await httpService.post(`${apiEndpoint}/allocatedProjects`,userEmail);
    return response;
  }
  export default {
    addassetsSharings,
    getassetsharingbyid 
  }